import React, {Component} from 'react';
import { Route } from 'react-router-dom'; 

import SuccessAnimate from './animation';
import FailedAnimate from './paymentFailed';

class Animate extends Component {
 
    render() {
        // console.log("props form home",this.props)
        return (
            <div style={{marginTop: 20, marginBottom: 20}}>
                <Route exact path='/animate/success' component={SuccessAnimate} />
                <Route exact path='/animate/failed' component={FailedAnimate} />
            </div>
        )
    }
}

export default Animate;