import React,{Component} from 'react';
import Select from 'react-select';
import { Form, FormGroup, Card , CardText ,Label, Input, Button, Row, Col, Container, CardBody} from 'reactstrap';
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify';

class CityCatDisplay extends Component{
    state={
        cities:[],
        city:{
            value:'',
            id:'',
            valid:false,
            Invalid:false
        },
        cat:[],
        subcat:[],
        displayvalue:'',
        AssignCat:[],
        AssignSubcat:[],
        category:{
            name:'',
            id:''
        },
        subcategory:{
            name:'',
            id:''
        }
    }
    componentDidMount(){
        axios.get("/support/consumer/city")
        .then((res) => {
         //    console.log("City", res.data);
            if(res.data.data){
                 if(res.data.result !== null){
                     this.setState({
                         ...this.state,
                         cities: res.data.result,
                         loading: false
                     });
                 } else {
                     this.setState({
                         ...this.state,
                         loading: false
                     });
                 }                
            } else {
             if(res.data.dbError){
                 toast.error("Internal Server Error", {
                     position: toast.POSITION.BOTTOM_CENTER
                 });
                 this.setState({
                     ...this.state,
                     loading: false
                 });
             } else {
                 this.setState({
                     ...this.state,
                     loading: false
                 });
             }
            }
            
        })
        .catch((err) => {
             toast.error("Unable to reach server please try again!", {
                 position: toast.POSITION.BOTTOM_CENTER
             });
             this.setState({
                 ...this.state,
                 loading: false
             });
        });

    }
    cityHandler = (item) => {
        console.log('trace',item);
        
        if(item == null || item.length == 0){
            this.setState({
                city:{
                    ...this.state.city,
                    value : []
                }
            })
          } else{
              this.setState({
                city:{
                    ...this.state.city,
                    id: item.value,
                    value:item.city,
                    valid: true,
                    invalid: false
                }
              })
          }
    }
    cityCatDisplayHandler=(id)=>{
        
        console.log('value',this.state.city.id)
        let body={
            id:this.state.city.id
        }
        axios.post("/support/consumer/citycatdisplay",body)
        .then((res) => {
            console.log("City", res.data);
            if(res.data.data){
                 if(res.data.catData!== null &&res.data.subCatData!==null){
                     this.setState({
                         ...this.state,
                         cat: res.data.catData,
                         subcat:res.data.subCatData,
                         loading: false,
                         displayvalue:'all'
                        
                         
                     });
                 } else {
                     this.setState({
                         ...this.state,
                         loading: false
                     });
                 }                
            } else {
             if(res.data.dbError){
                 toast.error("Internal Server Error", {
                     position: toast.POSITION.BOTTOM_CENTER
                 });
                 this.setState({
                     ...this.state,
                     loading: false
                 });
             } else {
                 this.setState({
                     ...this.state,
                     loading: false
                 });
             }
            }
            
        })
        .catch((error) => {
             toast.error("Unable to reach server please try again!", {
                 position: toast.POSITION.BOTTOM_CENTER
             });
             this.setState({
                 ...this.state,
                 loading: false
             });
        });
        
      
    }
    showSubcat=(cid,namevalue,cityId)=>{
     this.setState({
        category:{
            ...this.state.name,
            name:namevalue,
            id:cid
        }

     })  
     let body={
         cid,
         cityId
     }
        axios.post("/support/subcatdisplay",body)
        .then(res=>{
            if(res.data.data){
                console.log('sub cat data',res.data.subcat)
                if(res.data.subcat.length!==0){
                this.setState({
                    AssignSubcat:res.data.subcat,
                    displayvalue:'sub'
                    
                })
                }
                else{
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                }
            }
            else{
                if(res.data.dbError){
                    toast.error("Internal Server Error", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                } else {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                }

            }
            
        })
        .catch((error) => {
            toast.error("Unable to reach server please try again!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({
                ...this.state,
                loading: false
            });
       });
     
    }
    showCat=(sid,subcatname)=>{
        console.log('kkk')
        
        this.setState({
            subcategory:{
            ...this.state.name,
            name:subcatname,
            id:sid
            }
        })
        axios.post("/support/categorydisplay",{sid})
        .then(res=>{
            
                if(res.data.data){
                    console.log('cat data',res.data.category)
                    if(res.data.category.length!==0){
                    this.setState({
                        AssignCat:res.data.category,
                        displayvalue:'cat'
                    })
                    }
                    else{
                        this.setState({
                            ...this.state,
                            loading: false
                        });
                    }
                }
                else{
                    if(res.data.dbError){
                        toast.error("Internal Server Error", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        this.setState({
                            ...this.state,
                            loading: false
                        });
                    } else {
                        this.setState({
                            ...this.state,
                            loading: false
                        });
                    }
    
                }
    
            
        })
    }
    datacard=()=>{
    switch(this.state.displayvalue){
        case 'all':
            if(this.state.cat.length!==0&&this.state.subcat.length!==0){
            return (
                
                       <Card style={{marginTop: 10,marginLeft:250,marginRight:250,backgroundColor: "#E1E1E1"}}>
            
                            <CardBody>
                                <CardText style={{paddingLeft:'100px',paddingRight:'100px'}}>
                                    <Row style={{textAlign:'initial'}}>
                                        <Col style={{textAlign:'initial',padding:10}}>
                                        <text style={{fontWeight:'bold',fontFamily:'Muli'}}>Category</text>
                                        {this.state.cat.map((item,key)=>{
                                          return  (
                                        
                                             <ul  style={{marginTop:20}} key={item.QF_SERVICE_CATEGORY_PRID}>
                                                 <li onClick={()=>this.showSubcat(item.QF_SERVICE_CATEGORY_PRID,item.category_name,item.QF_CITY_PRID)}>{item.category_name}</li>
                                             </ul>
                                            
                                            
                                          )
        
                                        })}
                                        </Col>
                                        <Col></Col>
                                        <Col style={{textAlign:'initial',padding:10}}>
                                        <text style={{fontWeight:'bold',fontFamily:'Muli'}}>Subcategory</text>
                                        {this.state.subcat.map((item,key)=>{
                                          return(
                                                <ul style={{marginTop:20}} key={item.QF_SERVICE_SUB_CATEGORY_PRID}>
                                                    <li onClick={()=>this.showCat(item.QF_SERVICE_SUB_CATEGORY_PRID,item.service_sub_category_name)}>{item.service_sub_category_name}</li>
                                                    </ul>
                                            
                                            
                                          )  
        
                                        })}
                                        </Col>
                                    </Row>
                                    
                                </CardText>
                            </CardBody>
                        </Card>
                    
               
        
        
            )
            }
            case 'cat':
                return (
                    
                        <Card style={{marginTop: 10,marginLeft:250,marginRight:250,backgroundColor: "#E1E1E1"}}>
             
                             <CardBody>
                                 <CardText style={{paddingLeft:'100px',paddingRight:'100px'}}>
                                     <Row style={{textAlign:'initial'}}>
                                         <Col style={{textAlign:'initial',padding:10}}>
                                         <text style={{fontWeight:'bold',fontFamily:'Muli'}}>Category</text>
                                         {this.state.AssignCat.map((item,key)=>{
                                           return  (
                                            
                                              <ul  style={{marginTop:20}}>
                                                  <li onClick={()=>this.showSubcat()}>{item.category_name}</li>
                                              </ul>
                                             
                                             
                                           )
         
                                         })}
                                         </Col>
                                         <Col>
                                         </Col>
                                         <Col style={{textAlign:'initial',padding:10}}>
                                         <text style={{fontWeight:'bold',fontFamily:'Muli'}}>Subcategory</text>
                                         {/* {this.state.AssignSubcat.map((item,key)=>{
                                          return(
                                                <ul style={{marginTop:20}} key={item.QF_SERVICE_SUB_CATEGORY_PRID}>
                                                    <li onClick={()=>this.showCat()}>{item.service_sub_category_name}</li>
                                                    </ul>
                                            
                                            
                                          )  
        
                                        })} */}
                                        <ul style={{marginTop:20}}><li>{this.state.subcategory.name}</li></ul>
                                         </Col>
                                     </Row>
                                     <Row>
                                         <Col size='4'></Col>
                                         <Col size='4'>
                                             <Button color='warning' onClick={this.cityCatDisplayHandler}>Go Back</Button>
                                         </Col>
                                         <Col size='4'></Col>
                                         
                                     </Row>
                                     
                                 </CardText>
                             </CardBody>
                         </Card>
                     )
            case 'sub':
                return (
                    
                    <Card style={{marginTop: 10,marginLeft:250,marginRight:250,backgroundColor: "#E1E1E1"}}>
         
                         <CardBody>
                             <CardText style={{paddingLeft:'100px',paddingRight:'100px'}}>
                                 <Row style={{textAlign:'initial'}}>
                                     <Col style={{textAlign:'initial',padding:10}}>
                                     <text style={{fontWeight:'bold',fontFamily:'Muli'}}>Category</text>
                                     <ul>
                                    <li style={{marginTop:20}}>{this.state.category.name}</li> 
                                         </ul>

                                     </Col>
                                     <Col>
                                     </Col>
                                     <Col style={{textAlign:'initial',padding:10}}>
                                     <text style={{fontWeight:'bold',fontFamily:'Muli'}}>Subcategory</text>
                                     {this.state.AssignSubcat.map((item,key)=>{
                                       return(
                                             <ul style={{marginTop:20}} key={item.QF_SERVICE_SUB_CATEGORY_PRID}>
                                                 <li>{item.service_sub_category_name}</li>
                                                 </ul>
                                         
                                         
                                       )  
     
                                     })}
                                     </Col>
                                 </Row>
                                 <Row>
                                         <Col size='4'></Col>
                                         <Col size='4'>
                                             <Button color='warning' onClick={this.cityCatDisplayHandler}>Go Back</Button>
                                         </Col>
                                         <Col size='4'></Col>
                                         
                                     </Row>
                                 
                             </CardText>
                         </CardBody>
                     </Card>
                 )
            default:
                return null;
    }
    }
    allCatSubDisplay=()=>{
        this.props.history.goBack(this.cityCatDisplayHandler())
        // this.props.history.goBack()
    }
    render(){
        console.log('state',this.state)
        const customStyles = {
            option: (provided, state) => ({
              ...provided,
              borderBottom: '1px dotted #9277BA',
              color: state.isSelected ? '#50E3C2' : 'black',
              padding: 10,
              textAlign:'left'
            }),
            singleValue: (provided, state) => {
              const opacity = state.isDisabled ? 0.5 : 1;
              const transition = 'opacity 300ms';
          
              return { ...provided, opacity, transition };
            }
          }
    let displayAllCities;
    if(this.state.cities.length!==0){
            displayAllCities = this.state.cities.map((city) => {
                return (
                {value: city.QF_CITY_PRID, city: city.CITY_NAME, state: city.STATE_PROVINCE_NAME, country: city.COUNTRY_NAME}
                )
        });
    }
    // let display;
    // if(this.state.cat.length!==0 &&this.state.subcat.length!==0){
    //         display=(
    //            <Card style={{marginTop: 10,marginLeft:150,marginRight:150,backgroundColor: "#E1E1E1"}}>
    
    //                 <CardBody>
    //                     <CardText style={{paddingLeft:'100px',paddingRight:'100px'}}>
    //                         <Row style={{textAlign:'initial'}}>
    //                             <Col style={{textAlign:'initial',padding:10}}>
    //                             <text style={{fontWeight:'bold',fontFamily:'Muli'}}>Category</text>
    //                             {this.state.cat.map((item,key)=>{
    //                               return  (
                                
    //                                  <ul  style={{marginTop:20}} key={item.QF_SERVICE_CATEGORY_PRID}>
    //                                      <li onClick={()=>this.showSubcat(item.QF_SERVICE_CATEGORY_PRID)}>{item.category_name}</li>
    //                                  </ul>
                                    
                                    
    //                               )

    //                             })}
    //                             </Col>
    //                             <Col style={{textAlign:'initial',padding:10}}>
    //                             <text style={{fontWeight:'bold',fontFamily:'Muli'}}>Subcategory</text>
    //                             {this.state.subcat.map((item,key)=>{
    //                               return(
    //                                     <ul style={{marginTop:20}} key={item.QF_SERVICE_SUB_CATEGORY_PRID}>
    //                                         <li onClick={()=>this.showCat(item.QF_SERVICE_SUB_CATEGORY_PRID)}>{item.service_sub_category_name}</li>
    //                                         </ul>
                                    
                                    
    //                               )  

    //                             })}
    //                             </Col>
    //                         </Row>
                            
    //                     </CardText>
    //                 </CardBody>
    //             </Card>
    //         )
       
    // }
    
       
        return(
            <div>
            <Container  fluid>
            <div className="row justify-content-center" style={{backgroundColor: '#EFEFEF'}}>
            <div className="col-3">
              <FormGroup style={{marginLeft:0}}>
                        <Row>
                            <Label for="city" style={{marginLeft:150,fontFamily:'Muli',fontWeight:'bold'}}>City</Label>
                        </Row>
                        <Row>                               
                            <Col>
                                <Select
                                    isSearchable={true}
                                    isClearable
                                    onChange={this.cityHandler}
                                    options={displayAllCities}
                                    getOptionLabel={options => `${options.city}, ${options.state}, ${options.country}`}
                                    styles={customStyles}
                                    width='100%'
                                />
                            </Col>
                        </Row>
                    </FormGroup> 
                    <FormGroup>
                            <Button onClick={()=>this.cityCatDisplayHandler(this.state.city.id)} style={{backgroundColor:"#2C2165", color:'white'}}><text style={{fontFamily:'Muli'}}>Search</text></Button>
                    </FormGroup>
                </div>
                
            
                </div>
                {/* {display} */}
                {
                    this.datacard()
                }

                </Container>
            </div>
        )
        }

}
export default CityCatDisplay;