import Switch from 'react-switch';

import React, { Component } from 'react'; 
import {connect} from "react-redux";
import axios from 'axios';
import {ToastContainer, toast } from 'react-toastify';
import { ButtonGroup, Button, Card, CardBody,CardTitle,Image, CardText, Form,FormText, Row, Col, Container, Modal,ModalBody,ModalHeader,ModalFooter, Tooltip} from 'reactstrap';
import {BarLoader} from 'react-spinners';
import momenttz from 'moment-timezone'
import Curr from "currency.js";
import calender from '../../images/calender.png';
import time from '../../images/time.png';


// import Breadcrumb from '../Breadcrumb';




class MerchantCreatedOffers extends Component {
   
    state={
        currentOffers: [],
        pastOffers: [],
        loading:false,
        past: false,
        current: true,
        auto:false,
        consumerDet:[],
        booking:[],
        tooltipOpen: [],
        uid:this.props.location.state.id,       
        subcatDetails:'',
        toggle:[]
    }

    componentDidMount(){
      
        // console.log("Props111", this.props)
      
            this.setState({loading:true})
           let body ={
                uid:this.props.location.state.id,
                mer_add_timezone: this.props.location.state.tz
            }
           let toggle={};
            axios.post('/support/merchant/mcurrentoffers2',body)
           .then((res) => {
            console.log("Current offers", res)
            if(res.data.data){

                res.data.results2.map((offer) => {
                    if(offer.MERCHANT_OFFER_DELETED_FLAG === 1){
                        return toggle['offer_'+offer.QF_MERCHANT_OFFER_PRID] = false;
                    } else {
                        return toggle['offer_'+offer.QF_MERCHANT_OFFER_PRID] = true;
                    }
                })

                if(res.data.results2.length !== 0){
                    this.setState({
                        ...this.state,
                        currentOffers: res.data.results2,
                        toggle: toggle,
                        loading: false
                    });
                } else if(res.data.results2.length === 0){ 
                    this.setState({
                        ...this.state,
                        currentOffers: [],
                        loading: false
                    });
        
                }
            }
            else {
                if(res.data.dbError){
                    toast.error("Internal Server Error!",{
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                } else { 
                    this.setState({
                        ...this.state,
                        currentOffers: [],
                        loading: false
                    });
                    toast.info("No Current Offers to display",{
                        position: toast.POSITION.BOTTOM_CENTER
                    });

                }
                  
            
            }
           
        })
        .catch((err) => {
            toast.warn("Unable to Reach Server! Please Try Again!" , {
                position: toast.POSITION.BOTTOM_CENTER
              });
            this.setState({
                ...this.state,
                loading: false
            });
        })

        

    
}
    
currentTicketHandler = () => {
        this.setState({
                ...this.state,
                auto:false,
                current: true,
                past: false,
                loading: true            
        });
        if(this.state.currentOffers.length===0){
            return toast.info("No Current Offers to display",{
                 position: toast.POSITION.BOTTOM_CENTER
             });
         }
        let count=0;
        let test='';
        if(this.state.currentOffers.length !== 0){
        test=this.state.currentOffers.map((ticket) =>{
        console.log('auto',test)
        if(ticket.OFFER_AUTO_CREATED===null){
           count=count+1;
           console.log('count',count)
        }})
        if(count==0){
            return toast.info("No Current Offers to display",{
                position: toast.POSITION.BOTTOM_CENTER
            });
        }
    
    }
        this.componentDidMount();
        
    }
    autoTicketHandler = () => {
        this.setState({
                ...this.state,
                current: false,
                auto:true,
                past: false           
        });
        if(this.state.currentOffers.length===0){
            return toast.info("No Auto Offers to display",{
                 position: toast.POSITION.BOTTOM_CENTER
             });
         }
        let count=0;
        let test='';
        if(this.state.currentOffers.length !== 0){
        test=this.state.currentOffers.map((ticket) =>{
        console.log('auto',test)
        if(ticket.OFFER_AUTO_CREATED===1){
           count=count+1;
           console.log('count',count)
        }})
        if(count==0){
            return toast.info("No Auto Offers to display",{
                position: toast.POSITION.BOTTOM_CENTER
            });
        }
    
    }
        this.componentDidMount();
        
    }

    pastTicketHandler = () => {

        this.setState({
            ...this.state,
            current: false,
            past: true,
            auto:false,
            loading: true              
        });

        let body={
            uid:this.props.location.state.id,
            mer_add_timezone: this.props.location.state.tz
        }

        axios.post("/support/merchant/mpastoffers", body)
        .then((res) => {
            if(res.data.data){
                // console.log("Past offers", res.data)
                if(res.data.offerDetails.length !== 0){
                    this.setState({
                        ...this.state,
                        pastOffers: res.data.offerDetails,
                        loading: false
                    });
                } 
                else if(res.data.offerDetails.length === 0){
                    this.setState({
                        ...this.state,
                        pastOffers: [],
                        loading: false
                    });
                    
                }
            } else {
                if(res.data.dbError){
                    toast.error("Internal Server Error!",{
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                } else {
                    this.setState({
                        ...this.state,
                        pastOffers: [],
                        loading: false
                    });
                    toast.info("No Past Offers to display",{
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                }
                   
                
            }
           
        })
        .catch((err) => {
            console.log(err)
            toast.warn("Unable to Reach Server! Please Try Again!" , {
                position: toast.POSITION.BOTTOM_CENTER
              });
            this.setState({
                ...this.state,
                loading: false
            });
        })       
     }
    
    cardCurrentHandler = (id) => {
        let url=this.props.location.state.url ? this.props.location.state.url : this.props.match.url;

        this.props.history.push({
            pathname: url+'/OfferIdSearchDetails',
            state:{
              id,
              url
            }
        })

        // console.log('current',id)
        this.setState({
            ...this.state,
            loading: true
        });
    }

    toggle = (id) =>{
        // console.log("Prid", id)

        let tooltip={};

        if(this.state.tooltipOpen['update_'+id]){
            tooltip={
             ...this.state.tooltipOpen,
             ['update_'+id]:false
            }
            this.setState({
                ...this.state,
                tooltipOpen: tooltip,
            });
        } else {            
            tooltip={
                ...this.state.tooltipOpen,
                ['update_'+id]:true
               }
               this.setState({
                   ...this.state,
                   tooltipOpen: tooltip,
               });    
        }
    }

    updateOfferHandler = (offerDetails) => {
        this.props.history.push({
            pathname: this.props.match.url+'/MerchantOfferUpdate',
            state: {
                offerDetails,
                mpPrid: this.props.location.state.id,
                name : this.props.location.state.name
            }
        })
    }
    
    deleteOfferToggleHandler = (id) => {
        // console.log("id", id);

        let toggle={};
        if(this.state.toggle['offer_'+id] === true){
            toggle = {
                ...this.state.toggle,
                ['offer_'+id] : false
            }

            this.setState({
                ...this.state,
                toggle : toggle
            })

            axios.post('/support/merchant/offer/deleteToggle', {id})
            .then(res => {
                // console.log("response", res);
                if(res.data.data){
                    if(res.data.result.affectedRows){
                        toast.success("Offer Deactivated",{
                            position : toast.POSITION.BOTTOM_CENTER
                        });
                    } else {
                        toast.warn("Not Updated",{
                            position : toast.POSITION.BOTTOM_CENTER
                        });
                    }
                } else {
                    if(res.data.dbError){
                        toast.error("Internal Server Error",{
                            position : toast.POSITION.BOTTOM_CENTER
                        });
                    } else {
                        toast.warn("Not Updated",{
                            position : toast.POSITION.BOTTOM_CENTER
                        });
                    }
                }
            })
            .catch(err => {
                console.log(err);
                toast.warn("Unable to Reach Server! Please Try Again!" , {
                    position: toast.POSITION.BOTTOM_CENTER
                  });
            })
        } else if(this.state.toggle['offer_'+id] === false){
            toggle = {
                ...this.state.toggle,
                ['offer_'+id] : true
            }

            this.setState({
                ...this.state,
                toggle : toggle
            })

            axios.post('/support/merchant/offer/activeToggle', {id})
            .then(res => {
                // console.log("response", res);
                if(res.data.data){
                    if(res.data.result.affectedRows){
                        toast.success("Offer Activated",{
                            position : toast.POSITION.BOTTOM_CENTER
                        });
                    } else {
                        toast.warn("Not Updated",{
                            position : toast.POSITION.BOTTOM_CENTER
                        });
                    }
                } else {
                    if(res.data.dbError){
                        toast.error("Internal Server Error",{
                            position : toast.POSITION.BOTTOM_CENTER
                        });
                    } else {
                        toast.warn("Not Updated",{
                            position : toast.POSITION.BOTTOM_CENTER
                        });
                    }
                }
            })
            .catch(err => {
                console.log(err);
                toast.warn("Unable to Reach Server! Please Try Again!" , {
                    position: toast.POSITION.BOTTOM_CENTER
                  });
            })
        }
    }

    render() {

        // console.log("State in Offers", this.state)

        const rowStyle={
            textAlign:"initial",
        }
        const fWeightSpan={
            fontWeight:"500",
            padding: 5,
            cursor: 'pointer',
            fontFamily: 'Muli',
            // marginLeft: 5,
            color: '#868686'
            // border:'1px solid black'
        }

        const fWeightSpan1={
            fontWeight:"500",
            fontSize:16,
            padding: 5,
            marginBottom:10,
            cursor: 'pointer',
            fontFamily: 'Muli',
            // marginLeft: 5,
            color: '#563951'
            // border:'1px solid black'
        }
        
        const textDecor = {
            marginLeft: 5,
            fontFamily: 'Muli',
            // marginLeft: 5,
            color: '#563951'
        }
        const textDecor1 = {
            marginLeft: 5,
            fontFamily: 'Muli',
            // marginLeft: 5,
            color: '#3DC5B6'
        }

        let current = "No Current Offers";
        let past = "No Past Offers";

        if(this.state.currentOffers.length !== 0){
            // console.log('inside')
            current = this.state.currentOffers.map((ticket) => {
                if(ticket.OFFER_AUTO_CREATED==null){
                return (
                    <Card style={{marginTop: 20,backgroundColor:'white',width:'100%', borderRadius:'5px', padding:10}} >
                        <Row>                                    
                            <Col sm={{size:12}} style={{cursor:'pointer'}} onClick={this.cardCurrentHandler.bind(this,ticket.QF_MERCHANT_OFFER_PRID)}>
                                <img src={ticket.image_link} width='100%' height={300} style={{borderRadius:'5px'}}/>
                                
                            </Col>
                        </Row>
                        <CardBody >   
                        <CardTitle style={{fontSize:18,marginTop:-15}}></CardTitle>                                                    
                            <CardText>
                                <Row style={rowStyle}>
                                    <Col sm={{size:7}} style={fWeightSpan1} onClick={this.cardCurrentHandler.bind(this,ticket.QF_MERCHANT_OFFER_PRID)}><text>{ticket.MERCHANT_OFFER_HEADER_NAME}</text></Col>
                                    <Col sm={{size:4}} style={fWeightSpan} onClick={this.cardCurrentHandler.bind(this,ticket.QF_MERCHANT_OFFER_PRID)}>
                                        <span style={{marginRight:5}}><text style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}>{ticket.MERCHANT_OFFER_CURRENCY === "Rupees" ? <span>{'\u20B9'}</span> : <span>$</span>}{Curr(ticket.MERCHANT_OFFER_LIST_PRICE).value.toFixed(2)}</text></span> 
                                        <span style={textDecor1}>{ticket.MERCHANT_OFFER_CURRENCY === "Rupees" ? <span>{'\u20B9'}</span> : <span>$</span>}{Curr(ticket.MERCHANT_OFFER_PRICE).value.toFixed(2)}</span>
                                    </Col>
                                    {/* <Col sm={{size:1}} style={fWeightSpan1}> <Switch height={20} onColor='#3DC5B6' offColor='#F86C69' onChange={() => this.deleteOfferToggleHandler(ticket.QF_MERCHANT_OFFER_PRID)} checked={this.state.toggle['offer_'+ticket.QF_MERCHANT_OFFER_PRID]}/> </Col> */}


                                </Row>
                                <Row style={rowStyle}>
                                    <Col sm={{size:3}} style={fWeightSpan} onClick={this.cardCurrentHandler.bind(this,ticket.QF_MERCHANT_OFFER_PRID)}><img src={calender} width={15} height={15} /> <text style={textDecor}>{ticket.MERCHANT_OFFER_TO_DATE_TIME ? momenttz(ticket.MERCHANT_OFFER_TO_DATE_TIME+"Z").tz(ticket.MERCHANT_OFFER_TIMEZONE).format("DD MMM YYYY") : <text>---</text>} </text></Col>
                                    <Col sm={{size:5}} style={fWeightSpan} onClick={this.cardCurrentHandler.bind(this,ticket.QF_MERCHANT_OFFER_PRID)}><img src={time} width={15} height={15}/> <text style={textDecor}>{ticket.MERCHANT_OFFER_FROM_DATE_TIME ? momenttz(ticket.MERCHANT_OFFER_FROM_DATE_TIME+"Z").tz(ticket.MERCHANT_OFFER_TIMEZONE).format("hh:mm A") : <text>---</text>} </text><text>-</text><text style={textDecor}>{ticket.MERCHANT_OFFER_TO_DATE_TIME ? <text>{momenttz(ticket.MERCHANT_OFFER_TO_DATE_TIME+"Z").tz(ticket.MERCHANT_OFFER_TIMEZONE).format("hh:mm A z")}</text> : <text>---</text> } </text> </Col>
                                </Row>
                                <Row style={rowStyle}>
                                    <Col sm={{size:5}} style={fWeightSpan1} onClick={this.cardCurrentHandler.bind(this,ticket.QF_MERCHANT_OFFER_PRID)}>Bookings: <text style={textDecor}>{ticket.MERCHANT_OFFER_ACCEPTED ? ticket.MERCHANT_OFFER_ACCEPTED : <text>0</text>}{'/'}{ticket.MERCHANT_MAX_NUMBER_OF_OFFERS ? ticket.MERCHANT_MAX_NUMBER_OF_OFFERS : <text>0</text>}</text> </Col>
                                </Row>
                            </CardText>
                        </CardBody>
                    </Card>
                );
                }
            })
        } else {
            if(this.state.loading===true){
                current= <text style={textDecor}>Loading...</text>;
            }else{
                if(this.state.currentOffers.length!=0)
                current = <text style={textDecor}>No Current Offers</text>;  
        }           
            
                     
        }
// auto offer
let auto="No Auto offers"
if(this.state.currentOffers.length !== 0){
    // console.log('inside')
    auto = this.state.currentOffers.map((ticket) => {
        if(ticket.OFFER_AUTO_CREATED==1){
        return (
            <Card style={{marginTop: 20,backgroundColor:'white',width:'100%', borderRadius:'5px', padding:10}} >
                <Row>                                    
                    <Col sm={{size:12}} style={{cursor:'pointer'}} onClick={this.cardCurrentHandler.bind(this,ticket.QF_MERCHANT_OFFER_PRID)}>
                        <img src={ticket.image_link} width='100%' height={300} style={{borderRadius:'5px'}}/>
                        
                    </Col>
                </Row>
                <CardBody >   
                <CardTitle style={{fontSize:18,marginTop:-15}}></CardTitle>                                                    
                    <CardText>
                        <Row style={rowStyle}>
                            <Col sm={{size:7}} style={fWeightSpan1} onClick={this.cardCurrentHandler.bind(this,ticket.QF_MERCHANT_OFFER_PRID)}><text>{ticket.MERCHANT_OFFER_HEADER_NAME}</text></Col>
                            <Col sm={{size:4}} style={fWeightSpan} onClick={this.cardCurrentHandler.bind(this,ticket.QF_MERCHANT_OFFER_PRID)}>
                                <span style={{marginRight:5}}><text style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}>{ticket.MERCHANT_OFFER_CURRENCY === "Rupees" ? <span>{'\u20B9'}</span> : <span>$</span>}{Curr(ticket.MERCHANT_OFFER_LIST_PRICE).value.toFixed(2)}</text></span> 
                                <span style={textDecor1}>{ticket.MERCHANT_OFFER_CURRENCY === "Rupees" ? <span>{'\u20B9'}</span> : <span>$</span>}{Curr(ticket.MERCHANT_OFFER_PRICE).value.toFixed(2)}</span>
                            </Col>
                            {/* <Col sm={{size:1}} style={fWeightSpan1}> <Switch height={20} onColor='#3DC5B6' offColor='#F86C69' onChange={() => this.deleteOfferToggleHandler(ticket.QF_MERCHANT_OFFER_PRID)} checked={this.state.toggle['offer_'+ticket.QF_MERCHANT_OFFER_PRID]}/> </Col> */}


                        </Row>
                        <Row style={rowStyle}>
                            <Col sm={{size:3}} style={fWeightSpan} onClick={this.cardCurrentHandler.bind(this,ticket.QF_MERCHANT_OFFER_PRID)}><img src={calender} width={15} height={15} /> <text style={textDecor}>{ticket.MERCHANT_OFFER_TO_DATE_TIME ? momenttz(ticket.MERCHANT_OFFER_TO_DATE_TIME+"Z").tz(ticket.MERCHANT_OFFER_TIMEZONE).format("DD MMM YYYY") : <text>---</text>} </text></Col>
                            <Col sm={{size:5}} style={fWeightSpan} onClick={this.cardCurrentHandler.bind(this,ticket.QF_MERCHANT_OFFER_PRID)}><img src={time} width={15} height={15}/> <text style={textDecor}>{ticket.MERCHANT_OFFER_FROM_DATE_TIME ? momenttz(ticket.MERCHANT_OFFER_FROM_DATE_TIME+"Z").tz(ticket.MERCHANT_OFFER_TIMEZONE).format("hh:mm A") : <text>---</text>} </text><text>-</text><text style={textDecor}>{ticket.MERCHANT_OFFER_TO_DATE_TIME ? <text>{momenttz(ticket.MERCHANT_OFFER_TO_DATE_TIME+"Z").tz(ticket.MERCHANT_OFFER_TIMEZONE).format("hh:mm A z")}</text> : <text>---</text> } </text> </Col>
                        </Row>
                        <Row style={rowStyle}>
                            <Col sm={{size:5}} style={fWeightSpan1} onClick={this.cardCurrentHandler.bind(this,ticket.QF_MERCHANT_OFFER_PRID)}>Bookings: <text style={textDecor}>{ticket.MERCHANT_OFFER_ACCEPTED ? ticket.MERCHANT_OFFER_ACCEPTED : <text>0</text>}{'/'}{ticket.MERCHANT_MAX_NUMBER_OF_OFFERS ? ticket.MERCHANT_MAX_NUMBER_OF_OFFERS : <text>0</text>}</text> </Col>
                        </Row>
                    </CardText>
                </CardBody>
            </Card>
        );
        }
    })
} else {
    if(this.state.loading===true){
        auto= <text style={textDecor}>Loading...</text>;
    }else{
        if(this.state.currentOffers.length!=0)
        auto= <text style={textDecor}>No Auto Offers</text>;  
}           
    
             
}

       

        if(this.state.pastOffers.length !== 0){ 
            past = this.state.pastOffers.map((ticket) => {
                return (
                    <Card style={{marginTop: 20,backgroundColor:'white',width:'100%', borderRadius:'5px', padding:10}} >
                        <Row>                                    
                            <Col sm={{size:12}} style={{cursor:'pointer'}} onClick={this.cardCurrentHandler.bind(this,ticket.QF_MERCHANT_OFFER_PRID)}>
                                <img src={ticket.image_link} width='100%' height={300} style={{borderRadius:'5px'}}/>
                            </Col>
                        </Row>
                        <CardBody >   
                        <CardTitle style={{fontSize:18,marginTop:-15}}></CardTitle>                                                    
                            <CardText>
                                <Row style={rowStyle}>
                                    <Col sm={{size:7}} style={fWeightSpan1} onClick={this.cardCurrentHandler.bind(this,ticket.QF_MERCHANT_OFFER_PRID)}><text>{ticket.MERCHANT_OFFER_HEADER_NAME}</text></Col>
                                    <Col sm={{size:4}} style={fWeightSpan} onClick={this.cardCurrentHandler.bind(this,ticket.QF_MERCHANT_OFFER_PRID)}>
                                        <span style={{marginRight:5}}><text style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}>{ticket.MERCHANT_OFFER_CURRENCY === "Rupees" ? <span>{'\u20B9'}</span> : <span>$</span>}{Curr(ticket.MERCHANT_OFFER_LIST_PRICE).value.toFixed(2)}</text></span> 
                                        <span style={textDecor1}>{ticket.MERCHANT_OFFER_CURRENCY === "Rupees" ? <span>{'\u20B9'}</span> : <span>$</span>}{Curr(ticket.MERCHANT_OFFER_PRICE).value.toFixed(2)}</span>
                                    </Col>


                                </Row>
                                <Row style={rowStyle}>
                                    <Col sm={{size:3}} style={fWeightSpan} onClick={this.cardCurrentHandler.bind(this,ticket.QF_MERCHANT_OFFER_PRID)}><img src={calender} width={15} height={15} /> <text style={textDecor}>{ticket.MERCHANT_OFFER_TO_DATE_TIME ? momenttz(ticket.MERCHANT_OFFER_TO_DATE_TIME+"Z").tz(ticket.MERCHANT_OFFER_TIMEZONE).format("DD MMM YYYY") : <text>---</text>} </text></Col>
                                    <Col sm={{size:5}} style={fWeightSpan} onClick={this.cardCurrentHandler.bind(this,ticket.QF_MERCHANT_OFFER_PRID)}><img src={time} width={15} height={15}/> <text style={textDecor}>{ticket.MERCHANT_OFFER_FROM_DATE_TIME ? momenttz(ticket.MERCHANT_OFFER_FROM_DATE_TIME+"Z").tz(ticket.MERCHANT_OFFER_TIMEZONE).format("hh:mm A") : <text>---</text>} </text><text>-</text><text style={textDecor}>{ticket.MERCHANT_OFFER_TO_DATE_TIME ? <text>{momenttz(ticket.MERCHANT_OFFER_TO_DATE_TIME+"Z").tz(ticket.MERCHANT_OFFER_TIMEZONE).format("hh:mm A z")} </text> : <text>---</text> } </text> </Col>
                                </Row>
                                <Row style={rowStyle}>
                                    <Col sm={{size:5}} style={fWeightSpan1} onClick={this.cardCurrentHandler.bind(this,ticket.QF_MERCHANT_OFFER_PRID)}>Bookings: <text style={textDecor}>{ticket.MERCHANT_OFFER_ACCEPTED ? ticket.MERCHANT_OFFER_ACCEPTED : <text>0</text>}{'/'}{ticket.MERCHANT_MAX_NUMBER_OF_OFFERS ? ticket.MERCHANT_MAX_NUMBER_OF_OFFERS : <text>0</text>}</text> </Col>
                                </Row>
                            </CardText>
                        </CardBody>
                    </Card>
                    
                );
            })
        } else {

            if(this.state.loading===true){
                past= <text style={textDecor}>Loading...</text>;
            }else{ 
                if(this.state.pastOffers.length===0&&this.state.loading===false)
                past= <text style={textDecor}>No Past Offers</text>;  
        }  
        }

        return (
            <div style={{overflowY:'scroll'}}>
            {/* <Breadcrumb active0={'My Tickets'} 
                        active0Link={'/home/myTicket'}/> */}

            <Container>
            <div className="row justify-content-center">
            <div className="col-6">
            <Form>
                <FormText style={{fontSize:24,marginBottom:20}}><text style={{color:'#563951', fontFamily:'Muli'}}>Offers Created By <strong>{this.props.location.state.name}</strong> </text></FormText>
            </Form>
            

               <ButtonGroup >
                <Button onClick={this.currentTicketHandler} active={this.state.current? true : false}><text style={{fontFamily:'Muli'}}>Current Offers</text></Button>
                <Button onClick={this.autoTicketHandler} active={this.state.auto? true : false}><text style={{fontFamily:'Muli'}}>Auto Offers</text></Button>
                <Button onClick={this.pastTicketHandler} active={this.state.past ? true : false}><text style={{fontFamily:'Muli'}}>Past Offers</text></Button> 
               </ButtonGroup>
                
            
            </div>
            </div> 
            <Container fluid style={{width:700}}> 
            <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/>
 
                {this.state.current ? current : null}  
                {this.state.past ? past : null} 
                 {this.state.auto? auto: null}
              
            </Container>                
                
            <ToastContainer autoClose={2500}/> 
            </Container>                
            </div>
                     

        )
    }
} 
const mapStateToProps=state=>{
    return {
      id:state.id 
  }
  }  


  export default connect(mapStateToProps)(MerchantCreatedOffers);

  