import React, {Component} from 'react';
import { Table, Button, Col, Input, Row } from 'reactstrap';

class BookingTabView extends Component {
  
  state = {
     
      serviceConsumed:'All',
      refundStatus:'All',
      accepted_data:this.props.location.state.accepted_data
       
  }

  detialClickHandler=(booking_id)=>{
    let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;

    this.props.history.push({
      pathname:url+'/BookingIdSearch',
      state:{
          booking_id,
          url,
          active: this.props.active,
          active1: this.props.active1,
          active2: this.props.active2 
      }
  });

    
  }

  serviceConsumedHandler=(e)=>{
    
   this.setState({serviceConsumed:e.target.value})
 

  }
  refundStatusHandler=(e)=>{
    
    this.setState({refundStatus:e.target.value})
  
 
   }

  render() {
      let tableData=null

      if(this.state.serviceConsumed==='All'&&this.state.refundStatus==='All'){
  
      
      tableData = this.state.accepted_data.map((acc) => { 

          return (
            <tr style={{cursor:"pointer"}} onClick={()=>this.detialClickHandler(acc.QF_CONSUMER_OFFER_ACCEPTED_PRID)} key={acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}>
              <td>{acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}</td>
              <td>{acc.QF_MERCHANT_OFFER_PRID?acc.QF_MERCHANT_OFFER_PRID:acc.QF_MERCHANT_SERVICE_PRID}</td>
              
              <td>
              {
                  acc.OFFER_CONSUMED
                  ?
                  <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>
                  :
                  <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>

                }
              </td>
              <td>
                {
                  acc.REFUND_STATUS
                  ?
                  <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>
                  :
                  <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>

                }
                
                </td>
              <td>{acc.OFFER_ACCEPTANCE_PIN}</td>
              
              
            </tr>
          )
      })}

      else if(this.state.serviceConsumed==='Yes'&&this.state.refundStatus==='Yes'){
    
        let bothYes = this.state.accepted_data.filter(each =>{return(
         each.OFFER_CONSUMED===1&&each.REFUND_STATUS===1)})
     
         tableData =  bothYes.map((acc) => {

          return ( 
            <tr style={{cursor:"pointer"}} onClick={()=>this.detialClickHandler(acc.QF_CONSUMER_OFFER_ACCEPTED_PRID)} key={acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}>
              <td>{acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}</td>
              <td>{acc.QF_MERCHANT_OFFER_PRID?acc.QF_MERCHANT_OFFER_PRID:acc.QF_MERCHANT_SERVICE_PRID}</td>
              
              <td>
              {
                  acc.OFFER_CONSUMED
                  ?
                  <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>
                  :
                  <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>

                }
              </td>
              <td>
                {
                  acc.REFUND_STATUS
                  ?
                  <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>
                  :
                  <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>

                }
                
                </td>
              <td>{acc.OFFER_ACCEPTANCE_PIN}</td>
              
              
            </tr>
          )
      })     
        }
     

        else if(this.state.serviceConsumed==='No'&&this.state.refundStatus==='No'){
    
          let bothNo = this.state.accepted_data.filter(each =>{return(
           each.OFFER_CONSUMED!==1&&each.REFUND_STATUS!==1)})
       
           tableData =  bothNo.map((acc) => {
  
            return (
              <tr style={{cursor:"pointer"}} onClick={()=>this.detialClickHandler(acc.QF_CONSUMER_OFFER_ACCEPTED_PRID)} key={acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}>
                <td>{acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}</td>
                <td>{acc.QF_MERCHANT_OFFER_PRID?acc.QF_MERCHANT_OFFER_PRID:acc.QF_MERCHANT_SERVICE_PRID}</td>
                
                <td>
                {
                    acc.OFFER_CONSUMED
                    ?
                    <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>
                    :
                    <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>
  
                  }
                </td>
                <td>
                  {
                    acc.REFUND_STATUS
                    ?
                    <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>
                    :
                    <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>
  
                  }
                  
                  </td>
                <td>{acc.OFFER_ACCEPTANCE_PIN}</td>
                
                
              </tr>
            )
        })     
          }



          else if(this.state.serviceConsumed==='All'&&this.state.refundStatus==='Yes'){
    
            let all_Yes = this.state.accepted_data.filter(each =>{return(
             each.REFUND_STATUS===1)})
         
             tableData =  all_Yes.map((acc) => {
    
              return (
                <tr style={{cursor:"pointer"}} onClick={()=>this.detialClickHandler(acc.QF_CONSUMER_OFFER_ACCEPTED_PRID)} key={acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}>
                  <td>{acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}</td>
                  <td>{acc.QF_MERCHANT_OFFER_PRID?acc.QF_MERCHANT_OFFER_PRID:acc.QF_MERCHANT_SERVICE_PRID}</td>
                  
                  <td>
                  {
                      acc.OFFER_CONSUMED
                      ?
                      <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>
                      :
                      <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>
    
                    }
                  </td>
                  <td>
                    {
                      acc.REFUND_STATUS
                      ?
                      <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>
                      :
                      <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>
    
                    }
                    
                    </td>
                  <td>{acc.OFFER_ACCEPTANCE_PIN}</td>
                  
                  
                </tr>
              )
          })     
            }
  


            else if(this.state.serviceConsumed==='All'&&this.state.refundStatus==='No'){
    
              let all_No = this.state.accepted_data.filter(each =>{return(
               each.REFUND_STATUS!==1)})
           
               tableData =  all_No.map((acc) => {
      
                return (
                  <tr style={{cursor:"pointer"}} onClick={()=>this.detialClickHandler(acc.QF_CONSUMER_OFFER_ACCEPTED_PRID)} key={acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}>
                    <td>{acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}</td>
                    <td>{acc.QF_MERCHANT_OFFER_PRID?acc.QF_MERCHANT_OFFER_PRID:acc.QF_MERCHANT_SERVICE_PRID}</td>
                    
                    <td>
                    {
                        acc.OFFER_CONSUMED
                        ?
                        <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>
                        :
                        <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>
      
                      }
                    </td>
                    <td>
                      {
                        acc.REFUND_STATUS
                        ?
                        <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>
                        :
                        <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>
      
                      }
                      
                      </td>
                    <td>{acc.OFFER_ACCEPTANCE_PIN}</td>
                    
                    
                  </tr>
                )
            })     
              }


  

              else if(this.state.serviceConsumed==='Yes'&&this.state.refundStatus==='All'){
    
                let yes_All = this.state.accepted_data.filter(each =>{return(
                 each.OFFER_CONSUMED===1)})
             
                 tableData =  yes_All.map((acc) => {
        
                  return (
                    <tr style={{cursor:"pointer"}} onClick={()=>this.detialClickHandler(acc.QF_CONSUMER_OFFER_ACCEPTED_PRID)} key={acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}>
                      <td>{acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}</td>
                      <td>{acc.QF_MERCHANT_OFFER_PRID?acc.QF_MERCHANT_OFFER_PRID:acc.QF_MERCHANT_SERVICE_PRID}</td>
                      
                      <td>
                      {
                          acc.OFFER_CONSUMED
                          ?
                          <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>
                          :
                          <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>
        
                        }
                      </td>
                      <td>
                        {
                          acc.REFUND_STATUS
                          ?
                          <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>
                          :
                          <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>
        
                        }
                        
                        </td>
                      <td>{acc.OFFER_ACCEPTANCE_PIN}</td>
                      
                      
                    </tr>
                  )
              })     
                }

     
             

                else if(this.state.serviceConsumed==='No'&&this.state.refundStatus==='All'){
    
                  let no_All = this.state.accepted_data.filter(each =>{return(
                   each.OFFER_CONSUMED!==1)})
               
                   tableData =  no_All.map((acc) => {
          
                    return (
                      <tr style={{cursor:"pointer"}} onClick={()=>this.detialClickHandler(acc.QF_CONSUMER_OFFER_ACCEPTED_PRID)} key={acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}>
                        <td>{acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}</td>
                        <td>{acc.QF_MERCHANT_OFFER_PRID?acc.QF_MERCHANT_OFFER_PRID:acc.QF_MERCHANT_SERVICE_PRID}</td>
                        
                        <td>
                        {
                            acc.OFFER_CONSUMED
                            ?
                            <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>
                            :
                            <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>
          
                          }
                        </td>
                        <td>
                          {
                            acc.REFUND_STATUS
                            ?
                            <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>
                            :
                            <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>
          
                          }
                          
                          </td>
                        <td>{acc.OFFER_ACCEPTANCE_PIN}</td>
                        
                        
                      </tr>
                    )
                })     
                  }
  
           
                  else if(this.state.serviceConsumed==='Yes'&&this.state.refundStatus==='No'){
    
                    let yesNo = this.state.accepted_data.filter(each =>{return(
                     each.OFFER_CONSUMED===1&&each.REFUND_STATUS!==1)})
                 
                     tableData =  yesNo.map((acc) => {
            
                      return (
                        <tr style={{cursor:"pointer"}} onClick={()=>this.detialClickHandler(acc.QF_CONSUMER_OFFER_ACCEPTED_PRID)} key={acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}>
                          <td>{acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}</td>
                          <td>{acc.QF_MERCHANT_OFFER_PRID?acc.QF_MERCHANT_OFFER_PRID:acc.QF_MERCHANT_SERVICE_PRID}</td>
                          
                          <td>
                          {
                              acc.OFFER_CONSUMED
                              ?
                              <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>
                              :
                              <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>
            
                            }
                          </td>
                          <td>
                            {
                              acc.REFUND_STATUS
                              ?
                              <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>
                              :
                              <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>
            
                            }
                            
                            </td>
                          <td>{acc.OFFER_ACCEPTANCE_PIN}</td>
                          
                          
                        </tr>
                      )
                  })     
                    }


                
                    else if(this.state.serviceConsumed==='No'&&this.state.refundStatus==='Yes'){
    
                      let noYes = this.state.accepted_data.filter(each =>{return(
                       each.OFFER_CONSUMED!==1&&each.REFUND_STATUS===1)})
                   
                       tableData =  noYes.map((acc) => {
              
                        return (
                          <tr style={{cursor:"pointer"}} onClick={()=>this.detialClickHandler(acc.QF_CONSUMER_OFFER_ACCEPTED_PRID)} key={acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}>
                            <td>{acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}</td>
                            <td>{acc.QF_MERCHANT_OFFER_PRID?acc.QF_MERCHANT_OFFER_PRID:acc.QF_MERCHANT_SERVICE_PRID}</td>
                            
                            <td>
                            {
                                acc.OFFER_CONSUMED
                                ?
                                <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>
                                :
                                <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>
              
                              }
                            </td>
                            <td>
                              {
                                acc.REFUND_STATUS
                                ?
                                <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>
                                :
                                <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>
              
                              }
                              
                              </td>
                            <td>{acc.OFFER_ACCEPTANCE_PIN}</td>
                            
                            
                          </tr>
                        )
                    })     
                      }

       
       
       
       
       
          else{
        tableData = <p >no data</p>
      }

    return (
      
        <Col >
        {
          this.state.accepted_data[0].QF_MERCHANT_SERVICE_PRID!==null?
          <h5 style={{marginBottom:40,marginTop:40}}>Bookings for Service-Id {this.props.location.state.serviceId} :</h5>
          :
          <h5 style={{marginBottom:40,marginTop:40}}>Bookings for Offer-Id {this.props.location.state.offerId} :</h5>
        }
        <Table hover>
        <thead style={{backgroundColor:'#2C2165',color:"white", fontFamily:'Muli'}}>
          <tr>
            <th> Booking ID </th>
            <th> {this.state.accepted_data[0].QF_MERCHANT_SERVICE_PRID!==null?'Service ID':'Offer ID'} </th>
            <th style={{width: 250}}> Service Consumed <Input type='select' 
            style={{width: 70, height: 28, float: 'right',fontSize: '12px'}} 
            onChange={this.serviceConsumedHandler}
            value={this.state.serviceConsumed}
            >
                                      <option value='All'>All</option>
                                      <option value='Yes'>Yes</option>
                                      <option  value='No'>No</option>                                      
                                  </Input></th>
            <th style={{width: 220}}> Refund Status  <Input type='select' style={{width: 70, height: 28, float: 'right',fontSize: '12px'}}
                                    onChange={this.refundStatusHandler}
                                    value={this.state.refundStatus}>
                                      <option value='All'>All</option>
                                      <option value='Yes'>Yes</option>
                                      <option  value='No'>No</option>    
                                </Input></th>
            <th> Acceptance Pin </th>
            
            
            
          </tr>
        </thead>
        <tbody style={{fontFamily:"Muli", color: '#563951',fontWeight:'bold',}}>
          {tableData}
        </tbody>
      </Table>
      </Col>
    );

  }
}

export default BookingTabView;