import React,{Component} from "react";
import { Container, Col, Row, Card, CardText, CardHeader, Table, Label,Input,Modal,ModalBody,ModalFooter,ModalHeader,Button,CardBody } from "reactstrap";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import "../../../node_modules/react-toastify/dist/ReactToastify.css";
import { BarLoader } from "react-spinners";
import BreadCrumbs from '../Navbar/Breadcrumb';
import Curr from 'currency.js';
import { connect } from "react-redux";
import moment from 'moment';
import { AiOutlineCalendar } from "react-icons/ai";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { FaTrashAlt} from 'react-icons/fa';
const localizer = momentLocalizer(moment)
class MerchantSearchDetails extends Component {
    state = {
        merchantDetails: {
            
            id: this.props.location.state.id,
            name: null,
            email: null,
            phone: null,
            address1: null,
            address2: null,
            city: null,
            state: null,
            zip: null,
            country: null,
            legalName: null,
            // legalEntity: null,
            tz: null,
            account_number:null
            
        },
        MLocation:[],
        legalEntity: [],
        list:[],
        loading: false,
        merchantPersonnel: [],
        offerCount: null,
        offerAccepted: null,
        offerConsumed: null,
        offerCancelled: null,
        flagDetails: [],
        offerDet:[],
        offerConsumeDet:[],
        merchantVerDet:[],
        offerCancelDet:[],
        disputes:null,
        couponCount: null,
        showBusinessHrsModal:false,
        businessHrsArray:[],
        serviceCreated: null,
        merchantAutoOff:null,
        active: this.props.location.state.active,
        active0: this.props.location.state.active0 ? this.props.location.state.active0 : null,
        active0Link: this.props.location.state.active0Link ? this.props.location.state.active0Link : null,
        active1: this.props.location.state.active1 ? this.props.location.state.active1 : null,
        active1Link: this.props.location.state.active1Link ? this.props.location.state.active1Link : null,
        active2: this.props.location.state.active2 ? this.props.location.state.active2 : null,
        active2Link: this.props.location.state.active2Link ? this.props.location.state.active2Link : null,
        active3: this.props.location.state.active3 ? this.props.location.state.active3 : null,
        active3Link: this.props.location.state.active3Link ? this.props.location.state.active3Link : null,
        active4: this.props.location.state.active4 ? this.props.location.state.active4 : null,
        active4Link: this.props.location.state.active4Link ? this.props.location.state.active4Link : null,
        active5: this.props.location.state.active5 ? this.props.location.state.active5 : null,
        active5Link: this.props.location.state.active5Link ? this.props.location.state.active5Link : null,
        active6: this.props.location.state.active6 ? this.props.location.state.active6 : null,
        active6Link: this.props.location.state.active6Link ? this.props.location.state.active6Link : null,
        active7: this.props.location.state.active7 ? this.props.location.state.active7 : null,
        active7Link: this.props.location.state.active7Link ? this.props.location.state.active7Link : null,
        active8: this.props.location.state.active8 ? this.props.location.state.active8 : null,
        active8Link: this.props.location.state.active8Link ? this.props.location.state.active8Link : null,
        active9: this.props.location.state.active9 ? this.props.location.state.active9 : null,
        active9Link: this.props.location.state.active9Link ? this.props.location.state.active9Link : null,
        paymentCount:null,
        DispCount:null,
        loginDetails:'',
        clickCalendar:false,
        dateObj:{},
        singleEventDetModal:false,
        singleEventDet:'',
    }

    componentDidMount() {
        // console.log("props",this.props)
        this.setState({
            ...this.state,
            loading: true           
        })
        // let mpid=this.props.location.state.id;

        

        let id = this.props.location.state.id;
        // console.log("Component Did mount", id);

        axios.post("/support/consumer/merchantname/merchantDetails", {id})
            .then((res) => {    
                if(res.data.data){
                     console.log("Merchant Name", res.data)
                    
                     this.setState({
                        ...this.state,
                        // loading: false,
                        merchantDetails: {
                            ...this.state.merchantDetails,
                            name: res.data.result[0].MERCHANT_NAME,
                            legalName: res.data.result[0].MERCHANT_LEGAL_NAME,
                            // legalEntity: res.data.result[0].MERCHANT_LEGAL_ENTITY_TYPE,
                            tz: res.data.result[0].MERCHANT_TIMEZONE,
                            account_number:res.data.result[0].MERCHANT_STRIPE_CONNECT_ID
                        },
                        merchantAutoOff:res.data.result1 ? res.data.result1.length !== 0 ?res.data.result1[0].MERCHANT_CREATE_OFFER_AUTO : null :null,
                        merchantPersonnel: res.data.result1.length!==0?res.data.result1:[],
                        offerCount: res.data.offerCount?res.data.offerCount:null,
                        offerAccepted: res.data.offerAccepted?res.data.offerAccepted:null,
                        offerConsumed: res.data.offerConsumed?res.data.offerConsumed:null,
                        offerCancelled: res.data.cancelledCounts?res.data.cancelledCounts:null,
                        offerCancelDet:res.data.offerCancelledDet?res.data.offerCancelledDet:null,
                        merchantVerDet:res.data.merchantAccver?res.data.merchantAccver:[],
                        offerDet:res.data.offerAcceptedDet?res.data.offerAcceptedDet:null,
                        offerConsumeDet:res.data.offerConsumedDet?res.data.offerConsumedDet:null,
                        flagDetails: res.data.flagDetails?res.data.flagDetails:null,
                        serviceCreated: res.data.serviceCreated ?res.data.serviceCreated:null,
                        couponCount: res.data.couponCount ? res.data.couponCount.length !== 0 ? res.data.couponCount[0].couponCount : null : null,
                        loginDetails:res.data.loginDetails.length!==0?res.data.loginDetails:[],
                        loading: false

                    });

                    // console.log('res.data.merchantAccver',res.data.merchantAccver)
                    // let mpid=this.state.merchantPersonnel.length!==0?this.state.merchantPersonnel[0].QF_MERCHANT_PERSONNEL_PRID:[];
                    let mpid= res.data.result1.length!==0?res.data.result1[0].QF_MERCHANT_PERSONNEL_PRID:null;
                    return axios.post("/support/merchantpayments/payments/count", {mpid})
                    .then((res) => {
                        // console.log("Coupon res", res.data);
                        if(res.data.data){
            
                                           this.setState({
                                ...this.state,
                                paymentCount:res.data.details[0].payment_count,
                                
                                loading: false
            
                            })
                            return axios.post("/support/merchant/disputes", {mpid})
                            .then((res) => {
                                // console.log("dispute res", res.data);
                                if(res.data.dis){
                                   
                    
                                                   this.setState({
                                        ...this.state,
                                        disputes: res.data.details,
                                        DispCount:res.data.details.length,
                                        // loc: res.data.resultQ[0].MERCHANT_ADDRESS_COUNTRY,
                                        // activeToggle: toggle,
                                        loading: false
                    
                                    })
                                } else {
                                    if(res.data.dbError)
                                        {
                                            toast.error("Internal Server Error1",{
                                                position:toast.POSITION.BOTTOM_CENTER
                                            })
                                            this.setState({
                                                ...this.state,
                                                transactions: [],
                                                // disputes: [],
                                                loading: false
                                        });
                                        }
                                        else {
                                            this.setState({
                                                ...this.state,
                                                // transactions: [],
                                                 disputes: [],
                                                loading: false
                            
                                            })
                                        }          
                                }
                            })
                            .catch(err => {
                                console.log(err);
                                toast.warn("Unable2 to Reach Server! Please Try Again!" , {
                                    position: toast.POSITION.BOTTOM_CENTER
                                  });
                                this.setState({
                                    ...this.state,
                                    disputes: [],
                                    loading: false
                            });
                            })
                        } else {
                            if(res.data.dbError)
                                {
                                    toast.error("Internal Server Error2",{
                                        position:toast.POSITION.BOTTOM_CENTER
                                    })
                                    this.setState({
                                        ...this.state,
                                        loading: false
                                });
                                }
                                else {
                                    this.setState({
                                        ...this.state,
                                        loading: false
                    
                                    })
                                }          
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        toast.warn("Unable to Reach Server! Please Try Again!" , {
                            position: toast.POSITION.BOTTOM_CENTER
                          });
                        this.setState({
                            ...this.state,
                            transactions:[],
                            disputes:[],
                            loading: false
                    });
                    })
                } else {
                    if(res.data.dbError){
                        this.setState({
                            merchantDetails: [],
                            loading: false                
                        });
                        toast.error("Internal Server Error3", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    } else {
                        this.setState({
                            merchantDetails: [],
                            merchantPersonnel: [],
                            offerCount:[],
                            offerAccepted: [],
                            offerConsumed: [],
                            flagDetails: [],
                            serviceCreated: [],
                            loading: false               
                        });
                        toast.info("No Data Found", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    }
                }         
                
            })
            .catch((err) => {
                console.log('e1',err);
                toast.warn("Unable to Reach Server! Please Try Again!" , {
                    position: toast.POSITION.BOTTOM_CENTER
                  });
                  this.setState({
                      ...this.state,
                      loading: false
                  })
            });


            axios.post("/support/merchant/LegalNameFetch",{id})
            .then((res) => {
             if(res.data.data){
                 if(res.data.results.length !== 0){


                     this.setState({
                         ...this.state,
                         legalEntity: res.data.results, 
                         loading: false
                     });
                     
                    } else if(res.data.results.length === 0){ 
                        this.setState({
                            ...this.state,
                            legalEntity: [],
                            loading: false
                        });
                        
                    }
                }
                else {
                    if(res.data.dbError){
                        toast.error("Internal Server Error!",{
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        this.setState({
                            ...this.state,
                            loading: false
                        });
                    } else { 
                        this.setState({
                            ...this.state,
                            legalEntity: [],
                            loading: false
                        });
                    }
                    
                    
                }
                
            })
            .catch((err) => {
                console.log('err',err)
                toast.warn("Unable to Reach Server! Please Try Again!" , {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.setState({
                    ...this.state,
                    loading: false
                });
            }) 
                
                    
        // console.log('main',this.state)
    }

    offerDetailsHandler=(id)=>{
        let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
        this.props.history.push({
            pathname:url+'/OfferIdSearchDetails',
            state:{
                condata:this.props.location.state.condata,
                id: id,
                url,
                active: this.state.active,
                active0: this.state.active0,
                active0Link: this.state.active0Link,
                active1: this.state.active1,
                active1Link: this.state.active1Link,
                active2: this.state.active2 ,
                active2Link: this.state.active2Link,
                active3: this.state.active3,
                active3Link: this.state.active3Link,
                active4: this.state.active4,
                active4Link: this.state.active4Link,
                active5: this.state.active5,
                active5Link: this.state.active5Link,
                active6: this.state.active6,
                active6Link: this.state.active6Link,
                active7: this.state.active7,
                active7Link: this.state.active7Link ,
                active8: this.state.active8 ,
                active8Link: this.state.active8Link,
                active9: this.state.active9,
                active9Link: this.state.active9Link,            
        }});
    }


    bookingDetailsHandler =(id) => {
        let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
        this.props.history.push({
            pathname: url+'/BookingIdSearch',
            state:{
                condata:this.props.location.state.condata,
                booking_id: id,
                url,
                active: this.state.active,
                active0: this.state.active0,
                active0Link: this.state.active0Link,
                active1: this.state.active1,
                active1Link: this.state.active1Link,
                active2: this.state.active2 ,
                active2Link: this.state.active2Link,
                active3: this.state.active3,
                active3Link: this.state.active3Link,
                active4: this.state.active4,
                active4Link: this.state.active4Link,
                active5: this.state.active5,
                active5Link: this.state.active5Link,
                active6: this.state.active6,
                active6Link: this.state.active6Link,
                active7: this.state.active7,
                active7Link: this.state.active7Link ,
                active8: this.state.active8 ,
                active8Link: this.state.active8Link,
                active9: this.state.active9,
                active9Link: this.state.active9Link,            
        }});
    }

    showOffersHandler=()=>{
        let url=this.props.location.state.url ? this.props.location.state.url : this.props.match.url;
        this.props.history.push({
            pathname: url+'/MerchantCreatedOffer',
            state:{
                id:this.state.merchantPersonnel[0].QF_MERCHANT_PERSONNEL_PRID,
                name:this.state.merchantDetails.name,
                tz : this.state.merchantDetails.tz,
                url
               } 
            });
    }

    showServicesHandler=()=>{
        let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
        this.props.history.push({
            pathname:url+'/ServicesCreated',
            state:{
                id:this.state.merchantPersonnel[0].QF_MERCHANT_PERSONNEL_PRID,
                name:this.state.merchantDetails.name,
                url
               } 
            });
    }

    showCouponHandler=()=>{
        let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
        this.props.history.push({
            pathname: url+'/MerchantCreatedCoupon',
            state:{
                id:this.state.merchantPersonnel[0].QF_MERCHANT_PERSONNEL_PRID,
                m_id: this.state.merchantDetails.id,
                name:this.state.merchantDetails.name,
                tz: this.state.merchantDetails.tz,
                url
               } 
            });
    }
    showPaymentHandler=()=>{
        let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
        this.props.history.push({
            pathname: url+'/MerchantPayments',
            state:{
                id:this.state.merchantPersonnel[0].QF_MERCHANT_PERSONNEL_PRID,
                m_id: this.state.merchantDetails.id,
                name:this.state.merchantDetails.name,
                tz : this.state.merchantDetails.tz,
                country:this.state.merchantPersonnel[0].MERCHANT_PERSONNEL_ADDRESS_COUNTRY,
                url
               } 
            });
    }
    showDisputedHandler=()=>{
        let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
        this.props.history.push({
            pathname: url+'/DisputedBookings',
            state:{
                id:this.state.merchantPersonnel[0].QF_MERCHANT_PERSONNEL_PRID,
                m_id: this.state.merchantDetails.id,
                name:this.state.merchantDetails.name,
                tz : this.state.merchantDetails.tz,
                country:this.state.merchantPersonnel[0].MERCHANT_PERSONNEL_ADDRESS_COUNTRY,
                url
               } 
            });
    }
    bookingsHandler=()=>{
        let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
        this.props.history.push({
            pathname: url+'/MerchantBookings',
            state:{
                accepted_data:this.state.offerDet,
                url
               } 
            });




    }

    CancelsHandler=()=>{
        let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
        this.props.history.push({
            pathname: url+'/MerchCancellationsView',
            state:{
                accepted_data:this.state.offerCancelDet,
                url
               } 
            }); 

    }
    consumeHandler=()=>{
        let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
        this.props.history.push({
            pathname: url+'/MerchBookingConsumedView',
            state:{
                accepted_data:this.state.offerConsumeDet,
                url
               } 
            });

    }
    showBusinessHrsHandler=()=>{
        let body={
            id:this.state.merchantPersonnel[0].QF_MERCHANT_PERSONNEL_PRID 
        }
    
            this.setState({
                showBusinessHrsModal:true,
                loading2:true
            })
    
            axios.post("/support/merchant/businesshours",body)
            .then((res) => {
             if(res.data.data){
                 if(res.data.businessdetails.length !== 0){
                    res.data.businessdetails.map(item=>{
                        if(item.QF_CAL_EVENT_NAME=== "mon"){
                             return item.QF_CAL_EVENT_NAME='Monday';
                         }
                         if(item.QF_CAL_EVENT_NAME=== "tue"){
                             return item.QF_CAL_EVENT_NAME='Tuesday';
                         }
                         if(item.QF_CAL_EVENT_NAME=== "wed"){
                             return item.QF_CAL_EVENT_NAME='Wednesday';
                         }
                         if(item.QF_CAL_EVENT_NAME=== "thur"){
                             return item.QF_CAL_EVENT_NAME='Thursday';
                         }
                         if(item.QF_CAL_EVENT_NAME=== "fri"){
                             return  item.QF_CAL_EVENT_NAME='Friday';
                         }
                         if(item.QF_CAL_EVENT_NAME=== "sat"){
                             return item.QF_CAL_EVENT_NAME='Saturday';
                            //  return{ label:item.QF_CAL_EVENT_NAME='Satuarday',value:item.QF_CAL_AVAILABILITY_PRID};
                         }
                         if(item.QF_CAL_EVENT_NAME=== "sun"){
                             return item.QF_CAL_EVENT_NAME='Sunday'
                            
                         }
                    
                            })
                        this.setState({
                            ...this.state,
                            businessHrsArray: res.data.businessdetails, 
                            loading2:false
                        });
                        
                    
                    
                 } else if(res.data.businessdetails.length === 0){ 
                     this.setState({
                         ...this.state,
                         businessHrsArray: [],
                         loading2: false
                     });
         
                 }
             }
             else {
                 if(res.data.dbError){
                     toast.error("Internal Server Error!",{
                         position: toast.POSITION.BOTTOM_CENTER
                     });
                     this.setState({
                         ...this.state,
                         loading: false
                     });
                 } else { 
                     this.setState({
                         ...this.state,
                         businessHrsArray: [],
                         loading: false
                     });
                 }
                   
             
             }
            
         })
         .catch((err) => {
             toast.warn("Unable to Reach Server! Please Try Again!" , {
                 position: toast.POSITION.BOTTOM_CENTER
               });
             this.setState({
                 ...this.state,
                 loading: false
             });
         })
    
    }
    showModalCancel1=()=>{
        this.setState({
            ...this.state,
            showBusinessHrsModal:false,
            showModalValue : null
        })
    }
    serviceDetailHandler=(id)=>{
        let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
            this.props.history.push({
                pathname:url+"/ServiceDetails",
                state:{
                    id: id,
                    url          
            }});
    }
    clickCalendarView = () =>{
        this.setState({
            clickCalendar:true
        })
        let body ={
            mpid:this.state.merchantPersonnel[0].QF_MERCHANT_PERSONNEL_PRID,
            timezone:this.state.merchantDetails.tz
        }
        // console.log('body',body)
        axios.post('/support/calendar/view/v2',body)
        .then(resCal =>{
            console.log('res',resCal)
            if(resCal.data.cal){
                if(resCal.data.dateObj.length !==0){
                    this.setState({
                        dateObj:resCal.data.dateObj
                    })
                }else{
                    this.setState({
                        dateObj:{}
                    })
                }
            }else{
                if(resCal.data.dbError){
                    toast.error("Internal Server Error!",{
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                } else { 
                    this.setState({
                        ...this.state,
                        dateObj:{},
                        loading: false
                    });
                }
            }
        })
        .catch(errorc=>{
            toast.warn("Unable to Reach Server! Please Try Again!" , {
                position: toast.POSITION.BOTTOM_CENTER
              });
            this.setState({
                ...this.state,
                loading: false
            });
        })
    }
    calendarCancelModal = () =>{
        this.setState({
            clickCalendar:false
        })
    }
    onClickEvent = (event) =>{
        console.log('double click',event);
        let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
        if(event.bookingTrue){
            this.props.history.push({
                pathname:url+"/BookingIdSearch",
                state:{
                    condata:this.props.location.state.condata,
                    booking_id: event.id,
                    url,            
            }});
        }else{
            this.setState({
                singleEventDet:event,
                singleEventDetModal:true
            })
        }
    }
    eventDeletionHandler = () => {
        let delData=this.state.singleEventDet;
        console.log('data',delData)
        let body ={
            date:delData.eventDate?moment.tz(delData.eventDate,this.state.merchantDetails.tz).utc().format('YYYY-MM-DD'):moment().format('YYYY-MM-DD'),
            multiDel:false,
            mpid:this.state.merchantPersonnel[0].QF_MERCHANT_PERSONNEL_PRID,
            calPrid:delData.id
        }
        console.log('body in vacation',body)
        if(delData.vacation){
            axios.post('/support/cal/vacation/delete',body)
            .then(res=>{
                console.log('res',res.data)
                if(res.data.update){
                    toast.success("Event Deleted Successfully" , {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.setState({
                        singleEventDetModal:false,eventDeleteModal:false
                    })
                }
                this.clickCalendarView();
            })
            .catch(error=>{
                toast.warn("Unable to Reach Server! Please Try Again!" , {
                    position: toast.POSITION.BOTTOM_CENTER
                  });
                this.setState({
                    ...this.state,
                    loading: false
                });
            })
        }else{
            axios.post('/support/cal/outOfOffice/delete',body)
            .then(res=>{
                console.log('res',res.data)
                if(res.data.update){
                    toast.success("Event Deleted Successfully" , {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.setState({
                        singleEventDetModal:false,eventDeleteModal:false
                    })
                }
                this.clickCalendarView();
            })
            .catch(error=>{
                toast.warn("Unable to Reach Server! Please Try Again!" , {
                    position: toast.POSITION.BOTTOM_CENTER
                  });
                this.setState({
                    ...this.state,
                    loading: false
                });
            })
        }
    }
    deleteEventModal = () => {
        this.setState({
            eventDeleteModal : true
        })
    }
    render() {
    console.log("State", this.state);
        
        const rowStyle={
            textAlign:"initial"
        }

        const fWeightSpan={
            fontWeight:"500",
            marginRight: 5,
            fontFamily: 'Muli',
            color: '#868686'
        }
        const fontText = {
            // color:'red',
            // fontWeight: 'bold'            
        }
        const fontText1 = {
            fontWeight:'bold',
            fontFamily: 'Muli',
            color: '#563951'                        
        }

        const LinkedText = {
            textDecoration:'underline',
            cursor:'pointer',
            fontWeight:'bold',
            textTransform: 'uppercase',
            fontFamily: 'Muli',
            // marginLeft: 5,
            color: '#563951'
        }
        const textDecor = {
            fontWeight:"bold",
            fontFamily: 'Muli',
            // marginLeft: 5,
            color: '#563951'
        }

        let mp=this.state.merchantPersonnel ,ud=this.state.merchantVerDet;
        // console.log('mpp',this.state.merchantPersonnel)
        let showBusinessHrsModal=null
        if(this.state.businessHrsArray.length!==0){
            
            showBusinessHrsModal=(
                <Modal isOpen={this.state.showBusinessHrsModal} size='x1'>
                    <ModalHeader><text>Business Hours</text> </ModalHeader>
                    <ModalBody>
                    <Card style={{marginTop: 10, backgroundColor: "#E1E1E1"}}>
                        <CardBody>
                            <CardText>
                                {/* <ul>
                                    {this.state.businessHrsArray.map((item)=>{
                                        return(
                                            <li key={item}>{item.QF_CAL_EVENT_NAME}
                                            {item.QF_CAL_AVAILABLE_DAY=1?item.QF_CAL_FROM_TIME_HOURS item.QF_CAL_TO_TIME_HOURS:'not available'}
                                            </li>
                                        ) 
                                    })}
                                </ul> */}
                                <Table>
                                <thead style={{marginTop: 20,backgroundColor:'#2C2165',color:"white", fontFamily:'Muli'}}>
                                <tr>
                                    {/* <th>S.NO</th> */}
                                    <th>Day Name</th>
                                    <th>Starting Time</th>
                                    <th>Ending Time</th>
                                </tr>
                                </thead>
                                <tbody style={{fontFamily:"Muli", color: '#563951',fontWeight:'bold',rowGap:50,borderSpacing:30}}>
                               {this.state.businessHrsArray.map((item)=>{
                                return <tr key={item.QF_CAL_AVAILABILITY_PRID}>
                                    <td>{item.QF_CAL_EVENT_NAME}</td>
                                    <td>{item.QF_CAL_FROM_TIME_HOURS && item.QF_CAL_AVAILABLE_DAY===0 ?'Not Available':item.QF_CAL_FROM_TIME_HOURS<=9?"0"+item.QF_CAL_FROM_TIME_HOURS + " " +":"+  " " +(item.QF_CAL_FROM_TIME_MINS>9?item.QF_CAL_FROM_TIME_MINS:item.QF_CAL_FROM_TIME_MINS+"0"):item.QF_CAL_FROM_TIME_HOURS + " " +":"+  " " +(item.QF_CAL_FROM_TIME_MINS>9?item.QF_CAL_FROM_TIME_MINS:item.QF_CAL_FROM_TIME_MINS+"0")}</td>
                                    <td>{item.QF_CAL_TO_TIME_HOURS && item.QF_CAL_AVAILABLE_DAY===0 ?'Not Available':item.QF_CAL_TO_TIME_HOURS<=9?"0"+item.QF_CAL_TO_TIME_HOURS + " " +":"+  " " +(item.QF_CAL_TO_TIME_MINS>9?item.QF_CAL_TO_TIME_MINS:item.QF_CAL_TO_TIME_MINS+"0"):item.QF_CAL_TO_TIME_HOURS + " " +":"+  " " +(item.QF_CAL_TO_TIME_MINS>9?item.QF_CAL_TO_TIME_MINS:item.QF_CAL_TO_TIME_MINS+"0")}</td>
                                    {/* item.QF_CAL_TO_TIME_HOURS + " "  +":"+  " " +(item.QF_CAL_TO_TIME_MINS>9?item.QF_CAL_TO_TIME_MINS:item.QF_CAL_TO_TIME_MINS+"0")}</td> */}
                                    {/* <td>{item.QF_CAL_FROM_TIME_HOURS && item.QF_CAL_AVAILABLE_DAY===0 ?'Not Available':item.QF_CAL_FROM_TIME_HOURS + " " +":"+  " " +item.QF_CAL_FROM_TIME_MINS}</td>
                                    <td>{item.QF_CAL_TO_TIME_HOURS && item.QF_CAL_AVAILABLE_DAY===0 ?'Not Available':item.QF_CAL_TO_TIME_HOURS + " "  +":"+  " " +item.QF_CAL_TO_TIME_MINS}</td> */}
                                </tr>
                               })}
    
                                </tbody>
                                </Table>
                                
                            </CardText>
                        </CardBody>
                    </Card>
                    </ModalBody>
                    <ModalFooter>
                    <Button color="warning" onClick={this.showModalCancel1}>Cancel</Button>
        
                    </ModalFooter>
                </Modal>
            )
    
        }
        else{
            showBusinessHrsModal=(
                <Modal isOpen={this.state.showBusinessHrsModal} size='x1'>
                    <ModalHeader><text>Business Hours</text> </ModalHeader>
                    <ModalBody>
                    <Card style={{marginTop: 10, backgroundColor: "#E1E1E1"}}>
                        <CardBody>
                            <CardText>
                                <p>As of now ,This Professional don't have any Business Hours</p>
                            </CardText>
                        </CardBody>
                    </Card>
                    </ModalBody>
                    <ModalFooter>
                    <Button color="warning" onClick={this.showModalCancel1}>Cancel</Button>
        
                    </ModalFooter>
                </Modal>
            );
            }
      
        let mDetails = null;
        if(this.state.merchantDetails.length!== 0){
            mDetails = (
                <Card style={{border:"1px solid #a78bba"}}>
                        <Row className="mb-4" style={{paddingLeft:"15px",paddingRight:"15px"}}>
                            <Col sm={{size:12}} style={{fontWeight:"",fontSize:"22px",color:"#fff",backgroundColor:"#2C2165", fontFamily:'Muli'}}>
                            <CardHeader style={{fontSize:"18px",color:"#fff",backgroundColor:"#2C2165",fontFamily:"Muli"}}>Professional Details 
                            <span><AiOutlineCalendar style={{cursor: 'pointer',float:"right",}} color='#fff' size={26} onClick={()=>this.clickCalendarView()}/></span>
                            </CardHeader>
                            </Col>
                        </Row>
                        {/* <CardHeader style={{fontWeight:"",fontSize:"18px",color:"#fff",backgroundColor:"#2C2165",fontFamily: 'Muli'}}>Professional Details</CardHeader> */}
                        <CardText style={{padding:"25px"}}>

                            <Row className="mb-4" style={rowStyle}>

                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Professional Name: </span> <text style={textDecor}>{this.state.merchantDetails.name}</text>  </text>
                                </Col>
                                
                                {/* <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}> Email Id:</span> <text style={textDecor}>{this.state.merchantPersonnel.length !==0 ? this.state.merchantPersonnel[0].MERCHANT_PERSONNEL_EMAIL : <text style={fontText}>---</text> }</text> </text>
                                </Col>                             
                                    
                                <Col className="col-3">
                                    <text style={fontText1}><span style={fWeightSpan}>Mobile Number :</span> <text style={textDecor}>{this.state.merchantPersonnel.length !==0 ? this.state.merchantPersonnel[0].MERCHANT_PERSONNEL_PHONE : <text style={fontText}>---</text> }</text> </text>
                                </Col>                          */}
                                


                           { (mp.length!==0) ?
                           
                           <Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}>Email:</span> {mp[0].MERCHANT_PERSONNEL_EMAIL!==null?mp[0].MERCHANT_PERSONNEL_EMAIL:"Not updated"}</text> 

                            {mp[0].MERCHANT_PERSONNEL_EMAIL!==null ?
                                ud.length!==0?
                                    ud[0].EMAIL_VERIFIED_FLAG===1 ?
                                        <span style={{backgroundColor:"#3DC5B6",padding:"5px",color:"#fff",borderRadius:"3px"}}>Verified</span>
                                        :
                                        <span style={{backgroundColor:"#F86C69",padding:"5px",color:"#fff",borderRadius:"3px"}}>Not-Verified</span>
                                : <span style={{backgroundColor:"#F86C69",padding:"5px",color:"#fff",borderRadius:"3px"}}>Not-Verified</span>
                            :null} 
                               
                             
                             
                        </Col>:null} 
                         { mp.length>0? <Col className="col-4">
                                <text style={fontText1}><span style={fWeightSpan}>Mobile Number:</span>  
                                {/* <text>{ mp[0].MERCHANT_PERSONNEL_ADDRESS_COUNTRY === "United States" ? '+1 ' : '+91 '}</text>{mp[0].MERCHANT_PERSONNEL_PHONE!==null?mp[0].MERCHANT_PERSONNEL_PHONE:"Not updated"} */}
                               <text> {mp[0].MERCHANT_PERSONNEL_PHONE_COUNTRY_CODE?mp[0].MERCHANT_PERSONNEL_PHONE_COUNTRY_CODE+' ':mp[0].MERCHANT_PERSONNEL_PHONE!==null?mp[0].MERCHANT_PERSONNEL_PHONE_COUNTRY_CODE:null}{mp[0].MERCHANT_PERSONNEL_PHONE!==null ?  <text>{mp[0].MERCHANT_PERSONNEL_PHONE.toString().substring(0,3)}-{mp[0].MERCHANT_PERSONNEL_PHONE.toString().substring(3,6)}-{mp[0].MERCHANT_PERSONNEL_PHONE.toString().substring(6,10)}</text> : "Not updated"}</text>

                                </text> 
                               
                                {mp[0].MERCHANT_PERSONNEL_PHONE!==null ?
                                ud.length!==0?
                                    ud[0].MOBILE_VERIFIED_FLAG===1 ?
                                        <span style={{backgroundColor:"#3DC5B6",padding:"5px",color:"#fff",borderRadius:"3px"}}>Verified</span>
                                        :
                                        <span style={{backgroundColor:"#F86C69",padding:"5px",color:"#fff",borderRadius:"3px"}}>Not-Verified</span>
                                : <span style={{backgroundColor:"#F86C69",padding:"5px",color:"#fff",borderRadius:"3px"}}>Not-Verified</span>
                            :null}
                               
                               
                                </Col>  :null    }        
                                
                            </Row>

                            <Row className="mb-4" style={rowStyle}>

                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}> Address Line 1:</span> <text style={textDecor}>{this.state.merchantPersonnel.length !==0 ? this.state.merchantPersonnel[0].MERCHANT_PERSONNEL_ADDRESS_LINE2 ? this.state.merchantPersonnel[0].MERCHANT_PERSONNEL_ADDRESS_LINE2 : <text style={fontText}> --- </text> : <text style={fontText}> --- </text> }</text> </text>
                                </Col>
                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}> Address Line 2:</span> <text style={textDecor}>{this.state.merchantPersonnel.length !==0 ? this.state.merchantPersonnel[0].MERCHANT_PERSONNEL_ADDRESS_LINE3 ? this.state.merchantPersonnel[0].MERCHANT_PERSONNEL_ADDRESS_LINE3 : <text style={fontText}> --- </text> : <text style={fontText}> --- </text>}</text> </text>
                                </Col>

                                <Col className="col-3">                                
                                    <text style={fontText1}><span style={fWeightSpan}>City:</span> <text style={textDecor}>{this.state.merchantPersonnel.length !==0 ?  this.state.merchantPersonnel[0].MERCHANT_PERSONNEL_ADDRESS_CITY ? this.state.merchantPersonnel[0].MERCHANT_PERSONNEL_ADDRESS_CITY : <text style={fontText}>---</text> : <text style={fontText}>---</text>}</text></text>
                                </Col>                          

                            </Row>

                            <Row className="mb-4" style={rowStyle}>

                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>State:</span> <text style={textDecor}>{this.state.merchantPersonnel.length !==0 ? this.state.merchantPersonnel[0].MERCHANT_PERSONNEL_ADDRESS_STATE ? this.state.merchantPersonnel[0].MERCHANT_PERSONNEL_ADDRESS_STATE : <text style={fontText}>---</text> : <text style={fontText}>---</text>}</text></text>
                                </Col>
                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}> Zip Code:</span> <text style={textDecor}>{this.state.merchantPersonnel.length !==0 ? this.state.merchantPersonnel[0].MERCHANT_PERSONNEL_ADDRESS_ZIPCODE ? this.state.merchantPersonnel[0].MERCHANT_PERSONNEL_ADDRESS_ZIPCODE : <text style={fontText}>---</text>  : <text style={fontText}>---</text>}</text></text>
                                </Col>
                                <Col className="col-3">
                                    <text style={fontText1}><span style={fWeightSpan}>Country:</span> <text style={textDecor}>{this.state.merchantPersonnel.length !==0 ? this.state.merchantPersonnel[0].MERCHANT_PERSONNEL_ADDRESS_COUNTRY ? this.state.merchantPersonnel[0].MERCHANT_PERSONNEL_ADDRESS_COUNTRY : <text style={fontText}>---</text> : <text style={fontText}>---</text>}</text></text>
                                </Col>

                            </Row>
                            <Row className="mb-4" style={rowStyle}>

                                <Col className="col-4">
                                    {/* <text style={fontText1}><span style={fWeightSpan}>Legal Name:</span> <text style={textDecor}>{ this.state.merchantDetails.legalName != null ? this.state.merchantDetails.legalName : <text style={fontText}>---</text>} </text></text> */}
                                    <text style={fontText1}><span style={fWeightSpan}> Legal Name:</span> <text style={textDecor}>{this.state.merchantPersonnel.length !==0 ? this.state.merchantPersonnel[0].MERCHANT_PERSONNEL_FNAME ? this.state.merchantPersonnel[0].MERCHANT_PERSONNEL_FNAME  : <text style={fontText}>---</text>  : <text style={fontText}>---</text>} {this.state.merchantPersonnel.length !==0 ? this.state.merchantPersonnel[0].MERCHANT_PERSONNEL_LNAME ? this.state.merchantPersonnel[0].MERCHANT_PERSONNEL_LNAME  : null  : null}</text></text>

                                </Col>
                                <Col className="col-4">
                                <text style={fontText1}><span style={fWeightSpan}>Business Type:</span> <text style={textDecor}>{this.state.legalEntity.length !== 0 ? this.state.legalEntity[0].QF_ENTITY_NAME : <text style={fontText}>---</text>}</text></text>
                                    {/* <text style={fontText1}><span style={fWeightSpan}>Legal Entity:</span> <text style={textDecor}>{this.state.merchantDetails.legalEntity != null ? this.state.merchantDetails.legalEntity : <text style={fontText}>---</text>}</text></text> */}
                                </Col>
                                <Col className="col-4">
                                <text style={fontText1}><span style={fWeightSpan}>Last Login Time:</span>{
                                this.state.loginDetails.length !==0 ? 
                                (this.state.loginDetails[0].USER_TOKEN_UPDATED_ON_DATE ? 
                                    moment(this.state.loginDetails[0].USER_TOKEN_UPDATED_ON_DATE+"Z").tz (this.state.merchantDetails.tz ? this.state.merchantDetails.tz : (this.props.country === 'USA'?'America/Los_Angeles' : 'Asia/Kolkata')).format("DD-MMM-YYYY hh:mm A z") 
                                    : '-----') 
                                : '------'}
                                </text>
                 
                            </Col> 
                                 
                        {/* {  
                        ud.length!==0? 
                        
                            <Label>
                                <span style={fWeightSpan}>{' '} <text style={{color: '#b55400',marginRight:30,marginLeft:15}}>Create offers automatically</text> <Input type='checkbox'color="blue" checked={this.state.merchantAutoOff !=null?this.state.merchantPersonnel[0].MERCHANT_CREATE_OFFER_AUTO===1?true:false:this.state.checked1}  disabled={this.state.merchantAutoOff==null}/></span>
                            </Label>
                        :null 
                      } */}
                            </Row>
                            <hr />
                            <Row className="mb-4" style={rowStyle}>

                                {/* <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Offers Created:</span> <text style={textDecor}>{this.state.offerCount ? this.state.offerCount : <text style={fontText}>0</text>}</text></text>
                                        <Label style={{cursor:'pointer', textDecoration:'underline', color: '#b55400',marginLeft:20, fontWeight:'bold',fontFamily: 'Muli'}} onClick={this.showOffersHandler} >Show All Offers</Label>
                                </Col> */}


                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Services Created:</span>{this.state.serviceCreated ? this.state.serviceCreated : <text style={fontText}>0</text> }</text>
                                        <Label style={{cursor:'pointer', textDecoration:'underline', color: '#b55400',marginLeft:20, fontWeight:'bold'}} onClick={this.showServicesHandler}> Show All Services</Label>
                                </Col>   

                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Coupons Created:</span> <text style={textDecor}>{this.state.couponCount ? this.state.couponCount : <text style={fontText}>0</text>}</text></text>
                                    <Label style={{cursor:'pointer', textDecoration:'underline', color: '#b55400',marginLeft:20, fontWeight:'bold',fontFamily: 'Muli'}} onClick={this.showCouponHandler}> Show All Coupons</Label>
                                </Col>
                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Payments processed:</span> <text style={textDecor}>{this.state.paymentCount ? this.state.paymentCount : <text style={fontText}>0</text>}</text></text>
                                    <Label style={{cursor:'pointer', textDecoration:'underline', color: '#b55400',marginLeft:20, fontWeight:'bold',fontFamily: 'Muli'}} onClick={this.showPaymentHandler}> Show All Transactions</Label>
                                </Col>
                                 
                                 
                            </Row>
                            <Row className="mb-4" style={rowStyle}>
                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Bookings Disputed:</span> <text style={textDecor}>{this.state.DispCount? this.state.DispCount : <text style={fontText}>0</text>}</text></text>
                                    <Label style={{cursor:'pointer', textDecoration:'underline', color: '#b55400',marginLeft:20, fontWeight:'bold',fontFamily: 'Muli'}} onClick={this.showDisputedHandler}> Show All Bookings Disputed</Label>
                                </Col>
                                <Col>
                                <text style={fontText1}><span style={fWeightSpan}>Business Hours:</span></text>
                                    <Label style={{cursor:'pointer', textDecoration:'underline', color: '#b55400',marginLeft:20, fontWeight:'bold',fontFamily: 'Muli'}} onClick={this.showBusinessHrsHandler}> Show Business Hours</Label>
                                </Col>

                                </Row>
                            <hr />
                            <Row className="mb-4" style={rowStyle}>

                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Bookings : </span>{this.state.offerAccepted===null||0?0:this.state.offerAccepted>0?<text style={LinkedText} onClick={()=>this.bookingsHandler()}> {this.state.offerAccepted}</text>:this.state.offerAccepted}</text>
                                </Col>

                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Bookings Consumed: </span> {this.state.offerConsumed===null||0?0:this.state.offerConsumed>0?<text style={LinkedText} onClick={()=>this.consumeHandler()}> {this.state.offerConsumed}</text>:this.state.offerConsumed}</text>
                                    
                                </Col>
                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Bookings Cancelled: </span> 
                                    {this.state.offerCancelled===null||0?0:this.state.offerCancelled>0?<text style={LinkedText} onClick={()=>this.CancelsHandler()}> {this.state.offerCancelled}</text>:this.state.offerCancelled}
                                    </text>
                                </Col>

                            </Row>
                            <hr />
                            <Row className="mb-4" style={rowStyle}>

                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Rating: </span> <text style={textDecor}>{this.state.merchantPersonnel.length !== 0 ? this.state.merchantPersonnel[0].MERCHANT_PERSONNEL_RATING ? <text>{Curr(this.state.merchantPersonnel[0].MERCHANT_PERSONNEL_RATING).value.toFixed(1)}</text> : <text style={fontText}>---</text>: null}</text></text>
                                </Col>

                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Flagged:</span> <text style={textDecor}>{this.state.merchantPersonnel.length !== 0 ? this.state.merchantPersonnel[0].MERCHANT_PERSONNEL_FLAG_COUNT ? this.state.merchantPersonnel[0].MERCHANT_PERSONNEL_FLAG_COUNT : <text style={fontText}>--Not Yet--</text> : null }</text></text>
                                </Col>

                                <Col sm={{size: 3,offset:1}}>
                                   </Col>
                            </Row>

                            <hr />                            
                            
                            
                        </CardText>  
                        {this.state.merchantPersonnel.length !== 0 ?
                                this.state.merchantPersonnel[0].MERCHANT_PERSONNEL_FLAG_COUNT ?                                       
                                    <Card>
                                        <CardHeader style={{fontWeight:"",fontSize:"18px",color:"#fff",backgroundColor:"#2C2165"}}>Flagged Details</CardHeader>
                                        
                                        <CardText  style={{padding:"25px"}}>
                                                                                   
                                            <Row>
                                                <Col>
                                                    {this.state.merchantPersonnel.length !== 0 ?
                                                    this.state.merchantPersonnel[0].MERCHANT_PERSONNEL_FLAG_COUNT ?
                                                        <Table responsive >
                                                            <thead style={{marginTop:10,backgroundColor:'#2C2165',color:"white"}}>
                                                                <tr>
                                                                    <th>Service/Offer Id</th>
                                                                    <th>Booking Id</th>
                                                                    <th>Reason</th>
                                                                    <th>Type</th>
                                                                    <th>Support User</th>
                                                                </tr>
                                                            </thead>
                                                            {this.state.flagDetails.length !== 0 ? 
                                                                this.state.flagDetails.map((offer) => {
                                                                    return (
                                                                    <tr>
                                                                        {offer.QF_MERCHANT_SERVICE_PRID!=null?<td><text style={LinkedText} onClick={() => this.serviceDetailHandler(offer.QF_MERCHANT_SERVICE_PRID)}>{offer.QF_MERCHANT_SERVICE_PRID}</text></td>:<td><text style={LinkedText} onClick={() => this.offerDetailsHandler(offer.QF_MERCHANT_OFFER_PRID)}>{offer.QF_MERCHANT_OFFER_PRID}</text></td>}
                                                                        <td><text style={LinkedText} onClick={() => this.bookingDetailsHandler(offer.QF_CONSUMER_OFFER_ACCEPTED_PRID)}>{offer.QF_CONSUMER_OFFER_ACCEPTED_PRID}</text></td>
                                                                        <td>{offer.MERCHANT_FLAG_COMMENTS}</td>
                                                                        <td>{offer.MERCHANT_FLAG_ISSUE_TYPE}</td>
                                                                        <td>{offer.SUPPORT_USER_FNAME}{' '}{offer.SUPPORT_USER_LNAME}</td>
                                                                    </tr>
                                                                    )
                                                                })
                                                            :
                                                            null
                                                            }
                                                        </Table> 
                                                        :
                                                        null
                                                    :
                                                    null
                                                    } 
                                                </Col>                                          
                                            </Row>
                                
                                        </CardText>
                                    </Card>
                                :
                                null
                            :
                            null
                            }

                                              
                    </Card> 
            )
        }
        let finalEvents=[];
if(this.state.dateObj.length !== 0){
    Object.keys(this.state.dateObj).map((item,index)=>{
        // console.log(item)
        this.state.dateObj[item].map((item1,index1)=>{
            finalEvents.push(
                {
                    id:item1.QF_CONSUMER_OFFER_ACCEPTED_PRID ? item1.QF_CONSUMER_OFFER_ACCEPTED_PRID : item1.cal_prid ,
                    title:item1.itemName ? item1.itemName : item1.SERVICE_NAME ,
                    start:moment(item1.date + " " + item1.fromTime).toDate(),
                    end:moment(item1.date + " " + item1.toTime).toDate(),
                    bookingTrue:item1.isOffer ? true : false,
                    eventToTime:item1.toTime,
                    eventFromTime:item1.fromTime,
                    eventDate:item1.date,
                    vacation:item1.vacation ? true : false,
                    outOfOffice:item1.outOfOffice ? true : false
                }
            )
        })
    })
}
// console.log('final',finalEvents)
            let CalendarModal = (
            <Modal isOpen={this.state.clickCalendar} size='xl' >
                <ModalHeader><text style={{ fontWeight: "bold" }}>Professional Calendar</text></ModalHeader>
                <ModalBody>
                    <Card style={{ marginTop: 10, backgroundColor: "#E1E1E1" }}>
                        <CardBody>
                                {
                                    finalEvents.length !== 0 ?
                                    <Calendar
                                    localizer={localizer}
                                    events={finalEvents}
                                    startAccessor="start"
                                    endAccessor="end"
                                    onDoubleClickEvent={this.onClickEvent}
                                    onSelectEvent={this.onClickEvent}                            
                                    style={{ height: 500 }}
                                    /> :
                                    <text style={{textAlign:'center',display:'flex',justifyContent:'center',alignItems:'center'}}>There are no events to display</text>
                                }
                                

                        </CardBody>
                    </Card>
                </ModalBody>
                <ModalFooter>
                    <Button color="warning" onClick={this.calendarCancelModal}>Cancel</Button>
                </ModalFooter>
            </Modal>

        );
        let singleEventDetails;
    if(this.state.singleEventDet.length !==0){
        console.log('single',this.state.singleEventDet.start,moment(this.state.singleEventDet.end).format('DD MMM YY'))
        singleEventDetails=(
            <Modal isOpen={this.state.singleEventDetModal}>
                    <ModalHeader>
                        <text>Event Details</text>
                    </ModalHeader>
                    <ModalBody>
                    <Card style={{marginTop: 10, backgroundColor: "#E1E1E1"}}>
                        <CardBody>
                            <CardText>
                                <Row style={{marginTop:5}}>
                                    <Col>{this.state.singleEventDet.title ? this.state.singleEventDet.title : null}</Col>
                                    <Col style={{display:'flex',justifyContent:'flex-end',margin:4}}><FaTrashAlt size='1.2em' color='red' style={{cursor: 'pointer'}} onClick={this.deleteEventModal}/></Col>
                                </Row>
                                <Row style={{marginTop:5}}>
                                    {/* <Col> utc time{moment(this.state.singleEventDet.start).tz(this.state.merchantDetails.tz).utc().format()}</Col> */}
                                    <Col>{this.state.singleEventDet.length !==0 ? ( this.state.singleEventDet.vacation ? "Full Day" : moment(this.state.singleEventDet.start).format('DD-MMM-YYYY') + " From " + moment(this.state.singleEventDet.start).format('hh:mm A z') + " To " + moment(this.state.singleEventDet.end).format('hh:mm A z')): null}</Col>
                                </Row>
                            </CardText>
                        </CardBody>
                    </Card>
                    </ModalBody>
                    <ModalFooter>
                    <Button color="warning" onClick={()=>this.setState({singleEventDetModal:false,singleEventDet:''})}>Cancel</Button>
                    </ModalFooter>
                </Modal>
        )
    }
        let EventDeletionModal = (
            <Modal isOpen={this.state.eventDeleteModal}>
                <ModalHeader color="warning">Warning</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete it?
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={this.eventDeletionHandler}>Yes</Button>{' '}
                    <Button color="warning" onClick={() => this.setState({ eventDeleteModal: false })}>No</Button>
                </ModalFooter>
            </Modal>
        );

        return (
            <div>
                {/* <BreadCrumbs merchant='Merchant Details' merchantLink='/home/merchantDetails/MerchantSearchDetails' breadCrumb={true}/>   */}
            
                <Container fluid style={{paddingLeft:"50px",paddingRight:"50px",backgroundColor:"rgb(239,239,239)"}}>
                <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/>         
                    
                    {this.state.loading===false ? mDetails : <text style={{fontFamily: 'Muli', fontWeight:'bold',color: '#563951'}}>Loading...</text>}
                   
                    {this.state.showBusinessHrsModal?showBusinessHrsModal:null}

                    {this.state.clickCalendar ? CalendarModal : null}

                    {this.state.singleEventDetModal ? singleEventDetails : null}

                    {this.state.eventDeleteModal ? EventDeletionModal : null}

                    <ToastContainer />
                                 
                </Container>
                </div>
            
        )

    }

}
const mapStateToProps=state=>{
    return {
        country: state.country,
        token:state.token,
        id:state.id,
        view:state.view
    } 
  }

export default connect(mapStateToProps) (MerchantSearchDetails);