import React, {Component} from 'react';
import { Table, Col } from 'reactstrap';

class CouponView extends Component {
  
  
  MerDetialClickHandler=(coupon_code)=>{ 

    this.props.history.push({
      pathname:this.props.match.url+"/CouponSearchDetails",
      state:{
        merchant: true,
        coupon_code,
        active: this.props.active
      }
  });
}

OurDetialClickHandler=(coupon_code)=>{

  this.props.history.push({
    pathname:this.props.match.url+"/CouponSearchDetails",
    state:{
      our: true,
      coupon_code,
      active: this.props.active
    }
});
}

  render() {

    let couponDetails = null;

    if(this.props.mCoupon.length !== 0){
      couponDetails = (
        <tr style={{cursor:"pointer"}} onClick={()=>this.MerDetialClickHandler(this.props.mCoupon)} key={this.props.mCoupon[0].QF_MERCHANT_COUPON_PRID}>
            <td>{this.props.mCoupon[0].MERCHANT_COUPON_CREATED_BY}</td>
            <td>{this.props.mCoupon[0].MERCHANT_COUPON_CODE}</td>
            <td>{this.props.mCoupon[0].MERCHANT_COUPON_MAX_COUNT}</td>
            <td>{this.props.mCoupon[0].MERCHANT_COUPON_DESC}</td>
            <td>{this.props.mCoupon[0].QF_SERVICE_CATEGORY_PRID}</td>
            <td>{this.props.mCoupon[0].QF_SERVICE_SUB_CATEGORY_PRID}</td>
        </tr>
      );
  } else if(this.props.oCoupon.length !== 0){
    couponDetails = (
        <tr style={{cursor:"pointer"}} onClick={()=>this.OurDetialClickHandler(this.props.oCoupon)} key={this.props.oCoupon[0].QF_MERCHANT_COUPON_PRID}>
            <td>{this.props.oCoupon[0].OUR_COUPON_CREATED_BY}</td>
            <td>{this.props.oCoupon[0].OUR_COUPON_CODE}</td>
            <td>{this.props.oCoupon[0].OUR_COUPON_MAX_COUNT}</td>
            <td>{this.props.oCoupon[0].OUR_COUPON_DESC}</td>
            <td>{this.props.oCoupon[0].QF_SERVICE_CATEGORY_PRID}</td>
            <td>{this.props.oCoupon[0].QF_SERVICE_SUB_CATEGORY_PRID}</td>
        </tr>
      );
  }
    

    return (
      <Col xl={{size: 10, offset: 1}}>
        <Table style={{marginTop: 50}} hover hidden={this.props.state ? false : true}>
          <thead>
            <tr>
              <th> Created By </th>
              <th> Coupon Code </th>
              <th> Maximum Count </th>
              <th> Description </th>
              <th> Category Prid </th>
              <th> Subcategory Prid </th>
            </tr>
          </thead>
          <tbody>
            {couponDetails}            
          </tbody>
        </Table>
      </Col>
      
    );

  }
}

export default CouponView;