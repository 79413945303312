import React, {Component} from 'react';
import { Table, Input} from 'reactstrap';
import {Container,Row,Col,Card,CardHeader,CardBody,CardText,CardTitle,Button} from "reactstrap";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import {BarLoader} from "react-spinners";
import { connect } from 'react-redux';
import moment from 'moment';

class CancellationsView extends Component {
  
  state = {
       consumer_data:[],
       user_data:[],
       accepted_data1:[],
       serviceConsumed:'All',
       refundStatus:'All',
       accepted_data:this.props.location.state.accepted_data,
       consumerAdd:[],
       loginDetails:''
       
  }

  componentDidMount() 
{
//  console.log('p',this.props.location.state)
  
    axios.post("/support/consumer/ConsumerSearchDetails",{consumer_prid:this.props.location.state.consumer_prid})
    .then(res=>{
    

        if("Consumer Data",res.data.searchDet)
        {
            this.setState({
                consumer_data:res.data.consumerDet,
                user_data:res.data.userDet ? res.data.userDet.length !== 0 ? res.data.userDet : [] : [],
                accepted_data1:res.data.accepted_data.length !== 0 ? res.data.accepted_data : [] ,
                consumerAdd:res.data.adressUsers.length!==0?res.data.adressUsers:[],
                loginDetails:res.data.loginDetails.length!==0?res.data.loginDetails:[],
                loading:false
            });
        }
        else
        {
            if(res.data.dbError===true)
            {  toast.error("Internal Server Error",{position:toast.POSITION.BOTTOM_CENTER});  }
            this.setState({loading:false})

        }

    }).catch(err=>{
        toast.warn("Unable to Reach Server! Please Try Again!",{position:toast.POSITION.BOTTOM_CENTER});
        this.setState({loading:false})
    })
  }

  detialClickHandler=(booking_id)=>{
    let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;

    this.props.history.push({
      pathname:url+'/BookingIdSearch',
      state:{
          booking_id,
          url,
          active: this.props.active,
          active1: this.props.active1,
          active2: this.props.active2 
      }
  });

    
  }

  serviceConsumedHandler=(e)=>{
    
   this.setState({serviceConsumed:e.target.value})
 

  }
  refundStatusHandler=(e)=>{
    
    this.setState({refundStatus:e.target.value})
  
 
   }

   bookingsHandler = () => {
    let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
    this.props.history.push({
        pathname: url+'/ConsumerBookings',
        state:{
          accepted_data:this.state.accepted_data1,
          consumer_prid:this.props.location.state.consumer_prid,
          url 
        }
  
      })

}

disputesHandler=()=>{
  let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;

  let disputeCount=this.state.accepted_data1.filter((disputeCount)=>{
      return disputeCount.SERVICE_DISPUTE===1;
    })
  let disputeFiltered=disputeCount.map((each,k)=>{
      return k=each 
  }) 
  this.props.history.push({
    pathname: url+'/Disputes',
    state:{
     accepted_data:disputeFiltered,
     consumer_prid:this.props.location.state.consumer_prid,
     url
    }

  })
  
}
bookingsConsumedHandler = () =>{
  let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
  
  let consumedCount=this.state.accepted_data1.filter((canlcelledCount)=>{
      return canlcelledCount.OFFER_CONSUMED===1;
    })
  let consumedFiltered=consumedCount.map((each,k)=>{
      return k=each; 
  }) 
     this.props.history.push({ 
      pathname: url+'/BookingsConsumed',
      state:{
        accepted_data:consumedFiltered,
        consumer_prid:this.props.location.state.consumer_prid,
        url 
      }

    })


}
consumerPaymentHanler=()=>{
  let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;

  this.props.history.push({
      pathname: url+'/consumerpayments',
      state:{
          consumer_prid:this.props.location.state.consumer_prid,          
          url 
      }

    })
}
bookingsViewHandler=()=>{
  let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;

  this.props.history.push({
      pathname: url+'/allBookings',
      state:{
          consumer_prid:this.props.location.state.consumer_prid,          
          url 
      }

    })
}
earningsViewHandler=()=>{
  let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;

  this.props.history.push({
      pathname: url+'/earnedCoupons',
      state:{
          consumer_prid:this.props.location.state.consumer_prid,          
          url 
      }

    })
}

  render() {
    const LinkedText = {
      textDecoration:'underline',
      cursor:'pointer', 
      color: '#302939', 
      fontWeight:'bold',
      textTransform: 'uppercase',
      fontFamily: 'Muli',
      color: '#563951'
  }
  const fontText1 = {
    fontWeight:'bold',
    fontFamily: 'Muli',
    color: '#563951'                        
}
  const rowStyle={
    textAlign:"initial"
}
const fWeightSpan={
    fontWeight:"500",
    marginRight: 5,
    fontFamily: 'Muli',
    color: '#868686'
}
let cd=this.state.consumer_data,ud=this.state.user_data,ad=this.state.accepted_data;
let offerAcceptFilter=this.state.accepted_data1.map((each,k)=>{
  return k=each
 }
 )
 let offerAcceptedfiltered=offerAcceptFilter.length

 let offerCancelMap= this.state.accepted_data1.filter((offerCancelCount,i=0)=>{
     return offerCancelCount.OFFER_CANCELLED===1;
   })
   let offerCancelFilter=offerCancelMap.map((each,k)=>{
    return k=each
   })
   let offerCancelfiltered=offerCancelFilter.length

   let offerConsumeMap= this.state.accepted_data1.filter((offerConsumeCount,i=0)=>{
     return offerConsumeCount.OFFER_CONSUMED===1;
   })
   let offerConsumeFilter=offerConsumeMap.map((each,k)=>{
    return k=each
   }
   )
   let offerConsumefiltered=offerConsumeFilter.length  

   let disputeCount=this.state.accepted_data1.filter((disputeCount)=>{
     return disputeCount.SERVICE_DISPUTE===1;
   })
 let disputeFiltered=disputeCount.map((each,k)=>{
     return k=each
 })
 let finalDispCount=disputeFiltered.length

      let tableData=null

      if(this.state.serviceConsumed==='All'&&this.state.refundStatus==='All'){
  
      
      tableData = this.state.accepted_data.map((acc) => { 

          return (
            <tr style={{cursor:"pointer"}} onClick={()=>this.detialClickHandler(acc.QF_CONSUMER_OFFER_ACCEPTED_PRID)} key={acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}>
              <td>{acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}</td>
              <td>{acc.QF_MERCHANT_OFFER_PRID?acc.QF_MERCHANT_OFFER_PRID:acc.QF_MERCHANT_SERVICE_PRID}</td>
              
              <td>
              {
                  acc.OFFER_CONSUMED
                  ?
                  <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>
                  :
                  <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>

                }
              </td>
              <td>
                {
                  acc.REFUND_STATUS
                  ?
                  <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>
                  :
                  <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>

                }
                
                </td>
              <td>{acc.OFFER_ACCEPTANCE_PIN}</td>
              
              
            </tr>
          )
      })}

      else if(this.state.serviceConsumed==='Yes'&&this.state.refundStatus==='Yes'){
    
        let bothYes = this.state.accepted_data.filter(each =>{return(
         each.OFFER_CONSUMED===1&&each.REFUND_STATUS===1)})
     
         tableData =  bothYes.map((acc) => {

          return ( 
            <tr style={{cursor:"pointer"}} onClick={()=>this.detialClickHandler(acc.QF_CONSUMER_OFFER_ACCEPTED_PRID)} key={acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}>
              <td>{acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}</td>
              <td>{acc.QF_MERCHANT_OFFER_PRID?acc.QF_MERCHANT_OFFER_PRID:acc.QF_MERCHANT_SERVICE_PRID}</td>
              
              <td>
              {
                  acc.OFFER_CONSUMED
                  ?
                  <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>
                  :
                  <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>

                }
              </td>
              <td>
                {
                  acc.REFUND_STATUS
                  ?
                  <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>
                  :
                  <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>

                }
                
                </td>
              <td>{acc.OFFER_ACCEPTANCE_PIN}</td>
              
              
            </tr>
          )
      })     
        }
     

        else if(this.state.serviceConsumed==='No'&&this.state.refundStatus==='No'){
    
          let bothNo = this.state.accepted_data.filter(each =>{return(
           each.OFFER_CONSUMED!==1&&each.REFUND_STATUS!==1)})
       
           tableData =  bothNo.map((acc) => {
  
            return (
              <tr style={{cursor:"pointer"}} onClick={()=>this.detialClickHandler(acc.QF_CONSUMER_OFFER_ACCEPTED_PRID)} key={acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}>
                <td>{acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}</td>
                <td>{acc.QF_MERCHANT_OFFER_PRID?acc.QF_MERCHANT_OFFER_PRID:acc.QF_MERCHANT_SERVICE_PRID}</td>
                
                <td>
                {
                    acc.OFFER_CONSUMED
                    ?
                    <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>
                    :
                    <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>
  
                  }
                </td>
                <td>
                  {
                    acc.REFUND_STATUS
                    ?
                    <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>
                    :
                    <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>
  
                  }
                  
                  </td>
                <td>{acc.OFFER_ACCEPTANCE_PIN}</td>
                
                
              </tr>
            )
        })     
          }



          else if(this.state.serviceConsumed==='All'&&this.state.refundStatus==='Yes'){
    
            let all_Yes = this.state.accepted_data.filter(each =>{return(
             each.REFUND_STATUS===1)})
         
             tableData =  all_Yes.map((acc) => {
    
              return (
                <tr style={{cursor:"pointer"}} onClick={()=>this.detialClickHandler(acc.QF_CONSUMER_OFFER_ACCEPTED_PRID)} key={acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}>
                  <td>{acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}</td>
                  <td>{acc.QF_MERCHANT_OFFER_PRID?acc.QF_MERCHANT_OFFER_PRID:acc.QF_MERCHANT_SERVICE_PRID}</td>
                  
                  <td>
                  {
                      acc.OFFER_CONSUMED
                      ?
                      <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>
                      :
                      <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>
    
                    }
                  </td>
                  <td>
                    {
                      acc.REFUND_STATUS
                      ?
                      <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>
                      :
                      <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>
    
                    }
                    
                    </td>
                  <td>{acc.OFFER_ACCEPTANCE_PIN}</td>
                  
                  
                </tr>
              )
          })     
            }
  


            else if(this.state.serviceConsumed==='All'&&this.state.refundStatus==='No'){
    
              let all_No = this.state.accepted_data.filter(each =>{return(
               each.REFUND_STATUS!==1)})
           
               tableData =  all_No.map((acc) => {
      
                return (
                  <tr style={{cursor:"pointer"}} onClick={()=>this.detialClickHandler(acc.QF_CONSUMER_OFFER_ACCEPTED_PRID)} key={acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}>
                    <td>{acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}</td>
                    <td>{acc.QF_MERCHANT_OFFER_PRID?acc.QF_MERCHANT_OFFER_PRID:acc.QF_MERCHANT_SERVICE_PRID}</td>
                    
                    <td>
                    {
                        acc.OFFER_CONSUMED
                        ?
                        <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>
                        :
                        <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>
      
                      }
                    </td>
                    <td>
                      {
                        acc.REFUND_STATUS
                        ?
                        <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>
                        :
                        <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>
      
                      }
                      
                      </td>
                    <td>{acc.OFFER_ACCEPTANCE_PIN}</td>
                    
                    
                  </tr>
                )
            })     
              }


  

              else if(this.state.serviceConsumed==='Yes'&&this.state.refundStatus==='All'){
    
                let yes_All = this.state.accepted_data.filter(each =>{return(
                 each.OFFER_CONSUMED===1)})
             
                 tableData =  yes_All.map((acc) => {
        
                  return (
                    <tr style={{cursor:"pointer"}} onClick={()=>this.detialClickHandler(acc.QF_CONSUMER_OFFER_ACCEPTED_PRID)} key={acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}>
                      <td>{acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}</td>
                      <td>{acc.QF_MERCHANT_OFFER_PRID?acc.QF_MERCHANT_OFFER_PRID:acc.QF_MERCHANT_SERVICE_PRID}</td>
                      
                      <td>
                      {
                          acc.OFFER_CONSUMED
                          ?
                          <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>
                          :
                          <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>
        
                        }
                      </td>
                      <td>
                        {
                          acc.REFUND_STATUS
                          ?
                          <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>
                          :
                          <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>
        
                        }
                        
                        </td>
                      <td>{acc.OFFER_ACCEPTANCE_PIN}</td>
                      
                      
                    </tr>
                  )
              })     
                }

     
             

                else if(this.state.serviceConsumed==='No'&&this.state.refundStatus==='All'){
    
                  let no_All = this.state.accepted_data.filter(each =>{return(
                   each.OFFER_CONSUMED!==1)})
               
                   tableData =  no_All.map((acc) => {
          
                    return (
                      <tr style={{cursor:"pointer"}} onClick={()=>this.detialClickHandler(acc.QF_CONSUMER_OFFER_ACCEPTED_PRID)} key={acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}>
                        <td>{acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}</td>
                        <td>{acc.QF_MERCHANT_OFFER_PRID?acc.QF_MERCHANT_OFFER_PRID:acc.QF_MERCHANT_SERVICE_PRID}</td>
                        
                        <td>
                        {
                            acc.OFFER_CONSUMED
                            ?
                            <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>
                            :
                            <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>
          
                          }
                        </td>
                        <td>
                          {
                            acc.REFUND_STATUS
                            ?
                            <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>
                            :
                            <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>
          
                          }
                          
                          </td>
                        <td>{acc.OFFER_ACCEPTANCE_PIN}</td>
                        
                        
                      </tr>
                    )
                })     
                  }
  
           
                  else if(this.state.serviceConsumed==='Yes'&&this.state.refundStatus==='No'){
    
                    let yesNo = this.state.accepted_data.filter(each =>{return(
                     each.OFFER_CONSUMED===1&&each.REFUND_STATUS!==1)})
                 
                     tableData =  yesNo.map((acc) => {
            
                      return (
                        <tr style={{cursor:"pointer"}} onClick={()=>this.detialClickHandler(acc.QF_CONSUMER_OFFER_ACCEPTED_PRID)} key={acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}>
                          <td>{acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}</td>
                          <td>{acc.QF_MERCHANT_OFFER_PRID?acc.QF_MERCHANT_OFFER_PRID:acc.QF_MERCHANT_SERVICE_PRID}</td>
                          
                          <td>
                          {
                              acc.OFFER_CONSUMED
                              ?
                              <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>
                              :
                              <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>
            
                            }
                          </td>
                          <td>
                            {
                              acc.REFUND_STATUS
                              ?
                              <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>
                              :
                              <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>
            
                            }
                            
                            </td>
                          <td>{acc.OFFER_ACCEPTANCE_PIN}</td>
                          
                          
                        </tr>
                      )
                  })     
                    }


                
                    else if(this.state.serviceConsumed==='No'&&this.state.refundStatus==='Yes'){
    
                      let noYes = this.state.accepted_data.filter(each =>{return(
                       each.OFFER_CONSUMED!==1&&each.REFUND_STATUS===1)})
                   
                       tableData =  noYes.map((acc) => {
              
                        return (
                          <tr style={{cursor:"pointer"}} onClick={()=>this.detialClickHandler(acc.QF_CONSUMER_OFFER_ACCEPTED_PRID)} key={acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}>
                            <td>{acc.QF_CONSUMER_OFFER_ACCEPTED_PRID}</td>
                            <td>{acc.QF_MERCHANT_OFFER_PRID?acc.QF_MERCHANT_OFFER_PRID:acc.QF_MERCHANT_SERVICE_PRID}</td>
                            
                            <td>
                            {
                                acc.OFFER_CONSUMED
                                ?
                                <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>
                                :
                                <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.OFFER_CONSUMED?"Yes":"No"}</p>
              
                              }
                            </td>
                            <td>
                              {
                                acc.REFUND_STATUS
                                ?
                                <p style={{backgroundColor:"#3DC5B6",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>
                                :
                                <p style={{backgroundColor:"#F86C69",borderRadius:"2px",color:"#fff",marginLeft:"50px",marginRight:"50px",width: 100}}>{acc.REFUND_STATUS?"Yes":"No"}</p>
              
                              }
                              
                              </td>
                            <td>{acc.OFFER_ACCEPTANCE_PIN}</td>
                            
                            
                          </tr>
                        )
                    })     
                      }

       
       
       
       
       
          else{
        tableData = <p style={fontText1}>No Data</p>
      }

    return (
      <div>
            {/* <Breadcrumb active={'/home/customerDetails'} active1={this.state.active1}/> */}
        
        <Container fluid style={{paddingLeft:"50px",paddingRight:"50px",backgroundColor:"rgb(239,239,239)"}}>
             <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/> 
            {/* {loading} */}
            {
                (cd.length!==0)
                ?
                
                (
                <Card style={{border:"1px solid #a78bba"}}>
                <CardHeader style={{fontWeight:"",fontSize:"18px",color:"#fff",backgroundColor:"#2C2165",fontFamily:'Muli'}}>
                <Row>
                    <Col sm={{size:4, offset:4}}>Customer Details </Col> 
                    <Col sm={{size:'auto', offset:2}} > {ud.length === 0 ? <text style={{color:"#563951", padding:10,outline: '5px solid #e7eaf6',backgroundColor:"#FFD0D0"}}>Guest User</text> : ud[0].ACCOUNT_VERIFIED_FLAG !== 1 ? <text style={{color:"#563951", padding:10,outline: '5px solid #e7eaf6',backgroundColor:"#FFD0D0"}}> Guest User </text> : <text style={{color:"#563951", padding:10,outline: '5px solid #e7eaf6',backgroundColor:"#FFD0D0"}}>Registered User</text> } </Col>
                </Row>
                </CardHeader>
                <CardText style={{padding:"25px"}}>
                    <Row className="mb-4" style={rowStyle}>
                        <Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}>Name:</span>  {cd[0].CONSUMER_FNAME!==null?cd[0].CONSUMER_FNAME:"Not updated"}</text>
                        </Col>
   
                        <Col size={4}>
                            <text style={fontText1}><span style={fWeightSpan}>Email:</span> {cd[0].CONSUMER_EMAIL!==null?cd[0].CONSUMER_EMAIL:"Not updated"}

                            {cd[0].CONSUMER_EMAIL !==null ?
                                ud.length!==0?
                                    ud[0].ACCOUNT_VERIFIED_FLAG===1 ?
                                        <span style={{backgroundColor:"#3DC5B6",padding:"5px",color:"#fff",borderRadius:"3px"}}>Verified</span>
                                        :
                                        <span style={{backgroundColor:"#F86C69",padding:"5px",color:"#fff",borderRadius:"3px"}}>Not-Verified</span>
                                : <span style={{backgroundColor:"#F86C69",padding:"5px",color:"#fff",borderRadius:"3px"}}>Not-Verified</span>
                            :null}
                               </text>
                             
                             
                        </Col>
                        <Col size={3}>
                                <text style={fontText1}><span style={fWeightSpan}>Mobile Number:</span>
                                 {/* {cd[0].CONSUMER_PHONE!==null?cd[0].CONSUMER_PHONE:"Not updated"} */}
                                 {cd[0].CONSUMER_PHONE_COUNTRY_CODE?cd[0].CONSUMER_PHONE_COUNTRY_CODE+' ':cd[0].CONSUMER_PHONE!==null?'+1 ':null}{cd[0].CONSUMER_PHONE!==null ?  <text>{cd[0].CONSUMER_PHONE.toString().substring(0,3)}-{cd[0].CONSUMER_PHONE.toString().substring(3,6)}-{cd[0].CONSUMER_PHONE.toString().substring(6,10)}</text> : "Not updated"}                            

                                {cd[0].CONSUMER_PHONE !==null ?
                                ud.length!==0?
                                    ud[0].MOBILE_VERIFIED_FLAG===1 ?
                                        <span style={{backgroundColor:"#3DC5B6",padding:"5px",color:"#fff",borderRadius:"3px"}}>Verified</span>
                                        :
                                        <span style={{backgroundColor:"#F86C69",padding:"5px",color:"#fff",borderRadius:"3px"}}>Not-Verified</span>
                                : <span style={{backgroundColor:"#F86C69",padding:"5px",color:"#fff",borderRadius:"3px"}}>Not-Verified</span>
                            :null}
                               </text>
                               
                        </Col>                        
                    </Row>
                    <Row className="mb-4" style={rowStyle}>
                        

                        <Col size={4}>
                            <text style={fontText1}><span style={fWeightSpan}>Bookings:</span> {offerAcceptedfiltered===null||0?0:offerAcceptedfiltered>0?<text style={LinkedText} onClick={()=>this.bookingsHandler()}> {offerAcceptedfiltered}</text>:offerAcceptedfiltered}</text>
                        </Col>

                        <Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}>Bookings Consumed:</span> {offerConsumefiltered===null||0?0:offerConsumefiltered>0?<text style={LinkedText} onClick={()=>this.bookingsConsumedHandler()}> {offerConsumefiltered}</text>:offerConsumefiltered}</text>
                        </Col>

                        <Col size={3}>
                            <text style={fontText1}><span style={fWeightSpan}>Bookings Cancelled:</span> {offerCancelfiltered===null||0?0:offerCancelfiltered}</text> 
                                                    </Col>                        
                    </Row>   
                    <Row className="mb-4" style={rowStyle}>
                        <Col sm={{size:4}}>
                            <text style={fontText1}><span style={fWeightSpan}>Bookings Disputed:</span>
                             
                            {finalDispCount===null||0?0: finalDispCount>0?<text style={LinkedText} onClick={()=>this.disputesHandler()}>{finalDispCount}</text>:finalDispCount}
                            </text>
                                                    </Col>
                        <Col sm={{size:4}}>     
                        <Button size="md" style={{backgroundColor:"#612147",marginRight:5,fontFamily: 'Muli',}} onClick={()=>this.earningsViewHandler()}>Earned Coupons</Button>
                        
                       </Col>
                        <Col sm={{size:4}}>     
                        <Button size="md" style={{backgroundColor:"#612147",marginRight:5,fontFamily: 'Muli',}} onClick={()=>this.consumerPaymentHanler()}>Payment&Transactions {'-->'}</Button>
                        
                       </Col>
                        
                        </Row>
                        <Row className="mb-4" style={rowStyle}>
                             <Col className="col-4">
                                <text style={fontText1}><span style={fWeightSpan}> Customer Address:</span>{ this.state.consumerAdd[0].length!==0 ? this.state.consumerAdd[0][0].CONSUMER_ADDRESS ? this.state.consumerAdd[0][0].CONSUMER_ADDRESS : '------' : '------' }</text>
                                {/* <text style={fontText1}><span style={fWeightSpan}> Category:</span>{ this.state.cats.length != 0 ? this.state.cats[0].category_name : <text style={fontText}>---</text> } </text> */}
    
                             </Col>
                             <Col style={{paddingTop:0}}>
                                <text style={fontText1}><span style={fWeightSpan}>Last Login Time:</span>{
                                this.state.loginDetails.length !==0 ? 
                                (this.state.loginDetails[0].USER_TOKEN_UPDATED_ON_DATE ? 
                                    moment(this.state.loginDetails[0].USER_TOKEN_UPDATED_ON_DATE+"Z").tz(this.state.consumerAdd[0].length !==0 ? (this.state.consumerAdd[0][0].CONSUMER_ADDRESS.includes('USA') ? 'America/Los_Angeles' : 'Asia/Kolkata') : (this.props.country === 'USA'?'America/Los_Angeles' : 'Asia/Kolkata')).format("DD-MMM-YYYY hh:mm A z") 
                                    : '-----') 
                                : '------'}
                                </text>
                            </Col> 
                            </Row> 
                    </CardText>            
            </Card>):null}
           
      
        {cd.length!==0?
        <Col >
        <h5 style={{marginBottom:40,marginTop:40, fontFamily:'Muli', color:'#563951'}}>Cancelled Bookings :</h5>
        <Table hover>
        <thead style={{backgroundColor:'#2C2165',color:"white", fontFamily:'Muli'}}>
          <tr>
            <th> Booking ID </th>
            <th> Offer/Service ID </th>
            <th style={{width: 250}}> Service Consumed <Input type='select' 
            style={{width: 70, height: 28, float: 'right',fontSize: '12px'}} 
            onChange={this.serviceConsumedHandler}
            value={this.state.serviceConsumed}
            >
                                      <option value='All'>All</option>
                                      <option value='Yes'>Yes</option>
                                      <option  value='No'>No</option>                                      
                                  </Input></th>
            <th style={{width: 220}}> Refund Status  <Input type='select' style={{width: 70, height: 28, float: 'right',fontSize: '12px'}}
                                    onChange={this.refundStatusHandler}
                                    value={this.state.refundStatus}>
                                      <option value='All'>All</option>
                                      <option value='Yes'>Yes</option>
                                      <option  value='No'>No</option>    
                                </Input></th>
            <th> Acceptance Pin </th>
            
            
            
          </tr>
        </thead>
        <tbody style={{fontFamily:"Muli", color: '#563951',fontWeight:'bold',}}>
           {/* {tableData}  */}
           {tableData.length!==0?tableData: <tr>
                    <td></td>
                    <td></td>
                    <td>---No Bookings---</td>
                    <td></td>
                    <td></td>
                </tr>}
        </tbody>
      </Table>
      </Col>:null}
      </Container>
            </div>
            
    );

  }
}
const mapStateToProps=state=>{
  return {
      country: state.country,
      token:state.token,
      id:state.id,
      view:state.view
  } 
}
export default connect(mapStateToProps) (CancellationsView);