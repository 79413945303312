
import React, { Component } from 'react';
import {connect} from "react-redux";
import axios from 'axios'; 
import {ToastContainer, toast } from 'react-toastify';
import { ButtonGroup, Button, Label, Card, CardBody,CardTitle, CardText, Row, Col, Container, Modal,ModalBody,ModalHeader,ModalFooter} from 'reactstrap';
import {BarLoader} from 'react-spinners';
// import Breadcrumb from '../Breadcrumb';
// import Switch from 'react-switch';
import momenttz from 'moment-timezone'





class UserpaymentTrans extends Component {
   
    state = {
        
        payments: [],
        refunds: [],
        loading:false,
        payment:true,
        refund:false
        
    }

    componentDidMount(){
         
            // console.log('pro',this.props.location.state.consumer_prid)
            this.setState({loading:true})
            let user_id=this.props.location.state.consumer_prid
            axios.post("/support/paymentdetails",{user_id})
           .then((res) => {
            if(res.data.data){
                // console.log('pay',res.data)
                if(res.data.results.length !== 0){
                    this.setState({
                        ...this.state,
                        payments: res.data.results,
                        loading:false
                    })
                 
                   return axios.post("/support/refunds",{user_id})
                   .then((res) => {
                       if(res.data.data){
                        //    console.log("resolved", res.data)
                           if(res.data.results1.length !== 0){
                               this.setState({
                                   ...this.state,
                                   refunds: res.data.results1,
                                   loading: false
                               });
                           } else if(res.data.results1.length === 0){
                               this.setState({
                                   ...this.state,
                                   refunds: [],
                                   loading: false
                               });
                               
                           }
                       } else {
                           if(res.data.dbError){
                               toast.error("Internal Server Error!",{
                                   position: toast.POSITION.BOTTOM_CENTER
                               });
                               this.setState({
                                   ...this.state,
                                   loading: false
                               });
                           } else {
                               this.setState({
                                   ...this.state,
                                   refunds: [],
                                   loading: false
                               });
                           }
                        }   
                   })
                   .catch(err => {
                       console.log('err',err)
                       toast.warn("Unable to Reach Server! Please Try Again!" , {
                           position: toast.POSITION.BOTTOM_CENTER
                         });
                       this.setState({
                           ...this.state,
                           loading: false
                       });
                   })



                } else if(res.data.result.length === 0){ 
                    this.setState({
                        ...this.state,
                        payments: [],
                        loading: false
                    });
        
                }
            }
            else {
                if(res.data.dbError){
                    toast.error("Internal Server Error!",{
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                } else { 
                    
                    this.setState({loading:true})
                    axios.post("/support/refunds",{user_id})
                    .then((res) => {
                        if(res.data.data){
                            // console.log("resolved", res.data)
                            if(res.data.results1.length !== 0){
                                this.setState({
                                    ...this.state,
                                    refunds: res.data.results1,
                                    loading: false
                                });
                            } else if(res.data.results1.length === 0){
                                this.setState({
                                    ...this.state,
                                    refunds: [],
                                    loading: false
                                });
                                
                            }
                        } else {
                            if(res.data.dbError){
                                toast.error("Internal Server Error!",{
                                    position: toast.POSITION.BOTTOM_CENTER
                                });
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                            } else {
                                this.setState({
                                    ...this.state,
                                    refunds: [],
                                    loading: false
                                });
                            }
                         }   
                    })
                    .catch(err => {
                        console.log('err',err)
                        toast.warn("Unable to Reach Server! Please Try Again!" , {
                            position: toast.POSITION.BOTTOM_CENTER
                          });
                        this.setState({
                            ...this.state,
                            loading: false
                        });
                    })

                    this.setState({
                        ...this.state,
                        payments: [],
                        loading: false
                    });
                }
                  
            }
           
        })
        .catch((err) => {
            toast.warn("Unable to Reach Server! Please Try Again!" , {
                position: toast.POSITION.BOTTOM_CENTER
              });
            this.setState({
                ...this.state,
                loading: false
            });
        })
    

       
    

    
}
    
    paymentHandler = () => {
        this.setState({
                ...this.state,
               payment: true,
                refund: false,
               
               
        });
       
        
    }
    refundHandler = () => {
        this.setState({
                ...this.state,
                payment: false,
                refund: true,
              
        });
       
     }
    

        
    render() {

        const rowStyle={
            textAlign:"initial",
        }
        const fWeightSpan={
            fontWeight:"500",
            padding: 5,
            // cursor: 'pointer',
            fontFamily: 'Muli',
            color: '#868686'
            // border:'1px solid black'
        }
        
        const textDecor = {
            marginLeft: 5,
            fontWeight:"bold",
            fontFamily: 'Muli',
            // marginLeft: 5,
            color: '#563951'
        }

        const textDecor1 = {
            marginLeft: 5,
            fontFamily: 'Muli',
            fontWeight:"bold",
            // marginLeft: 5,
            color: '#563951'
           // color: 'green'
        }

        let payment = null;
        let refund = null;

        console.log('state',this.state)
        if(this.state.payments.length !== 0){
            // payment = this.state.payments.map((ticket,index=0) => {

            //     console.log('inde',index);
            //     return (
            //         <Card style={{marginTop: 10, borderColor: '#a78bba',width:'100%'}} >
            //             <CardBody  >   
            //             <CardTitle style={{fontSize:18}}  >  
                      
                      
            //             <Row style={rowStyle}>
                                
            //                       {
            //                           ticket.MERCHANT_OFFER_HEADER_NAME? 
            //                        <Col style={textDecor} >{ticket.MERCHANT_OFFER_HEADER_NAME}</Col>:null}
                                  
                                  
            //                        <Col>{ ticket.PAYMENT_PROCESSED_CURRENCY==="usd"?  
            //                        <Col style={{fontFamily: "Muli",fontSize: 18,fontWeight: "bold",fontStyle: "normal",letterSpacing: 0, textAlign: "right", color: "#63c2b7" }} >${ticket.CONSUMER_PAYMENT_PROCESSED_AMOUNT.toFixed(2)}</Col>
            //                        :<Col style={{fontFamily: "Muli",fontSize: 18,fontWeight: "bold",fontStyle: "normal",letterSpacing: 0, textAlign: "right", color: "#63c2b7" }}>{'\u20B9'}{ticket.CONSUMER_PAYMENT_PROCESSED_AMOUNT.toFixed(2)}</Col> }</Col>

            //             </Row>
            //             </CardTitle>                                                    
            //                 <CardText >
            //                { ticket.MERCHANT_NAME ?
            //                     <Row style={rowStyle}>
            //                         <Col style={textDecor}> {ticket.MERCHANT_NAME}</Col>
                                  
            //                     </Row>:null}
            //                     <hr style={{marginTop:14,
            //                       height: 1,
            //                       borderWidth: 1,
            //                       borderColor: "#ded3eb"}}/>

            //                     <Row style={rowStyle}>
            //                     {ticket.MERCHANT_OFFER_FROM_DATE_TIME? <Col sm={{size:4}} ><text style={fWeightSpan}>Offer Date:</text></Col>:null}
            //                           <Col sm={{size:4}} ><text style={fWeightSpan}>Payment Date:</text></Col> 
            //                         <Col sm={{size:4}} ><text style={fWeightSpan}>Time:</text></Col>
                              
            //                     </Row>
            //                     <Row style={rowStyle}>
            //                         {ticket.MERCHANT_OFFER_FROM_DATE_TIME?
            //                     <Col style={textDecor1}><text>{momenttz(ticket.MERCHANT_OFFER_FROM_DATE_TIME+"Z").tz(ticket.MERCHANT_OFFER_TIMEZONE).format("D MMM YY")}</text></Col>
            //                         :null}
            //                     {ticket.PAYMENT_PROCESSED_CURRENCY==="usd"?

            //                     <Col style={textDecor}><text> { momenttz(ticket.CONSUMER_PAYMENT_PROCESSED_ON_DATE+"Z").tz('America/Los_Angeles').format("D MMM YY")} </text></Col>
            //                     :<Col style={textDecor}><text> { momenttz(ticket.CONSUMER_PAYMENT_PROCESSED_ON_DATE+"Z").tz('Asia/Kolkata').format("D MMM YY")} </text></Col>
                                
            //                 }
            //                     {ticket.PAYMENT_PROCESSED_CURRENCY==="usd"?
            //                     <Col style={textDecor1}><text>{momenttz(ticket.CONSUMER_PAYMENT_PROCESSED_ON_DATE+"Z").tz('America/Los_Angeles').format("hh:mmA")} PST</text></Col>
            //                     :
            //                     <Col style={textDecor1}><text>{momenttz(ticket.CONSUMER_PAYMENT_PROCESSED_ON_DATE+"Z").tz('Asia/Kolkata').format("hh:mmA")} IST</text></Col>
            //                      }
                                
            //                         </Row>
            //                       <Row style={rowStyle}>
            //                       {/* {ticket.QF_CONSUMER_OFFER_ACCEPTED_PRID? <Col style={{fontFamily: "Muli",
            //                                     fontWeight:500,
            //                                     fontStyle: "normal",
            //                                     letterSpacing: 0,
            //                                     color: "#817b85",
            //                                     marginTop:16,
            //                                     borderRadius: 4,
            //                                     backgroundColor: "#f1f1f1",
            //                                     padding:11,
            //                                     marginLeft:5
            //                                     }}> Booking ID:  
            //                                     <text style={{
            //                                     fontFamily: "Muli",
            //                                     fontWeight: "bold",
            //                                     fontStyle: "normal",
            //                                     letterSpacing: 0,
            //                                     color: "#563951"}}> */}
            //                                     {/* {
            //                                      ticket.booking.map((item,index)=><text key={item.QF_CONSUMER_OFFER_ACCEPTED_PRID}> {item.QF_CONSUMER_OFFER_ACCEPTED_PRID}</text>)}
            //                                    </text></Col> :<Col>
            //                                     <Button  active={this.state.refund? true : false} style={{backgroundColor:'#2C2165', fontFamily:'Muli',marginTop:10}}>Refund -></Button></Col>
            //                                     }                                              */}
            //                                   </Row>                            
 
                                
            //                 </CardText>
            //             </CardBody>
            //         </Card>
            //     );
            // })
            payment = this.state.payments.map((ticket) => {
                return (
                    <Card style={{marginTop: 10, borderColor: '#a78bba',width:'100%'}} >
                        <CardBody  >   
                        <CardTitle style={{fontSize:18}}  >  
                        <Row style={rowStyle}>
                                   <Col style={textDecor} >{ticket.MERCHANT_OFFER_HEADER_NAME!=null?ticket.MERCHANT_OFFER_HEADER_NAME:ticket.offer?ticket.offer[0].MERCHANT_OFFER_HEADER_NAME:ticket.SERVICE_NAME}</Col>
                                   <Col>{ ticket.PAYMENT_PROCESSED_CURRENCY==="INR"?  
                                   <Col style={{fontFamily: "Muli",fontSize: 18,fontWeight: "bold",fontStyle: "normal",letterSpacing: 0, textAlign: "right", color: "#63c2b7" }}>{'\u20B9'}{ticket.CONSUMER_PAYMENT_PROCESSED_AMOUNT.toFixed(2)}</Col>
                                   :<Col style={{fontFamily: "Muli",fontSize: 18,fontWeight: "bold",fontStyle: "normal",letterSpacing: 0, textAlign: "right", color: "#63c2b7" }} >${ticket.CONSUMER_PAYMENT_PROCESSED_AMOUNT.toFixed(2)}</Col> }</Col>

                        </Row>
                        </CardTitle>                                                    
                            <CardText >
                           
                                <Row style={rowStyle}>
                                    <Col style={textDecor}> {ticket.MERCHANT_NAME!=null?ticket.MERCHANT_NAME:ticket.offer?ticket.offer[0].MERCHANT_NAME:null}</Col>
                                    <Col  style={{textAlign: "right"}}> {ticket.booking?null:<text style={{backgroundColor:'#ffe4c5',color:'#f3972d',padding:5}}>Booking Failed</text> }</Col> 
                                  
                                </Row>
                                <hr style={{marginTop:14,
                                  height: 1,
                                  borderWidth: 1,
                                  borderColor: "#ded3eb"}}/>
                            

                               {
                                   ticket.QF_MERCHANT_SERVICE_PRID!=null?
                                    <Row style={rowStyle}>
                                        <Col sm={{size:4}} ><text style={fWeightSpan}>Payment Date</text></Col> 
                                        <Col sm={{size:4}} ><text style={fWeightSpan}></text></Col> 
                                        <Col sm={{size:4}} ><text style={fWeightSpan}>Time</text></Col>
                                    </Row>
                                   :
                                    <Row style={rowStyle}>
                                        <Col sm={{size:4}} ><text style={fWeightSpan}>Offer Date</text></Col> 
                                        <Col sm={{size:4}} ><text style={fWeightSpan}>Payment Date</text></Col> 
                                        <Col sm={{size:4}} ><text style={fWeightSpan}>Time</text></Col>
                             
                                    </Row>
                                }
                                
                                {
                                   ticket.QF_MERCHANT_SERVICE_PRID!=null? 
                                   <Row style={rowStyle}>                                  
                                        <Col sm={{size:4}} style={textDecor}><text> { momenttz(ticket.CONSUMER_PAYMENT_PROCESSED_ON_DATE+"Z").tz(ticket.SERVICE_TIMEZONE).format("D MMM YY")} </text></Col>
                                        <Col></Col>
                                        <Col style={textDecor1}><text>{momenttz(ticket.CONSUMER_PAYMENT_PROCESSED_ON_DATE+"Z").tz(ticket.SERVICE_TIMEZONE).format("hh:mmA z")}</text></Col>
                                   </Row>
                                   :
                                   <Row style={rowStyle}>
                                        <Col style={textDecor1}><text>{momenttz(ticket.MERCHANT_OFFER_FROM_DATE_TIME+"Z").tz(ticket.MERCHANT_OFFER_TIMEZONE).format("D MMM YY")}</text></Col>
                                        <Col sm={{size:4}} style={textDecor}><text> { momenttz(ticket.CONSUMER_PAYMENT_PROCESSED_ON_DATE+"Z").tz(ticket.MERCHANT_OFFER_TIMEZONE).format("D MMM YY")} </text></Col>
                                        <Col style={textDecor1}><text>{momenttz(ticket.CONSUMER_PAYMENT_PROCESSED_ON_DATE+"Z").tz(ticket.MERCHANT_OFFER_TIMEZONE).format("hh:mmA z")}</text></Col>
                                  </Row>
                                   
                                }
                        
                                {/* {ticket.MERCHANT_OFFER_FROM_DATE_TIME||ticket.BOOKING_FROM_DATE_TIME ?
                                <Row style={rowStyle}>

                                <Col style={textDecor1}><text>{momenttz(ticket.MERCHANT_OFFER_FROM_DATE_TIME?ticket.MERCHANT_OFFER_FROM_DATE_TIME+"Z":ticket.BOOKING_FROM_DATE_TIME+"Z").tz(ticket.MERCHANT_OFFER_TIMEZONE?ticket.MERCHANT_OFFER_TIMEZONE:ticket.SERVICE_TIMEZONE).format("D MMM YY")}</text></Col> */}
                              
                                {/* {ticket.PAYMENT_PROCESSED_CURRENCY==="INR"? */}
                                 {/* <Col sm={{size:4}} style={textDecor}><text> { momenttz(ticket.CONSUMER_PAYMENT_PROCESSED_ON_DATE+"Z").tz('Asia/Kolkata').format("D MMM YY")} </text></Col>: */}
                                {/* <Col style={textDecor}><text> { momenttz(ticket.CONSUMER_PAYMENT_PROCESSED_ON_DATE+"Z").tz(ticket.MERCHANT_OFFER_TIMEZONE?ticket.MERCHANT_OFFER_TIMEZONE:ticket.SERVICE_TIMEZONE).format("D MMM YY")} </text></Col> */}

                                {/* } */}
                                
                                {/* <Col style={textDecor1}><text>{momenttz(ticket.CONSUMER_PAYMENT_PROCESSED_ON_DATE+"Z").tz(ticket.MERCHANT_OFFER_TIMEZONE?ticket.MERCHANT_OFFER_TIMEZONE:ticket.SERVICE_TIMEZONE).format("hh:mmA z")}</text></Col> */}
                                 
                                
                               {/* </Row>:
                               ticket.offer?

                                <Row style={rowStyle}>

                                 <Col style={textDecor1}><text>{momenttz(ticket.offer[0].MERCHANT_OFFER_FROM_DATE_TIME?ticket.offer[0].MERCHANT_OFFER_FROM_DATE_TIME+"Z":ticket.BOOKING_FROM_DATE_TIME+"Z").tz(ticket.offer[0].MERCHANT_OFFER_TIMEZONE ?ticket.offer[0].MERCHANT_OFFER_TIMEZONE:ticket.SERVICE_TIMEZONE).format("D MMM YY")}</text></Col>


                              
                                <Col  style={textDecor}><text> { momenttz(ticket.CONSUMER_PAYMENT_PROCESSED_ON_DATE+"Z").tz(ticket.offer[0].MERCHANT_OFFER_TIMEZONE ?ticket.offer[0].MERCHANT_OFFER_TIMEZONE:ticket.SERVICE_TIMEZONE).format("D MMM YY")} </text></Col> */}

                                {/* {ticket.PAYMENT_PROCESSED_CURRENCY==="INR"?
                                <Col style={textDecor1}><text>{momenttz(ticket.CONSUMER_PAYMENT_PROCESSED_ON_DATE+"Z").tz('Asia/Kolkata').format("hh:mmA")} IST</text></Col>
                                : */}
                                {/* <Col  style={textDecor1}><text>{momenttz(ticket.CONSUMER_PAYMENT_PROCESSED_ON_DATE+"Z").tz(ticket.offer[0].MERCHANT_OFFER_TIMEZONE ?ticket.offer[0].MERCHANT_OFFER_TIMEZONE:ticket.SERVICE_TIMEZONE).format("hh:mmA z")}</text></Col> */}
                                 {/* } */}
                                 {/* </Row>:null} */}


                                  <Row style={rowStyle}>
                                  {ticket.booking?(ticket.booking.length!==0? (ticket.booking.length==1 ?<Col style={{fontFamily: "Muli",
                                                fontWeight:500,
                                                fontStyle: "normal",
                                                letterSpacing: 0,
                                                color: "#817b85",
                                                marginTop:16,
                                                borderRadius: 4,
                                                backgroundColor: "#f1f1f1",
                                                padding:11,
                                                marginLeft:5
                                                }}> Booking ID {' '} 
                                                <text style={{
                                                fontFamily: "Muli",
                                                fontWeight: "bold",
                                                fontStyle: "normal",
                                                letterSpacing: 0,
                                                color: "#563951"}}>
                                                {
                                                 ticket.booking.map((item,index)=><text key={item.QF_CONSUMER_OFFER_ACCEPTED_PRID}> {item.QF_CONSUMER_OFFER_ACCEPTED_PRID}</text>)}
                                                </text></Col> :
                                                <Col style={{fontFamily: "Muli",
                                                fontWeight:500,
                                                fontStyle: "normal",
                                                letterSpacing: 0,
                                                color: "#817b85",
                                                marginTop:16,
                                                borderRadius: 4,
                                                backgroundColor: "#f1f1f1",
                                                padding:11,
                                                marginLeft:5
                                                }}> Booking IDs{' '} 
                                                <text style={{
                                                fontFamily: "Muli",
                                                fontWeight: "bold",
                                                fontStyle: "normal",
                                                letterSpacing: 0,
                                                color: "#563951"}}>
                                                {
                                                 ticket.booking.map((item,index)=><text key={item.QF_CONSUMER_OFFER_ACCEPTED_PRID}> {item.QF_CONSUMER_OFFER_ACCEPTED_PRID} {ticket.booking.length !== index+1 ? ',' : null}</text>)}
                                                </text></Col>) :null)
                                            //    <Col>
                                            //     <Button  active={this.state.refund? true : false} style={{backgroundColor:'#2C2165', fontFamily:'Muli',marginTop:10}}>Refund -></Button></Col>
                                                :null}                                             
                                              </Row>                            
 
                                
                            </CardText>
                        </CardBody>
                    </Card>
                );
            }) 
        } else {
            if(this.state.loading===true){
                payment= "Loading...";
            }else{
                if(this.state.payments.length===0&&!this.state.loading)
                payment = "No Payments";  
        }           
            
                     
        }

       

        if(this.state.refunds.length !== 0){
            refund = this.state.refunds.map((ticket) => {
                return ( 
                    <Card style={{marginTop: 10, borderColor: '#a78bba',width:'100%'}} >
                    <CardBody  >   
                    <CardTitle style={{fontSize:18}}  >  
                    <Row style={rowStyle}>
                            
                               <Col style={textDecor} >{ticket.MERCHANT_OFFER_HEADER_NAME?ticket.MERCHANT_OFFER_HEADER_NAME:ticket.SERVICE_NAME}</Col>
                               <Col >{ ticket.MERCHANT_OFFER_CURRENCY==="INR"?  
                               <Col style={{fontFamily: "Muli",fontSize: 18,fontWeight: "bold",fontStyle: "normal",letterSpacing: 0, textAlign: "right", color: "#63c2b7" }}>{'\u20B9'}{ticket.REFUND_AMOUNT.toFixed(2)}</Col>
                               :<Col style={{fontFamily: "Muli",fontSize: 18,fontWeight: "bold",fontStyle: "normal",letterSpacing: 0, textAlign: "right", color: "#63c2b7" }} >${ticket.REFUND_AMOUNT.toFixed(2)}</Col> }</Col>

                    </Row>
                    </CardTitle>                                                    
                        <CardText >
                       
                            <Row style={rowStyle}>
                                <Col  style={textDecor}>{ticket.mer_name[0].MERCHANT_NAME}</Col>
                              
                            </Row>
                            <hr style={{marginTop:14,
                              height: 1,
                              borderWidth: 1,
                              borderColor: "#ded3eb"}}/>

                            <Row style={rowStyle}>
                                <Col sm={{size:4}} ><text style={fWeightSpan}>{ticket.QF_MERCHANT_SERVICE_PRID!=null?'Service Date':'Offer Date'}</text></Col>
                                  <Col sm={{size:4}} ><text style={fWeightSpan}>Refund Date</text></Col> 
                                <Col sm={{size:4}} ><text style={fWeightSpan}>Time</text></Col>
                          
                            </Row>
                            <Row style={rowStyle}>
                            <Col style={textDecor1}><text>{momenttz(ticket.MERCHANT_OFFER_FROM_DATE_TIME?ticket.MERCHANT_OFFER_FROM_DATE_TIME+"Z":ticket.BOOKING_FROM_DATE_TIME+"Z").tz(ticket.MERCHANT_OFFER_TIMEZONE?ticket.MERCHANT_OFFER_TIMEZONE:ticket.SERVICE_TIMEZONE).format("D MMM YY")}</text></Col>
                            <Col style={textDecor}><text> { momenttz(ticket.REFUND_CREATED_AT+"Z").tz(ticket.MERCHANT_OFFER_TIMEZONE?ticket.MERCHANT_OFFER_TIMEZONE:ticket.SERVICE_TIMEZONE).format("D MMM YY")} </text></Col>
                            
                            {/* {ticket.MERCHANT_OFFER_TIMEZONE==='Asia/Kolkata' ? */}
                            <Col style={textDecor1}><text>{momenttz(ticket.REFUND_CREATED_AT+"Z").tz(ticket.MERCHANT_OFFER_TIMEZONE?ticket.MERCHANT_OFFER_TIMEZONE:ticket.SERVICE_TIMEZONE).format("hh:mmA z")} </text></Col>
                            {/* :
                            <Col style={textDecor1}><text>{momenttz(ticket.REFUND_CREATED_AT+"Z").tz(ticket.MERCHANT_OFFER_TIMEZONE).format("hh:mmA")} PDT</text></Col>
                             } */}
                            
                                </Row>
                            <Row style={rowStyle}>
                            <Col style={{fontFamily: "Muli",
                                                fontWeight:500,
                                                fontStyle: "normal",
                                                letterSpacing: 0,
                                                color: "#817b85",
                                                marginTop:16,
                                                borderRadius: 4,
                                                backgroundColor: "#f1f1f1",
                                                padding:11,
                                                marginLeft:5
                                                }}> Booking ID  <text 
                                                style={{
                                                    fontFamily: "Muli",
                                                    fontWeight: "bold",
                                                    fontStyle: "normal",
                                                    letterSpacing: 0,
                                                    color: "#563951"}}
                                                >{ticket.QF_CONSUMER_OFFER_ACCEPTED_PRID}</text> 
                                                
                                                 </Col>
                            </Row>                            
                            
                        </CardText>
                    </CardBody>
                </Card>
                );
            })
        } else {

            
                if(this.state.refunds.length===0&&this.state.loading===false)
               {

                 refund= "No Refunds";
               }  
             
           
         
            
        }
        let paymentStyle,refundStyle;
        if(this.state.payment){
            paymentStyle={
                backgroundColor:'#2C2165', 
                fontFamily:'Muli'
            }
        }
        if(this.state.refund){
            refundStyle={
                backgroundColor:'#2C2165', 
                fontFamily:'Muli'
            }
        }

        return (
            <div>
            {/* <Breadcrumb active0={'My Tickets'} 
                        active0Link={'/home/myTicket'}/> */}
            <Container fluid>
            <div className="row justify-content-center" style={{marginTop: 20, marginBottom: 20}}>
            <div className="col-3" >
            
               <ButtonGroup >

                <Button onClick={this.paymentHandler} active={this.state.payment? true : false} style={paymentStyle}>Payments</Button>
                <Button onClick={this.refundHandler} active={this.state.refund? true : false} style={refundStyle}>Refunds </Button> 
               </ButtonGroup>
                
            
            </div>
            </div>
            <Container fluid style={{width: 600}}> 
            <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/>

                {this.state.payment ? payment : null}  
                 {this.state.refund ? refund : null}  
              
            </Container>                
                 
            <ToastContainer autoClose={2500}/>   
            </Container>              
            </div>
                     

        )
    }
} 
const mapStateToProps=state=>{
    return {
      id:state.id 
  }
  }  


export default connect(mapStateToProps)(UserpaymentTrans);

  