import React, { Component } from 'react';
import { Form,FormGroup, FormFeedback, Button, Label, Input, Row,Col,Container } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import {BarLoader} from 'react-spinners';


class CustDetails extends Component {  
    
    state = {
        bugName: {
            value: '',
            valid: false,
            invalid: false
        },
        type: {
            value: 0,
            valid: false,
            invalid: false
        },
        pageLocation: {
            value: '',
            valid: false,
            invalid: false
        },
        comment: {
            value: '',
            valid: false,
            invalid: false
        },
        severity: {
            value: 0,
            valid: false,
            invalid: false
        },
        submit: {
            invalid: false
        },
        loading:false,
        fClicked:false
    }

    bugNameHandler = (event) => {
   
        
        if(event.target.value.length === 0){
            return this.setState({
                bugName:{
                    ...this.state.bugName,
                    value:event.target.value,
                    valid: false,
                    // invalid: true
                }
            })
        } else {
            return this.setState({
                bugName : {
                    ...this.state.bugName,
                    value: event.target.value,
                    valid: true,
                    invalid: false
                },
                submit: {
                    ...this.state.submit,
                    invalid: false
                }
            });
        }        
    } 
    typeHandler = (e) =>{
    
        if(e.target.value==='0')
        {
             this.setState({
                type:{
                     ...this.state.type,
                     value: null,
                     valid: false,
                    //  invalid: true
                },
               
            })
    
        }
        
        else{   
        this.setState({
                type:{
                    ...this.state.type,
                   value:e.target.value,
                   valid:true,invalid:false
                }, submit: {
                    invalid: false
                }
            })
    
    
        }
    }
    pageLocationHandler = (event) => {
        if(event.target.value.length === 0){
            return this.setState({
                pageLocation:{
                    ...this.state.pageLocation,
                    value:event.target.value,
                    valid: false,
                    // invalid: true
                }
            })
        } else {
            return this.setState({
                pageLocation : {
                    ...this.state.pageLocation,
                    value: event.target.value,
                    valid: true,
                    invalid: false
                },
                submit: {
                    ...this.state.submit,
                    invalid: false
                }
            });
        }        
    }

    commentFieldHandler = (event) => {
        
        if(event.target.value.length === 0){
            return this.setState({
                comment:{
                    ...this.state.comment,
                    value:event.target.value,
                    valid: false,
                    // invalid: true
                }
            })
        } else {
            return this.setState({
                comment : {
                    ...this.state.comment,
                    value: event.target.value,
                    valid: true,
                    invalid: false
                },
                submit: {
                    ...this.state.submit,
                    invalid: false
                }
            });
        }        
    } 
    severityHandler = (e) =>{
    
        if(e.target.value === '0')
        {
             this.setState({
                severity:{
                     ...this.state.severity,
                     value:null,
                     valid: false,
                    //      invalid: true
                },
               
            })
    
        }
        
        else{   
        this.setState({
                severity:{
                    ...this.state.severity,
                   value:e.target.value,
                   valid:true,invalid:false
                }, submit: {
                    invalid: false
                }
            })
    
    
        }
    }

    submitButtonHandler = () => {
        // console.log('sever',this.state.severity.value)
       
        if(this.state.bugName.invalid || this.state.bugName.value.length === 0){
            this.setState({
                submit: {
                    ...this.state.submit,
                    invalid: true 
                },
                bugName:{
                    ...this.state.bugName,
                    valid: false,
                    invalid: true
                }
            });
            return false;
            // return toast.error("Bug identity name is Invalid !", {
            //     position: toast.POSITION.BOTTOM_CENTER
            //   });
        }
        else if(this.state.type.value===0 || this.state.type.value==='--select--' || this.state.type.value === null){

            this.setState({
                 type:{
                     ...this.state.type,
                     invalid:true,
                     valid:false
                 },
                 submit: {
                    ...this.state.submit,
                     invalid: true 
                }
            })
          return false;
            // return toast.error("Select the Type of Bug", {
            //     position: toast.POSITION.BOTTOM_CENTER
            //   });
        } else if(this.state.severity.value === 0 || this.state.severity.value === null || this.state.severity.invalid || this.state.severity.value === '--select--'){
                this.setState({
                    severity:{
                        ...this.state.severity,
                        invalid:true,
                        valid:false
                    },
                    submit: {
                    ...this.state.submit,
                        invalid: true 
                }
            })
            return false;
        }        
        else if(this.state.pageLocation.invalid || this.state.pageLocation.value.length === 0){
            this.setState({
                submit: {
                    ...this.state.submit,
                    invalid: true 
                },
                pageLocation:{
                    ...this.state.pageLocation,
                    valid: false,
                    invalid: true
                }
            });
            return false;
            // return toast.error("Page location is Invalid !", {
            //     position: toast.POSITION.BOTTOM_CENTER
            //   });
        } else if(this.state.comment.invalid || this.state.comment.value.length === 0 || (this.state.comment.value).trim() === ''){
            this.setState({
                submit: {
                    ...this.state.submit,
                    invalid: true 
                },
                comment:{
                    ...this.state.comment,
                    valid: false,
                    invalid: true
                }
            });
            return false;
            // return toast.error("Field is Invalid !", {
            //     position: toast.POSITION.BOTTOM_CENTER
            //   });
        }
        
        else {
            this.setState({
                ...this.state,
                fClicked:true,

                submit: {
                    ...this.state.submit,
                    invalid: false
                },
                bugName:{
                    ...this.state.bugName,
                    valid: true,
                    invalid: false
                },
                pageLocation:{
                    ...this.state.pageLocation,
                    valid: true,
                    invalid: false
                },
                type:{
                    ...this.state.type,
                    valid: true,
                    invalid: false
                },
                comment:{
                    ...this.state.comment,
                    valid: true,
                    invalid: false
                },
                severity:{
                    ...this.state.severity,
                    valid: true,
                    invalid: false
                }, 
                loading:true,
                
            });

            let bugReportObj={
                Type:'bug',
                bugName:this.state.bugName.value,
                type:this.state.type.value,
                pageLocation:this.state.pageLocation.value,
                comment:this.state.comment.value,
                severity:this.state.severity.value
            }
            
            axios.post('/support/consumer/feedbackOrBugReport',bugReportObj)
            .then((res) => {
                if(res.data.data){
                this.setState(
                    {
                        bugName:{  
                            ...this.state.bugName,
                             value:''
                        },
                        type:{
                            ...this.state.type,
                             value:0
    
                        },
                        comment:{
                            ...this.state.comment,
                            value:''
    
                        },
                        pageLocation:{
                            ...this.state.pageLocation,
                             value:''
    
                        },severity:{
                            ...this.state.severity,
                            value:0
                        },
                        loading:false,
                        fClicked:false
    
                    }
                )
                toast.success("Bug Report Successfully Submitted",{position:toast.POSITION.BOTTOM_CENTER});
            }
            else{
                if(res.data.dbError===true)
                 {
                    this.setState({
                        fClicked:false,
                        loading:false
                    })

                     
                    toast.error("Internal Server Error",{position:toast.POSITION.BOTTOM_CENTER});
                }
             
                
            }
        }).catch(err=>{
            toast.warn("Unable to Reach Server! Please Try Again!" , {
                position: toast.POSITION.BOTTOM_CENTER
              });
        })
                this.setState({loading:false,
                    fClicked:false
                })
    
        }
         
        }
     
        
      
    
    render() {
        const rowStyle={
            textAlign:"initial"
        }

        const fWeightSpan={
            fontWeight:"500",
            marginRight: 5,
            fontFamily: 'Muli',
            color: '#868686'
        }
        const fontText1 = {
            fontWeight:'bold',
            fontFamily: 'Muli',
            color: 'red'                        
        }
        return (
            <div>
                <Container fluid>
            <div className="row justify-content-center" style={{marginTop: 50, backgroundColor: '#EFEFEF'}}>
            <div className="col-3" >
               <Form>
                   <FormGroup row>
                        <Label for="Bug"><text style={fWeightSpan}>Bug Identity Name </text><span style={{color: 'red'}}>*</span></Label>
                        <Input  onChange={this.bugNameHandler}
                                value={this.state.bugName.value}
                                valid={this.state.bugName.valid ? true : false}
                                invalid={this.state.bugName.invalid ? true : false}
                                placeholder="Please enter Bug Identity Name" />
                        <FormFeedback>Bug identity name shouldn't be empty</FormFeedback>
                   </FormGroup>

                    <Row>
                        <Col>
                            <FormGroup row>
                                    <Label for="Type" ><text style={fWeightSpan}>Type </text><span style={{color: 'red'}}>*</span></Label>
                                    <Input type="select" value={this.state.type.value} 
                                    onChange={this.typeHandler}
                                    valid={this.state.type.valid ? true : false}
                                    invalid={this.state.type.invalid ? true : false} 
                                    style={{fontFamily: 'Muli',color: '#563951'}}>
                                        <option value={0}>--Select--</option>
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                        <option value={5}>5</option>
                                    </Input>
                                <FormFeedback>Select Type</FormFeedback>                                                

                            </FormGroup>
                        </Col>

                        <Col style={{marginLeft: 10}}>
                            <FormGroup row>
                            <Label for="Type" ><text style={fWeightSpan}>Severity </text><span style={{color: 'red'}}>*</span></Label>
                            <Input type="select" onChange={this.severityHandler}
                                    valid={this.state.severity.valid ? true : false}
                                    invalid={this.state.severity.invalid ? true : false}
                                    style={{fontFamily: 'Muli',color: '#563951'}}
                                    value={this.state.severity.value}>
                                        <option value={0}>--Select--</option>
                                        <option value={1}>Very Low</option>
                                        <option value={2}>Low</option>
                                        <option value={3}>Medium</option>
                                        <option value={4}>High</option>
                                        <option value={5}>Very High</option>
                                    </Input>
                                <FormFeedback>Select Severity</FormFeedback>                                                
                                    
                            </FormGroup>
                        </Col>

                    </Row>
                    
                    <Row>

                        <Col>
                            <FormGroup row>  
                                <Label for="Page_Location" ><text style={fWeightSpan}>Page-Location</text> <span style={{color: 'red'}}>*</span></Label>                     
                                <Input  onChange={this.pageLocationHandler}
                                value={this.state.pageLocation.value}
                                        valid={this.state.pageLocation.valid ? true : false}
                                        invalid={this.state.pageLocation.invalid ? true : false}
                                        style={{fontFamily: 'Muli',color: '#563951'}}
                                        placeholder="Please enter Page Location"/>
                                <FormFeedback>Page-Location shouldn't be empty</FormFeedback>                        
                            </FormGroup>
                        </Col>
                    </Row>
                    
                <Row>
                    <Col>
                        <FormGroup row>
                            <Label for="Text"><text style={fWeightSpan}>Comment </text><span style={{color: 'red'}}>*</span></Label>
                            <Input  type="textarea" 
                                    onChange={this.commentFieldHandler}
                                    valid={this.state.comment.valid ? true : false}
                                    invalid={this.state.comment.invalid ? true : false} 
                                    value={this.state.comment.value}
                                    maxLength={1000}
                                    placeholder="Please type your comment"
                                    style={{height:100,fontFamily: 'Muli',color: '#563951'}} />
                            <FormFeedback>Comment field shouldn't be empty</FormFeedback>                                                
                        </FormGroup> 
                    </Col>

                   
                </Row>
                    
                    
                    <FormGroup>
                    <Button onClick={this.submitButtonHandler} style={{backgroundColor:"#2C2165", color:'white'}} disabled={this.state.fClicked}><text style={{fontFamily:'Muli'}}>Submit</text></Button>
                    
                    </FormGroup>

                <ToastContainer />
                <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/>

               </Form>
            </div>
            </div>
            </Container>
            </div>
        )
    }
} 

export default CustDetails;