import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { withRouter } from 'react-router-dom';
class Example extends React.Component {
  state= {
    url:  this.props.match.url.split("/"),
    // urlName: null,
    breadArray: []
  }

  componentDidMount(){
    let urlName= null;
    if(this.state.url.length === 5){

      if(this.state.url[4] !== this.state.urlName)
      // console.log("URL", this.state)
      urlName= this.state.url[4];
      this.setState(prevState => {
        return prevState.breadArray.push(urlName)
      })
  } else {
    this.setState({
      ...this.state,
      breadArray: []
    })
  }
}
// booking: this.props.consumerData
  // } 

  active1Handler = () =>{
      this.props.history.push({
        pathname: this.props.active1link,
        state: {
            active: 1,
            active0: this.props.active0,
            active0Link:this.props.active0Link,
            TicketId: this.state.booking[0].QF_CONSUMER_PRID,
            consumerData: {
              QF_CONSUMER_OFFER_ACCEPTED_PRID: this.state.booking[0].QF_CONSUMER_OFFER_ACCEPTED_PRID,
              CONSUMER_SUPPORT_CATEGORY: this.state.booking[0].CONSUMER_SUPPORT_CATEGORY,
              CONSUMER_SUPPORT_DESC: this.state.booking[0].CONSUMER_SUPPORT_DESC,
              CONSUMER_SUPPORT_STATUS: this.state.booking[0].CONSUMER_SUPPORT_STATUS,
              QF_CONSUMER_SUPPORT_PRID: this.state.booking[0].QF_CONSUMER_SUPPORT_PRID
            }
        }
    })
    
  }

  render(){
    // console.log("Breadcrumb", this.props);
  return (
    <div>
      <Breadcrumb tag="nav" listTag="div">

        {this.props.breadCrumb ? <BreadcrumbItem tag="a" href='/home/customerDetails'><text style={{color: '#0645AD'}}>Home</text></BreadcrumbItem> : null }

        {

          this.state.url[3] === "ConsumerSearchDetails" ? <BreadcrumbItem active={ this.props.consumer ? false : true} tag={this.props.consumer ? 'a' : "span"} href={this.props.consumer ? this.props.consumerLink : null}><text style={{color: this.props.consumer ? '#0645AD' : null, cursor:'pointer'}}>{this.props.consumer ? this.props.consumer : null}</text></BreadcrumbItem> : 
          this.state.url[3] === "BookingIdSearch" ? <BreadcrumbItem active={ this.props.booking ? false : true} tag={this.props.booking ? 'a' : "span"} href={this.props.booking ? this.props.bookingLink : null}><text style={{color: this.props.booking ? '#0645AD' : null, cursor:'pointer'}}>{this.props.booking ? this.props.booking : null}</text></BreadcrumbItem> : 
          this.state.url[3] === "MerchantSearchDetails" ? <BreadcrumbItem active={ this.props.merchant ? false : true} tag={this.props.merchant ? 'a' : "span"} href={this.props.merchant ? this.props.merchantLink : null}><text style={{color: this.props.merchant ? '#0645AD' : null, cursor:'pointer'}}>{this.props.merchant ? this.props.merchant : null}</text></BreadcrumbItem> : 
          this.state.url[3] === "OfferIdSearchDetails" ? <BreadcrumbItem active={ this.props.offer ? false : true} tag={this.props.offer ? 'a' : "span"} href={this.props.offer ? this.props.offerLink : null}><text style={{color: this.props.offer ? '#0645AD' : null, cursor:'pointer'}}>{this.props.offer ? this.props.offer : null}</text></BreadcrumbItem> : 
          this.state.url[3] === "CouponSearchDetails" ? <BreadcrumbItem active={ this.props.coupon ? false : true} tag={this.props.coupon ? 'a' : "span"} href={this.props.coupon ? this.props.couponLink : null}><text style={{color: this.props.coupon ? '#0645AD' : null, cursor:'pointer'}}>{this.props.coupon ? this.props.coupon : null}</text></BreadcrumbItem> : 
          this.state.url[3] === "ConsumerSearchTicketId" ? <BreadcrumbItem active={ this.props.csticket ? false : true} tag={this.props.csticket ? 'a' : "span"} href={this.props.csticket ? this.props.csticketLink : null}><text style={{color: this.props.csticket ? '#0645AD' : null, cursor:'pointer'}}>{this.props.csticket ? this.props.csticket : null}</text></BreadcrumbItem> : 
          null
       }
       {
        
          this.state.url[4] === "ConsumerSearchDetails" ? <BreadcrumbItem active={ this.props.consumer ? false : true} tag={this.props.consumer ? 'a' : "span"} href={this.props.consumer ? this.props.consumerLink : null}><text style={{color: this.props.consumer ? '#0645AD' : null, cursor:'pointer'}}>{this.props.consumer ? this.props.consumer : null}</text></BreadcrumbItem> : 
          this.state.url[4] === "BookingIdSearch" ? <BreadcrumbItem active={ this.props.booking ? false : true} tag={this.props.booking ? 'a' : "span"} href={this.props.booking ? this.props.bookingLink : null}><text style={{color: this.props.booking ? '#0645AD' : null, cursor:'pointer'}}>{this.props.booking ? this.props.booking : null}</text></BreadcrumbItem> : 
          this.state.url[4] === "MerchantSearchDetails" ? <BreadcrumbItem active={ this.props.merchant ? false : true} tag={this.props.merchant ? 'a' : "span"} href={this.props.merchant ? this.props.merchantLink : null}><text style={{color: this.props.merchant ? '#0645AD' : null, cursor:'pointer'}}>{this.props.merchant ? this.props.merchant : null}</text></BreadcrumbItem> : 
          this.state.url[4] === "OfferIdSearchDetails" ? <BreadcrumbItem active={ this.props.offer ? false : true} tag={this.props.offer ? 'a' : "span"} href={this.props.offer ? this.props.offerLink : null}><text style={{color: this.props.offer ? '#0645AD' : null, cursor:'pointer'}}>{this.props.offer ? this.props.offer : null}</text></BreadcrumbItem> : 
          this.state.url[4] === "CouponSearchDetails" ? <BreadcrumbItem active={ this.props.coupon ? false : true} tag={this.props.coupon ? 'a' : "span"} href={this.props.coupon ? this.props.couponLink : null}><text style={{color: this.props.coupon ? '#0645AD' : null, cursor:'pointer'}}>{this.props.coupon ? this.props.coupon : null}</text></BreadcrumbItem> : 
          this.state.url[4] === "ConsumerSearchTicketId" ? <BreadcrumbItem active={ this.props.csticket ? false : true} tag={this.props.csticket ? 'a' : "span"} href={this.props.csticket ? this.props.csticketLink : null}><text style={{color: this.props.csticket ? '#0645AD' : null, cursor:'pointer'}}>{this.props.csticket ? this.props.csticket : null}</text></BreadcrumbItem> : 
          null
       }
       
      </Breadcrumb>
    </div>
  );
  }
};

export default withRouter(Example);

/////
// import React from 'react';
// import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
// import { withRouter } from 'react-router-dom';

// class Example extends React.Component {
//   state= {
//     booking: this.props.consumerData
//   }

//   active1Handler = () =>{
    // if(this.props.case === 'myTicket'){
    //   this.props.history.push({
    //     pathname: this.props.active1link,
    //     state: {
    //         case: 'myTicket',
    //         active: 1,
    //         active0: this.props.active0,
    //         active0Link:this.props.active0Link,
    //         TicketId: this.state.booking[0].QF_CONSUMER_PRID,
    //         consumerData:{
    //           QF_CONSUMER_OFFER_ACCEPTED_PRID: this.state.booking[0].QF_CONSUMER_OFFER_ACCEPTED_PRID,
    //           CONSUMER_SUPPORT_CATEGORY: this.state.booking[0].CONSUMER_SUPPORT_CATEGORY,
    //           CONSUMER_SUPPORT_DESC: this.state.booking[0].CONSUMER_SUPPORT_DESC,
    //           CONSUMER_SUPPORT_STATUS: this.state.booking[0].CONSUMER_SUPPORT_STATUS,
    //           QF_CONSUMER_SUPPORT_PRID: this.state.booking[0].QF_CONSUMER_SUPPORT_PRID
    //         }
    //     }
    // })
    // }    
  // }

  // caseHandler = () => {
  //   this.props.history.push({})
  // }
  // case0Handler = () => {}
  // case1Handler = () => {}
  // case2Handler = () => {}
  // case3Handler = () => {}
  // case4Handler = () => {}

  // render(){
  // return (
  //   <div>
      {/* <Breadcrumb tag="nav" listTag="div">
        {this.props.active ? <BreadcrumbItem tag="a" href={this.props.active}><text style={{color: '#0645AD'}}>Home</text></BreadcrumbItem> : null }
        {this.props.active0 ? <BreadcrumbItem active={ this.props.active1 ? false : true} tag={this.props.active1 ? 'a' : "span"} href={this.props.active1 ? this.props.active0Link : null}><text style={{color: this.props.active1 ? '#0645AD' : null, cursor:'pointer'}}>{this.props.active0}</text></BreadcrumbItem> : null }
        {this.props.active1 ? <BreadcrumbItem active={ this.props.active2 ? false : true} tag={this.props.active2 ? 'a' : "span"} > <text onClick={this.active1Handler} style={{color: this.props.active2 ? '#0645AD' : null,cursor:'pointer'}}>{this.props.active1}</text></BreadcrumbItem> : null }
        {this.props.active2 ? <BreadcrumbItem active tag={this.props.active3 ? 'a' : "span"} href={this.props.active1 ? this.props.active0Link : null}><text onClick={this.active1Handler} style={{color: this.props.active3 ? '#0645AD' : null,cursor:'pointer'}}>{this.props.active2}</text></BreadcrumbItem> : null }
        {this.props.active3 ? <BreadcrumbItem active tag={this.props.active4 ? 'a' : "span"} href={this.props.active1 ? this.props.active0Link : null}>{this.props.active3}</BreadcrumbItem> : null }
        {this.props.active4 ? <BreadcrumbItem active tag="span">{this.props.active4}</BreadcrumbItem> : null }
      </Breadcrumb> */}

      {/* <Breadcrumb tag="nav" listTag="div">

        {this.props.case ? <BreadcrumbItem tag="a" ><text style={{color: this.props.case1 ? '#0645AD' : null,cursor:'pointer'}} onClick={this.caseHandler}>Home</text></BreadcrumbItem> : null }
        {this.props.case0 ? <BreadcrumbItem tag="a" ><text style={{color: this.props.case1 ? '#0645AD' : null,cursor:'pointer'}} onClick={this.case0Handler}>My Ticket</text></BreadcrumbItem> : null }
        {this.props.case1 ? <BreadcrumbItem tag="a" ><text style={{color: this.props.case2 ? '#0645AD' : null,cursor:'pointer'}} onClick={this.case1Handler}>Support Id Details</text></BreadcrumbItem> : null }
        {this.props.case2 ? <BreadcrumbItem tag="a" ><text style={{color: this.props.case3 ? '#0645AD' : null,cursor:'pointer'}} onClick={this.case2Handler}>Offer Booking Details</text></BreadcrumbItem> : null }
        {this.props.case3 ? <BreadcrumbItem tag="a" ><text style={{color: this.props.case4 ? '#0645AD' : null,cursor:'pointer'}} onClick={this.case3Handler}>Offer Details</text></BreadcrumbItem> : null }
        {this.props.case4 ? <BreadcrumbItem tag="a" ><text style={{color: this.props.case5 ? '#0645AD' : null,cursor:'pointer'}} onClick={this.case4Handler}>Merchant Details</text></BreadcrumbItem> : null }

      </Breadcrumb>
    </div>
  );
  }
};

export default withRouter(Example); */}



// import React from 'react';
// import { Route, Link } from 'react-router-dom';
// import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

// const routes = {
//   '/': 'Home',
//   '/settings': 'Settings',
//   '/settings/a': 'A',
//   '/settings/a/b': 'B',
// };

// const findRouteName = url => routes[url];

// const getPaths = (pathname) => {
//   const paths = ['/'];

//   if (pathname === '/') return paths;

//   pathname.split('/').reduce((prev, curr, index) => {
//     const currPath = `${prev}/${curr}`;
//     paths.push(currPath);
//     return currPath;
//   });

//   return paths;
// };

// const BreadcrumbsItem = ({ ...rest, match }) => {
//   const routeName = findRouteName(match.url);
//   if (routeName) {
//     return (
//       match.isExact ?
//       (
//         <BreadcrumbItem active>{routeName}</BreadcrumbItem>
//       ) :
//       (
//         <BreadcrumbItem>
//           <Link to={match.url || ''}>
//             {routeName}
//           </Link>
//         </BreadcrumbItem>
//       )
//     );
//   }
//   return null;
// };

// const Breadcrumbs = ({ ...rest, location : { pathname }, match }) => {
//   const paths = getPaths(pathname);
//   return (
//     <Breadcrumb>
//       {paths.map(p => <Route path={p} component={BreadcrumbsItem} />)}
//     </Breadcrumb>
//   );
// };

// export default props => (
//   <div>
//     <Route path="/:path" component={Breadcrumbs} {...props} />
//   </div>
// );