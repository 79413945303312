import React, { Component } from 'react';
import { Form, FormGroup, Table,ListGroup, ListGroupItem, Popover, PopoverHeader, PopoverBody, Label, Input, Button, Container,Card, CardBody, CardText, Row, Col, } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import { BarLoader } from "react-spinners";


class Category extends Component {

    state={
        create: this.props.location.state ? this.props.location.state.create : false,
        assignedView: this.props.location.state ? this.props.location.state.view : true,
        view: false,
        categories: [],
        cit: [],
        loading:false,
        category : {
            value: this.props.location.state ? this.props.location.state.id : '-1',
            valid: false,
            invalid: false
        },
        subCategoryName : {
            value: '',
            valid: false,
            invalid: false
        },
        submit :{
            invalid: false
        },
        image: {
            value: null,
            link:null,
            display: null,
            invalid: false
        },
        images:[],
        submitButton: true,
        open:false,
    
        loading:false
        
    }


    componentDidMount(){
        this.setState({
            loading: true
        });

        let id= this.props.location.state ? this.props.location.state.id : null;
        // console.log("Id", id)

        axios.post("/support/coupon/categoryDetails", {id})
        .then((res) => {
            if(res.data.result){
                if(res.data.category !== null){
                    this.setState({
                        ...this.state,
                        categories: res.data.category,
                        loading: false,
                        
                    });                    
                } else {
                    this.setState({
                        ...this.state,
                        loading: false
                    }); 
                }
            } else {
                if(res.data.dbError){
                    toast.error("Internal Server Error", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                } else {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                }
            }
       })
       .catch((err) => {
           console.log("Error",err);
            toast.error("Server Error", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({
                ...this.state,
                loading: false
            });
       });


    }


    searchHandler=()=>{
        this.setState({
            ...this.state,
            open:true
        })
        }

    categoryHandler = (event) => {



        let id = event.target.value;
        // console.log('bnbn',id)
        this.setState({
            ...this.state,
            open:false,
            category:{
                ...this.state.category,
                value :  event.target.value
            },
            subCategory:{
                ...this.state.subCategory,
                value: '',
            },
            // country:{

            //     ...this.state.country,
            //     value:'select'

            // }
            // loading: true
        });
        if(event.target.value !== "-1"){
        axios.post("/support/byCategory/subCategoryDetails", {id})
            .then((res1) => {
                console.log("city", res1.data.cities);
                console.log("sub", res1.data.sub_category);
                if(res1.data.data){
                    if(res1.data.city && res1.data.subCat){
                        this.setState({
                            ...this.state,
                            subCategoriess: res1.data.sub_category,
                            cities: res1.data.cities,
                            loading: false
                        });
                    } else if(res1.data.city && !res1.data.subCat){
                        this.setState({
                            ...this.state,
                            subCategoriess: [],
                            cities: res1.data.cities,
                            loading: false
                        });
                    } else if(!res1.data.city && res1.data.subCat){
                        this.setState({
                            ...this.state,
                            subCategoriess: res1.data.sub_category,
                            cities: [],
                            loading: false
                        });
                    } else {
                        this.setState({
                            ...this.state,
                            subCategoriess: [],
                            cities: [],
                            loading: false
                        });
                    }
                } else {
                    if(res1.data.dbError){
                        toast.error("Internal Server Error", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        this.setState({
                            ...this.state,
                            loading: false
                        })
                    } else {
                        this.setState({
                            ...this.state,
                            loading: false
                        });                     
                    }                    
                }
            })
            .catch((err) => {
                console.log("Error",err);
              
                toast.error("Server Error", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.setState({
                    ...this.state,
                    loading: false
                }); 
            });
        } else if(event.target.value === "-1" ){
            this.setState({
                ...this.state,
                subCategoriess:[],
                category:{
                    ...this.state.category,
                    value : ''
                },
                subCategory:{
                    ...this.state.subCategory,
                    value: '',
                },
                // country:{
                //     ...this.state.country,
                //     value:'select'
                // }

            });
            axios.get("/support/fetch/city")
           .then((res) => {
            //    console.log("City", res.data.data);
               if(res.data.data){
                    if(res.data.result !== null){
                        this.setState({
                            ...this.state,
                            cities: res.data.result,
                            loading: false
                        });
                    } else {
                        this.setState({
                            ...this.state,
                            loading: false
                        });
                    }                
               } else {
                if(res.data.dbError){
                    toast.error("Internal Server Error", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                } else {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                }
               }
               
           })
           .catch((err) => {
            console.log("Error",err);

                toast.error("Server Error", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.setState({
                    ...this.state,
                    loading: false
                });
           });
        }

        
    }



subHandler=(event)=>{
    let id = event.target.value;
        console.log('bnbn',id)
    
    
    // console.log("Id", id)

    axios.post("/support/country/city", {id})
    .then((res) => {
        
        if(res.data.result){
            console.log('city',res.data.result)
            if(res.data.result){
                console.log('data',res.data.result)
                this.setState({
                    ...this.state,
                    cit: res.data.result,
                    loading: false
                });                   
            } else {
                this.setState({
                    ...this.state,
                    loading: false
                }); 
            }
        } else {
            if(res.data.dbError){
                toast.error("Internal Server Error", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.setState({
                    ...this.state,
                    loading: false
                });
            } 
            // else {  
            //     this.setState({
            //         ...this.state,
            //         loading: false
            //     });
            // }
        }
   })
   .catch((err1) => {
       console.log("Error",err1);
        toast.error("Server Error", {
            position: toast.POSITION.BOTTOM_CENTER
        });
        
   });


}

    
    render() {

        console.log('state1',this.state)
        let categoryOptions = null;
        if(this.state.categories.length !== 0){ 
            categoryOptions = this.state.categories.map((option) => {
                return <option  value={option.qf_service_category_prid} key={option.qf_service_category_prid}>{option.category_name}</option>;
            });
        } 

        let subCategory = null;
        if(this.state.subCategoriess){ 
            subCategory = this.state.subCategoriess.map((option) => {
                return (
                    
                 <li style={{textAlign:'initial',cursor:'pointer'}} value={option.qf_service_sub_category_prid} key={option.qf_service_sub_category_prid}>
                     {option.service_sub_category_name}</li>

                

                )  
            });
        } 

        let Citys = null;
        if(this.state.cities){ 
            Citys = this.state.cities.map((option) => {
                return (
                    
                 <li  style={{textAlign:'initial'}}value={option.qf_service_category_prid} key={option.QF_CITY_PRID}>
                     {option.CITY_NAME},{option.COUNTY_DISTRICT_NAME},{option.STATE_PROVINCE_NAME},{option.COUNTRY_NAME}</li>

                

                )  
            });
        } 


        let Cit = null;
        if(this.state.cit){ 
            Cit = this.state.cit.map((option) => {
                return (
                    
                 <li  style={{textAlign:'initial'}}value={option.qf_service_category_prid} key={option.QF_CITY_PRID}>
                     {option.CITY_NAME},{option.COUNTY_DISTRICT_NAME},{option.STATE_PROVINCE_NAME},{option.COUNTRY_NAME}</li>

                

                )  
            });
        } 

        return (      
                <div>
                <Container fluid>
            <div className="row justify-content-center" style={{backgroundColor: '#EFEFEF'}}>
                <div className="col-3" >
                    <Form>
                        <FormGroup row>
                            <Label for="Id" ><text style={{fontFamily:'Muli'}}>Search Category </text><span style={{color: 'red'}}>*</span></Label>
                            <Input onChange={this.categoryHandler} type="select" invalid={this.state.category.invalid} valid={this.state.category.valid}>
                                    <option value='-1'>--select--</option>
                                    {categoryOptions}
                                
                                </Input>
                            {/* <FormFeedback><text style={{fontFamily:'Muli'}}> Booking Id is invalid!</text></FormFeedback> */}
                        </FormGroup>
                       
                        <FormGroup>
                            <Button onClick={this.searchHandler} style={{backgroundColor:"#2C2165", color:'white'}} disabled={this.state.sClicked}><text style={{fontFamily:'Muli'}}>Search</text></Button>
                            {/* disabled={this.state.submit.invalid ? true : false} */}
                        </FormGroup>
                    </Form>
                   
                    <ToastContainer />
                    
            </div>
            </div>
            </Container>
            {this.state.open?
                    <Card style={{marginLeft:370,width:'40%',marginTop: 10, border: '2px solid #a78bba', backgroundColor:"white",borderRadius:10}}>
                        <CardBody  >                                                       
                            <CardText style={{color:''}}> 
                             <Row>
                              <Col><b><u>Subcategorys:</u></b><ul onClick={this.subHandler}>{subCategory}</ul></Col>  
                              <hr/>
                            
                            
                             {this.state.cit.length>0?
                              <Col style={{borderLeft:'1px solid black'}}>
                                 <b><u>AssignCities:</u></b><ol>{Cit}</ol>

                             </Col>  
                             :
                             <Col style={{borderLeft:'1px solid black'}}>
                                  <b><u>Cities:</u></b><ol>{Citys}</ol>

                             </Col> 
                             }
                             
                             </Row>
                             
                               
                            </CardText>
                        </CardBody>
                    </Card>
                :null}
            </div>
        )
    }
} 

export default Category;