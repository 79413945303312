import React, {Component} from 'react';
import { Route,Redirect } from 'react-router-dom'; 

import NavBeta from './navb';
import BetaSignUp from './BetaSignUp';
 
class Betaversion extends Component {

    

    render() {
        return (
            <div>
                <NavBeta {...this.props} />
                <div style={{marginTop: 20, marginBottom: 20}}> 

                


                    
                        <BetaSignUp />
                    
                    
            



                </div>           
            </div>
        );
    }
}
  

export default Betaversion;