import React,{ Component } from "react";
import { ButtonGroup, Button, Card, CardBody,CardTitle, CardText, Form,FormText, Row, Col, Container, Modal,ModalBody,ModalHeader,ModalFooter, Tooltip} from 'reactstrap';
import curr from "currency.js";
import momenttz from 'moment-timezone';
import axios from 'axios';
import {ToastContainer, toast } from 'react-toastify';
import {BarLoader} from 'react-spinners';
import moment from "moment";
import numeral from "numeral"


 
class MerchantPaymentsOfferview extends Component {
    state={
       transactions:this.props.location.state.selectedTrans,
       offers:[],
       margin:null 
    }

componentDidMount(){
    // console.log('sese',this.state.transactions)
    
    let body={
        mPayPrid:this.state.transactions[0].QF_MERCHANT_PAYMENT_PRID,
        mpid:this.state.transactions[0].QF_MERCHANT_PERSONNEL_PRID
    }
   
    axios.post("/support/merchantpayments/payments/offerview",body)
        .then((res) => {
             console.log("Coupon res", res.data);
            if(res.data.get){
               
             this.setState({
                    ...this.state,
                    offers: res.data.offer_final_array,
                    margin:res.data.margin,
                    loading: false

                })
                // console.log('fine',this.state.offers)
            } else {
                if(res.data.dbError)
                    {
                        toast.error("Internal Server Error",{
                            position:toast.POSITION.BOTTOM_CENTER
                        })
                        this.setState({
                            ...this.state,
                            offers: [],
                            loading: false
                    });
                    }
                    else {
                        this.setState({
                            ...this.state,
                            offers: [],
                            loading: false
        
                        })
                    }          
            }
        })
        .catch(err => {
            console.log(err);
            toast.warn("Unable to Reach Server! Please Try Again!" , {
                position: toast.POSITION.BOTTOM_CENTER
              });
            this.setState({
                ...this.state,
                offers: [],
                loading: false
        });
        })
    



    // console.log('props',this.props)
}
offerClick=(offerId)=>{
    // console.log('yes',offerId)
    let selectedOffer = this.state.offers.filter(each=>{
        return  each.offer_id===offerId
        })
        // console.log('haha',selectedOffer)
        let url=this.props.location.state.url ? this.props.location.state.url : this.props.match.url;
        this.props.history.push({
            pathname: url+'/bookingView',
            state:{
                // id:this.state.merchantPersonnel[0].QF_MERCHANT_PERSONNEL_PRID,
                // name:this.state.merchantDetails.name,
                  tz : this.props.location.state.tz,
                  mPayPrid:this.state.transactions[0].QF_MERCHANT_PAYMENT_PRID,
                 country:this.props.location.state.country,  
                 margin:this.state.margin,                
                 selectedOffer,
                url
               } 
            });
}
serviceClick=(serviceId)=>{
    let selectedOffer = this.state.offers.filter(each=>{
        return  each.service_id===serviceId
        })
        // console.log('haha',selectedOffer)
        let url=this.props.location.state.url ? this.props.location.state.url : this.props.match.url;
        this.props.history.push({
            pathname: url+'/bookingView',
            state:{
                // id:this.state.merchantPersonnel[0].QF_MERCHANT_PERSONNEL_PRID,
                // name:this.state.merchantDetails.name,
                  tz : this.props.location.state.tz,
                  mPayPrid:this.state.transactions[0].QF_MERCHANT_PAYMENT_PRID,
                 country:this.props.location.state.country,  
                 margin:this.state.margin,                
                 selectedOffer,
                url
               } 
            });
}
    render(){

        console.log('state',this.state);
        const rowStyle={
            textAlign:"initial",
        }
        const fWeightSpan={
            fontWeight:"400",
            fontFamily: 'Muli',
            padding: 2,
            color: '#868686',
            // cursor: 'pointer',
            // marginBottom:5,
            // border:'1px solid black',
            fontSize: 18
        }

        const fWeightSpan1={
            fontWeight:"400",
            padding: 5,            
            // cursor: 'pointer',
            marginBottom:10,
            // border:'1px solid black'
        }
        
        const textDecor = {
            fontWeight:"bold",
            fontFamily: 'Muli',
            // marginLeft: 5,
            color: '#563951'
        }
        const textDecor22 = {
            fontWeight:"bold",
            fontFamily: 'Muli',
            // marginLeft: 5,
            color: '#563951',
            marginTop:10
        }
        let transaction = <text style={textDecor}>No Transactions</text>;


        if(this.state.transactions.length !== 0){

            transaction = this.state.transactions.map((coupon) => {
                // console.log("Time", momenttz(coupon.MERCHANT_PAYMENT_CREATED_ON_DATE+"Z").tz(this.props.location.state.tz).format("DD MMM YYYY hh:mm"))
                return (
                    <Card>
                        <CardBody>
                            <CardText style={{marginLeft:30, marginRight: 30}}>
                                <Row style={rowStyle}>
                                    <Col sm={{size:4}}>
                                        <Row>
                                            <Col sm={{size:12}} style={fWeightSpan}><text> Payment Id </text> </Col>
                                            <Col sm={{size:12}} style={fWeightSpan}><text style={textDecor}>{coupon.QF_MERCHANT_PAYMENT_PRID}</text></Col>
                                        </Row>
                                    </Col>
                                    <Col sm={{size:4}}>
                                        <Row>
                                            <Col sm={{size:12}} style={fWeightSpan}><text>Payment Date</text></Col>
                                            <Col sm={{size:12}} style={fWeightSpan}><text style={textDecor}>{momenttz(coupon.MERCHANT_PAYMENT_CREATED_ON_DATE+"Z").tz(this.props.location.state.tz).format("DD MMM YYYY")}</text></Col>
                                        </Row>
                                    </Col>

                                    <Col sm={{size:4}}>
                                        <Row>                         
                                            <Col sm={{size:12}} style={fWeightSpan}><text>Status</text></Col>
                                            <Col sm={{size:12}} style={fWeightSpan}><text style={textDecor}>{coupon.MERCHANT_PAYMENT_STATUS_OUR===1? <text style={{color:"#f3972d"}}>Initiated</text> :coupon.MERCHANT_PAYMENT_STATUS_OUR===2? <text style={{color:"#f3972d"}}>Pending</text> :coupon.MERCHANT_PAYMENT_STATUS_OUR===3? <text style={{color:"#3dc5b6"}}>Processed</text> :coupon.MERCHANT_PAYMENT_STATUS_OUR===4? <text style={{color:"#ff5050"}}>Failed</text> :coupon.MERCHANT_PAYMENT_STATUS_OUR===5? <text style={{color:"#ff5050"}}>Cancelled</text>: <text style={{color:"#FFC459"}}>Unknown</text>}</text> </Col>
                                        </Row>
                                    </Col>
                                </Row> 
                            </CardText>
                        </CardBody>
                    </Card>                       
                )
            })



    }
    let offers=null;
    if(this.state.offers.length !== 0){
        // console.log('hhhhh',this.props.location.state.tz)

        offers = this.state.offers.map((coupon) => {
            if(coupon.offer_id){
                return (
                    <Card style={{marginTop:15, backgroundColor:'white',width:'100%',cursor:'pointer',borderBottom: '3px solid #a78bba'}} onClick={()=>this.offerClick(coupon.offer_id)} >
                        <CardBody>                                                   
                            <CardText style={{marginLeft:30, marginRight: 30}}>
                                <Row style={rowStyle}>
                                    <Col sm={{size:4}}>
                                        <Row>
                                            <Col sm={{size:12}} style={fWeightSpan}><text>Offer Id </text></Col>
                                            <Col sm={{size:12}} style={fWeightSpan}><text style={textDecor}>{coupon.offer_id}</text></Col>
                                        </Row>
                                    </Col>
    
                                    <Col sm={{size:4}}>
                                        <Row>
                                            <Col sm={{size:12}} style={fWeightSpan}><text> Offer Date </text> </Col>                            
                                            <Col sm={{size:12}} style={fWeightSpan}><text style={textDecor}>{moment(coupon.offer_date+"Z").tz(this.props.location.state.tz).format('DD MMM YYYY')}</text></Col>
                                        </Row>
                                    </Col> 
    
                                    <Col sm={{size:4}}>
                                        <Row>
                                            <Col sm={{size:12}} style={fWeightSpan}><text> Offer Name </text> </Col>                            
                                            <Col sm={{size:12}} style={fWeightSpan}><text style={textDecor}>{coupon.offer_name}</text></Col>
                                        </Row>
                                    </Col>      
                                </Row>
                                <Row style={{backgroundColor: '#f1f1f1', padding: 10, marginTop:10}}>
                                    <Col>
                                        <Row>
                                            <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5,fontFamily: 'Muli',color: '#868686'}}><text>Net Price </text> </Col>
                                            <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5, marginBottom:10}}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{numeral(coupon.offer_net_price).format("0.00")}</text></Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5,fontFamily: 'Muli',color: '#868686'}}><text>Platform Fee</text> </Col>
                                            <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5, marginBottom:10}}> <text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{numeral(numeral(this.state.margin).divide(100).value()).multiply(coupon.offer_net_price).format("0.00") }</text></Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col sm={{size:12}} style={{fontWeight:"400", padding: 5,fontFamily: 'Muli',color: '#868686'}}><text>Earnings</text>  </Col>
                                            <Col sm={{size:12}} style={{fontWeight:"400", padding: 5, marginBottom:10}}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{numeral(numeral(coupon.offer_net_price).subtract(numeral(numeral(this.state.margin).divide(100).value()).multiply(coupon.offer_net_price).value()).value()).format("0.00")}</text></Col>
                                                
                                        </Row>
                                    </Col>
                                   
                                </Row>
                                
                               
    
                                 
                            </CardText>
                        </CardBody>
                    </Card>
                )
            }
            if(coupon.service_id){
                return (
                    <Card style={{marginTop:15, backgroundColor:'white',width:'100%',cursor:'pointer',borderBottom: '3px solid #a78bba'}} onClick={()=>this.serviceClick(coupon.service_id)} >
                        <CardBody>                                                   
                            <CardText style={{marginLeft:30, marginRight: 30}}>
                                <Row style={rowStyle}>
                                    <Col sm={{size:4}}>
                                        <Row>
                                            <Col sm={{size:12}} style={fWeightSpan}><text>Service Id </text></Col>
                                            <Col sm={{size:12}} style={fWeightSpan}><text style={textDecor}>{coupon.service_id}</text></Col>
                                        </Row>
                                    </Col>
    
                                    <Col sm={{size:4}}>
                                        {/* <Row>
                                            <Col sm={{size:12}} style={fWeightSpan}><text> Offer Date </text> </Col>                            
                                            <Col sm={{size:12}} style={fWeightSpan}><text style={textDecor}>{moment(coupon.offer_date+"Z").tz(this.props.location.state.tz).format('DD MMM YYYY')}</text></Col>
                                        </Row> */}
                                    </Col> 
    
                                    <Col sm={{size:4}}>
                                        <Row>
                                            <Col sm={{size:12}} style={fWeightSpan}><text> Service Name </text> </Col>                            
                                            <Col sm={{size:12}} style={fWeightSpan}><text style={textDecor}>{coupon.service_name}</text></Col>
                                        </Row>
                                    </Col>      
                                </Row>
                                <Row style={{backgroundColor: '#f1f1f1', padding: 10, marginTop:10}}>
                                    <Col>
                                        <Row>
                                            <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5,fontFamily: 'Muli',color: '#868686'}}><text>Net Price </text> </Col>
                                            <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5, marginBottom:10}}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{numeral(coupon.offer_net_price).format("0.00")}</text></Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5,fontFamily: 'Muli',color: '#868686'}}><text>Platform Fee</text> </Col>
                                            <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5, marginBottom:10}}> <text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{numeral(numeral(this.state.margin).divide(100).value()).multiply(coupon.offer_net_price).format("0.00") }</text></Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col sm={{size:12}} style={{fontWeight:"400", padding: 5,fontFamily: 'Muli',color: '#868686'}}><text>Earnings</text>  </Col>
                                            <Col sm={{size:12}} style={{fontWeight:"400", padding: 5, marginBottom:10}}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{numeral(numeral(coupon.offer_net_price).subtract(numeral(numeral(this.state.margin).divide(100).value()).multiply(coupon.offer_net_price).value()).value()).format("0.00")}</text></Col>
                                                
                                        </Row>
                                    </Col>
                                   
                                </Row>         
                            </CardText>
                        </CardBody>
                    </Card>
                )
            }
            // return (
            //     <Card style={{marginTop:15, backgroundColor:'white',width:'100%',cursor:'pointer',borderBottom: '3px solid #a78bba'}} onClick={()=>this.offerClick(coupon.offer_id)} >
            //         <CardBody>                                                   
            //             <CardText style={{marginLeft:30, marginRight: 30}}>
            //                 <Row style={rowStyle}>
            //                     <Col sm={{size:4}}>
            //                         <Row>
            //                             <Col sm={{size:12}} style={fWeightSpan}><text>{coupon.list_price!=='null'?"Service Id":"Offer Id"} </text></Col>
            //                             <Col sm={{size:12}} style={fWeightSpan}><text style={textDecor}>{coupon.offer_id}</text></Col>
            //                         </Row>
            //                     </Col>

            //                     <Col sm={{size:4}}>
            //                         <Row>
            //                             <Col sm={{size:12}} style={fWeightSpan}><text>{coupon.list_price!=='null'?"Service Date":"Offer Date"} </text> </Col>                            
            //                             <Col sm={{size:12}} style={fWeightSpan}><text style={textDecor}>{moment(coupon.offer_date+"Z").tz(this.props.location.state.tz).format('DD MMM YYYY')}</text></Col>
            //                         </Row>
            //                     </Col> 

            //                     <Col sm={{size:4}}>
            //                         <Row>
            //                             <Col sm={{size:12}} style={fWeightSpan}><text>{coupon.list_price!=='null'?"Service Name":" Offer Name"} </text> </Col>                            
            //                             <Col sm={{size:12}} style={fWeightSpan}><text style={textDecor}>{coupon.offer_name}</text></Col>
            //                         </Row>
            //                     </Col>      
            //                 </Row>
            //                 <Row style={{backgroundColor: '#f1f1f1', padding: 10, marginTop:10}}>
            //                     {/* <Col>
            //                         <Row>
            //                             <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5,fontFamily: 'Muli',color: '#868686'}}><text>Net Price </text> </Col>
            //                             <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5, marginBottom:10}}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{coupon.offer_net_price.toFixed(2)}</text></Col>
            //                         </Row>
            //                     </Col>
            //                     <Col>
            //                         <Row>
            //                             <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5,fontFamily: 'Muli',color: '#868686'}}><text>Platform Fee</text> </Col>
            //                             <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5, marginBottom:10}}> <text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{ curr(curr(this.state.margin).divide(100).value).multiply(coupon.offer_net_price).value.toFixed(2) }</text></Col>
            //                         </Row>
            //                     </Col>
            //                     <Col>
            //                         <Row>
            //                             <Col sm={{size:12}} style={{fontWeight:"400", padding: 5,fontFamily: 'Muli',color: '#868686'}}><text>Earnings</text>  </Col>
            //                             <Col sm={{size:12}} style={{fontWeight:"400", padding: 5, marginBottom:10}}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{curr(curr(coupon.offer_net_price).subtract(curr(curr(this.state.margin).divide(100).value).multiply(coupon.offer_net_price).value).value).value.toFixed(2)}</text></Col>
            //                         </Row>
            //                     </Col> */}
            //                     <Col>
            //                         <Row>
            //                             <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5,fontFamily: 'Muli',color: '#868686'}}><text>Net Price </text> </Col>
            //                             <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5, marginBottom:10}}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{numeral(coupon.offer_net_price).format("0.00")}</text></Col>
            //                         </Row>
            //                     </Col>
            //                     <Col>
            //                         <Row>
            //                             <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5,fontFamily: 'Muli',color: '#868686'}}><text>Platform Fee</text> </Col>
            //                             <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5, marginBottom:10}}> <text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{numeral(numeral(this.state.margin).divide(100).value()).multiply(coupon.offer_net_price).format("0.00") }</text></Col>
            //                         </Row>
            //                     </Col>
            //                     <Col>
            //                         <Row>
            //                             <Col sm={{size:12}} style={{fontWeight:"400", padding: 5,fontFamily: 'Muli',color: '#868686'}}><text>Earnings</text>  </Col>
            //                             <Col sm={{size:12}} style={{fontWeight:"400", padding: 5, marginBottom:10}}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{numeral(numeral(coupon.offer_net_price).subtract(numeral(numeral(this.state.margin).divide(100).value()).multiply(coupon.offer_net_price).value()).value()).format("0.00")}</text></Col>
                                            
            //                         </Row>
            //                     </Col>
                               
            //                 </Row>
                            
                           

                             
            //             </CardText>
            //         </CardBody>
            //     </Card>
            // )
        })



}
else {
    if(this.state.loading===true){
        offers= <text style={textDecor}>Loading...</text>;
    }
    else{
        if( this.state.offers.length===0&&this.state.loading===false)
        {
         offers=<text style={textDecor22}>No payments found. Please check back later.</text>;
 
     }
    }
               
    
             
}
    

    return (
        <div className="row justify-content-center" style={{marginTop:50, height: '100%', backgroundColor: '#EFEFEF'}}>
            <div className="col-7" > 

            <Form>
                <FormText style={{fontSize:24,marginBottom:20,}}><text style={{color:'#563951', fontFamily:'Muli'}}>Transactions</text></FormText>
            </Form>

                {transaction}
                {offers}
            
        <BarLoader style={{marginTop:500}} widthUnit ={"%"} width={100} loading={this.state.loading} color="#2C2165"/>

        </div>
    </div>
    )

}
}
export default MerchantPaymentsOfferview;
