import React from 'react';
import {
        Navbar,
        NavbarBrand
       
    } from 'reactstrap';
import BetaSignUp from './BetaSignUp';
class NavBeta extends React.Component {



    

      render () {
         
          const mainNavStyle={
            color:"#fff",
            padding:12,
            borderRadius:3,
            textDecoration:"none",
            fontWeight:500,
            fontFamily: 'Muli'
          }

          const mainNavStyleMob={
            color:"#fff",
            padding:1,
            borderRadius:3,
            textDecoration:"none",
            // fontWeight:500,
            fontSize: '10px'
          }
          const mainNavStyleMob1={
            color:"#d8d860",
            padding:2,
            borderRadius:3,
            textDecoration:"none",
            fontWeight:500,
            fontSize: '10px'
          }

          const adminNavStyle={
            color:"black",
            padding:10,
            borderRadius:3,
            textDecoration:"none",
            fontWeight:500
          }

          const activeNav={
              backgroundColor:'#DBBCD9',
            //   "#CFB3CD",
              color:"black",
              fontFamily: 'Muli'
          }

          const subNavStyle={
              color:"#fff",
              padding:7,
              borderRadius:3,
              textDecoration:"none",
              fontWeight:400,
              fontFamily: 'Muli'
          }

          const subNavStyleMob={
            color:"#fff",
            padding:2,
            borderRadius:3,
            textDecoration:"none",
            fontWeight:500,
            fontSize: '10px'
        }
          const subNavStyle1={
            color:"#d1f4fa",
            padding:7,
            borderRadius:3,
            textDecoration:"none",
            fontWeight:400,
        }
        
        return(
        <div>
            

            <Navbar style={{backgroundColor:"#2C2165"}}>
                <NavbarBrand style={{color:"white",fontSize:"22px",fontWeight:"bold", fontFamily: 'Muli'}} className="navbar-brand mb-0 h1"> 
                   <img src="https://qf-bucket.s3.us-east-2.amazonaws.com/Logos+and+Icons/slashWhite.png"  title="logo" alt="logo" width="102px" height="30px" styles="margin-top: 54px; display: block; margin-left:auto; margin-right: auto;"/>&nbsp;   
                    Beta SignUp </NavbarBrand>
                
            </Navbar>

            </div>
            
                       
        );    
    }
}



export default NavBeta;