import React,{Component} from 'react';
import {ButtonGroup, Button, Form,FormText,Col,Table} from 'reactstrap';
import { toast} from 'react-toastify';
import axios from 'axios';
import  tick from '../../../images/group2.png'
import  untick from '../../../images/group3.png'
import {BarLoader} from 'react-spinners';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';



class SupportVersions extends Component{
 
    state={
           create: true,
           view: false, 
           consumerVersions:[], 
           merchantVersions:[],
           loading:false

          }


  componentDidMount () {
   
    this.setState({loading:true})
    
    axios.get("/support/manage/fetchVersions")
    .then((res) => {
      if(res.data.data){
        if(res.data.result.length!==0){
           this.setState({
            consumerVersions:res.data.result,    
            loading:false
           })
        }
         if(res.data.result.length===0){
           this.setState({
            consumerVersions:[],    
            loading:false
           })
        }
      }
      else{
         if(res.data.dbError===true)
         {
         this.setState({loading:false,
          consumerVersions:[]
        })
         toast.warn("Internal Server Error.",{position:toast.POSITION.BOTTOM_CENTER});

        }
        else{
            this.setState({
            consumerVersions:[],    
            loading:false
            })
         }
         }}).catch(err=>{
          console.log(err);
          this.setState({loading:false
          })
          toast.warn("Unable to reach the sever please try again",{position:toast.POSITION.BOTTOM_CENTER})
       
      })
    

      axios.get("/support/manage/fetchVersions")
      .then((res) => {
        if(res.data.data){
          if(res.data.result.length!==0){
            this.setState({
              merchantVersions:res.data.result,    
             loading:false
            })
           
         }
          if(res.data.result.length===0){
            this.setState({
              merchantVersions:[],    
             loading:false
            })
         }
           
        }
    else{
         if(res.data.dbError===true)
         {
           this.setState({loading:false,
            merchantVersions:[]})
            toast.warn("Internal Server Error.",{position:toast.POSITION.BOTTOM_CENTER});
  
        }
        else{
          this.setState({
            merchantVersions:[],    
            loading:false
          })

        }
        }}).catch(err=>{
            console.log(err);
            this.setState({
             loading:false
             })
            toast.warn("Unable to reach the sever please try again.",{position:toast.POSITION.BOTTOM_CENTER})
         
        })


  }

 
 
    supportUserAddHandler = () => {
        this.setState({
            ...this.state,
            create: true,
            view: false
        })
    }

    
    supportUserViewHandler = () => {
        this.setState({
            ...this.state, 
            view: true,
            create: false
        })                
    }

    
      

    render() {
      let UserVersions=null;

        if(this.state.consumerVersions.length!==0){
          

            let filteredVersions=this.state.consumerVersions.filter((each,index)=>{

                return each.QF_CONSUMER_APP_VERSION!==null;
              })
              let i=filteredVersions.length!==0?filteredVersions.length+1:0
           
            filteredVersions.length!==0?
            UserVersions=(
              <div className="row justify-content-center" style={{height: '100%' , backgroundColor: '#EFEFEF'}}>
              <div className="col-4" >

                <Col xs={15}>
                <Table hover> 
                  <thead style={{marginTop: 20,backgroundColor:'#2C2165',color:"white", fontFamily:'Muli'}}>
                    <tr>
                        <th>S.No</th>
                        <th>Customer Version</th>
                        <th>Released Date</th>
                        <th>Android</th>  
                        <th>IOS</th>  

                   </tr>
                 </thead>
                  <tbody style={{fontFamily:"Muli", color: '#563951',fontWeight:'bold',}}>
                        { filteredVersions.map((comment) => {
                          i=i-1;
                  return (
                          <tr>
                          <td>{i}</td>  
                          <td>{comment.QF_CONSUMER_APP_VERSION}</td>
                         {  comment.QF_VERSION_CREATED_ON_DATE!==null?
                          <td>{moment(comment.QF_VERSION_CREATED_ON_DATE).format("DD-MM-YYYY")}</td>:<td>---</td>}

                          {comment.QF_VERSION_FLAG_ANDROID===1?<td><img style={{width:22,height:22}} src={tick}/></td>:<td><img style={{width:22,height:22}} src={untick}/></td>}
                          {comment.QF_VERSION_FLAG_IOS===1?<td><img style={{width:22,height:22}} src={tick}/></td>:<td><img style={{width:22,height:22}} src={untick}/></td>}
                         
                         </tr> 
                        )
                      })
                        }
                    
                      
                </tbody>
                </Table>
                
              </Col>
              </div>
              </div>
              ): UserVersions=(
                <p style={{fontFamily:'Muli', color:'#563951'}}>No Versions found</p>
              )
          }
          else{
               if(this.state.loading===true){
               UserVersions=(
              <div className="row justify-content-center" style={{height: '100%' , backgroundColor: '#EFEFEF'}}>
              <div className="col-4" >
              <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/>  
              <p style={{fontFamily:'Muli', color:'#563951'}}>Loading...</p>
              </div>
              </div>)
              }
             
          }

        let merchantVersions=null;
        

        if(this.state.merchantVersions.length!==0){

          let filteredVersions=this.state.merchantVersions.filter((each,index)=>{

            return each.QF_MERCHANT_APP_VERSION!==null;
          })
          let i=filteredVersions.length!==0?filteredVersions.length+1:0;               

          filteredVersions.length!==0?

            merchantVersions=(
            <div className="row justify-content-center" style={{height: '100%' , backgroundColor: '#EFEFEF'}}>
            <div className="col-4" >
            <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/>

                <Col xs={15}>
                
                <Table hover > 
                  <thead style={{marginTop: 20,backgroundColor:'#2C2165',color:"white", fontFamily:'Muli'}}>
                    <tr>
                    <th>S.No</th>
                    <th>Professional Version</th>
                    <th>Released Date</th>                    
                    <th>Android</th>  
                    <th>IOS</th>                     
                   </tr>
                 </thead>
                 <tbody style={{fontFamily:"Muli", color: '#563951',fontWeight:'bold',}}>
                { filteredVersions.map((comment,key) => {
                i=i-1;
                key=comment.QF_VERSION_PRID
                    return (
                      <tr key={comment.QF_VERSION_PRID}>

                         <td >{i}</td>  
                         <td>{comment.QF_MERCHANT_APP_VERSION}</td>
                         {  comment.QF_VERSION_CREATED_ON_DATE!==null?
                          <td>{moment(comment.QF_VERSION_CREATED_ON_DATE).format("DD-MM-YYYY")}</td>:<td>---</td>}
                         {comment.QF_VERSION_FLAG_ANDROID===1?<td><img style={{width:22,height:22}} src={tick}/></td>:<td><img style={{width:22,height:22}} src={untick}/></td>}
                         {comment.QF_VERSION_FLAG_IOS===1?<td><img style={{width:22,height:22}} src={tick}/></td>:<td><img style={{width:22,height:22}} src={untick}/></td>}
                        
                         </tr> 
                    )
                })
                }    
                </tbody>
                </Table>

              </Col>
              </div>
              </div>
              ):merchantVersions=(
                <p style={{fontFamily:'Muli', color:'#563951'}}>No Versions found</p>

              )


        }
        else{
          if(this.state.loading===true){
          merchantVersions=(
            <div className="row justify-content-center" style={{height: '100%' , backgroundColor: '#EFEFEF'}}>
            <div className="col-4" >
            <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/>
            <p style={{fontFamily:'Muli', color:'#563951'}}>Loading...</p>
            </div>
            </div>)
          }
         
        }
        let conStyle;
        let merStyle;
        if(this.state.create){
            conStyle={
                backgroundColor:'#2C2165',
                fontFamily:'Muli'
            }
        }
        if(this.state.view){
            merStyle={
                backgroundColor:'#2C2165',
                fontFamily:'Muli'
            }
        }
  return (
            
            <div>
            
            <Form>
                <FormText style={{fontSize:24,marginBottom:20, }}><text style={{fontFamily:'Muli', color:'#563951'}}>Versions</text></FormText>
            </Form>
            <ButtonGroup style={{marginBottom:20}}>
                <Button onClick={this.supportUserAddHandler} active={this.state.create ? true : false} style={conStyle}><text style={{fontFamily:'Muli'}}>Customer</text> </Button>
                <Button onClick={this.supportUserViewHandler} active={this.state.view ? true : false} style={merStyle}><text style={{fontFamily:'Muli'}}>Professional</text></Button> 
           
            </ButtonGroup>

               {this.state.create ? UserVersions : null}
               {this.state.view ? merchantVersions : null} 
           
           </div>

          );

    }
}

export default SupportVersions;