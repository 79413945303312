import React, { Component } from 'react';
import { ButtonGroup, Button, Card, CardBody, CardText, Row, Col, Container, Modal,ModalBody,ModalHeader,ModalFooter} from 'reactstrap';
import axios from 'axios';
import {ToastContainer, toast } from 'react-toastify';
import Switch from 'react-switch';
import { FaTrashAlt } from 'react-icons/fa';
import {BarLoader} from 'react-spinners';
import moment from 'moment';
import {connect} from "react-redux";
import Curr from 'currency.js';

class supportViewCoupon extends Component {

    state = {
        curentCoupons: [],
        pastCoupons: [],
        couponDetails: [],
        toggle: [],
        city: null, 
        category: null,
        subCategory: null,
        current: true,
        past: false,
        modal : false,
        id: null,
        currentModalView: false,
        pastModalView: false,
        loading: false
       
    }
    componentDidMount(){
        this.setState({
            ...this.state,
            loading: true 
        })
       let id=this.props.id;
       
        axios.post("/support/view/supportCurrentCoupon",{id})
        .then((res) => {
            // console.log("Response", res.data)
            if(res.data.searchCoupon){
                let toggle = [];
                if(res.data.currentCoupon.length !== 0 && res.data.pastCoupon.length !== 0){
                    res.data.currentCoupon.map((coupon_id) => {
                        if(coupon_id.SUPPORT_COUPON_ACTIVE === 1)
                        {
                            return toggle['toggle_' + coupon_id.QF_SUPPORT_COUPON_PRID]=true;
                        } 
                        else 
                        {
                            return toggle['toggle_' + coupon_id.QF_SUPPORT_COUPON_PRID]=false;
                        }

                    })
                  
                    this.setState({
                        ...this.state,
                        curentCoupons: res.data.currentCoupon,
                        pastCoupons: res.data.pastCoupon,
                        toggle,
                        loading: false
                    });
                } else if(res.data.currentCoupon.length === 0 && res.data.pastCoupon.length !== 0){
                    
                    this.setState({
                            ...this.state,
                            pastCoupons: res.data.pastCoupon,
                            loading: false

                    })
                } else if(res.data.currentCoupon.length !== 0 && res.data.pastCoupon.length === 0){
                    res.data.currentCoupon.map((coupon_id) => {
                        if(coupon_id.SUPPORT_COUPON_ACTIVE === 1)
                        {
                            return toggle['toggle_' + coupon_id.QF_SUPPORT_COUPON_PRID]=true;
                        } 
                        else 
                        {
                            return toggle['toggle_' + coupon_id.QF_SUPPORT_COUPON_PRID]=false;
                        }

                    })
                    this.setState({
                        ...this.state,
                        curentCoupons: res.data.currentCoupon,
                        toggle,
                        loading: false
                    });
                } else if(res.data.currentCoupon.length === 0 && res.data.pastCoupon.length === 0){
                        this.setState({
                            ...this.state,
                            curentCoupons: [],
                            pastCoupons: [],
                            modal: false,
                            loading: false                            
                        });
                    } 
                } 
                else {
                    if(res.data.dbError){
                        toast.error("Internal Server Error", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });

                    }
                    this.setState({
                        ...this.state,
                        curentCoupons: [],
                        pastCoupons: [],
                        modal: false,
                        loading: false                            
                    });
                }
        })
        .catch((err) => {
            toast.warn("Unable to Reach Server! Please Try Again!" , {
                position: toast.POSITION.BOTTOM_CENTER
              });
            this.setState({
                ...this.state,
                loading: false
            });
        })        
    }

    toggleSwitchHandler = (id) => {
        this.setState({
            ...this.state,
            loading: true
        });
        
        let toggle={};
         if(this.state.toggle['toggle_'+id]){
           toggle={
              ...this.state.toggle,
              ['toggle_'+id]:false
          }
           this.setState({
               ...this.state,
                toggle: toggle,
            });

            let body = {
                id: id,
                toggle: false
            }
        
    
             axios.post("/support/consumer/supportActiveCoupon", body)
                    .then((res) => {
                        if(res.data.data){
                            
                            if(res.data.active){
                                toast.success("Coupon is activated", {
                                    position: toast.POSITION.BOTTOM_CENTER
                                });
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                            } else if(res.data.inactive){
                                toast.success("Coupon is deactivated", {
                                    position: toast.POSITION.BOTTOM_CENTER
                                });
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                            } else {
                                toast.error("Data Not Found", {
                                    position: toast.POSITION.BOTTOM_CENTER
                                });
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                            }
                        } else {
                            
                            if(res.data.dbError){
                                toast.error("Internal Server Error", {
                                    position: toast.POSITION.BOTTOM_CENTER
                                });
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                            } else {
                                toast.error("Coupon not updated, try again!", {
                                    position: toast.POSITION.BOTTOM_CENTER
                                });
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                            }
                            this.setState({
                                ...this.state,
                                 toggle: toggle,
                                 loading: false
                            });
                        }
                    })
                    .catch((err) => {
                        toast.warn("Unable to Reach Server! Please Try Again!" , {
                            position: toast.POSITION.BOTTOM_CENTER
                          });
                        this.setState({
                            ...this.state,
                            toggle: toggle,
                           // currentModalView: false,
                            loading: false
                        });
                    });
            
        } else {            
            toggle={
              ...this.state.toggle,
              ['toggle_'+id]:true
            }
            this.setState({
                ...this.state,
                toggle: toggle,
               
         });

         let body = {
            id: id,
            toggle: true
        }

         axios.post("/support/consumer/supportActiveCoupon", body)
                .then((res) => {
                    if(res.data.data){
                        
                        if(res.data.active){
                            toast.success("Coupon is activated", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                        } else if(res.data.inactive){
                            toast.success("Coupon is deactivated", {
                                position: toast.POSITION.BOTTOM_CENTER,
                                
                            });
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                        } else{
                            toast.warn("Data Not Found!", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                        }
                    } else {
                        
                        if(res.data.dbError){
                            toast.error("Internal Server Error", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                        } else {
                            toast.error("Coupon not updated, try again!", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                        }
                        this.setState({
                            ...this.state,
                             toggle: toggle,
                             currentModalView: false,
                             loading: false
                        });

                    }
                })
                .catch((err) => {
                    toast.warn("Unable to Reach Server! Please Try Again!" , {
                        position: toast.POSITION.BOTTOM_CENTER
                      });
                    this.setState({
                        ...this.state,
                        toggle: toggle,
                       // currentModalView: false,
                        loading: false
                    });
                });
        }

        
      
    }

    currentTicketHandler = () => {
        this.setState({
                ...this.state,
                current: true,
                past: false,
        });
       let id=this.props.id;

        axios.post("/support/view/supportCurrentCoupon",{id})
        .then((res) => {
            if(res.data.searchCoupon){
                if(res.data.currentCoupon.length !== 0){
                    this.setState({
                        ...this.state,
                        curentCoupons: res.data.currentCoupon,
                        loading: false
                    });
                } if(res.data.currentCoupon.length === 0){
                    this.setState({
                        ...this.state,
                        curentCoupons: [],
                        modal: false,
                        loading: false
                    });
                }
            } else {
                if(res.data.dbError){
                    toast.error("Internal Server Error!",{
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                } else {
                   
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                }
            }
           
        })
        .catch((err) => {
            toast.warn("Unable to Reach Server! Please Try Again!" , {
                position: toast.POSITION.BOTTOM_CENTER
              });
            this.setState({
                ...this.state,
                loading: false
            });
        })
    }

    pastTicketHandler = () => {

        this.setState({
                ...this.state,
                current: false,
                past: true,
                loading: true
        });
       let id=this.props.id;


        axios.post("/support/view/supportCurrentCoupon",{id})
        .then((res) => {
            if(res.data.searchCoupon){
                if(res.data.pastCoupon.length !== 0 ){
                    this.setState({
                        ...this.state,
                        pastCoupons: res.data.pastCoupon,
                        loading: false
                    });
                } else if(res.data.pastCoupon.length === 0){
                        this.setState({
                            ...this.state,
                            pastCoupons: [],
                            modal: false,
                            loading: false
                        });
                    } 
                } else {
                    if(res.data.dbError){
                        toast.error("Internal Server Error!",{
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        this.setState({
                            ...this.state,
                            loading: false
                        });
                    } else {
                      
                        this.setState({
                            ...this.state,
                            loading: false
                        });
                    }
                }
               
        })
        .catch((err) => {
            toast.warn("Unable to Reach Server! Please Try Again!" , {
                position: toast.POSITION.BOTTOM_CENTER
              });
            this.setState({
                ...this.state,
                loading: false
            });
        });

    }

    modalSwitchHandler = (id) => {
        this.setState({
            ...this.state,
            modal: !this.state.modal,
           // currentModalView:false,
            pastModalView: false,
            id: id
        });
    }

    deleteCouponHandler = () => {
        this.setState({
            ...this.state,
            loading: true
        });

        let body = {
            id: this.state.id
        }

        axios.post("/support/consumer/ourcoupon/supportDelete", body)
        .then((res) => {
            if(res.data.deleted){
                this.setState({
                    currentModalView:false
                })
                toast.success("Coupon is deleted!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });

               let id = this.props.id;

                return axios.post("/support/view/supportCurrentCoupon", {id})
                .then((res1) => {
                    if(res1.data.searchCoupon){
                        if(res1.data.currentCoupon.length !== 0 && res1.data.pastCoupon.length !== 0){
                            this.setState({
                                ...this.state,
                                curentCoupons: res1.data.currentCoupon,
                                pastCoupons: res1.data.pastCoupon,
                                modal: false,
                                loading: false
                            });
                        } else if(res1.data.currentCoupon.length !== 0 && res1.data.pastCoupon.length === 0){
                            this.setState({
                                    ...this.state,
                                    curentCoupons: res1.data.currentCoupon,
                                    pastCoupons: [],
                                    modal: false,
                                    loading: false
                            });
                        } else if(res1.data.currentCoupon.length === 0 && res1.data.pastCoupon.length !== 0){
                                this.setState({
                                    ...this.state,
                                    curentCoupons: [],
                                    pastCoupons: res1.data.pastCoupon,
                                    modal: false,
                                    loading: false
                                });
                        } else if(res1.data.currentCoupon.length === 0 && res1.data.pastCoupon.length === 0){
                            
                                toast.warn("No coupons to display",{
                                    position: toast.POSITION.BOTTOM_CENTER
                                });

                                this.setState({
                                    ...this.state,
                                    curentCoupons: [],
                                    pastCoupons: [],
                                    modal: false,
                                    loading: false
                                });                                
                            }                       
                    } else {
                        if(res.data.dbError){
                            toast.error("Internal Server Error!",{
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                        } else {
                            
                            this.setState({
                                ...this.state,
                                curentCoupons: [],
                                pastCoupons: [],
                                modal: false,
                                loading: false
                            }); 
                        }
                    }
                })
                .catch((err) => {
                    toast.warn("Unable to Reach Server! Please Try Again!" , {
                        position: toast.POSITION.BOTTOM_CENTER
                      });
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                });
            } else {
                if(res.data.dbError){
                    toast.error("Internal Server Error!",{
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.setState({
                        ...this.state,
                        loading: false
                    });;
                } else {
                    toast.error("Coupon is not deleted!", {
                    position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                }
            }
        })
        .catch((err) => {
            toast.warn("Unable to Reach Server! Please Try Again!" , {
                position: toast.POSITION.BOTTOM_CENTER
              });
            this.setState({
                ...this.state,
                loading: false
            });
        })
    }

    cardCurrentCouponHandler = (id) => {
        this.setState({
            ...this.state,
            currentModalView: true,
            modal: false,
            // loading: true
        });
        
        axios.post("/support/consumer/supportCitySerach", {id})
        .then((res) => {

            if(res.data.result){

                if(res.data.city && !res.data.catg && !res.data.sub){

                    this.setState({
                        ...this.state,
                        couponDetails: res.data.couponDetails,
                        city: res.data.cityResult[0].CITY_NAME,
                        loading: false
                    });

                } else if(!res.data.city && res.data.catg && !res.data.sub){

                    this.setState({ 
                        ...this.state,  
                        couponDetails: res.data.couponDetails,                    
                        category: res.data.cat[0].category_name,  
                        loading: false                      
                    });

                } else if(!res.data.city && !res.data.catg && res.data.sub){

                    this.setState({
                        ...this.state,
                        couponDetails: res.data.couponDetails,
                        subCategory: res.data.subCat[0].service_sub_category_name,
                        loading: false
                    });

                } else if(res.data.city && res.data.catg && !res.data.sub){

                    this.setState({
                        ...this.state,
                        couponDetails: res.data.couponDetails,
                        city: res.data.cityResult[0].CITY_NAME,
                        category: res.data.cat[0].category_name,
                        loading: false
                    });

                } else if(res.data.city && !res.data.catg && res.data.sub){

                    this.setState({
                        ...this.state,
                        couponDetails: res.data.couponDetails,
                        city: res.data.cityResult[0].CITY_NAME,
                        subCategory: res.data.subCat[0].service_sub_category_name,
                        loading: false
                    });

                } else if(!res.data.city && res.data.catg && res.data.sub){

                    this.setState({
                        ...this.state,
                        couponDetails: res.data.couponDetails,
                        category: res.data.cat[0].category_name,
                        subCategory: res.data.subCat[0].service_sub_category_name,
                        loading: false
                    });

                } else if(res.data.city && res.data.catg && res.data.sub){

                    this.setState({
                        ...this.state,
                        couponDetails: res.data.couponDetails,
                        city: res.data.cityResult[0].CITY_NAME,
                        category: res.data.cat[0].category_name,
                        subCategory: res.data.subCat[0].service_sub_category_name,
                        loading: false
                    });

                } else {                        
                    this.setState({
                        ...this.state,
                        couponDetails: res.data.couponDetails,
                        loading: false
                    });
                }
            } else {
                if(res.data.dbError){
                    toast.error("Internal Server Error", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                } else {
                    toast.warn("No data found!",{
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                }
            }
        })
        .catch((err) => {
            toast.warn("Unable to Reach Server! Please Try Again!" , {
                position: toast.POSITION.BOTTOM_CENTER
              });
            this.setState({
                ...this.state,
                loading: false
            });
        });
    } 

    cardPastCouponHandler = (id) => {
        this.setState({
            ...this.state,
            pastModalView: true,
            modal: false,
            loading: true
        });
        
        axios.post("/support/consumer/supportCitySerach", {id})
        .then((res) => {

            if(res.data.result){

                if(res.data.city && !res.data.catg && !res.data.sub){

                    this.setState({
                        ...this.state,
                        couponDetails: res.data.couponDetails,
                        city: res.data.cityResult[0].CITY_NAME,
                        loading: false
                    });

                } else if(!res.data.city && res.data.catg && !res.data.sub){

                    this.setState({  
                        ...this.state, 
                        couponDetails: res.data.couponDetails,                    
                        category: res.data.cat[0].category_name,   
                        loading: false                     
                    });

                } else if(!res.data.city && !res.data.catg && res.data.sub){

                    this.setState({
                        ...this.state,
                        couponDetails: res.data.couponDetails,
                        subCategory: res.data.subCat[0].service_sub_category_name,
                        loading: false
                    });

                } else if(res.data.city && res.data.catg && !res.data.sub){

                    this.setState({
                        ...this.state,
                        couponDetails: res.data.couponDetails,
                        city: res.data.cityResult[0].CITY_NAME,
                        category: res.data.cat[0].category_name,
                        loading: false
                    });

                } else if(res.data.city && !res.data.catg && res.data.sub){

                    this.setState({
                        ...this.state,
                        couponDetails: res.data.couponDetails,
                        city: res.data.cityResult[0].CITY_NAME,
                        subCategory: res.data.subCat[0].service_sub_category_name,
                        loading: false
                    });

                } else if(!res.data.city && res.data.catg && res.data.sub){

                    this.setState({
                        ...this.state,
                        couponDetails: res.data.couponDetails,
                        category: res.data.cat[0].category_name,
                        subCategory: res.data.subCat[0].service_sub_category_name,
                        loading: false
                    });

                } else if(res.data.city && res.data.catg && res.data.sub){

                    this.setState({
                        ...this.state,
                        couponDetails: res.data.couponDetails,
                        city: res.data.cityResult[0].CITY_NAME,
                        category: res.data.cat[0].category_name,
                        subCategory: res.data.subCat[0].service_sub_category_name,
                        loading: false
                    });

                } else {
                    this.setState({
                        ...this.state,
                        couponDetails: res.data.couponDetails,
                        loading: false
                    });
                }
            } else {
                if(res.data.dbError){
                    toast.error("Internal Server Error", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                } else {
                    toast.warn("No data found!",{
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                }
            }
        })
        .catch((err) => {
            toast.warn("Unable to Reach Server! Please Try Again!" , {
                position: toast.POSITION.BOTTOM_CENTER
              });
            this.setState({
                ...this.state,
                loading: false
            });
        });
    }

    currentModalCloseHandler = () => {        
        this.setState({
            ...this.state,
            currentModalView: false,
            pastModalView: false
        });
    }
    
    render() {
        // console.log("State",this.state)
        const rowStyle={
            textAlign:"initial" 
        }
        const fWeightSpan={
            fontWeight:"500",
            padding: 5,
            cursor: 'pointer',
            fontFamily: 'Muli',
            color: '#868686'
            // border: "1px solid black"
        }
        const fWeightSpan1={
            fontWeight:"500",
            padding: 5,
            fontFamily: 'Muli',
            color: '#868686'
            // cursor: 'pointer',
            // border: "1px solid black"
        }
        const textDecor = {
            padding: 5,
            fontWeight:'bold',
            fontFamily: 'Muli',
            color: '#563951',
            cursor: 'pointer',
            // border: "1px solid black"
        }
        const textDecor1 = {
            padding: 5,
            fontWeight:'bold',
            fontFamily: 'Muli',
            color: '#563951'
            // cursor: 'pointer',
            // border: "1px solid black"
        }

        let current = <text style={{fontFamily:'Muli', color:'#563951'}}>No Current Coupons</text>;
        let past = <text style={{fontFamily:'Muli', color:'#563951'}}>No Past Coupons</text>;
        let delId=null;

        if(this.state.curentCoupons.length !== 0){  
            current = this.state.curentCoupons.map((coupon) => {
             delId=coupon.QF_SUPPORT_COUPON_PRID;
                return (
                    <Card style={{marginTop: 10, border: '2px solid #a78bba', backgroundColor:"white",borderRadius:10}}>
                        <CardBody  >                                                       
                            <CardText style={{color:''}}> 
                                <Row style={rowStyle}>
                                    <Col onClick={this.cardCurrentCouponHandler.bind(this,delId)} sm={{size:'auto'}} style={fWeightSpan}><text>Coupon Code:</text></Col>
                                    <Col onClick={this.cardCurrentCouponHandler.bind(this,delId)} sm={{size:8}} style={textDecor}><text>{coupon.SUPPORT_COUPON_CODE}</text></Col>
                                    <Col sm={{size:'auto'}} style={fWeightSpan}><Switch onColor='#3DC5B6' offColor='#F86C69' height={20} onChange={this.toggleSwitchHandler.bind(this,delId)} checked={this.state.toggle["toggle_"+coupon.QF_SUPPORT_COUPON_PRID]} /></Col>
                                </Row>
                                <Row style={rowStyle}>
                                    <Col onClick={this.cardCurrentCouponHandler.bind(this,delId)} sm={{size:'auto'}} style={fWeightSpan}><text>Coupon Desc:</text></Col>
                                    <Col onClick={this.cardCurrentCouponHandler.bind(this,delId)} sm={{size:9}} style={textDecor}><text>{coupon.SUPPORT_COUPON_DESC === null ? <text>---</text> : <text>{coupon.SUPPORT_COUPON_DESC}</text>}</text></Col>
                                </Row>
                                <Row style={rowStyle}>
                                    {coupon.SUPPORT_COUPON_PERCENT_OFF !== null ? <Col onClick={this.cardCurrentCouponHandler.bind(this,delId)} sm={{size:12}} style={fWeightSpan}><text>Coupon Discount:</text><text>{coupon.SUPPORT_COUPON_PERCENT_OFF}% </text></Col> : null}
                                </Row>
                                <Row style={rowStyle}>

                                    <Col onClick={this.cardCurrentCouponHandler.bind(this,delId)} md={{size:'auto'}} style={fWeightSpan}><text>Coupon Discount Amount :</text> </Col>
                                    <Col onClick={this.cardCurrentCouponHandler.bind(this,delId)} md={{size:5}} style={textDecor}><text>{coupon.MERCHANT_OFFER_CURRENCY === "Rupees" || coupon.REC_SERVICE_CURRENCY=== "Rupees"? <span> {'\u20B9'}</span> : coupon.MERCHANT_OFFER_CURRENCY === "Dollars" || coupon.REC_SERVICE_CURRENCY=== "Dollars" ? <span>$</span> : coupon.MERCHANT_OFFER_CURRENCY || coupon.REC_SERVICE_CURRENCY}{coupon.SUPPORT_COUPON_VALUE_OFF}</text></Col>

                                    <Col sm={{size:'auto'}} style={fWeightSpan}><FaTrashAlt size='1.2em' color='#F86C69' onClick={this.modalSwitchHandler.bind(this,delId)} style={{cursor:'pointer'}} /></Col>
                                </Row>
                                
                                
                                <Row style={rowStyle}>
                                <Col onClick={this.cardCurrentCouponHandler.bind(this,delId)} sm={{size:10}}></Col>
                                </Row>
                            </CardText>
                        </CardBody>
                    </Card>
                );
            })
        } else {
            if(this.state.loading===true){
                current = <text style={{fontFamily:'Muli', color:'#563951'}}>Loading...</text>;
            }else{
            current = <text style={{fontFamily:'Muli', color:'#563951'}}>No Current Coupons</text>; 
        }           
        }

       

        if(this.state.pastCoupons.length !== 0){
            past = this.state.pastCoupons.map((coupon) => {
                delId=coupon.QF_SUPPORT_COUPON_PRID
                return (
                    <Card style={{marginTop: 10, border: '2px solid #a78bba', backgroundColor:"white",borderRadius:10}}>
                        <CardBody>                            
                            <CardText onClick={this.cardPastCouponHandler.bind(this,delId)}>
                                <Row style={rowStyle}>
                                    <Col sm={{size:10}} style={fWeightSpan}><text>Coupon Code:</text> <text style={textDecor}>{coupon.SUPPORT_COUPON_CODE}</text></Col>
                                </Row>
                                <Row style={rowStyle}>
                                    <Col sm={{size:10}} style={fWeightSpan}><text>Coupon Description:</text> <text style={textDecor}>{coupon.SUPPORT_COUPON_DESC ? coupon.SUPPORT_COUPON_DESC : <text>---</text>}</text></Col>
                                </Row>
                                <Row style={rowStyle}>
                                    {coupon.SUPPORT_COUPON_PERCENT_OFF !== null ? <Col sm={{size:10}} style={fWeightSpan}><text>Coupon Discount:</text> <text style={textDecor}>{coupon.SUPPORT_COUPON_PERCENT_OFF}</text></Col> : null}
                                </Row>
                                <Row style={rowStyle}>
                                    {coupon.SUPPORT_COUPON_VALUE_OFF !== null ? <Col md={{size:10}} style={fWeightSpan}><text>Coupon Discount Amount:</text> <text style={textDecor}>{coupon.MERCHANT_OFFER_CURRENCY === "Rupees" || coupon.REC_SERVICE_CURRENCY === "Rupees" ? <span> {'\u20B9'}</span> : coupon.MERCHANT_OFFER_CURRENCY === "Dollars" || coupon.REC_SERVICE_CURRENCY === "Dollars" ? <span>$</span> : coupon.MERCHANT_OFFER_CURRENCY || coupon.REC_SERVICE_CURRENCY}{coupon.SUPPORT_COUPON_VALUE_OFF}</text></Col> : null}
                                </Row>
                            </CardText>
                        </CardBody>
                    </Card>
                );
            })
        } else {
            if(this.state.loading===true){
                past = <text style={{fontFamily:'Muli', color:'#563951'}}>Loading...</text>;
            }else{
            past = <text style={{fontFamily:'Muli', color:'#563951'}}>No Past Coupons</text>; 
        }
    }

        let modal = (
        <Modal isOpen={this.state.modal}>
        <ModalHeader color="warning">Warning</ModalHeader>
          <ModalBody style={{fontFamily:'Muli'}}>
                Coupon will be deleted permanently!
                Are you sure?
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={() => this.deleteCouponHandler(delId)}>Delete</Button>{' '}
            <Button  onClick={this.modalSwitchHandler}>Cancel</Button>
          </ModalFooter>
        </Modal>
        );

        let currentModalDetail = null;
        if(this.state.couponDetails.length !== 0){
            let delId=this.state.couponDetails[0].QF_SUPPORT_COUPON_PRID;
            currentModalDetail =  (
            <Modal isOpen={this.state.currentModalView} size='lg'>
                <ModalHeader style={{color: 'success', fontFamily:'Muli'}}>Coupon Details</ModalHeader>
                        <ModalBody  >
                            <Card style={{marginTop: 10, backgroundColor:"#ebf0f6"}}>
                                <CardBody >                          
                                    <CardText >
                                        <Row style={rowStyle}>
                                            <Col sm={{size:4}} style={fWeightSpan1}><text>Coupon Code:</text> </Col>
                                            <Col sm={{size:4}} style={textDecor1}>{this.state.couponDetails[0].SUPPORT_COUPON_CODE !== null ? this.state.couponDetails[0].SUPPORT_COUPON_CODE : null}</Col>
                                            <Col sm={{size:3} } style={textDecor1}><Switch onColor='#3DC5B6' offColor='#F86C69' height={20} onChange={this.toggleSwitchHandler.bind(this,this.state.couponDetails[0].QF_SUPPORT_COUPON_PRID)} checked={this.state.toggle["toggle_"+this.state.couponDetails[0].QF_SUPPORT_COUPON_PRID]} /></Col>
                                        </Row>
                                        <Row style={rowStyle}>
                                            <Col sm={{size:4}} style={fWeightSpan1}><text>Coupon Desc:</text> <text style={textDecor}></text></Col>
                                            <Col sm={{size:3}} style={textDecor1}>{this.state.couponDetails[0].SUPPORT_COUPON_DESC !== null ? this.state.couponDetails[0].SUPPORT_COUPON_DESC : <text> --- </text>}</Col>
                                        </Row>
                                        <Row style={rowStyle}>
                                            <Col sm={{size:4}} style={fWeightSpan1}><text>City:</text> </Col>
                                            <Col sm={{size:8}} style={textDecor1}>{this.state.city !== null ? this.state.city :<text> --- </text> }</Col>
                                        </Row>
                                        <Row style={rowStyle}>
                                            <Col sm={{size:4}} style={fWeightSpan1}><text>Category:</text> </Col>
                                            <Col sm={{size:8}} style={textDecor1}>{this.state.category !== null ? this.state.category :<text > --- </text> }</Col>
                                        </Row>
                                        <Row style={rowStyle}>
                                            <Col sm={{size:4}} style={fWeightSpan1}><text>Subcategory:</text> </Col>
                                            <Col sm={{size:8}} style={textDecor1}>{this.state.subCategory !== null ? this.state.subCategory :<text > --- </text> }</Col>
                                        </Row>
                                        <Row style={rowStyle}>
                                            <Col sm={{size:4}} style={fWeightSpan1}> <text>Coupon Minimum Amount:</text> </Col>                                                
                                            <Col sm={{size:8}} style={textDecor1}>{this.state.couponDetails[0].SUPPORT_COUPON_MINIMUM_AMOUNT !== null?this.state.couponDetails[0].MERCHANT_OFFER_CURRENCY === "Rupees" || this.state.couponDetails[0].REC_SERVICE_CURRENCY === "Rupees"? <span> {'\u20B9'}</span> : this.state.couponDetails[0].MERCHANT_OFFER_CURRENCY === "Dollars" || this.state.couponDetails[0].REC_SERVICE_CURRENCY === "Dollars" ? <span>$</span> : this.state.couponDetails[0].MERCHANT_OFFER_CURRENCY || this.state.couponDetails[0].REC_SERVICE_CURRENCY:null}{this.state.couponDetails[0].SUPPORT_COUPON_MINIMUM_AMOUNT !== null ? this.state.couponDetails[0].SUPPORT_COUPON_MINIMUM_AMOUNT :<text>---</text> }</Col>
                                        </Row>
                                        <Row style={rowStyle}>
                                            <Col sm={{size:4}} style={fWeightSpan1}><text>Coupon Discount Amount:</text> </Col>
                                            <Col sm={{size:8}} style={textDecor1}>{this.state.couponDetails[0].MERCHANT_OFFER_CURRENCY === "Rupees" || this.state.couponDetails[0].REC_SERVICE_CURRENCY === "Rupees" ? <span> {'\u20B9'}</span> : this.state.couponDetails[0].MERCHANT_OFFER_CURRENCY === "Dollars" || this.state.couponDetails[0].REC_SERVICE_CURRENCY === "Dollars" ? <span>$</span> : this.state.couponDetails[0].MERCHANT_OFFER_CURRENCY || this.state.couponDetails[0].REC_SERVICE_CURRENCY}{this.state.couponDetails[0].SUPPORT_COUPON_VALUE_OFF !== null ? this.state.couponDetails[0].SUPPORT_COUPON_VALUE_OFF :<text>0</text> }</Col>
                                        </Row> 
                                        <Row style={rowStyle}>
                                            <Col sm={{size:4}} style={fWeightSpan1}><text>Coupon From Booking Date:</text> </Col> 
                                            <Col sm={{size:8}} style={textDecor1}>{this.state.couponDetails[0].SUPPORT_COUPON_BOOKING_DATE_FROM !== null ? moment(this.state.couponDetails[0].SUPPORT_COUPON_BOOKING_DATE_FROM).format('DD-MMM-YYYY') :<text> ---</text> }</Col>
                                        </Row>
                                        <Row style={rowStyle}>
                                            <Col sm={{size:4}} style={fWeightSpan1}><text>Coupon Till Booking Date:</text> </Col>
                                            <Col sm={{size:8}} style={textDecor1}>{this.state.couponDetails[0].SUPPORT_COUPON_BOOKING_DATE_TO !== null ? moment(this.state.couponDetails[0].SUPPORT_COUPON_BOOKING_DATE_TO).format('DD-MMM-YYYY')  :<text>---</text> }</Col>
                                        </Row>
                                        {/* <Row>
                                            <Col sm={{size:3}} style={fWeightSpan}><text>Service from:</text> </Col>
                                            <Col sm={{size:'auto'}}><text style={textDecor}>{this.state.couponDetails[0].SUPPORT_COUPON_SERVICE_DATE_FROM !== null ? this.state.couponDetails[0].SUPPORT_COUPON_SERVICE_DATE_FROM :<text style={{color: '#bf0000'}}> --</text> }</text></Col>
                                        </Row>
                                        <Row>
                                            <Col sm={{size:3}} style={fWeightSpan}><text>Service to:</text> </Col>
                                            <Col sm={{size:'auto'}}><text style={textDecor}>{this.state.couponDetails[0].SUPPORT_COUPON_SERVICE_DATE_TO !== null ? this.state.couponDetails[0].SUPPORT_COUPON_SERVICE_DATE_TO :<text style={{color: '#bf0000'}}> --</text> }</text></Col>
                                        </Row> */}
                                        
                                        </CardText>
                                        
                                </CardBody>
                            </Card>
                        </ModalBody>                   
                <ModalFooter>
                        <Button color="danger" onClick={this.modalSwitchHandler.bind(this,delId)}><text style={{fontFamily:'Muli'}}>Delete</text></Button>{' '}
                        <Button  onClick={this.currentModalCloseHandler}><text style={{fontFamily:'Muli'}}>Close</text></Button>
                </ModalFooter>
            </Modal>
        );
    }

    let pastModalDetail = null;
        if(this.state.couponDetails.length !== 0){
            pastModalDetail =  (
            <Modal isOpen={this.state.pastModalView} size='lg'>
                <ModalHeader style={{color: 'success', fontFamily:'Muli'}}>Coupon Details</ModalHeader>
                        <ModalBody>
                            <Card style={{marginTop: 10, backgroundColor:"#ebf0f6"}}>
                                <CardBody >                            
                                    <CardText>
                                        <Row style={rowStyle}>
                                            <Col sm={{size:3}} style={fWeightSpan1}><text>Coupon Code:</text> </Col>
                                            <Col sm={{size:9}} style={textDecor1}>{this.state.couponDetails[0].SUPPORT_COUPON_CODE !== null ? this.state.couponDetails[0].SUPPORT_COUPON_CODE : null}</Col>
                                        </Row>
                                        <Row>
                                            <Col sm={{size:3}} style={fWeightSpan1}><text>Coupon Desc:</text> <text style={textDecor}></text></Col>
                                            <Col sm={{size:9}} style={textDecor1}>{this.state.couponDetails[0].SUPPORT_COUPON_DESC !== null ? this.state.couponDetails[0].SUPPORT_COUPON_DESC : <text> ---</text>}</Col>
                                        </Row>
                                        <Row>
                                            <Col sm={{size:5}} style={fWeightSpan1}> <text>Coupon Minimum Amount:</text> </Col>                                                
                                            <Col sm={{size:5}} style={textDecor1}>{this.state.couponDetails[0].SUPPORT_COUPON_MINIMUM_AMOUNT !== null?this.state.couponDetails[0].MERCHANT_OFFER_CURRENCY === "Rupees" || this.state.couponDetails[0].REC_SERVICE_CURRENCY === "Rupees" ? <span> {'\u20B9'}</span> : this.state.couponDetails[0].MERCHANT_OFFER_CURRENCY === "Dollars" || this.state.couponDetails[0].REC_SERVICE_CURRENCY === "Dollars" ? <span>$</span> : this.state.couponDetails[0].MERCHANT_OFFER_CURRENCY || this.state.couponDetails[0].REC_SERVICE_CURRENCY:null}{this.state.couponDetails[0].SUPPORT_COUPON_MINIMUM_AMOUNT !== null ? this.state.couponDetails[0].SUPPORT_COUPON_MINIMUM_AMOUNT :<text >---</text> }  </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={{size:5}} style={fWeightSpan1}><text>Coupon Discount Amount:</text> </Col>
                                            <Col sm={{size:3}} style={textDecor1}>{this.state.couponDetails[0].MERCHANT_OFFER_CURRENCY === "Rupees" || this.state.couponDetails[0].REC_SERVICE_CURRENCY === "Rupees" ? <span> {'\u20B9'}</span> : this.state.couponDetails[0].MERCHANT_OFFER_CURRENCY === "Dollars" || this.state.couponDetails[0].REC_SERVICE_CURRENCY === "Dollars" ? <span>$</span> : this.state.couponDetails[0].MERCHANT_OFFER_CURRENCY||this.state.couponDetails[0].REC_SERVICE_CURRENCY }{this.state.couponDetails[0].SUPPORT_COUPON_VALUE_OFF !== null ? this.state.couponDetails[0].SUPPORT_COUPON_VALUE_OFF :<text >0</text> }</Col>
                                        </Row>
                                        <Row>
                                            <Col sm={{size:5}} style={fWeightSpan1}><text>Allow Coupon From Booking Date:</text> </Col>
                                            <Col sm={{size:3}} style={textDecor1}>{this.state.couponDetails[0].SUPPORT_COUPON_BOOKING_DATE_FROM !== null ? moment(this.state.couponDetails[0].SUPPORT_COUPON_BOOKING_DATE_FROM).format('DD-MMM-YYYY') :<text > ---</text> }</Col>
                                        </Row>
                                        <Row>
                                            <Col sm={{size:5}} style={fWeightSpan1}><text>Allow Coupon Till Booking Date:</text> </Col>
                                            <Col sm={{size:3}} style={textDecor1}>{this.state.couponDetails[0].SUPPORT_COUPON_BOOKING_DATE_TO !== null ? moment(this.state.couponDetails[0].SUPPORT_COUPON_BOOKING_DATE_TO).format('DD-MMM-YYYY') :<text >---</text> }</Col>
                                        </Row>
                                        {/* <Row>
                                            <Col sm={{size:3}} style={fWeightSpan}><text>Service from:</text> </Col>
                                            <Col sm={{size:'auto'}}><text style={textDecor}>{this.state.couponDetails[0].SUPPORT_COUPON_SERVICE_DATE_FROM !== null ? this.state.couponDetails[0].SUPPORT_COUPON_SERVICE_DATE_FROM :<text style={{color: 'red'}}> --</text> }</text></Col>
                                        </Row>
                                        <Row>
                                            <Col sm={{size:3}} style={fWeightSpan}><text>Service to:</text> </Col>
                                            <Col sm={{size:'auto'}}><text style={textDecor}>{this.state.couponDetails[0].SUPPORT_COUPON_SERVICE_DATE_TO !== null ? this.state.couponDetails[0].SUPPORT_COUPON_SERVICE_DATE_TO :<text style={{color: 'red'}}> --</text> }</text></Col>
                                        </Row> */}
                                        <Row>
                                            <Col sm={{size:3}} style={fWeightSpan1}><text>City:</text> </Col>
                                            <Col sm={{size:9}} style={textDecor1}>{this.state.city !== null ? this.state.city :<text> ---</text> }</Col>
                                        </Row>
                                        <Row>
                                            <Col sm={{size:3}} style={fWeightSpan1}><text>Category:</text> </Col>
                                            <Col sm={{size:9}} style={textDecor1}>{this.state.category !== null ? this.state.category :<text> --- </text> }</Col>
                                        </Row>
                                        <Row>
                                            <Col sm={{size:3}} style={fWeightSpan1}><text>Subcategory:</text> </Col>
                                    <Col sm={{size:9}} style={textDecor1}>{this.state.subCategory !== null ? this.state.subCategory :<text > --- </text> }</Col>
                                        </Row>
                                        </CardText>
                                        
                                </CardBody>
                            </Card>
                        </ModalBody>                   
                <ModalFooter>
                        <Button color="danger" onClick={this.modalSwitchHandler.bind(this,delId)}><text style={{fontFamily:'Muli'}}>Delete</text></Button>{' '}
                        <Button color="secondary" onClick={this.currentModalCloseHandler}><text style={{fontFamily:'Muli'}}>Close</text></Button>
                </ModalFooter>
            </Modal>
        );
    }
    let currentStyle;
    let pastStyle;
    if(this.state.current){
        currentStyle={
            backgroundColor:'#2C2165',
            fontFamily:'Muli'
        }
    }
    if(this.state.past){
        pastStyle={
            backgroundColor:'#2C2165',
            fontFamily:'Muli'
        }
    }
        return (
            <div>
                <Container fluid >
                
            <div style={{marginBottom: 40}}>
               <ButtonGroup>
                   <Button style={currentStyle} onClick={this.currentTicketHandler} active={this.state.current ? true : false}><text style={{fontFamily:'Muli'}}> Current Coupons </text></Button>
                   <Button  style={pastStyle} onClick={this.pastTicketHandler} active={this.state.past ? true : false}><text style={{fontFamily:'Muli'}}>Past Coupons</text> </Button>
               </ButtonGroup>
            </div>
            <Container fluid style={{width: 600}}>
                {this.state.current ? current : null}  
                {this.state.past ? past : null} 
                {this.state.modal ? modal : null}
            <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/>

                {this.state.currentModalView ? currentModalDetail : null}
                {this.state.pastModalView ? pastModalDetail : null}
            </Container>                
                
            <ToastContainer autoClose={2500}/>      
            </Container>           
            </div>           

        )
    }
} 
const mapStateToProps=state=>{
    return {
      id:state.id 
  }
  }  
  export default connect(mapStateToProps)(supportViewCoupon);
