const initialState={
    token:null,
    id:null,
    email:"",
    fname:"",
    role:"",
    country:""

}

const reducer=(state=initialState,action)=>{

    switch(action.type)
    {
        case "SET_TOKEN":
        return {
            ...state,
            token:action.token
        }
        case "SET_CREDENTIALS":
        return {
            ...state,
            id:action.userDetails.id,
            email:action.userDetails.email,
            fname:action.userDetails.fname,
            role:action.userDetails.role
        }
        case "REMOVE_TOKEN":
        return {
            ...state,
            token:null
        }
        case "REMOVE_CREDENTIALS":
        return {
            ...state,
            id:null,
            email:null,
            fname:null,
            role:null
        }

        case "USA":
        return {
            ...state,
            country:'USA'
        }

        case "India":
        return {
            ...state,
            country: 'India'
        }
    }

    return state;
}

export default reducer;