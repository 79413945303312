import React,{Component} from 'react';
import axios from 'axios';
import {BarLoader} from "react-spinners";
import {Container, Table, Form, FormText, ButtonGroup, Button,Input,Row,Col,Popover,PopoverBody} from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import Curr from 'currency.js';

class EarnedCouponsView extends Component{
    state={
        earnedCoupon_Data:[],
        loading:false
    }
    componentDidMount(){
        console.log('props',this.props)
        this.setState({loading:true})
        let body={
            consumer_id:this.props.location.state.consumer_prid
        }
        axios.post('/support/consumerEarnedCoupons',body)
        .then(res=>{
            console.log('res',res.data)
            if(res.data.data){
                if(res.data.earnedCouponsData.length!==0){
                    this.setState({
                        ...this.state,
                        earnedCoupon_Data:res.data.earnedCouponsData,
                        loading:false
                    })
                }
                else{
                    if(res.data.earnedCouponsData.length==0){
                        this.setState({
                            ...this.state,
                            earnedCoupon_Data:[],
                            // loading:true
                        })
                    }
                    else{
                        if(res.data.dbError===true){  
                            toast.error("Internal Server Error",{position:toast.POSITION.BOTTOM_CENTER});  
                        }
                    }
                }
            }
        })
        .catch(error=>{
            console.log(error)
            toast.warn("Unable to Reach Server! Please Try Again!",{position:toast.POSITION.BOTTOM_CENTER});

        })
    }
    couponViewHandler=(id)=>{
        console.log('id',id)
        let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
        if(this.state.earnedCoupon_Data.length!==0){
            let fildata=this.state.earnedCoupon_Data.filter(fil=>{
                return fil.QF_OUR_COUPON_PRID==id;
            })
            console.log('filData',fildata)
            this.props.history.push({
                pathname:url+"/CouponSearchDetails",
                state:{
                    our:true,
                    coupon_code:fildata,
                    url,
                    id
                }
            });
            }
    }
    render(){
        const LinkedText = {
            textDecoration:'underline',
            cursor:'pointer', 
            color: '#302939', 
            fontWeight:'bold',
            textTransform: 'uppercase',
            fontFamily: 'Muli',
            color: '#563951'
        }
        let earnedCoupons=null;
        if(this.state.earnedCoupon_Data.length!==0){
        earnedCoupons=( <Table style={{marginTop:20}}>
                <thead style={{backgroundColor:'#2C2165',color:"white", fontFamily:'Muli'}}>
                    <tr style={{cursor:'pointer'}}>
                        <th>Coupon Code</th>
                        <th>Coupon Discount Amount</th>
                        {/* <th>Coupon Type</th>*/}
                        <th>Scratch Card Status</th>
                    </tr>
                </thead>
                <tbody style={{fontFamily:"Muli", color: '#563951',fontWeight:'bold',}}>
                    {this.state.earnedCoupon_Data.map((item)=>{
                   return (<tr key={item.QF_OUR_COUPON_PRID}>
                        <td style={LinkedText} onClick={()=>this.couponViewHandler(item.QF_OUR_COUPON_PRID)}>{item.OUR_COUPON_CODE}</td>
                        <td>{item.OUR_COUPON_VALUE_OFF?"$"+Curr(item.OUR_COUPON_VALUE_OFF).value.toFixed(2):"---"}</td>
                        <td>{<text>Scratched</text>}</td>
                    </tr>
                    )
                    })}
                </tbody>
            </Table>)

        }
        else{
                earnedCoupons=(
                    <p>This Customer not yet earned any coupons</p>
                )
            
        }
        return(
            <div>
            <div className="row justify-content-center" style={{marginTop: 20, marginBottom: 20}}>
                <div className="col-3" >
                <Form>
                <FormText style={{fontSize:24,marginBottom:20}}>Earned Coupon Details</FormText>
                </Form>
                </div>
            </div>
            {/* <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/> */}
                <Container style={{width:1000}}>
                    {earnedCoupons?earnedCoupons:null}
                </Container>
            </div>
        )
    }
}
export default EarnedCouponsView;