import React,{Component} from "react";
import { Container, Col, Row, Card, CardText, CardHeader, Modal,ModalBody, ModalHeader, Table } from "reactstrap";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import "../../../node_modules/react-toastify/dist/ReactToastify.css";
import { BarLoader } from "react-spinners"
import Breadcrumb from '../Navbar/Breadcrumb';
import momenttz from 'moment-timezone';
import Curr from 'currency.js';

class OfferIdSearchDetails extends Component {
    state = {
        offerIdDetails: [],
        bookingDetails:[],
        cancelDet:[],
        merchantName: null,
        mPrid: null,
        subCats: null,
        cats: null,
        loading: false,
        MLocation: [],
        nOfCancelations: null,
        nOfAccepts: null,
        bookingsView: false,
        data: false,
        backdrop: true,
        active: this.props.location.state.active,
        active0: this.props.location.state.active0 ? this.props.location.state.active0 : null,
        active0Link: this.props.location.state.active0Link ? this.props.location.state.active0Link : null,
        active1: this.props.location.state.active1 ? this.props.location.state.active1 : null,
        active1Link: this.props.location.state.active1Link ? this.props.location.state.active1Link : null,
        active2: this.props.location.state.active2 ? this.props.location.state.active2 : null,
        active2Link: this.props.location.state.active2Link ? this.props.location.state.active2Link : null,
        active3: this.props.location.state.active3 ? this.props.location.state.active3 : null,
        active3Link: this.props.location.state.active3Link ? this.props.location.state.active3Link : null,
        active4: this.props.location.state.active4 ? this.props.location.state.active4 : null,
        active4Link: this.props.location.state.active4Link ? this.props.location.state.active4Link : null,
        active5: this.props.location.state.active5 ? this.props.location.state.active5 : null,
        active5Link: this.props.location.state.active5Link ? this.props.location.state.active5Link : null,
        active6: this.props.location.state.active6 ? this.props.location.state.active6 : null,
        active6Link: this.props.location.state.active6Link ? this.props.location.state.active6Link : null,
        active7: this.props.location.state.active7 ? this.props.location.state.active7 : null,
        active7Link: this.props.location.state.active7Link ? this.props.location.state.active7Link : null,
        active8: this.props.location.state.active8 ? this.props.location.state.active8 : null,
        active8Link: this.props.location.state.active8Link ? this.props.location.state.active8Link : null,
        active9: this.props.location.state.active9 ? this.props.location.state.active9 : null,
        active9Link: this.props.location.state.active9Link ? this.props.location.state.active9Link : null,
    }

    componentDidMount() {
        this.setState({
            ...this.state,
            loading: true
        });
        
        let offerId = this.props.location.state.id;
        let mPPrid = null;
        let scPrid = null;
        axios.post("/support/consumer/searchByOfferid", {offerId})
            .then((res) => {    
                if(res.data.data){

                    // console.log("Merchant Details", res.data.details)
                    

                    mPPrid = res.data.details[0].QF_MERCHANT_PERSONNEL_PRID;
                    // console.log("Personnel Prid", mPPrid)
                    scPrid = res.data.details[0].QF_SERVICE_SUB_CATEGORY_PRID;

                    axios.post("/support/mofferviewstats", {id:offerId})
                    .then((res) => {
                        // console.log("yes iam response of mystats", res.data); 
                        if(res.data.data){
                            // console.log("Past offers", res.data);
                        
                                this.setState({
                                    ...this.state,
                                ignores:res.data.stats.rejects[0].rejected_count,
                                pinValidations:res.data.stats.pinvCount[0].pinvCount,
                                nOfAccepts:res.data.stats.acceptedCount[0].accepted_count,
                                nOfCancelations:res.data.stats.canCount,
                                cancelDet:res.data.stats.canceldetails,
                                subcatDetails:res.data.stats.subcatDetails[0].service_sub_category_name,
                                deatailOfCurrentCard:res.data.stats.offerDetails,
                                
                                });


                                axios.post("/support/consumer/merchantname/merchantPersonnelToMerchantName", {mPPrid})
                                .then((res1) => {
                                    // console.log("Merchant Name", res1.data);
                                    if(res1.data.data){
                                        this.setState({
                                            ...this.state,
                                            merchantName: res1.data.mNameDetails[0].MERCHANT_NAME,
                                            mPrid: res1.data.mNameDetails[0].QF_MERCHANT_PRID,
                                            MLocation: res1.data.mNameDetails
                                        })

                                        axios.post("/support/consumer/subcategory/pridToName", {scPrid})
                                        .then((res2) => {
                                            // console.log("Subcategory", res2.data);
                                            if(res2.data.data){
                                                this.setState({
                                                    ...this.state,
                                                    cats: res2.data.subCats ? res2.data.subCats[0].category_name : null,
                                                    subCats: res2.data.subCats ? res2.data.subCats[0].service_sub_category_name : null,
                                                    loading: false
                                                });

                                                

                                            } else {
                                                if(res2.data.dbError){
                                                    this.setState({
                                                        ...this.state,
                                                        loading: false
                                                    })
                                                    
                                                    toast.error("Internal Server Error", {
                                                        position: toast.POSITION.BOTTOM_CENTER
                                                    });
                                                } else {
                                                    this.setState({
                                                        ...this.state,
                                                        cats: null,
                                                        subCats: null,
                                                    });
                                                }
                                            }                       
                                        })
                                        .catch((err) => {
                                            this.setState({
                                                ...this.state,
                                                loading: false
                                            })
                                            console.log(err);
                                            toast.warn("Unable to Reach Server! Please Try Again!", {
                                                position: toast.POSITION.BOTTOM_CENTER
                                            });
                                        })
                                    } else {
                                        if(res1.data.dbError){
                                            this.setState({
                                                ...this.state,
                                                // offerIdDetails: [],
                                                loading: false               
                                            });
                                            toast.error("Internal Server Error", {
                                                position: toast.POSITION.BOTTOM_CENTER
                                            });
                                        } else {
                                            this.setState({
                                                ...this.state,
                                                offerIdDetails: [],
                                                merchantName: null,
                                            });
                                            // toast.info("No Data Found", {
                                            //     position: toast.POSITION.BOTTOM_CENTER
                                            // });

                                            axios.post("/support/consumer/subcategory/pridToName", {scPrid})
                                            .then((res2) => {
                                                // console.log("Subcategory", res2.data);
                                                if(res2.data.data){
                                                    this.setState({
                                                        ...this.state,
                                                        cats: res2.data.cats ? res2.data.cats[0].category_name : null,
                                                        subCats: res2.data.subCats ? res2.data.subCats[0].service_sub_category_name : null,
                                                        loading: false
                                                    });

                                                    

                                                } else {
                                                    if(res2.data.dbError){
                                                        this.setState({
                                                            ...this.state,
                                                            loading: false
                                                        })
                                                        
                                                        toast.error("Internal Server Error", {
                                                            position: toast.POSITION.BOTTOM_CENTER
                                                        });
                                                    } else {

                                                    
                                                        this.setState({
                                                            ...this.state,
                                                            cats: null,
                                                            subCats: null,
                                                        });
                                                    }
                                                }                       
                                            })
                                            .catch((err) => {
                                                this.setState({
                                                    ...this.state,
                                                    loading: false
                                                })
                                                console.log(err);
                                                toast.warn("Unable to Reach Server! Please Try Again!", {
                                                    position: toast.POSITION.BOTTOM_CENTER
                                                });
                                            })
                                        }
                                    }
                                })
                                .catch((err) => {
                                    console.log(err);
                                    this.setState({
                                        ...this.state,
                                        loading: false
                                    })
                                    toast.warn("Unable to Reach Server! Please Try Again!" , {
                                    position: toast.POSITION.BOTTOM_CENTER
                                    });
                                });
                            }
                        else {
                            if(res.data.dbError){
                                toast.error("Internal Server Error!",{
                                    position: toast.POSITION.BOTTOM_CENTER
                                });
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                            } else {
                                // this.setState({
                                //     ...this.state,
                                //     loading: false
                                // });
                                
                            }
                            
                            
                        }
                    
                    })
                    .catch((err) => {
                        console.log("error in stats",err)
                        toast.warn("Unable to Reach Server! Please Try Again!",{
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        this.setState({
                            ...this.state,
                            loading: false
                        });
                    })

                    this.setState({
                        ...this.state,
                        offerIdDetails: res.data.details,
                        // loading: false
                    });

                } else {
                    if(res.data.dbError){
                        this.setState({
                            ...this.state,
                            loading: false,
                            // offerIdDetails: [],              
                        });
                        toast.error("Internal Server Error", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    } else {
                        this.setState({
                            ...this.state,
                            // loading: false,
                            offerIdDetails: [],                 
                        });
                        // toast.info("No Data Found", {
                        //     position: toast.POSITION.BOTTOM_CENTER
                        // });
                    }
                }   
                
               
                
            })
            .catch((err) => {
                console.log(err);
                toast.warn("Unable to Reach Server! Please Try Again!" , {
                    position: toast.POSITION.BOTTOM_CENTER
                  });
                  this.setState({
                      ...this.state,
                      loading: false
                  })
            });
        
    }

    MerchantDetailsHandler=(id)=>{
        // console.log("Merchan personnel prid", id);
        let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;

        this.props.history.push({
            pathname:url+'/MerchantSearchDetails',
            state:{
                condata:this.props.location.state.condata,
                id: id,
                url,
                active: this.state.active,
                active0: this.state.active0,
                active0Link: this.state.active0Link,
                active1: this.state.active1,
                active1Link: this.state.active1Link,
                active2: this.state.active2 ,
                active2Link: this.state.active2Link,
                active3: this.state.active3,
                active3Link: this.state.active3Link,
                active4: this.state.active4,
                active4Link: this.state.active4Link,
                active5: this.state.active5,
                active5Link: this.state.active5Link,
                active6: this.state.active6,
                active6Link: this.state.active6Link,
                active7: this.state.active7,
                active7Link: this.state.active7Link ,
                active8: this.state.active8 ,
                active8Link: this.state.active8Link,
                active9: this.state.active9,
                active9Link: this.state.active9Link,
             
        }});
      
    }

    // toggle = () => {
    //     this.setState(prevState => ({
            
    //             bookingsView: !prevState.bookingsView
            
          
    //     }));
    //   }
    
    

    noofBookingDisplayHandler = (offerId) => {
        // this.setState({
        //     ...this.state,
        //     bookingsView: true
        // })
        let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
        axios.post('/support/offer/allBookingDetails', {offerId})
        .then((res) => {
            // console.log("bookings response",res.data );
            if(res.data.data){
                if(res.data.result.length !== 0){
                    this.setState({
                        ...this.state,
                        bookingDetails: res.data.result
                    })

                    this.props.history.push({
                        pathname: url+'/BookingsTabView',
                        state:{
                          accepted_data: this.state.bookingDetails,
                          url,
                          offerId
                        }
                  
                      })
                }
            } else {
                if(res.data.dbError){
                    this.setState({
                        ...this.state,
                        loading: false,
                        bookingDetails: [],              
                    });
                    toast.error("Internal Server Error", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                } else {
                    this.setState({
                        ...this.state,
                        // loading: false,
                        bookingDetails: [],                 
                    });
                    // toast.info("No Data Found", {
                    //     position: toast.POSITION.BOTTOM_CENTER
                    // });
                }
            }
        })
        .catch((err) => {
            console.log("Error", err);
            toast.warn("Unable to Reach Server! Please Try Again!" , {
                position: toast.POSITION.BOTTOM_CENTER
              });
              this.setState({
                  ...this.state,
                  loading: false
              })
        })
    }


    cancelHandler = () => {
        let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
        this.props.history.push({
            pathname: url+'/offerCancellations',
            state:{
              accepted_data:this.state.cancelDet,
              url 
            }
      
          })
    
    }

    render() {
        console.log("State", this.state);
        // console.log("props", this.props);
        
        const rowStyle={
            textAlign:"initial"
        }

        const fWeightSpan={
            fontWeight:"500",
            marginRight: 5,
            fontFamily: 'Muli',
            color: '#868686'
        }
        const fontText = {
            // color:'red',
            // fontWeight: 'bold'            
        }
        const fontText1 = {
            fontFamily: 'Muli',
            fontWeight:'bold',
            // marginLeft: 5,
            color: '#563951'            
        }
        const LinkedText = {
            textDecoration:'underline',
            cursor:'pointer', 
            fontWeight:'bold',
            textTransform: 'uppercase',
            fontFamily: 'Muli',
            // marginLeft: 5,
            color: '#563951'
        }

        const textDecor = {
            fontWeight:"bold",
            fontFamily: 'Muli',
            // marginLeft: 5,
            color: '#563951'
        }

        let detailView = null;
        if(this.state.offerIdDetails.length !== 0){
            detailView = (
                    <Card style={{border:"1px solid #a78bba"}}>
                        <CardHeader style={{fontSize:"18px",color:"#fff",backgroundColor:"#2C2165", fontFamily:'Muli'}}>Offer Details</CardHeader>
                        <CardText style={{padding:"25px"}}>
                            <Row className="mb-4" style={rowStyle}>

                                    <Col className="col-4">
                                        <text style={fontText1}><span style={fWeightSpan}>Offer Id:</span>{this.state.offerIdDetails[0].QF_MERCHANT_OFFER_PRID ?  <text style={{fontWeight: 'bold'}} onClick={this.offerDetailsHandler}>{this.state.offerIdDetails[0].QF_MERCHANT_OFFER_PRID}</text> : <text style={fontText}>---</text> } </text>
                                    </Col>

                                    <Col className="col-4">
                                        <text style={fontText1}><span style={fWeightSpan}>Professional Name: </span>{this.state.merchantName ? <text style={LinkedText} onClick={() => this.MerchantDetailsHandler(this.state.mPrid)}>{this.state.merchantName}</text> : <text style={fontText}>---</text>}  </text>
                                    </Col>
                                
                                    <Col className="col-4">
                                        <text style={fontText1}><span style={fWeightSpan}>Professional Location:</span>{this.state.MLocation.length !==0 ? <text>{this.state.MLocation[0].MERCHANT_ADDRESS_LINE2}{', '}{this.state.MLocation[0].MERCHANT_ADDRESS_LINE3 ? <text> {this.state.MLocation[0].MERCHANT_ADDRESS_LINE3}{', '} </text> : null}{this.state.MLocation[0].MERCHANT_ADDRESS_CITY}{', '}{this.state.MLocation[0].MERCHANT_ADDRESS_STATE}{', '}{this.state.MLocation[0].MERCHANT_ADDRESS_COUNTRY}{' - '}{this.state.MLocation[0].MERCHANT_ADDRESS_ZIPCODE}</text> : <text style={fontText}>---</text> } </text>
                                    </Col>                      

                            </Row>
                            <Row className="mb-4" style={rowStyle}>

                                    <Col className="col-4">
                                        <text style={fontText1}><span style={fWeightSpan}>Offer Name: </span>{this.state.offerIdDetails[0].MERCHANT_OFFER_HEADER_NAME ? this.state.offerIdDetails[0].MERCHANT_OFFER_HEADER_NAME : <text style={fontText}>---</text>}  </text>
                                    </Col>

                                    <Col className="col-4">
                                        <text style={fontText1}><span style={fWeightSpan}> Description:</span>{ this.state.offerIdDetails[0].MERCHANT_OFFER_DETAIL_DESC ? this.state.offerIdDetails[0].MERCHANT_OFFER_DETAIL_DESC : <text style={fontText}>---</text> } </text>
                                    </Col>                             
                                        
                                    <Col className="col-4">
                                        <text style={fontText1}><span style={fWeightSpan}> Category:</span>{ this.state.cats ? this.state.cats : <text style={fontText}>---</text> } </text>
                                    </Col>            

                            </Row>
                            <Row className="mb-4" style={rowStyle}>                       

                                    <Col className="col-4">
                                        <text style={fontText1}><span style={fWeightSpan}> Subcategory:</span>{this.state.subCats ? this.state.subCats : <text style={fontText}>---</text> } </text>
                                    </Col> 

                                   



                                    <Col className="col-4">                                
                                        <text style={fontText1}><span style={fWeightSpan}>Time Zone:</span>{this.state.offerIdDetails[0].MERCHANT_OFFER_TIMEZONE ? this.state.offerIdDetails[0].MERCHANT_OFFER_TIMEZONE : <text style={fontText}>---</text>}</text>
                            
                                    </Col> 

                                    <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Offer Status:</span>
                                    <text > {this.state.offerIdDetails[0].MERCHANT_OFFER_DELETED_FLAG ?
                                                <span style={{backgroundColor:"#F86C69",padding:10,width:100,color:"#fff",borderRadius:"3px"}}>Deleted</span> 
                                            :
                                            momenttz(this.state.offerIdDetails[0].MERCHANT_OFFER_TO_DATE_TIME+"Z").tz(this.state.offerIdDetails[0].MERCHANT_OFFER_TIMEZONE).isBefore(new Date()) ? 
                                                <span style={{backgroundColor:"#F86C69",padding:10,width:100,color:"#fff",borderRadius:"3px"}}>Expired</span>
                                            :
                                                <span style={{backgroundColor:"#3DC5B6",padding:10,width:100,color:"#fff",borderRadius:"3px"}}>Active</span> 
                                            }
                                    </text></text>
                                </Col>
                                    
                            </Row>
                            <Row className="mb-4" style={rowStyle}>

                            <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}> Offer Created Date:</span>{this.state.offerIdDetails[0].MERCHANT_OFFER_CREATED_ON_DATE ? momenttz(this.state.offerIdDetails[0].MERCHANT_OFFER_CREATED_ON_DATE+"Z").tz(this.state.offerIdDetails[0].MERCHANT_OFFER_TIMEZONE).format('DD-MMM-YYYY hh:mm A z') : <text style={fontText}>------</text>}</text>
                                </Col>
                                </Row>
                            <hr />
                            <Row className="mb-4" style={rowStyle}>

                                    <Col className="col-4">
                                        <text style={fontText1}><span style={fWeightSpan}>Offer Date:</span>{this.state.offerIdDetails[0].MERCHANT_OFFER_FROM_DATE_TIME ? momenttz(this.state.offerIdDetails[0].MERCHANT_OFFER_FROM_DATE_TIME+"Z").tz(this.state.offerIdDetails[0].MERCHANT_OFFER_TIMEZONE).format('DD-MMM-YYYY') : <text style={fontText}>---</text>} </text>
                                    </Col>

                                    <Col className="col-4">
                                        <text style={fontText1}><span style={fWeightSpan}>From:</span>{this.state.offerIdDetails[0].MERCHANT_OFFER_FROM_DATE_TIME ? momenttz(this.state.offerIdDetails[0].MERCHANT_OFFER_FROM_DATE_TIME+"Z").tz(this.state.offerIdDetails[0].MERCHANT_OFFER_TIMEZONE).format('hh:mm A z') : <text style={fontText}>---</text>}</text>
                                    </Col> 
                                
                                    <Col className="col-4">
                                        <text style={fontText1}><span style={fWeightSpan}>Till:</span>{this.state.offerIdDetails[0].MERCHANT_OFFER_TO_DATE_TIME ? momenttz(this.state.offerIdDetails[0].MERCHANT_OFFER_TO_DATE_TIME+"Z").tz(this.state.offerIdDetails[0].MERCHANT_OFFER_TIMEZONE).format('hh:mm A z') : <text style={fontText}>---</text>}</text>
                                    </Col>
                            </Row>
                            
                            <hr />
                            <Row className="mb-4" style={rowStyle}>                                    

                                    <Col className="col-4">
                                        <text style={fontText1}><span style={fWeightSpan}>No. of Offer Limited To:</span>{this.state.offerIdDetails[0].MERCHANT_MAX_NUMBER_OF_OFFERS ? this.state.offerIdDetails[0].MERCHANT_MAX_NUMBER_OF_OFFERS : <text style={fontText}>0</text>}</text>
                                    </Col>

                                    <Col className="col-4">
                                        <text style={fontText1}><span style={fWeightSpan}>No. of Bookings:</span>{this.state.nOfAccepts ? <text style={LinkedText} onClick={() => this.noofBookingDisplayHandler(this.state.offerIdDetails[0].QF_MERCHANT_OFFER_PRID)}>{this.state.nOfAccepts}</text> : <text style={fontText}>0</text>}</text>
                                    </Col> 

                                    <Col className="col-4">
                                        <text style={fontText1}><span style={fWeightSpan}>Offer Counts:</span>{this.state.nOfAccepts ? this.state.nOfAccepts : <text style={fontText}>0</text>}{'/'}{this.state.offerIdDetails[0].MERCHANT_MAX_NUMBER_OF_OFFERS ? this.state.offerIdDetails[0].MERCHANT_MAX_NUMBER_OF_OFFERS : <text style={fontText}>0</text>}</text>
                                    </Col>                                  

                            </Row>
                            <Row className="mb-4" style={rowStyle}>                          

                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>No. of Cancellations:</span>
                                    {this.state.nOfCancelations===null||0?0:this.state.nOfCancelations>0?<text style={LinkedText} onClick={()=>this.cancelHandler()}>{this.state.nOfCancelations}</text>:this.state.nOfCancelations}
                                    </text>
                                </Col>


                            </Row>

                            <hr />
                            <Row className="mb-4" style={rowStyle}>  

                                    <Col className="col-4">
                                        <text style={fontText1}><span style={fWeightSpan}>Regular Price:</span>
                                            {this.state.offerIdDetails[0].MERCHANT_OFFER_CURRENCY === "Rupees" ? <span>{'\u20B9'}</span> : this.state.offerIdDetails[0].MERCHANT_OFFER_CURRENCY === "Dollars" ? <span>$</span>: this.state.offerIdDetails[0].MERCHANT_OFFER_CURRENCY }
                                            {this.state.offerIdDetails[0].MERCHANT_OFFER_LIST_PRICE !== "" ? Curr(this.state.offerIdDetails[0].MERCHANT_OFFER_LIST_PRICE).value.toFixed(2) : <text style={fontText}> 0 </text>} </text>
                                    </Col> 

                                    <Col className="col-4">
                                        <text style={fontText1}><span style={fWeightSpan}> Offer Discount Percent:</span>{this.state.offerIdDetails[0].MERCHANT_OFFER_DISCOUNT ? this.state.offerIdDetails[0].MERCHANT_OFFER_DISCOUNT.toFixed(2) : Curr(Curr(Curr(this.state.offerIdDetails[0].MERCHANT_OFFER_LIST_PRICE).subtract(this.state.offerIdDetails[0].MERCHANT_OFFER_PRICE).value).multiply(100).value / this.state.offerIdDetails[0].MERCHANT_OFFER_LIST_PRICE).value.toFixed(2)}<span>%</span> </text>
                                    </Col>

                                    <Col className="col-4">
                                        <text style={fontText1}><span style={fWeightSpan}> Offer Discount Amount:</span>
                                            {this.state.offerIdDetails[0].MERCHANT_OFFER_CURRENCY === "Rupees" ? <span>{'\u20B9'}</span> : this.state.offerIdDetails[0].MERCHANT_OFFER_CURRENCY === "Dollars" ? <span>$</span>: this.state.offerIdDetails[0].MERCHANT_OFFER_CURRENCY}
                                            {this.state.offerIdDetails[0].MERCHANT_OFFER_PRICE ? Curr(this.state.offerIdDetails[0].MERCHANT_OFFER_LIST_PRICE).subtract(this.state.offerIdDetails[0].MERCHANT_OFFER_PRICE).value.toFixed(2) : <text style={fontText}>0</text> } </text>
                                    </Col>
                                    
                                                                             

                            </Row>
                            
                            <Row className="mb-4" style={rowStyle}>
                            
                                    <Col className="col-4">
                                        <text style={fontText1}><span style={fWeightSpan}> Offer Price:</span>
                                            {this.state.offerIdDetails[0].MERCHANT_OFFER_CURRENCY === "Rupees" ? <span>{'\u20B9'}</span> : this.state.offerIdDetails[0].MERCHANT_OFFER_CURRENCY === "Dollars" ? <span>$</span>: this.state.offerIdDetails[0].MERCHANT_OFFER_CURRENCY}
                                            {this.state.offerIdDetails[0].MERCHANT_OFFER_PRICE ? Curr(this.state.offerIdDetails[0].MERCHANT_OFFER_PRICE).value.toFixed(2) : <text style={fontText}>0</text> } </text>
                                    </Col>  

                            </Row>
                            <hr />
                            <Row className="mb-4" style={rowStyle}>

                                    

                                    <Col className="col-4">
                                        <text style={fontText1}><span style={fWeightSpan}>Cancellation Allowed:</span>{this.state.offerIdDetails[0].MERCHANT_OFFER_CANCEL_ALLOWED ? <span style={{backgroundColor:"#3DC5B6",padding:"5px",color:"#fff",borderRadius:"3px"}}> Yes </span>:<span style={{backgroundColor:"#F86C69",padding:"5px",color:"#fff",borderRadius:"3px"}}>No</span>}</text>
                                    </Col>

                                    {
                                        this.state.offerIdDetails[0].MERCHANT_OFFER_CANCEL_ALLOWED ? 
                                            <Col className="col-4">
                                                <text style={fontText1}><span style={fWeightSpan}>Days Before Cancellation Allowed:</span>{this.state.offerIdDetails[0].MERCHANT_OFFER_CANCEL_ALLOWED_MINUTES_BEFORE ? this.state.offerIdDetails[0].MERCHANT_OFFER_CANCEL_ALLOWED_MINUTES_BEFORE : <text style={fontText}>0</text> }{this.state.offerIdDetails[0].MERCHANT_OFFER_CANCEL_ALLOWED_MINUTES_BEFORE === 1 ? <span> day</span> : this.state.offerIdDetails[0].MERCHANT_OFFER_CANCEL_ALLOWED_MINUTES_BEFORE === 0 ? <span> day</span> : <span> days</span>}</text>
                                            </Col>
                                        : null
                                        }

                                                           
                                        {
                                            this.state.offerIdDetails[0].MERCHANT_OFFER_CANCEL_ALLOWED ? 
                                                <Col className="col-4">
                                                    <text style={fontText1}><span style={fWeightSpan}>Cancellation Allowed Before:</span>
                                                        {momenttz(this.state.offerIdDetails[0].MERCHANT_OFFER_FROM_DATE_TIME+"Z").tz(this.state.offerIdDetails[0].MERCHANT_OFFER_TIMEZONE).subtract(this.state.offerIdDetails[0].MERCHANT_OFFER_CANCEL_ALLOWED_MINUTES_BEFORE, 'days' ).format('DD MMM YYYY hh:mm A z')}
                                                                                                        
                                                    </text>
                                                </Col>
                                            : null
                                            }

                            </Row>
                            <Row className="mb-4" style={rowStyle}>
                            

                            { this.state.offerIdDetails[0].MERCHANT_OFFER_CANCEL_FEE === 0 || this.state.offerIdDetails[0].MERCHANT_OFFER_CANCEL_FEE !== null ?

                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Cancellation Fee:</span>
                                        {this.state.offerIdDetails[0].MERCHANT_OFFER_CURRENCY === "Rupees" ? <span>{'\u20B9'}</span> : this.state.offerIdDetails[0].MERCHANT_OFFER_CURRENCY === "Dollars" ? <span>$</span>: this.state.offerIdDetails[0].MERCHANT_OFFER_CURRENCY}
                                        {this.state.offerIdDetails[0].MERCHANT_OFFER_CANCEL_FEE ? Curr(this.state.offerIdDetails[0].MERCHANT_OFFER_CANCEL_FEE).value.toFixed(2) : this.state.offerIdDetails[0].MERCHANT_OFFER_CANCEL_FEE === 0 ? Curr(this.state.offerIdDetails[0].MERCHANT_OFFER_CANCEL_FEE).value : null} </text>
                                </Col>
                            :
                            null
                            }

                            </Row>
                    </CardText>
                    
                    </Card>
            )
        }

        // let bookingsModal = (
        //     <Modal isOpen={this.state.bookingsView} size='lg' backdrop={this.state.backdrop} toggle={this.toggle}>
        //         <ModalHeader>Bookings for Offer-Id {' '}{this.props.location.state.id}</ModalHeader>
        //         <ModalBody>
        //         <Table responsive> 
        //                 <thead style={{marginTop: 10,backgroundColor:'#2C2165',color:"white"}}>
        //                     <tr>
        //                         <th>Booking Id </th>
        //                         <th>Consumed </th>
        //                         <th>Severity</th>
        //                         <th>Description / Resolution </th>
        //                         <th>Created Date</th>
        //                         <th>Created By </th>
        //                     </tr>
        //                 </thead>
        //                 <tbody>
        //                     {/* {this.props.Sdetails.map((detail, index) => {
        //                         return ( */}
        //                             <tr >                            
        //                                 <td style={LinkedText}></td>
        //                                 <td></td>
        //                                 <td></td>
        //                                 <td></td>
        //                                 <td></td>
        //                                 <td></td>    
        //                             </tr>
        //                         {/* )
        //                     }) */}
        //                     {/* } */}
                        
                    
                            
        //                 </tbody>
        //                 </Table>
        //         </ModalBody>
        //     </Modal>            
        // )

        return (
            <div>
                {/* <Breadcrumb offer='Offer Details' offerLink='/home/offerDetails/OfferIdSearchDetails' breadCrumb={true}/> */}
            
                <Container fluid style={{paddingLeft:"50px",paddingRight:"50px",backgroundColor:"rgb(239,239,239)"}}>
                    <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/>
                    
                    {this.state.loading === false ? detailView : <text style={fontText1}>Loading...</text>}
                    {/* {bookingsModal} */}

                    <ToastContainer />
                                 
                </Container>
            </div>            
        )

    }

}

export default OfferIdSearchDetails;