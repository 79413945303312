import Switch from 'react-switch';

import React, { Component } from 'react'; 
import {connect} from "react-redux";
import axios from 'axios';
import {ToastContainer, toast } from 'react-toastify';
import { ButtonGroup, Button, Card, CardBody,CardTitle, CardText, Form,FormText, Row, Col, Container, Modal,ModalBody,ModalHeader,ModalFooter, Tooltip} from 'reactstrap';
import {BarLoader} from 'react-spinners';
import momenttz from 'moment-timezone'
import moment from 'moment';
import { FaTrashAlt, FaRegEdit } from 'react-icons/fa';
import curr from "currency.js";
// import Breadcrumb from '../Breadcrumb';




class MerchantDisputedBookings extends Component {
   
    state={
       loading: false,
       transactions:[],
       disputes:[],
       transaction: true,
       dispute: false,
       update:null,
       loc: null,
       paymentStatus:0,
       consumerData:[]


    }

    componentDidMount() {
        this.setState({
            ...this.state,
            loading: true
    });
        // console.log('see check',this.props.location.state.country);
        let mpid = this.props.location.state.id
    //   console.log()

        // console.log("M_Prid", mpid);
       

        axios.post("/support/merchant/disputes", {mpid})
        .then((res) => {
            // console.log("dispute res", res.data);
            if(res.data.dis){
               

                               this.setState({
                    ...this.state,
                    disputes: res.data.details,
                    // loc: res.data.resultQ[0].MERCHANT_ADDRESS_COUNTRY,
                    // activeToggle: toggle,
                    loading: false

                })
            } else {
                if(res.data.dbError)
                    {
                        toast.error("Internal Server Error",{
                            position:toast.POSITION.BOTTOM_CENTER
                        })
                        this.setState({
                            ...this.state,
                            transactions: [],
                            // disputes: [],
                            loading: false
                    });
                    }
                    else {
                        this.setState({
                            ...this.state,
                            // transactions: [],
                             disputes: [],
                            loading: false
        
                        })
                    }          
            }
        })
        .catch(err => {
            console.log(err);
            toast.warn("Unable to Reach Server! Please Try Again!" , {
                position: toast.POSITION.BOTTOM_CENTER
              });
            this.setState({
                ...this.state,
                disputes: [],
                loading: false
        });
        })
    }

      
        
    
transactionCouponHandler = () => {
        this.setState({
                ...this.state,
                transaction: true,
                dispute: false,
                //loading: true              
        });
    }

disputeCouponHandler = () => {
        this.setState({
            ...this.state,
            transaction: false,
            dispute: true,
            //loading: true          
        });

    } 
    payDrill=(id)=>{
        
        //  alert('hai',id)
        //  console.log('hai',id)
    // let selectedTrans = this.state.transactions.filter(each=>{
    // return  each.QF_MERCHANT_PAYMENT_PRID===id
    // })
    let TicketId={
        ticketId :id
     }
    axios.post("/support/consumer/SearchByTicketId",TicketId)


    .then(res=>{

        if(res.data.data){
            if(res.data.details.length!==0) 
              {
                
                this.setState(({ 
                              ...this.state,
                            consumerDet: res.data.details,
                            // f_name:res.data.details1.length!==0?res.data.details1[0].SUPPORT_USER_FNAME:null,
                            // l_name:res.data.details1.length!==0?res.data.details1[0].SUPPORT_USER_LNAME:null,
                     
                              loading:false,
                              noResult:false,
                              sClicked:false

                }))
                // console.log('Props render()',this.state.consumerDet)

                let url=this.props.location.state.url ? this.props.location.state.url : this.props.match.url;
                this.props.history.push({
                    pathname: url+'/DisputedOverview',
                    state:{
                        TicketId:this.state.consumerDet[0].QF_CONSUMER_PRID, 
                        acceptance_prid:this.state.consumerDet[0].QF_CONSUMER_OFFER_ACCEPTED_PRID,
                        consumerData:{
                         
                            QF_CONSUMER_OFFER_ACCEPTED_PRID :this.state.consumerDet[0].QF_CONSUMER_OFFER_ACCEPTED_PRID,
                            CONSUMER_SUPPORT_CATEGORY : this.state.consumerDet[0].CONSUMER_SUPPORT_CATEGORY,
                            CONSUMER_SUPPORT_DESC: this.state.consumerDet[0].CONSUMER_SUPPORT_DESC,
                            CONSUMER_SUPPORT_STATUS:this.state.consumerDet[0].CONSUMER_SUPPORT_STATUS,
                            QF_CONSUMER_SUPPORT_PRID:this.state.consumerDet[0].QF_CONSUMER_SUPPORT_PRID,
                            CONSUMER_SUPPORT_RESOLUTION:this.state.consumerDet[0].CONSUMER_SUPPORT_RESOLUTION,
                            CONSUMER_SUPPORT_CREATED_ON_DATE:this.state.consumerDet[0].CONSUMER_SUPPORT_CREATED_ON_DATE,
                            QF_CONSUMER_PRID:this.state.consumerDet[0].QF_CONSUMER_PRID,
                            CONSUMER_SUPPORT_RESOLVED_ON_DATE:this.state.consumerDet[0].CONSUMER_SUPPORT_RESOLVED_ON_DATE,
                            rf_name:this.state.consumerDet[1]?this.state.consumerDet[1].Resolved_fname:'Not', 
                            rl_name:this.state.consumerDet[1]?this.state.consumerDet[1].Resolved_lname:'Resolved',
                            f_name:this.state.consumerDet[0].SUPPORT_USER_FNAME,
                            l_name:this.state.consumerDet[0].SUPPORT_USER_LNAME, 
 
                           },
                        //    f_name:this.state.f_name,
                        //    l_name:this.state.l_name,
                         
                         url
                        } 
                     });

               
              }
              if(res.data.details.length===0)
              {
                //   console.log('yes going')
                this.setState({ 
                    ...this.state,
                   consumerDet:[],

                    submit: {
                   ...this.state.submit,
                    valid: true,
               },
               loading:false
               ,   
               noResult:true,
               sClicked:false
               

              })
               
              }

          }

          else{
                  if(res.data.dbError===true)
                  {
                      
                      toast.error("Internal Server Error.",{position:toast.POSITION.BOTTOM_CENTER});
                      this.setState({loading:false,dbError:true})
                  }
                    
                 else{

                       this.setState({ 
                        ...this.state,
                        consumerDet:[], 
                        noData: true,

                 submit: {
                   ...this.state.submit,
                    valid: true,
               },
               loading:false,
            sClicked:false})

                }                    
                  
          }

      }).catch(err=>{
        console.log(err);
        toast.warn("Unable to Reach Server! Please Try Again!" , {
            position: toast.POSITION.BOTTOM_CENTER
          });
        this.setState({loading:false,
        sClicked:false})
    })
    //  console.log('hahah',id) 

                       // id:this.state.merchantPersonnel[0].QF_MERCHANT_PERSONNEL_PRID,
                // name:this.state.merchantDetails.name,
                //  tz : this.props.location.state.tz,
            //    selectedTrans,
            //    country:this.props.location.state.country,
                

    }
   
   
    
    render() {

        // console.log("State in Coupon", this.state)
        const rowStyle={
            textAlign:"initial",
        }
        const fWeightSpan={
            fontWeight:"400",
            padding: 5,
            fontFamily:'Muli',
            color: '#868686',
            // cursor: 'pointer',
            marginBottom:5,
            // border:'1px solid black'
        }

        const fWeightSpan1={
            fontWeight:"400",
            padding: 5,            
            // cursor: 'pointer',
            marginBottom:10,
            // border:'1px solid black'
        }
        
        const textDecor = {
            fontWeight:"bold",
            // marginLeft: 5,
            fontFamily:'Muli',
            color: '#563951'
        }

        let disputes = "No Disputes";

        if(this.state.disputes.length !== 0){

            disputes = this.state.disputes.map((coupon) => {
                return (
                <Card style={{marginTop: 15, border: '2px solid #a78bba',backgroundColor:'white',width:'100%',cursor:'pointer'}} onClick={()=>this.payDrill(coupon.QF_CONSUMER_SUPPORT_PRID)} >
                        <CardBody>   
                      
                            <CardTitle style={{fontSize:18,marginTop:-15}}></CardTitle>                                                    
                            <CardText style={{marginLeft:20, marginRight: 20}}>
                                
                                <Row style={{backgroundColor: '#f1f1f1', padding: 10}}>
                                    <Col sm={{size:4}}>
                                        <Row>
                                            <Col sm={{size:12}} style={{fontWeight:"400", padding: 5,fontFamily: 'Muli',color: '#868686'}}><text>Dispute Id </text></Col>
                                            <Col sm={{size:12}} style={{fontWeight:"bold", padding: 5, marginBottom:10,}}> <text style={textDecor}>{coupon.QF_CONSUMER_SUPPORT_PRID}</text></Col>
                                        </Row>
                                    </Col>
                                    <Col sm={{size:4}}>
                                        <Row>
                                            <Col sm={{size:12}} style={{fontWeight:"400", padding: 5,fontFamily: 'Muli',color: '#868686'}}><text>Booking Id</text>  </Col>
                                            <Col sm={{size:12}} style={{fontWeight:"bold", padding: 5, marginBottom:10}}><text style={textDecor}>{coupon.QF_CONSUMER_OFFER_ACCEPTED_PRID}</text></Col>
                                        </Row>
                                    </Col>
                                    <Col sm={{size:4}}>
                                        <Row>
                                            <Col sm={{size:12}} style={{fontWeight:"400", padding: 5,fontFamily: 'Muli',color: '#868686'}}><text>Status</text></Col>
                                            <Col sm={{size:12}} style={{fontWeight:"bold", padding: 5, marginBottom:10}}>
                                                <text style={textDecor}> 
                                                    {
                                                        coupon.CONSUMER_SUPPORT_STATUS ===2 ?
                                                            <span style={{backgroundColor:"#3DC5B6",borderRadius:"3px",color:"#fff",padding:5}}>  Resolved </span>
                                                            :
                                                            <span style={{backgroundColor:"#FFC459",borderRadius:"3px",color:"#fff",padding:5}}> Pending </span>
                                                    }
                                                </text>
                                            </Col>                                            
                                        </Row>
                                    </Col>
                            
                               
                                </Row>

                                 
                            </CardText>
                        </CardBody>
                    </Card>
                )
            })
            
        } else {
            if(this.state.loading===true){
                disputes= <text style={textDecor}>Loading...</text>;
            }else{
                if(this.state.disputes.length==0)
                disputes = <text style={textDecor}>No Bookings Disputed</text>;  
        }           
            
                     
        }

       

       

        return (
            
            <div className="row justify-content-center" style={{marginTop: 50}}>
            <div className="col-6" >
           
            <Form>
                <FormText style={{fontSize:24,marginBottom:20}}><text style={{fontFamily: 'Muli',color: '#563951'}}>Disputed Bookings of <strong>{this.props.location.state.name}</strong></text></FormText>
            </Form>               
                
            
            
            <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/>

                {this.state.disputes ? disputes : null}  
              
            
                
            <ToastContainer autoClose={2500}/>     
            
            </div>
            </div>
                     

        )
    }
}  
const mapStateToProps=state=>{
    return {
      id:state.id 
  }
  }  


  export default connect(mapStateToProps)(MerchantDisputedBookings);

  