import React,{Component} from 'react';
import {Container, Table, Form, FormText, ButtonGroup, Button,Input,Row,Col,Popover,PopoverBody} from 'reactstrap';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import momenttz from 'moment-timezone';
import moment from 'moment';
import {BarLoader} from "react-spinners"; 




class AllBookingsView extends Component{
    state = {
        consumer_data:[],
        user_data:[], 
        accepted_data:[],
        upcoming:true,
        past:false, 
        consumer_name:this.props.location.state.consumer_name,
        pastBookings:[],
        currentBookings:[],
        loading:false
       
        
   }
 
    componentDidMount(){
        console.log(this.props);

        this.setState({loading:true})
        // cona
  let body={
    consumer_prid:this.props.location.state.consumer_prid
}
axios.post('/support/consumer/allBookings',body)
.then((res)=>{
console.log('res',res.data)
if(res.data.searchDet){
    this.setState({

        loading:false,
        // consumer_data:res.data.allBookings,
        pastBookings:res.data.PastBookings,
        currentBookings:res.data.UpBookings
    })
}
else
    {
        if(res.data.dbError===true)
        {  
            toast.error("Internal Server Error",{position:toast.POSITION.BOTTOM_CENTER});  
        }

    }
})
.catch(err=>{
            toast.warn("Unable to Reach Server! Please Try Again!",{position:toast.POSITION.BOTTOM_CENTER});


})

//  console.log('p',this.props.location.state)
  
    // axios.post("/support/consumer/ConsumerSearchDetails",{consumer_prid:this.props.location.state.consumer_prid})
    // .then(res=>{
    // console.log('res',res.data)

    //     if("Consumer Data",res.data.searchDet)
    //     {
    //         this.setState({
    //             consumer_data:res.data.consumerDet,
    //             user_data:res.data.userDet ? res.data.userDet.length !== 0 ? res.data.userDet : [] : [],
    //             accepted_data:res.data.accepted_data.length !== 0 ? res.data.accepted_data : [] ,
    //             loading:false
    //         });
    //     }
    //     else
    //     {
    //         if(res.data.dbError===true)
    //         {  toast.error("Internal Server Error",{position:toast.POSITION.BOTTOM_CENTER});  }
    //         // this.setState({loading:false})

    //     }

    // }).catch(err=>{
    //     toast.warn("Unable to Reach Server! Please Try Again!",{position:toast.POSITION.BOTTOM_CENTER});
    //     // this.setState({loading:false})
    // })
  }
  upcomingHandler = () => {
    this.setState({
            ...this.state,
            upcoming: true,
            past: false,
           
           
    });
   
    
}
pastHandler = () => {
    this.setState({
            ...this.state,
            upcoming: false,
            past: true,
          
    });
   
 }


 bookingIdLinkHandler = (booking_id) => {
    let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
    this.props.history.push({
        pathname:url+"/BookingIdSearch",
        state:{
            booking_id,
            url,
        }
    }); 
}
    
render(){
    console.log(this.state)
    let upcoming;
    let past;



    if(this.state.currentBookings.length!==0){
        let i=this.state.currentBookings;
           

           i.length!==0?
           upcoming=(
            //   <div className="row justify-content-center" style={{height: '100%' , backgroundColor: '#EFEFEF'}}>
            //   <div className="col-5" >
                // <Col xs={15}>
                
                <Table hover  > 
                  <thead style={{marginTop: 20,backgroundColor:'#2C2165',color:"white", fontFamily:'Muli'}}>
                    <tr>
                  
                    <th>Booking Id</th>
            <th>Offer Date</th>
            <th>Merchant Name</th>
            <th>Consumer Name/Email</th>  

                   </tr>
                 </thead>
                   <tbody style={{fontFamily:"Muli", color: '#563951',fontWeight:'bold',}}>
            { i.map((booking,key) => {


    if(moment(booking.MERCHANT_OFFER_TO_DATE_TIME,"YYYY-MM-DD HH:mm:ss").isAfter(moment().utc().format("YYYY-MM-DD HH:mm:ss"))){

                 
                    key=booking.QF_CONSUMER_OFFER_ACCEPTED_PRID
                    return (

                        <tr onClick={()=>this.bookingIdLinkHandler(booking.QF_CONSUMER_OFFER_ACCEPTED_PRID)}>

                        <td >{booking.QF_CONSUMER_OFFER_ACCEPTED_PRID}</td>
                        <td>{momenttz(booking.MERCHANT_OFFER_FROM_DATE_TIME+"Z").tz(booking.MERCHANT_OFFER_TIMEZONE).format('DD-MMM-YYYY hh:mm')}-{momenttz(booking.MERCHANT_OFFER_TO_DATE_TIME+"Z").tz(booking.MERCHANT_OFFER_TIMEZONE).format('hh:mm A z')}</td>
                        <td>{booking.MERCHANT_NAME}</td>
                        <td>{booking.CONSUMER_FNAME!=null?booking.CONSUMER_FNAME:booking.CONSUMER_EMAIL!=null?booking.CONSUMER_EMAIL:'Not updated'}</td>

          
                      </tr>
                      
                    )
    }
                })
            }
                
                      
                  </tbody>
                </Table>

            //   </Col>
            //   </div>
            // </div>
              ):upcoming=(
                <p>No Bookings found</p>
              )
           
        }
        else{

          if(this.state.loading===true){
            upcoming=(
            <div className="row justify-content-center" style={{height: '100%' , backgroundColor: '#EFEFEF'}}>
            <div className="col-4" >
            <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/>
            <p>Loading...</p>
            </div>
            </div>)
            }
            else{
                upcoming=(
                <p>No Booking found</p>
              )
            }

        }


        if(this.state.pastBookings.length!==0){
            let i=this.state.pastBookings;
               
    
               i.length!==0?
               past=(
                //   <div className="row justify-content-center" style={{height: '100%' , backgroundColor: '#EFEFEF'}}>
                //   <div className="col-5" >
                    // <Col xs={15}>
                    
                    <Table hover  > 
                      <thead style={{marginTop: 20,backgroundColor:'#2C2165',color:"white", fontFamily:'Muli'}}>
                        <tr>
                      
                        <th width={120}>Booking Id</th>
                <th >Offer Date</th>
                <th>Merchant Name</th>
                <th>Consumer Name/Email</th>  
    
                       </tr>
                     </thead>
                       <tbody style={{fontFamily:"Muli", color: '#563951',fontWeight:'bold',}}>
                { i.map((booking,key) => {

                    if(moment(booking.MERCHANT_OFFER_TO_DATE_TIME,"YYYY-MM-DD HH:mm:ss").isBefore(moment().utc().format("YYYY-MM-DD HH:mm:ss"))){
                     
                        key=booking.QF_CONSUMER_OFFER_ACCEPTED_PRID
                        return (
    
                            <tr onClick={()=>this.bookingIdLinkHandler(booking.QF_CONSUMER_OFFER_ACCEPTED_PRID)}>
    
                            <td>{booking.QF_CONSUMER_OFFER_ACCEPTED_PRID}</td>
                            <td>{momenttz(booking.MERCHANT_OFFER_FROM_DATE_TIME+"Z").tz(booking.MERCHANT_OFFER_TIMEZONE).format('DD-MMM-YYYY hh:mm')}-{momenttz(booking.MERCHANT_OFFER_TO_DATE_TIME+"Z").tz(booking.MERCHANT_OFFER_TIMEZONE).format('hh:mm A z')}</td>
                            <td>{booking.MERCHANT_NAME}</td>
                            <td>{booking.CONSUMER_FNAME!=null?booking.CONSUMER_FNAME:booking.CONSUMER_EMAIL!=null?booking.CONSUMER_EMAIL:'Not updated'}</td>
              
                          </tr>
                          
                        )
                    }
                    })
                }
                    
                          
                      </tbody>
                    </Table>
    
                //   </Col>
                //   </div>
                // </div>
                  ):past=(
                    <p>No Bookings found</p>
                  )
               
            }
            else{
    
              if(this.state.loading===true){
                past=(
                <div className="row justify-content-center" style={{height: '100%' , backgroundColor: '#EFEFEF'}}>
                <div className="col-4" >
                <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/>
                <p>Loading...</p>
                </div>
                </div>)
                }
                else{
                    past=(
                    <p>No Booking found</p>
                  )
                }
    
            }


    

    
   
    return(
        <div>
        <Container fluid>
        <div className="row justify-content-center" style={{marginTop: 20, marginBottom: 20}}>
        <div className="col-3" >
        
           <ButtonGroup >

            <Button onClick={this.upcomingHandler} active={this.state.upcoming? true : false} style={{backgroundColor:'#2C2165', fontFamily:'Muli'}}>Upcoming Bookings</Button>
            <Button onClick={this.pastHandler} active={this.state.past? true : false} style={{backgroundColor:'#2C2165', fontFamily:'Muli'}}>Past Bookings </Button> 
           </ButtonGroup>
            
        
        </div>
        </div>
        <Container fluid style={{width: 850}}> 
        {/* <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/> */}

            {this.state.upcoming?upcoming:null}  
             {this.state.past ? past : null}   
          
        </Container>                
             
        <ToastContainer autoClose={2500}/>   
        </Container>              
        </div>

    )
}
}
export default AllBookingsView;
