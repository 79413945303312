import React,{Component} from "react";
import {Container,Alert,Row,Col} from "reactstrap";
import {NavLink} from "react-router-dom";

class ServerError extends Component
{


    state={

    }

    render()
    {
        return (
            <Container fluid style={{height:"100%"}} className="text-center">
            <Row style={{padding:"100px"}}>
                <Col>
                <Alert color="danger">
                    <h1>Unauthorized User</h1>
                    <hr/>
                    <p>You are trying to access an unauthorized page. Please <NavLink className="alert-link" to="/">Sign In</NavLink>  and continue</p>
                </Alert>
                </Col>
            </Row>
            </Container>
        ) 
    }

}

export default ServerError;
