import React, { Component } from 'react';
import { Form,FormGroup, FormFeedback, Button, Label, Input, Row, Col, Container } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import {BarLoader} from 'react-spinners';



class CustDetails extends Component {

    state = {
        feedback: {
            value: '---Select---',
            valid: false,
            invalid: false,
           
        },
        type: {
            value: '---Select---',
            valid: false,
            invalid: false,
            feedBackoptions:['F1','F2','F3','F4','F5'],
            suggestionOptions:['S1','S2','S3','S4','S5']
        },
        comment: {
            value: '',
            valid: false,
            invalid: false
        },
        submit: {
            invalid: false
        },
        SelectedSuggestion:false,
        SelectedFeedBack:false,
        loading:false,
        fClicked:false
    }
   
typeHandler = (e) =>{
    
    if(e.target.value.length===1)
    {
         this.setState({
            type:{
                 ...this.state.type,
                 value: e.target.value,
                 valid: false,
                 invalid: true
            },
           
        })

    }
    
    else{   
    this.setState({
            type:{
                ...this.state.type,
               value:e.target.value,
               valid:true,invalid:false
            }, submit: {
                invalid: false
            }
        })


    }
}

    feedbackOrSuggestionHandler = (e) =>    {
        if(e.target.value==='---Select---'){
            this.setState({
                feedback:{
                ...this.state.feedback,
                value:e.target.value,
                }
        })
    }
        if(e.target.value==='suggestion')
        {
            
            
             this.setState({
                feedback:{
                ...this.state.feedback,
                value:e.target.value,valid:true,invalid:false
                },
                
              SelectedFeedBack:false,SelectedSuggestion:true,
              type:{
                  ...this.state.type,
                  value:0
              },
              comment:{
                  value:''
              },
              submit: {
                invalid: false
            }
            

       })
           
        }
        if(e.target.value==='feedback')
        {
            
              this.setState({
                  feedback:{
                  ...this.state.feedback,
                  value:e.target.value,
                  valid: true,
                  invalid: false
                  },
                  
                SelectedFeedBack:true,SelectedSuggestion:false,
                type:{
                    ...this.state.type,
                    value:0
                },
                comment:{
                    value:''
                },
                submit: {
                    invalid: false
                }

         })
        }
    
    }
    
    commentFieldHandler = (event) => {
        if(event.target.value.length === 0){
            return this.setState({
                comment:{
                     ...this.state.comment,
                     value: event.target.value,
                     valid: false,
                }
                
            })
        } else {
            return this.setState({
                comment : {
                    ...this.state.comment,
                    value: event.target.value, 
                    valid: true,
                    invalid: false
                },
                submit: {
                    ...this.state.submit, 
                    invalid: false
                }
            }); 
        }        
    } 

    submitButtonHandler = (e) => {
        e.preventDefault();
        
        if(this.state.feedback.value==='---Select---'||this.state.feedback.invalid||this.state.feedback.value.length===0){
            
            this.setState({
                submit: {
                    ...this.state.submit,
                    invalid: true 
                },
                feedback:{
                    ...this.state.feedback,
                    invalid:true,
                    valid:false
                }
           })
            return false;          
           
        }
        else{
            this.setState({
                feedback:{
                    ...this.state.feedback,
                    invalid:false,
                    valid:true
                }
           })

        }
          if(this.state.type.value=== 0|| this.state.type.value==='---Select---'){

            this.setState({
                 type:{
                     ...this.state.type,
                     invalid:true,
                     valid:false
                 },
                 submit: {
                    ...this.state.submit, 
                     invalid: true 
                }
            })
            return false;
            
        }
        else{
            this.setState({
                
                type:{
                    ...this.state.type,
                    invalid:false,
                    valid:true
                },
               
           })

        }
        
   
        let FeedbackDetails=
        {
            feedbackType:this.state.feedback.value,
            type:this.state.type.value,
            comment:this.state.comment.value,
            SelectedFeedBack:this.state.SelectedFeedBack,
            SelectedSuggestion:this.state.SelectedSuggestion
        }
        if(this.state.comment.invalid || this.state.comment.value.length === 0 || (this.state.comment.value).trim() === ''){
            this.setState({
                submit: {
                    ...this.state.submit,
                     invalid: true 
                },
                comment:{
                    ...this.state.comment,
                    valid: false,
                    invalid: true
                }
            });
            return false;
           
        } else {
            this.setState({
                submit: {
                    ...this.state.submit,
                    invalid: false
                },
                comment:{
                    ...this.state.comment,
                    valid: true,
                    invalid: false
                },
                loading:true,
                fClicked:true

              });
           
        }
       
      
        axios.post('/support/consumer/feedbackOrBugReport',FeedbackDetails)
        .then((res) => {
            if(res.data.data){
            this.setState(
                {
                    feedback:{
                        ...this.state.feedback,
                         value:''
                    },
                    type:{
                        ...this.state.type,
                         value:''

                    },
                    comment:{
                        ...this.state.comment,
                        value:''

                    },
                    loading:false,
                    fClicked:false
                    

                }
            )       
            if(FeedbackDetails.SelectedFeedBack){

                toast.success("Feedback Successfully Submitted",{position:toast.POSITION.BOTTOM_CENTER});
            }
            else{

                toast.success("Suggestion Successfully Submitted",{position:toast.POSITION.BOTTOM_CENTER});
            }

        }
        else{
             if(res.data.dbError===true)
             {
                this.setState({
                    fClicked:false
                })
                toast.warn("Internal Server Error",{position:toast.POSITION.BOTTOM_CENTER});
                this.setState({loading:false})
            
            }
       
        }}).catch(err=>{
            this.setState({
                fClicked:false,
                loading:false
            })
            toast.warn("Unable to Reach Server! Please Try Again!" , {
                position: toast.POSITION.BOTTOM_CENTER
              });
            })
                 
        
    }
    
    render() {

        const fWeightSpan={
            fontWeight:"500",
            marginRight: 5,
            fontFamily: 'Muli',
            color: '#868686'
        }
        const fontText1 = {
            fontWeight:'bold',
            fontFamily: 'Muli',
            color: '#563951'                        
        }

        let options=null;

        if(this.state.SelectedFeedBack){ 

            options = this.state.type.feedBackoptions.map((option,index) => {

                return <option  key={index}>{option}</option>
           })
             
        }
         if(this.state.SelectedSuggestion){

            options = this.state.type.suggestionOptions.map((option,index) => {

                return <option  key={index}>{option}</option>
           })

        }

        return (
            <div>
                <Container fluid>
           
            <div className="row justify-content-center" style={{marginTop: 50, backgroundColor: '#EFEFEF'}}>
            <div className="col-3">
               <Form>
               <Row>
                   <Col md={6}>
                   <FormGroup row>
                        <Label><text style={fWeightSpan}> Feedback/Suggestion</text> <span style={{color: 'red'}}>*</span></Label>
                        <Input type="select" 
                          value={this.state.feedback.value}
                          onChange={this.feedbackOrSuggestionHandler}
                          valid={this.state.feedback.valid ? true : false}
                          invalid={this.state.feedback.invalid ? true : false} 
                          style={{fontFamily: 'Muli',color: '#563951'}}
                          
                          >
                         <option >---Select---</option>
                         <option value="feedback">Feedback</option>
                         <option value="suggestion">Suggestion</option>
                        </Input>
                        <FormFeedback>Select Feedback/Suggestion</FormFeedback>
                    
                    </FormGroup>
                   </Col>

                   <Col style={{marginLeft: 10}}>
                   <FormGroup row >
                        <Label><text style={fWeightSpan}>Type </text><span style={{color: 'red'}}>*</span></Label>
                        <Input type="select" value={this.state.type.value}  
                        onChange={this.typeHandler}
                        invalid={this.state.type.invalid ? true : false}  
                        valid={this.state.type.valid ? true : false}
                        style={{fontFamily: 'Muli'}}
                        >
                        <option >---Select---</option>                        
                        {options}
                        </Input>
                        <FormFeedback>Select Type</FormFeedback>

                    </FormGroup>
                    </Col>
                </Row>                   
                    
                    <FormGroup row>
                        <Label for="Text"><text style={fWeightSpan}> Comment</text> <span style={{color: 'red'}}>*</span></Label>
                        <Input  type="textarea"
                                 maxLength={1000}
                                onChange={this.commentFieldHandler}
                                valid={this.state.comment.valid ? true : false}
                                invalid={this.state.comment.invalid ? true : false} 
                                placeholder="Please type your comment"
                                value={this.state.comment.value}
                                style={{height:100,fontFamily: 'Muli'}} />
                        <FormFeedback>Comment shouldn't be empty!</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                    <Button onClick={this.submitButtonHandler} style={{backgroundColor:"#2C2165", color:'white'}} disabled={this.state.fClicked}><text style={{fontFamily:'Muli'}}>Submit</text></Button>
                    
                    </FormGroup>
                <ToastContainer autoClose={2500}/>
                <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/>

               </Form>
            </div>
            </div>
            </Container>

            </div>
        )
    }
} 

export default CustDetails;