import React,{Component} from "react";
import {Container,Row,Col,Card,CardHeader,CardBody,CardText,CardTitle,Button} from "reactstrap";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import "../../../node_modules/react-toastify/dist/ReactToastify.css"; 
import {BarLoader} from "react-spinners";
import TableView from "../SubComponents/iewByConsumerSearchDetails";
import BreadCrumbs from '../Navbar/Breadcrumb';
import { IoMdCheckmark, IoMdClose } from 'react-icons/io';
import { localeData } from "moment";
import moment from 'moment';
import { connect } from 'react-redux';
 
class ConsumerSearchDetails extends Component
{ 
state={ 
loading:true,
consumer_data:[],
user_data:[],
accepted_data:[],
active: this.props.location.state.active,
active1: this.props.location.state.active1,
active2: this.props.location.state.active2,
dispute:false,
consumerAdd:[],
loginDetails:''
}

componentDidMount() 
{
 
  
    axios.post("/support/consumer/ConsumerSearchDetails",{consumer_prid:this.props.location.state.consumer_prid})
    .then(res=>{
    

        if("Consumer Data",res.data.searchDet)
        {
            this.setState({
                consumer_data:res.data.consumerDet,
                user_data:res.data.userDet ? res.data.userDet.length !== 0 ? res.data.userDet : [] : [],
                accepted_data:res.data.accepted_data.length !== 0 ? res.data.accepted_data : [] ,
                consumerAdd:res.data.adressUsers.length!==0?res.data.adressUsers:[],
                loginDetails:res.data.loginDetails.length!==0?res.data.loginDetails:[],
                loading:false
            });
        }
        else
        {
            if(res.data.dbError===true)
                        {
                            toast.error("Internal Server Error",{position:toast.POSITION.BOTTOM_CENTER});

                        }
            this.setState({loading:false})

        }

    }).catch(err=>{
        toast.warn("Unable to Reach Server! Please Try Again!",{position:toast.POSITION.BOTTOM_CENTER});
        this.setState({loading:false})
    })


 
 
}
cancelledHandler=()=>{
    let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;

    let cancelledCount=this.state.accepted_data.filter((canlcelledCount)=>{
        return canlcelledCount.OFFER_CANCELLED===1&&canlcelledCount.OFFER_ACCEPTED===0;
      })
    let cancelFiltered=cancelledCount.map((each,k)=>{
        return k=each 
    }) 
    this.props.history.push({
      pathname: url+'/Cancellations',
      state:{
        accepted_data:cancelFiltered,
        consumer_prid:this.props.location.state.consumer_prid,
        url 
      }

    })
}
disputesHandler=()=>{
    let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;

    let disputeCount=this.state.accepted_data.filter((disputeCount)=>{
        return disputeCount.SERVICE_DISPUTE===1;
      })
    let disputeFiltered=disputeCount.map((each,k)=>{
        return k=each 
    })
    this.setState({
            dispute:true,
            accepted_data:disputeFiltered,

    })
    this.props.history.push({
      pathname: url+'/Disputes',
      state:{
        accepted_data:disputeFiltered,
        url,
        consumer_prid:this.props.location.state.consumer_prid
      }

    })
    
}

bookingsConsumedHandler = () =>{
    let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
    

    let consumedCount=this.state.accepted_data.filter((canlcelledCount)=>{
        return canlcelledCount.OFFER_CONSUMED===1;
      })
    let consumedFiltered=consumedCount.map((each,k)=>{
        return k=each; 
    }) 
       this.props.history.push({
        pathname: url+'/BookingsConsumed',
        state:{
          accepted_data:consumedFiltered,
          consumer_prid:this.props.location.state.consumer_prid,
          url 
        }
  
      })
  
  
  }

  consumerPaymentHanler=()=>{
    let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;

    this.props.history.push({
        pathname: url+'/consumerpayments',
        state:{
            consumer_prid:this.props.location.state.consumer_prid,          
            url 
        }
  
      })
}
  
bookingsViewHandler=()=>{
    let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;

    this.props.history.push({
        pathname: url+'/allBookings',
        state:{
            consumer_prid:this.props.location.state.consumer_prid,
            consumer_name:this.state.consumer_data[0].CONSUMER_FNAME?this.state.consumer_data[0].CONSUMER_FNAME:this.state.consumer_data[0].CONSUMER_EMAIL!=null?this.state.consumer_data[0].CONSUMER_EMAIL:'Not Updated',       
            url 
        }
  
      })

}
earningsViewHandler=()=>{
    let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;

    this.props.history.push({
        pathname: url+'/earnedCoupons',
        state:{
            consumer_prid:this.props.location.state.consumer_prid,
            consumer_name:this.state.consumer_data[0].CONSUMER_FNAME?this.state.consumer_data[0].CONSUMER_FNAME:this.state.consumer_data[0].CONSUMER_EMAIL!=null?this.state.consumer_data[0].CONSUMER_EMAIL:'Not Updated',       
            url 
        }
  
      })

}
render()
{
    const LinkedText = {
        textDecoration:'underline',
        cursor:'pointer', 
        color: '#302939', 
        fontWeight:'bold',
        textTransform: 'uppercase'
    }

    const fontText1 = {
        fontWeight:'bold',
        fontFamily: 'Muli',
        color: '#563951'                        
    }

    const rowStyle={
        textAlign:"initial"
    }
    const fWeightSpan={
            fontWeight:"500",
            marginRight: 5,
            fontFamily: 'Muli',
            color: '#868686'
    }
    console.log('state',this.state)
    let loading = null;

    if(this.state.loading){
        loading = <text style={fontText1}>Loading...</text>
    } else {
        loading = null
    }

    // let offerAcceptMap= this.state.accepted_data.filter((offerCount,i=0)=>{
    //   return offerCount.OFFER_ACCEPTED===1;
    // })
    let offerAcceptFilter=this.state.accepted_data.map((each,k)=>{
     return k=each
    }
    )
    let offerAcceptedfiltered=offerAcceptFilter.length

    let offerCancelMap= this.state.accepted_data.filter((offerCancelCount,i=0)=>{
        return offerCancelCount.OFFER_CANCELLED===1;
      })
      let offerCancelFilter=offerCancelMap.map((each,k)=>{
       return k=each  
      })
      let offerCancelfiltered=offerCancelFilter.length;
  
      let offerConsumeMap= this.state.accepted_data.filter((offerConsumeCount,i=0)=>{
        return offerConsumeCount.OFFER_CONSUMED===1;
      })
      let offerConsumeFilter=offerConsumeMap.map((each,k)=>{
       return k=each
      })
      let offerConsumefiltered=offerConsumeFilter.length;  

      let disputeCount=this.state.accepted_data.filter((disputeCount)=>{
        return disputeCount.SERVICE_DISPUTE===1;
      })
      let disputeFiltered=disputeCount.map((each,k)=>{
        return k=each
      })
    let finalDispCount=disputeFiltered.length;
  
    
    
    let cd=this.state.consumer_data,ud=this.state.user_data,ad=this.state.accepted_data;

    let adView=null;
    if(ad.length!==0&&this.state.dispute===false)
    {
        adView=(<TableView {...this.props} active={this.state.active} active1={this.state.active1} active2={this.state.active2} accepted_data={ad} />);
    }
    else if(ad.length!==0&&this.state.dispute===true)
    {
        adView=(<TableView {...this.props} active={this.state.active} active1={this.state.active1} active2={this.state.active2} accepted_data={ad} />);
    }

    else {
        if(!this.state.loading){
        adView=<Col><text style={fontText1}>Consumer has not accepted any offers</text></Col>
    }
}
    
    

   
    
    return (
        <div>
            {/* <BreadCrumbs consumer='Consumer Details' consumerLink='/home/customerDetails/ConsumerSearchDetails' breadCrumb={true}/> */}
        
        <Container fluid style={{paddingLeft:"50px",paddingRight:"50px",backgroundColor:"rgb(239,239,239)"}}>
            <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/>
            {loading}
            {
                (cd.length!==0)
                ?
                
                (
                <Card style={{border:"1px solid #a78bba"}}>
                <CardHeader style={{fontWeight:"",fontSize:"18px",color:"#fff",backgroundColor:"#2C2165", fontFamily:'Muli'}}>
                <Row>
                    <Col sm={{size:4, offset:4}}>Customer Details </Col> 
                    <Col sm={{size:'auto', offset:2}} > {ud.length === 0 ? <text style={{color:"#563951", padding:10,outline: '5px solid #e7eaf6',backgroundColor:"#FFD0D0"}}>Guest User</text> : ud[0].ACCOUNT_VERIFIED_FLAG !== 1 ? <text style={{color:"#563951", padding:10,outline: '5px solid #e7eaf6',backgroundColor:"#FFD0D0"}}> Guest User </text> : <text style={{color:"#563951", padding:10,outline: '5px solid #e7eaf6',backgroundColor:"#FFD0D0"}}>Registered User</text> } </Col>
                </Row>
                </CardHeader>
                <CardText style={{padding:"25px"}}>
                    <Row className="mb-4" style={rowStyle}>
                        <Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}>Name:</span>  {cd[0].CONSUMER_FNAME!==null?cd[0].CONSUMER_FNAME:"Not updated"}</text>
                        </Col>
   
                        <Col size={4}>
                            <text style={fontText1}><span style={fWeightSpan}>Email:</span> {cd[0].CONSUMER_EMAIL!==null?cd[0].CONSUMER_EMAIL:"Not updated"}</text> 

                            {cd[0].CONSUMER_EMAIL !==null ?
                                ud.length!==0?
                                    ud[0].ACCOUNT_VERIFIED_FLAG===1 ?
                                        <span style={{backgroundColor:"#3DC5B6",padding:"5px",color:"#fff",borderRadius:"3px"}}>Verified</span>
                                        :
                                        <span style={{backgroundColor:"#F86C69",padding:"5px",color:"#fff",borderRadius:"3px"}}>Not-Verified</span>
                                : <span style={{backgroundColor:"#F86C69",padding:"5px",color:"#fff",borderRadius:"3px"}}>Not-Verified</span>
                            :null}
                               
                             
                             
                        </Col>
                        <Col size={3}>
                                <text style={fontText1}><span style={fWeightSpan}>Mobile Number:</span>
                                 {/* { cd[0].CONSUMER_PHONE!==null?'+1':null} {cd[0].CONSUMER_PHONE!==null ? cd[0].CONSUMER_PHONE : "Not updated"}</text>  */}
                                 {cd[0].CONSUMER_PHONE!==null?(cd[0].CONSUMER_PHONE_COUNTRY_CODE ? cd[0].CONSUMER_PHONE_COUNTRY_CODE+' ' : ''): null}{cd[0].CONSUMER_PHONE!==null ?  <text>{cd[0].CONSUMER_PHONE.toString().substring(0,3)}-{cd[0].CONSUMER_PHONE.toString().substring(3,6)}-{cd[0].CONSUMER_PHONE.toString().substring(6,10)}</text> : "Not updated"}</text>                         

                                {cd[0].CONSUMER_PHONE !==null ?
                                ud.length!==0?
                                    ud[0].MOBILE_VERIFIED_FLAG===1 ?
                                        <span style={{backgroundColor:"#3DC5B6",padding:"5px",color:"#fff",borderRadius:"3px"}}>Verified</span>
                                        :
                                        <span style={{backgroundColor:"#F86C69",padding:"5px",color:"#fff",borderRadius:"3px"}}>Not-Verified</span>
                                : <span style={{backgroundColor:"#F86C69",padding:"5px",color:"#fff",borderRadius:"3px"}}>Not-Verified</span>
                            :null}
                               
                               
                        </Col>                        
                    </Row>                
                    <Row className="mb-4" style={rowStyle}>
                        

                        <Col size={4}>
                            <text style={fontText1}><span style={fWeightSpan}>Bookings:</span> {offerAcceptedfiltered===null||0?0:offerAcceptedfiltered}</text>
                        </Col>

                        <Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}>Bookings Consumed:</span> {offerConsumefiltered===null||0?0:offerConsumefiltered>0?<text style={LinkedText} onClick={()=>this.bookingsConsumedHandler()}> {offerConsumefiltered}</text>:offerConsumefiltered}</text>
                        </Col>

                        <Col size={3}>
                        <text style={fontText1}><span style={fWeightSpan}>Bookings Cancelled:</span> 
                            {offerCancelfiltered===null||0?0:offerCancelfiltered>0?<text style={LinkedText} onClick={()=>this.cancelledHandler()}>{offerCancelfiltered}</text>:offerCancelfiltered}
                          </text>
                            {/* <text ><span style={fWeightSpan}>Bookings Cancelled:</span> {offerCancelfiltered===null||0?0:offerCancelfiltered}</text>  */}
                        </Col>                        
                    </Row>   
                    <Row className="mb-4" style={rowStyle}>
                        <Col sm={{size:4}}>
                            <text style={fontText1}><span style={fWeightSpan}>Bookings Disputed:</span>
                             
                            {finalDispCount===null||0?0: finalDispCount>0? <span style={LinkedText} onClick={()=>this.disputesHandler()}>{finalDispCount} </span>: <span> {finalDispCount} </span>}
                            </text>
                        </Col>
                        {/* <Col sm={{size:4}}>     
                        <Button size="md" style={{backgroundColor:"#612147",marginRight:5,fontFamily: 'Muli',}} onClick={()=>this.bookingsViewHandler()}>View All Bookings</Button>
                       </Col> */}
                        <Col sm={{size:4}}>     
                            <Button size="md" style={{backgroundColor:"#612147",marginRight:5,fontFamily: 'Muli',}} onClick={()=>this.earningsViewHandler()}>Earned Coupons</Button>
                       </Col>
                       {/* <Col sm={{size:3,offset:5}} */}
                        <Col sm={{size:4}}>     
                        <Button size="md" style={{backgroundColor:"#612147",marginRight:5,fontFamily: 'Muli',}} onClick={()=>this.consumerPaymentHanler()}>Payment&Transactions {'-->'}</Button>
                        
                       </Col>


                          <Col className="col-4" style={{paddingTop:20}}>
                                <text style={fontText1}><span style={fWeightSpan}> Customer Address:</span>{ this.state.consumerAdd[0].length!==0 ? this.state.consumerAdd[0][0].CONSUMER_ADDRESS ? this.state.consumerAdd[0][0].CONSUMER_ADDRESS : '------' : '------' }</text>
                                {/* <text style={fontText1}><span style={fWeightSpan}> Category:</span>{ this.state.cats.length != 0 ? this.state.cats[0].category_name : <text style={fontText}>---</text> } </text> */}
    
                            </Col> 
                            <Col className="col-4" style={{paddingTop:20}}>
                            <text style={fontText1}><span style={fWeightSpan}>Promotional Consent:</span>{cd[0].CONSUMER_PROMO_CONSENT_FLAG==1?"Yes":"No"}</text>
                        </Col>
                            <Col style={{paddingTop:20}}>
                                <text style={fontText1}><span style={fWeightSpan}>Last Login Time:</span>{
                                this.state.loginDetails.length !==0 ? 
                                (this.state.loginDetails[0].USER_TOKEN_UPDATED_ON_DATE ? 
                                    moment(this.state.loginDetails[0].USER_TOKEN_UPDATED_ON_DATE+"Z").tz(this.state.consumerAdd[0].length !==0 ? (this.state.consumerAdd[0][0].CONSUMER_ADDRESS.includes('USA') ? 'America/Los_Angeles' : 'Asia/Kolkata') : (this.props.country === 'USA'?'America/Los_Angeles' : 'Asia/Kolkata')).format("DD-MMM-YYYY hh:mm A z") 
                                    : '-----') 
                                : '------'}
                                </text>
                            </Col> 
                        
                        </Row>              
                </CardText>            
            </Card>
            
                )
            
            :
            null
            
        }
        <Row className="mt-5">{adView}</Row>
            

        </Container>
        </div>
    )

}

}
const mapStateToProps=state=>{
    return {
        country: state.country,
        token:state.token,
        id:state.id,
        view:state.view
    } 
  }
export default connect(mapStateToProps)(ConsumerSearchDetails)