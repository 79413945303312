import React, {Component} from 'react';
import {connect} from "react-redux";
import axios from 'axios';
import {ToastContainer, toast } from 'react-toastify';
import { ButtonGroup, Button, Card, CardBody,CardTitle, CardText, Form,FormText, Row, Col, Container, Modal,ModalBody,ModalHeader,ModalFooter, Tooltip} from 'reactstrap';
import {BarLoader} from 'react-spinners';
import momenttz from 'moment-timezone'
import moment from 'moment';
import Switch from 'react-switch';
import Curr from "currency.js";
import time from '../../images/time.png';

class MerchantCreatedServices extends Component{
    state={
        servicesCreated:[],
        loading:false
    }
    componentDidMount(){
        console.log('props data',this.props)
        let body={
            id:this.props.location.state.id
        }
        axios.post("/support/merchant/allservices/display",body)
        .then((res)=>{
            if(res.data.data){
                if(res.data.services.length!==0){
                    this.setState({
                        ...this.state,
                        servicesCreated:res.data.services,
                        loading:false
                    })
                }
                else if(res.data.services.length === 0){ 
                    this.setState({
                        ...this.state,
                        servicesCreated: [],
                        loading: false
                    });
                            
                }
            }
            else {
                if(res.data.dbError){
                    toast.error("Internal Server Error!",{
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                } else { 
                    this.setState({
                    ...this.state,
                    servicesCreated: [],
                    loading: false
                    });
                    toast.info("No Services to display",{
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    
                }
            }
        })
        .catch((error)=>{
            toast.error("Server Error",{
                position: toast.POSITION.BOTTOM_CENTER
            });
                this.setState({
                    ...this.state,
                    loading: false
                });
        })
    }
    serviceDetailsHandler = (id) => {
        console.log('id',id);
           let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
   
           this.props.history.push({
               pathname:url+"/ServiceDetails",
               state:{
                 id,
                 name:this.props.location.state.name,
                 url
               }
           })
       }
    render(){
        const rowStyle={
            textAlign:"initial",
        }
        const fWeightSpan={
            fontWeight:"500",
            padding: 5,
            cursor: 'pointer',
            fontFamily: 'Muli',
            // marginLeft: 5,
            color: '#868686'
            // border:'1px solid black'
        }

        const fWeightSpan1={
            fontWeight:"500",
            fontSize:16,
            padding: 5,
            marginBottom:10,
            cursor: 'pointer',
            fontFamily: 'Muli',
            color: '#563951'
        }
        
        const textDecor = {
            marginLeft: 5,
            fontFamily: 'Muli',
            color: '#563951'
        }
        const textDecor1 = {
            marginLeft: 5,
            fontFamily: 'Muli',
            color: '#3DC5B6'
        }
        let service="No Services to display";
        if(this.state.servicesCreated.length !== 0){ 
            service = this.state.servicesCreated.map((item) => {
                return (
                    <Card style={{marginTop: 15, border: '2px solid #a78bba',backgroundColor:'white',width:'100%'}} >
                        <Row>                                    
                            <Col sm={{size:12}} style={{cursor:'pointer'}}>
                                <img src={item.image_link} width='100%' height={300} style={{borderRadius:'5px'}} onClick={()=>this.serviceDetailsHandler(item.QF_MERCHANT_SERVICE_PRID)}/>
                            </Col>
                        </Row>
                        <CardBody>   
                        <CardTitle style={{fontSize:18,marginTop:-15}}></CardTitle>                                                    
                            <CardText>
                                <Row style={rowStyle}>
                                    <Col sm={{size:5}} style={fWeightSpan1}><text>{item.SERVICE_NAME}</text></Col>
                                    <Col sm={{size:5}} style={fWeightSpan1}><img src={time} width={15} height={15}/><text style={textDecor}><span>
                                    {moment.utc(moment.duration(item.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("H") == 0 ? null : moment.utc(moment.duration(item.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("H")+ "h"}{' '}{moment.utc(moment.duration(item.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("mm") == 0 ? null : moment.utc(moment.duration(item.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("mm") + "m"}
                                        </span></text></Col>
                                    <Col sm={{size:2}} style={fWeightSpan}>
                                        <span style={textDecor1}>{item.SERVICE_CURRENCY === "Rupees" ? <span>{'\u20B9'}</span> : <span>$</span>}{Curr(item.SERVICE_OFFER_PRICE).value.toFixed(2)}</span>
                                    </Col>
                                    {/* <span style={{marginRight:5}}><text style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}>{item.SERVICE_CURRENCY === "Rupees" ? <span>{'\u20B9'}</span> : <span>$</span>}{Curr(item.SERVICE_LIST_PRICE).value.toFixed(2)}</text></span>  */}
                                </Row>
                                {/* <Row style={rowStyle}>
                                    <Col sm={{size:5}} style={fWeightSpan}><img src={time} width={15} height={15}/> <text style={textDecor}>{ticket.MERCHANT_OFFER_FROM_DATE_TIME ? momenttz(ticket.MERCHANT_OFFER_FROM_DATE_TIME+"Z").tz(ticket.MERCHANT_OFFER_TIMEZONE).format("hh:mm A") : <text>---</text>} </text><text>-</text><text style={textDecor}>{ticket.MERCHANT_OFFER_TO_DATE_TIME ? momenttz(ticket.MERCHANT_OFFER_TO_DATE_TIME+"Z").tz(ticket.MERCHANT_OFFER_TIMEZONE).format("hh:mm A z") : <text>---</text> } </text> </Col>
                                </Row> */}
                            </CardText>
                        </CardBody>
                    </Card>
                    
                );
            })
        } else {
            if(this.state.loading===true){
                service= "Loading...";
            }else{ 
                if(this.state.servicesCreated.length===0&&this.state.loading===false)
                service= "No Services to Display";  
        }  
        }
        return(
            <div>
            <div className="row justify-content-center">
            <div className="col-6">
            <Form>
                <FormText style={{fontSize:24,marginBottom:20}}><text style={{color:'#563951', fontFamily:'Muli'}}>Services Created By <strong>{this.props.location.state.name}</strong> </text></FormText>
            </Form>
            </div>
            </div>
            <Container fluid style={{width: 600}}> 
            <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#50E3C2"/>
                {service}  
            <ToastContainer autoClose={2500}/> 
            </Container>
            </div>
        )
    }
}
const mapStateToProps=state=>{
    return {
      id:state.id 
  }
  }
export default connect(mapStateToProps)(MerchantCreatedServices);

// import Switch from 'react-switch';

// import React, { Component } from 'react'; 
// import {connect} from "react-redux";
// import axios from 'axios';
// import {ToastContainer, toast } from 'react-toastify';
// import { ButtonGroup, Button, Card, CardBody,CardTitle, CardText, Form,FormText, Row, Col, Container, Modal,ModalBody,ModalHeader,ModalFooter, Tooltip} from 'reactstrap';
// import {BarLoader} from 'react-spinners';
// import momenttz from 'moment-timezone'
// import moment from 'moment';
// import { FaPlus, FaTrashAlt, FaRegEdit } from 'react-icons/fa';

// // import Breadcrumb from '../Breadcrumb';




// class MerchantCreatedOffers extends Component {
   
//     state={
//         Services: [],
//         toggle:[],
//         loading:false,
//         tooltipOpen: []
//     }

//     componentDidMount(){
      
//         this.setState({loading:true})
           
//         let uid=this.props.location.state.id
//         let toggle={};

//             axios.post("/support/mcategories/sub/services/view",{uid})
//            .then((res) => {
//             if(res.data.data){

//                 res.data.results.map((service) => {
//                     if(service.merchant_service_deleted_flag === 1){
//                         return toggle['service_'+service.qf_merchant_service_prid] = false
//                     } else {
//                         return toggle['service_'+service.qf_merchant_service_prid] = true
//                     }
//                 })

//                 if(res.data.results.length !== 0){
//                     this.setState({
//                         ...this.state,
//                         Services: res.data.results,
//                         toggle: toggle, 
//                         loading: false
//                     });
//                 } else if(res.data.results.length === 0){ 
//                     this.setState({
//                         ...this.state,
//                         Services: [],
//                         loading: false
//                     });
        
//                 }
//             }
//             else {
//                 if(res.data.dbError){
//                     toast.error("Internal Server Error!",{
//                         position: toast.POSITION.BOTTOM_CENTER
//                     });
//                     this.setState({
//                         ...this.state,
//                         loading: false
//                     });
//                 } else { 
//                     this.setState({
//                         ...this.state,
//                         Services: [],
//                         loading: false
//                     });
//                     // toast.info("No Services to display",{
//                     //     position: toast.POSITION.BOTTOM_CENTER
//                     // });

//                 }
                  
            
//             }
           
//         })
//         .catch((err) => {
//             toast.warn("Unable to Reach Server! Please Try Again!" , {
//                 position: toast.POSITION.BOTTOM_CENTER
//               });
//             this.setState({
//                 ...this.state,
//                 loading: false
//             });
//         })
// }

// serviceDeleteToggleHandler = (id) => {
//     // console.log("id", id);

//     let toggle={};
//     if(this.state.toggle['service_'+id] === true){
//         toggle = {
//             ...this.state.toggle,
//             ['service_'+id] : false
//         }

//         this.setState({
//             ...this.state,
//             toggle : toggle
//         })

//         axios.post('/support/merchant/service/deleteToggle', {id})
//         .then(res => {
//             // console.log("response", res);
//             if(res.data.data){
//                 if(res.data.result.affectedRows){
//                     toast.success("Service Deactivated",{
//                         position : toast.POSITION.BOTTOM_CENTER
//                     });
//                 } else {
//                     toast.warn("Not Updated",{
//                         position : toast.POSITION.BOTTOM_CENTER
//                     });
//                 }
//             } else {
//                 if(res.data.dbError){
//                     toast.error("Internal Server Error",{
//                         position : toast.POSITION.BOTTOM_CENTER
//                     });
//                 } else {
//                     toast.warn("Not Updated",{
//                         position : toast.POSITION.BOTTOM_CENTER
//                     });
//                 }
//             }
//         })
//         .catch(err => {
//             console.log(err);
//             toast.warn("Unable to Reach Server! Please Try Again!" , {
//                 position: toast.POSITION.BOTTOM_CENTER
//               });
//         })
//     } else if(this.state.toggle['service_'+id] === false){
//         toggle = {
//             ...this.state.toggle,
//             ['service_'+id] : true
//         }

//         this.setState({
//             ...this.state,
//             toggle : toggle
//         })

//         axios.post('/support/merchant/service/activeToggle', {id})
//         .then(res => {
//             // console.log("response", res);
//             if(res.data.data){
//                 if(res.data.result.affectedRows){
//                     toast.success("Service Activated",{
//                         position : toast.POSITION.BOTTOM_CENTER
//                     });
//                 } else {
//                     toast.warn("Not Updated",{
//                         position : toast.POSITION.BOTTOM_CENTER
//                     });
//                 }
//             } else {
//                 if(res.data.dbError){
//                     toast.error("Internal Server Error",{
//                         position : toast.POSITION.BOTTOM_CENTER
//                     });
//                 } else {
//                     toast.warn("Not Updated",{
//                         position : toast.POSITION.BOTTOM_CENTER
//                     });
//                 }
//             }
//         })
//         .catch(err => {
//             console.log(err);
//             toast.warn("Unable to Reach Server! Please Try Again!" , {
//                 position: toast.POSITION.BOTTOM_CENTER
//               });
//         })
//     }
// }

//     updateButtonHandler = (service) => {
//         this.props.history.push({
//             pathname: this.props.match.url+'/MerchantServiceUpdate',
//             state : {
//                 service,
//                 name: this.props.location.state.name,
//                 id: this.props.location.state.id
//             }
//         })
//     }

//     toggle = (id) =>{
//         // console.log("Prid", id)

//         let tooltip={};

//         if(this.state.tooltipOpen['update_'+id]){
//             tooltip={
//              ...this.state.tooltipOpen,
//              ['update_'+id]:false
//             }
//             this.setState({
//                 ...this.state,
//                 tooltipOpen: tooltip,
//             });
//         } else {            
//             tooltip={
//                 ...this.state.tooltipOpen,
//                 ['update_'+id]:true
//                }
//                this.setState({
//                    ...this.state,
//                    tooltipOpen: tooltip,
//                });    
//         }
//     }

//     render() {

//         // console.log("State in Services", this.state)
//         const rowStyle={
//             textAlign:"initial",
//         }
//         const fWeightSpan={
//             fontWeight:"500",
//             padding: 5,
//             // cursor: 'pointer',
//             // border:'1px solid black'
//         }

//         const fWeightSpan1={
//             fontWeight:"500",
//             fontSize:21,
//             padding: 5,
//             marginBottom:10,
//             // cursor: 'pointer',
//             // border:'1px solid black'
//         }
        
//         const textDecor = {
//             marginLeft: 5,
//             color: 'black'
//         }

//         let service = "No Services Created";
//         let delId=null;

//         if(this.state.Services.length !== 0){
//             // console.log('inside')
//             service = this.state.Services.map((service) => {
//                 delId = service.qf_merchant_service_prid;
//                 // console.log("In render current", delId);
//                 return (
//                     <Card style={{marginTop: 15, border: '2px solid #028090',backgroundColor:'white',width:'100%'}} >
//                         <CardBody>
//                             <CardTitle style={{fontSize:18,marginTop:-15}}></CardTitle>                                                    
//                             <CardText style={{marginLeft:10}}>
//                                 <Row style={rowStyle}>
//                                     <Col sm={{size:3}} style={fWeightSpan}><text>Service Name:</text> </Col>
//                                     <Col sm={{size:7}} style={fWeightSpan}><text style={textDecor}>{service.merchant_service_name} </text></Col>
//                                     <Col sm={{size:2}} style={fWeightSpan}> <Switch height={20} onChange={() => this.serviceDeleteToggleHandler(service.qf_merchant_service_prid)}  checked={this.state.toggle['service_'+service.qf_merchant_service_prid]}/> </Col>
//                                 </Row>
//                                 <Row style={rowStyle}>
//                                     <Col sm={{size:3}} style={fWeightSpan}><text>Description:</text> </Col>
//                                     <Col sm={{size:7}} style={fWeightSpan}><text style={textDecor}>{service.merchant_service_description} </text></Col>
//                                     {/* <Col style={fWeightSpan} sm={{size:1}}>
//                                         <span href="#" id={'Update_'+service.qf_merchant_service_prid}><FaRegEdit style={{cursor: 'pointer'}} color='#df0e62' size={26} onClick={() => this.updateButtonHandler(service)}/></span>
//                                         <Tooltip placement="right" isOpen={this.state.tooltipOpen['update_'+service.qf_merchant_service_prid]} target={'Update_'+service.qf_merchant_service_prid} toggle={() => this.toggle(service.qf_merchant_service_prid)}>
//                                             Update Service
//                                         </Tooltip>
//                                     </Col> */}
//                                 </Row>
//                                 <Row style={rowStyle}>
//                                     <Col sm={{size:3}} style={fWeightSpan}><text>Regular Price: </text> </Col>
//                                     <Col sm={{size:4}} style={fWeightSpan}><text style={textDecor}><span>{service.MERCHANT_LOCATION_ADDRESS_COUNTRY === "India" ? <span> {'\u20B9'} </span> : service.MERCHANT_LOCATION_ADDRESS_COUNTRY === "United States" ? <span> $ </span> : <span>{' '}</span>}</span>{service.merchant_service_location_list_price}</text> </Col>
//                                     <Col sm={{size:'auto'}} style={fWeightSpan}><text>Duration:</text> </Col>
//                                     <Col sm={{size:3}} style={fWeightSpan}><text style={textDecor}>{service.merchant_service_duration_minutes} minutes </text></Col>
//                                 </Row>
//                                 <Row style={rowStyle}>
//                                 </Row>  
//                             </CardText>
//                         </CardBody>
//                     </Card>
//                 );
//             })
//         } else {
//             if(this.state.loading===true){
//                 service= "Loading...";
//             }else{
//                 if(this.state.Services.length!=0)
//                 service = "No Services Created";  
//             }                       
//         }

//         return (
//             <div>
           
//             <div className="row justify-content-center" style={{marginTop: 30, marginBottom: 20}}>
//             <div className="col-4" >
//             <div>
//             <Form>
//                 <FormText style={{fontSize:24,marginBottom:20}}>  Services Created by {this.props.location.state.name} </FormText>
//             </Form>
                
//             </div>
//             </div>
//             </div> 
//             <Container fluid style={{width: 600}}> 
//             <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/>

//                 {service}  
              
//             </Container>                
                
//             <ToastContainer autoClose={2500}/>                 
//             </div>
                     

//         )
//     }
// } 
// const mapStateToProps=state=>{
//     return {
//       id:state.id 
//   }
//   }  


//   export default connect(mapStateToProps)(MerchantCreatedOffers 
//     );

  