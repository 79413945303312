import React, { Component } from 'react';
import { Form, FormGroup, FormFeedback, Label, Input, Button, Container, Row, Col } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import { BarLoader } from "react-spinners";
import {connect} from "react-redux";
import TableRecordsView from '../SubComponents/ViewByMerchantName';
import Select from 'react-select';


class MerchantDetails extends Component {

    state = {
        merchantName : {
            value: null,
            valid : false,
            invalid: false
        },
        city: {
            value: null,
            valid: false,
            invalid: false
        },
        submit : {
            invalid: false,
            valid: false
        },
        merchantDetails: [],
        email : {
            value : null,
            valid : false,
            invalid : false,
            emailQ: false
        },
        phone : {
            value : '',
            valid : false,
            invalid : false,
            phoneQ: false
        },
        payid:{
            value:'',
            valid:false,
            invalid:false
        },
        cities: [],
        transaction:[],
        show: false,
        noData: false,
        loading: false,
        country:null,
        tz:null,
        noTrans:false,
        merchantOptions:[]
    }

    // merchantNameHandler = (event) => {
    //     if(event.target.value.length === 0){
    //         this.setState({
    //             merchantName:{
    //                 ...this.state.merchantName,
    //                 value: null,
    //                 valid: false,
    //                 invalid: true
    //             }
    //         })
    //     } else {
    //         this.setState({
    //             merchantName : {
    //                 ...this.state.merchantName,
    //                 value: event.target.value,
    //                 valid: true,
    //                 invalid: false
    //             },
    //             submit: {
    //                 ...this.state.submit,
    //                 invalid: false
    //             },
    //             email:{
    //                 ...this.state.email,
    //                 value:'',
    //                 invalid: false,
    //                 emailQ: false
    //             },
    //             phone:{
    //                 ...this.state.phone,
    //                 value: '',
    //                 invalid: false,
    //                 phoneQ: false
    //             },
    //             payid:{
    //                 value:'',
    //                 valid:false,
    //                 invalid:false,
    //                 payidQ:false
    //             },
    //             noData: false
    //         });
    //     }        
    // } 

    // merchantCityHandler = (event) => {
    //     if(event.target.value === '0'){
    //         this.setState({
    //             city:{
    //                 ...this.state.city,
    //                 value: null,
    //                 valid: false,
    //                 invalid: true
    //             }
    //         });
    //     } else {
    //         this.setState({
    //             city : {
    //                 ...this.state.city,
    //                 value: event.target.value,
    //                 valid: true,
    //                 invalid: false
    //             },
    //             submit: {
    //                 ...this.state.submit,
    //                 invalid: false
    //             },
    //             email:{
    //                 ...this.state.email,
    //                 invalid: false
    //             },
    //             noData: false
    //         });
    //     }        
    // } 

    emailTextHandler = (event) => {

        if(event.target.value.length!==0){
            this.setState({
                email : {
                    ...this.state.email,
                    value : event.target.value,
                    valid: true,
                    invalid: false,
                    emailQ: true
                },
                submit: {
                    ...this.state.submit,
                    invalid: false
                },
                merchantName:{
                    ...this.state.merchantName,
                    value: '',
                    invalid: false
                },
                phone:{
                    ...this.state.phone,
                    value:'',
                    invalid: false,
                    phoneQ: false
                },
                city:{
                    ...this.state.city,
                    value: 0,
                    invalid: false
                },
                payid:{
                    value:'',
                    valid:false,
                    invalid:false,
                    payidQ:false
                },
                noData:false
            });            
        } else {
            this.setState({
                email: {
                    ...this.state.email,
                    value: null,
                    valid: false,
                    invalid: true
                },
                submit: {
                    ...this.state.submit,
                    invalid: true
                }
            });            
        }        
    }

    phoneHandler = (event) => {

        let k =/^[0-9\b]+$/;

        if(event.target.value==='' || k.test(event.target.value)){
            this.setState({
                phone : {
                    ...this.state.phone,
                    value : event.target.value,
                    valid: true,
                    invalid: false,
                    phoneQ: true
                },
                submit: {
                    ...this.state.submit,
                    invalid: false
                },
                email:{
                    ...this.state.email,
                    value: '',
                    valid:false,
                    invalid:false,
                    emailQ: false
                },
                merchantName:{
                    ...this.state.merchantName,
                    value:'',
                    invalid: false
                },
                city:{
                    ...this.state.city,
                    value: 0,
                    invalid: false
                },
                payid:{
                    value:'',
                    valid:false,
                    invalid:false,
                    payidQ:false
                },
                noData: false
            });
        }  
    }


    payidHandler = (event) =>{
        let k =/^[0-9\b]+$/;

        if(event.target.value==='' || k.test(event.target.value)){
            this.setState({
                payid : {
                    ...this.state.payid,
                    value : event.target.value,
                    valid: true,
                    invalid: false,
                    payidQ: true
                },
                submit: {
                    ...this.state.submit,
                    invalid: false
                },
                email:{
                    ...this.state.email,
                    value: '',
                    valid:false,
                    invalid:false,
                    emailQ: false
                },
                phone : {
                    ...this.state.phone,
                    value : '',
                    valid: false,
                    invalid: false,
                    phoneQ: false
                },
                merchantName:{
                    ...this.state.merchantName,
                    value:'',
                    invalid: false
                },
                city:{
                    ...this.state.city,
                    value: 0,
                    invalid: false
                },
                noData: false,
                noTrans:false
            });
        }


    }

    submitButtonHandler = () => {

        
        if(this.state.email.emailQ){

            let k=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if(!k.test(this.state.email.value))
            {
                this.setState({
                    submit: {
                        ...this.state.submit,
                        invalid: true 
                    },
                    email:{
                        ...this.state.email,
                        valid: false,
                        invalid: true
                    }
                });
                return false;
            } else if(this.state.email.valid || this.state.email.value !== null){
                this.setState({
                    ...this.state,
                    loading: true
                });
    
                let body = {
                    email: this.state.email.value
                }
                axios.post('/support/merchant/searchByEmail', body)
                .then((res) => {
                    // console.log(res);
                    if(res.data.data){
                        // console.log("Merchant Name", res.data.result)
                        this.setState({
                            ...this.state,
                            loading: false,
                            merchantDetails: res.data.result,
    
                            submit: {
                                ...this.state.submit,
                                invalid: false,
                                valid: true
                            },
                            merchantName:{
                                ...this.state.merchantName,
                                valid: true,
                                invalid: false
                            }                  
                        });
    
                        if(res.data.result.length === 1){
                            this.props.history.push({
                                pathname:"/home/merchantDetails/MerchantSearchDetails",
                                state:{ 
                                id: this.state.merchantDetails[0].QF_MERCHANT_PRID,
                                merLink: '/home/merchantDetails',
                                mer: 'Merchant Details'
                                }
                            });
                            this.setState({
                                ...this.state,
                                show: false,
                            })
                        } else {
                            this.setState({
                                ...this.state,
                                show: true
                            })
                        }
                    } else {
                        if(res.data.dbError){
                            this.setState({
                                ...this.state,
                                loading: false,
                                merchantDetails: [],  
                                noData: true               
                            });
                            toast.error("Internal Server Error", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                        } else {
                            this.setState({
                                ...this.state,
                                loading: false,
                                merchantDetails: [], 
                                noData: true               
                            });
                            
                        }
                    } 
                })
                .catch((err) => {
                    console.log(err);
                    toast.warn("Unable to Reach Server! Please Try Again!" , {
                        position: toast.POSITION.BOTTOM_CENTER
                      });
                    this.setState({
                        ...this.state,
                        loading: false,
                        noData: true
                    })
                })
            }

        } else if(this.state.phone.phoneQ){

            if(this.state.phone.invalid || !(/^[0-9\b]+$/.test(this.state.phone.value)) || this.state.phone.value.length !== 10){

                this.setState({
                    submit: {
                        ...this.state.submit,
                        invalid: true 
                    },
                    phone: {
                        ...this.state.phone,
                        valid: false,
                        invalid: true
                    }
                });

        } else {
                this.setState({
                    submit: {
                        ...this.state.submit,
                        invalid: false,
                    },
                    phone: {
                        ...this.state.phone,
                        valid: true,
                        invalid: false
                    },
                    loading:true,
                    submitButton:true
                });

            
                let mobile =this.state.phone.value; 
            
            axios.post("/support/merchant/phoneSearch", {mobile})
            .then((res) => {
                // console.log(res);
                if(res.data.data){
                    // console.log("Merchant Name", res.data.result)
                    this.setState({
                        ...this.state,
                        loading: false,
                        merchantDetails: res.data.result,

                        submit: {
                            ...this.state.submit,
                            invalid: false,
                            valid: true
                        },
                        merchantName:{
                            ...this.state.merchantName,
                            valid: true,
                            invalid: false
                        }                  
                    });

                    if(res.data.result.length === 1){
                        this.props.history.push({
                            pathname:"/home/merchantDetails/MerchantSearchDetails",
                            state:{ 
                            id: this.state.merchantDetails[0].QF_MERCHANT_PRID,
                            merLink: '/home/merchantDetails',
                            mer: 'Merchant Details'
                            }
                        });
                        this.setState({
                            ...this.state,
                            show: false,
                        })
                    } else {
                        this.setState({
                            ...this.state,
                            show: true
                        })
                    }
                } else {
                    if(res.data.dbError){
                        this.setState({
                            ...this.state,
                            loading: false,
                            merchantDetails: [],  
                            noData: true               
                        });
                        toast.error("Internal Server Error", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    } else {
                        this.setState({
                            ...this.state,
                            loading: false,
                            merchantDetails: [], 
                            noData: true               
                        });
                        
                    }
                } 
            })
            .catch(err=>{
                toast.warn("Unable to Reach Server! Please Try Again!",{position:toast.POSITION.BOTTOM_CENTER});
                this.setState({loading:false})
            });
        }

        }else if(this.state.payid.payidQ){

            if(this.state.payid.invalid || !(/^[0-9\b]+$/.test(this.state.payid.value))){

                this.setState({
                    submit: {
                        ...this.state.submit,
                        invalid: true 
                    },
                    payid: {
                        ...this.state.payid,
                        valid: false,
                        invalid: true
                    }
                });

        } else {
                this.setState({
                    submit: {
                        ...this.state.submit,
                        invalid: false,
                    },
                    payid: {
                        ...this.state.payid,
                        valid: true,
                        invalid: false
                    },
                    loading:true,
                    submitButton:true
                });
            //   console.log('id',this.state.payid.value)
                let payId = this.state.payid.value;
                axios.post("/support/payments/paymentById", {payId})
                .then((res) => {
                    if(res.data.data){
                    //    console.log('haiiaia',res.data.details)
                      if(res.data.details.length!==0)  {
                            this.setState({
                            ...this.state,
                            transaction: res.data.details,
                            tz:res.data.details2[0].MERCHANT_PERSONNEL_TIMEZONE,
                            country:res.data.details2[0].MERCHANT_PERSONNEL_ADDRESS_COUNTRY,
                            loading: false,
                            noTrans:false
        
                        })
                        //  return console.log('lldldl',this.state.transaction,this.state.tz,this.state.country)

                          this.props.history.push({
                           pathname: '/home/merchantDetails/offerView/bookingView',
                           state:{
                               tz : this.state.tz,
                               country:this.state.country,
                               selectedTrans:this.state.transaction,
                               offerViewLink: '/home/merchantDetails',
                               offerView: 'Merchant Details',
                       }
                  })

                    }
                      if(res.data.details.length===0)  {
                    //    console.log('haiiaia',res.data.details)


                        this.setState({
                            ...this.state,
                            transaction: [],
                            tz:null,
                            country:null,
                            loading: false,
                            noTrans:true
        
                        })
                    }
                    
                    } else {
                        if(res.data.dbError)
                            {
                                toast.error("Internal Server Error",{
                                    position:toast.POSITION.BOTTOM_CENTER
                                })
                                this.setState({
                                    ...this.state,
                                    transaction: [],
                                    tz:null,
                                    country:null,
                                    loading: false,
                                    noTrans:false                            });
                            }
                            else {
                                this.setState({
                                    ...this.state,
                                    transactions: [],
                                    tz:null,
                                    country:null,
                                    loading: false,
                                    noTrans:false
                
                                })
                            }          
                    }
                })
                .catch(err => {
                    console.log(err);
                    toast.warn("Unable to Reach Server! Please Try Again!" , {
                        position: toast.POSITION.BOTTOM_CENTER
                      });
                    this.setState({
                        ...this.state,
                        transaction: [],
                        tz:null,
                        country:null,
                        loading: false
                });
                })
                 

            
           
        }

        } else if(this.state.city.invalid || this.state.city.value === "0" || this.state.city.value === null){
            this.setState({
                submit: {
                    ...this.state.submit,
                    invalid: true 
                },
                city:{
                    ...this.state.city,
                    valid: false,
                    invalid: true
                }
            });
            
        } else if(this.state.merchantName.invalid || this.state.merchantName.value === null){
            this.setState({
                submit: {
                    ...this.state.submit,
                    invalid: true 
                },
                merchantName:{
                    ...this.state.merchantName,
                    valid: false,
                    invalid: true
                }
            });
            
        } else {

                this.setState({
                    ...this.state,
                    loading: true
                });

            let body = {
                m_name : this.state.merchantName.value,
                city: this.state.city.value
            } 

            axios.post("/support/consumer/merchantname", body)
            .then((res) => {    
                if(res.data.data){
                    // console.log("Merchant Name", res.data.result)
                    this.setState({
                        ...this.state,
                        loading: false,
                        merchantDetails: res.data.result,
                        submit: {
                            ...this.state.submit,
                            invalid: false,
                            valid: true
                        },
                        merchantName:{
                            ...this.state.merchantName,
                            valid: true,
                            invalid: false
                        }                  
                    });

                    if(res.data.result.length === 1){
                        this.props.history.push({
                            pathname:"/home/merchantDetails/MerchantSearchDetails",
                            state:{ 
                              id: this.state.merchantDetails[0].QF_MERCHANT_PRID,
                              merLink: '/home/merchantDetails',
                              mer: 'Merchant Details'
                            }
                        });
                        this.setState({
                            ...this.state,
                            show: false,
                        })
                    } else {
                        this.setState({
                            ...this.state,
                            show: true,
                        })
                    }
                    
                } else {
                    if(res.data.dbError){
                        this.setState({
                            ...this.state,
                            loading: false,
                            merchantDetails: [],  
                            noData: true               
                        });
                        toast.error("Internal Server Error", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    } else {
                        this.setState({
                            ...this.state,
                            loading: false,
                            merchantDetails: [], 
                            noData: true               
                        });
                        
                    }
                }         
                
            })
            .catch((err) => {
                console.log(err);
                toast.warn("Unable to Reach Server! Please Try Again!" , {
                    position: toast.POSITION.BOTTOM_CENTER
                  });
                this.setState({
                    ...this.state,
                    loading: false,
                    noData: true
                })
            });
        }
    }

    componentDidMount(){
        // this.setState({
        //     ...this.state,
        //     loading: true
        // })
        axios.get("/support/consumer/city")
           .then((res) => {
            //    console.log("City", res.data.result);
               if(res.data.data){
                
                    this.setState({
                        ...this.state,
                        cities: res.data.result,
                        loading: false
                    })

                    axios.post('/support/allMerchantFetch')
                    .then((response) => {
                        if(response.data.data){
                            if(response.data.result.length!==0){
                                console.log('Result', response.data.result);
                                this.setState({
                                    merchantOptions: response.data.result
                                })
                            } else{
                                console.log("No result found")
                            }
                        } else {
                            if(response.data.dbError){
                                toast.error("Internal Server Error", {
                                    position: toast.POSITION.BOTTOM_CENTER
                                });
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                            } else {
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                            
                            }
                        }
                    })
                    .catch((err1) => {
                        console.log("Error",err1);
                        toast.error("Server Error", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        this.setState({
                            ...this.state,
                            loading: false
                        });
                    });

               } else {
                   if(res.data.dbError){
                       toast.error("Internal Server Error", {
                           position: toast.POSITION.BOTTOM_CENTER
                       });
                       this.setState({
                           ...this.state,
                           loading: false
                       })
                   } else {
                        
                        this.setState({
                            ...this.state,
                            loading: false
                        })
                   }
               }
               
           })
           .catch((err) => {
               toast.warn("Unable to Reach Server! Please Try Again!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({
                ...this.state,
                loading: false
            })
           })        
    }

    onKeyDown=(event)=>{
        if(event.key==='Enter')
        {
            event.preventDefault();
            event.stopPropagation();
            this.submitButtonHandler();
        }
        
    }



    merchantNameHandler = (value) => {

        
        if(value == null || value.length == 0){
            this.setState({
                merchantName:{
                    ...this.state.merchantName,
                    value : null,
                    valid: false,
                    invalid: true
                }
            })
          } else{

            this.setState({
                merchantName:{
                    ...this.state.merchantName,
                    value: value.label,
                    valid: true,
                    invalid: false
                },
                submit: {
                    ...this.state.submit,
                    invalid: false
                },
                email:{
                    ...this.state.email,
                    value:'',
                    invalid: false,
                    emailQ: false
                },
                phone:{
                    ...this.state.phone,
                    value: '',
                    invalid: false,
                    phoneQ: false
                },
                payid:{
                    value:'',
                    valid:false,
                    invalid:false,
                    payidQ:false
                },
                noData: false
            });
        }       
    } 

    merchantCityHandler = (item) => {
        console.log("Value", item)

        if(item == null || item.length == 0){
            this.setState({
                city:{
                    ...this.state.city,
                    value : null,
                    state: null
                }
            })
        } else{
            this.setState({
            city:{
                ...this.state.city,
                value : item.city,
                state: item.state,
                valid: true,
                invalid: false
            },
            submit: {
                ...this.state.submit,
                invalid: false
            },
            email:{
                ...this.state.email,
                invalid: false
            },
            noData: false
            })
        }      
    }
    
    render() {
         console.log("State", this.state)
         const customStyles = {
            option: (provided, state) => ({
              ...provided,
              borderBottom: '1px dotted #9277BA',
              color: state.isSelected ? '#50E3C2' : 'black',
              padding: 10,
              textAlign:'left'
            }),
            singleValue: (provided, state) => {
              const opacity = state.isDisabled ? 0.5 : 1;
              const transition = 'opacity 300ms';
          
              return { ...provided, opacity, transition };
            }
          }
        
        // let allCities = this.state.cities.map((city) => {
        //     return <option value={city.CITY_NAME} key={city.QF_CITY_PRID}>{city.CITY_NAME}{', '}{city.STATE_PROVINCE_NAME}{', '}{city.COUNTRY_NAME}</option>
        // });
        let allCities = this.state.cities.map((city) => {
            return {value: city.QF_CITY_PRID, city: city.CITY_NAME, state: city.STATE_PROVINCE_NAME, country: city.COUNTRY_NAME}
        });


        let options=[];
        if(this.state.merchantOptions.length !== 0){
            if(this.state.city.value!=null){
                let fillArr =this.state.merchantOptions.filter((each)=>{
                                return each.MERCHANT_ADDRESS_CITY===this.state.city.value;
                              })
        
                              options=fillArr.map((item) => {
                        
                                return (
                                    {value: item.QF_MERCHANT_PERSONNEL_PRID, label: item.MERCHANT_NAME ? item.MERCHANT_NAME : '! -- Not Updated'}
                                )
                            })
                      
                }
                else{
        
                    options=this.state.merchantOptions.map((item) => {
                        
                        return (
                            {value: item.QF_MERCHANT_PERSONNEL_PRID, label: item.MERCHANT_NAME ? item.MERCHANT_NAME : '! -- Not Updated'}
                        )
                    })
                }
        }

        return (
            <div >
                <Container fluid>
            <div className="row justify-content-center" style={{ backgroundColor: '#EFEFEF'}}>
            <div className="col-3" >
                    <Form style={{width:350}}>
                            {/* <FormGroup row>
                                <Label for="city" ><text style={{fontFamily:'Muli'}}>City </text><span style={{color: 'red'}}>*</span></Label>
                                <Input onChange={this.merchantCityHandler}
                                    valid={this.state.city.valid ? true : false}
                                    invalid={this.state.city.invalid ? true : false}
                                    value={this.state.city.value ? this.state.city.value : ''}
                                    style={{fontFamily: 'Muli',color: '#563951'}}
                                    type="select">
                                    <option value="0">---Select---</option>
                                    {allCities}
                                
                                </Input>
                                <FormFeedback><text style={{fontFamily:'Muli'}}>City is Required</text></FormFeedback>
                            </FormGroup>
                        <FormGroup row>
                            <Label for="Merchant" ><text style={{fontFamily:'Muli'}}>Merchant Name</text> <span style={{color: 'red'}}>*</span></Label>
                            <Input  onChange={this.merchantNameHandler}
                                    valid={this.state.merchantName.valid ? true : false}
                                    invalid={this.state.merchantName.invalid ? true : false} 
                                    value={this.state.merchantName.value}
                                    onKeyDown={this.onKeyDown}
                                    maxLength={50}
                                    placeholder="Please enter Merchant Name"/>
                            <FormFeedback><text style={{fontFamily:'Muli'}}> Merchant name is invalid!</text> </FormFeedback>
                        </FormGroup> */}
                        <FormGroup>
                                <Row>
                                    <Label for="city" >City <span style={{color: 'red'}}>*</span></Label>
                                </Row>
                                <Row>                               
                                    <Col>
                                    
                                        <Select
                                            isSearchable={true}
                                            isClearable
                                            required
                                            onChange={this.merchantCityHandler}
                                            options={allCities}
                                            getOptionLabel={options => `${options.city}, ${options.state}, ${options.country}`}
                                            styles={customStyles}
                                            width='100%'
                                            valid={this.state.city.valid ? true : false}
                                            invalid={this.state.city.invalid ? true : false}
                                            placeholder="Please enter City Name"
                                            />
                                            
                                            { this.state.city.invalid? <span style={{color: '#DC143C'}}>City is Required</span>:null}
                                    </Col>
                                </Row>
                            </FormGroup>
                        <FormGroup>
                            <Row>
                                <Label for="Merchant" >Professional Name <span style={{color: 'red'}}>*</span></Label>
                            </Row>
                            <Row>
                                <Col>
                                    <Select
                                        isSearchable={true}
                                        isClearable
                                        required
                                        onChange={this.merchantNameHandler}
                                        options={options}
                                        styles={customStyles}
                                        width='100%'
                                        valid={this.state.merchantName.valid ? true : false}
                                        invalid={this.state.merchantName.invalid ? true : false} 
                                        placeholder="Please enter Professional Name"
                                        maxLength={50}
                                        // onKeyDown={this.onKeyDown}
                                    />
                                            { this.state.merchantName.invalid? <span style={{color: '#DC143C'}}>Merchant Name is Required</span>:null}

                                </Col>
                            </Row>
                            <FormFeedback> Professional name is invalid! </FormFeedback>
                        </FormGroup>
                        <FormGroup> <text style={{fontFamily:'Muli'}}>OR</text> </FormGroup>
                        <FormGroup row>
                            <Label for="city"><text style={{fontFamily:'Muli'}}>Email</text> <span style={{color: 'red'}}>*</span></Label>
                            <Input  onChange={this.emailTextHandler}
                                    valid={this.state.email.valid ? true : false}
                                    invalid={this.state.email.invalid ? true : false} 
                                    placeholder="Please enter professional email"
                                    value={this.state.email.value}
                                    maxLength={50}
                                    autoFocus
                                    onKeyDown={this.onKeyDown} />
                            <FormFeedback><text style={{fontFamily:'Muli'}}>Enter Valid Email!</text></FormFeedback>
                        </FormGroup>

                        <FormGroup> <text style={{fontFamily:'Muli'}}>OR</text> </FormGroup>

                        <Row form>
                            <Col sm={12}>
                                <FormGroup row>
                                    <Label for="Phone"><text style={{fontFamily:'Muli'}}>Mobile Number</text> <span style={{color: 'red'}}>*</span></Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup row>
                                    <Input style={{width: 60}} value={this.props.country === 'India' ? '+91' : this.props.country === 'USA' ? '+1' : '+91'}/>
                                </FormGroup>
                            </Col>

                            <Col sm={{size:10}}>
                                <FormGroup>                                
                                    <Input  onChange={this.phoneHandler}
                                            valid={this.state.phone.valid ? true : false}
                                            invalid={this.state.phone.invalid ? true : false} 
                                            placeholder="Please enter Mobile Number"
                                            value={this.state.phone.value}
                                            maxLength={10}
                                            onKeyDown={this.onKeyDown} />
                                    <FormFeedback><text style={{fontFamily:'Muli'}}>Enter Valid Mobile Number!</text></FormFeedback>                                    
                                </FormGroup>
                            </Col>
                        </Row>  
                    
                        <FormGroup> <text>OR</text> </FormGroup>
        
                            
                            
                                
                                <FormGroup  row>    
                                <Label for="Phone">Payment Id <span style={{color: 'red'}}>*</span></Label>

                                    <Input  onChange={this.payidHandler}
                                            valid={this.state.payid.valid ? true : false}
                                            invalid={this.state.payid.invalid ? true : false} 
                                            placeholder="Please enter payment Id"
                                            value={this.state.payid.value}
                                            maxLength={10}
                                            onKeyDown={this.onKeyDown} />
                                    <FormFeedback>Enter Valid Payment Id!</FormFeedback>                                    
                                </FormGroup>

                        <FormGroup>
                            <Button onClick={this.submitButtonHandler} style={{backgroundColor:"#2C2165", color:'white'}} disabled={this.state.loading?true:false}><text style={{fontFamily:'Muli'}}>Search</text></Button>
                        </FormGroup>
                    <ToastContainer />
                    </Form>
                <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/>

                    {this.state.noData ? <text style={{fontFamily:'Muli'}}>No results to display</text> : null}
                    {this.state.noTrans?<text style={{fontFamily:'Muli'}}>No results to display</text> : null}

            
            </div>
            </div>
            <TableRecordsView merchant={this.state.merchantDetails} state={this.state.show}/>
</Container>
        </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        country: state.country
    }
}

export default connect(mapStateToProps)(MerchantDetails);