import React,{Component} from "react";
import {Container,Row,Col,Card,CardHeader,CardBody,CardText,CardTitle,Button,Input,Table,Label,FormFeedback,Form,FormGroup} from "reactstrap"; 
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import "../../../node_modules/react-toastify/dist/ReactToastify.css";
import {BarLoader} from "react-spinners";
import BreadCrumbs from '../Navbar/Breadcrumb';
import moment, { relativeTimeThreshold } from 'moment';
import {connect} from "react-redux";
 import momenttz from 'moment-timezone'; 


class ConsumerSearchTicketId extends Component
{
state={
    offDet:[],
    bookDet:[],
    merchantDet:[],
    severity:null,
    inputCommentValue:'',
    inputResolveValue:'',
    commentButtonClicked:false,
    resolveButtonClick:false,
    resolveInvalid:false, 
    commentInvalid:false,
    SavedComments:[],
    loading:true, 
    consumer_data:[], 
    user_data:[],
    accepted_data:[],
    supportData:this.props.location.state ? this.props.location.state.consumerData : null, 
    f_name:this.props.location.state ? this.props.location.state.rf_name!==null?this.props.location.state.rf_name:null:null,
    l_name:this.props.location.state ? this.props.location.state.rl_name!=null?this.props.location.state.rl_name:'--':null,
    active: this.props.location.state ? null : '/home/supportTicket',
    active0: this.props.location.state ? this.props.location.state.active0 : null,
    active0Link: this.props.location.state ? this.props.location.state.active0Link : null,
    active1: this.props.location.state ? this.props.location.state.active1 : null,
    active1Link: this.props.location.state ? this.props.location.state.active1Link : null,
    active2: this.props.location.state ? this.props.location.state.active2 : null,
    active2Link: this.props.location.state ? this.props.location.state.active2Link : null,
    active3: this.props.location.state ? this.props.location.state.active3 : null,
    active3Link: this.props.location.state ? this.props.location.state.active3Link : null,
    active4: this.props.location.state ? this.props.location.state.active4 : null,
    active4Link: this.props.location.state ? this.props.location.state.active4Link : null,
    active5: this.props.location.state ? this.props.location.state.active5 : null,
    active5Link: this.props.location.state ? this.props.location.state.active5Link : null,
    active6: this.props.location.state ? this.props.location.state.active6 : null,
    active6Link: this.props.location.state ? this.props.location.state.active6Link : null,
    active7: this.props.location.state ? this.props.location.state.active7 : null,
    active7Link: this.props.location.state ? this.props.location.state.active7Link : null,
    active8: this.props.location.state ? this.props.location.state.active8 : null,
    active8Link: this.props.location.state ? this.props.location.state.active8Link : null,
    active9: this.props.location.state ? this.props.location.state.active9 : null,
    active9Link: this.props.location.state ? this.props.location.state.active9Link : null,
    commentAddClicked:false,
    resolveAddClicked:false,
    DisputeVal:this.props.location.state ? this.props.location.state.consumerData.CONSUMER_SUPPORT_CATEGORY==='Dispute'?true:false:null,
    merchantLang:null,
    consumerAdd:[],
    loginDetails:''
}




resolveHandler = () => {
    
    if(this.state.inputResolveValue.length===0||(this.state.inputResolveValue).trim()===''){
       return this.setState({
            resolveInvalid:true,
            commentInvalid:true
        })}
    
     
    let ticketDetails={
        resolveId:this.state.supportData.QF_CONSUMER_SUPPORT_PRID,
        consumerName:this.state.consumer_data[0].CONSUMER_FNAME,
        bId : this.state.supportData.QF_CONSUMER_OFFER_ACCEPTED_PRID,

        merchPersnelPrid:this.state.offDet[0].QF_MERCHANT_PERSONNEL_PRID,
        merchEmail:this.state.merchantDet[0].MERCHANT_CONTACT_EMAIL,
        merchName:this.state.merchantDet[0].MERCHANT_NAME,
        offerName:this.state.offDet[0].MERCHANT_OFFER_HEADER_NAME?this.state.offDet[0].MERCHANT_OFFER_HEADER_NAME:this.state.offDet[0].SERVICE_NAME,
        offerDate:this.state.offDet[0].MERCHANT_OFFER_FROM_DATE_TIME ? 
        momenttz(this.state.offDet[0].MERCHANT_OFFER_FROM_DATE_TIME+"Z").tz(this.state.offDet[0].MERCHANT_OFFER_TIMEZONE).format('DD MMM YYYY')
        :
        momenttz(this.state.bookDet[0].BOOKING_FROM_DATE_TIME+"Z").tz(this.state.offDet[0].SERVICE_TIMEZONE).format('DD MMM YYYY')
        ,
        offerDateFrom:
        this.state.offDet[0].MERCHANT_OFFER_FROM_DATE_TIME ? 
        momenttz(this.state.offDet[0].MERCHANT_OFFER_FROM_DATE_TIME+"Z").tz(this.state.offDet[0].MERCHANT_OFFER_TIMEZONE).format('hh:mm A') 
        :
        momenttz(this.state.bookDet[0].BOOKING_FROM_DATE_TIME+"Z").tz(this.state.offDet[0].SERVICE_TIMEZONE).format('hh:mm A') 
        ,
        offerDateTo:  this.state.offDet[0].MERCHANT_OFFER_TO_DATE_TIME ? 
        momenttz(this.state.offDet[0].MERCHANT_OFFER_TO_DATE_TIME+"Z").tz(this.state.offDet[0].MERCHANT_OFFER_TIMEZONE).format('hh:mm A z') 
        :
        momenttz(this.state.bookDet[0].BOOKING_TO_DATE_TIME+"Z").tz(this.state.offDet[0].SERVICE_TIMEZONE).format('hh:mm A z') 
        ,
        bookingDate:this.state.bookDet[0].OFFER_ACCEPTED_CREATED_ON_DATE ? 
        momenttz(this.state.bookDet[0].OFFER_ACCEPTED_CREATED_ON_DATE+"Z").tz(this.state.offDet[0].QF_MERCHANT_SERVICE_PRID!==null?this.state.offDet[0].SERVICE_TIMEZONE:this.state.offDet[0].MERCHANT_OFFER_TIMEZONE).format('DD MMM YYYY hh:mm A z') 
        :null, 
        offercurrency:this.state.offDet[0].MERCHANT_OFFER_CURRENCY?this.state.offDet[0].MERCHANT_OFFER_CURRENCY:this.state.offDet[0].SERVICE_CURRENCY,
        supportCategory:this.state.supportData.CONSUMER_SUPPORT_CATEGORY,
        description:this.state.supportData.CONSUMER_SUPPORT_DESC,
        // merchantPrid:this.props.location.state.condata[0].QF_MERCHANT_OFFER_PRID,
        consumerPrid: this.props.location.state.TicketId, //this.props.location.state.condata[0].QF_CONSUMER_PRID,
        resolutionComment: this.state.inputResolveValue,
        SupportPrid:this.props.id,
        resolve:true,
        create:false,
        DisputeVal:this.state.DisputeVal,
        lang:this.state.merchantLang
      } 
      
      this.setState({
          resolveAddClicked:true
      })
    axios.post("/support/consumer/BookingIdSearch/CreateTicket",ticketDetails)
    .then(res=>{

    if(res.data.data){
        this.setState({
            resolveAddClicked:false
        })

    this.props.history.push({pathname:'/home/myTicket',state:{resolveActive:true}})
    return toast.success("Ticket Resolved Successfully",{position:toast.POSITION.BOTTOM_CENTER}); 
        }
   else{
       if(res.data.dbError===true){
        this.setState({
            resolveAddClicked:false
        })
    return toast.error("Internal Server Error",{position:toast.POSITION.BOTTOM_CENTER}); 
      }

      if(res.data.nresolved){   
        this.setState({
            resolveAddClicked:false
        })
    return toast.warn("Not Resolved",{position:toast.POSITION.BOTTOM_CENTER}); 

      }

   }

})
.catch(err=>{
    this.setState({
        resolveAddClicked:false
    })
    toast.warn("Unable to Reach Server! Please Try Again!" , {
        position: toast.POSITION.BOTTOM_CENTER
      });
    this.setState({loading:false})
})

}
resolveButtonClick=()=>{
    this.setState({resolveButtonClick:true})
}
severityHandler=(event,id)=>{
        
    this.setState({severity:event.target.value })
    let body={
        severity:event.target.value,
        id:id
    }
    axios.post('/support/consumerSupport/updateSeverity', body)
    .then(res => {
        if(res.data.data){
            toast.success("Severity is Updated", {
                position: toast.POSITION.BOTTOM_CENTER
            })
            let body={
                id:this.state.supportData.QF_CONSUMER_SUPPORT_PRID
            };
             return axios.post('/support/consumer/severityFetch',body)
                       .then((res) => {
                        //    console.log(res);
                        if(res.data.data){
                            if(res.data.result.length !== 0){
                                this.setState({
                                    ...this.state,
                                    severity: res.data.result[0].CONSUMER_SUPPORT_SEVERITY, 
                                    loading: false
                                });
                            } 
                        }
                        else {
                             if(res.data.dbError){
                                toast.error("Internal Server Error!",{
                                    position: toast.POSITION.BOTTOM_CENTER
                                });
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                            } else { 
                                this.setState({
                                    ...this.state,
                                    severity:null,
                                    loading: false
                                });
                                              }
                              
                         
                        }
                       
                    })
                    .catch((err) => {
                        toast.warn("Unable to Reach Server! Please Try Again!" , {
                            position: toast.POSITION.BOTTOM_CENTER
                          });
                        this.setState({
                            ...this.state,
                            loading: false
                        });
                    })
                
            
        } else{
            if(res.data.dbError){
                toast.error("Internal Server Error", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            } else {
                toast.warn("Updation failed", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            }
        }
    })
    .catch(err => {
        toast.warn("Unable to Reach Server! Please Try Again!" , {
            position: toast.POSITION.BOTTOM_CENTER
          });
    })

    
}
componentDidMount()
{ 
    // console.log('names',this.props)
    // console.log('Props render()',this.state.consumerDet)

    this.setState({ 
        ...this.state,
    severity:this.props.location.state ? this.props.location.state.consumerData.CONSUMER_SUPPORT_SEVERITY : null,

    })

    axios.post("/support/consumer/ConsumerSearchDetails",{consumer_prid:this.props.location.state ? this.props.location.state.TicketId : null})
    .then(res=>{
    

        if(res.data.searchDet)
        {
            this.setState({
                accepted_data:res.data.accepted_data, 
                consumer_data:res.data.consumerDet,
                user_data:res.data.userDet,
                consumerAdd:res.data.adressUsers.length!==0?res.data.adressUsers:[],
                loginDetails:res.data.loginDetails.length!==0?res.data.loginDetails:[],
                loading:false
            });
            // console.log('here',this.state)
            let body={
                id:this.state.supportData.QF_CONSUMER_SUPPORT_PRID
            };
            return axios.post('/support/consumer/severityFetch',body)
            .then((res) => {
                // console.log(res);
             if(res.data.data){
                 if(res.data.result.length !== 0){
                     this.setState({
                         ...this.state,
                         severity: res.data.result[0].CONSUMER_SUPPORT_SEVERITY, 
                         loading: false
                     });
                 } 
             }
             else {
                 if(res.data.dbError){
                     toast.error("Internal Server Error!",{
                         position: toast.POSITION.BOTTOM_CENTER
                     });
                     this.setState({
                         ...this.state,
                         loading: false
                     });
                 } else { 
                     this.setState({
                         ...this.state,
                         severity:null,
                         loading: false
                     });
                                   }
                   
             
             }
            
         })
         .catch((err) => {
            toast.warn("Unable to Reach Server! Please Try Again!" , {
                position: toast.POSITION.BOTTOM_CENTER
              });
             this.setState({
                 ...this.state,
                 loading: false
             });
         })
 
           
        }
        else
        {
            if(res.data.dbError===true)
                        {
                            toast.error("Internal Server Error",{position:toast.POSITION.BOTTOM_CENTER});

                        }
            this.setState({loading:false})

        }

    }).catch(err=>{
        toast.warn("Unable to Reach Server! Please Try Again!" , {
            position: toast.POSITION.BOTTOM_CENTER
          });
        this.setState({loading:false})
    })

   let det={
    supportPrid: this.state.supportData ? this.state.supportData.QF_CONSUMER_SUPPORT_PRID : null
}
   axios.post("/support/consumer/fetchComments",{det})
    .then(res=>{
        if(res.data.data){
            this.setState({
                ...this.state,
                SavedComments:res.data.supportUsername,
                loading:false
            })
        } else {
            if(res.data.dbError){
                toast.error("Internal Server Error",{position:toast.POSITION.BOTTOM_CENTER});
                this.setState({loading:false})
            } else {
                this.setState({loading:false})
            }
        }
    })
    .catch(err=>{ 
        toast.warn("Unable to Reach Server! Please Try Again!" , {
            position: toast.POSITION.BOTTOM_CENTER
          });
        this.setState({loading:false})
    })

  let a_prid=this.props.location.state?this.props.location.state.acceptance_prid:null;
   
  axios.post("/support/resolveTic/searchOfferDet",{acceptance_prid:a_prid})
  .then((res) => {
      console.log(res.data)
   if(res.data.data){
       if(res.data.bookingDet.length !== 0){
           this.setState({
               ...this.state,
               bookDet:res.data.bookingDet,
               offDet:res.data.offerDet,
               loading: false
           });
        return axios.post("/support/consumer/merchantname/merchantPersonnelToMerchantName",{mPPrid:res.data.bookingDet[0].QF_MERCHANT_PERSONNEL_PRID})
        .then((res)=>{
             this.setState({
                 merchantDet:res.data.mNameDetails,
                 merchantLang:res.data.mdetails?res.data.mdetails[0].QF_MERCHANT_PERSONNEL_LANG:null
             })
            //  console.log('mydar',this.state.merchantDet)
        })
        .catch((err) => {
            toast.warn("Unable to Reach Server! Please Try Again!" , {
                position: toast.POSITION.BOTTOM_CENTER
              });
            this.setState({
                ...this.state,
                loading: false
            });
         })
    //  console.log('bookDetshhs',this.state.bookDet);
 
       } else if(res.data.bookingDet.length === 0){ 
           this.setState({
               ...this.state,
               bookDet: [],
               offDet:[],
               loading: false
           });

       }
   }
   else {
       if(res.data.dbError){
           toast.error("Internal Server Error!",{
               position: toast.POSITION.BOTTOM_CENTER
           });
           this.setState({
               ...this.state,
               loading: false
           });
       } else { 
           this.setState({
               ...this.state,
               bookDet: [],
               offDet:[],
               loading: false
           }); 
       }
         
   
   }
  
})
.catch((err) => {
   toast.warn("Unable to Reach Server! Please Try Again!" , {
       position: toast.POSITION.BOTTOM_CENTER
     });
   this.setState({
       ...this.state,
       loading: false
   });
})
// console.log('check',this.state)

}

addCommentCancelHandler=()=>{
    this.setState({commentButtonClicked:false,
        addInputInvalid:false,
        submitInvalid:false})
}
inputCommentHandler=(e)=>{
 if(e.target.value.length===0){
    this.setState({
        inputCommentValue:e.target.value,
        submitInvalid:true})
        
    }
    else{
    this.setState({inputCommentValue:e.target.value,
        addInputInvalid:false,
        submitInvalid:false}) 
    }
   
} 
resolveCancelHandler=()=>{
    this.setState({resolveButtonClick:false,
        commentInvalid:false,
        resolveInvalid:false})
}
inputResolveHandler=(e)=>{
    if(e.target.value.length===0){
    this.setState({
        inputResolveValue:e.target.value, 
        resolveInvalid:true})
        
    }
    else{
    this.setState({inputResolveValue:e.target.value,
        commentInvalid:false,
        resolveInvalid:false}) 
    }
}

bookingIdLinkHandler = (booking_id) => {
    let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
    this.props.history.push({
        pathname:url+"/BookingIdSearch",
        state:{
            booking_id,
            url,
            condata: this.props.location.state.condata,
            active: this.state.active,
            active0: this.state.active0,
            active0Link: this.state.active0Link,
            active1: this.state.active1,
            active1Link: this.state.active1Link,
            active2: this.state.active2 ,
            active2Link: this.state.active2Link,
            active3: this.state.active3,
            active3Link: this.state.active3Link,
            active4: this.state.active4,
            active4Link: this.state.active4Link,
            active5: this.state.active5,
            active5Link: this.state.active5Link,
            active6: this.state.active6,
            active6Link: this.state.active6Link,
            active7: this.state.active7,
            active7Link: this.state.active7Link ,
            active8: this.state.active8 ,
            active8Link: this.state.active8Link,
            active9: this.state.active9,
            active9Link: this.state.active9Link,
        }
    }); 
}

disputesHandler=()=>{
    let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;

    let disputeCount=this.state.accepted_data.filter((disputeCount)=>{
        return disputeCount.SERVICE_DISPUTE===1;
      })
    let disputeFiltered=disputeCount.map((each,k)=>{
        return k=each 
    })
    this.props.history.push({
      pathname: url+'/Disputes',
      state:{
        accepted_data:disputeFiltered,
        consumer_prid: this.props.location.state.TicketId,
        url
      }

    })
    
}
cancelledHandler=()=>{ 
    let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;

    let cancelledCount=this.state.accepted_data.filter((canlcelledCount)=>{
        return canlcelledCount.OFFER_CANCELLED===1&&canlcelledCount.OFFER_ACCEPTED===0;
      })
    let cancelFiltered=cancelledCount.map((each,k)=>{
        return k=each 
    }) 
    this.props.history.push({
      pathname: url+'/Cancellations',
      state:{
        accepted_data:cancelFiltered,
        consumer_prid:this.props.location.state.TicketId,
        url
      }

    })
}

bookingsHandler = () => {
    let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
    this.props.history.push({
        pathname: url+'/ConsumerBookings',
        state:{
          accepted_data:this.state.accepted_data,
          consumer_prid:this.props.location.state.TicketId,
          url 
        }
  
      })

}

bookingsConsumedHandler = () =>{
    let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
    
    let consumedCount=this.state.accepted_data.filter((canlcelledCount)=>{
        return canlcelledCount.OFFER_CONSUMED===1;
      })
    let consumedFiltered=consumedCount.map((each,k)=>{
        return k=each 
    }) 
    this.props.history.push({
        pathname: url+'/BookingsConsumed',
        state:{
          accepted_data:consumedFiltered,
          consumer_prid:this.props.location.state.TicketId,
          url 
        }
  
      })
  
  
  }

  consumerPaymentHanler=()=>{
    let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
  
    this.props.history.push({
        pathname: url+'/consumerpayments',
        state:{
            consumer_prid:this.props.location.state.TicketId,          
            url 
        }
  
      })
  }
  
  bookingsViewHandler=()=>{
    let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;

    this.props.history.push({
        pathname: url+'/allBookings',
        state:{
            consumer_prid:this.props.location.state.TicketId,          
            url 
        }
  
      })

}

submitCommentHandler=(e)=>{
   // e.preventdefault();
   if(this.state.inputCommentValue.length===0||(this.state.inputCommentValue).trim()===''){
    return this.setState({
         addInputInvalid:true,
         submitInvalid:true
     })}
    
    this.setState({commentButtonClicked:false})
  
    let commentData={

        comment:this.state.inputCommentValue,
        supportPrid:this.state.supportData.QF_CONSUMER_SUPPORT_PRID,
        support:this.props.id
        
    }
        
    let det={
        supportPrid: this.state.supportData.QF_CONSUMER_SUPPORT_PRID
        
        
    }
    this.setState({
        commentAddClicked:true
    })
  
    axios.post("/support/consumer/addComment",{commentData})
    .then(res=>{
        if(res.data.data){
            this.setState({inputCommentValue:''});
                return axios.post("/support/consumer/fetchComments",{det})
                .then(res1=>{
                    this.setState({
                        SavedComments:res1.data.supportUsername,
                        commentAddClicked:false
    })
                })
                .catch(err=>{
                    this.setState({commentAddClicked:false})

                    toast.warn("Unable to Reach Server! Please Try Again!" , {
                        position: toast.POSITION.BOTTOM_CENTER
                      });
                    this.setState({loading:false})
                })
        }
    })       
    .catch(err=>{ 
        this.setState({commentAddClicked:false})

        toast.warn("Unable to Reach Server! Please Try Again!" , {
            position: toast.POSITION.BOTTOM_CENTER
          });
        this.setState({loading:false})
    })

   
}
earningsViewHandler=()=>{
    let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;

    this.props.history.push({
        pathname: url+'/earnedCoupons',
        state:{
            consumer_prid:this.props.location.state.TicketId,          
            url 
        }
  
      })

}
render()
{   
    console.log('here',this.state)

    console.log('fhere',this.state.accepted_data)

    
    let loading =null;
    if(this.state.loading){
        loading = <text style={fontText1}>Loading...</text>
    } else {
        loading = null;
    }

    // let offerAcceptMap= this.state.accepted_data.filter((offerCount)=>{
    //     return offerCount.OFFER_ACCEPTED===1;
    //   })
      let offerAcceptFilter=this.state.accepted_data.map((each,k)=>{
       return k=each
      }
      )
      let offerAcceptedfiltered=offerAcceptFilter.length
  
      let offerCancelMap= this.state.accepted_data.filter((offerCancelCount)=>{
          return offerCancelCount.OFFER_CANCELLED===1;
        })
        let offerCancelFilter=offerCancelMap.map((each,k)=>{
         return k=each
        }
        )
        let offerCancelfiltered=offerCancelFilter.length
    
        let offerConsumeMap= this.state.accepted_data.filter((offerConsumeCount)=>{
          return offerConsumeCount.OFFER_CONSUMED===1;
        })
        let offerConsumeFilter=offerConsumeMap.map((each,k)=>{
         return k=each
        }
        )
        let offerConsumefiltered=offerConsumeFilter.length 
     
        let disputeCount=this.state.accepted_data.filter((disputeCount)=>{
            return disputeCount.SERVICE_DISPUTE===1;
          })
        let disputeFiltered=disputeCount.map((each,k)=>{
            return k=each
        })
        let finalDispCount=disputeFiltered.length
         
   let resolveComment=null
   if(this.state.resolveButtonClick){
    resolveComment=(
        <Col sm={{size:4}} style={{marginTop:10}}>  
  
            <Label style={{fontWeight:"500",marginRight: 5,fontFamily: 'Muli',color: '#868686'}}>Enter Resolution:<span style={{color: 'red'}}>*</span> </Label>
            <Input  value={this.state.inputResolveValue} invalid={this.state.commentInvalid?true:false} onChange={this.inputResolveHandler} maxLength={1000} style={{width:300,marginBottom:20,height:100}}  type="textarea"/>
            <FormFeedback style={{marginBottom:10, fontFamily:'Muli'}}>Resolution is required</FormFeedback>

            <Button  onClick={this.resolveHandler} invalid={this.state.resolveInvalid?false:true} style={{ float:'left', backgroundColor:"#612147", fontFamily:'Muli'}} disabled={this.state.resolveAddClicked} >Resolve</Button>
            <Button  onClick={this.resolveCancelHandler}  style={{  marginLeft:20,float:'left', backgroundColor:'#F49F3D', fontFamily:'Muli' }}>Cancel</Button>
        
        </Col>)
 
   }
   

    let addComment=null;
    let i=this.state.SavedComments.length+1;
    // console.log("here",i)
    if(this.state.commentButtonClicked) {
        if(this.state.SavedComments.length!==0){

               addComment=(

                //    <Col xl={12}>
                   
                   <Table   hover > 
                     <thead style={{marginTop: 50,backgroundColor:'#2C2165',color:"white", fontFamily:'Muli'}}>
                       <tr>
                       <th>S.No</th>
                       <th>Resolution Comment </th>
                       <th>Support User</th>
                       <th>Comment Created Date</th>
                       </tr>
                    </thead>    
                      <tbody style={{fontFamily:"Muli", color: '#563951',fontWeight:'bold'}}>
               { this.state.SavedComments.map((comment) => {
                   i=i-1;
                       return (
                         <tr>

                            <td>{i}</td>  
                            <td>{comment.QF_SUPPORT_USER_COMMENTS}</td>
                            <td>{comment.SUPPORT_USER_FNAME} {comment.SUPPORT_USER_LNAME}</td>
                            <td>{momenttz(comment.QF_SUPPORT_USER_COMMENT_CREATED_ON_DATE+'Z').tz('Asia/Kolkata').format("DD MMM YYYY hh:mm A")} IST</td>
                            
                            </tr> 
                       )
                   })}
                  </tbody>
                   </Table>
                 /* </Col> */
                 )
              
                }
            
            else{
                if(this.state.SavedComments.length===0){
                addComment=(
                 <div>
                    <p style={{marginTop:20}}><text style={fontText1}>### No Comments Found ###</text></p>
                </div>)
                }
                    
            }
        }
        else{
            if(this.state.SavedComments.length!==0){
                addComment=(

                    <Col xl={12}>
                    
                    <Table hover > 
                      <thead style={{marginTop: 20,backgroundColor:'#2C2165',color:"white", fontFamily:'Muli'}}>
                        <tr>
                      
                        <th>S.No</th>
                        <th>Resolution Comment</th>
                        <th>Support User</th>
                        <th>Comment Created Date</th>
                          
                       </tr>
                     </thead>
                       <tbody style={{fontFamily:"Muli", color: '#563951',fontWeight:'bold',}}>
                { this.state.SavedComments.map((comment) => {
                    i=i-1;
                        return (
                          <tr>
 
                             <td>{i}</td>  
                             <td>{comment.QF_SUPPORT_USER_COMMENTS}</td>
                             <td>{comment.SUPPORT_USER_FNAME} {comment.SUPPORT_USER_LNAME} </td>
                             <td>{momenttz(comment.QF_SUPPORT_USER_COMMENT_CREATED_ON_DATE+'Z').tz('Asia/Kolkata').format("DD-MMM-YYYY hh:mm A")} IST</td>
                             
                             </tr> 
                        )
                    })
                }
                    
                          
                      </tbody>
                    </Table>
                  </Col>
                  )


            }
            if(this.state.SavedComments.length===0){
                addComment=(<div>
                    <p style={{marginTop:20}}><text style={fontText1}>### No Comments Found ###</text></p>
                </div>
                    
                    
                )} 
        }
         
    const rowStyle={
        textAlign:"initial",
        // border: '1px solid black'
    }
    const fWeightSpan={
        fontWeight:"500",
        fontFamily: 'Muli',
        color: '#868686',
        marginRight:5
    }
    const fontText1 = {
        fontWeight:'bold',
        fontFamily: 'Muli',
        color: '#563951'                        
    }
    const LinkedText = {
        textDecoration:'underline',
        cursor:'pointer', 
        color: '#302939', 
        fontWeight:'bold',
        textTransform: 'uppercase',
        fontWeight:'bold',
        fontFamily: 'Muli',
        color: '#563951' 
    }
    
    let cd=this.state.consumer_data,ud=this.state.user_data,sd=this.state.supportData;
    return (
                  <div>
            {/* <BreadCrumbs csticket='Support Ticket Details' csticketLink='/home/supportTicket/ConsumerSearchTicketId' breadCrumb={true}/> */}

         <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/> 
         {loading}
            {
                (cd.length!==0 )
                ?
                 
                (
        <Container fluid style={{backgroundColor:"rgb(239,239,239)"}}>
                <Card style={{border:"1px solid #a78bba"}}>
                <CardHeader style={{fontWeight:"bolder",fontSize:"18px",color:"#fff",backgroundColor:"#2C2165", fontFamily:'Muli'}}>
                <Row> 
                    <Col sm={{size:4, offset:4}}>Customer Details </Col>  
                    <Col sm={{size:'auto', offset:2}}  > {ud.length === 0 ? <text style={{color:"#563951", padding:10,outline: '5px solid #e7eaf6',backgroundColor:"#FFD0D0"}}>Guest User</text> : ud[0].ACCOUNT_VERIFIED_FLAG !== 1 ? <text style={{color:"#563951", padding:10,outline: '5px solid #e7eaf6',backgroundColor:"#FFD0D0"}}> Guest User </text> : <text style={{color:"#563951", padding:10,outline: '5px solid #e7eaf6',backgroundColor:"#FFD0D0"}}>Registered User</text> } </Col>
                </Row>
                </CardHeader>
                <CardText style={{padding:"25px"}}>
                    <Row className="mb-4" style={rowStyle}>
                        <Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}>Name:</span>  {cd[0].CONSUMER_FNAME!==null?cd[0].CONSUMER_FNAME:"Not updated"}</text>
                        </Col>

                        <Col size={4}>
                            <text style={fontText1}><span style={fWeightSpan}>Email:</span> {cd[0].CONSUMER_EMAIL!==null?cd[0].CONSUMER_EMAIL:"Not updated"}
                            {cd[0].CONSUMER_EMAIL !==null ?
                                ud.length!==0?
                                    ud[0].ACCOUNT_VERIFIED_FLAG===1 ?
                                        <span style={{backgroundColor:"#3DC5B6",padding:"5px",color:"#fff",borderRadius:"3px"}}>Verified</span>
                                        :
                                        <span style={{backgroundColor:"#F86C69",padding:"5px",color:"#fff",borderRadius:"3px"}}>Not-Verified</span>
                                : <span style={{backgroundColor:"#F86C69",padding:"5px",color:"#fff",borderRadius:"3px"}}>Not-Verified</span>
                            :null} 
                            </text>
                        </Col>
                        <Col size={3}>
                            <text style={fontText1}><span style={fWeightSpan}>Mobile Number:</span> 
                            {/* { cd[0].CONSUMER_PHONE!==null?'+1':null} {cd[0].CONSUMER_PHONE!==null ? cd[0].CONSUMER_PHONE : "Not updated"}  */}
                            {cd[0].CONSUMER_PHONE_COUNTRY_CODE?cd[0].CONSUMER_PHONE_COUNTRY_CODE+' ':cd[0].CONSUMER_PHONE!==null?'+1 ':null}{cd[0].CONSUMER_PHONE!==null ?  <text>{cd[0].CONSUMER_PHONE.toString().substring(0,3)}-{cd[0].CONSUMER_PHONE.toString().substring(3,6)}-{cd[0].CONSUMER_PHONE.toString().substring(6,10)}</text> : "Not updated"}                            

                            {cd[0].CONSUMER_PHONE !==null ?
                                ud.length!==0? 
                                    ud[0].MOBILE_VERIFIED_FLAG===1 ?
                                        <span style={{backgroundColor:"#3DC5B6",padding:"5px",color:"#fff",borderRadius:"3px"}}>Verified</span>
                                        :
                                        <span style={{backgroundColor:"#F86C69",padding:"5px",color:"#fff",borderRadius:"3px"}}>Not-Verified</span>
                                : <span style={{backgroundColor:"#F86C69",padding:"5px",color:"#fff",borderRadius:"3px"}}>Not-Verified</span>
                            :null} 
                            </text>   
                        </Col>                        
                    </Row>   
                    <Row className="mb-4" style={rowStyle}>

                    <Col size={4}>
                            <text style={fontText1}><span style={fWeightSpan}>Bookings:</span> {offerAcceptedfiltered===null||0?0:offerAcceptedfiltered>0?<text style={LinkedText} onClick={()=>this.bookingsHandler()}> {offerAcceptedfiltered}</text>:offerAcceptedfiltered}</text>
                        </Col>
                        
                        <Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}>Bookings Consumed:</span> {offerConsumefiltered===null||0?0:offerConsumefiltered>0?<text style={LinkedText} onClick={()=>this.bookingsConsumedHandler()}> {offerConsumefiltered}</text>:offerConsumefiltered}</text>
                        </Col>

                       
                        <Col size={3}>

                        <text style={fontText1}><span style={fWeightSpan}>Bookings Cancelled:</span> 
                            {offerCancelfiltered===null||0?0:offerCancelfiltered>0?<text style={LinkedText} onClick={()=>this.cancelledHandler()}>{offerCancelfiltered}</text>:offerCancelfiltered}
                          </text>
                            {/* <text><span style={fWeightSpan}>Bookings Cancelled:</span> {offerCancelfiltered===null||0?0:offerCancelfiltered}</text> */}
                                                    </Col>                        
                    </Row>
                    <Row className="mb-4" style={rowStyle}>
                        <Col sm={{size:4}}>
                            <text style={fontText1}><span style={fWeightSpan}>Bookings Disputed:</span>
                            {finalDispCount===null||0?0:finalDispCount>0?<span style={LinkedText} onClick={()=>this.disputesHandler()}>{finalDispCount}</span>: <span> {finalDispCount}</span>}
                            </text>
                             {/* <text style={LinkedText} onClick={()=>this.disputesHandler()}>{finalDispCount===null||0?0:finalDispCount}</text></text> */}
                        </Col>
                        <Col sm={{size:4}}>     
                        <Button size="md" style={{backgroundColor:"#612147",marginRight:5,fontFamily: 'Muli',}} onClick={()=>this.earningsViewHandler()}>Earned Coupons</Button>
                        
                       </Col>
                        <Col sm={{size:4}}>     
                        <Button size="md" style={{backgroundColor:"#612147",marginRight:5,fontFamily: 'Muli',}} onClick={()=>this.consumerPaymentHanler()}>Payment&Transactions {'-->'}</Button>
                        
                       </Col>
                        </Row>  
                        <Row className="mb-4" style={rowStyle}>
                           <Col className="col-4">
                                <text style={fontText1}><span style={fWeightSpan}> Customer Address:</span>{ this.state.consumerAdd[0].length!==0 ? this.state.consumerAdd[0][0].CONSUMER_ADDRESS ? this.state.consumerAdd[0][0].CONSUMER_ADDRESS : '------' : '------' }</text>
                                {/* <text style={fontText1}><span style={fWeightSpan}> Category:</span>{ this.state.cats.length != 0 ? this.state.cats[0].category_name : <text style={fontText}>---</text> } </text> */}
    
                            </Col> 
                            <Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}>Promotional Consent:</span>{cd[0].CONSUMER_PROMO_CONSENT_FLAG==1?"Yes":"No"}</text>
                        </Col> 
                            <Col style={{paddingTop:10}}>
                                <text style={fontText1}><span style={fWeightSpan}>Last Login Time:</span>{
                                this.state.loginDetails!==0 ? 
                                (this.state.loginDetails[0].USER_TOKEN_UPDATED_ON_DATE ? 
                                    moment(this.state.loginDetails[0].USER_TOKEN_UPDATED_ON_DATE+"Z").tz(this.state.consumerAdd[0].length !==0 ? (this.state.consumerAdd[0][0].CONSUMER_ADDRESS.includes('USA') ? 'America/Los_Angeles' : 'Asia/Kolkata') : (this.props.country === 'USA'?'America/Los_Angeles' : 'Asia/Kolkata')).format("DD-MMM-YYYY hh:mm A z") 
                                    : '-----') 
                                : '------'}
                                </text>
                            </Col> 
                            </Row>               
                </CardText>
                </Card>
                <Card style={{border:"1px solid #a78bba", marginTop:10}}>
            
                <CardHeader style={{fontWeight:"bolder",fontSize:"18px",color:"#fff",backgroundColor:"#2C2165", fontFamily:'Muli'}}>Support Details</CardHeader>
                <CardText style={{padding:"25px"}}>
                <Row className="mb-4" style={rowStyle}>

                    <Col className="col-4"  >
                    <text style={fontText1}><span style={fWeightSpan}>Ticket-Id:</span> {sd.QF_CONSUMER_SUPPORT_PRID}</text>
                    </Col>
                    <Col  className="col-4" >
                                <text style={fontText1}><span style={fWeightSpan}>Booking-Id:</span> <text style={LinkedText} onClick={() => this.bookingIdLinkHandler(sd.QF_CONSUMER_OFFER_ACCEPTED_PRID)}>{sd.QF_CONSUMER_OFFER_ACCEPTED_PRID} </text></text>
                    </Col>
 
                    <Col >
                    <text style={fontText1}><span style={fWeightSpan}>Status:</span>
                        {
                            sd.CONSUMER_SUPPORT_STATUS ===2 ?

                            <span style={{backgroundColor:"#3DC5B6",borderRadius:"3px",color:"#fff",marginLeft:"15px",marginRight:"15px",padding:5}}>  Resolved </span>
                        :
                            <span style={{backgroundColor:"#FFC459",borderRadius:"3px",color:"#fff",marginLeft:"15px",marginRight:"10px", padding:5}}> Pending </span>
                        }
                       </text>
                    </Col>
                    
 
                    
                  
              
                   
                </Row>
                 


                    <Row className="mb-4" style={rowStyle}>
                    <Col  className="col-4">
                         
                         <text style={fontText1}><span style={fWeightSpan}>Category:</span> {sd.CONSUMER_SUPPORT_CATEGORY==='Consumer refund request'?'Customer Refund Request':sd.CONSUMER_SUPPORT_CATEGORY}</text>
                     </Col>
                    <Col className="col-4"  >
                          
                         <text style={fontText1}><span style={fWeightSpan}>Ticket Created Date:</span> {sd.CONSUMER_SUPPORT_CREATED_ON_DATE===null?<text>---</text>:momenttz(sd.CONSUMER_SUPPORT_CREATED_ON_DATE+'Z').tz('Asia/Kolkata').format("DD-MMM-YYYY hh:mm A")} IST</text>
                     </Col>
                      
                       
                    { sd.CONSUMER_SUPPORT_STATUS ===2 ?

                        <Col className="col-4">                         
                            <text style={fontText1}><span style={fWeightSpan}>Ticket Resolved Date:</span> {sd.CONSUMER_SUPPORT_RESOLVED_ON_DATE===null?<text>---</text>:momenttz(sd.CONSUMER_SUPPORT_RESOLVED_ON_DATE+'Z').tz('Asia/Kolkata').format("DD-MMM-YYYY hh:mm A") } IST</text>
                        </Col>

                         :
                        <Col sm={{size:3}}>
                            <text style={fWeightSpan}>Severity:</text>
                            <text style={fontText1}><Input  type="select" style={{ marginRight:'65px', width:'50%', float:'right'}} value={this.state.severity}
                                    onChange={(event) => this.severityHandler(event,sd.QF_CONSUMER_SUPPORT_PRID)}>
                                        <option value={1}>Very Low</option>
                                        <option value={2}>Low</option>
                                        <option value={3}>Medium</option>
                                        <option value={4}>High</option>
                                        <option value={5}>Very High</option>
                            </Input></text>
                        </Col> 
                    }
                    

                      </Row>
                      {/* <Col style={colStyle} sm={{size:2}}> Type: </Col>
                                    <Col style={colStyle} sm={{size:4}}>
                                        <Input type="select" onChange={(event) => this.supportUserTypeHandler(event,user.QF_SUPPORT_USER_PRID)}>
                                            <option value='Consumer'>Consumer</option>
                                            <option value='Merchant'>Merchant</option>
                                        </Input>
                                    </Col>                  */}
                 
                    <Row className="mb-4" style={rowStyle}>


<Col className="col-4">
        
        <text style={fontText1}><span style={fWeightSpan}>Created By:</span> {sd.f_name?sd.f_name:sd.SUPPORT_USER_FNAME}{' '}{sd.l_name?sd.l_name:sd.SUPPORT_USER_LNAME}</text> 

        
    </Col>

                        

                   
                 
                    
                        {sd.CONSUMER_SUPPORT_STATUS ===2?
                            <Col  className="col-4">
                                <text style={fontText1}><span style={fWeightSpan}>Resolution Provided:</span> {sd.CONSUMER_SUPPORT_RESOLUTION}</text>
                            </Col>
                        :
                        null}
                        {sd.CONSUMER_SUPPORT_STATUS ===2?
                            <Col  className="col-4">
                                {/* <text style={fontText1}><span style={fWeightSpan}>Resolved by:</span> {sd.rf_name?sd.rf_name.length!==0?sd.rf_name:'---':null} {sd.rl_name?sd.rl_name.length!==0?sd.rl_name:'---':null}</text> */}
                                <text style={fontText1}><span style={fWeightSpan}>Resolved by:</span> {sd.rf_name?sd.rf_name:'-'} {sd.rl_name?sd.rl_name:'--'}</text>

                            </Col>
                        :
                        null}
                            {/* <Row> */}
                            
                            {/* {addComment} */}

                        {this.state.SavedComments.length !==0 ? 
                            (sd.CONSUMER_SUPPORT_STATUS === 1 ? 
       
                                <Col sm={{size:4}} style={{marginTop:10}}>
                                    
                                    <div style={fWeightSpan}>
                                    <Label >Add Comment:<span style={{color: 'red'}}>*</span></Label>

                                        <Input invalid={this.state.addInputInvalid ? true : false} value={this.state.inputCommentValue} onChange={this.inputCommentHandler} maxLength={2000} style={{width:300,height:100}}  type="textarea"/>
                                        <FormFeedback  style={{marginBottom:20, fontFamily:'Muli'}}>Comment is required</FormFeedback>

                                        <Button  onClick={this.submitCommentHandler} invalid={this.state.submitInvalid?true:false}  style={{marginTop:20, backgroundColor:'#3A506B'}} disabled={this.state.commentAddClicked}>Submit</Button>
                                    </div>

                                        
                
                                </Col>
                            :
                            null)

                        :
                        (sd.CONSUMER_SUPPORT_STATUS === 1 ?
                            <Col>
                            
                                    <div style={fWeightSpan}>
                                        <Label >Add Comment:<span style={{color: 'red'}}>*</span></Label>
                                        <Input invalid={this.state.addInputInvalid?true:false} value={this.state.inputCommentValue} onChange={this.inputCommentHandler} maxLength={2000} style={{width:300,height:100}}  type="textarea"/>
                                        <FormFeedback  style={{marginBottom:20, fontFamily:'Muli'}}>Comment is required</FormFeedback>

                                        <Button  onClick={this.submitCommentHandler} invalid={this.state.submitInvalid?true:false}  style={{marginTop:20, backgroundColor:'#3A506B'}} disabled={this.state.commentAddClicked}>Submit</Button>
                                    </div>                            
                                
                                
                            </Col>
                        :
                        null)}                     
                

                        {sd.CONSUMER_SUPPORT_STATUS ===1 ?                    
                            this.state.resolveButtonClick ?
                                resolveComment
                            :
                                <Col sm={{size: 4}}>
                                    <Button invalid={this.state.resolveInvalid?true:false} onClick={this.resolveButtonClick} style={{backgroundColor: '#612147', float:'left', width:'40%', fontFamily:'Muli'}} color="info" >Resolve Ticket {'->'}</Button>
                                </Col>
                        :
                        null}

                    </Row>
                    <Row className="mb-4" style={rowStyle}>

                    <Col className="col-4">
                            
                            <text style={fontText1}><span style={fWeightSpan}>Description:</span> {sd.CONSUMER_SUPPORT_DESC}</text> 
                            
                        </Col>
                        </Row>
                    
           </CardText>
    
    {
    cd.length!==0?addComment:null}
    
       
            </Card>
            
            
        </Container>
                )
            
            :
            null
            
        }
            <ToastContainer autoClose={2500}/>
       
       
    
    </div>
    )

}

}
const mapStateToProps=state=>{
    return {
      id:state.id,
      country: state.country, 
  }
  }  
  export default connect(mapStateToProps)(ConsumerSearchTicketId);

 