import React,{Component} from 'react';
import { Button, Form, FormGroup,FormFeedback, FormText, Input,Label, Container} from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import { connect } from 'react-redux';

class SupportUserChangePassword extends Component{
 
    state={
        oldPass:{
            value:null,
            valid : false,
            invalid: false,
            match: false

        },
        newPass:{
            value: null,
            valid : false,
            invalid: false,
            exist: false

        },
        confirmPass: {
            value: null,
            valid: false,
            invalid: false
        },
        submit: {
            invalid: false
        },
        noMatch: false,
        changeCliked:false
    }

    oldPassHandler=(e)=>{
        if(e.target.value.length===0){
            this.setState({
                oldPass:{
                    ...this.state.oldPass,
                    value:null,
                    invalid:true,
                    valid:false
                },
                changeCliked:false
            })                
        }
        else{
            this.setState({
                oldPass:{
                    ...this.state.oldPass,
                    value:e.target.value,
                    invalid:false,
                    valid:true,
                    match:false
                },
                changeCliked:false

            })                   
        }
    }

    newPassHandler=(e)=>{
        if(e.target.value.length===0){
            this.setState({
                newPass:{
                    ...this.state.newPass,
                    value:null,
                    invalid:true,
                    valid:false
                },
                changeCliked:false

            })                
        }
        else{
            this.setState({
                newPass:{
                    ...this.state.newPass,
                    value:e.target.value,
                    invalid:false,
                    valid:true,
                    exist: false
                },
                changeCliked:false

            })                   
        }
    }

    confirmPassHandler=(e)=>{
        if(e.target.value.length === 0){
            this.setState({
                confirmPass:{
                    ...this.state.confirmPass,
                    value:null,
                    invalid:true,
                    valid:false
                },
                changeCliked:false

            })                
        }
        else{
            this.setState({
                confirmPass:{
                    ...this.state.confirmPass,
                    value:e.target.value,
                    invalid:false,
                    valid:true
                },
                changeCliked:false

            })                   
        }
    }
    onKeyDown=(event)=>{
        if(event.key==='Enter')
        {
            event.preventDefault();
            event.stopPropagation();
            this.submitHandler();
        }
        
    }
    submitHandler = () => {
     
        // console.log('det',this.state)
        if(this.state.oldPass.invalid || this.state.oldPass.value === null){

            this.setState({
                submit: {
                    ...this.state.submit,
                    invalid: true 
                },
                oldPass:{
                    ...this.state.oldPass,
                    valid: false,
                    invalid: true
                }
            });
            // return toast.error("Old Password is required!", {
            //     position: toast.POSITION.BOTTOM_CENTER
            //   });

        }
        
        else if(this.state.newPass.invalid || this.state.newPass.value === null || this.state.newPass.value.length <8){

            this.setState({
                submit: {
                    ...this.state.submit,
                    invalid: true 
                },
                newPass:{
                    ...this.state.newPass,
                    valid: false,
                    invalid: true
                }
            });
            // return toast.error("New Password is required!", {
            //     position: toast.POSITION.BOTTOM_CENTER
            //   });

        }
        
        else if(this.state.confirmPass.invalid || this.state.confirmPass.value === null){

            this.setState({
                submit: {
                    ...this.state.submit,
                    invalid: true 
                },
                confirmPass:{
                    ...this.state.confirmPass,
                    valid: false,
                    invalid: true
                }
            });
            // return toast.error("Confirm Password is required!", {
            //     position: toast.POSITION.BOTTOM_CENTER
            //   });
        }
        
        else if(this.state.newPass.value !== this.state.confirmPass.value){

            this.setState({
                submit: {
                    ...this.state.submit,
                    invalid: true 
                },
                confirmPass:{
                    ...this.state.confirmPass,
                    valid: false,
                    invalid: true
                },
                noMatch: true
            });
            // return toast.error("Password doesn't match!", {
            //     position: toast.POSITION.BOTTOM_CENTER
            //   });

        } else {
            this.setState({
                submit: {
                    ...this.state.submit,
                    invalid: false
                },
                oldPass:{
                    ...this.state.oldPass,
                    valid: true,
                    invalid: false
                },
                newPass:{
                    ...this.state.newPass,
                    valid: true,
                    invalid: false
                },
                confirmPass:{
                    ...this.state.confirmPass,
                    valid: true,
                    invalid: false
                },
                changeCliked:true

            });

            let body ={
                sid : this.props.u_id,
                password : this.state.oldPass.value,
                newPassword: this.state.newPass.value,  
            }

            // console.log("Body", body)

           

            axios.post('/support/supportUser/compareChangePassword', body)
            .then(res => {
                // console.log("Response", res.data);
                if(res.data.data){
                    this.setState({
                        changeCliked:false
                          
                        })
                        
                    axios.post('/support/supportUser/changePassword', body)
                    .then(res1 => {
                        // console.log("Request Update", res1.data)
                        if(res1.data.data){
                            this.setState({
                            changeCliked:false
                              
                            }) 

                           this.props.history.push("/");

                            return toast.success("Password Changed Successfully", {
                                position: toast.POSITION.BOTTOM_CENTER
                            })

                            

                        } else {
                            if(res1.data.dbError){
                                this.setState({
                                    changeCliked:false
                                      
                                    })
                                toast.error("Internal Server Error", {
                                    position: toast.POSITION.BOTTOM_CENTER
                                })
                            }
                        }
                    }) .catch(err1 => {
                        this.setState({
                            changeCliked:false
                              
                            })
                        toast.warn("Unable to Reach Server! Please Try Again!" , {
                            position: toast.POSITION.BOTTOM_CENTER
                          });
                    })                   
                } else {
                    if(res.data.dbError){
                        this.setState({
                            changeCliked:false
                              
                            })
                        toast.error("Internal Server Error", {
                            position: toast.POSITION.BOTTOM_CENTER
                        })
                    } else if(res.data.existing){
                        this.setState({
                            changeCliked:false
                              
                            })

                        toast.error("Please Update a new Password!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        })
                        this.setState({
                            ...this.state,
                            newPass:{
                                ...this.state.newPass,
                                valid: false,
                                invalid: true,

                                exist: true
                            },
                            changeCliked:false

                        })

                    } else {
                        // toast.error("Old Password doesn't Match!", {
                        //     position: toast.POSITION.BOTTOM_CENTER
                        // })
                        this.setState({
                            ...this.state,
                            oldPass:{
                                valid: false,
                                invalid: true,
                                match: true,
                    changeCliked:false

                            }
                        })
                    }
                }
            })
            .catch(err => {
                this.setState({
                    changeCliked:false
                      
                    })
                console.log("Error", err);
                toast.warn("Unable to Reach Server! Please Try Again!" , {
                    position: toast.POSITION.BOTTOM_CENTER
                  });
            })
        }
    }


      

    render() {

        // console.log("State", this.state)

        
        return (
            <div >
                <Container fluid>
            <div className="row justify-content-center" style={{height: '100%' , backgroundColor: '#EFEFEF'}}>
            <div className="col-3" >

            <Form>
                    <FormText style={{fontSize:24}}> Change Password</FormText>
                    <FormGroup row>
                    <Label for="fname" >Old Password <span style={{color: 'red'}}>*</span></Label>
                            <Input  onChange={this.oldPassHandler}
                                    valid={this.state.oldPass.valid ? true : false}
                                    invalid={this.state.oldPass.invalid ? true : false} 
                                     //"Old Password"
                                    type='password'
                                    value={this.state.oldPass.value}
                                    />
                            {this.state.oldPass.match ? <FormFeedback>Old Password doesn't Match!</FormFeedback> : <FormFeedback> Old Password is invalid</FormFeedback>}
 
                    </FormGroup>  
                    <FormGroup row>
                    <Label for="lname" >New Password <span style={{color: 'red'}}>*</span></Label>
                            <Input  onChange={this.newPassHandler}
                                    valid={this.state.newPass.valid ? true : false}
                                    invalid={this.state.newPass.invalid ? true : false} 
                                     //"New Password"
                                    type='password'
                                    value={this.state.newPass.value}
                                    />
                            {this.state.newPass.exist ? <FormFeedback>You are Trying to Update Existing Password!</FormFeedback> : <FormFeedback> New Password is invalid! </FormFeedback>}
 
                    </FormGroup>
                    <FormGroup row>
                    <Label for="Email" >Confirm Password <span style={{color: 'red'}}>*</span></Label>
                            <Input  onChange={this.confirmPassHandler}
                                    type='password'
                                    valid={this.state.confirmPass.valid ? true : false}
                                    invalid={this.state.confirmPass.invalid ? true : false} 
                                     //"Confirm Password"
                                     onKeyDown={this.onKeyDown}
                                    value={this.state.confirmPass.value}
                                    
                                    />
                            {this.state.noMatch ? <FormFeedback> Password doesn't match! </FormFeedback> : <FormFeedback> Confirm Password is invalid </FormFeedback>} 
                    </FormGroup>                      
                         
                    <FormGroup>
                        <Button style={{ margin:5, backgroundColor:'#2C2165'}} onClick={this.submitHandler} disabled={this.state.changeCliked}   >Change</Button>
                    </FormGroup>
                </Form>
            <ToastContainer />
            
            </div>
            </div>
            </Container>
            </div>
        );

    }
}

const mapStateToProps = state => {
    return {
        u_id : state.id 
    }
}

export default connect(mapStateToProps)(SupportUserChangePassword);