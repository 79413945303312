import React, { Component } from 'react';
import { Form, FormGroup, FormFeedback, Label, Input, Button, Row, Col,Container,ModalFooter,CardText,CardBody,Card,ModalBody,ModalHeader,Modal } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import '../../../node_modules/react-datepicker/dist/react-datepicker.css';
import '../../../node_modules/react-datepicker/dist/react-datepicker-cssmodules.css';
import { BarLoader } from "react-spinners";

// import ViewByOfferid from '../SubComponents/ViewByOfferId';


class ImageData extends Component {
    
        state = {
            category : {
                value: "---select---",
                valid: false,
                invalid: false
            },
            categories: [],
            subCategory : {
                value: "---select---",
                valid: false,
                invalid: false
            },
            subCategories: [],
         
          
            submit:{
                invalid: false,
                valid: false,
                Button: false
            },
        
          loading: false,
          laoding2:false,
          state: false,
          images:[],
          showImageModal:false
        }


        componentDidMount() {
            // this.setState({
            //     ...this.state,
            //     loading:true
            // });
            axios.post("/support/consumer/categoryDetails")
            .then((res) => {
                if(res.data.result){
                        this.setState({
                            ...this.state,
                            categories: res.data.category,
                            loading: false
                        });                    

                } else {
                    if(res.data.dbError){
                        toast.error("Internal Server Error", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        this.setState({
                            ...this.state,
                            loading: false,
                            categories: []
                        });
                    } else {
                        toast.error("Problem fetching Categories", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        this.setState({
                            ...this.state,
                            loading: false,
                            categories: []
                        });
                    }
                }
           })
           .catch((err) => {
                toast.warn("Unable to Reach Server! Please Try Again!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.setState({
                    ...this.state,
                    loading: false
                });
           });
        }

        categoryHandler = (event) => {
           
                let id = event.target.value;

                if(event.target.value === '0'){

                    this.setState({
                        ...this.state,
                        category: {
                            ...this.state.category,
                            value: '',
                            valid: false,
                            invalid: false
                        },
                        subCategory:{
                            ...this.state.subCategory,
                            value: '',
                            valid: false,
                            invalid: false
                        },
                        subCategories:[]
                    })

                } else {

                    this.setState({
                        ...this.state,
                        category: {
                            ...this.state.category,
                            value: event.target.value,
                            valid: true,
                            invalid: false
                        },
                        submit:{
                            ...this.state.submit,
                            invalid: false,
                            Button: false
                        },
                        offerId:{
                            ...this.state.offerId,
                            value: '',
                            invalid: false
                        },
                        subCategory:{
                            ...this.state.subCategory,
                            value: ''
                        },
                        subCategories:[]
                        
                    });
                
                    axios.post("/support/consumer/subCategoryDetails", {id})
                    .then((res1) => {

                        console.log('tRACE',res1.data)
        
                        if(res1.data.data){
                            if(res1.data.subCat){
                                this.setState({
                                    ...this.state,
                                    subCategories: res1.data.sub_category,
                                    loading: false
                                });
                            } else {
                                this.setState({
                                    ...this.state,
                                    subCategories: [],
                                    loading: false
                                });
                            }
                        } else {
                            if(res1.data.dbError){
                                toast.error("Internal Server Error", {
                                    position: toast.POSITION.BOTTOM_CENTER
                                });
                                this.setState({
                                    ...this.state,
                                    loading: false
                                })
                            } else {
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });                     
                            }                    
                        }
                    })
                    .catch((err) => {
                        toast.warn("Unable to Reach Server! Please Try Again!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        this.setState({
                            ...this.state,
                            loading: false
                        }); 
                    });
                }
                
    }

        subCategoryHandler = (event) => {

            if(event.target.value === '0'){

                this.setState({
                    ...this.state,
                    subCategory: {
                        ...this.state.subCategory,
                        value: '',
                        valid: true,
                        invalid: false
                    }
                })

            } else {
    
                this.setState({
                    subCategory: {
                        ...this.state.subCategory,
                        value: event.target.value,
                        valid: true,
                        invalid: false
                    },
                    submit: {
                        ...this.state.submit,
                        invalid: false,
                        Button: false
                    },
                                  });
            }
        }

        
        showModalCancelHandlerv = () => {
            this.setState({
                ...this.state,
                showImageModal:false,
                images:[]
            });
        }
    
        submitButtonHandler = () => {


             if(this.state.category.value === "---select---" || this.state.category.invalid || this.state.category.value === '' || this.state.category.value === 0){
                this.setState({
                    submit: {
                        ...this.state.submit,
                        invalid: true 
                    },
                    category: {
                        ...this.state.category,
                        valid: false,
                        invalid: true
                    },
                    
                    subCategory: {
                        ...this.state.subCategory,
                        value: "---select---"
                    }
                });                
                return false;
            } else if(this.state.subCategory.value === "---select---" || this.state.subCategory.value === '' || this.state.subCategory.invalid || this.state.subCategory.value === 0){
                this.setState({
                    submit: {
                        ...this.state.submit,
                        invalid: true 
                    },
                    subCategory: {
                        ...this.state.subCategory,
                        valid: false,
                        invalid: true
                    }
                });                    
                // return toast.warn("Please select Sub-Category!", {
                //     position: toast.POSITION.BOTTOM_CENTER
                // });
            } 
            // else if(this.state.merchantName.value.length === 0 || this.state.merchantName.invalid){
            //     this.setState({
            //         submit: {
            //             ...this.state.submit,
            //             invalid: true
            //         },
            //         merchantName:{
            //             ...this.state.merchantName,
            //             valid: false,
            //             invalid: true
            //         }
            //     });

            //     return toast.warn("Please enter Merchant Name!", {
            //         position: toast.POSITION.BOTTOM_CENTER
            //     }); 
            // } 
            else {
                this.setState({
                    submit: {
                        ...this.state.submit,
                        invalid: false,
                        Button: true
                    },
                    category:{
                        ...this.state.category,
                        valid: true,
                        invalid: false
                    },
                    subCategory: {
                        ...this.state.subCategory,
                        valid: true,
                        invalid: false
                    },
                    showImageModal:true,
                   
                    loading: false
                });

                let body = {
                    sub_cat : this.state.subCategory.value,
                }
                axios.post("/support/imageData", body)
                .then((res) => {
                    // console.log("Sub-category and Date search", res);

                    if(res.data.data){
                        this.setState({
                            ...this.state,
                            images: res.data.result,
                            submit: {
                                ...this.state.submit,
                                valid: true,
                            },
                            loading2: false                         
                        }); 
                        
                        if(res.data.result.length > 1){

                            this.setState({
                                ...this.state,
                                state: true,
                                loading2:false
                            })

                        } else {

                            this.setState({
                                ...this.state,
                                state: false
                            })
                            
                            
                        }              
                    } else {
                        if(res.data.dbError){
                            this.setState({
                                submit:{
                                    ...this.state.submit,
                                    valid: false
                                },
                                loading: false
                            })
                            toast.error("Internal server error!", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                        } 
                            // console.log("No data found!");                            
                            this.setState({
                                ...this.state,
                                images: [],
                                submit: {
                                    ...this.state.submit,
                                    valid: false
                                },
                                loading: false
                            });
                        
                    }                   
                })
                .catch((err) => {
                    console.log("error", err);
                    toast.warn("Unable to Reach Server! Please Try Again!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                    this.setState({
                        submit:{
                            ...this.state.submit,
                            valid: false
                        },
                        loading: false
                    })
                });
            }
        }
     
     
      onKeyDown=(event)=>{
        if(event.key==='Enter')
        {
            event.preventDefault();
            event.stopPropagation();
            this.submitButtonHandler();
        }
        
    }

    
    
    render() {
             console.log("state",this.state);

             const rowStyle={
                textAlign:"initial",
                margin: 5
            }

        let categoryOptions = this.state.categories.map((option) => {
            // console.log("Category",option.category_name );
            return <option value={option.qf_service_category_prid} key={option.qf_service_category_prid}>{option.category_name} - {option.qf_service_category_prid}</option>;
        });

        let subCategoryOptions = this.state.subCategories.map((option) => {
            return <option value={option.qf_service_sub_category_prid} key={option.qf_service_sub_category_prid}>{option.service_sub_category_name} - {option.qf_service_sub_category_prid}</option> ;
        });


        let showImageModal=null;
    // if(this.state.showImage.length!==0&&this.state.subcatName.length!==0){
    //   let  subcatImgs=this.state.allImages.filter(item => item.image_type==='subcategory');

    let imgs=null;
    if(this.state.images.length!==0){

 
     imgs = this.state.images.map((option,index)=>{

  return <div key={index} style={{display:'inline-block'}}>
                                                
  <span>
  {/* <text style={MWeightSpan}>Images:</text> */} 
  <div style={{position:"absolute",marginTop:10,backgroundColor:'#17B1AE',color:'white', marginLeft:114}}>{option.QF_IMAGES_PRID}</div>
  <img  src={option.image_link} width={180}  height={150}  style={{padding:10}} />
   </span>  
  
  

</div>

     })
        

        
    }
    else{

        imgs="No images"

    }
        showImageModal = ( 
            <Modal isOpen={this.state.showImageModal} size='lg'>
                    <ModalHeader><text style={{fontWeight:"bold"}}>Images</text></ModalHeader>
                            <ModalBody>
                                <Card style={{marginTop: 10, backgroundColor: "#E1E1E1"}}>
                                    <CardBody >                          
                                          <BarLoader widthUnit={"%"} width={100} loading={this.state.loading2} color="#2C2165"/>
                                        <CardText>

                                            {/*  */}
                                            {imgs}
                                            
                                                                                       
                                        </CardText>
                                            
                                    </CardBody>
                                </Card>
                            </ModalBody>                   
                    <ModalFooter>
                          
                            <Button color="warning" onClick={this.showModalCancelHandlerv}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            )
    


        return (
            <div>
                <Container fluid>
            <div className="row justify-content-center" style={{ backgroundColor: '#EFEFEF'}}>
                <div className="col-3" >
                    <Form style={{width:350,marginTop:150}}>
                        
                            <Col>
                            <FormGroup row>
                            <Label style={{float:'left'}} for="Categories" ><text style={{fontFamily:'Muli'}}>Category</text> <span style={{color: 'red'}}>*</span></Label>
                            <Input  valid={this.state.category.valid ? true : false}
                                    invalid={this.state.category.invalid ? true : false}
                                    value={this.state.category.value ? this.state.category.value:''}
                                    onChange={this.categoryHandler} type="select"
                                    style={{marginRight:3,fontFamily: 'Muli',color: '#563951'}}>
                                   
                                <option value={0}>---Select---</option>
                                {categoryOptions}
                            </Input>
                            <FormFeedback><text style={{fontFamily:'Muli'}}>Category is Required</text></FormFeedback>
                        </FormGroup>
                        </Col>

                        <Col>
                        <FormGroup row>
                            <Label style={{float:'left'}} for="Subcategories" ><text style={{fontFamily:'Muli'}}>Subcategory</text> <span style={{color: 'red'}}>*</span></Label>
                            <Input  valid={this.state.subCategory.valid ? true : false}
                                    invalid={this.state.subCategory.invalid ? true : false}
                                    value={this.state.subCategory.value ? this.state.subCategory.value:''}
                                    onChange={this.subCategoryHandler} type="select" name="select" id="exampleSelect"
                                    style={{marginLeft:3,fontFamily: 'Muli',color: '#563951'}}>
                                <option value={0}>---Select---</option>
                                {subCategoryOptions}
                            </Input>
                            <FormFeedback><text style={{fontFamily:'Muli'}}>Subcategory is Required</text></FormFeedback>
                        </FormGroup>
                        </Col>
                        
                      
                       
                       
                        
                        
                        <FormGroup>
                            <Button onClick={this.submitButtonHandler} style={{backgroundColor:"#2C2165", color:'white'}} disabled={this.state.loading?true:false}><text style={{fontFamily:'Muli'}}>Submit</text></Button>
                        </FormGroup>
                        {this.state.showImageModal ? showImageModal : null}  

                        <ToastContainer />
                    </Form>
                    <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/>  
            </div>
            


            </div>
            </Container>
            </div>
        )
    }
} 

export default ImageData;