import React, { Component } from 'react';
import { Form, FormGroup, Label, Input, Button, Row, Col, Container, FormFeedback } from 'reactstrap';
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import Breadcrumb from '../Navbar/Breadcrumb'; 

import { connect } from "react-redux";

class FlagMerchant extends Component {
    state = {
        bId: this.props.location.state.id,
        supportCategory: {
            value: '--Select--',
            valid: false,
            invalid: false
        },
        severity: {
            value: 0,
            valid: false,
            invalid: false
        },
        description: '',
        resolutionComment: '',
        resValid: false,
        resInvalid: false,
        descValid: false,
        descInvalid: false,
        SupportPrid: this.props.id,
        active: this.props.location.state.active,
        active0: this.props.location.state.active0 ? this.props.location.state.active0 : null,
        active1: this.props.location.state.active1,
        active2: this.props.location.state.active2,
        dispute: false,
        createClicked: false,
        resolveClicked: false,
        DisputeVal: false
    }
    componentDidMount() {
    
         console.log('ver',this.props)
        let bId = this.state.bId
        axios.post("/support/booking/disputeCheck", { bId })
            .then((res) => {
                if (res.data.results1.length !== 0) {
                    if (res.data.results1[0].SERVICE_DISPUTE === 1) {
                        this.setState({
                            dispute: true
                        })
                    }
                    else {
                        this.setState({
                            dispute: false
                        })

                    }
                }
                else {
                    if (res.data.dbError === true) {
                        toast.error("Internal Server Error.", { position: toast.POSITION.BOTTOM_CENTER });

                    }
                }
            }).catch(err => {
                toast.warn("Unable to Reach Server! Please Try Again!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });

            })




        // console.log('proops', this.props)
    }


    supportCategoryHandler = (e) => {
        // console.log('support', e.target.value)
        if (e.target.value === '--Select--') {
            this.setState({
                supportCategory: {
                    ...this.state.supportCategory,
                    value: null,
                    valid: false,
                    //      invalid: true
                },

            })

        }

        else if (e.target.value === 'Dispute') {
            this.setState({
                supportCategory: {
                    ...this.state.supportCategory,
                    value: e.target.value,
                    valid: true, invalid: false
                    //      invalid: true
                },
                DisputeVal: true,

                submit: {
                    invalid: false
                }

            })

        }

        else {
            this.setState({
                supportCategory: {
                    ...this.state.supportCategory,
                    value: e.target.value,
                    valid: true, invalid: false
                }, submit: {
                    invalid: false
                }
            })


        }


    }
    severityHandler = (e) => {

        if (e.target.value === '0') {
            this.setState({
                severity: {
                    ...this.state.severity,
                    value: null,
                    valid: false,
                    //      invalid: true
                },

            })

        }

        else {
            this.setState({
                severity: {
                    ...this.state.severity,
                    value: e.target.value,
                    valid: true, invalid: false
                }, submit: {
                    invalid: false
                }
            })


        }
    }

    descriptionHandler = (event) => {

        if (event.target.value.length > 0) {

            this.setState({ description: event.target.value, descValid: true, descInvalid: false, resValid: true, resInvalid: false })

        }
        else {
            this.setState({ description: event.target.value })
        }

    }
    resolutionCommentHandler = (event) => {
        if (event.target.value.length > 0) {

            this.setState({ resolutionComment: event.target.value, resValid: true, resInvalid: false })

        }
        else {
            this.setState({ resolutionComment: event.target.value })
        }
    }
    createHandler = () => {
        if (this.state.supportCategory.value === null || this.state.supportCategory.invalid || this.state.supportCategory.value === '--Select--') {
            this.setState({
                supportCategory: {
                    ...this.state.supportCategory,
                    invalid: true,
                    valid: false
                },
                submit: {
                    ...this.state.submit,
                    invalid: true
                }
            })
            return false;
        }
        // return console.log(this.state.severity.value)

        if (this.state.severity.value === 0 || this.state.severity.value === null || this.state.severity.invalid || this.state.severity.value === '--Select--') {
            this.setState({
                severity: {
                    ...this.state.severity,
                    invalid: true,
                    valid: false
                },
                submit: {
                    ...this.state.submit,
                    invalid: true
                }
            })
            return false;
        }
        if (this.state.description.length === 0 || (this.state.description).trim() === '') {
            this.setState({ descInvalid: true, descValid: false })
            return false;
        }

        let url = this.props.location.state.url ? this.props.location.state.url : this.props.match.url;
        this.setState({

            createClicked: true
        })
        let ticketDetails = {
            bId: this.state.bId,
            merchEmail:this.props.location.state.merchEmail, 
            merchPersnelPrid:this.props.location.state.merchPersnelPrid,
            merchName:this.props.location.state.merchName,
            offerName:this.props.location.state.offerName,
            offerDate:this.props.location.state.offerDate,
            offerDateFrom:this.props.location.state.offerDateFrom,
            offerDateTo:this.props.location.state.offerDateTo,
            bookingDate:this.props.location.state.bookingDate,
            offercurrency:this.props.location.state.offercurrency,
            merchantPrid: this.props.location.state.offerId,
            merchantPrid1: this.props.location.state.serviceId,
            consumerPrid: this.props.location.state.consumerId,
            supportCategory: this.state.supportCategory.value,
            SupportPrid: this.state.SupportPrid,
            create: true,
            DisputeVal:this.state.DisputeVal,
            resolve: false,
            description: this.state.description,
            severity: this.state.severity.value,
            resolutionComment: this.state.resolutionComment ? this.state.resolutionComment : null,
            consumerName:this.props.location.state.consumerName,
            lang:this.props.location.state.lang,


        }
        // return console.log("request", ticketDetails);
        axios.post("/support/consumer/BookingIdSearch/CreateTicket", ticketDetails)
            .then((res) => {
                if (res.data.data) {

                    this.setState(
                        {
                            supportCategory: {
                                ...this.state.supportCategory,
                                value: '--Select--'
                            },
                            severity: {
                                ...this.state.severity,
                                value: 0
                            },
                            description: '',
                            resolutionComment: '',
                            createClicked: false

                        }
                    )
                    this.props.history.push({ pathname: url + '/BookingIdSearch', state: { booking_id: this.state.bId, url } });
                    return toast.success("Ticket Created Successfully", { position: toast.POSITION.BOTTOM_CENTER });

                }
                else {
                    if (res.data.dbError === true) {
                        this.setState({

                            createClicked: false
                        })
                        toast.error("Internal Server Error.", { position: toast.POSITION.BOTTOM_CENTER });

                    }
                    if (res.data.ncreated === true) {
                        this.setState({

                            createClicked: false
                        })
                        toast.warn("Creation Failed", { position: toast.POSITION.BOTTOM_CENTER });

                    }

                }
            }).catch(err => {
                this.setState({

                    createClicked: false
                })
                toast.warn("Unable to Reach Server! Please Try Again!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });

            })

    }

    resolveHandler = () => {
        if (this.state.supportCategory.value === null || this.state.supportCategory.invalid || this.state.supportCategory.value === '--Select--') {
            this.setState({
                supportCategory: {
                    ...this.state.supportCategory,
                    invalid: true,
                    valid: false
                },
                submit: {
                    ...this.state.submit,
                    invalid: true
                }
            })
            return false;
        }
        if (this.state.severity.value === 0 || this.state.severity.value === null || this.state.severity.invalid || this.state.severity.value === '--Select--') {
            this.setState({
                severity: {
                    ...this.state.severity,
                    invalid: true,
                    valid: false
                },
                submit: {
                    ...this.state.submit,
                    invalid: true
                }
            })
            return false;
        }

        if (this.state.description.length === 0 || (this.state.description).trim() === '') {
            this.setState({ descInvalid: true, descValid: false })
            return false;
        }
        if (this.state.resolutionComment.length === 0 || (this.state.resolutionComment).trim() === '') {

            this.setState({ resInvalid: true, resValid: false })
            return false;
        }

        let url = this.props.location.state.url ? this.props.location.state.url : this.props.match.url;
        this.setState({

            resolveClicked: true
        })
        let ticketDetails = {
            bId: this.state.bId,
            merchPersnelPrid:this.props.location.state.merchPersnelPrid,
            merchEmail:this.props.location.state.merchEmail,
            merchName:this.props.location.state.merchName,
            offerName:this.props.location.state.offerName,
            offerDate:this.props.location.state.offerDate,
            offerDateFrom:this.props.location.state.offerDateFrom,
            offerDateTo:this.props.location.state.offerDateTo,
            bookingDate:this.props.location.state.bookingDate,
            offercurrency:this.props.location.state.offercurrency,
            supportCategory: this.state.supportCategory.value,
            description: this.state.description,
            merchantPrid: this.props.location.state.offerId,
            merchantPrid1: this.props.location.state.serviceId,
            consumerPrid: this.props.location.state.consumerId,
            resolutionComment: this.state.resolutionComment,
            SupportPrid: this.state.SupportPrid,
            severity: this.state.severity.value,
            resolve: false,
            create: false,
            DisputeVal:this.state.DisputeVal,
            directResolve: true,
            consumerName:this.props.location.state.consumerName,
            lang:this.props.location.state.lang

        }
        // console.log("request", ticketDetails);
        axios.post("/support/consumer/BookingIdSearch/CreateTicket", ticketDetails)
            .then((res) => {
                if (res.data.data) {
                    this.setState(
                        {
                            supportCategory: 'type1',
                            description: '',
                            resolutionComment: '',
                            resolveClicked: false

                        }
                    )
                    this.props.history.push({ pathname: url + '/BookingIdSearch', state: { booking_id: this.state.bId, url } });
                    return toast.success("Ticket Resolved Successfully", { position: toast.POSITION.BOTTOM_CENTER });
                }
                else {
                    if (res.data.dbError === true) {
                        this.setState({
                            resolveClicked: false

                        })
                        toast.error("Internal Server Error.", { position: toast.POSITION.BOTTOM_CENTER });

                    }
                    if (res.data.nresolved === true) {
                        this.setState({
                            resolveClicked: false

                        })
                        toast.warn("Not Resolved", { position: toast.POSITION.BOTTOM_CENTER });
                    }

                }
            }).catch(err => {
                this.setState({
                    resolveClicked: false

                })
                toast.warn("Unable to Reach Server! Please Try Again!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });

            })


    }



    render() {

        console.log('state',this.state)

        const fWeightSpan={
            fontWeight:"500",
            marginRight: 5,
            fontFamily: 'Muli',
            color: '#868686'
        }
        const fontText1 = {
            fontWeight:'bold',
            fontFamily: 'Muli',
            color: '#563951'                        
        }

        return (
            <div >
                <Breadcrumb active={this.state.active} active0={this.state.active0} active1={this.state.active1} active2={this.state.active2} active3={'Create Ticket'} />
                <Container fluid >
                    <div className="row justify-content-center" style={{ backgroundColor: '#EFEFEF' }}>
                        <div className="col-3" >
                            <Form>

                                {/* <FormGroup row>
                            <Label>Offer-Id</Label>
                            <Input type="text" value="Offer123456789" disabled />
                    </FormGroup>   */}
                                <Row>
                                    <Col>
                                        <FormGroup row>
                                            <Label><text style={fWeightSpan}> Booking-Id </text>< span style={{ color: 'red' }}>*</span></Label>
                                            <Input type="text" style={{fontFamily: 'Muli',color: '#563951'}} value={this.state.bId} disabled />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup row >
                                            <Label for="supportCategory" ><text style={fWeightSpan}>Category </text><span style={{ color: 'red' }}>*</span></Label>
                                            {this.state.dispute === true ? <Input type="select" name="supportCategory"
                                                valid={this.state.supportCategory.valid ? true : false}
                                                invalid={this.state.supportCategory.invalid ? true : false}
                                                value={this.state.supportCategory.value}
                                                style={{fontFamily: 'Muli',color: '#563951'}}
                                                onChange={this.supportCategoryHandler}>
                                                <option value='--Select--'>--Select--</option>
                                                {/* <option value='Dispute'>Dispute</option> */}
                                                <option value='Emergency'>Emergency</option>
                                                <option value='type3'>type3</option>
                                                <option value='type4'>type4</option>
                                                <option value='type5'>type5</option>
                                            </Input> : <Input type="select" name="supportCategory"
                                                valid={this.state.supportCategory.valid ? true : false}
                                                invalid={this.state.supportCategory.invalid ? true : false}
                                                value={this.state.supportCategory.value}
                                                style={{fontFamily: 'Muli',color: '#563951'}}
                                                onChange={this.supportCategoryHandler}>
                                                    <option value='--Select--'>--Select--</option>
                                                    <option value='Dispute'>Dispute</option>
                                                    <option value='Emergency'>Emergency</option>
                                                    <option value='Consumer refund request'>Customer refund request</option>
                                                    <option value='type4'>type4</option>
                                                    <option value='type5'>type5</option>
                                                </Input>}
                                            <FormFeedback><text style={fontText1}> Select Category</text></FormFeedback>

                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup row style={{ marginLeft: 4 }}>
                                            <Label for="supportCategory" ><text style={fWeightSpan}> Severity</text><span style={{ color: 'red' }}>*</span></Label>
                                            <Input type="select" name="Severity"
                                                valid={this.state.severity.valid ? true : false}
                                                invalid={this.state.severity.invalid ? true : false}
                                                value={this.state.severity.value}
                                                style={{fontFamily: 'Muli',color: '#563951'}}
                                                onChange={this.severityHandler}>
                                                <option value={0}>--Select--</option>
                                                <option value={1}>Very Low</option>
                                                <option value={2}>Low</option>
                                                <option value={3}>Medium</option>
                                                <option value={4}>High</option>
                                                <option value={5}>Very High</option>
                                            </Input>
                                            <FormFeedback><text style={fontText1}> Select Severity</text></FormFeedback>

                                        </FormGroup>
                                    </Col>
                                </Row>

                                <FormGroup row>
                                    <Label for="Description" ><text style={fWeightSpan}> Description/Comment </text><span style={{ color: 'red' }}>*</span></Label>
                                    <Input type="textarea"
                                        placeholder="Type Description or Comment"
                                        maxLength={1000}
                                        value={this.state.description}
                                        invalid={this.state.descInvalid ? true : false}
                                        valid={this.state.descValid ? true : false}
                                        onChange={this.descriptionHandler}
                                        style={{ height: 100,fontFamily: 'Muli',color: '#563951' }} />
                                    <FormFeedback><text style={fontText1}> Description shouldn't be empty</text></FormFeedback>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="Resolution"><text style={fWeightSpan}> Resolution Provided</text></Label>
                                    <Input type="textarea"
                                        placeholder="Please type a Resolution"
                                        maxLength={1000}
                                        onChange={this.resolutionCommentHandler}
                                        value={this.state.resolutionComment}
                                        invalid={this.state.resInvalid ? true : false}
                                        valid={this.state.resValid ? true : false}
                                        style={{ height: 100,fontFamily: 'Muli',color: '#563951' }} />
                                    <FormFeedback><text style={fontText1}> Resolution shouldn't be empty</text></FormFeedback>

                                </FormGroup>
                                <FormGroup>
                                    <Button style={{ margin: 5, backgroundColor: '#2C2165' }} onClick={this.createHandler} disabled={this.state.createClicked} ><text style={{fontFamily:'Muli'}}> Create</text></Button>
                                    <Button style={{ margin: 25, backgroundColor: '#612147' }} onClick={this.resolveHandler} disabled={this.state.resolveClicked} ><text style={{fontFamily:'Muli'}}> Resolve</text></Button>
                                    <Button style={{ backgroundColor: "#F49F3D" }} onClick={this.props.history.goBack} ><text style={{fontFamily:'Muli'}}> Go Back</text></Button>


                                </FormGroup>
                            </Form>

                            <ToastContainer />
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        id: state.id
    }
}

export default connect(mapStateToProps)(FlagMerchant);