import React, { Component } from 'react';
import { Form, FormGroup, FormFeedback, Label, Input, Button, Row, Col, Container } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import { Redirect } from 'react-router-dom'
import DatePicker from 'react-datepicker'; 
import moment from 'moment';
import axios from 'axios';
import '../../../../node_modules/react-datepicker/dist/react-datepicker.css';
import '../../../../node_modules/react-datepicker/dist/react-datepicker-cssmodules.css';
import {BarLoader} from 'react-spinners';
import Breadcrumb from '../Breadcrumb';
import {connect} from "react-redux";
import {IoIosRefresh} from 'react-icons/io';



class supportCreateCoupon extends Component {
    
        state = {
            category : {
                value: '',
                valid: false,
                invalid: false
            },
            subCategory : {
                value: '',
                valid: false,
                invalid: false
            },
            city:{
                value: '',
                valid: false,
                invalid: false
            },
            couponCode: {
                value: '',
                valid: false,
                invalid: false
            },
            couponMinAmt: {
                value: '',
                valid: false,
                invalid: false
            },
            couponValue : {
                value:'',
                valid: false,
                invalid: false
            },
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().add(60, 'days').format('YYYY-MM-DD'),
                 // startDate: moment().set({'year': 2018, 'month': 6}).format('YYYY-MM-DD'),
                //endDate:  moment().set({'year': 2018, 'month': 9}).format('YYYY-MM-DD'),   
            submit :{
                invalid: false
            },
            couponPrice : {
                value: '',
                valid: false,
                invalid: false
            },
            country:this.props.location.state.merchantCountry,
            categories: [],
            subCategoriess:[],
            cities: [],
            loading: false,
            id:this.props.location.state.id,
            active: this.props.location.state.active,
            active0: this.props.location.state.active0 ? this.props.location.state.active0 : null,
            active1: this.props.location.state.active1,
            active2: this.props.location.state.active2,
            netPrice:this.props.location.state.netPrice,
            name:this.props.location.state.name,
            email:this.props.location.state.email,
            amountSign:this.props.location.state.amountSign,
            cClicked:false,
            length:false
        };


        componentDidMount() {
            // console.log('haiaiiaia',this.state.netPrice);
        //   console.log('country',this.state.country);
            var text = "";
            var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

            for (var i = 0; i < 8; i++){
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            }
            this.setState({
                loading: true,
                couponCode:{
                    ...this.state.couponCode,
                    value: text
                },
                // couponValue:{
                //     ...this.state.couponValue,
                //     value: 'B-'+this.state.id+','
                // }
            });

            axios.post("/support/consumer/categoryDetails")
            .then((res) => {
                if(res.data.result){
                    if(res.data.category !== null){
                        this.setState({
                            ...this.state,
                            categories: res.data.category,
                            loading: false
                        });                    
                    } else {
                        this.setState({
                            ...this.state,
                            loading: false
                        }); 
                    }
                } else {
                    if(res.data.dbError){
                        toast.error("Internal Server Error", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        this.setState({
                            ...this.state,
                            loading: false
                        });
                    } else {
                        this.setState({
                            ...this.state,
                            loading: false
                        });
                    }
                }
           })
           .catch((err) => {
               console.log("Error",err);
                toast.error("Unable to reach server please try again!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.setState({
                    ...this.state,
                    loading: false
                });
           });

          
       
           

           axios.get("/support/consumer/city")
           .then((res) => {
            //    console.log("City", res.data);
               if(res.data.data){
                    if(res.data.result !== null){
                        this.setState({
                            ...this.state,
                            cities: res.data.result,
                            loading: false
                        });
                    } else {
                        this.setState({
                            ...this.state,
                            loading: false
                        });
                    }                
               } else {
                if(res.data.dbError){
                    toast.error("Internal Server Error", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                } else {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                }
               }
               
           })
           .catch((err) => {
                toast.error("Unable to reach server please try again!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.setState({
                    ...this.state,
                    loading: false
                });
           });
        }


                

        couponDescriptionHandler = (event) => {
            this.setState({
                    couponValue : {
                        ...this.state.couponValue,
                        value: event.target.value,
                        valid: true,
                        invalid: false
                    },
                    submit: {
                        ...this.state.submit,
                        invalid: false
                    }
                });
            } 
    
        submitButtonHandler = () => {

        //    console.log('CouponObject.');

            // return console.log('state',this.state)
            this.setState({
                ...this.state,
                loading: true
            });
           
           if(this.state.couponPrice.value.length===0){
                this.setState({
                    ...this.state,
                    couponPrice:{
                        ...this.state.couponPrice,
                        valid: false,
                        invalid: true
                    },
                    submit:{
                        ...this.state.submit,
                        invalid: true
                    },
                    loading: false
                });
                return false;
            } 
            if(Number(this.state.couponPrice.value)===0){

                this.setState({
                    ...this.state,
                    couponPrice:{
                        ...this.state.couponPrice,
                        valid: false,
                        invalid: true
                    },
                    submit:{
                        ...this.state.submit,
                        invalid: true
                    },
                    loading: false
                });
                return toast.error("Coupon Discount Amount cannot be Zero ", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            } 
            else if(this.state.couponMinAmt.value.length!==0&&Number(this.state.couponPrice.value)>=Number(this.state.couponMinAmt.value)){
                this.setState({
                    ...this.state,
                    couponPrice:{
                        ...this.state.couponPrice,
                        valid: false,
                        invalid: true
                    },
                    submit:{
                        ...this.state.submit,
                        invalid: true
                    },
                    loading: false
                });
                return toast.error("Coupon Discount Amount should be less than Coupon Minimum Amount ", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            } 
          
           
            else if(this.state.couponPrice.value>this.state.netPrice)
            {
                   this.setState({
                        ...this.state,
                        couponPrice:{
                            ...this.state.couponPrice,
                            valid: false,
                            invalid: true,
                        },
                        submit:{
                            ...this.state.submit,
                            invalid: true
                        }
                    });
                return toast.error(`Coupon Discount Amount is greater than $${this.state.netPrice}`, {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            }
        
           
            else {
                this.setState({
                    couponCode:{
                        ...this.state.couponCode,
                        valid: true,
                        invalid: false
                    },
                    couponPrice:{
                        ...this.state.couponPrice,
                        valid: true,
                        invalid: false
                    },
                   
                    submit:{
                        ...this.state.submit,
                        invalid: false
                    }
                })

                let CouponObject = {
                    cat_prid: this.state.category.value.length!==0? this.state.category.value : null,
                    sub_cat_prid:this.state.subCategory.value.length!==0? this.state.subCategory.value : null,
                    city: this.state.city.value.length !==0? this.state.city.value : null,
                    coupon_date_valid_from:this.state.startDate,
                    coupon_date_valid_to: this.state.endDate,
                    coupon_price: this.state.couponPrice.value.length!==0?this.state.couponPrice.value:null,
                    max_no_of_coupons: 1,
                    coupon_min_amt: this.state.couponMinAmt.value.length!==0? this.state.couponMinAmt.value : null ,
                    coupon_code: this.state.couponCode.value ,
                    coupon_desc:this.state.couponValue.value.length !== 0 ? 'B-'+this.state.id+', '+this.state.couponValue.value : 'B-'+this.state.id,
                    bookId:this.state.id,
                    Suprid:this.props.id,
                    name:this.state.name,
                    email:this.state.email,
                    amountSign:this.state.amountSign,
                    country:this.state.country
                }


            let url=this.props.location.state.url ? this.props.location.state.url : this.props.match.url;

                 this.setState({
                     cClicked:true
                 })

                
                axios.post("/supportCreatecoupon", { CouponObject })
                .then((res) => {
                    if(res.data.data){
                    
                        // console.log("Response", res)
                        this.setState({
                            ...this.state,
                            push:true,
                            cClicked:false,
                            loading: false
                        });
                        
                        this.props.history.push({ 
                            pathname:url+"/BookingIdSearch",
                            state: {
                                booking_id: this.state.id,
                                url
                            }
                        });
                        return toast.success("Coupon is successfully created",{
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    } else if(!res.data.data){
                        if(res.data.dbError){
                            
                            toast.error("Internal Server Error", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            this.setState({
                                ...this.state,
                                cClicked:false,
                                loading: false
                            });
                        } else if(res.data.available){

                            toast.warn("Coupon Code is Already Exist! Please try with another one", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            this.setState({
                                ...this.state,
                                cClicked:false,
                                loading: false
                            });
                        } else {

                            toast.error("Coupon not created! Please try again", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                        }
                    }
                })
                .catch((err) => {
                    console.log(err)
                    toast.error("Unable to reach server please try again!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.setState({
                        ...this.state,
                        cClicked:false,
                        loading: false
                    });                            
                });
            }
        }
        
     
   
     

   

    couponPriceHandler = (event) => {

        // return false;

        let cNumber=Number(event.target.value);
        let price=this.state.netPrice;
        let k =/^[0-9]+\.?[0-9]*$/;
        if(event.target.value==='' ||cNumber <= price&&k.test(event.target.value)) {

            console.log('1');
            this.setState({
                ...this.state,
                couponPrice: {
                    ...this.state.couponPrice,
                    value: event.target.value,
                    valid: true,
                    invalid: false
                },
                submit:{
                    ...this.state.submit,
                    invalid: false
                },
                length: false         
            });
        let value = event.target.value.split(".");
       console.log('ZERO',value[0],value[1]);

        // if(value.length > 2 ){
        //     return this.setState({
        //         couponPrice: {
        //             ...this.state.couponPrice,
        //             valid: false,
        //             invalid: true
        //         }
                
        //     });
        // }
         if(value[0]){

            console.log('2');

            if(value[0] === "00" ){
            console.log('3');


                this.setState({
                ...this.state,
                couponPrice: {
                    ...this.state.couponPrice,
                    value: "",
                    valid: true,
                    invalid: false
                }
            
            })
            }

            if(value[0].length===2 ){

            console.log('4');

                this.setState({
                ...this.state,
                couponPrice: {
                    ...this.state.couponPrice,
                    value: event.target.value.replace(/^0+/, ''),
                    valid: true,
                    invalid: false
                }
            
            })
            }

         }
        

        if(value[1]){
            console.log('5');
            if(value[1]&&(value[0]<=cNumber)){


                console.log('DOM')
             }

            if(value[1].length === 2&&cNumber<price){


                


                console.log(value[0]);
                console.log('66',value[1]);

                // console.log("Decimal is 2")
                return this.setState({
                    ...this.state,
                    length: false,
                    max: 2,
                    couponPrice: {
                        ...this.state.couponPrice,
                        value: event.target.value,
                        valid: true,
                        invalid: false
                    }
                });
            }
            else{
                
                // console.log('7');

                if(value[1].length > 2){

                // console.log('7009');


                    return this.setState({
                        ...this.state,
                        length: false,
                        max: 2,
                        couponPrice: {
                            ...this.state.couponPrice,
                            // value: event.target.value,
                            valid: true,
                            invalid: false
                        }
                    });


                }
                else{

                    return this.setState({
                        ...this.state,
                        length: false,
                        max: 2,
                        couponPrice: {
                            ...this.state.couponPrice,
                            value: event.target.value,
                            valid: true,
                            invalid: false
                        }
                    });


                }

                

               


            }
        } 
        if(this.state.couponMinAmt.value.length !== 0){
            console.log('8');


            if(value[0]){
            console.log('9');

                if(value[0] === "00" ){
                    console.log('10');
    
                    this.setState({
                    ...this.state,
                    couponPrice: {
                        ...this.state.couponPrice,
                        value: "",
                        valid: true,
                        invalid: false
                    }
                
                })
                }
                else if(value[0].length===2 ){
            console.log('11');


                    this.setState({
                    ...this.state,
                    couponPrice: {
                        ...this.state.couponPrice,
                        value: event.target.value.replace(/^0+/, ''),
                        valid: true,
                        invalid: false
                    },
                    max: 2,
                    length:false
                
                })
                }
               else if(Number(this.state.couponMinAmt.value) <= Number(event.target.value)){

                console.log('laregere')
                    this.setState({
                        ...this.state,
                        couponPrice:{
                            ...this.state.couponPrice.value,
                            value: event.target.value,
                            // valid: false,
                            // invalid: true
                        },
                      
                        // submit:{
                        //     invalid :true
                        // }
                 
                      })
                      
            
                } 
           //newly added
                else{

            console.log('19');

                    this.setState({
                        ...this.state,
    
                        couponPrice:{
                            ...this.state.couponPrice.value,
                            value: event.target.value,
                            valid: true,
                            invalid: false
                        },
    
                    couponMinAmt:{
                        ...this.state.couponMinAmt,
                         valid: true,
                        invalid: false
                    },
                    submit:{
                        invalid :false
                    }

                })
    
    
                }
    
    
             }
    



            // console.log("Coupon Min Amount is present")
            
         }
       

    }
    }

    

 

    couponMinAmtHandler = (event) => {
        
        let cNumber=Number(event.target.value);
        let k =/^[0-9]+\.?[0-9]*$/;
        if(event.target.value==='' || cNumber <= 9999.99&&k.test(event.target.value)) {
            this.setState({
                ...this.state,
                 couponMinAmt:{
                    ...this.state.couponMinAmt,
                    value: event.target.value, 
                    valid: true,
                   invalid: false
                },
                couponPrice: {
                    ...this.state.couponPrice,
                    valid: true,
                    invalid: false
                },
                submit:{
                    ...this.state.submit,
                    invalid: true
                }  ,
                length1:false         
            });
        let value = event.target.value.split(".");
        // if(value.length > 2 ){
        //     return this.setState({
        //         couponMinAmt:{
        //             ...this.state.couponMinAmt,
        //             valid: false,
        //            invalid: true
        //         }     
        //     });
        // }
        if(value[0]){
            if(value[0] === "00" ){

            console.log('2');


                this.setState({
                ...this.state,
                 couponMinAmt:{
                    ...this.state.couponMinAmt,
                    value: "", 
                    valid: true,
                   invalid: false
                }
            

                
            })
            }
             if(value[0].length===2 ){

            console.log('3');


                this.setState({
                ...this.state,
                // couponPrice: {
                //     ...this.state.couponPrice,
                //     value: event.target.value.replace(/^0+/, ''),
                //     valid: true,
                //     invalid: false
                // }
                couponMinAmt:{
                    ...this.state.couponMinAmt,
                    value: event.target.value.replace(/^0+/, ''),                    
                    valid: true,
                   invalid: false
                }
            
            })
            }


         }
        if(value[1]){

            console.log('5');

            if(value[1].length === 2 ){
            console.log('6',value[1]);

                // console.log("Decimal is 2")
                return this.setState({
                    ...this.state,
                    length1: true,
                    max1: 2,
                    couponMinAmt: {
                        ...this.state.couponMinAmt,
                        value: event.target.value,
                        valid: true,
                        invalid: false
                    }
                });
            }
            else{

            console.log('78');


                return this.setState({
                    ...this.state,
                    length1: false,
                    max1: 2,
                    couponMinAmt: {
                        ...this.state.couponMinAmt,
                        value: event.target.value,
                        valid: true,
                        invalid: false
                    }
                });


            }
        } 
        else if(this.state.couponPrice.value.length !== 0){

            console.log('8');



             if(event.target.value===''){

            console.log('9');


                this.setState({
                    ...this.state,
                    couponMinAmt:{
                        ...this.state.couponMinAmt.value,
                        value: event.target.value,
                        valid: true,
                        invalid: false
                    },

                    couponPrice: {
                        ...this.state.couponPrice,
                        valid: true,
                        invalid: false
                    },

                    submit:{
                        invalid :false
                    }
             
                  })

             }

             if(value[0]){

            console.log('10');

                if(value[0] === "00" ){
            console.log('11');

    
                    this.setState({
                    ...this.state,
                    couponMinAmt: {
                        ...this.state.couponMinAmt,
                        value: "",
                        valid: true,
                        invalid: false
                    }
                
                })
                }
            }

            // console.log("Coupon Min Amount is present")
            else if(Number(this.state.couponPrice.value) >= Number(event.target.value)){
             console.log('inside');


                this.setState({
                    ...this.state,
                    couponMinAmt:{
                        ...this.state.couponMinAmt.value,
                        value: event.target.value,
                        valid: false,
                        // invalid: true
                    },
                    submit:{
                        invalid :true
                    }
             
                  })
                  
        
            } else {

            console.log('14');

                this.setState({
                    ...this.state,
                    couponMinAmt: {
                        ...this.state.couponMinAmt,
                          value: event.target.value,
                        valid: true,
                        invalid: false
                    },
                    couponPrice: {
                        ...this.state.couponPrice,
                        valid: true,
                        invalid: false
                    },
                    submit:{
                        ...this.state.submit,
                        invalid: false
                    },
                    length1: false           
                });      
            }
         } 
        }         
     }

    categoryHandler = (event) => {
        let id = event.target.value;
        this.setState({
            ...this.state,
            category:{
                ...this.state.category,
                value :  event.target.value
            },
            subCategory:{
                ...this.state.subCategory,
                value:  ""
            }
            // loading: true
        });
        if(event.target.value !== "-1"){
        axios.post("/support/consumer/subCategoryDetails", {id})
            .then((res1) => {
                // console.log("Response", res1.data.cities);
                if(res1.data.data){
                    if(res1.data.city && res1.data.subCat){
                        this.setState({
                            ...this.state,
                            subCategoriess: res1.data.sub_category,
                            cities: res1.data.cities,
                            loading: false
                        });
                    } else if(res1.data.city && !res1.data.subCat){
                        this.setState({
                            ...this.state,
                            subCategoriess: [],
                            cities: res1.data.cities,
                            loading: false
                        });
                    } else if(!res1.data.city && res1.data.subCat){
                        this.setState({
                            ...this.state,
                            subCategoriess: res1.data.sub_category,
                            cities: [],
                            loading: false
                        });
                    } else {
                        this.setState({
                            ...this.state,
                            subCategoriess: [],
                            cities: [],
                            loading: false
                        });
                    }
                } else {
                    if(res1.data.dbError){
                        toast.error("Internal Server Error", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        this.setState({
                            ...this.state,
                            loading: false
                        })
                    } else {
                        this.setState({
                            ...this.state,
                            loading: false
                        });                     
                    }                    
                }
            })
            .catch((err) => {
                toast.error("Unable to reach server please try again!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.setState({
                    ...this.state,
                    loading: false
                }); 
            });
        } else if(event.target.value === "-1" ){
            this.setState({
                ...this.state,
                subCategoriess:[],
                category:{
                    ...this.state.category,
                    value:""
                },
                subCategory:{
                    ...this.state.subCategory,
                    value:""
                }
                
            });
            
            axios.get("/support/consumer/city")
           .then((res) => {
            //    console.log("City", res.data.data);
               if(res.data.data){
                    if(res.data.result !== null){
                        this.setState({
                            ...this.state,
                            cities: res.data.result,
                            loading: false
                        });
                    } else {
                        this.setState({
                            ...this.state,
                            loading: false
                        });
                    }                
               } else {
                if(res.data.dbError){
                    toast.error("Internal Server Error", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                } else {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                }
               }
               
           })
           .catch((err) => {
                toast.error("Unable to reach server please try again!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.setState({
                    ...this.state,
                    loading: false
                });
           });
        }

        
    }

    subCategoryHandler = (event) => {
        let id = event.target.value;
        this.setState({
            ...this.state,
            // loading: true,
            subCategory:{
                ...this.state.subCategory,
                value:  event.target.value,
            }
        });

        if(event.target.value !== "-1"){
        axios.post("/support/consumer/subCatCity", {id})
            .then((res1) => {

                if(res1.data.data){
                    if(res1.data.city){
                        this.setState({
                            ...this.state,
                            cities: res1.data.cities,
                            loading: false
                        });
                    } else {
                        this.setState({
                            ...this.state,
                            cities: [],
                            loading: false
                        });
                    }
                } else {
                    if(res1.data.dbError){
                        toast.error("Internal Server Error", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        this.setState({
                            ...this.state,
                            loading: false
                        })
                    } else {
                        this.setState({
                            ...this.state,
                            loading: false
                        });                     
                    }                    
                }
            })
            .catch((err) => {
                toast.error("Unable to reach server please try again!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.setState({
                    ...this.state,
                    loading: false
                }); 
            });
        }
        else if(event.target.value === "-1" ){
            this.setState({
                ...this.state,
                subCategory:{
                    ...this.state.subCategory,
                    value:"",
                }
                
            }); 
        }
        
        else {
            id= this.state.category.value;
            axios.post("/support/consumer/subCategoryDetails", {id})
            .then((res1) => {
                // console.log("Response", res1.data.cities);
                if(res1.data.data){
                    if(res1.data.city && res1.data.subCat){
                        this.setState({
                            ...this.state,
                            subCategoriess: res1.data.sub_category,
                            cities: res1.data.cities,
                            loading: false
                        });
                    } else if(res1.data.city && !res1.data.subCat){
                        this.setState({
                            ...this.state,
                            subCategoriess: [],
                            cities: res1.data.cities,
                            loading: false
                        });
                    } else if(!res1.data.city && res1.data.subCat){
                        this.setState({ 
                            ...this.state,
                            subCategoriess: res1.data.sub_category,
                            cities: [],
                            loading: false
                        });
                    } else {
                        this.setState({
                            ...this.state,
                            subCategoriess: [],
                            cities: [],
                            loading: false
                        });
                    }
                } else {
                    if(res1.data.dbError){
                        toast.error("Internal Server Error", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        this.setState({
                            ...this.state,
                            loading: false
                        })
                    } else {
                        this.setState({
                            ...this.state,
                            loading: false
                        });                     
                    }                    
                }
            })
            .catch((err) => {
                toast.error("Unable to reach server please try again!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.setState({
                    ...this.state,
                    loading: false
                }); 
            });                      
        }
    }

    cityHandler = (event) => {
     console.log("City Prid", event.target.value);
    if(event.target.value!="-1"){

        this.setState({
            city:{
                ...this.state.city,
                value : event.target.value,
                valid: true,
                invalid: false
            } 
        })
    }
    else{
     console.log("else", event.target.value);

        this.setState({
            city:{
                ...this.state.city,
                value : '',
                valid: true,
                invalid: false
            } 


        })
     console.log("else", this.state.city.value);



    }

        
    }
    
    regenerateCoupon=()=>{
        
        var text = "";
            var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

            for (var i = 0; i < 8; i++){
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            }
            this.setState({
                
                couponCode:{
                    ...this.state.couponCode,
                    value: text
                }
            });
    }
    
    render() {
        // console.log('state in create coupon',this.state.amountSign);
        // console.log('Props',this.props);]
        console.log('thsi',this.state)
    
        const fWeightSpan={
            fontWeight:"400",
            fontFamily: 'Muli',
            color: '#868686'
        }
        const fontText1 = {
            fontWeight:'bold',
            fontFamily: 'Muli',
            color: '#563951'                        
        }
       

        let categoryOptions = null;
        // let catId = null;
        // let catName = null;
        // let catEx = null;
        if(this.state.categories.length !== 0){
            categoryOptions = this.state.categories.map((option) => {
                return <option  value={option.qf_service_category_prid} key={option.qf_service_category_prid}>{option.category_name}</option>;
            });
        }
        // if(this.props.location.state.catPrid){
        //     catId = this.state.categories.filter((cat) => {
        //         return cat.qf_service_category_prid === this.props.location.state.catPrid
        //     })
        //     catName = catId.map((option) => {
        //         return <option  value={option.qf_service_category_prid} key={option.qf_service_category_prid}>{option.category_name}</option>;
        //     }) 

        //     catEx = this.state.categories.filter((cat) => {
        //         return cat.qf_service_category_prid !== this.props.location.state.catPrid
        //     })

        //     categoryOptions = catEx.map((option) => {
        //         return <option  value={option.qf_service_category_prid} key={option.qf_service_category_prid}>{option.category_name}</option>;
        //     });
        // } else {
        //     catName = <option value='-1'>--select--</option>
        // }



        let subCategoryOptions = null;
        // let subCatId = null;
        // let subCatName = null;
        // let subCatEx = null;
        if(this.state.subCategoriess.length !== 0 ){
            subCategoryOptions = this.state.subCategoriess.map((option) => {
                return <option value={option.qf_service_sub_category_prid} key={option.qf_service_sub_category_prid}>{option.service_sub_category_name}</option>;
            });
        }
        let counrtyCities=null;
        if(this.state.cities.length !== 0){

        if(this.state.country==='United States'){
            counrtyCities= this.state.cities.filter(city=>{
             return city.COUNTRY_NAME==='United States'
         })     
        }
        else{
            counrtyCities= this.state.cities.filter(city=>{
                return city.COUNTRY_NAME==='India'
            })

        }
    }

         
         let allCities = null;
         if(this.state.cities.length !== 0){
            allCities = counrtyCities.map((city) => {
            return <option value={city.QF_CITY_PRID} key={city.QF_CITY_PRID}>{city.CITY_NAME}{', '}{city.STATE_PROVINCE_NAME}{', '}{city.COUNTRY_NAME}</option>
            });
         

        }
                
       
        return (
            <div> 
                <Breadcrumb active={this.state.active} active0={this.state.active0} active1={this.state.active1} active2={this.state.active2} active3={'Create Coupon'}/>  
            <Container fluid>
            <div className="row justify-content-center" style={{ backgroundColor: '#EFEFEF'}}>
            <div className="col-4" >
                <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/>         
                <Form style={{width: 450}}>
               
                    <FormGroup row>                            
                        <Label for="couponCode"><text style={fWeightSpan}> Booking Id -</text> <span style={fontText1}>  {this.state.id}</span> </Label>                       
                    </FormGroup>
                      
                    
                    <Row>
                        <Col>
                            <FormGroup row>
                                <Label for="Categories" ><text style={fWeightSpan}>Category</text> </Label>
                                <Input onChange={this.categoryHandler} type="select">
                                    <option value='-1'>---Select---</option>
                                    {/* {catName} */}
                                    {categoryOptions}
                                
                                </Input>
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <Label style={{marginRight: 100}} for="Subcategories" ><text style={fWeightSpan}>Subcategory</text></Label>
                                <Input onChange={this.subCategoryHandler} type="select" name="select" id="exampleSelect">
                                    <option value='-1'>---Select---</option>
                                    {/* {subCatName} */}
                                    {subCategoryOptions}
                                
                                </Input>
                            </FormGroup>
                        </Col>
                        
                    </Row>
                    <Row>
                    
                    <Col >
                            <FormGroup row>
                                <Label style={{marginRight: 180}} for="city" ><text style={fWeightSpan}>City</text></Label>
                                <Input onChange={this.cityHandler} type="select" name="select" id="exampleSelect">
                                    <option value='-1'>---Select---</option>
                                    {allCities}
                                
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col >
                            <FormGroup >
                                <Label style={{marginRight:150}} for="country"><text style={fWeightSpan}>Country </text> </Label>
                                <Input  
                                        // style={{textTransform: 'uppercase', width:'90%'}}
                                        value={this.state.country}
                                        style={{fontFamily:'Muli', backgroundColor:'white'}}
                                        readOnly/>
                            </FormGroup>
                        </Col>
                        </Row>
                    <Row>
                        <Col>
                            <FormGroup row>
                                <Label for="couponCode"><text style={fWeightSpan}>Coupon Code </text><span style={{color: 'red'}}>*</span> </Label>
                                <Input  valid={this.state.couponCode.valid ? true : false}
                                        invalid={this.state.couponCode.invalid ? true : false}
                                        placeholder="Please enter a Coupon Code"
                                        style={{textTransform: 'uppercase', width:'90%', fontFamily:'Muli', backgroundColor:'white'}}
                                        value={this.state.couponCode.value}
                                        maxLength={8} 
                                        readOnly/>
                                <IoIosRefresh style={{margin:10, backgroundColor:'#2C2165', color:'white'}} size={20} onClick={this.regenerateCoupon} />
                            </FormGroup>
                        </Col>
                                                       
                          
                    </Row>     
                            <FormGroup row>
                                <Label for="couponValue"><text style={fWeightSpan}>Coupon Description  </text></Label>
                                <Input  onChange={this.couponDescriptionHandler}
                                        value={this.state.couponValue.value}
                                    placeholder="Please enter Coupon Description"/>
                                <FormFeedback><text style={fontText1}>Coupon description is invalid</text></FormFeedback>
                            </FormGroup>
                    <Row>
                        <Col>
                            <FormGroup row>
                                <Label><text style={fWeightSpan}>Booking Date From: </text></Label>
                                <Input  value={this.state.startDate}                                           
                                            dateFormat="DD MMM YYYY"
                                            //onClick={this.startDateHandler}
                                            style={{width:200,fontFamily:'Muli', backgroundColor:'white'}}
                                            readOnly />                                                      
                             </FormGroup >
                        </Col>
                        <Col>
                            <FormGroup row>
                                <Label><text style={fWeightSpan}>Booking Date To:</text></Label>
                                <Input value={this.state.endDate} 
                                            dateFormat="DD MMM YYYY"
                                            //onClick={this.endDateHandler}
                                            style={{width:200,fontFamily:'Muli', backgroundColor:'white'}}
                                            readOnly/>                                                    
                                </FormGroup >                                             
                        </Col>
                    </Row>                      
                            <FormGroup row>
                                <Label for="couponPrice"><text style={fWeightSpan}>Coupon Minimum Amount</text> </Label>
                                <Input 
                                onChange={this.couponMinAmtHandler}
                                        maxLength={this.state.length1 ? this.state.max1 : null}
                                        valid={this.state.couponMinAmt.valid ? true : false}
                                        invalid={this.state.couponMinAmt.invalid ? true : false}
                                        value={this.state.couponMinAmt.value}
                                        placeholder="Please enter Coupon Minimum Amount"/>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="couponPrice"><text style={fWeightSpan}>Coupon Discount Amount </text><span style={{color: 'red'}}>*</span></Label>
                                <Input 
                                onChange={this.couponPriceHandler}
                                        maxLength={this.state.length ? this.state.max : null}
                                        valid={this.state.couponPrice.valid ? true : false}
                                        invalid={this.state.couponPrice.invalid ? true : false}
                                        value={this.state.couponPrice.value}

                                        placeholder="Please enter Coupon Discount Amount"/>
                                        <text style={{marginTop:5,marginLeft:14, color:'#005792',fontSize:14}}><span style={{color:'#005792', fontFamily:'Muli', fontWeight:'bold'}}>Coupon Discount Amount shouldn't be more than </span> <text style={fontText1}>{this.state.amountSign === "Rupees" ? <span> {'\u20B9'} </span> : this.state.amountSign === "Dollars" ? <span>$</span> : <span>{this.state.amountSign} </span>}{this.state.netPrice===null||0?0:this.state.netPrice}</text></text>
                                       
                                       {this.state.couponPrice.value.length === 0?
                                        <FormFeedback> Coupon Discount Amount is required</FormFeedback>
                                        :null}
                                       
                                   {/* {this.state.couponMinAmt.value.length !== 0 ? this.state.couponMinAmt.value <= this.state.couponPrice.value ? <FormFeedback>Price should be lesser than Minimum Amount</FormFeedback> : this.state.couponMinAmt.value > this.state.couponPrice.value ? <FormFeedback> Coupon Price is Invalid </FormFeedback> : null : <FormFeedback> Coupon Price is Invalid </FormFeedback>} */}
                               
                            </FormGroup>
                            <FormGroup> 
                                 
                                                           
                                
                                <Button style={{marginRight:70,backgroundColor:"#2C2165", color:'white'}} onClick={this.submitButtonHandler} disabled={this.state.cClicked}><text style={{fontFamily:'Muli'}}>Create</text></Button>                                 
                                <Button style={{backgroundColor:"#F49F3D"}} onClick={this.props.history.goBack} ><text style={{fontFamily:'Muli'}}>Go Back</text></Button>
                            
                            

                            </FormGroup>
                        <ToastContainer />
                </Form>
            
            </div>
            </div>
            </Container>
            </div>
        ) 
    }
} 

const mapStateToProps=state=>{
    return {
      id:state.id 
  }
  }  
  export default connect(mapStateToProps)(supportCreateCoupon);
