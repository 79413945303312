import Switch from 'react-switch';

import React, { Component } from 'react';
import {connect} from "react-redux";
import axios from 'axios'; 
import {ToastContainer, toast } from 'react-toastify';
import { ButtonGroup, Button, Label, Card, CardBody,CardTitle, CardText, Row, Col, Container, Modal,ModalBody,ModalHeader,ModalFooter} from 'reactstrap';
import {BarLoader} from 'react-spinners';
import Breadcrumb from '../Breadcrumb';




class CustDetails extends Component {
   
    state={
        
        pendingTickets: [],
        resolvedTickets: [],
        loading:false,
        resolved: this.props.location.state ?
        (this.props.location.state.resolved ?this.props.location.state.resolved:
        this.props.location.state.resolveActive?this.props.location.state.resolveActive:false):false,
        
        pending: this.props.location.state ? this.props.location.state.pending : true,
        consumerDet:[],
        booking:[],
        f_name:null,
        l_name:null
    }

    componentDidMount(){
         
    //   console.log('pro',this.props)
            this.setState({loading:true})
            let id=this.props.id
            // console.log('id',id)
            axios.post("/support/consumer/myTicket/PendingTickets",{id})
           .then((res) => {
            if(res.data.data){
                if(res.data.result.length !== 0){
                    this.setState({
                        ...this.state,
                        pendingTickets: res.data.result, 
                        loading: false
                    });
                } else if(res.data.result.length === 0){ 
                    this.setState({
                        ...this.state,
                        pendingTickets: [],
                        loading: false
                    });
        
                }
            }
            else {
                if(res.data.dbError){
                    toast.error("Internal Server Error!",{
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                } else { 
                    this.setState({
                        ...this.state,
                        pendingTickets: [],
                        loading: false
                    });
                }
                  
            
            }
           
        })
        .catch((err) => {
            toast.warn("Unable to Reach Server! Please Try Again!" , {
                position: toast.POSITION.BOTTOM_CENTER
              });
            this.setState({
                ...this.state,
                loading: false
            });
        })
    

        axios.post("/support/consumer/myTicket/ResolvedTickets",{id})
        .then((res) => {
            if(res.data.data){
                // console.log("resolved", res.data)
                if(res.data.arr3.length !== 0){
                    this.setState({
                        ...this.state,
                        resolvedTickets: res.data.arr3,
                        loading: false
                    });
                } else if(res.data.arr3.length === 0){
                    this.setState({
                        ...this.state,
                        resolvedTickets: [],
                        loading: false
                    });
                    
                }
            } else {
                if(res.data.dbError){
                    toast.error("Internal Server Error!",{
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                } else {
                    this.setState({
                        ...this.state,
                        resolvedTickets: [],
                        loading: false
                    });
                  
                }
                   
                
            }
           
        })
        .catch(err => {
            toast.warn("Unable to Reach Server! Please Try Again!" , {
                position: toast.POSITION.BOTTOM_CENTER
              });
            this.setState({
                ...this.state,
                loading: false
            });
        })
    

    
}
    
    pendingTicketHandler = () => {
        this.setState({
                ...this.state,
               pending: true,
                resolved: false,
               
               
        });
       
        
    }
    resolvedTicketHandler = () => {
        this.setState({
                ...this.state,
                pending: false,
                resolved: true,
              
        });
       
     }
    FullTicketDetails = (id,bookid,consumer_prid,fname,lname,rfname,rlname) => {


    //    console.log('fname,lname,rfname,rlname',fname,lname,rfname,rlname);
        let url=this.props.match.url;
        
        let TicketId={
            ticketId:consumer_prid
         } 
       
        axios.post("/support/consumer/SearchByTicketId",TicketId)
        .then(res=>{
      
          if(res.data.data){
              if(res.data.details.length!==0)
                {
                      this.setState({ 
                        ...this.state,
                        booking: res.data.details,
                        // f_name:res.data.details.length!==0?res.data.details[0].SUPPORT_USER_FNAME:fname,
                        // l_name:res.data.details.length!==0?res.data.details[0].SUPPORT_USER_LNAME:lname,
                        loading:false})
                        // console.log(this.state.f_name)
                        // console.log(this.state.l_name)

                        // console.log('checkoutDet',res.data)
                        
        axios.post("/support/consumer/ConsumerSearchDetails",{consumer_prid:id})
        .then(res=>{
            

    
            if(res.data.searchDet)
            {
                this.setState({
                    consumerDet:res.data.consumerDet,
                    loading:false
                });
                this.props.history.push({
                    pathname:url+"/ConsumerSearchTicketId",
                    state:{    
                        url,                    
                        active: 1,
                        active0: 'My Tickets',
                        active0Link:'/home/myTicket',
                        active1: 'Support Id Details',
                        active1Link: '/home/myTicket/ConsumerSearchTicketId',
                        active2: 'Booking Details',
                        active2Link: '/home/myTicket/ConsumerSearchTicketId/BookingIdSearch',
                        active3: 'Offer Id Details',
                        active3Link: 'home/myTicket/ConsumerSearchTicketId/BookingIdSearch/offerIdSearchDetails',
                        active4:'Merchant Details',
                        active4Link:'/home/myTicket/ConsumerSearchTicketId/BookingIdSearch/MerchantSearchDetails',
                        active5:'Coupon Details',
                        active5Link:'/home/myTicket/ConsumerSearchTicketId/BookingIdSearch/CouponSearchDetails',
                        active6:'Create Ticket',
                        active6Link:'/home/myTicket/ConsumerSearchTicketId/BookingIdSearch/CreateTicket',
                        active7:'Merchant Flag',
                        active7Link:'/home/myTicket/ConsumerSearchTicketId/BookingIdSearch/MerchantFlag',
                        active8:'Create Coupon',
                        active8Link: '/home/myTicket/ConsumerSearchTicketId/BookingIdSearch/supportCreateCoupon',
                        active9:'Merchant Details',
                        active9Link: '/home/myTicket/ConsumerSearchTicketId/BookingIdSearch/offerIdSearchDetails/MerchantSearchDetails',
 
                        TicketId:id, 
                        acceptance_prid:this.state.booking[0].QF_CONSUMER_OFFER_ACCEPTED_PRID,
                        condata: this.state.booking,
                        consumerData:{
                        QF_CONSUMER_OFFER_ACCEPTED_PRID: this.state.booking[0].QF_CONSUMER_OFFER_ACCEPTED_PRID,
                        CONSUMER_SUPPORT_CATEGORY: this.state.booking[0].CONSUMER_SUPPORT_CATEGORY,
                        CONSUMER_SUPPORT_DESC: this.state.booking[0].CONSUMER_SUPPORT_DESC,
                        CONSUMER_SUPPORT_STATUS: this.state.booking[0].CONSUMER_SUPPORT_STATUS,
                        QF_CONSUMER_SUPPORT_PRID: this.state.booking[0].QF_CONSUMER_SUPPORT_PRID,
                        CONSUMER_SUPPORT_RESOLUTION:this.state.booking[0].CONSUMER_SUPPORT_RESOLUTION,
                        CONSUMER_SUPPORT_CREATED_ON_DATE:this.state.booking[0].CONSUMER_SUPPORT_CREATED_ON_DATE,
                        CONSUMER_SUPPORT_RESOLVED_ON_DATE:this.state.booking[0].CONSUMER_SUPPORT_RESOLVED_ON_DATE,
                        CONSUMER_SUPPORT_SEVERITY:this.state.booking[0].CONSUMER_SUPPORT_SEVERITY,
                        SUPPORT_USER_FNAME:this.state.booking[0].SUPPORT_USER_FNAME,
                        SUPPORT_USER_LNAME:this.state.booking[0].SUPPORT_USER_LNAME,
                        rf_name:rfname?rfname:'Not Yet Resolved',
                        rl_name:rlname?rlname:null,
                        f_name:fname,
                        l_name:lname,
                        

                    },
                    // rf_name:fname,
                    // rl_name:lname
                } 
            });  
               
            }
            else
            {
                if(res.data.dbError===true)
                            {
                                toast.error("Internal Server Error",{position:toast.POSITION.BOTTOM_CENTER});
                                this.setState({loading:false})
    
                            }
                else{
                    this.setState({
                        consumerDet:[],
                        loading:false
                    })
                }
    
            }
    
        }).catch(err=>{
            toast.warn("Unable to Reach Server! Please Try Again!" , {
                position: toast.POSITION.BOTTOM_CENTER
              });
            this.setState({loading:false})
        })
                 
                }
                if(res.data.details.length===0)
                {
                      this.setState({ 
                        ...this.state,
                        booking: [],
                        loading:false}) 
                 
                }

            }

            else{
                    if(res.data.dbError===true)
                    {
                        toast.error("Internal Server Error.",{position:toast.POSITION.BOTTOM_CENTER});
                        this.setState({loading:false})
                    }
                      
                   else{
                       this.setState({ 
                        ...this.state,
                        booking: [],
                        loading:false})


                       }
                    
            }

        }).catch(err=>{
            toast.warn("Unable to Reach Server! Please Try Again!" , {
                position: toast.POSITION.BOTTOM_CENTER
              });
            this.setState({loading:false})  
        })
    
        

      
      
      

    }

        
    render() {

        const rowStyle={
            textAlign:"initial",
        }
        const fWeightSpan={
            fontWeight:"500",
            padding: 5,
            cursor: 'pointer',
            fontFamily: 'Muli',
            color: '#868686'
            // border:'1px solid black'
        }
        
        const textDecor = {
            marginLeft: 5,
            fontWeight:"bold",
            fontFamily: 'Muli',
            // marginLeft: 5,
            color: '#563951'
        }

        const textDecor1 = {
            marginLeft: 5,
            fontFamily: 'Muli',
            fontWeight:"bold",
            // marginLeft: 5,
            color: '#563951'
           // color: 'green'
        }

        let pending = "No Pending Tickets";
        let resolved = "No Resolved Tickets";
        let delId=null;

        // console.log('state',this.state)
        if(this.state.pendingTickets.length !== 0){
            pending = this.state.pendingTickets.map((ticket) => {
                delId = ticket.QF_CONSUMER_OFFER_ACCEPTED_PRID;
                return (
                    <Card style={{marginTop: 10, borderColor: '#a78bba',width:'100%'}} >
                        <CardBody  >   
                        <CardTitle style={{fontSize:18,marginTop:-15}}  onClick={()=>this.FullTicketDetails(ticket.QF_CONSUMER_PRID,ticket.QF_CONSUMER_OFFER_ACCEPTED_PRID,ticket.QF_CONSUMER_SUPPORT_PRID,ticket.SUPPORT_USER_FNAME,ticket.SUPPORT_USER_LNAME,ticket.Resolved_fname?ticket.Resolved_fname:'--',ticket.Resolved_lname?ticket.Resolved_lname:'---')}>  
                                    <text  style={fWeightSpan}>Ticket-Id:</text><text  style={textDecor} >{ticket.QF_CONSUMER_SUPPORT_PRID}</text>
                                </CardTitle>                                                    
                            <CardText onClick={()=>this.FullTicketDetails(ticket.QF_CONSUMER_PRID,ticket.QF_CONSUMER_OFFER_ACCEPTED_PRID,ticket.QF_CONSUMER_SUPPORT_PRID,ticket.SUPPORT_USER_FNAME,ticket.SUPPORT_USER_LNAME,ticket.Resolved_fname?ticket.Resolved_fname:'--',ticket.Resolved_lname?ticket.Resolved_lname:'---')}>
                           
                           <hr style={{borderBottom: '2px solid #fff'}}/>
                                <Row style={rowStyle}>
                                    <Col sm={{size:5,offset:1}} style={fWeightSpan}><text>Booking-Id:</text> <text style={textDecor}>{ticket.QF_CONSUMER_OFFER_ACCEPTED_PRID}</text></Col>
                                    <Col sm={{size:6}} style={fWeightSpan}><text>Status:</text> { ticket.CONSUMER_SUPPORT_STATUS===1? <text style={{backgroundColor:"#FFC459",borderRadius:"3px",color:"#fff",marginLeft:"15px",marginRight:"10px", padding:5}}>Pending</text>:<text>Unknown</text> }</Col>
                                    {/* <Col sm={{size:'auto', offset:0}}><Switch height={20} onChange={this.toggleSwitchHandler.bind(this,delId)} checked={this.state.toggle["toggle_"+ticket.QF_OUR_ticket_PRID]} /></Col> */}
                               
                                </Row>
                                <Row style={rowStyle}>
                                    {/* <Col sm={{size:10,offset:1}} style={fWeightSpan}><text>Description:</text> <text style={textDecor}>{ticket.CONSUMER_SUPPORT_DESC} </text></Col> */}
                                    <Col sm={{size:5,offset:1}} style={fWeightSpan}><text>Severity:</text><text style={textDecor1}>{ticket.CONSUMER_SUPPORT_SEVERITY!==null ? ticket.CONSUMER_SUPPORT_SEVERITY === 1 ? <Label style={{backgroundColor:'#00aedb',borderRadius:"2px",color:"#fff",padding:1, width: 80, textAlign:"center"}}>Very Low</Label> :  ticket.CONSUMER_SUPPORT_SEVERITY === 2 ? <Label style={{backgroundColor:'#00b159',borderRadius:"2px",color:"#fff", padding:1, width: 80, textAlign:"center"}}>Low</Label> : ticket.CONSUMER_SUPPORT_SEVERITY === 3 ? <Label style={{backgroundColor:'#ffc425',borderRadius:"2px",color:"#fff",padding:1, width: 80, textAlign:"center"}}>Medium</Label> : ticket.CONSUMER_SUPPORT_SEVERITY === 4 ? <Label style={{backgroundColor:'#f37735',borderRadius:"2px",color:"#fff",padding:1, width: 80, textAlign:"center"}}>High</Label> : ticket.CONSUMER_SUPPORT_SEVERITY === 5 ? <Label style={{backgroundColor:'#d11141',borderRadius:"2px",color:"#fff", padding:1, width: 80, textAlign:"center"}}> Very High </Label> : '---' :'---'} </text></Col>
                                    <Col sm={{size:6}} style={fWeightSpan}><text>Category:</text> <text style={textDecor}>{ticket.CONSUMER_SUPPORT_CATEGORY==='Consumer refund request'?'Customer Refund Request':ticket.CONSUMER_SUPPORT_CATEGORY} </text></Col>
                              
                                {/* <Col sm={{size:5,offset:1}} style={fWeightSpan}><text> Resolution:</text> <text style={textDecor}>{ticket.CONSUMER_SUPPORT_STATUS} </text></Col> */}
                                </Row>
                                {/* <hr/> */}
                                
                                
                            </CardText>
                        </CardBody>
                    </Card>
                );
            }) 
        } else {
            if(this.state.loading===true){
                pending= "Loading...";
            }else{
                if(this.state.pendingTickets.length==0)
                pending = "No Pending Tickets";  
        }           
            
                     
        }

       

        if(this.state.resolvedTickets.length !== 0){
            resolved = this.state.resolvedTickets.map((ticket) => {
                delId=ticket.QF_OUR_ticket_PRID
                return ( 
                    <Card style={{marginTop: 10, borderColor: '#a78bba'}}>
                        <CardBody  onClick={()=>this.FullTicketDetails(ticket.QF_CONSUMER_PRID,ticket.QF_CONSUMER_OFFER_ACCEPTED_PRID,ticket.QF_CONSUMER_SUPPORT_PRID,ticket.SUPPORT_USER_FNAME,ticket.SUPPORT_USER_LNAME,ticket.Resolved_fname?ticket.Resolved_fname:'--',ticket.Resolved_lname?ticket.Resolved_lname:'---')} >                                                       
                        <CardTitle style={{fontSize:18,marginTop:-15}} onClick={()=>this.FullTicketDetails(ticket.QF_CONSUMER_PRID,ticket.QF_CONSUMER_OFFER_ACCEPTED_PRID,ticket.QF_CONSUMER_SUPPORT_PRID,ticket.SUPPORT_USER_FNAME,ticket.SUPPORT_USER_LNAME,ticket.Resolved_fname?ticket.Resolved_fname:'--',ticket.Resolved_lname?ticket.Resolved_lname:'---')}>  
                                    <text  style={fWeightSpan}>Ticket-Id:</text><text style={textDecor}>{ticket.QF_CONSUMER_SUPPORT_PRID}</text>
                                </CardTitle>
                            <CardText>
                                <hr />
                          
                                <Row style={rowStyle}>
                                    <Col sm={{size:5, offset:1}} style={fWeightSpan}><text>Booking-Id:</text> <text style={textDecor}>{ticket.QF_CONSUMER_OFFER_ACCEPTED_PRID}</text></Col>
                                    <Col sm={{size:6}} style={fWeightSpan}><text >Status:</text> <text style={textDecor}>{ticket.CONSUMER_SUPPORT_STATUS===2?<text style={{backgroundColor:"#3DC5B6",borderRadius:"3px",color:"#fff",marginLeft:"15px",marginRight:"10px", padding:5}}>Resolved </text>:<text>Unknown</text>}</text></Col>
                                
                                </Row>
                                <Row style={rowStyle}>
                                    <Col sm={{size:5, offset:1}} style={fWeightSpan}><text>Category:</text> <text style={textDecor}>{ticket.CONSUMER_SUPPORT_CATEGORY==='Consumer refund request'?'Customer Refund Request':ticket.CONSUMER_SUPPORT_CATEGORY} </text></Col>
                                    <Col sm={{size:6}} style={fWeightSpan}><text> Resolved By:</text> <text style={textDecor}>{ticket.Resolved_fname} {ticket.Resolved_lname} </text></Col> 
                                </Row>
                                
                               
                                
                            </CardText>
                        </CardBody> 
                    </Card>
                );
            })
        } else {

            if(this.state.loading===true){
                resolved= "Loading...";
            }else{
                if(this.state.resolvedTickets.length==0)
                resolved= "No Resolved Tickets";  
        }        
           
         
            
        }
        let pendingStyle;
        let resolvedStyle;
        if(this.state.pending){
            pendingStyle={backgroundColor:'#2C2165', fontFamily:'Muli'}
        }
        if(this.state.resolved){
            resolvedStyle={backgroundColor:'#2C2165', fontFamily:'Muli'}
        }
        return (
            <div>
            <Breadcrumb active0={'My Tickets'} 
                        active0Link={'/home/myTicket'}/>
            <Container fluid>
            <div className="row justify-content-center" style={{marginTop: 20, marginBottom: 20}}>
            <div className="col-3" >
            
               <ButtonGroup >

                <Button onClick={this.pendingTicketHandler} active={this.state.pending? true : false} style={pendingStyle}>Pending Tickets</Button>
                <Button onClick={this.resolvedTicketHandler} active={this.state.resolved ? true : false} style={resolvedStyle}>Resolved Tickets </Button> 
               </ButtonGroup>
                
            
            </div>
            </div>
            <Container fluid style={{width: 600}}> 
            <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/>

                {this.state.pending ? pending : null}  
                {this.state.resolved ? resolved : null} 
              
            </Container>                
                
            <ToastContainer autoClose={2500}/>   
            </Container>              
            </div>
                     

        )
    }
} 
const mapStateToProps=state=>{
    return {
      id:state.id 
  }
  }  


export default connect(mapStateToProps)(CustDetails);

  