import Switch from 'react-switch';

import React, { Component } from 'react'; 
import {connect} from "react-redux";
import axios from 'axios';
import {ToastContainer, toast } from 'react-toastify';
import { ButtonGroup, Button, Card, CardBody,CardTitle, CardText, Form,FormText, Row, Col, Container, Modal,ModalBody,ModalHeader,ModalFooter, Tooltip} from 'reactstrap';
import {BarLoader} from 'react-spinners';
import momenttz from 'moment-timezone'
import moment from 'moment';
import { FaTrashAlt, FaRegEdit } from 'react-icons/fa';
import Curr from "currency.js";



// import Breadcrumb from '../Breadcrumb';




class MerchantCreatedCoupons extends Component {
   
    state={
        m_id:null,
       loading: false,
       currentCoupons:[],
       pastCoupons:[],
       current: true,
       past: false,
       activeToggle: [],
       update:null,
       tooltipOpen: [],
       loc: null,
       tz: null

    }

    componentDidMount() {
        let body={
            m_id:this.props.location.state.m_id,
            timezone:this.props.location.state.tz
        }
        
        this.setState({
            ...this.state,
            loading: true,
            m_id: this.props.location.state.m_id,
            tz: this.props.location.state.tz
    });
        let toggle=[];

        axios.post("/support/merchant/viewcoupons", body)
        .then((res) => {
            console.log("Coupon res", res.data);
            if(res.data.data){

                res.data.current.map(coupon => {
                    if(coupon.MERCHANT_COUPON_ACTIVE){
                        return toggle['coupon_'+coupon.QF_MERCHANT_COUPON_PRID] = true
                    } else {
                        return toggle['coupon_'+coupon.QF_MERCHANT_COUPON_PRID] = false
                    }
                })
                this.setState({
                    ...this.state,
                    currentCoupons: res.data.current, 
                    pastCoupons: res.data.past,
                    // loc: res.data.resultQ[0].MERCHANT_ADDRESS_COUNTRY,
                    activeToggle: toggle,
                    loading: false

                })
            } else {
                if(res.data.dbError)
                    {
                        toast.error("Internal Server Error",{
                            position:toast.POSITION.BOTTOM_CENTER
                        })
                        this.setState({
                            ...this.state,
                            loading: false
                    });
                    }
                    else {
                        this.setState({
                            ...this.state,
                            currentCoupons: [],
                            pastCoupons: [],
                            loading: false
        
                        })
                    }          
            }
        })
        .catch(err => {
            console.log(err);
            toast.warn("Unable to Reach Server! Please Try Again!" , {
                position: toast.POSITION.BOTTOM_CENTER
              });
            this.setState({
                ...this.state,
                loading: false
        });
        })
    }

      
        
    
currentCouponHandler = () => {
        this.setState({
                ...this.state,
                current: true,
                past: false,
                //loading: true              
        });
        //this.componentDidMount();       
    }

pastCouponHandler = () => {
        this.setState({
            ...this.state,
            current: false,
            past: true,
            //loading: true          
        });

        //this.componentDidMount();
    } 
   




    activeToggleHandler = (prid) => {
        // console.log("Coupon Prid", prid);

        let toggle={}
        if(this.state.activeToggle['coupon_'+prid]){
            toggle={
                ...this.state.activeToggle,
                ['coupon_'+prid]: false
            }
            this.setState({
                ...this.state,
                activeToggle: toggle
            })

            let body ={
                prid,
                val: false
            }

            axios.post('/support/merchant/couponactive', body)
            .then(res => {
                if(res.data.updated){
                    this.componentDidMount(this.state.m_id);
                    toast.success("Coupon is Deactivated", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                } else {
                    if(res.data.dbError){
                        toast.error("Internal Server Error", {
                            position: toast.POSITION.BOTTOM_CENTER
                        })
                    } else {
                        this.setState({
                            ...this.state,
                            loading: false
                        })
                    }
                }
            })
            .catch(err => {
                toast.warn("Unable to Reach Server! Please Try Again!" , {
                    position: toast.POSITION.BOTTOM_CENTER
                  });
            })
        } else {
            toggle={
                ...this.state.activeToggle,
                ['coupon_'+prid]: true
            }
            this.setState({
                ...this.state,
                activeToggle: toggle
            })

            let body ={
                prid,
                val: true
            }

            axios.post('/support/merchant/couponactive', body)
            .then(res => {
                if(res.data.updated){
                    this.componentDidMount(this.state.m_id);
                    toast.success("Coupon is Activated", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                } else {
                    if(res.data.dbError){
                        toast.error("Internal Server Error", {
                            position: toast.POSITION.BOTTOM_CENTER
                        })
                    } else {
                        this.setState({
                            ...this.state,
                            loading: false
                        })
                    }
                }
            })
            .catch(err => {
                toast.warn("Unable to Reach Server! Please Try Again!" , {
                    position: toast.POSITION.BOTTOM_CENTER
                  });
            })
        }
    }

    toggle = (id) =>{
        // console.log("Prid", id)

        let tooltip={};

        if(this.state.tooltipOpen['update_'+id]){
            tooltip={
             ...this.state.tooltipOpen,
             ['update_'+id]:false
            }
            this.setState({
                ...this.state,
                tooltipOpen: tooltip,
            });
        } else {            
            tooltip={
                ...this.state.tooltipOpen,
                ['update_'+id]:true
               }
               this.setState({
                   ...this.state,
                   tooltipOpen: tooltip,
               });    
        }
    }
    
    render() {

        // console.log("State in Coupon", this.state)
        const rowStyle={
            textAlign:"initial",
        }
        const fWeightSpan={
            fontWeight:"400",
            padding: 5,
            fontFamily: 'Muli',
            color: '#868686',
            // cursor: 'pointer',
            marginBottom:5,
            // border:'1px solid black'
        }

        const fWeightDate={
            fontWeight:"400",
            padding: 5,
            fontFamily: 'Muli',
            color: '#868686',
            // cursor: 'pointer',
            marginBottom:5,
            // border:'1px solid black'
        }

        const dateSpan={
            fontWeight:"400",
            padding: 5,
            fontFamily: 'Muli',
            color: '#868686',
            // cursor: 'pointer',
            marginBottom:5,
            // border:'1px solid black',
            // textAlign: 'center'
        }

        const fWeightSpan1={
            fontWeight:"400",
            fontFamily: 'Muli',
            color: '#868686',
            padding: 5,            
            // cursor: 'pointer',
            marginBottom:10,
            // border:'1px solid black'
        }
        
        const textDecor = {
            fontWeight:"bold",
            // marginLeft: 5,
            fontFamily: 'Muli',
            color: '#563951',
        }

        let current = "No Current Offers";
        let past = "No Past Offers";

        if(this.state.currentCoupons.length !== 0){

            current = this.state.currentCoupons.map((coupon) => {
                return (
                <Card style={{marginTop: 15, border: '2px solid #a78bba',backgroundColor:'white',width:'100%'}} >
                        <CardBody>   
                            <CardTitle style={{fontSize:18,marginTop:-15}}></CardTitle>                                                    
                            <CardText style={{marginLeft:20, marginRight: 20}}>
                                <Row style={rowStyle}>
                                    <Col sm={{size:2}} style={fWeightSpan1}><text>Coupon Code:</text> </Col>
                                    <Col sm={{size:6}} style={fWeightSpan1}><text style={textDecor}>{coupon.MERCHANT_COUPON_CODE}</text></Col>
                                    <Col sm={{size:3}}  style={fWeightSpan}><text>Coupon Status:</text>
                                    <text > 
                                        {coupon.MERCHANT_COUPON_DELETED_FLAG===1 ?
                                                <text style={{backgroundColor:"#F86C69", marginLeft:10 ,padding:7,width:100,color:"#fff",borderRadius:"3px"}}>Deleted</text> 
                                        :
                                        coupon.MERCHANT_COUPON_SERVICE_DATE_TO ? 
                                            moment(coupon.MERCHANT_COUPON_SERVICE_DATE_TO).set('hour', 23).set('minute', 59).set('second', 59).isBefore(moment().tz(this.state.tz).format("YYYY-MM-DD")) ? <text style={{backgroundColor:"#F86C69", marginLeft:10 ,padding:7,width:100,color:"#fff",borderRadius:"3px"}}>Expired</text>
                                            : 
                                            coupon.MERCHANT_COUPON_ACTIVE===1?
                                                <text style={{backgroundColor:"#3DC5B6",marginLeft:10,padding:5,width:100,color:"#fff",borderRadius:"3px"}}>Active</text>
                                                :
                                                <text style={{backgroundColor:"#F86C69", marginLeft:10 ,padding:7,width:100,color:"#fff",borderRadius:"3px"}}>Inactive</text>
                                        :
                                            moment(coupon.MERCHANT_COUPON_BOOKING_DATE_TO).set('hour', 23).set('minute', 59).set('second', 59).isBefore(moment().tz(this.state.tz).format("YYYY-MM-DD")) ? <text style={{backgroundColor:"#F86C69", marginLeft:10 ,padding:7,width:100,color:"#fff",borderRadius:"3px"}}>Expired</text>
                                            : 
                                            coupon.MERCHANT_COUPON_ACTIVE===1?
                                                <text style={{backgroundColor:"#3DC5B6",marginLeft:10,padding:5,width:100,color:"#fff",borderRadius:"3px"}}>Active</text>
                                                :
                                                <text style={{backgroundColor:"#F86C69", marginLeft:10 ,padding:7,width:100,color:"#fff",borderRadius:"3px"}}>Inactive</text> 

                                        }
                                    </text>
                                </Col> 
                                {coupon.MERCHANT_COUPON_DELETED_FLAG === 1 ?
                                    null 
                                :
                                    <Col sm={{size:1}} style={fWeightSpan1}><Switch height={20} onColor='#3DC5B6' offColor='#F86C69' onChange={() => this.activeToggleHandler(coupon.QF_MERCHANT_COUPON_PRID)} checked={this.state.activeToggle['coupon_'+coupon.QF_MERCHANT_COUPON_PRID]}/></Col>
                                }
                                </Row>
                                <Row style={rowStyle}>
                                    <Col sm={{size:2}} style={fWeightSpan}><text> Coupon Desc:</text> </Col>
                                    <Col sm={{size:4}} style={fWeightSpan}><text style={textDecor}>{coupon.MERCHANT_COUPON_DESC ? coupon.MERCHANT_COUPON_DESC : <text>--</text>}</text></Col>
                                    <Col sm={{size:4,offset:2}} style={fWeightSpan}>Subcategory:
                                    <text style={textDecor}> {coupon.service_sub_category_name ? coupon.service_sub_category_name : <text>--</text>}</text></Col>
                                </Row>

                                <Row style={{marginBottom:10, marginTop:10,}}>
                                    <Col sm={{size:4}}>
                                        <Row style={rowStyle}>
                                            <Col sm={{size:12}} style={fWeightDate}><text>Booking Date </text></Col>
                                        </Row>
                                        <Row style={rowStyle}>
                                            <Col sm={{size:12}} style={dateSpan}><text style={textDecor}>{ coupon.MERCHANT_COUPON_BOOKING_DATE_FROM ? moment(coupon.MERCHANT_COUPON_BOOKING_DATE_FROM+"Z").tz(coupon.MERCHANT_TIMEZONE).format('DD MMM YY') : coupon.MERCHANT_COUPON_BOOKING_DATE_TO ? <text style={textDecor}>Current</text> : <text>--</text>} {<strong><b>-</b></strong>} {coupon.MERCHANT_COUPON_BOOKING_DATE_TO ? moment(coupon.MERCHANT_COUPON_BOOKING_DATE_TO+"Z").tz(coupon.MERCHANT_TIMEZONE).format('DD MMM YY') : coupon.MERCHANT_COUPON_BOOKING_DATE_FROM ? <text style={textDecor}>Future</text> : <text>--</text>}</text></Col>
                                        </Row>
                                    </Col>
                                    <Col sm={{size:4}}>
                                        <Row style={rowStyle}>
                                            <Col sm={{size:12}} style={fWeightDate}><text>Service Date </text> </Col>
                                        </Row>
                                        <Row style={rowStyle}>
                                            <Col sm={{size:12}} style={dateSpan}><text style={textDecor}>{ coupon.MERCHANT_COUPON_SERVICE_DATE_FROM ? moment(coupon.MERCHANT_COUPON_SERVICE_DATE_FROM+"Z").tz(coupon.MERCHANT_TIMEZONE).format('DD MMM YY') : coupon.MERCHANT_COUPON_SERVICE_DATE_TO ? <text style={textDecor}>Current</text> : <text>--</text>} {<strong><b>-</b></strong>} {coupon.MERCHANT_COUPON_SERVICE_DATE_TO ? moment(coupon.MERCHANT_COUPON_SERVICE_DATE_TO+"Z").tz(coupon.MERCHANT_TIMEZONE).format('DD MMM YY') : coupon.MERCHANT_COUPON_SERVICE_DATE_FROM ? <text style={textDecor}>Future</text> : <text>--</text>}</text></Col>
                                        </Row>
                                    </Col>
                                </Row>
                              
                                <Row style={{backgroundColor: '#f1f1f1', padding: 10}}>                                    
                                    <Col sm={{size:3}}>
                                        <Row>
                                            <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5,fontFamily: 'Muli',color: '#868686'}}><text>Coupon Count</text></Col>
                                            <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"bold", padding: 5, marginBottom:10,fontFamily: 'Muli',color: '#563951'}}><text style={textDecor}>{coupon.MERCHANT_COUPON_USED_COUNT ? coupon.MERCHANT_COUPON_USED_COUNT : <text>0</text>}{'/'}{coupon.MERCHANT_COUPON_MAX_COUNT ? coupon.MERCHANT_COUPON_MAX_COUNT : <text>0</text>}</text></Col> 
                                        </Row>
                                    </Col>

                                    <Col sm={{size:3}}>
                                        <Row>
                                            <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5,fontFamily: 'Muli',color: '#868686'}}><text>Coupon Minimum Amount</text></Col>
                                            <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"bold", padding: 5, marginBottom:10,fontFamily: 'Muli',color: '#563951'}}><text style={textDecor}>
                                                {coupon.MERCHANT_COUPON_MINIMUM_AMOUNT ?this.state.loc === "India" ? <span>{'\u20B9'}</span> : <span>$</span>:null}
                                                {coupon.MERCHANT_COUPON_MINIMUM_AMOUNT ? Curr(coupon.MERCHANT_COUPON_MINIMUM_AMOUNT).value : <text>---</text>}</text> 
                                            </Col>
                                        </Row>
                                    </Col>
                                
                                    <Col sm={{size:3}}>
                                        <Row>
                                            <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5,fontFamily: 'Muli',color: '#868686'}}><text>Coupon Maximum Discount Amount</text></Col>
                                            <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"bold", padding: 5, marginBottom:10,fontFamily: 'Muli',color: '#563951'}}><text style={textDecor}>
                                                {coupon.MERCHANT_COUPON_MAXIMUM_AMOUNT?this.state.loc === "India" ? <span>{'\u20B9'}</span> : <span>$</span>:null}
                                                {coupon.MERCHANT_COUPON_MAXIMUM_AMOUNT ? Curr(coupon.MERCHANT_COUPON_MAXIMUM_AMOUNT).value : <text>---</text>}</text> 
                                            </Col>
                                        </Row>
                                    </Col>

                                    
                                
                                {coupon.MERCHANT_COUPON_VALUE_OFF ? 
                                    <Col sm={{size:3}}>
                                        <Row>
                                            <Col sm={{size:12}} style={{fontWeight:"400", padding: 5,fontFamily: 'Muli',color: '#868686'}}><text>Coupon Discount Amount</text></Col>
                                            <Col sm={{size:12}} style={{fontWeight:"bold", padding: 5, marginBottom:10,fontFamily: 'Muli',color: '#563951'}}><text style={textDecor}>
                                                {this.state.loc === "India" ? <span>{'\u20B9'}</span> : <span>$</span>}
                                                {coupon.MERCHANT_COUPON_VALUE_OFF ? Curr(coupon.MERCHANT_COUPON_VALUE_OFF).value : <text>0</text>}</text>
                                            </Col>
                                        </Row>
                                    </Col>
                                        
                                :
                                coupon.MERCHANT_COUPON_PERCENT_OFF ?
                                    <Col sm={{size:3}}>
                                        <Row>
                                            <Col sm={{size:12}} style={{fontWeight:"400", padding: 5,fontFamily: 'Muli',color: '#868686'}}><text>Coupon Discount Percent</text></Col>
                                            <Col sm={{size:12}} style={{fontWeight:"bold", padding: 5, marginBottom:10,fontFamily: 'Muli',color: '#563951'}}><text style={textDecor}>{coupon.MERCHANT_COUPON_PERCENT_OFF ? Curr(coupon.MERCHANT_COUPON_PERCENT_OFF).value : <text>0</text>}%</text> </Col>
                                        </Row>
                                    </Col>
                                :
                                null
                                }


                                     
                                </Row>


                                                               
                                
                            </CardText>
                        </CardBody>
                    </Card>
                )
            })
            
        } else {
            if(this.state.loading===true){
                current= "Loading...";
            }else{
                if(this.state.currentCoupons.length==0)
                current = "No Current Coupons";  
        }           
            
                     
        }

       

         if(this.state.pastCoupons.length!==0){ 
            past = this.state.pastCoupons.map((coupon) => {
                return (
                    <Card  style={{marginTop: 15, border: '2px solid #a78bba',backgroundColor:'white',width:'100%'}} >
                    <CardBody>   
                        <CardTitle style={{fontSize:18,marginTop:-15}}></CardTitle>                                                    
                        <CardText style={{marginLeft:20, marginRight: 20}}>
                            <Row style={rowStyle}>
                                <Col sm={{size:2}} style={fWeightSpan1}><text>Coupon Code:</text> </Col>
                                <Col sm={{size:9}} style={fWeightSpan1}><text style={textDecor}>{coupon.MERCHANT_COUPON_CODE}</text></Col>

                            </Row>
                            <Row style={rowStyle}>
                                    <Col sm={{size:2}} style={fWeightSpan}><text> Coupon Desc:</text> </Col>
                                    <Col sm={{size:4}} style={fWeightSpan}><text style={textDecor}>{coupon.MERCHANT_COUPON_DESC ? coupon.MERCHANT_COUPON_DESC : <text>--</text>}</text></Col>
                               
                                    <Col sm={{size:2}} style={fWeightSpan}><text> Subcategory:</text> </Col>
                                    <Col sm={{size:4}} style={fWeightSpan}><text style={textDecor}>{coupon.service_sub_category_name ? coupon.service_sub_category_name : <text>--</text>}</text></Col>
                                </Row> 
                               
                            <Row style={{marginBottom:10, marginTop:10}}>
                                <Col sm={{size:4}}>
                                    <Row style={rowStyle}>
                                        <Col sm={{size:12, offset:0}} style={fWeightSpan}><text>Booking Date </text></Col>
                                        <Col sm={{size:12, offset:0}} style={fWeightSpan}><text style={textDecor}>{ coupon.MERCHANT_COUPON_BOOKING_DATE_FROM ? moment(coupon.MERCHANT_COUPON_BOOKING_DATE_FROM+"Z").tz(coupon.MERCHANT_TIMEZONE).format('DD MMM YY') : coupon.MERCHANT_COUPON_BOOKING_DATE_TO ? <text style={textDecor}>Current</text> : <text>-</text>} {<strong><b>-</b></strong>} {coupon.MERCHANT_COUPON_BOOKING_DATE_TO ? moment(coupon.MERCHANT_COUPON_BOOKING_DATE_TO+"Z").tz(coupon.MERCHANT_TIMEZONE).format('DD MMM YY') : coupon.MERCHANT_COUPON_BOOKING_DATE_FROM ? <text style={textDecor}>Future</text> : <text>--</text>}</text></Col>
                                    </Row>
                                </Col>

                                <Col sm={{size:4}}>
                                    <Row style={rowStyle}>
                                        <Col sm={{size:12, offset:0}} style={fWeightSpan}><text>Service Date </text></Col>
                                        <Col sm={{size:12, offset:0}} style={fWeightSpan}><text style={textDecor}>{ coupon.MERCHANT_COUPON_SERVICE_DATE_FROM ? moment(coupon.MERCHANT_COUPON_SERVICE_DATE_FROM+"Z").tz(coupon.MERCHANT_TIMEZONE).format('DD MMM YY') : coupon.MERCHANT_COUPON_SERVICE_DATE_TO ? <text style={textDecor}>Current</text> : <text>-</text>} {<strong><b>-</b></strong>} {coupon.MERCHANT_COUPON_SERVICE_DATE_TO ? moment(coupon.MERCHANT_COUPON_SERVICE_DATE_TO+"Z").tz(coupon.MERCHANT_TIMEZONE).format('DD MMM YY') : coupon.MERCHANT_COUPON_SERVICE_DATE_FROM ? <text style={textDecor}>Future</text> : <text>--</text>}</text></Col>
                                    </Row>
                                </Col>
                            
                            </Row> 

                            <Row style={{backgroundColor: '#f1f1f1', padding: 10}}>
                                <Col >
                                    <Row>
                                        <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5,fontFamily: 'Muli',color: '#868686'}}><text>Coupon Count</text>
                                        <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"bold", padding: 5, marginBottom:10,fontFamily: 'Muli',color: '#563951'}}></Col><text style={textDecor}>{coupon.MERCHANT_COUPON_USED_COUNT ? coupon.MERCHANT_COUPON_USED_COUNT : <text>0</text>}{'/'}{coupon.MERCHANT_COUPON_MAX_COUNT ? coupon.MERCHANT_COUPON_MAX_COUNT : <text>0</text>}</text></Col>
                                    </Row>
                                </Col>

                                <Col>
                                    <Row>
                                        <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5,fontFamily: 'Muli',color: '#868686'}}><text>Coupon Minimum Amount</text></Col>
                                        <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"bold", padding: 5, marginBottom:10,fontFamily: 'Muli',color: '#563951'}}><text style={textDecor}>
                                            {coupon.MERCHANT_COUPON_MINIMUM_AMOUNT?this.state.loc === "India" ? <span>{'\u20B9'}</span> : <span>$</span>:null}
                                            {coupon.MERCHANT_COUPON_MINIMUM_AMOUNT ? Curr(coupon.MERCHANT_COUPON_MINIMUM_AMOUNT).value : <text>---</text>}</text>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col sm={{size:3}}>
                                        <Row>
                                            <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5,fontFamily: 'Muli',color: '#868686'}}><text>Coupon Maximum Discount Amount</text></Col>
                                            <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"bold", padding: 5, marginBottom:10,fontFamily: 'Muli',color: '#563951'}}><text style={textDecor}>
                                                {coupon.MERCHANT_COUPON_MAXIMUM_AMOUNT?this.state.loc === "India" ? <span>{'\u20B9'}</span> : <span>$</span>:null}
                                                {coupon.MERCHANT_COUPON_MAXIMUM_AMOUNT ? Curr(coupon.MERCHANT_COUPON_MAXIMUM_AMOUNT).value : <text>---</text>}</text> 
                                            </Col>
                                        </Row>
                                    </Col>
                                
                                
                                {coupon.MERCHANT_COUPON_VALUE_OFF ? 
                                    <Col>
                                        <Row>
                                            <Col sm={{size:12}} style={{fontWeight:"400", padding: 5, fontFamily: 'Muli',color: '#868686'}}><text>Coupon Discount Amount</text></Col>
                                            <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"bold", padding: 5, marginBottom:10,fontFamily: 'Muli',color: '#563951'}}><text style={textDecor}> 
                                                {this.state.loc === "India" ? <span>{'\u20B9'}</span> : <span>$</span>}
                                                {coupon.MERCHANT_COUPON_VALUE_OFF ? Curr(coupon.MERCHANT_COUPON_VALUE_OFF).value : <text>0</text>}</text>
                                            </Col>
                                        </Row>
                                    </Col>
                                :
                                coupon.MERCHANT_COUPON_PERCENT_OFF ?
                                    <Col>
                                        <Row>
                                            <Col sm={{size:12}} style={{fontWeight:"400", padding: 5, fontFamily: 'Muli',color: '#868686'}}><text>Coupon Discount Percent</text></Col>
                                            <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"bold", padding: 5, marginBottom:10,fontFamily: 'Muli',color: '#563951'}}><text style={textDecor}>{coupon.MERCHANT_COUPON_PERCENT_OFF ? Curr(coupon.MERCHANT_COUPON_PERCENT_OFF).value : <text>0</text>}%</text> </Col>
                                        </Row>
                                    </Col>
                                :
                                null
                                }


                               
                            </Row>
                            
                            
                        </CardText>
                    </CardBody>
                </Card>
                )
            })
         } else {

          if(this.state.loading===true){
              past= "Loading...";
          }else{ 
                if(this.state.pastCoupons.length===0)
                past= "No Past Coupons";  
         }  
         }

        return (
            <div>
            {/* <Breadcrumb active0={'My Tickets'} 
                        active0Link={'/home/myTicket'}/> */}
            <Container fluid>
            <div className="row justify-content-center" style={{marginTop: 50}}>
            <div className="col-6" >
           
            <Form>
                <FormText style={{fontSize:24,marginBottom:20}}><text style={{fontFamily: 'Muli',color:'#563951'}}>Coupons Created By <strong>{this.props.location.state.name}</strong></text> </FormText>
            </Form>

               <ButtonGroup>
                <Button onClick={this.currentCouponHandler} active={this.state.current? true : false}><text style={{fontFamily: 'Muli'}}>Current Coupons</text></Button>
                <Button onClick={this.pastCouponHandler} active={this.state.past ? true : false}><text style={{fontFamily: 'Muli'}}>Past Coupons</text></Button> 
               </ButtonGroup>
                
            
            </div>
            </div> 
            <Container fluid style={{width: 1300}}> 
            <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/>

                {this.state.current ? current : null}  
                {this.state.past ? past : null}                 
              
            </Container>                
                
            <ToastContainer autoClose={2500}/>     
            </Container>            
            </div>
                     

        )
    }
} 
const mapStateToProps=state=>{
    return {
      id:state.id 
  }
  }  


  export default connect(mapStateToProps)(MerchantCreatedCoupons);

  