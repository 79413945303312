import React, { Component } from 'react';
import { Container,Form, FormGroup, Label, Input, Button, Row, Col, FormFeedback } from 'reactstrap';
import axios from 'axios';
import {toast, ToastContainer} from 'react-toastify';
import {connect} from "react-redux";
import Breadcrumb from '../Navbar/Breadcrumb';
import moment from 'moment';

class FlagMerchant extends Component {
    state = {
        bId: this.props.location.state.id,
        mname: this.props.location.state.mname,
        mpPrid: this.props.location.state.mpPrid,
        comment: {
            value: null,
            valid: false,
            invalid: false
        },
        issueType: {
            value: 0,
            valid: false,
            invalid: false
        },
        severity: {
            value: 0,
            valid: false,
            invalid: false
        },
        submit: {
            valid: false,
            invalid: false
        },
        active: this.props.location.state.active,
        active0: this.props.location.state.active0 ? this.props.location.state.active0 : null,
        active1: this.props.location.state.active1,
        active2: this.props.location.state.active2,
        url: this.props.location.state.url,
        fClicked:false
    }

   

    issueTypeHandler = (event) => {
        if(event.target.value === '0'){
        this.setState({
            ...this.state,
            issueType: {
                ...this.state.issueType,
                value : null,
                valid: false,
                invalid: true
            }
        });
    } else {
        this.setState({
            ...this.state,
            issueType: {
                ...this.state.issueType,
                value : event.target.value,
                valid: true,
                invalid: false
            }
        });
    }
    }

    severityHandler = (event) => {
        if(event.target.value === '0'){
            this.setState({
                ...this.state,
                severity: {
                    ...this.state.severity,
                    value: null,
                    valid: false,
                    invalid: true
                }
            });
        } else {
            this.setState({
            ...this.state,
            severity: {
                ...this.state.severity,
                value: event.target.value,
                valid: true,
                invalid: false
            }
        });
    }
    }

    commentHandler = (event) => {
        this.setState({
            ...this.state,
            comment: {
                ...this.state.comment,
                value: event.target.value,
                valid: true,
                invalid: false
            }
        });
    }

    submitHandler = () => {
        // console.log("submit");
        if(this.state.issueType.value === 0 || this.state.issueType.value === null || this.state.issueType.value === '' || this.state.issueType.invalid){
            this.setState({
                ...this.state,
                issueType:{
                    ...this.state.issueType,
                    valid: false,
                    invalid: true
                }
            })
            return false;
        } else if(this.state.severity.value === 0 || this.state.severity.value === null || this.state.severity.value === '' || this.state.severity.invalid){
            this.setState({
                ...this.state,
                severity:{
                    ...this.state.severity,
                    valid: false,
                    invalid: true
                }
            })
            return false;
        } else if(this.state.comment.value === null || this.state.comment.value===''||(this.state.comment.value).trim()===''){
            // console.log("in if block")
            this.setState({
                ...this.state,
                comment: {
                    ...this.state.comment,
                    valid: false,
                    invalid: true
                },
                submit: {
                    ...this.state.submit,
                    invalid: true
                }
            })
        } else {
            this.setState({
                ...this.state,
                comment: {
                    ...this.state.comment,
                    valid: true,
                    invalid: false
                },
                submit: {
                    ...this.state.submit,
                    invalid: false
                }
            });
            // console.log("I an in else block");

            let data = {
                issue : this.state.issueType.value,
                severity : this.state.severity.value,
                comment : this.state.comment.value,
                bid : this.state.bId,
                mpPrid: this.state.mpPrid,
                u_id: this.props.u_id,
                date: moment().format('YYYY-MM-DD hh:mm:ss')
            }

            let url=this.props.location.state.url ? this.props.location.state.url : this.props.match.url;
            this.setState({
                fClicked:true
            })

            axios.post("/support/consumer/merchantFlag", data)
            .then((res) => {
                // console.log("Response from flag merchant", res);
                if(res.data.Inserted){
                    this.setState({
                        fClicked:false
                    })
                    if(res.data.result.affectedRows === 1){

                        this.props.history.push({
                            pathname:url+'/BookingIdSearch',
                            state:{
                                booking_id: this.state.bId,
                                url,
                                active: this.state.active,
                                active1: this.state.active1,
                                active2: this.state.active2
                            }
                        });

                        return toast.success("Successfully Inserted",{
                            position : toast.POSITION.BOTTOM_CENTER
                        });
                    }
                } else {
                    if(res.data.dbError){
                        this.setState({
                            fClicked:false
                        })
                        toast.error("Internal Server Error",{
                            position : toast.POSITION.BOTTOM_CENTER
                        });
                    }
                }
            })
            .catch((err) => {
                console.log("Server Error",err);
                this.setState({
                    fClicked:false
                })
                toast.warn("Unable to Reach Server! Please Try Again!" , {
                    position: toast.POSITION.BOTTOM_CENTER
                  });
            })
        }
    }
    
    render() {
        // console.log("Booking Id In Merchant Flag Page", this.state);
        // console.log("Support User Id", this.props.u_id);

        const fWeightSpan={
            fontWeight:"500",
            marginRight: 5,
            fontFamily: 'Muli',
            color: '#868686'
        }
        const fontText1 = {
            fontWeight:'bold',
            fontFamily: 'Muli',
            color: '#563951'                        
        }

        return (
            <div>
                <Breadcrumb active={this.state.active} active0={this.state.active0} active1={this.state.active1} active2={this.state.active2} active3={'Flag Merchant'}/>
            <Container fluid>
            <div className="row justify-content-center" style={{backgroundColor: '#EFEFEF'}}>
            <div className="col-3" >
                    <Form>
                        <FormGroup row>
                            <Label><text style={fWeightSpan}> Professional Name </text></Label>
                            <Input type="text" style={{fontFamily: 'Muli',color: '#563951'}} value={this.state.mname} readOnly />
                        </FormGroup>
                        <Row>
                            <Col>
                                <FormGroup row>
                                    <Label for="IssueType" ><text style={fWeightSpan}>Issue Type </text><span style={{color:'red'}}> *</span></Label>
                                    <Input type="select" name="IssueType" onChange={this.issueTypeHandler}
                                            valid={this.state.issueType.valid}
                                            invalid={this.state.issueType.invalid}
                                            style={{fontFamily: 'Muli',color: '#563951'}}>
                                            {/* > */}
                                            <option value={0}>---Select---</option>
                                            <option value='type1'>type1</option>
                                            <option value='type2'>type2</option>
                                            <option value='type3'>type3</option>
                                            <option value='type4'>type4</option>
                                            <option value='type5'>type5</option>
                                    </Input>
                                    <FormFeedback><text style={fontText1}>Issue Type is Required</text></FormFeedback>
                                </FormGroup>
                            </Col>

                            <Col>
                                <FormGroup >
                                    <Label for="Security" ><text style={fWeightSpan}>Severity</text> <span style={{color:'red'}}> *</span></Label>
                                    <Input type="select" name="Security" onChange={this.severityHandler}
                                            valid={this.state.severity.valid}
                                            invalid={this.state.severity.invalid}
                                            style={{fontFamily: 'Muli',color: '#563951'}}>
                                            {/* > */}
                                        <option value={0}>---Select---</option>
                                        <option value={1}>Very Low</option>
                                        <option value={2}>Low</option>
                                        <option value={3}>Medium</option>
                                        <option value={4}>High</option>
                                        <option value={5}>Very High</option>
                                    </Input>
                                    <FormFeedback><text style={fontText1}>Severity is Required</text></FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        
                        <FormGroup row>
                            <Label for="comment"><text style={fWeightSpan}>Comment</text> <span style={{color:'red'}}> *</span></Label>
                            <Input  type="textarea" placeholder="Please type a comment"
                                    onChange={this.commentHandler}
                                    invalid={this.state.comment.invalid ? true : false}
                                    valid={this.state.comment.valid ? true : false}
                                    maxLength={1000}
                                    style={{height:100,fontFamily: 'Muli',color: '#563951'}}/>
                                    <FormFeedback><text style={fontText1}>Comment is required</text></FormFeedback>
                        </FormGroup>
                        
                        <FormGroup>
                            <Button style={{backgroundColor:"#2C2165", color:'white', margin:25}} disabled={this.state.fClicked} onClick={this.submitHandler}><text style={{fontFamily:'Muli'}}>Submit</text></Button>
                            <Button style={{backgroundColor:"#F49F3D"}} onClick={this.props.history.goBack} ><text style={{fontFamily:'Muli'}}>Go Back</text></Button>
                      
                        </FormGroup>
                    </Form>
                    <ToastContainer />
            </div>
            </div>
            </Container>
            </div>
        )
    }
} 

const mapStateToProps = state => {
    return {
        u_id : state.id
    }
}

export default connect(mapStateToProps)(FlagMerchant);