import React, {Component} from 'react';
import { Route,Redirect } from 'react-router-dom'; 
 
import Navbar from '../Navbar/Navbar';
import {connect} from "react-redux";

import CustDetails from '../SubScreen/customerDetails';
import BookingId from '../SubScreen/bookingId';
import MerchantDetails from '../SubScreen/merchantDetails';
import OfferDetails from '../SubScreen/offerDetails';
import CouponId from '../SubScreen/coupons';
import SupportTicket from '../SubScreen/supportTicketId';

import FlagMerchant from '../SubComponents/FlagMerchant';
import Feedback from '../Navbar/NavbarScreens/Feedback';
import BugReport from '../Navbar/NavbarScreens/BugReport';
import MyTicket from '../Navbar/NavbarScreens/MyTicket';
import ConsumerSearchDetail from "../SubScreen/ConsumerSearchDetails";
import DisputeTabView from '../SubComponents/DisputeTabView';
import CancellationView from '../SubComponents/CancellationsView';

import BookingsTabView from '../SubComponents/BookingsTabView';
import CouponSearchDetails from "../SubScreen/CouponSearchDetails";
import BookingIdSearch from "../SubScreen/BookingIdSearch";
import supportCreateCoupon from '../Navbar/NavbarScreens/supportCreateCoupon'
import supportViewCoupon from '../Navbar/NavbarScreens/supportViewCoupon'
import ConsumerSearchTicketId from '../SubScreen/ConsumerSearchTicketId';
import MerchantSearchDetails from '../SubScreen/merchantSearchDetails';
import OfferIdSearchDetails from '../SubScreen/OfferIdSearchDetails';
import CreateTicket from '../SubComponents/CreateTicket';

import ServiceDetails from '../SubScreen/ServiceDetails';
import ChangePassword from '../SubScreen/SupportUserChangePassword';

import MerchantCreatedOffer from '../SubScreen/MerchantCreatedOffers'
import MerchantCreatedCoupon from '../SubScreen/MerchantCreatedCoupons'
import MerchantCreatedServices from '../SubScreen/MerchantCreatedServices'
import MerchantPayments from '../SubScreen/MerchantPayments'
import MerchantPaymentsOfferview from '../SubScreen/MerchantPaymentsOfferview'
import MerchantPaymentsBookingView from '../SubScreen/MerchantPaymentsBookingView'
import MerchantDisputedBookings from '../SubScreen/MerchantDisputedBookings'
import SupportVersions from '../Navbar/NavbarScreens/SupportVersions';
import ConsumerBookingsTabView from '../SubComponents/ConsumerBookTabView';
import BookingConsumedView from '../SubComponents/BookingConsumedView'
import PaymentTransactions from '../SubScreen/PaymentTransactions';
import MerchantBookingsTabView from '../SubComponents/MerchantBookTabView';
import MerchCancellationsView from '../SubScreen/MerchCancellationsView';
import MerchBookingConsumedView from '../SubComponents/MerchBookingConsumedView';
import OfferCancellations from '../SubScreen/OfferCancellations';
import BookingsOfCoupon from '../SubScreen/BookingsOfCoupon';
import CancellationsView from '../SubComponents/BookingConsumedView';
import AllBookingsView from '../SubComponents/AllBookings';
import ImageData from '../SubComponents/ImageData'
import CityCatDisplay from '../Testing Screens/cityCatDisplay';
import EarnedCouponsView from '../SubScreen/ConsumerEarnedCoupons';
import  Categorysearch from '../MainScreens/SupportSearch/Category'
class Home extends Component {

    componentDidMount()
    {
        if(this.props.token)
        {
            console.log("from if cdm")

        }
        else
        {
            console.log("from else cdm")
            this.props.history.push("/error/unauth");
        }
    }

    render() {
        return (
            <div>
                <Navbar {...this.props} />
                <div style={{marginTop: 20, marginBottom: 20}}> 

                

                    {/* MainTabs */}

{/* for test purpose */}


                        <Route exact path='/home/myTicket' component={MyTicket} />   
                        <Route exact path='/home/feedback' component={Feedback} />   
                        <Route exact path='/home/bugreport' component={BugReport} /> 
                        <Route exact path='/home/supportViewCoupon' component={supportViewCoupon} /> 
                        <Route exact path='/home/versions' component={SupportVersions} /> 
                        

                    
                    
                    {/* SubTags */}

                        <Route exact path='/home/customerDetails' component={CustDetails} />
                        <Route exact path= '/home/merchantDetails' component={MerchantDetails} />
                        <Route exact path= '/home/offerDetails' component={OfferDetails} />
                        <Route exact path= '/home/supportTicket' component={SupportTicket} />
                        <Route exact path= '/home/bookingId' component={BookingId} />                       
                        <Route exact path= '/home/coupon' component={CouponId} />                       

                    
                    {/* Detail Pages */}

                        <Route exact path='/home/customerDetails/ConsumerSearchDetails' component={ConsumerSearchDetail} />
                        <Route exact path='/home/merchantDetails/MerchantSearchDetails' component={MerchantSearchDetails} />
                        <Route exact path='/home/merchantDetails/offerView' component={MerchantPaymentsOfferview} />
                        <Route exact path='/home/merchantDetails/offerView/bookingView' component={MerchantPaymentsBookingView} />
                        
                        <Route exact path='/home/offerDetails/OfferIdSearchDetails' component={OfferIdSearchDetails} />   
                        <Route exact path='/home/supportTicket/ConsumerSearchTicketId' component={ConsumerSearchTicketId} />
                        <Route exact path='/home/bookingId/BookingIdSearch' component={BookingIdSearch} />                          
                        <Route exact path='/home/coupon/CouponSearchDetails' component={CouponSearchDetails} />
                        
                    {/* sub Detail pages    */}
                          <Route exact path='/home/get/imagedata' component={ImageData}/>


                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/BookingIdSearch' component={BookingIdSearch} />
                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/MerchantSearchDetails' component={MerchantSearchDetails} />
                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/OfferIdSearchDetails' component={OfferIdSearchDetails} />
                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/CouponSearchDetails' component={CouponSearchDetails} />
                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/ConsumerSearchTicketId' component={ConsumerSearchTicketId} />
                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/ConsumerSearchTicketId/Disputes' component={DisputeTabView}/>
                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/ConsumerSearchTicketId/Cancellations' component={CancellationView}/>

                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/MerchantCreatedCoupon' component={MerchantCreatedCoupon} />
                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/MerchantCreatedOffer' component={MerchantCreatedOffer} />
                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/CreateTicket' component={CreateTicket} />
                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/FlagMerchant' component={FlagMerchant} />
                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/supportCreateCoupon' component={supportCreateCoupon} />
                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/Disputes' component={DisputeTabView}/>
                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/Cancellations' component={CancellationView}/>

                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/BookingIdSearch/Disputes' component={DisputeTabView}/>
                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/BookingIdSearch/Cancellations' component={CancellationView}/>

                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/BookingsTabView' component={BookingsTabView}/>
                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/MerchantPayments'component={MerchantPayments}/>
                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/offerView' component={MerchantPaymentsOfferview}/>
                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/bookingView' component={MerchantPaymentsBookingView}/>
                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/DisputedBookings' component={MerchantDisputedBookings}/>
                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/DisputedOverview' component={ConsumerSearchTicketId}/>
                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/ConsumerBookings' component={ConsumerBookingsTabView}/>
                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/BookingsConsumed' component={BookingConsumedView}/>
                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/consumerpayments' component={PaymentTransactions}/>
                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/BookingsOfCoupon' component={BookingsOfCoupon}/>
                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/allBookings' component={AllBookingsView}/>

                       
                        <Route exact path='/home/merchantDetails/MerchantSearchDetails/BookingIdSearch' component={BookingIdSearch} />
                        <Route exact path='/home/merchantDetails/MerchantSearchDetails/MerchantSearchDetails' component={MerchantSearchDetails} />
                        <Route exact path='/home/merchantDetails/MerchantSearchDetails/OfferIdSearchDetails' component={OfferIdSearchDetails} />
                        <Route exact path='/home/merchantDetails/MerchantSearchDetails/CouponSearchDetails' component={CouponSearchDetails} />
                        <Route exact path='/home/merchantDetails/MerchantSearchDetails/ConsumerSearchTicketId' component={ConsumerSearchTicketId} />
                        <Route exact path='/home/merchantDetails/MerchantSearchDetails/MerchantCreatedCoupon' component={MerchantCreatedCoupon} />
                        <Route exact path='/home/merchantDetails/MerchantSearchDetails/MerchantPayments' component={MerchantPayments} />
                        <Route exact path='/home/merchantDetails/MerchantSearchDetails/offerView' component={MerchantPaymentsOfferview}/>
                        <Route exact path='/home/merchantDetails/MerchantSearchDetails/bookingView' component={MerchantPaymentsBookingView}/>                    
                        <Route exact path='/home/merchantDetails/MerchantSearchDetails/MerchantCreatedOffer' component={MerchantCreatedOffer} />
                        <Route exact path='/home/merchantDetails/MerchantSearchDetails/CreateTicket' component={CreateTicket} />
                        <Route exact path='/home/merchantDetails/MerchantSearchDetails/FlagMerchant' component={FlagMerchant} />
                        <Route exact path='/home/merchantDetails/MerchantSearchDetails/supportCreateCoupon' component={supportCreateCoupon} />
                        <Route exact path='/home/merchantDetails/MerchantSearchDetails/BookingsTabView' component={BookingsTabView} />
                        <Route exact path='/home/merchantDetails/MerchantSearchDetails/Disputes' component={DisputeTabView} />
                        <Route exact path='/home/merchantDetails/MerchantSearchDetails/Cancellations' component={CancellationView} />
                        <Route exact path='/home/merchantDetails/MerchantSearchDetails/ConsumerBookings' component={ConsumerBookingsTabView} />
                        <Route exact path='/home/merchantDetails/MerchantSearchDetails/MerchantBookings' component={MerchantBookingsTabView}/>
                        <Route exact path='/home/merchantDetails/MerchantSearchDetails/DisputedBookings' component={MerchantDisputedBookings}/>
                        <Route exact path='/home/merchantDetails/MerchantSearchDetails/DisputedOverview' component={ConsumerSearchTicketId}/>
                        <Route exact path='/home/merchantDetails/MerchantSearchDetails/BookingsConsumed'  component={BookingConsumedView}/>
                        <Route exact path='/home/merchantDetails/MerchantSearchDetails/MerchBookingConsumedView' component={MerchBookingConsumedView}/>
                        <Route exact path='/home/merchantDetails/MerchantSearchDetails/consumerpayments' component={PaymentTransactions}/>
                        <Route exact path='/home/merchantDetails/MerchantSearchDetails/MerchCancellationsView' component={MerchCancellationsView}/>
                        <Route exact path='/home/merchantDetails/MerchantSearchDetails/BookingsOfCoupon'component={BookingsOfCoupon}/>
                        <Route exact path='/home/merchantDetails/MerchantSearchDetails/offerCancellations'component={OfferCancellations}/>
                        <Route exact path='/home/merchantDetails/MerchantSearchDetails/allBookings' component={AllBookingsView}/>

                     
                     
                        <Route exact path='/home/offerDetails/OfferIdSearchDetails/BookingIdSearch' component={BookingIdSearch} />
                        <Route exact path='/home/offerDetails/OfferIdSearchDetails/MerchantSearchDetails' component={MerchantSearchDetails} />
                        <Route exact path='/home/offerDetails/OfferIdSearchDetails/OfferIdSearchDetails' component={OfferIdSearchDetails} />
                        <Route exact path='/home/offerDetails/OfferIdSearchDetails/CouponSearchDetails' component={CouponSearchDetails} />
                        <Route exact path='/home/offerDetails/OfferIdSearchDetails/ConsumerSearchTicketId' component={ConsumerSearchTicketId} />
                        <Route exact path='/home/offerDetails/OfferIdSearchDetails/MerchantCreatedCoupon' component={MerchantCreatedCoupon} />
                        <Route exact path='/home/offerDetails/OfferIdSearchDetails/MerchantCreatedOffer' component={MerchantCreatedOffer} />
                        <Route exact path='/home/offerDetails/OfferIdSearchDetails/CreateTicket' component={CreateTicket} />
                        <Route exact path='/home/offerDetails/OfferIdSearchDetails/FlagMerchant' component={FlagMerchant} />
                        <Route exact path='/home/offerDetails/OfferIdSearchDetails/supportCreateCoupon' component={supportCreateCoupon} />
                        <Route exact path='/home/offerDetails/OfferIdSearchDetails/BookingsTabView' component={BookingsTabView} />
                        <Route exact path='/home/offerDetails/OfferIdSearchDetails/Disputes' component={DisputeTabView}/>
                        <Route exact path='/home/offerDetails/OfferIdSearchDetails/Cancellations' component={CancellationView}/>
                        <Route exact path='/home/offerDetails/OfferIdSearchDetails/offerCancellations' component={OfferCancellations}/>
                        <Route exact path='/home/offerDetails/OfferIdSearchDetails/MerchantPayments'component={MerchantPayments}/>
                        <Route exact path='/home/offerDetails/OfferIdSearchDetails/offerView' component={MerchantPaymentsOfferview}/>
                        <Route exact path='/home/offerDetails/OfferIdSearchDetails/bookingView' component={MerchantPaymentsBookingView}/>
                        <Route exact path='/home/offerDetails/OfferIdSearchDetails/DisputedBookings' component={MerchantDisputedBookings}/>
                        <Route exact path='/home/offerDetails/OfferIdSearchDetails/DisputedOverview' component={ConsumerSearchTicketId}/>
                        <Route exact path='/home/offerDetails/OfferIdSearchDetails/BookingsConsumed' component={BookingConsumedView}/>
                        <Route exact path='/home/offerDetails/OfferIdSearchDetails/ConsumerBookings' component={ConsumerBookingsTabView}/>
                        <Route exact path='/home/offerDetails/OfferIdSearchDetails/consumerpayments' component={PaymentTransactions}/>   
                        <Route exact path='/home/offerDetails/OfferIdSearchDetails/allBookings' component={AllBookingsView}/>   
                        <Route exact path='/home/offerDetails/OfferIdSearchDetails/MerchantBookings' component={MerchantBookingsTabView}/>  
                        <Route exact path='/home/offerDetails/OfferIdSearchDetails/MerchBookingConsumedView' component={MerchBookingConsumedView} />
                        <Route exact path='/home/offerDetails/OfferIdSearchDetails/MerchCancellationsView'  component={OfferCancellations}/>
                        <Route exact path='/home/offerDetails/OfferIdSearchDetails/BookingsOfCoupon' component={BookingsOfCoupon}/>
                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/offerCancellations' component={OfferCancellations}/>
                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/MerchantBookings' component={MerchantBookingsTabView}/>
                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/MerchBookingConsumedView' component={MerchBookingConsumedView}/>
                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/MerchCancellationsView' component={OfferCancellations}/>

                        
                        
                        
                        
                        
                        
                        <Route exact path='/home/supportTicket/ConsumerSearchTicketId/BookingIdSearch' component={BookingIdSearch} />
                        <Route exact path='/home/supportTicket/ConsumerSearchTicketId/MerchantSearchDetails' component={MerchantSearchDetails} />
                        <Route exact path='/home/supportTicket/ConsumerSearchTicketId/OfferIdSearchDetails' component={OfferIdSearchDetails} />
                        <Route exact path='/home/supportTicket/ConsumerSearchTicketId/CouponSearchDetails' component={CouponSearchDetails} />
                        <Route exact path='/home/supportTicket/ConsumerSearchTicketId/ConsumerSearchTicketId' component={ConsumerSearchTicketId} />
                        <Route exact path='/home/supportTicket/ConsumerSearchTicketId/MerchantCreatedCoupon' component={MerchantCreatedCoupon} />
                        <Route exact path='/home/supportTicket/ConsumerSearchTicketId/MerchantCreatedOffer' component={MerchantCreatedOffer} />
                        <Route exact path='/home/supportTicket/ConsumerSearchTicketId/CreateTicket' component={CreateTicket} />
                        <Route exact path='/home/supportTicket/ConsumerSearchTicketId/FlagMerchant' component={FlagMerchant} />
                        <Route exact path='/home/supportTicket/ConsumerSearchTicketId/supportCreateCoupon' component={supportCreateCoupon} />
                        <Route exact path='/home/supportTicket/ConsumerSearchTicketId/Disputes' component={DisputeTabView}/>
                        <Route exact path='/home/supportTicket/ConsumerSearchTicketId/Cancellations' component={CancellationView}/>
                        <Route exact path='/home/supportTicket/ConsumerSearchTicketId/ConsumerBookings' component={ConsumerBookingsTabView}/>
                        <Route exact path='/home/supportTicket/ConsumerSearchTicketId/BookingsConsumed' component={BookingConsumedView}/>
                        
                        <Route exact path='/home/supportTicket/ConsumerSearchTicketId/BookingsTabView' component={BookingsTabView}/>
                        <Route exact path='/home/supportTicket/ConsumerSearchTicketId/MerchantPayments'component={MerchantPayments}/>
                        <Route exact path='/home/supportTicket/ConsumerSearchTicketId/offerView' component={MerchantPaymentsOfferview}/>
                        <Route exact path='/home/supportTicket/ConsumerSearchTicketId/bookingView' component={MerchantPaymentsBookingView}/>
                        <Route exact path='/home/supportTicket/ConsumerSearchTicketId/DisputedBookings' component={MerchantDisputedBookings}/>
                        <Route exact path='/home/supportTicket/ConsumerSearchTicketId/DisputedOverview' component={ConsumerSearchTicketId}/>
                        <Route exact path='/home/supportTicket/ConsumerSearchTicketId/consumerpayments'component={PaymentTransactions}/>
                        <Route exact path='/home/supportTicket/ConsumerSearchTicketId/MerchantBookings'component={MerchantBookingsTabView}/>
                        <Route exact path='/home/supportTicket/ConsumerSearchTicketId/MerchBookingConsumedView' component={MerchBookingConsumedView}/>
                        <Route exact path='/home/supportTicket/ConsumerSearchTicketId/MerchCancellationsView'component={MerchCancellationsView}/>
                        <Route exact path='/home/supportTicket/ConsumerSearchTicketId/BookingsOfCoupon' component={BookingsOfCoupon}/>
                        <Route exact path='/home/supportTicket/ConsumerSearchTicketId/offerCancellations'component={OfferCancellations}/>
                        <Route exact path='/home/supportTicket/ConsumerSearchTicketId/allBookings'component={AllBookingsView}/>

                        
                        
                        <Route exact path='/home/bookingId/BookingIdSearch/BookingIdSearch' component={BookingIdSearch} />
                        <Route exact path='/home/bookingId/BookingIdSearch/MerchantSearchDetails' component={MerchantSearchDetails} />
                        <Route exact path='/home/bookingId/BookingIdSearch/OfferIdSearchDetails' component={OfferIdSearchDetails} />
                        <Route exact path='/home/bookingId/BookingIdSearch/CouponSearchDetails' component={CouponSearchDetails} />
                        <Route exact path='/home/bookingId/BookingIdSearch/ConsumerSearchTicketId' component={ConsumerSearchTicketId} />
                        <Route exact path='/home/bookingId/BookingIdSearch/MerchantCreatedCoupon' component={MerchantCreatedCoupon} />
                        <Route exact path='/home/bookingId/BookingIdSearch/MerchantCreatedOffer' component={MerchantCreatedOffer} />
                        <Route exact path='/home/bookingId/BookingIdSearch/CreateTicket' component={CreateTicket} />
                        <Route exact path='/home/bookingId/BookingIdSearch/FlagMerchant' component={FlagMerchant} />
                        <Route exact path='/home/bookingId/BookingIdSearch/supportCreateCoupon' component={supportCreateCoupon} />
                        <Route exact path='/home/bookingId/BookingIdSearch/Disputes' component={DisputeTabView} />
                        <Route exact path='/home/bookingId/BookingIdSearch/Cancellations' component={CancellationView} />
                        <Route exact path='/home/bookingId/BookingIdSearch/ConsumerBooking' component={ConsumerBookingsTabView}/>
                        {/* <Route exact path='/home/bookingId/BookingIdSearch/offerView' component={MerchantPaymentsOfferview}/> */}

                        <Route exact path='/home/bookingId/BookingIdSearch/ConsumerBookings' component={ConsumerBookingsTabView}/>

                        <Route exact path='/home/bookingId/BookingIdSearch/BookingsTabView' component={BookingsTabView} />
                        <Route exact path='/home/bookingId/BookingIdSearch/MerchantPayments'component={MerchantPayments}/>
                        <Route exact path='/home/bookingId/BookingIdSearch/offerView' component={MerchantPaymentsOfferview}/>
                        <Route exact path='/home/bookingId/BookingIdSearch/bookingView' component={MerchantPaymentsBookingView}/>
                        <Route exact path='/home/bookingId/BookingIdSearch/DisputedBookings' component={MerchantDisputedBookings}/>
                        <Route exact path='/home/bookingId/BookingIdSearch/DisputedOverview' component={ConsumerSearchTicketId}/>
                        <Route exact path='/home/bookingId/BookingIdSearch/BookingsConsumed' component={BookingConsumedView}/>
                        <Route exact path='/home/bookingId/BookingIdSearch/consumerpayments' component={PaymentTransactions}/>
                        <Route exact path='/home/bookingId/BookingIdSearch/BookingsOfCoupon' component={BookingsOfCoupon}/>
                        <Route exact path='/home/bookingId/BookingIdSearch/offerCancellations' component={OfferCancellations}/>
                        <Route exact path='/home/bookingId/BookingIdSearch/MerchantBookings'component={MerchantBookingsTabView}/>
                        <Route exact path='/home/bookingId/BookingIdSearch/MerchBookingConsumedView'component={MerchBookingConsumedView}/>
                        <Route exact path='/home/bookingId/BookingIdSearch/allBookings' component={AllBookingsView}/>
 

                        <Route exact path='/home/bookingId/BookingIdSearch/MerchCancellationsView'component={OfferCancellations}/>
                        
                        <Route exact path='/home/coupon/CouponSearchDetails/BookingIdSearch' component={BookingIdSearch} />
                        <Route exact path='/home/coupon/CouponSearchDetails/MerchantSearchDetails' component={MerchantSearchDetails} />
                        <Route exact path='/home/coupon/CouponSearchDetails/OfferIdSearchDetails' component={OfferIdSearchDetails} />
                        <Route exact path='/home/coupon/CouponSearchDetails/CouponSearchDetails' component={CouponSearchDetails} />
                        <Route exact path='/home/coupon/CouponSearchDetails/ConsumerSearchTicketId' component={ConsumerSearchTicketId} />
                        <Route exact path='/home/coupon/CouponSearchDetails/MerchantCreatedCoupon' component={MerchantCreatedCoupon} />
                        <Route exact path='/home/coupon/CouponSearchDetails/MerchantCreatedOffer' component={MerchantCreatedOffer} />
                        <Route exact path='/home/coupon/CouponSearchDetails/CreateTicket' component={CreateTicket} />
                        <Route exact path='/home/coupon/CouponSearchDetails/FlagMerchant' component={FlagMerchant} />
                        <Route exact path='/home/coupon/CouponSearchDetails/supportCreateCoupon' component={supportCreateCoupon} />
                        <Route exact path='/home/coupon/CouponSearchDetails/BookingsTabView' component={BookingsTabView} />
                        <Route exact path='/home/coupon/CouponSearchDetails/Disputes' component={DisputeTabView}/>
                        <Route exact path='/home/coupon/CouponSearchDetails/Cancellations' component={CancellationView}/>
                        <Route exact path='/home/coupon/CouponSearchDetails/ConsumerBookings' component={ConsumerBookingsTabView}/>
                        <Route exact path='/home/coupon/CouponSearchDetails/BookingsConsumed' component={BookingConsumedView}/>
                        <Route exact path='/home/coupon/CouponSearchDetails/BookingsOfCoupon' component={BookingsOfCoupon}/>
                        <Route exact path='/home/coupon/CouponSearchDetails/MerchantPayments'component={MerchantPayments}/>
                        <Route exact path='/home/coupon/CouponSearchDetails/offerView' component={MerchantPaymentsOfferview}/>
                        <Route exact path='/home/coupon/CouponSearchDetails/bookingView' component={MerchantPaymentsBookingView}/>
                        <Route exact path='/home/coupon/CouponSearchDetails/DisputedBookings' component={MerchantDisputedBookings}/>
                        <Route exact path='/home/coupon/CouponSearchDetails/DisputedOverview' component={ConsumerSearchTicketId}/>
                        <Route exact path='/home/coupon/CouponSearchDetails/consumerpayments' component={PaymentTransactions}/>
                        <Route exact path='/home/coupon/CouponSearchDetails/MerchantBookings'component={MerchantBookingsTabView}/> 
                        <Route exact path='/home/coupon/CouponSearchDetails/MerchBookingConsumedView' component={MerchBookingConsumedView}/>
                        <Route exact path='/home/coupon/CouponSearchDetails/MerchCancellationsView'component={MerchCancellationsView}/>
                        <Route exact path='/home/coupon/CouponSearchDetails/offerCancellations' component={OfferCancellations}/>
                        <Route exact path='/home/coupon/CouponSearchDetails/allBookings' component={AllBookingsView}/>

                            
                       
                        <Route exact path='/home/myTicket/BookingIdSearch' component={BookingIdSearch} />
                        <Route exact path='/home/myTicket/MerchantSearchDetails' component={MerchantSearchDetails} />
                        <Route exact path='/home/myTicket/OfferIdSearchDetails' component={OfferIdSearchDetails} />
                        <Route exact path='/home/myTicket/CouponSearchDetails' component={CouponSearchDetails} />
                        <Route exact path='/home/myTicket/ConsumerSearchTicketId' component={ConsumerSearchTicketId} />
                        <Route exact path='/home/myTicket/MerchantCreatedCoupon' component={MerchantCreatedCoupon} />
                        <Route exact path='/home/myTicket/MerchantCreatedOffer' component={MerchantCreatedOffer} />
                        <Route exact path='/home/myTicket/CreateTicket' component={CreateTicket} />
                        <Route exact path='/home/myTicket/FlagMerchant' component={FlagMerchant} />
                        <Route exact path='/home/myTicket/supportCreateCoupon' component={supportCreateCoupon} /> 
                        <Route exact path='/home/myTicket/Disputes' component={DisputeTabView} />
                        <Route exact path='/home/myTicket/Cancellations' component={CancellationView} />
                        <Route exact path='/home/myTicket/ConsumerBookings' component={ConsumerBookingsTabView}/>
                        <Route exact path='/home/myTicket/BookingsConsumed' component={BookingConsumedView}/>
                        <Route exact path='/home/myTicket/consumerpayments' component={PaymentTransactions}/>
                        <Route exact path='/home/myTicket/allBookings' component={AllBookingsView}/>

                        <Route exact path='/home/myTicket/BookingsTabView' component={BookingsTabView} />
                        <Route exact path='/home/myTicket/MerchantPayments'component={MerchantPayments}/>
                        <Route exact path='/home/myTicket/offerView' component={MerchantPaymentsOfferview}/>
                        <Route exact path='/home/myTicket/bookingView' component={MerchantPaymentsBookingView}/>
                        <Route exact path='/home/myTicket/DisputedBookings' component={MerchantDisputedBookings}/>
                        <Route exact path='/home/myTicket/DisputedOverview' component={ConsumerSearchTicketId}/>
                        <Route exact path='/home/myTicket/MerchantBookings' component={MerchantBookingsTabView}/>
                        <Route exact path='/home/myTicket/MerchBookingConsumedView' component={MerchBookingConsumedView}/>
                        <Route exact path='/home/myTicket/MerchCancellationsView'component={MerchCancellationsView}/>
                        <Route exact path='/home/myTicket/BookingsOfCoupon' component={BookingsOfCoupon}/>
                        <Route exact path='/home/myTicket/offerCancellations' component={OfferCancellations}/>
                    
                        <Route exact path='/home/changePassword' component={ChangePassword} />
                        <Route exact path='/home/cityCategoryDisplay' component={CityCatDisplay} />
                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/earnedCoupons' component={EarnedCouponsView}/>
                        <Route exact path='/home/bookingId/BookingIdSearch/earnedCoupons' component={EarnedCouponsView}/>
                        <Route exact path='/home/supportTicket/ConsumerSearchTicketId/earnedCoupons'component={EarnedCouponsView}/>
                        <Route exact path='/home/myTicket/earnedCoupons' component={EarnedCouponsView}/>
                        <Route exact path='/home/coupon/CouponSearchDetails/earnedCoupons' component={EarnedCouponsView}/>
                        <Route exact path='/home/merchantDetails/MerchantSearchDetails/earnedCoupons' component={EarnedCouponsView}/>
                        <Route exact path='/home/offerDetails/OfferIdSearchDetails/earnedCoupons' component={EarnedCouponsView}/>
                        <Route exact path='/home/categorysearch' component={Categorysearch} />
                        <Route exact path='/home/merchantDetails/MerchantSearchDetails/ServicesCreated' component={MerchantCreatedServices}/>
                        <Route exact path='/home/myTicket/ServicesCreated' component={MerchantCreatedServices} />
                        <Route exact path='/home/coupon/CouponSearchDetails/ServicesCreated' component={MerchantCreatedServices} />
                        <Route exact path='/home/bookingId/BookingIdSearch/ServicesCreated' component={MerchantCreatedServices} />
                        <Route exact path='/home/supportTicket/ConsumerSearchTicketId/ServicesCreated' component={MerchantCreatedServices} />
                        <Route exact path='/home/offerDetails/OfferIdSearchDetails/ServicesCreated' component={MerchantCreatedServices} />
                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/ServicesCreated' component={MerchantCreatedServices} />
                        <Route exact path='/home/merchantDetails/MerchantSearchDetails/ServiceDetails' component={ServiceDetails}/>
                        <Route exact path='/home/myTicket/ServiceDetails' component={ServiceDetails} />
                        <Route exact path='/home/coupon/CouponSearchDetails/ServiceDetails' component={ServiceDetails} />
                        <Route exact path='/home/bookingId/BookingIdSearch/ServiceDetails' component={ServiceDetails} />
                        <Route exact path='/home/supportTicket/ConsumerSearchTicketId/ServiceDetails' component={ServiceDetails} />
                        <Route exact path='/home/offerDetails/OfferIdSearchDetails/ServiceDetails' component={ServiceDetails} />
                        <Route exact path='/home/customerDetails/ConsumerSearchDetails/ServiceDetails' component={ServiceDetails} />
                        <Route exact path='/home/offerDetails/ServiceDetails' component={ServiceDetails} />
                       
                        <Route exact path='/home/offerDetails/ServiceDetails/ServiceDetails' component={ServiceDetails} />
                        <Route exact path='/home/offerDetails/ServiceDetails/BookingIdSearch' component={BookingIdSearch} />
                        <Route exact path='/home/offerDetails/ServiceDetails/MerchantSearchDetails' component={MerchantSearchDetails} />
                        <Route exact path='/home/offerDetails/ServiceDetails/OfferIdSearchDetails' component={OfferIdSearchDetails} />
                        <Route exact path='/home/offerDetails/ServiceDetails/CouponSearchDetails' component={CouponSearchDetails} />
                        <Route exact path='/home/offerDetails/ServiceDetails/ConsumerSearchTicketId' component={ConsumerSearchTicketId} />
                        <Route exact path='/home/offerDetails/ServiceDetails/MerchantCreatedCoupon' component={MerchantCreatedCoupon} />
                        <Route exact path='/home/offerDetails/ServiceDetails/MerchantCreatedOffer' component={MerchantCreatedOffer} />
                        <Route exact path='/home/offerDetails/ServiceDetails/CreateTicket' component={CreateTicket} />
                        <Route exact path='/home/offerDetails/ServiceDetails/FlagMerchant' component={FlagMerchant} />
                        <Route exact path='/home/offerDetails/ServiceDetails/supportCreateCoupon' component={supportCreateCoupon} />
                        <Route exact path='/home/offerDetails/ServiceDetails/BookingsTabView' component={BookingsTabView} />
                        <Route exact path='/home/offerDetails/ServiceDetails/Disputes' component={DisputeTabView}/>
                        <Route exact path='/home/offerDetails/ServiceDetails/Cancellations' component={CancellationView}/>
                        <Route exact path='/home/offerDetails/ServiceDetails/offerCancellations' component={OfferCancellations}/>
                        <Route exact path='/home/offerDetails/ServiceDetails/MerchantPayments'component={MerchantPayments}/>
                        <Route exact path='/home/offerDetails/ServiceDetails/offerView' component={MerchantPaymentsOfferview}/>
                        <Route exact path='/home/offerDetails/ServiceDetails/bookingView' component={MerchantPaymentsBookingView}/>
                        <Route exact path='/home/offerDetails/ServiceDetails/DisputedBookings' component={MerchantDisputedBookings}/>
                        <Route exact path='/home/offerDetails/ServiceDetails/DisputedOverview' component={ConsumerSearchTicketId}/>
                        <Route exact path='/home/offerDetails/ServiceDetails/BookingsConsumed' component={BookingConsumedView}/>
                        <Route exact path='/home/offerDetails/ServiceDetails/ConsumerBookings' component={ConsumerBookingsTabView}/>
                        <Route exact path='/home/offerDetails/ServiceDetails/consumerpayments' component={PaymentTransactions}/>   
                        <Route exact path='/home/offerDetails/ServiceDetails/allBookings' component={AllBookingsView}/>   
                        <Route exact path='/home/offerDetails/ServiceDetails/MerchantBookings' component={MerchantBookingsTabView}/>  
                        <Route exact path='/home/offerDetails/ServiceDetails/MerchBookingConsumedView' component={MerchBookingConsumedView} />
                        <Route exact path='/home/offerDetails/ServiceDetails/MerchCancellationsView'  component={OfferCancellations}/>
                        <Route exact path='/home/offerDetails/ServiceDetails/BookingsOfCoupon' component={BookingsOfCoupon}/>
                        <Route exact path='/home/offerDetails/ServiceDetails/earnedCoupons' component={EarnedCouponsView}/>
                        <Route exact path='/home/offerDetails/ServiceDetails/ServicesCreated' component={MerchantCreatedServices}/>


                </div>           
            </div>
        );
    }
}
const mapStateToProps=state=>{
    return {
      token:state.token
    }
  }  

export default connect(mapStateToProps)(Home);