import React, { Component } from 'react';
import { Form, FormGroup, Label, Input, Button, FormFeedback, Container, Row, Col } from 'reactstrap';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from "axios";
import {BarLoader} from "react-spinners";
import TableView from "../SubComponents/ViewByConsumerDetails";
import {connect} from "react-redux";


const strings={
    invalidMobile:{
      en:"Please enter a valid mobile number",
      es:"Por favor, introduzca un número de móvil válido"
    },
    invalidName:{
      en:"Email field cannot be blank",
      es:"El campo de correo electrónico no puede estar en blanco"
    }
  }
  
 

class CustDetails extends Component {

    state = {
        email : {
            value : '',
            valid : false,
            invalid : false,
            emailQ: false
        },
        phone : {
            value : '',
            valid: false,
            invalid: false,
            phoneQ: false
        },
        submit : {
            invalid: false
        },
        loading:false,
        userDetails:[],
        submitButton:false,
        noData: false
    } 

    emailTextHandler = (event) => {
        // let k=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
 
        if(event.target.value.length!==0 || event.target.value === ''){
            this.setState({
                email : {
                    ...this.state.email,
                    value : event.target.value,
                    valid: true,
                    invalid: false,
                    emailQ: true,
                },
                submit: {
                    ...this.state.submit,
                    invalid: false
                },
                phone:{
                    ...this.state.phone,
                    value: '',
                    valid:false,
                    invalid: false,
                    phoneQ: false
                },
                noData: false
            }); 
        }           
            else {
                this.setState({
                    email: {
                        ...this.state.email,
                        value: null,
                        valid: false,
                        // invalid: true
                    },
                    // submit: {
                    //     ...this.state.submit,
                    //     invalid: true
                    // }
                });            
            }               
        }
        
    phoneNumberHandler = (event) => {

        let k =/^[0-9\b]+$/;

        if(event.target.value === '' || k.test(event.target.value)){
            this.setState({
                phone : {
                    ...this.state.phone,
                    value : event.target.value,
                    valid: true,
                    invalid: false,
                    phoneQ: true
                },
                submit: {
                    ...this.state.submit,
                    invalid: false
                },
                email:{
                    ...this.state.email,
                    value: '',
                    valid:false,
                    invalid:false,
                    emailQ: false
                },
                noData: false
            });
        } 
        // else {
        //     this.setState({
        //         phone : {
        //             ...this.state.phone,
        //             value : null,
        //             valid: false,
        //             invalid: true
        //         },
        //         submit: {
        //             ...this.state.submit,
        //             invalid: true
        //         }
        //     });
        // }  
    } 

    submitButtonHandler = () => {
        let k=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(!this.state.phone.phoneQ)
        {
            if(this.state.email.value === '' && this.state.phone.value === ''){
                this.setState({
                    ...this.state,
                    email: {
                        ...this.state.email,
                        valid: false,
                        invalid: true
                    },
                    submit: {
                        ...this.state.submit,
                        invalid: true,
                    }
                })
            }
        }


        if(this.state.email.emailQ){
            if(this.state.email.invalid || this.state.email.value === null || this.state.email.value === ''||!k.test(this.state.email.value)){


                this.setState({
                    submit: {
                        ...this.state.submit,
                        invalid: true 
                    },
                    email:{
                        ...this.state.email,
                        valid: false,
                        invalid: true
                    }
                });
                return false;
                
            } else if(this.state.email.valid || this.state.email.value !== null || this.state.email.value !== '') {

                        this.setState({
                            submit: {
                                ...this.state.submit,
                                invalid: false,
                            },
                            loading:true,
                            submitButton:true
                        });

                    let userDet={
                        email:this.state.email.value,
                    }
                    axios.post("/support/consumer/consumerDetails",userDet)
                    .then(res=>{

                        if(res.data.search)
                        {
                            this.setState({
                                userDetails:res.data.results2 ? res.data.results2 : res.data.results,
                                loading:false
                            });
                            return this.props.history.push({ 
                                pathname:"/home/customerDetails/ConsumerSearchDetails",
                                state:{
                                    consumer_prid: res.data.results[0].QF_CONSUMER_PRID,
                                    conLink: '/home/customerDetails',
                                    con: 'Consumer Details'
                                }
                            });
                        }
                        else
                        {
                            if(res.data.dbError===true)
                            {
                                toast.error("Internal Server Error",{position:toast.POSITION.BOTTOM_CENTER});
                                

                            }
                            this.setState({loading:false,noData: true});
                        }

                    }).catch(err=>{
                        toast.warn("Unable to Reach Server! Please Try Again!",{position:toast.POSITION.BOTTOM_CENTER});
                        this.setState({loading:false})
                    });
            }
        } else if(this.state.phone.phoneQ){
            if(this.state.phone.invalid || !(/^\(?([0-9]{0,10})$/.test(this.state.phone.value)) || this.state.phone.value.length !== 10){

                this.setState({
                    submit: { 
                        ...this.state.submit,
                        invalid: true 
                    },
                    phone: {
                        ...this.state.phone,
                        valid: false,
                        invalid: true
                    }
                });
            // return toast.error("Phone Number is Invalid !", {
            //     position: toast.POSITION.BOTTOM_CENTER
            // });
        } else if(this.state.submit.invalid){
            this.setState({
                submit: {
                    ...this.state.submit,
                    invalid: true 
                }
            });
            // return toast.error("Fields are Invalid !", {
            //     position: toast.POSITION.BOTTOM_CENTER
            // });
        } else {
                this.setState({
                    submit: {
                        ...this.state.submit,
                        invalid: false,
                    },
                    phone: {
                        ...this.state.phone,
                        valid: true,
                        invalid: false
                    },
                    loading:true,
                    submitButton:true
                });

            let userDet={
                mobile:this.state.phone.value
            }
            axios.post("/support/consumer/consumerDetails",userDet)
            .then(res=>{   
                if(res.data.search)
                {
                    this.setState({
                        userDetails:res.data.userDet,
                        loading:false
                    });

                    return this.props.history.push({ 
                        pathname:"/home/customerDetails/ConsumerSearchDetails",
                        state:{
                               consumer_prid: res.data.results[0].QF_CONSUMER_PRID,
                               conLink: '/home/customerDetails',
                               Con: 'Consumer Details'
                        }
                    });
                }
                else
                {
                    if(res.data.dbError===true)
                    {
                        toast.error("Internal Server Error",{position:toast.POSITION.BOTTOM_CENTER});
                        

                    }

                    this.setState({loading:false,noData: true})
                }

            }).catch(err=>{
                toast.warn("Unable to Reach Server! Please Try Again!",{position:toast.POSITION.BOTTOM_CENTER});
                this.setState({loading:false})
            });
        }
        }  
    }

    onKeyDown=(event)=>{
        if(event.key==='Enter')
        {
            event.preventDefault();
            event.stopPropagation();
            this.submitButtonHandler();
        }
        
    }
    
    
    render() {

        // console.log("State", strings.invalidName['en']);
        // console.log("Country", this.props.country)


        let tView=null;

        // if(this.state.userDetails.length!==0)
        // { 
        //     tView=(
        //         <TableView {...this.props} active={'/home/customerDetails'} active1={'Consumer Details'} active2={'Offer Booking Details'} userDet={this.state.userDetails} />
        //     )
        // }
        // else
        // {
            if(this.state.noData)
            {
                tView=(<p>No results to display</p>)
            }
            else
            {
                tView=null;
            }
        // }

        return (
              <div>  
                  <Container fluid>
                
                <div className="row justify-content-center" style={{backgroundColor: '#EFEFEF'}}>                
                <div className="col-3" >

            
                    <Form> 
                        <FormGroup row>
                                <Label for="email"><text style={{fontFamily:'Muli'}}>Email </text></Label>
                                <Input  invalid={this.state.email.invalid ? true : false} 
                                        type='email'
                                        valid={this.state.email.valid ? true : false} 
                                        onChange={this.emailTextHandler} 
                                        placeholder="Please enter email" 
                                        onKeyDown={this.onKeyDown}
                                        autoFocus
                                        value={this.state.email.value}
                                        onClick={this.emailTextHandler}
                                        
                                        />
                                <FormFeedback><text style={{fontFamily:'Muli'}}> Enter Valid Email! </text></FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label><text style={{fontFamily:'Muli'}}>OR</text></Label>
                        </FormGroup>
                        <Row form>
                            <Col sm={12}>
                                <FormGroup row>
                                    <Label for="Phone"><text style={{fontFamily:'Muli'}}>Mobile Number </text></Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup row>
                                    <Input style={{width: 60}} value={this.props.country === 'India' ? '+91' : this.props.country === 'USA' ? '+1' : '+91'}/>
                                </FormGroup>
                            </Col>

                            <Col sm={{size:10}}>
                                <FormGroup>                                
                                    <Input  invalid={this.state.phone.invalid ? true : false}
                                            valid={this.state.phone.valid ? true : false} 
                                            style={{marginLeft:5}}
                                            onChange={this.phoneNumberHandler} 
                                            placeholder="Please enter Mobile Number"
                                            value={this.state.phone.value}
                                            maxLength={10}
                                            onKeyDown={this.onKeyDown}
                                            id='phone'
                                            onClick={this.phoneNumberHandler}
                                            required />
                                    <FormFeedback><text style={{fontFamily:'Muli'}}> Enter Valid Phone Number!</text> </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        
                        <FormGroup>
                            <Button className="mb-4"
                                    onClick={this.submitButtonHandler} 
                                    style={{backgroundColor:"#2C2165", color:'white'}} disabled={this.state.loading?true:false}><text style={{fontFamily:'Muli'}}> Search </text></Button>

                        </FormGroup>                    
                    </Form> 
                    <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/>

                    {tView}

                    <ToastContainer />                            
                    </div>
                </div>


                </Container>
            </div>
        )
    }
} 

const mapStateToProps = (state) => {
    return {
        country: state.country
    }
}

export default connect(mapStateToProps)(CustDetails);