import React, {Component} from 'react';
import { Table, Button, Col } from 'reactstrap';

class TableView extends Component {
  
  componentDidMount(){ 
  }

  detialClickHandler=(consumer_prid)=>{

    this.props.history.push({ 
      pathname:this.props.match.url+"/ConsumerSearchDetails",
      state:{
             consumer_prid,
             active: this.props.active,
             active1: this.props.active1,
             active2: this.props.active2
      }
  });

    
  }

//   CreateTicketHandler = (id) => {
//     this.props.history.push({
//         pathname:this.props.match.url+"/CreateTicket",
//         state:{
//           id: id
//         }
//     });
// }

  render() {
      const tableData = this.props.userDet.map((person) => {
          return (
            <tr style={{cursor:"pointer"}} key={person.QF_CONSUMER_PRID}>
              
              <td onClick={()=>this.detialClickHandler(person.QF_CONSUMER_PRID)}>{person.CONSUMER_EMAIL}</td>
              <td onClick={()=>this.detialClickHandler(person.QF_CONSUMER_PRID)}>{person.CONSUMER_FNAME?person.CONSUMER_FNAME:"Name not updated"}</td>
              <td onClick={()=>this.detialClickHandler(person.QF_CONSUMER_PRID)}>{person.CONSUMER_PHONE}</td>
              <td onClick={()=>this.detialClickHandler(person.QF_CONSUMER_PRID)}>
              {
                  person.ACCOUNT_VERIFIED_FLAG
                  ?
                  <p style={{backgroundColor:"green",borderRadius:"2px",color:"#fff",marginLeft:"15px",marginRight:"10px"}}>{person.ACCOUNT_VERIFIED_FLAG?"Yes":"No"}</p>
                  :
                  <p style={{backgroundColor:"red",borderRadius:"2px",color:"#fff",marginLeft:"15px",marginRight:"10px"}}>{person.ACCOUNT_VERIFIED_FLAG?"Yes":"No"}</p>

                }
              </td>
              <td onClick={()=>this.detialClickHandler(person.QF_CONSUMER_PRID)}>
                {
                  person.MOBILE_VERIFIED_FLAG
                  ?
                  <p style={{backgroundColor:"green",borderRadius:"2px",color:"#fff",marginLeft:"15px",marginRight:"10px"}}>{person.MOBILE_VERIFIED_FLAG?"Yes":"No"}</p>
                  :
                  <p style={{backgroundColor:"red",borderRadius:"2px",color:"#fff",marginLeft:"15px",marginRight:"10px"}}>{person.MOBILE_VERIFIED_FLAG?"Yes":"No"}</p>

                }
                
                </td>
              <td onClick={()=>this.detialClickHandler(person.QF_CONSUMER_PRID)}>{person.CONSUMER_ADDRESS_CITY}</td>
              <td onClick={()=>this.detialClickHandler(person.QF_CONSUMER_PRID)}>{person.CONSUMER_ADDRESS_COUNTRY}</td>
              {/* <td><Button style={{padding:"1px 5px 1px 5px"}} color="primary" onClick={() => this.CreateTicketHandler(person.QF_CONSUMER_PRID)}>Create Ticket</Button></td> */}
            </tr>
          )
      })

    return (
      
        <Col xl={{size: 12}}>
        <Table hover>
        <thead>
          <tr>
            <th> Email </th>
            <th> Name </th>
            <th> Mobile </th> 
            <th> Email Verified </th>
            <th> Mobile verified </th>
            <th> City </th>
            
            <th> Country </th>
          </tr>
        </thead>
        <tbody>
          {tableData}
        </tbody>
      </Table>
      </Col>
    );

  }
}

export default TableView;