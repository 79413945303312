import React,{Component} from "react";
import { Container, Col, Row, Card, CardText, CardHeader, CardBody } from "reactstrap";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import { BarLoader } from "react-spinners";
import momenttz from 'moment-timezone'
import moment from 'moment';
import Switch from 'react-switch';
import Curr from 'currency.js';
class ServiceSearchDetails extends Component{
    state={
        displayServices:[],
        loading:false,
        ServiceBookedDetails:[],
        selectAll: false,
        refundChecked: [],
        merchantName: null,
        mPrid: null,
        subCats: null,
        cats: null,
        MLocation: [],
        pinValidations:null,
        nOfAccepts:null,
        nOfCancelations:null,
        subcatDetails:null,
        deatailOfCurrentCard:[],
        cancelDet:[],
        toggle:[]
    }
    componentDidMount(){
        console.log("props",this.props)
        this.setState({
            ...this.state,
            loading:true
        })
        let  service_id=this.props.location.state.id
        let body={
            sid:this.props.location.state.id
        }
        let mPPrid=null;
        let scPrid=null;
        let toggle=[];
        axios.post("/support/merchantservices/detailsdisplay",body)
        .then((res)=>{
            console.log("res",res.data)
            if(res.data.data){
                mPPrid = res.data.displayservices[0].QF_MERCHANT_PERSONNEL_PRID;
                scPrid = res.data.displayservices[0].QF_SERVICE_SUB_CATEGORY_PRID;
                res.data.displayservices.map((tag) => {
                    if(tag.SERVICE_ACTIVE_FLAG == 1)
                    {
                        return toggle['toggle_' + tag.QF_MERCHANT_SERVICE_PRID]=true;
                       
                    } 
                    else
                    {
                        return toggle['toggle_' + tag.QF_MERCHANT_SERVICE_PRID]=false;
                    } 
                    
                })
                if(res.data.displayservices.length!==0){
                    this.setState({
                        ...this.state,
                        displayServices:res.data.displayservices,
                        loading:false,
                        toggle
                    })
                }
                axios.post("/support/consumer/merchantname/merchantPersonnelToMerchantName", {mPPrid})
                .then((res101) => {
                    // console.log("Merchant Name", res101.data.mNameDetails);
                    if(res101.data.data){
                        this.setState({
                            ...this.state,
                            merchantName: res101.data.mNameDetails[0].MERCHANT_NAME,
                            mPrid: res101.data.mNameDetails[0].QF_MERCHANT_PRID,
                            MLocation:res101.data.mNameDetails,
                            loading: false
                        })
                    } else {
                        if(res101.data.dbError){
                            this.setState({
                                ...this.state,
                                // offerIdDetails: [],

                                loading: false               
                            });
                            toast.error("Internal Server Error", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                        } else {
                            this.setState({
                                ...this.state,
                                merchantName: null,
                                MLocation:res101.data.mNameDetails,
                                loading: false              
                            });
                            toast.info("No Data Found", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({
                        ...this.state,
                        loading: false
                    })
                    toast.error("Server Error2" , {
                    position: toast.POSITION.BOTTOM_CENTER
                    });
                });

                

                axios.post("/support/consumer/subcategory/pridToName", {scPrid})
                .then((res2) => {
                    // console.log("Subcategory", res2.data);
                    if(res2.data.data){
                        this.setState({
                            ...this.state,
                            loading: false,
                            cats: res2.data.subCats ? res2.data.subCats[0].category_name : null,
                            subCats: res2.data.subCats ? res2.data.subCats[0].service_sub_category_name : null,
                        });
                    } else {
                        if(res2.data.dbError){
                            this.setState({
                                ...this.state,
                                loading: false
                            })
                            
                            toast.error("Internal Server Error", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                        } else {
                            this.setState({
                                ...this.state,
                                cats: null,
                                subCats: null,
                                loading: false             
                            });
                        }
                    }                       
                })
                .catch((err) => {
                    this.setState({
                        ...this.state,
                        loading: false
                    })
                    console.log(err);
                    toast.error("Server Error3", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                })
                axios.post('/support/merchantservice/viewcount',{id:service_id})
                .then((resv) => {
                    // console.log("Merchant Name", res101.data.mNameDetails);
                    if(resv.data.data){
                        this.setState({
                            ...this.state,
                            // ignores:resv.data.stats.rejects[0].rejected_count,
                            pinValidations:resv.data.stats.pinvCount[0].pinvCount,
                            nOfAccepts:resv.data.stats.acceptedCount[0].accepted_count,
                            nOfCancelations:resv.data.stats.canCount,
                            cancelDet:resv.data.stats.canceldetails,
                            subcatDetails:resv.data.stats.subcatDetails[0].service_sub_category_name,
                            deatailOfCurrentCard:resv.data.stats.offerDetails,
                            loading: false
                        });               
                    } else {
                        if(resv.data.dbError){
                            this.setState({
                                ...this.state,
                                loading: false               
                            });
                            toast.error("Internal Server Error", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                        } else {
                            this.setState({
                                ...this.state,
                                loading: false              
                            });
                            toast.info("No Data Found", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({
                        ...this.state,
                        loading: false
                    })
                    toast.error("Server Error1" , {
                    position: toast.POSITION.BOTTOM_CENTER
                    });
                });
            }
            else {
                if(res.data.dbError){
                    toast.error("Internal Server Error!",{
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                } else { 
                    this.setState({
                    ...this.state,
                    displayServices: [],
                    loading: false
                    });
                }
            }
        })
        .catch((error)=>{
            toast.error("Server Error",{
                position: toast.POSITION.BOTTOM_CENTER
            });
                this.setState({
                    ...this.state,
                    loading: false
                });
        })
    }
    MerchantDetailsHandler=(id)=>{
        // console.log("Merchan personnel prid", id);
        let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;

        this.props.history.push({
            pathname:url+'/MerchantSearchDetails',
            state:{
                condata:this.props.location.state.condata,
                id: id,
                url,
                active: this.state.active,
                active0: this.state.active0,
                active0Link: this.state.active0Link,
                active1: this.state.active1,
                active1Link: this.state.active1Link,
                active2: this.state.active2 ,
                active2Link: this.state.active2Link,
                active3: this.state.active3,
                active3Link: this.state.active3Link,
                active4: this.state.active4,
                active4Link: this.state.active4Link,
                active5: this.state.active5,
                active5Link: this.state.active5Link,
                active6: this.state.active6,
                active6Link: this.state.active6Link,
                active7: this.state.active7,
                active7Link: this.state.active7Link ,
                active8: this.state.active8 ,
                active8Link: this.state.active8Link,
                active9: this.state.active9,
                active9Link: this.state.active9Link,
             
        }});
      
    }
    noofBookingDisplayHandler = (serviceId) => {
        // this.setState({
        //     ...this.state,
        //     bookingsView: true
        // })
        let body={
            id:serviceId
        }
        let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
        axios.post('/support/offer/allBookingDetails',body)
        .then((res) => {
            // console.log("bookings response",res.data );
            if(res.data.data){
                if(res.data.result.length !== 0){
                    this.setState({
                        ...this.state,
                        bookingDetails: res.data.result
                    })

                    this.props.history.push({
                        pathname: url+'/BookingsTabView',
                        state:{
                          accepted_data: this.state.bookingDetails,
                          url,
                          serviceId
                        }
                      })
                }
            } else {
                if(res.data.dbError){
                    this.setState({
                        ...this.state,
                        loading: false,
                        bookingDetails: [],              
                    });
                    toast.error("Internal Server Error", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                } else {
                    this.setState({
                        ...this.state,
                        // loading: false,
                        bookingDetails: [],                 
                    });
                }
            }
        })
        .catch((err) => {
            console.log("Error", err);
            toast.warn("Unable to Reach Server! Please Try Again!" , {
                position: toast.POSITION.BOTTOM_CENTER
              });
              this.setState({
                  ...this.state,
                  loading: false
              })
        })
    }
    cancelHandler = () => {
        let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
        this.props.history.push({
            pathname: url+'/offerCancellations',
            state:{
              accepted_data:this.state.cancelDet,
              url 
            }
          })
    }
    toggleSwitchHandler = (id) => {
        this.setState({
            ...this.state,
            loading: true
        });
        let toggle={};
         if(this.state.toggle['toggle_'+id]){
           toggle={
              ...this.state.toggle,
              ['toggle_'+id]:false
          }
           this.setState({
               ...this.state,
                toggle: toggle,
            });

            let body = {
                id: id,
                toggle: false
            }
    
             axios.post("/support/services/active/deactive", body)
                    .then((res) => {
                        console.log("C response", res.data);
                        if(res.data.data){
                            
                            if(res.data.active){
                                // console.log("Active");
                                toast.success("Service is activated", {
                                    position: toast.POSITION.BOTTOM_CENTER
                                });
                                
                                this.componentDidMount();
                            } else if(res.data.inactive){
                                toast.success("Service is deactivated", {
                                    position: toast.POSITION.BOTTOM_CENTER
                                });
                                this.componentDidMount();
                            } else {
                                // console.log("No data found!");
                                toast.error("Data Not Found", {
                                    position: toast.POSITION.BOTTOM_CENTER
                                });
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                            }
                        } else {
                            
                            if(res.data.dbError){
                                toast.error("Internal Server Error, Try again!", {
                                    position: toast.POSITION.BOTTOM_CENTER
                                });
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                            } else {
                                toast.error("Service is not updated, try again!", {
                                    position: toast.POSITION.BOTTOM_CENTER
                                });
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                            }
                            this.setState({
                                ...this.state,
                                 toggle: toggle,
                                 loading: false
                            });
                        }
                    })
                    .catch((err) => {
                        console.log("Server error",err);
                        toast.error("Server Error", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        this.setState({
                            ...this.state,
                            toggle: toggle,
                            loading: false
                        });
                    });
            
        } else {            
            toggle={
              ...this.state.toggle,
              ['toggle_'+id]:true
            }
            this.setState({
                ...this.state,
                toggle: toggle,
         });

         let body = {
            id: id,
            toggle: true
        }

         axios.post("/support/services/active/deactive", body)
                .then((res) => {
                    if(res.data.data){
                        
                        if(res.data.active){
                            // console.log("Active");
                            toast.success("Service is activated", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            // this.setState({
                            //     ...this.state,
                            //     loading: false
                            // });
                            this.componentDidMount();
                        } else if(res.data.inactive){
                            // console.log("Inactive");
                            toast.success("Service is deactivated", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            this.componentDidMount();

                        } else{
                            toast.warn("Data Not Found!", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                        }
                    } else {
                        
                        if(res.data.dbError){
                            toast.error("Internal Server Error, Try again!", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                        } else {
                            toast.error("Service is not updated, try again!", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                        }
                        this.setState({
                            ...this.state,
                             toggle: toggle,
                             loading: false
                        });

                    }
                })
                .catch((err) => {
                    console.log("Server error",err);
                    toast.error("Server Error", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.setState({
                        ...this.state,
                        toggle: toggle,
                        loading: false
                    });
                });
            }  
    }
    render(){
        console.log('state',this.state)
        const rowStyle={
            textAlign:"initial"
        }
        const fWeightSpan={
            fontWeight:"500",
            marginRight:5,
            fontFamily: 'Muli',
            color: '#868686'
        }
        const fontText = {
            fontWeight:'bold',
            fontFamily: 'Muli',
            color: '#563951'          
        }
        const fontText1 = {
            fontWeight:'bold',
            fontFamily: 'Muli',
            color: '#563951'            
        }
        const LinkedText = {
            textDecoration:'underline',
            cursor:'pointer', 
            color: '#302939', 
            fontWeight:'bold',
            textTransform: 'uppercase',
            fontFamily: 'Muli',
            color: '#563951'
        }
        const textDecor = {
            fontWeight:"bold",
            fontFamily: 'Muli',
            // marginLeft: 5,
            color: '#563951'
        }
        let serviceDetails=null;
        if(this.state.displayServices.length!==0){
            serviceDetails = <Card style={{border:"1px solid #a78bba"}}>
            <Row className="mb-4" style={{paddingLeft:"15px",paddingRight:"15px"}}>
                    <Col sm={{size:12}} style={{fontWeight:"",fontSize:"22px",color:"#fff",backgroundColor:"#2C2165", fontFamily:'Muli'}}>
                    <CardHeader style={{fontSize:"18px",color:"#fff",backgroundColor:"#2C2165",fontFamily:"Muli"}}>Service Details {this.state.displayServices[0].SERVICE_DELETED_FLAG === 0?<span style={{float: 'right'}}><Switch height={20} onChange={() => this.toggleSwitchHandler(this.state.displayServices[0].QF_MERCHANT_SERVICE_PRID)} checked={this.state.toggle["toggle_"+this.state.displayServices[0].QF_MERCHANT_SERVICE_PRID]} onColor='#3DC5B6' offColor='#F86C69'/></span>:null}</CardHeader>
                    </Col>
            </Row>
            {/* <CardHeader style={{fontWeight:"",fontSize:"18px",color:"#fff",backgroundColor:"#2C2165",fontFamily:"Muli"}}>Service Details</CardHeader> */}
            <CardText style={{padding:"25px"}}>
                <Row className="mb-4" style={rowStyle}>
                    <Col>
                    <text style={fontText1}><span style={fWeightSpan}>Service Id:</span>{this.state.displayServices[0].QF_MERCHANT_SERVICE_PRID ?  <text style={{fontWeight: 'bold'}}>{this.state.displayServices[0].QF_MERCHANT_SERVICE_PRID}</text> : <text style={fontText}>------</text> } </text>
                    </Col>
                    <Col className="col-4">
                        <text style={fontText1}><span style={fWeightSpan}>Professional Name: </span>{this.state.merchantName ? <text style={LinkedText} onClick={() => this.MerchantDetailsHandler(this.state.mPrid)}>{this.state.merchantName}</text> : <text style={fontText}>------</text>}  </text>
                    </Col>
                    <Col className="col-4">
                        <text style={fontText1}><span style={fWeightSpan}>Professional Location:</span>{this.state.MLocation.length !==0 ? this.state.MLocation[0].MERCHANT_ADDRESS_STATE ? <text>{this.state.MLocation[0].MERCHANT_ADDRESS_LINE2}{', '}{this.state.MLocation[0].MERCHANT_ADDRESS_LINE3 ? <text> {this.state.MLocation[0].MERCHANT_ADDRESS_LINE3}{', '} </text> : null}{this.state.MLocation[0].MERCHANT_ADDRESS_CITY}{', '}{this.state.MLocation[0].MERCHANT_ADDRESS_STATE}{', '}{this.state.MLocation[0].MERCHANT_ADDRESS_COUNTRY}{' - '}{this.state.MLocation[0].MERCHANT_ADDRESS_ZIPCODE}</text> : <text style={fontText}>---</text> :  <text style={fontText}>---</text>} </text>
                    </Col>
                </Row>
                <Row className="mb-4" style={rowStyle}>
                    <Col className="col-4">
                    <text style={fontText1}><span style={fWeightSpan}>Service Name: </span>{this.state.displayServices[0].SERVICE_NAME? this.state.displayServices[0].SERVICE_NAME : <text style={fontText}>------</text>}  </text>
                    </Col>
                    <Col className="col-4">
                    <text style={fontText1}><span style={fWeightSpan}> Description:</span>{this.state.displayServices[0].SERVICE_DETAIL_DESC  ? this.state.displayServices[0].SERVICE_DETAIL_DESC : <text style={fontText}>------</text> } </text>
                    </Col>
                    <Col className="col-4">
                        <text style={fontText1}><span style={fWeightSpan}> Category:</span>{ this.state.cats ? this.state.cats : <text style={fontText}>------</text> } </text>
                    </Col>
                </Row>
                {/* <Row className="mb-4" style={rowStyle}>
                    <Col>
                    <text style={fontText1}><span style={fWeightSpan}>Time Zone:</span>{this.state.displayServices[0].SERVICE_TIMEZONE ? this.state.displayServices[0].SERVICE_TIMEZONE : <text style={fontText}>------</text>}</text>
                    </Col>
                </Row> */}
                <Row className="mb-4" style={rowStyle}> 
                    <Col className="col-4">
                        <text style={fontText1}><span style={fWeightSpan}> Subcategory:</span>{this.state.subCats ? this.state.subCats : <text style={fontText}>------</text> } </text>
                    </Col> 
                    <Col>
                    <text style={fontText1}><span style={fWeightSpan}>Time Zone:</span>{this.state.displayServices[0].SERVICE_TIMEZONE ? this.state.displayServices[0].SERVICE_TIMEZONE : <text style={fontText}>------</text>}</text>
                    </Col>
                    <Col className="col-4">
                        <text style={fontText1}><span style={fWeightSpan}> Service Created Date:</span>{this.state.displayServices[0].SERVICE_CREATED_ON_DATE ? momenttz(this.state.displayServices[0].SERVICE_CREATED_ON_DATE+"Z").tz(this.state.displayServices[0].SERVICE_TIMEZONE ? this.state.displayServices[0].SERVICE_TIMEZONE : 'America/Los_Angeles').format('DD-MMM-YYYY hh:mm A z') : <text style={fontText}>------</text>}</text>
                    </Col>
                </Row>
                <Row className="mb-4" style={rowStyle}>
                    <Col className="col-4">
                        <text style={fontText1}><span style={fWeightSpan}> Service Price:</span>{this.state.displayServices[0].SERVICE_CURRENCY === "Rupees" ? <span> {'\u20B9'} </span> : <span>$</span>}{this.state.displayServices[0].SERVICE_OFFER_PRICE !== "" ? this.state.displayServices[0].SERVICE_OFFER_PRICE.toFixed(2) : <text style={fontText}> ------ </text>} </text>
                    </Col>
                    <Col className="col-4">
                        <text style={fontText1}><span style={fWeightSpan}> Service Duration:</span>
                        {moment.utc(moment.duration(this.state.displayServices[0].SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("H") == 0 ? null : moment.utc(moment.duration(this.state.displayServices[0].SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("H")+ "h"}{' '}{moment.utc(moment.duration(this.state.displayServices[0].SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("mm") == 0 ? null : moment.utc(moment.duration(this.state.displayServices[0].SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("mm") + "m"}
                        </text>
                    </Col>
                    <Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}>Service Status:</span>
                            <text >
                                {
                                    this.state.displayServices[0].SERVICE_DELETED_FLAG === 1 ?
                                                <span style={{backgroundColor:"#F86C69",padding:10,width:100,color:"#fff",borderRadius:"3px"}}>Deleted</span> 
                                    :
                                    this.state.displayServices[0].SERVICE_ACTIVE_FLAG === 1 ? 
                                    <span style={{backgroundColor:"#3DC5B6",padding:10,width:100,color:"#fff",borderRadius:"3px"}}>Active</span> 
                                    :
                                    <span style={{backgroundColor:"#F86C69",padding:10,width:100,color:"#fff",borderRadius:"3px"}}>Inactive</span>
                                }
                        </text></text>
                    </Col>
                </Row> 
                <hr/>
                <Row className="mb-4" style={rowStyle}>
                    <Col className="col-4">
                        <text style={fontText1}><span style={fWeightSpan}>No. of Bookings:</span>{this.state.nOfAccepts ? <text style={LinkedText} onClick={()=>this.noofBookingDisplayHandler(this.state.displayServices[0].QF_MERCHANT_SERVICE_PRID)}>{this.state.nOfAccepts}</text> : <text style={fontText}>0</text>}</text>
                    </Col>
                    <Col className="col-4">
                        <text style={fontText1}><span style={fWeightSpan}>No. of Views:</span>{this.state.displayServices[0].SERVICE_VIEW_COUNT ? this.state.displayServices[0].SERVICE_VIEW_COUNT: <text style={fontText}>0</text>}</text>
                    </Col>
                    <Col className="col-4">
                        <text style={fontText1}><span style={fWeightSpan}>No. of Pin Validated:</span>{this.state.pinValidations ? this.state.pinValidations : <text style={fontText}>0</text>}</text>
                    </Col>
                </Row> 
                <Row className="mb-4" style={rowStyle}>
                    <Col className="col-4">
                        <text style={fontText1}><span style={fWeightSpan}>No. of Cancellations:</span>
                        {this.state.nOfCancelations===null||0?0:this.state.nOfCancelations>0?<text style={LinkedText} onClick={()=>this.cancelHandler()}>{this.state.nOfCancelations}</text>:this.state.nOfCancelations}
                        </text>
                    </Col>
                </Row>
                <hr/>
                <Row className="mb-4" style={rowStyle}>
                    <Col className="col-4">
                        <text style={fontText1}><span style={fWeightSpan}>Cancellation Allowed:</span>{this.state.displayServices[0].SERVICE_CANCEL_ALLOWED ? <span style={{backgroundColor:"#3DC5B6",padding:"5px",color:"#fff",borderRadius:"3px"}}>Yes</span>:<span style={{backgroundColor:"#F86C69",padding:"5px",color:"#fff",borderRadius:"3px"}}>No</span>}</text>
                    </Col>
                    {
                        this.state.displayServices[0].SERVICE_CANCEL_ALLOWED ? 
                            <Col className="col-4">
                                <text style={fontText1}><span style={fWeightSpan}>Days Before Cancellation Allowed:</span>{this.state.displayServices[0].SERVICE_CANCEL_ALLOWED_DAYS ? this.state.displayServices[0].SERVICE_CANCEL_ALLOWED_DAYS : <text style={fontText}>0</text> }{this.state.displayServices[0].SERVICE_CANCEL_ALLOWED_DAYS === 1 ? <span> day</span> : this.state.displayServices[0].SERVICE_CANCEL_ALLOWED_DAYS === 0 ? <span> day</span> : <span> days</span>}</text>
                            </Col>
                        : null
                    }
                    { 
                            this.state.displayServices[0].SERVICE_CANCEL_FEE || this.state.displayServices[0].SERVICE_CANCEL_FEE===0 ?
                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Cancellation Fee:</span>{this.state.displayServices[0].SERVICE_CURRENCY === "Rupees" ? <span> {'\u20B9'} </span> : <span>$</span>}{this.state.displayServices[0].SERVICE_CANCEL_FEE ? this.state.displayServices[0].SERVICE_CANCEL_FEE.toFixed(2):this.state.displayServices[0].SERVICE_CANCEL_FEE === 0 ? Curr(this.state.displayServices[0].SERVICE_CANCEL_FEE).value.toFixed(2) : null} </text>
                                </Col>
                            : null
                    }
                    {/* { 
                        this.state.displayServices[0].SERVICE_CANCEL_FEE?
                            <Col className="col-4">
                                <text style={fontText1}><span style={fWeightSpan}>Cancellation Fee:</span>{this.state.displayServices[0].SERVICE_CURRENCY === "Rupees" ? <span> {'\u20B9'} </span> : <span>$</span>}{this.state.displayServices[0].SERVICE_CANCEL_FEE ? this.state.displayServices[0].SERVICE_CANCEL_FEE.toFixed(2) : null} </text>
                            </Col>
                        : null
                    } */}

                </Row>

            </CardText>
        </Card>
        }
        

        return(
            <div>
                <Container fluid style={{paddingLeft:"50px",paddingRight:"50px",backgroundColor:"rgb(239,239,239)"}}>
                <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/>
                {serviceDetails} 
                </Container>
                <ToastContainer autoClose={2000}/>
            </div>
        )
    }
}
export default ServiceSearchDetails;




















// import React,{Component} from "react";
// import { Container, Col, Row, Card, CardText, CardHeader } from "reactstrap";
// import axios from "axios";
// import { toast, ToastContainer } from 'react-toastify';
// import "../../../node_modules/react-toastify/dist/ReactToastify.css";
// import { BarLoader } from "react-spinners"
// import Breadcrumb from '../Navbar/Breadcrumb';
// import momenttz from 'moment-timezone';

// class ServiceSearchDetails extends Component {
//     state = {
        
//         loading: false,
//         active: this.props.location.state.active,
//         active0: this.props.location.state.active0 ? this.props.location.state.active0 : null,
//         active0Link: this.props.location.state.active0Link ? this.props.location.state.active0Link : null,
//         active1: this.props.location.state.active1 ? this.props.location.state.active1 : null,
//         active1Link: this.props.location.state.active1Link ? this.props.location.state.active1Link : null,
//         active2: this.props.location.state.active2 ? this.props.location.state.active2 : null,
//         active2Link: this.props.location.state.active2Link ? this.props.location.state.active2Link : null,
//         active3: this.props.location.state.active3 ? this.props.location.state.active3 : null,
//         active3Link: this.props.location.state.active3Link ? this.props.location.state.active3Link : null,
//         active4: this.props.location.state.active4 ? this.props.location.state.active4 : null,
//         active4Link: this.props.location.state.active4Link ? this.props.location.state.active4Link : null,
//         active5: this.props.location.state.active5 ? this.props.location.state.active5 : null,
//         active5Link: this.props.location.state.active5Link ? this.props.location.state.active5Link : null,
//         active6: this.props.location.state.active6 ? this.props.location.state.active6 : null,
//         active6Link: this.props.location.state.active6Link ? this.props.location.state.active6Link : null,
//         active7: this.props.location.state.active7 ? this.props.location.state.active7 : null,
//         active7Link: this.props.location.state.active7Link ? this.props.location.state.active7Link : null,
//         active8: this.props.location.state.active8 ? this.props.location.state.active8 : null,
//         active8Link: this.props.location.state.active8Link ? this.props.location.state.active8Link : null,
//         active9: this.props.location.state.active9 ? this.props.location.state.active9 : null,
//         active9Link: this.props.location.state.active9Link ? this.props.location.state.active9Link : null,
//         serviceDetails: [],
//         listPrice: null
//     }

//     componentDidMount(){
//         this.setState({
//             ...this.state,
//             loading: true
//         })
//         let id= this.props.location.state.id;
//         // console.log("Id", id);

//         axios.post("/support/consumer/merchantOffer/service", {id})
//         .then(res => {
//             // console.log("Response", res.data);
//             if(res.data.data)
//             {
//                 this.setState({
//                     ...this.state,
//                     listPrice: res.data.results[0].MERCHANT_SERVICE_LOCATION_LIST_PRICE,
//                     serviceDetails: res.data.results1,
//                     loading: false
//                 })
//             }
//             else
//             {
//                 if(res.data.dbError)
//                 {
//                     toast.error("Internal Server Error", {position : toast.POSITION.BOTTOM_CENTER});
//                     this.setState({
//                         ...this.state,
//                         listPrice: null,
//                         serviceDetails:[],
//                         loading: false
//                     })
//                 }
//                 else
//                 {
//                     this.setState({
//                         ...this.state,
//                         listPrice: null,
//                         serviceDetails:[],
//                         loading: false
//                     })
//                 }
//             }
//         })
//         .catch(err => {
//             console.log(err);
//             toast.warn("Unable to Reach Server! Please Try Again!" , {
//                 position: toast.POSITION.BOTTOM_CENTER
//               });
//             this.setState({
//                 ...this.state,
//                 listPrice: null,
//                 serviceDetails:[],
//                 loading: false
//             })

//         })
//     }

//     render() {
//         // console.log("State", this.state);
//         // console.log("props", this.props);
        
//         const rowStyle={
//             textAlign:"initial"
//         }

//         const fWeightSpan={
//             fontWeight:"500",
//             marginRight: 5
//         }
//         const fontText = {
//             // color:'red',
//             // fontWeight: 'bold'            
//         }
//         const fontText1 = {
//             // color:'black',
//             // fontWeight: 'bold'            
//         }
//         const LinkedText = {
//             textDecoration:'underline',
//             cursor:'pointer', 
//             color: '#302939', 
//             fontWeight:'bold',
//             textTransform: 'uppercase'
//         }

//         let detailView = null;
//         if(this.state.serviceDetails.length !== 0){
//             detailView = (
//                     <Card style={{border:"1px solid #00A896"}}>
//                         <CardHeader style={{fontWeight:"bolder",fontSize:"18px",color:"#fff",backgroundColor:"#00A896"}}>Service Details</CardHeader>
//                         <CardText style={{padding:"25px"}}>
//                             <Row className="mb-4" style={rowStyle}>

//                                 <Col className="col-4">
//                                     <text style={fontText1}><span style={fWeightSpan}>Service Name: </span>{this.state.serviceDetails.length != 0 ? this.state.serviceDetails[0].merchant_service_name ? this.state.serviceDetails[0].merchant_service_name : <text style={fontText}>---</text> : null} </text>
//                                 </Col>

//                                 <Col className="col-4">
//                                     <text style={fontText1}><span style={fWeightSpan}>Description: </span> {this.state.serviceDetails.length != 0 ? this.state.serviceDetails[0].qf_merchant_service_description ? this.state.serviceDetails[0].qf_merchant_service_description :   <text style={fontText}>---</text> : null }</text>
//                                 </Col>
                            
//                                 <Col className="col-4">
//                                     <text style={fontText1}><span style={fWeightSpan}>Regular Price: </span> {this.state.listPrice ? this.state.listPrice.toFixed(2) : <text style={fontText}>---</text> } </text>
//                                 </Col>                      

//                             </Row>
//                             <Row className="mb-4" style={rowStyle}>

//                                 <Col className="col-4">
//                                     <text style={fontText1}><span style={fWeightSpan}>Image: </span>  <text style={fontText}>---</text> </text>
//                                 </Col>            

//                             </Row>
//                     </CardText>
                    
//                     </Card>
//             )
//         }

//         return (
//             <div>
//                 <Breadcrumb active={this.state.active} 
//                             active0={this.state.active0}
//                             active0Link={this.state.active0Link}
//                             active1={this.state.active1}
//                             active1Link={this.state.active1Link}
//                             active2={this.state.active2} 
//                             active2Link={this.state.active2Link}
//                             active3={this.state.active3}
//                             active3Link={this.state.active3Link}
//                             consumerData={this.props.location.state.condata}/>

//                 <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#50E3C2"/>
            
//                 <Container fluid>
//                     {/* <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#50E3C2"/> */}
                    
//                     {detailView}

//                     <ToastContainer />
                                 
//                 </Container>
//             </div>            
//         )

//     }

// }

// export default ServiceSearchDetails;