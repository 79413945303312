import React, { Component } from 'react';
import './App.css';
import { BrowserRouter, Route } from 'react-router-dom';
import Login from "./Consumer/MainScreens/Login"
import Home from './Consumer/MainScreens/Home';
import axios from "axios"; 
import config from "./config";
import {connect} from "react-redux";
import ServerError from "./ErrorScreens/ServerError";
// import Admin from './AdminScreen/Main/admin';
import AnimateHome from './Payment_animation/routeHome';
import betaVersion from './versionBeta/betaVersion';
import  termsConditions from './versionBeta/termsConditions';
import ImageData from './Consumer/SubComponents/ImageData'
// import Website from './WebsiteDesign/NewWebsite'

// import TermsConditions from './AdminScreen/NavScreens/MainScreens/TermsConditions';

axios.defaults.baseURL=config.localhost_url;
//axios.defaults.headers.common['x-auth'] = this.props.token;
//
//abcd
class App extends Component {
  render() {
    return (
      <BrowserRouter>
      <div className="App">
      
          <Route exact path="/" component={Login} />
          <Route path="/home" component={Home} />

          <Route path="/BetaVersion/SignUp" component={betaVersion} />
          <Route path="/BetaVersion/termsConditions" component={termsConditions} />

          <Route path="/animate" component={AnimateHome} />
          <Route exact path="/get/images" component={ImageData} />
          <Route exact path="/error/unauth" component={ServerError} />
          
          

          {/* <Route path="/admin" component={Admin} /> */}
          {/* <Route path="/web" component={Website} /> */}

      </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps=state=>{
  return {
    token:state.token,
    country: state.country
  }
}  

export default connect(mapStateToProps)(App);
