import Switch from 'react-switch';

import React, { Component } from 'react';
import axios from 'axios'; 
import {ToastContainer, toast } from 'react-toastify';
import { ButtonGroup, Button, Label, Card, CardBody,CardTitle, CardText, Row, Col, Container, Modal,ModalBody,ModalHeader,ModalFooter} from 'reactstrap';
import { Form, FormGroup,FormFeedback, FormText, Input} from 'reactstrap';
import { Link } from 'react-router-dom';
import {BarLoader} from 'react-spinners';




class BetaSignUp extends Component {
   
    state={
        
        DevSelection:'IOS',
        DevSelection2:'IOS',        
        loading:false,
        pending: true,
        resolved:false,
        isChecked:false,
        isChecked2:false,
        phone:{
               value:''
        },
        phone1:{ 
               value:''
        },
        name:{
               value:''
        },
        name1:{
               value:''
        },
        email:{
               value:'',
               emailE:false

        },
        email1:{
               value:'',
               emailE1:false
        },
        storeName:{
              value:'' 
        },

        deviceImei:{
        value:'',
        deviceImeiError:false
        },
        deviceImei1:{
        value:'',
        deviceImeiError:false
        },
        deviceUdid:{
        value:'',
        deviceUdidError:false
        },
        deviceUdid1:{
        value:'',
        deviceUdidError1:false

        }
    }

    toggleChange = () => {
        this.setState({
          isChecked: !this.state.isChecked,
        });
      }
      toggleChange2 = () => {
        this.setState({
          isChecked2: !this.state.isChecked2,
        });
      }
    
    
    cHandler = () => {
        this.setState({

                    ...this.state,
                     DevSelection2:'IOS',        
                     isChecked2:false,
                     phone1:{ 
                            value:''
                     },
                     
                     name1:{
                            value:''
                     },
                    
                     email1:{
                            value:'',
                            emailE1:false
                     },
                     storeName:{
                           value:'' 
                     },
             
                     deviceImei1:{
                     value:'',
                     deviceImeiError:false
                     },
                     
                     deviceUdid1:{
                     value:'',
                     deviceUdidError1:false
                     },
         
                pending: true,
                resolved: false,
               
               
        });
       
        
    }
    mHandler = () => {
        this.setState({
                ...this.state,
                     DevSelection:'IOS',        
                     isChecked:false,
                     phone:{ 
                            value:''
                     },
                     
                     name:{
                            value:''
                     },
                    
                     email:{
                            value:'',
                            emailE:false
                     },
                     
             
                     deviceImei:{
                     value:'',
                     deviceImeiError:false
                     },
                     
                     deviceUdid:{
                     value:'',
                     deviceUdidError:false
                     },  
                     pending: false,
                     resolved: true,
              
        });
       
     }
      
     mobileNumHandler = (event) => {
        console.log(event)
        let k =/^[0-9\b]+$/;

        if(event.target.value==='' || k.test(event.target.value)){
            this.setState({
                phone : {
                    ...this.state.phone,
                    value : event.target.value,
                },
                submit: {
                    ...this.state.submit,
                    invalid: false
                }
                
            });
        }

     }

     mobileNumHandler1 = (event) => {
        console.log(event)
        let k =/^[0-9\b]+$/;

        if(event.target.value==='' || k.test(event.target.value)){
            this.setState({
                phone1 : {
                    ...this.state.phone1,
                    value : event.target.value,
                },
                submit: {
                    ...this.state.submit,
                    invalid: false
                }
                
            });
        }

     }


     emailTextHandler = (event) => {
        // let k=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
 
        if(event.target.value.length!==0 || event.target.value === ''){
            this.setState({
                email : {
                    ...this.state.email,
                    value : event.target.value,
                    emailE:false
                },
                submit: {
                    ...this.state.submit,
                    invalid: false
                }
            }); 
        }                       
                    
        }

        emailTextHandler1 = (event) => {
            // let k=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
     
            if(event.target.value.length!==0 || event.target.value === ''){
                this.setState({
                    email1 : {
                        ...this.state.email1,
                        value : event.target.value,
                        emailE1:false
                    },
                    submit: {
                        ...this.state.submit,
                        invalid: false
                    }
                }); 
                console.log(this.state)
            }                       
                        
            }


           storeNameHandler=(event)=>{

            if(event.target.value.length!==0 || event.target.value === ''){
                this.setState({
                    storeName : {
                        ...this.state.storeName,
                        value : event.target.value,
                    },
                    submit: {
                        ...this.state.submit,
                        invalid: false
                    }
                }); 
            }

            }



     nameHandler = (event) => {
        console.log(event)
         let k =/^[a-zA-Z]+(\s{0,1}[a-zA-Z ])*$/;
        // let k = /^[a-zA-Z]+ ?([a-zA-Z]+$){1}/;
        //  let k= /^[A-Za-z]+( [A-Za-z]+)/;

        if(event.target.value==='' || k.test(event.target.value)){
            this.setState({
                name : {
                    ...this.state.name,
                    value : event.target.value,
                },
                submit: {
                    ...this.state.submit,
                    invalid: false
                }
                
            });
        }

     }


     udidHandler=(event)=>{
        console.log('ufff',event.target.value);
        // let k =/^[a-zA-Z]+(\s{0,1}[a-zA-Z ])*$/;
       // let k = /^[a-zA-Z]+ ?([a-zA-Z]+$){1}/;
       //  let k= /^[A-Za-z]+( [A-Za-z]+)/;

    //    if(event.target.value==='' || k.test(event.target.value)){
           this.setState({
            deviceUdid : {
                   ...this.state.deviceUdid,
                     value : event.target.value,
                     deviceUdidError:false
               },
               submit: {
                   ...this.state.submit,
                   invalid: false
               }
               
           });
    //    }

     }


     udidHandler1=(event)=>{
        console.log('ufff',event.target.value);
        // let k =/^[a-zA-Z]+(\s{0,1}[a-zA-Z ])*$/;
       // let k = /^[a-zA-Z]+ ?([a-zA-Z]+$){1}/;
       //  let k= /^[A-Za-z]+( [A-Za-z]+)/;

    //    if(event.target.value==='' || k.test(event.target.value)){
           this.setState({
            deviceUdid1 : {
                   ...this.state.deviceUdid1,
                     value : event.target.value,
               },
               submit: {
                   ...this.state.submit,
                   invalid: false
               }
               
           });
    //    }
        }

     imeiHandler = (event) => {
     

        this.setState({
            deviceImei : {
                   ...this.state.deviceImei,
                     value : event.target.value,
                     deviceImeiError:false
               },
               submit: {
                   ...this.state.submit,
                   invalid: false
               }
               
           });

     }


     imeiHandler1 = (event) => {
     

        this.setState({
            deviceImei1 : {
                   ...this.state.deviceImei1,
                     value : event.target.value,
                     deviceImeiError1:false
               },
               deviceUdid1:{
                   ...this.state,
                   deviceUdidError1:false
               },
               submit: {
                   ...this.state.submit,
                   invalid: false
               }
               
           });

     }

     nameHandler1 = (event) => {
        console.log(event)
         let k =/^[a-zA-Z]+(\s{0,1}[a-zA-Z ])*$/;
        // let k = /^[a-zA-Z]+ ?([a-zA-Z]+$){1}/;
        //  let k= /^[A-Za-z]+( [A-Za-z]+)/;

        if(event.target.value==='' || k.test(event.target.value)){
            this.setState({
                name1 : {
                    ...this.state.name1,
                    value : event.target.value,
                },
                submit: {
                    ...this.state.submit,
                    invalid: false
                }
                
            });
        }

     }



     handleChange(event) {

        if(event.target.value==='IOS'){
            this.setState({
                DevSelection: event.target.value,
                deviceImei:{
                    ...this.state.deviceImei,
                    value:'',
                    deviceImeiError:false
                },
               
              });

          }
          else{
            this.setState({
                DevSelection: event.target.value,
                deviceUdid:{
                    ...this.state.deviceUdid,
                    value:'',
                    deviceUdidError:false
                },
                
              });

          }
      }
      handleChange2(event) {
          console.log('hjd',event.target.value)
          if(event.target.value==='IOS'){
            this.setState({
                DevSelection2: event.target.value,
                deviceImei1:{
                    ...this.state.deviceImei1,
                    value:'',
                    deviceImeiError1:false
                }
              });

          }
          else{
            this.setState({
                DevSelection2: event.target.value,
                deviceUdid1:{
                    ...this.state.deviceUdid1,
                    value:'',
                    deviceUdidError1:false
                }
              });

          }
       
      }
      
      termsConditionsHandler=(e)=>{

        this.setState({
                termsConditions: e.target.value
              });
              console.log(this.state)
            }
      
      
      
  submitButtonHandler = () =>{

console.log("submit")
      if(this.state.pending) {


        
    let k=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // let h =/^[a-zA-Z]+(\s{0,1}[a-zA-Z ])*$/;
    
      if(this.state.email.value === ''||!k.test(this.state.email.value)){


           this.setState({
                   email:{
                           ...this.state.email,
                             emailE:true
                   }
           })
           
       }
 


       else if(this.state.DevSelection==='IOS'){
        if(this.state.deviceUdid.value === ''||(this.state.deviceUdid.value.length===0)){

            console.log('hai') ;
            this.setState({
                deviceUdid:{
                            ...this.state.deviceUdid,
                              deviceUdidError:true
                    }
            })
            
        }  else {
            console.log("IOS condition");
           let body={
                USER_TYPE:'consumer',
                USER_NAME:this.state.name.value.length!==0?this.state.name.value:null,
                USER_EMAIL:this.state.email.value,
                MOBILE_NUMBER:this.state.phone.value.length!==0?this.state.phone.value:null,
                OS_TYPE:this.state.DevSelection,
                DEVICE_UDID:this.state.deviceUdid.value,
                termsConditions:this.state.isChecked?true:false,
                consumer:true,
                merchant:false
                }
                console.log('body',body);
                
                return axios.post("/admin/betaRegistration", body)
                .then((res) => { 
                if(res.data.data){
                    console.log('res',res)
                   
                
                                   this.setState({
                                   loading: false
                
                    })
                toast.success("Registered successfully",{position:toast.POSITION.BOTTOM_CENTER});
                
                } else {
                    if(res.data.dbError)
                        {
                            toast.error("Internal Server Error",{
                                position:toast.POSITION.BOTTOM_CENTER
                            })
                           
                        }
                        else if(res.data.mailExist){

                            console.log('email error');

                            toast.error("Email already exists",{
                                position:toast.POSITION.BOTTOM_CENTER
                            })
                            }
                        else {
                  toast.success("Not Registered",{position:toast.POSITION.BOTTOM_CENTER});
                        
                        }          
                }
                })
                .catch(err => {
                console.log(err);
                toast.warn("Unable to Reach Server! Please Try Again!" , {
                    position: toast.POSITION.BOTTOM_CENTER
                  });
                
                })
        }


    }
    else if(this.state.DevSelection==='Android'){
        if(this.state.deviceImei.value === ''||(this.state.deviceImei.value.length===0)){

            console.log('hai') ;
            this.setState({
                deviceImei:{
                            ...this.state.deviceImei,
                            deviceImeiError:true
                    }
            })
            
        }  else{
            console.log("Android condition");
            let body={
                USER_TYPE:'consumer',
                USER_NAME:this.state.name.value.length!==0?this.state.name.value:null,
                USER_EMAIL:this.state.email.value,
                MOBILE_NUMBER:this.state.phone.value.length!==0?this.state.phone.value:null,
                OS_TYPE:this.state.DevSelection,
                DEVICE_IMEI:this.state.deviceImei.value,
                termsConditions:this.state.isChecked?true:false,
                consumer:true,
                merchant:false
                }
                console.log('body',body);
                return axios.post("/admin/betaRegistration", body)
                .then((res) => { 
                if(res.data.data){
                    console.log('res',res)
                   
                
                                   this.setState({
                                   loading: false
                
                    })
                toast.success("Registered successfully",{position:toast.POSITION.BOTTOM_CENTER});
                
                } else {
                    if(res.data.dbError)
                        {
                            toast.error("Internal Server Error",{
                                position:toast.POSITION.BOTTOM_CENTER
                            })
                           
                        }

                        else if(res.data.mailExist){

                            console.log('email error');
                            toast.error("Email already exists",{
                                position:toast.POSITION.BOTTOM_CENTER
                            })
                            }
                        else {
                  toast.success("Not Registered",{position:toast.POSITION.BOTTOM_CENTER});
                        
                        }          
                }
                })
                .catch(err => {
                console.log(err);
                toast.warn("Unable to Reach Server! Please Try Again!" , {
                    position: toast.POSITION.BOTTOM_CENTER
                  });
                
                })
        }


    }



      
    else{
        console.log("In else");
        this.setState({
        email:{
                 ...this.state.email,
                  emailE:false
              },
        deviceUdid:{
                ...this.state.deviceUdid,
                   deviceUdidError:false
        }
       })

  


//   return console.log('yrw',body)





}


} else {
    console.log("Not pending")
    let k=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // let h =/^[a-zA-Z]+(\s{0,1}[a-zA-Z ])*$/;
    
      if(this.state.email1.value === ''||!k.test(this.state.email1.value)){


           this.setState({
                   email1:{
                           ...this.state.email1,
                             emailE1:true
                   }
           })
           
       }


       else if(this.state.DevSelection2==='IOS'){
        if(this.state.deviceUdid1.value === ''||(this.state.deviceUdid1.value.length===0)){

            this.setState({
                deviceUdid1:{
                            ...this.state.deviceUdid1,
                              deviceUdidError1:true
                    }
            })

            return false;
            
        }  else {
            console.log("IOS condition");
           let body={
                USER_TYPE:'merchant',
                USER_NAME:this.state.name1.value.length!==0?this.state.name1.value:null,
                USER_EMAIL:this.state.email1.value,
                MOBILE_NUMBER:this.state.phone1.value.length!==0?this.state.phone1.value:null,
                OS_TYPE:this.state.DevSelection2,
                DEVICE_UDID:this.state.deviceUdid1.value,
                STORE_NAME:this.state.storeName.value.length!==0?this.state.storeName.value:null,
                termsConditions:this.state.isChecked2?true:false,
                consumer:false,
                merchant:true
                }
                console.log('body',body);
                
                return axios.post("/admin/betaRegistration", body)
                .then((res) => { 
                if(res.data.data){
                    console.log('res',res)
                   
                
                                   this.setState({
                                   loading: false
                
                    })
                toast.success("Registered successfully",{position:toast.POSITION.BOTTOM_CENTER});
                
                } else {
                    if(res.data.dbError)
                        {
                            toast.error("Internal Server Error",{
                                position:toast.POSITION.BOTTOM_CENTER
                            })
                           
                        }

                    else if(res.data.mailExist){

                    console.log('email error');
                    toast.error("Email already exists",{
                        position:toast.POSITION.BOTTOM_CENTER
                    })
                    }
                        else {
                  toast.success("Not Registered",{position:toast.POSITION.BOTTOM_CENTER});
                        
                        }          
                }
                })
                .catch(err => {
                console.log(err);
                toast.warn("Unable to Reach Server! Please Try Again!" , {
                    position: toast.POSITION.BOTTOM_CENTER
                  });
                
                })
        }


    }


    else if(this.state.DevSelection2==='Android'){
        if(this.state.deviceImei1.value === ''||(this.state.deviceImei1.value.length===0)){

            console.log('hai') ;
            this.setState({
                deviceImei1:{
                            ...this.state.deviceImei1,
                               deviceImeiError1:true
                    }
            })
            
        }  else{
            console.log("Android condition");
            let body={
                USER_TYPE:'merchant',
                USER_NAME:this.state.name1.value.length!==0?this.state.name1.value:null,
                USER_EMAIL:this.state.email1.value,
                MOBILE_NUMBER:this.state.phone1.value.length!==0?this.state.phone1.value:null,
                OS_TYPE:this.state.DevSelection2,
                STORE_NAME:this.state.storeName.value.length!==0?this.state.storeName.value:null,
                DEVICE_IMEI:this.state.deviceImei1.value,
                termsConditions:this.state.isChecked2?true:false,
                consumer:false,
                merchant:true
                }
                console.log('body',body);
                return axios.post("/admin/betaRegistration", body)
                .then((res) => { 
                if(res.data.data){
                    console.log('res',res)
                   
                
                                   this.setState({
                                   loading: false
                
                    })
                toast.success("Registered successfully",{position:toast.POSITION.BOTTOM_CENTER});
                
                } else {
                    if(res.data.dbError)
                        {
                            toast.error("Internal Server Error",{
                                position:toast.POSITION.BOTTOM_CENTER
                            })
                           
                        }
                        else if(res.data.mailExist){

                            console.log('email error');
                            toast.error("Email already exists",{
                                position:toast.POSITION.BOTTOM_CENTER
                            })
                            }
                        else {
                  toast.success("Not Registered",{position:toast.POSITION.BOTTOM_CENTER});
                        
                        }          
                }
                })
                .catch(err => {
                console.log(err);
                toast.warn("Unable to Reach Server! Please Try Again!" , {
                    position: toast.POSITION.BOTTOM_CENTER
                  });
                
                })
        }


    }




    }
        

        
        }
        

    

        
    render() {
        //   console.log('uff',this.state);

        const rowStyle={
            textAlign:"initial",
        }
        const fWeightSpan={
            fontWeight:"500",
            padding: 5,
            cursor: 'pointer',
            fontFamily: 'Muli',
            color: '#868686'
            // border:'1px solid black'
        }
        
        const textDecor = {
            marginLeft: 5,
            fontWeight:"bold",
            fontFamily: 'Muli',
            // marginLeft: 5,
            color: '#563951'
        }

        const textDecor1 = {
            marginLeft: 5,
            fontFamily: 'Muli',
            fontWeight:"bold",
            // marginLeft: 5,
            color: '#563951'
           // color: 'green'
        }


        console.log('state',this.state)
       
        let createUser = (
            <div className="row justify-content-center" style={{marginTop: 20, marginBottom: 20, height: '100%' , backgroundColor: '#EFEFEF'}}>
            
            <div className="col-8"> 
            <Form>

           {this.state.email.emailE===true?
           <FormGroup row>
           <Label style={{backgroundColor:'#ffe4c5',color:'#f3972d',width:'400px',height:'35px'}}>Email is invalid!</Label>
          </FormGroup>:null}
           {this.state.deviceUdid.deviceUdidError===true&&this.state.DevSelection==="IOS"?
           <FormGroup row>
           <Label style={{backgroundColor:'#ffe4c5',color:'#f3972d',width:'400px',height:'35px'}}>UDID is invalid!</Label>
          </FormGroup>:null}

          {this.state.deviceImei.deviceImeiError===true&&this.state.DevSelection==="Android"?
           <FormGroup row>
           <Label style={{backgroundColor:'#ffe4c5',color:'#f3972d',width:'400px',height:'35px'}}>IMEI is invalid!</Label>
          </FormGroup>:null}
                    <FormGroup row>
                    <Label for="fname" >Name </Label>
                            <Input  onChange={(event)=>this.nameHandler(event)}
                                    // valid={this.state.fname.valid ? true : false}
                                    // invalid={this.state.fname.invalid ? true : false} 
                                    placeholder="Please enter name"
                                    maxLength={40}

                                    value={this.state.name.value}
                                    />
                            <FormFeedback>Name is invalid! </FormFeedback>
 
                    </FormGroup>  
                  
                    <FormGroup row>
                    <Label for="Email" >Email <span style={{color: 'red'}}>*</span></Label>
                            <Input  onChange={(event)=>this.emailTextHandler(event)}
                                    // valid={this.state.email.valid ? true : false}
                                    // invalid={this.state.email.invalid ? true : false} 
                                    placeholder="Please enter email"
                                    value={this.state.email.value}
                                    
                                    />
                            <FormFeedback> Email is invalid! </FormFeedback> 
                    </FormGroup>
                    <FormGroup >

<Row form>
            <Col sm={12}>
                <FormGroup row>
                    <Label for="Phone"><text style={{fontFamily:'Muli'}}>Mobile Number</text> </Label>
                </FormGroup>
            </Col>
            <Col>
                <FormGroup row>
                    <Input disabled style={{width: 60}}  value={ '+1'}/>
                </FormGroup>
            </Col>

            <Col sm={{size:10}}>
                <FormGroup row>                                
                    <Input  onChange={(event)=>this.mobileNumHandler(event)}
                             
                            placeholder="Please enter Mobile number"
                            value={this.state.phone.value}
                            maxLength={10}
                            style={{width:'100%'}}
                            onKeyDown={this.onKeyDown} />
                    <FormFeedback><text style={{fontFamily:'Muli'}}>Enter Valid Phone Number!</text></FormFeedback>                                    
                </FormGroup>
            </Col>
        </Row>  
    
        </FormGroup >

                    <FormGroup >
                    <Label><input
                     type="radio"
                     value="IOS"
                     checked={this.state.DevSelection==="IOS"}
                
                     onChange={(event)=>this.handleChange(event)}
                      />{' '}IOS</Label>
                    <Label> <input
                     type="radio"
                     value="Android"
                   checked={this.state.DevSelection==="Android"}
                style={{marginLeft:100,marginTop:25}}
                
                     onChange={(event)=>this.handleChange(event)}
                      />{' '} Android</Label>
                
                    </FormGroup>

                    {this.state.DevSelection==='IOS'?<FormGroup row>
                    <Label for="Device Id" >Device UDID <span style={{color: 'red'}}>*</span></Label>
                            <Input onChange={(event)=>this.udidHandler(event)}
                                    // valid={this.state.mobileNum.valid ? true : false}
                                    // invalid={this.state.mobileNum.invalid ? true : false} 
                                    placeholder="Please enter Device UDID"
                                    value={this.state.deviceUdid.value}
                                    maxLength={40}
                                    />
                            <FormFeedback> Device UDID is invalid! </FormFeedback>
 
                    </FormGroup>:<FormGroup row>
                    <Label for="Device Id" >Device IMEI <span style={{color: 'red'}}>*</span></Label>
                            <Input onChange={(event)=>this.imeiHandler(event)}
                                    // valid={this.state.mobileNum.valid ? true : false}
                                    // invalid={this.state.mobileNum.invalid ? true : false} 
                                    placeholder="Please enter Device IMEI"
                                    value={this.state.deviceImei.value}
                                    maxLength={15}
                                    />
                            <FormFeedback> Device IMEI is invalid! </FormFeedback>
 
                    </FormGroup>}

                    <FormGroup >
                    <Input  type='checkbox' checked={this.state.isChecked} onChange={this.toggleChange} />{' '} I have read and agree to the &nbsp;<Link  to='/BetaVersion/termsConditions' target="_blank">Terms and conditions</Link>
                
                
                   
               
               </FormGroup>
                       
                         
                    <FormGroup>
                <Button onClick={this.submitButtonHandler} style={{backgroundColor:"#2C2165", color:'white'}} disabled={this.state.isChecked===false?true:false}><text style={{fontFamily:'Muli'}}>Sign Up</text></Button>

                        {/* <Button  style={{marginTop:20,width:120,fontSize:20}} onClick={this.submitHandler}>Sign Up</Button> */}
                        {/* color={this.state.submit.invalid ? 'danger' : 'success'} */}
                    </FormGroup>

                </Form>
                </div>
                </div>);
       let createMerchant= (
        <div className="row justify-content-center" style={{marginTop: 20, marginBottom: 20, height: '100%' , backgroundColor: '#EFEFEF'}}>
        
        <div className="col-8"> 
            <Form>
            {this.state.resolved? 
          this.state.email1.emailE1===true?
           <FormGroup row>
           <Label style={{backgroundColor:'#ffe4c5',color:'#f3972d',width:'400px',height:'35px'}}>Email is Invalid!</Label>
          </FormGroup>:null:null}
          {this.state.resolved? 
          this.state.deviceUdid1.deviceUdidError1===true?
          <FormGroup row>
          <Label style={{backgroundColor:'#ffe4c5',color:'#f3972d',width:'400px',height:'35px'}}>UDID is Invalid!</Label>
          </FormGroup>:null:null}
          {this.state.resolved? 
           this.state.deviceImei1.deviceImeiError1===true?
           <FormGroup row>
           <Label style={{backgroundColor:'#ffe4c5',color:'#f3972d',width:'400px',height:'35px'}}>IMEI is Invalid!</Label>
           </FormGroup>:null:null}



                <FormGroup row>
                <Label for="fname" >Name </Label>
                        <Input  onChange={(event)=>this.nameHandler1(event)}
                                // valid={this.state.fname.valid ? true : false}
                                // invalid={this.state.fname.invalid ? true : false} 
                                placeholder="Please enter name"
                                maxLength={60}
                                 value={this.state.name1.value}
                                />
                        <FormFeedback> Name is invalid! </FormFeedback>

                </FormGroup>  
              
                <FormGroup row>
                <Label for="Email1" >Email <span style={{color: 'red'}}>*</span></Label>
                        <Input  onChange={(event)=>this.emailTextHandler1(event)}
                                // valid={this.state.email.valid ? true : false}
                                // invalid={this.state.email.invalid ? true : false} 
                                placeholder="Please enter email"
                                value={this.state.email1.value}
                                
                                />
                        <FormFeedback> Email is invalid! </FormFeedback> 
                </FormGroup>

                <FormGroup >

                <Row form>
                            <Col sm={12}>
                                <FormGroup row>
                                    <Label for="Phone1"><text style={{fontFamily:'Muli'}}>Mobile Number</text> </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup row>
                                    <Input style={{width: 60}} disabled value={ '+1'}/>
                                </FormGroup>
                            </Col>

                            <Col sm={{size:10}}>
                                <FormGroup row>                                
                                    <Input  onChange={(event)=>this.mobileNumHandler1(event)}
                                             
                                            placeholder="Please enter Mobile number"
                                            value={this.state.phone1.value}
                                            maxLength={10}
                                            style={{width:'100%'}}
                                            onKeyDown={this.onKeyDown} />
                                    <FormFeedback><text style={{fontFamily:'Muli'}}>Enter Valid Phone Number!</text></FormFeedback>                                    
                                </FormGroup>
                            </Col>
                        </Row>  
                    
                        </FormGroup >












                <FormGroup row>
                <Label for="Booking-Id" >Store Name </Label>
                        <Input 
                                // valid={this.state.mobileNum.valid ? true : false}
                                // invalid={this.state.mobileNum.invalid ? true : false} 
                                placeholder="Please enter Store Name"
                                value={this.state.storeName.value}
                                maxLength={60}
                                onChange={(event)=>this.storeNameHandler(event)}

                                />
                        <FormFeedback>Store Name is invalid! </FormFeedback>

                </FormGroup>
                


                <FormGroup >
                <FormGroup >
                    <Label><input
                     type="radio"
                     value="IOS"
                     checked={this.state.DevSelection2==="IOS"}
                
                     onChange={(event)=>this.handleChange2(event)}
                      />{' '}IOS</Label>
                    <Label> <input
                     type="radio"
                     value="Android"
                   checked={this.state.DevSelection2==="Android"}
                style={{marginLeft:100,marginTop:25}}
                
                     onChange={(event)=>this.handleChange2(event)}
                      />{' '} Android</Label>
                
                    </FormGroup>
               
                    </FormGroup>

                    {this.state.DevSelection2==='IOS'?<FormGroup row>
                    <Label for="Device Id" >Device UDID <span style={{color: 'red'}}>*</span></Label>
                            <Input onChange={(event)=>this.udidHandler1(event)}
                                    // valid={this.state.mobileNum.valid ? true : false}
                                    // invalid={this.state.mobileNum.invalid ? true : false} 
                                    placeholder="Please enter Device UDID"
                                    value={this.state.deviceUdid1.value}
                                    maxLength={40}
                                    />
                            <FormFeedback> Device UDID is invalid! </FormFeedback>
 
                    </FormGroup>:<FormGroup row>
                    <Label for="Device Id" >Device IMEI <span style={{color: 'red'}}>*</span></Label>
                            <Input onChange={(event)=>this.imeiHandler1(event)}
                                    // valid={this.state.mobileNum.valid ? true : false}
                                    // invalid={this.state.mobileNum.invalid ? true : false} 
                                    placeholder="Please enter Device IMEI"
                                     value={this.state.deviceImei1.value}
                                    maxLength={15}
                                    />
                            <FormFeedback> Device IMEI is invalid! </FormFeedback>
 
                    </FormGroup>}


               

                <FormGroup >                
                 <Label>
                       <Input  type='checkbox' checked={this.state.isChecked2} onChange={this.toggleChange2}  />{' '} I have read and agree to the &nbsp;<Link  to='/BetaVersion/termsConditions' target="_blank">Terms and conditions</Link>

                    </Label>
                 
                </FormGroup>
                   
                     
                <FormGroup>
                <Button onClick={this.submitButtonHandler} style={{backgroundColor:"#2C2165", color:'white'}} disabled={this.state.isChecked2===false?true:false}><text style={{fontFamily:'Muli'}}>Sign Up</text></Button>

                    {/* color={this.state.submit.invalid ? 'danger' : 'success'} */}
                </FormGroup>

            </Form>
            </div>
            </div>);
        return (


            
            <div>
            <Container fluid>
            <div className="row justify-content-center" style={{marginTop: 70, marginBottom: 20}}>
            <div className="col-3" >
            
               <ButtonGroup  >

                <Button onClick={this.cHandler} active={this.state.pending? true : false} style={{backgroundColor:'#2C2165', fontFamily:'Muli',width:150}}>Consumer</Button>&nbsp;
                <Button onClick={this.mHandler} active={this.state.resolved ? true : false} style={{backgroundColor:'#2C2165', fontFamily:'Muli',width:150}}>Merchant</Button> 
               </ButtonGroup>
                
            
            </div> 
            </div>
            <Container fluid style={{width: 600}}> 
            <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/>

                {this.state.pending ? createUser : null}  
                {this.state.resolved ? createMerchant : null} 
              
            </Container>                
                
            <ToastContainer autoClose={2500}/>   
            </Container>              
            </div>
                     

        )
    }
} 

export default BetaSignUp;

  