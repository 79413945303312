import Switch from 'react-switch';

import React, { Component } from 'react'; 
import {connect} from "react-redux";
import axios from 'axios';
import {ToastContainer, toast } from 'react-toastify';
import { Card, CardBody,CardTitle, CardText, Form,FormText, Row, Col} from 'reactstrap';
import {BarLoader} from 'react-spinners';
import momenttz from 'moment-timezone'
import curr from "currency.js";
// import Breadcrumb from '../Breadcrumb';




class MerchantPayments extends Component {
   
    state={
       loading: false,
       transactions:[],
    //    disputes:[],
       transaction: true,
       dispute: false,
       update:null,
       loc: null,
       paymentStatus:0,


    }

    componentDidMount() {
        this.setState({
            ...this.state, 
            loading: true
    });

        // console.log('see check',this.props.location.state.country);
        let mpid = this.props.location.state.id
        // console.log("M_Prid", mpid);
       

        axios.post("/support/merchantpayments/payments", {mpid})
        .then((res) => {
            // console.log("Coupon res", res.data);
            if(res.data.get){
               

                               this.setState({
                    ...this.state,
                    transactions: res.data.details,
                    // disputes: res.data.dispute,
                    // loc: res.data.resultQ[0].MERCHANT_ADDRESS_COUNTRY,
                    // activeToggle: toggle,
                    loading: false

                })
            } else {
                if(res.data.dbError)
                    {
                        toast.error("Internal Server Error",{
                            position:toast.POSITION.BOTTOM_CENTER
                        })
                        this.setState({
                            ...this.state,
                            transactions: [],
                            // disputes: [],
                            loading: false
                    });
                    }
                    else {
                        this.setState({
                            ...this.state,
                            transactions: [],
                            // disputes: [],
                            loading: false
        
                        })
                    }          
            }
        })
        .catch(err => {
            console.log(err);
            toast.warn("Unable to Reach Server! Please Try Again!" , {
                position: toast.POSITION.BOTTOM_CENTER
              });
            this.setState({
                ...this.state,
                transactions: [],
                disputes: [],
                loading: false
        });
        })
    }

      
        
    
transactionCouponHandler = () => {
        this.setState({
                ...this.state,
                transaction: true,
                dispute: false,
                //loading: true              
        });
    }

disputeCouponHandler = () => {
        this.setState({
            ...this.state,
            transaction: false,
            dispute: true,
            //loading: true          
        });

    } 
    payDrill=(id)=>{
        //  alert('hai',id)
        //  console.log('hai',id)
    let selectedTrans = this.state.transactions.filter(each=>{
    return  each.QF_MERCHANT_PAYMENT_PRID===id
    })
    //  console.log('hahah',selectedTrans)

        let url=this.props.location.state.url ? this.props.location.state.url : this.props.match.url;
        this.props.history.push({
            pathname: url+'/bookingView',
            state:{
                // id:this.state.merchantPersonnel[0].QF_MERCHANT_PERSONNEL_PRID,
                // name:this.state.merchantDetails.name,
                 tz : this.props.location.state.tz,
                 selectedTrans,
                 country:this.props.location.state.country,
            url
               } 
            }); 

    }
   
   
    
    render() {

        // console.log("State in Coupon", this.state)
        const rowStyle={
            textAlign:"initial",
        }
        const fWeightSpan={
            fontWeight:"400",
            fontFamily: 'Muli',
            color: '#868686',
            padding: 2,
            // cursor: 'pointer',
            // marginBottom:5,
            // border:'1px solid black'
        }

        const fWeightSpan1={
            fontWeight:'bold',
            padding: 2,
            fontFamily: 'Muli',
            color: '#563951',          
            // cursor: 'pointer',
            marginBottom:5,
            // border:'1px solid black'
        }
        
        const textDecor = {
            fontWeight:"400",
            // marginLeft: 5,
            fontFamily: 'Muli',
            color: '#563951',
        }

        const textDecor1 = {
            fontWeight:"bold",
            marginLeft: 5,
            fontFamily: 'Muli',
            color: '#563951',
        }

        let transaction = <text style={textDecor}>No Transactions</text>;
        let dispute = "No Disputes";

        if(this.state.transactions.length !== 0){

            transaction = this.state.transactions.map((coupon) => {
                return (
                <Card style={{marginTop: 15, border: '2px solid #a78bba',backgroundColor:'white',width:'100%',cursor:'pointer'}} onClick={()=>this.payDrill(coupon.QF_MERCHANT_PAYMENT_PRID)} >
                        <CardBody>   
                            <CardTitle style={{fontSize:18,marginTop:-15}}></CardTitle>                                                    
                            <CardText style={{marginLeft:20, marginRight: 20}}>
                                <Row style={rowStyle}>
                                    <Col sm={{size:12}} style={fWeightSpan1}><text>Payment Date :</text> <text style={{marginLeft:5}}>{momenttz(coupon.MERCHANT_PAYMENT_CREATED_ON_DATE+"Z").tz(this.props.location.state.tz).format("DD MMM YYYY")}</text></Col>
                                </Row>
                                
                               
                                <Row style={{marginBottom: 10, marginTop:10}}>
                                    <Col sm={{size:8}}>
                                        <Row style={rowStyle}>
                                            <Col sm={{size:12}} style={fWeightSpan}><text> Payment Id </text> </Col>
                                            <Col sm={{size:12}} style={fWeightSpan}><text style={textDecor}>{coupon.QF_MERCHANT_PAYMENT_PRID}</text></Col>
                                        </Row>
                                    </Col>

                                    <Col sm={{size:4}}>
                                        <Row style={rowStyle}>
                                            <Col sm={{size:12}} style={fWeightSpan}><text>Status</text> </Col>
                                            <Col sm={{size:12}} style={fWeightSpan}><text style={textDecor}>{coupon.MERCHANT_PAYMENT_STATUS_OUR===1? <text style={{color:"#f3972d"}}>Initiated</text> : coupon.MERCHANT_PAYMENT_STATUS_OUR===2 ? <text style={{color:"#f3972d"}}>Pending</text> : coupon.MERCHANT_PAYMENT_STATUS_OUR===3? <text style={{color:"#3dc5b6"}}>Processed</text> : coupon.MERCHANT_PAYMENT_STATUS_OUR===4 ? <text style={{color:"#ff5050"}}>Failed</text> : coupon.MERCHANT_PAYMENT_STATUS_OUR===5? <text style={{color:"#ff5050"}}>Cancelled</text> : <text style={{color:"#FFC459"}}>Unknown</text>}</text></Col>
                                        </Row>
                                    </Col>
                                </Row>
                                
                                <Row style={{backgroundColor: '#f1f1f1', padding: 10}}>
                                    <Col sm={{size:4}}>
                                        <Row>
                                            <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5,fontFamily: 'Muli',color: '#868686'}}><text>Net Price </text> </Col>
                                            <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5, marginBottom:10}}><text style={textDecor1}>{this.props.location.state.country==="India"?'\u20B9':"$"}{curr(coupon.PAYMENT_AMOUNT_TO_MERCHANT).add(coupon.PERCENT_SERVICE_FEE).value.toFixed(2)}</text></Col>
                                        </Row>
                                    </Col>
                                    <Col sm={{size:4}}>
                                        <Row>
                                            <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5,fontFamily: 'Muli',color: '#868686'}}><text>Platform Fee</text></Col>
                                            <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5, marginBottom:10}}> <text style={textDecor1}>{this.props.location.state.country==="India"?'\u20B9':"$"}{coupon.PERCENT_SERVICE_FEE.toFixed(2)}</text> </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={{size:4}}>
                                        <Row>
                                            <Col sm={{size:12}} style={{fontWeight:"400", padding: 5,fontFamily: 'Muli',color: '#868686'}}><text>Earnings</text></Col>
                                            <Col sm={{size:12}} style={{fontWeight:"400", padding: 5, marginBottom:10}}> <text style={textDecor1}>{this.props.location.state.country==="India"?'\u20B9':"$"}{coupon.PAYMENT_AMOUNT_TO_MERCHANT.toFixed(2)}</text> </Col>
                                        </Row>
                                    </Col>
                               
                                </Row>

                                 
                            </CardText>
                        </CardBody>
                    </Card>
                )
            })
            
        } else {
            if(this.state.loading===true){
                transaction= <text style={textDecor}>Loading...</text>;
            }else{
                if(this.state.transactions.length==0)
                transaction = <text style={textDecor}>No Transactions</text>;  
        }           
            
                     
        }

       

        //  if(this.state.disputes.length!==0){ 
        //     dispute = this.state.disputes.map((coupon) => {
        //         return (
        //             <Card style={{marginTop: 15, border: '2px solid #a78bba',backgroundColor:'white',width:'100%'}} >
        //             <CardBody>   
        //                 <CardTitle style={{fontSize:18,marginTop:-15}}></CardTitle>                                                    
        //                 <CardText style={{marginLeft:20, marginRight: 20}}>
        //                     <Row style={rowStyle}>
        //                         <Col sm={{size:2}} style={fWeightSpan1}><text>Payment Date:</text> </Col>
        //                         <Col sm={{size:9}} style={fWeightSpan1}><text style={textDecor}>{coupon.MERCHANT_COUPON_CODE}</text></Col>
        //                     </Row>
        //                     <Row style={rowStyle}>
        //                             <Col sm={{size:2}} style={fWeightSpan}><text> Payment Id:</text> </Col>
        //                             <Col sm={{size:10}} style={fWeightSpan}><text style={textDecor}>{coupon.MERCHANT_COUPON_DESC ? coupon.MERCHANT_COUPON_DESC : <text>--</text>}</text></Col>
        //                         </Row>
                              
                                
        //                         <Row style={rowStyle}>
                                
                               
        //                                 <Col sm={{size:4}} style={fWeightSpan}><text>Status:</text><text style={textDecor}></text> </Col>
                                
        //                     </Row>
                            
        //                     <Row style={rowStyle}>
        //                         <Col sm={{size:4}} style={fWeightSpan}><text>Net Price: </text> <text style={textDecor}>{ coupon.MERCHANT_COUPON_BOOKING_DATE_FROM ? moment(coupon.MERCHANT_COUPON_BOOKING_DATE_FROM).format('DD MMM YYYY') : <text>--</text>}</text></Col>
        //                         <Col sm={{size:4}} style={fWeightSpan}><text>Platform Fee:</text> <text style={textDecor}>{coupon.MERCHANT_COUPON_BOOKING_DATE_TO ? moment(coupon.MERCHANT_COUPON_BOOKING_DATE_TO).format('DD MMM YYYY') : <text>--</text>}</text> </Col>
        //                         <Col sm={{size:4}} style={fWeightSpan}><text>Earnings:</text> <text style={textDecor}>{coupon.MERCHANT_COUPON_BOOKING_DATE_TO ? moment(coupon.MERCHANT_COUPON_BOOKING_DATE_TO).format('DD MMM YYYY') : <text>--</text>}</text> </Col>
                          
        //                     </Row> 

                             
        //                 </CardText>
        //             </CardBody>
        //         </Card>
        //         )
        //     })
        //  } 
        // else {

        //   if(this.state.loading===true){
        //       dispute= "Loading...";
        //   }else{ 
        //         if(this.state.disputes.length===0)
        //         dispute= "No Disputes";  
        //  }  
        //  }

        return (
            
            <div className="row justify-content-center" style={{marginTop: 50}}>
            <div className="col-6" >
           
            <Form>
                <FormText style={{fontSize:24,marginBottom:20, }}><text style={{color:'#563951', fontFamily:'Muli'}}>Payments of <strong>{this.props.location.state.name}</strong></text> </FormText>
            </Form>

               
                {/* <h3 onClick={this.transactionCouponHandler} active={this.state.transaction? true : false}>Transactions</h3> */}
               
                
            
            
            <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/>

                {this.state.transaction ? transaction : null}  
              
            
                
            <ToastContainer autoClose={2500}/>     
            
            </div>
            </div>
                     

        )
    }
} 
const mapStateToProps=state=>{
    return {
      id:state.id 
  }
  }  


  export default connect(mapStateToProps)(MerchantPayments);

  