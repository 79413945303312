import React,{ Component } from "react";
import { ButtonGroup, Button, Card, CardBody,CardTitle, CardText, Form,FormText, Row, Col, Container, Modal,ModalBody,ModalHeader,ModalFooter, Tooltip} from 'reactstrap';
import curr from "currency.js";
import momenttz from 'moment-timezone';
import axios from 'axios';
import {ToastContainer, toast } from 'react-toastify';
import {BarLoader} from 'react-spinners';
import moment from "moment";
import numeral from "numeral"


 
class MerchantPaymentsBookingView extends Component {
    state={ 
       selectedOffer:this.props.location.state.selectedOffer,
       bookings:[],
       margin:null,
       transactions:this.props.location.state.selectedTrans,
    }
    componentDidMount(){
    let body={
        paymentId:this.state.transactions[0].QF_MERCHANT_PAYMENT_PRID,
        mpid:this.state.transactions[0].QF_MERCHANT_PERSONNEL_PRID
        // offer_id:this.state.selectedOffer[0].offer_id,
        // service_id:this.state.selectedOffer[0].service_id
        // offer:this.state.selectedOffer[0].list_price!='null'?false:true
    }
   
    axios.post("/support/merchantpayments/payments/bookingview",body)
        .then((res) => {
            if(res.data.get){

                console.log('bookings',res.data)
               
             this.setState({
                    ...this.state,
                    bookings: res.data.details,
                    margin:res.data.margin,
                    loading: false

                })

                console.log('bookings',this.state);
            } else {
                if(res.data.dbError)
                    {
                        toast.error("Internal Server Error",{
                            position:toast.POSITION.BOTTOM_CENTER
                        })
                        this.setState({
                            ...this.state,
                            bookings: [],
                            loading: false
                    });
                    }
                    else {
                        this.setState({
                            ...this.state,
                            bookings: [],                            
                            loading: false
        
                        })
                    }          
            }
        })
        .catch(err => {
            console.log(err);
            toast.warn("Unable to Reach Server! Please Try Again!" , {
                position: toast.POSITION.BOTTOM_CENTER
              });
            this.setState({
                ...this.state,
                bookings: [],
                loading: false
        });
        })
    }

render(){ 

    console.log('st',this.state);

    const rowStyle={
        textAlign:"initial",
    }
    const rowStyle2={
        textAlign:"initial",
        marginBottom:15,
        fontFamily:'Muli',
        fontWeight:'bold'
    }
    const fWeightSpan={
        fontWeight:"400",
        fontFamily: 'Muli',
        color: '#868686',
        // padding: 5,
        // cursor: 'pointer',
        // marginBottom:5,
        // border:'1px solid black'
    }

    const fWeightSpan1={
        fontWeight:"400",
        fontFamily: 'Muli',
        color: '#868686',
        // padding: 5,            
        // cursor: 'pointer',
        marginBottom:15,
        // border:'1px solid black'
    }
    
    const textDecor = {
        fontWeight:"bold",
        fontFamily: 'Muli',
        // marginLeft: 5,
        color: '#563951',
        // padding:5
    }
    let transaction = <text style={textDecor}>No Transactions</text>;
    if(this.state.transactions.length !== 0){
        transaction = this.state.transactions.map((coupon) => {
            // console.log("Time", momenttz(coupon.MERCHANT_PAYMENT_CREATED_ON_DATE+"Z").tz(this.props.location.state.tz).format("DD MMM YYYY hh:mm"))
            return (
                <Card>
                    <CardBody>
                        <CardText style={{marginLeft:30, marginRight: 30}}>
                            <Row style={rowStyle}>
                                <Col sm={{size:4}}>
                                    <Row>
                                        <Col sm={{size:12}} style={fWeightSpan}><text> Payment Id </text> </Col>
                                        <Col sm={{size:12}} style={fWeightSpan}><text style={textDecor}>{coupon.QF_MERCHANT_PAYMENT_PRID}</text></Col>
                                    </Row>
                                </Col>
                                <Col sm={{size:4}}>
                                    <Row>
                                        <Col sm={{size:12}} style={fWeightSpan}><text>Payment Date</text></Col>
                                        <Col sm={{size:12}} style={fWeightSpan}><text style={textDecor}>{momenttz(coupon.MERCHANT_PAYMENT_CREATED_ON_DATE+"Z").tz(this.props.location.state.tz).format("DD MMM YYYY")}</text></Col>
                                    </Row>
                                </Col>

                                <Col sm={{size:4}}>
                                    <Row>                         
                                        <Col sm={{size:12}} style={fWeightSpan}><text>Status</text></Col>
                                        <Col sm={{size:12}} style={fWeightSpan}><text style={textDecor}>{coupon.MERCHANT_PAYMENT_STATUS_OUR===1? <text style={{color:"#f3972d"}}>Initiated</text> :coupon.MERCHANT_PAYMENT_STATUS_OUR===2? <text style={{color:"#f3972d"}}>Pending</text> :coupon.MERCHANT_PAYMENT_STATUS_OUR===3? <text style={{color:"#3dc5b6"}}>Processed</text> :coupon.MERCHANT_PAYMENT_STATUS_OUR===4? <text style={{color:"#ff5050"}}>Failed</text> :coupon.MERCHANT_PAYMENT_STATUS_OUR===5? <text style={{color:"#ff5050"}}>Cancelled</text>: <text style={{color:"#FFC459"}}>Unknown</text>}</text> </Col>
                                    </Row>
                                </Col>
                            </Row> 
                        </CardText>
                    </CardBody>
                </Card>                       
            )
        })
}
    // let selectedOffer=<text style={textDecor}>No Offers</text>
    // if(this.state.selectedOffer.length !== 0){

    //     selectedOffer = this.state.selectedOffer.map((coupon) => {
    //         if(coupon.offer_id){
    //             return (
    //                 <Card>
    //                     <CardBody>
    //                         <CardText style={{marginLeft:30, marginRight: 30}}>
    //                             <Row style={rowStyle}>
    //                                 <Col sm={{size:3}}>
    //                                     <Row>
    //                                         <Col sm={{size:12}} style={fWeightSpan}><text>Offer Id</text></Col>
    //                                         <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.offer_id}</text></Col>
    //                                     </Row>
    //                                 </Col>
    //                                 <Col sm={{size:3}}>
    //                                     <Row>
    //                                         <Col sm={{size:12}} style={fWeightSpan}><text>Offer Date</text></Col>
    //                                         <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{momenttz(coupon.offer_date+"Z").tz(this.props.location.state.tz).format("DD MMM YYYY")}</text></Col>
    //                                     </Row>
    //                                 </Col>
    //                                 <Col sm={{size:3}}>
    //                                     <Row>
    //                                         <Col sm={{size:12}} style={fWeightSpan}><text> Offer Name </text> </Col>                
    //                                         <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.offer_name}</text></Col>
    //                                     </Row>
    //                                 </Col>
    //                                 <Col sm={{size:3}}>
    //                                     <Row>
    //                                         <Col sm={{size:12}} style={fWeightSpan}><text>Cancellation Fee</text>  </Col>                                        
    //                                         <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.cancellation_fee?(this.props.location.state.country==="India"?'\u20B9'+coupon.cancellation_fee.toFixed(2):"$"+coupon.cancellation_fee.toFixed(2)):(this.props.location.state.country==="India"?'\u20B9 0.00':"---")}</text></Col>
    //                                     </Row>
    //                                 </Col>
    //                             </Row>
    //                         </CardText>
    //                     </CardBody>
    //                 </Card>                                       
    //             )
    //         }
    //         if(coupon.service_id){
    //             return (
    //                 <Card>
    //                     <CardBody>
    //                         <CardText style={{marginLeft:30, marginRight: 30}}>
    //                             <Row style={rowStyle}>
    //                                 <Col sm={{size:3}}>
    //                                     <Row>
    //                                         <Col sm={{size:12}} style={fWeightSpan}><text>Service Id</text></Col>
    //                                         <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.service_id}</text></Col>
    //                                     </Row>
    //                                 </Col>
    //                                 {/* <Col sm={{size:3}}>
    //                                     <Row>
    //                                         <Col sm={{size:12}} style={fWeightSpan}><text>Offer Date</text></Col>
    //                                         <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{momenttz(coupon.offer_date+"Z").tz(this.props.location.state.tz).format("DD MMM YYYY")}</text></Col>
    //                                     </Row>
    //                                 </Col> */}
    //                                 <Col sm={{size:3}}>
    //                                     <Row>
    //                                         <Col sm={{size:12}} style={fWeightSpan}><text> Service Name </text> </Col>                
    //                                         <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.service_name}</text></Col>
    //                                     </Row>
    //                                 </Col>
    //                                 <Col sm={{size:3}}>
    //                                     <Row>
    //                                         <Col sm={{size:12}} style={fWeightSpan}><text>Cancellation Fee</text>  </Col>                                        
    //                                         <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.service_fee?(this.props.location.state.country==="India"?'\u20B9'+coupon.service_fee.toFixed(2):"$"+coupon.service_fee.toFixed(2)):(this.props.location.state.country==="India"?'\u20B9 0.00':"---")}</text></Col>
    //                                     </Row>
    //                                 </Col>
    //                             </Row>
    //                         </CardText>
    //                     </CardBody>
    //                 </Card>                             
                           
    //             )
    //         }
            // return (
            //     <Card>
            //         <CardBody>
            //             <CardText style={{marginLeft:30, marginRight: 30}}>
            //                 <Row style={rowStyle}>
            //                     <Col sm={{size:3}}>
            //                         <Row>
            //                             <Col sm={{size:12}} style={fWeightSpan}><text>{this.state.selectedOffer[0].list_price!='null'?'Service Id':'Offer Id'}</text></Col>
            //                             <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.offer_id}</text></Col>
            //                         </Row>
            //                     </Col>
            //                     <Col sm={{size:3}}>
            //                         <Row>
            //                             <Col sm={{size:12}} style={fWeightSpan}><text>{this.state.selectedOffer[0].list_price!='null'?'Service Date':'Offer Date'}</text></Col>
            //                             <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{momenttz(coupon.offer_date+"Z").tz(this.props.location.state.tz).format("DD MMM YYYY")}</text></Col>
            //                         </Row>
            //                     </Col>
            //                     <Col sm={{size:3}}>
            //                         <Row>
            //                             <Col sm={{size:12}} style={fWeightSpan}><text> Offer Name </text> </Col>                
            //                             <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.offer_name}</text></Col>
            //                         </Row>
            //                     </Col>
            //                     <Col sm={{size:3}}>
            //                         <Row>
            //                             <Col sm={{size:12}} style={fWeightSpan}><text>Cancellation Fee</text>  </Col>                                        
            //                             <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.cancellation_fee?(this.props.location.state.country==="India"?'\u20B9'+coupon.cancellation_fee.toFixed(2):"$"+coupon.cancellation_fee.toFixed(2)):(this.props.location.state.country==="India"?'\u20B9 0.00':"---")}</text></Col>
            //                         </Row>
            //                     </Col>
            //                 </Row>
            //             </CardText>
            //         </CardBody>
            //     </Card>         
            // )
//         })



// }
let bookings=null;
    if(this.state.bookings.length !== 0){

        bookings = this.state.bookings.map((coupon) => {
            if(coupon.QF_MERCHANT_OFFER_PRID){
                return (
                    <Card style={{marginTop: 15, borderBottom: '3px solid #a78bba',backgroundColor:'white',width:'100%'}}  >
                            <CardBody>   
                                <CardTitle style={{fontSize:18,marginTop:-15}}></CardTitle>                                                    
                                <CardText style={{marginLeft:30, marginRight: 30}}>
                                <Row style={rowStyle}>
                                    <Col sm={{size:4}}>
                                        <Row>
                                            <Col sm={{size:12}} style={fWeightSpan}><text>Offer Id </text></Col>
                                            <Col sm={{size:12}} style={fWeightSpan}><text style={textDecor}>{coupon.QF_MERCHANT_OFFER_PRID}</text></Col>
                                        </Row>
                                    </Col>
                                    <Col sm={{size:4}}>
                                        <Row>
                                            <Col sm={{size:12}} style={fWeightSpan}><text> Offer Name </text> </Col>                            
                                            <Col sm={{size:12}} style={fWeightSpan}><text style={textDecor}>{coupon.MERCHANT_OFFER_HEADER_NAME}</text></Col>
                                        </Row>
                                    </Col>  
                                    <Col sm={{size:4}}>
                                            <Row>
                                                <Col sm={{size:12}} style={fWeightSpan}><text>Cancel Fee</text></Col>
                                                <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.MERCHANT_OFFER_CANCEL_FEE?(this.props.location.state.country==="India"?'\u20B9'+coupon.MERCHANT_OFFER_CANCEL_FEE.toFixed(2):"$"+coupon.MERCHANT_OFFER_CANCEL_FEE.toFixed(2)):(this.props.location.state.country==="India"?'\u20B9 0.00':"---")}</text></Col>
                                            </Row>
                                    </Col>    
                                </Row>
                                    <Row style={rowStyle}>
                                        <Col sm={{size:4}}>
                                            <Row>
                                                <Col sm={{size:12}} style={fWeightSpan}><text>Booking Id</text></Col>
                                                <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.QF_CONSUMER_OFFER_ACCEPTED_PRID}</text></Col>
                                            </Row>
                                        </Col>
                                        <Col sm={{size:4}}>
                                            <Row>
                                                <Col sm={{size:12}} style={fWeightSpan}><text>Booking Date</text></Col>
                                                <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{momenttz(coupon.OFFER_ACCEPTED_CREATED_ON_DATE+"Z").tz(this.props.location.state.tz).format("DD MMM YYYY")}</text></Col>
                                            </Row>
                                        </Col>
                                        <Col sm={{size:4}}>
                                            <Row>
                                                <Col sm={{size:12}} style={fWeightSpan}><text>Service Date </text> </Col>
                                                <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.PIN_VALIDATED_TIME?momenttz(coupon.PIN_VALIDATED_TIME+"Z").tz(this.props.location.state.tz).format("DD MMM YYYY"):"---"}</text></Col>
                                            </Row>
                                        </Col>
                                    
                                   </Row>
                                    <Row style={rowStyle}>
                                        <Col sm={{size:4}}>
                                            <Row>
                                                <Col sm={{size:12}} style={fWeightSpan}><text>Regular Price</text></Col>
                                                <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{coupon.MERCHANT_OFFER_LIST_PRICE.toFixed(2)}</text></Col>
                                            </Row>
                                        </Col>
                                        <Col sm={{size:4}}>
                                            <Row>
                                                <Col sm={{size:12}} style={fWeightSpan}><text>Offer Discount</text></Col>
                                                <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{curr(coupon.MERCHANT_OFFER_LIST_PRICE).subtract(coupon.MERCHANT_OFFER_PRICE).value.toFixed(2)}</text></Col>
                                            </Row>
                                        </Col>
                                        <Col sm={{size:4}}>
                                            <Row>
                                                <Col sm={{size:12}} style={fWeightSpan}><text> Offer Price</text> </Col>
                                                <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{coupon.MERCHANT_OFFER_PRICE.toFixed(2)}</text> </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row style={rowStyle}>
                                        <Col sm={{size:4}}>
                                            <Row>
                                                <Col sm={{size:12}} style={fWeightSpan}><text>Coupon Code</text></Col>
                                                <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.MERCHANT_COUPON_CODE?coupon.MERCHANT_COUPON_CODE:coupon.SUPPORT_COUPON_CODE?coupon.SUPPORT_COUPON_CODE:coupon.OUR_COUPON_CODE?coupon.OUR_COUPON_CODE:"---"}</text></Col>
                                            </Row>
                                        </Col>
                                        <Col sm={{size:4}}>
                                            <Row>
                                                <Col sm={{size:12}} style={fWeightSpan}><text>Coupon Discount</text></Col>
                                              
                                                {/* <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.MERCHANT_COUPON_CODE?(this.props.location.state.country==="India"?'\u20B9'+(coupon.COUPON_DISCOUNT!==null?coupon.COUPON_DISCOUNT.toFixed(2):"0.00"):"$"+(coupon.COUPON_DISCOUNT!==null?coupon.COUPON_DISCOUNT.toFixed(2):"0.00")):"---"}</text></Col> */}
                                                <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.MERCHANT_COUPON_CODE?(this.props.location.state.country==="India"?'\u20B9'+(coupon.COUPON_DISCOUNT!==null?coupon.COUPON_DISCOUNT.toFixed(2):"0.00"):"$"+(coupon.COUPON_DISCOUNT!==null?coupon.COUPON_DISCOUNT.toFixed(2):"0.00")):coupon.OUR_COUPON_CODE?(this.props.location.state.country==="India"?'\u20B9'+(coupon.COUPON_DISCOUNT!==null?coupon.COUPON_DISCOUNT.toFixed(2):"0.00"):"$"+(coupon.COUPON_DISCOUNT!==null?coupon.COUPON_DISCOUNT.toFixed(2):"0.00")):coupon.SUPPORT_COUPON_CODE?(this.props.location.state.country==="India"?'\u20B9'+(coupon.COUPON_DISCOUNT!==null?coupon.COUPON_DISCOUNT.toFixed(2):"0.00"):"$"+(coupon.COUPON_DISCOUNT!==null?coupon.COUPON_DISCOUNT.toFixed(2):"0.00")):"---"}</text></Col>
        
                                                {/* <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.COUPON_DISCOUNT?(this.props.location.state.country==="India"?'\u20B9'+coupon.COUPON_DISCOUNT:"$"+coupon.COUPON_DISCOUNT):"---"}</text></Col> */}
                                            </Row>
                                        </Col>
                                        <Col sm={{size:4}}>
                                            <Row>
                                                <Col sm={{size:12}} style={fWeightSpan}><text> Cancel Date</text> </Col>                                        
                                                <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.REFUND_CREATED_AT!=null?<text>{momenttz(coupon.REFUND_CREATED_AT+"Z").tz(this.props.location.state.tz).format("DD MMM YYYY")}</text>:"---"}</text> </Col>                                        
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row style={{backgroundColor: '#f1f1f1', padding: 15,marginTop:10}}>
                                        <Col sm={{size:4}}>
                                            <Row >
                                                <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5, color:'#868686'}}><text>Net Price</text></Col>
                                                <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5, marginBottom:10,color:'#563951'}}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{numeral(coupon.MERCHANT_PAYMENT_NET_PRICE).format("0.00")}</text></Col>
                                            </Row>
                                        </Col>
                                        <Col sm={{size:4}}>
                                            <Row>
                                                <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5,color:'#868686'}}><text>Platform Fee</text></Col>                                        
                                                <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5, marginBottom:10,color:'#563951'}}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{
                                                    numeral(numeral(this.state.margin).divide(100).value()).multiply(coupon.MERCHANT_PAYMENT_NET_PRICE).format("0.00")}
                                                    </text></Col>
                                            </Row>
                                        </Col>
                                        <Col sm={{size:4}}>
                                            <Row>
                                                <Col sm={{size:12}} style={{fontWeight:"400", padding: 5,color:'#868686'}}><text> Earnings</text> </Col>
                                                <Col sm={{size:12}} style={{fontWeight:"400", padding: 5, marginBottom:10,color:'#563951'}}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}
                                                {
                                                    numeral(numeral(coupon.MERCHANT_PAYMENT_NET_PRICE).subtract(numeral(numeral(this.state.margin).divide(100).value()).multiply(coupon.MERCHANT_PAYMENT_NET_PRICE).value()).value()).format("0.00")
                                                    }</text> </Col>
                                                
                                            </Row>
                                        </Col>
                                    </Row>
                                   
                                </CardText>
                            </CardBody>
                        </Card>
                    )
        
            }
            if(coupon.QF_MERCHANT_SERVICE_PRID){
                let service_coup_discount=0,total_diff_mul_price=0,cp_diff_price=0,ts_diff_price=0;
                if(coupon.length!==0)
                {
                        // offDet=this.state.offerDetails[0];
                        service_coup_discount=coupon.COUPON_DISCOUNT?coupon.COUPON_DISCOUNT:0;
                        total_diff_mul_price=0;
                        cp_diff_price=0;
                        ts_diff_price=0;
                    if(coupon.CP_PRID)
                    {
                        coupon.CP_DIFF_PRICE===null ? cp_diff_price=0 : cp_diff_price=coupon.CP_DIFF_PRICE
                    }
                    if(coupon.TS_PRID)
                    {
                        coupon.TS_DIFF_PRICE===null ? ts_diff_price=0 : ts_diff_price=coupon.TS_DIFF_PRICE
                    }
                    total_diff_mul_price=numeral(cp_diff_price).add(ts_diff_price).value()
                    if(total_diff_mul_price>0)
                    {
                        //// total_diff_mul_price is positive so extra price was added while purchasing - subtracting this amount while paying to consumer as this extra amount should be with us
                        total_diff_mul_price= - (total_diff_mul_price)
                    }
                    else if(total_diff_mul_price<0)
                    {
                        //// total_diff_mul_price is negative -  so we need to add this extra amount we gave as discount
                        total_diff_mul_price=Math.abs(total_diff_mul_price)
                    }
                    if(coupon.QF_MERCHANT_COUPON_PRID && coupon.MERCHANT_COUPON_PERCENT_OFF)
                    {
                        if(coupon.MERCHANT_COUPON_MAXIMUM_AMOUNT)
                        {
                            if((coupon.MERCHANT_COUPON_MAXIMUM_AMOUNT !== coupon.COUPON_DISCOUNT))
                            {
                                service_coup_discount=numeral( numeral(coupon.MERCHANT_COUPON_PERCENT_OFF).divide(100).value() ).multiply(total_diff_mul_price).value()
                                service_coup_discount=numeral(coupon.COUPON_DISCOUNT?coupon.COUPON_DISCOUNT:0).add(service_coup_discount).value()
                                service_coup_discount=Number(numeral(service_coup_discount).format('0.00'))
                            }
                        }
                        else
                        {
                            service_coup_discount=numeral( numeral(coupon.MERCHANT_COUPON_PERCENT_OFF).divide(100).value() ).multiply(total_diff_mul_price).value()
                            service_coup_discount=numeral(coupon.COUPON_DISCOUNT?coupon.COUPON_DISCOUNT:0).add(service_coup_discount).value()
                            service_coup_discount=Number(numeral(service_coup_discount).format('0.00'))
                        }
                    }
                }
                return (
                    <Card style={{marginTop: 15, borderBottom: '3px solid #a78bba',backgroundColor:'white',width:'100%'}}  >
                            <CardBody>   
                                <CardTitle style={{fontSize:18,marginTop:-15}}></CardTitle>                                                    
                                <CardText style={{marginLeft:30, marginRight: 30}}>
                                    <Row style={rowStyle}>
                                        <Col sm={{size:4}}>
                                            <Row>
                                                <Col sm={{size:12}} style={fWeightSpan}><text>Service Id </text></Col>
                                                <Col sm={{size:12}} style={fWeightSpan}><text style={textDecor}>{coupon.QF_MERCHANT_SERVICE_PRID}</text></Col>
                                            </Row>
                                        </Col>
                                        <Col sm={{size:4}}>
                                            <Row>
                                                <Col sm={{size:12}} style={fWeightSpan}><text> Service Name </text> </Col>                            
                                                <Col sm={{size:12}} style={fWeightSpan}><text style={textDecor}>{coupon.REC_SERVICE_NAME}</text></Col>
                                            </Row>
                                        </Col>  
                                        <Col sm={{size:4}}>
                                                <Row>
                                                    <Col sm={{size:12}} style={fWeightSpan}><text>Cancel Fee</text></Col>
                                                    <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.REC_SERVICE_CANCEL_FEE?(coupon.REC_SERVICE_CURRENCY==="Rupees"?'\u20B9'+coupon.REC_SERVICE_CANCEL_FEE.toFixed(2):"$"+coupon.REC_SERVICE_CANCEL_FEE.toFixed(2)):(coupon.REC_SERVICE_CURRENCY==="Rupees"?'\u20B9 0.00':"---")}</text></Col>
                                                </Row>
                                        </Col>    
                                    </Row>
                                    <Row style={rowStyle}>
                                        <Col sm={{size:4}}>
                                            <Row>
                                                <Col sm={{size:12}} style={fWeightSpan}><text>Booking Id</text></Col>
                                                <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.QF_CONSUMER_OFFER_ACCEPTED_PRID}</text></Col>
                                            </Row>
                                        </Col>
                                        <Col sm={{size:4}}>
                                            <Row>
                                                <Col sm={{size:12}} style={fWeightSpan}><text>Booking Date</text></Col>
                                                <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{momenttz(coupon.OFFER_ACCEPTED_CREATED_ON_DATE+"Z").tz(this.props.location.state.tz).format("DD MMM YYYY")}</text></Col>
                                            </Row>
                                        </Col>
                                        <Col sm={{size:4}}>
                                            <Row>
                                                <Col sm={{size:12}} style={fWeightSpan}><text>Service Date </text> </Col>
                                                <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.PIN_VALIDATED_TIME?momenttz(coupon.PIN_VALIDATED_TIME+"Z").tz(this.props.location.state.tz).format("DD MMM YYYY"):"---"}</text></Col>
                                            </Row>
                                        </Col>
                                    
                                   </Row>
                                    <Row style={rowStyle}>
                                        {/* <Col sm={{size:4}}>
                                            <Row>
                                                <Col sm={{size:12}} style={fWeightSpan}><text>Regular Price</text></Col>
                                                <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{this.state.selectedOffer[0].service_listp.toFixed(2)}</text></Col>
                                            </Row>
                                        </Col>
                                        <Col sm={{size:4}}>
                                            <Row>
                                                <Col sm={{size:12}} style={fWeightSpan}><text>Service Discount</text></Col>
                                                <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{curr(this.state.selectedOffer[0].service_listp).subtract(this.state.selectedOffer[0].service_price).value.toFixed(2)}</text></Col>
                                            </Row>
                                        </Col> */}
                                        <Col sm={{size:4}}>
                                            <Row>
                                                <Col sm={{size:12}} style={fWeightSpan}><text>Coupon Code</text></Col>
                                                <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.MERCHANT_COUPON_CODE?coupon.MERCHANT_COUPON_CODE:coupon.SUPPORT_COUPON_CODE?coupon.SUPPORT_COUPON_CODE:coupon.OUR_COUPON_CODE?coupon.OUR_COUPON_CODE:"---"}</text></Col>
                                            </Row>
                                        </Col>
                                        <Col sm={{size:4}}>
                                            <Row>
                                                <Col sm={{size:12}} style={fWeightSpan}><text>Coupon Discount</text></Col>
                                              
                                                {/* <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.MERCHANT_COUPON_CODE?(this.props.location.state.country==="India"?'\u20B9'+(coupon.COUPON_DISCOUNT!==null?coupon.COUPON_DISCOUNT.toFixed(2):"0.00"):"$"+(coupon.COUPON_DISCOUNT!==null?coupon.COUPON_DISCOUNT.toFixed(2):"0.00")):"---"}</text></Col> */}
                                                <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.MERCHANT_COUPON_CODE?(coupon.REC_SERVICE_CURRENCY==="Rupees"?'\u20B9'+(service_coup_discount!==null?service_coup_discount.toFixed(2):"0.00"):"$"+(service_coup_discount!==null?service_coup_discount.toFixed(2):"0.00")):coupon.OUR_COUPON_CODE?(coupon.REC_SERVICE_CURRENCY==="Rupees"?'\u20B9'+(coupon.COUPON_DISCOUNT!==null?coupon.COUPON_DISCOUNT.toFixed(2):"0.00"):"$"+(coupon.COUPON_DISCOUNT!==null?coupon.COUPON_DISCOUNT.toFixed(2):"0.00")):coupon.SUPPORT_COUPON_CODE?(coupon.REC_SERVICE_CURRENCY==="Rupees"?'\u20B9'+(coupon.COUPON_DISCOUNT!==null?coupon.COUPON_DISCOUNT.toFixed(2):"0.00"):"$"+(coupon.COUPON_DISCOUNT!==null?coupon.COUPON_DISCOUNT.toFixed(2):"0.00")):"---"}</text></Col>
        
                                                {/* <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.COUPON_DISCOUNT?(this.props.location.state.country==="India"?'\u20B9'+coupon.COUPON_DISCOUNT:"$"+coupon.COUPON_DISCOUNT):"---"}</text></Col> */}
                                            </Row>
                                        </Col>
                                        <Col sm={{size:4}}>
                                            <Row>
                                                <Col sm={{size:12}} style={fWeightSpan}><text> Service Price</text> </Col>
                                                <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.REC_SERVICE_CURRENCY==="Rupees"?'\u20B9':"$"}{coupon.REC_SERVICE_OFFER_PRICE?coupon.REC_SERVICE_OFFER_PRICE.toFixed(2):'---'}</text> </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row style={rowStyle}>
                                        <Col sm={{size:4}}>
                                            <Row>
                                                <Col sm={{size:12}} style={fWeightSpan}><text> Cancel Date</text> </Col>                                        
                                                <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.REFUND_CREATED_AT!=null?<text>{momenttz(coupon.REFUND_CREATED_AT+"Z").tz(this.props.location.state.tz).format("DD MMM YYYY")}</text>:"---"}</text> </Col>                                        
                                            </Row>
                                        </Col>
                                    </Row>
        
                                    <Row style={{backgroundColor: '#f1f1f1', padding: 15,marginTop:10}}>
                                        <Col sm={{size:4}}>
                                            <Row >
                                                <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5, color:'#868686'}}><text>Net Price</text></Col>
                                                <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5, marginBottom:10,color:'#563951'}}><text style={textDecor}>{coupon.REC_SERVICE_CURRENCY==="Rupees"?'\u20B9':"$"}{numeral(coupon.MERCHANT_PAYMENT_NET_PRICE).format("0.00")}</text></Col>
                                            </Row>
                                        </Col>
                                        <Col sm={{size:4}}>
                                            <Row>
                                                <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5,color:'#868686'}}><text>Platform Fee</text></Col>                                        
                                                <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5, marginBottom:10,color:'#563951'}}><text style={textDecor}>{coupon.REC_SERVICE_CURRENCY==="Rupees"?'\u20B9':"$"}{
                                                    numeral(numeral(this.state.margin).divide(100).value()).multiply(coupon.MERCHANT_PAYMENT_NET_PRICE).format("0.00")}
                                                    </text></Col>
                                            </Row>
                                        </Col>
                                        <Col sm={{size:4}}>
                                            <Row>
                                                <Col sm={{size:12}} style={{fontWeight:"400", padding: 5,color:'#868686'}}><text> Earnings</text> </Col>
                                                <Col sm={{size:12}} style={{fontWeight:"400", padding: 5, marginBottom:10,color:'#563951'}}><text style={textDecor}>{coupon.REC_SERVICE_CURRENCY==="Rupees"?'\u20B9':"$"}
                                                {
                                                    numeral(numeral(coupon.MERCHANT_PAYMENT_NET_PRICE).subtract(numeral(numeral(this.state.margin).divide(100).value()).multiply(coupon.MERCHANT_PAYMENT_NET_PRICE).value()).value()).format("0.00")
                                                    }</text> </Col>
                                                
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardText>
                            </CardBody>
                        </Card>
                    )
            }

//--------previuos one
//             console.log(coupon.COUPON_DISCOUNT)
//             return (
//             <Card style={{marginTop: 15, borderBottom: '3px solid #a78bba',backgroundColor:'white',width:'100%'}}  >
//                     <CardBody>   
//                         <CardTitle style={{fontSize:18,marginTop:-15}}></CardTitle>                                                    
//                         <CardText style={{marginLeft:30, marginRight: 30}}>
//                             <Row style={rowStyle}>
//                                 <Col sm={{size:4}}>
//                                     <Row>
//                                         <Col sm={{size:12}} style={fWeightSpan}><text>Booking Id</text></Col>
//                                         <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.QF_CONSUMER_OFFER_ACCEPTED_PRID}</text></Col>
//                                     </Row>
//                                 </Col>
//                                 <Col sm={{size:4}}>
//                                     <Row>
//                                         <Col sm={{size:12}} style={fWeightSpan}><text>Booking Date</text></Col>
//                                         <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{momenttz(coupon.OFFER_ACCEPTED_CREATED_ON_DATE+"Z").tz(this.props.location.state.tz).format("DD MMM YYYY")}</text></Col>
//                                     </Row>
//                                 </Col>
//                                 <Col sm={{size:4}}>
//                                     <Row>
//                                         <Col sm={{size:12}} style={fWeightSpan}><text>Service Date </text> </Col>
//                                         <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.PIN_VALIDATED_TIME?momenttz(coupon.PIN_VALIDATED_TIME+"Z").tz(this.props.location.state.tz).format("DD MMM YYYY"):"---"}</text></Col>
//                                     </Row>
//                                 </Col>
                            
//                            </Row>
//                             {/* <Row style={rowStyle}>
//                                 <Col sm={{size:4}}>
//                                 {this.state.selectedOffer[0].list_price==='null'?null: <Row>
//                                         <Col sm={{size:12}} style={fWeightSpan}><text>Regular Price</text></Col>
//                                         <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{this.state.selectedOffer[0].list_price==='null'?'---':this.state.selectedOffer[0].list_price}</text></Col>
//                                     </Row>}
//                                 </Col>
//                                 <Col sm={{size:4}}>
//                                     <Row>
//                                         <Col sm={{size:12}} style={fWeightSpan}><text>Offer Discount</text></Col>
//                                         <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{curr(this.state.selectedOffer[0].list_price).subtract(this.state.selectedOffer[0].offer_price).value.toFixed(2)}</text></Col>
//                                     </Row>
//                                 </Col>
//                                 <Col sm={{size:4}}>
//                                     <Row>
//                                         <Col sm={{size:12}} style={fWeightSpan}><text> Offer Price</text> </Col>
//                                         <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{this.state.selectedOffer[0].offer_price.toFixed(2)}</text> </Col>
//                                     </Row>
//                                 </Col>
//                             </Row> */}
//                             <Row style={rowStyle}>
//                                         <Col sm={{size:4}}>
//                                             <Row>
//                                                 <Col sm={{size:12}} style={fWeightSpan}><text>Regular Price</text></Col>
//                                                 <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{this.state.selectedOffer[0].list_price!='null'?this.state.selectedOffer[0].list_price.toFixed(2):coupon.DISPLAY_LIST_PRICE.toFixed(2)}</text></Col>
//                                             </Row>
//                                         </Col>
//                                         <Col sm={{size:4}}>
//                                             <Row>
//                                                 <Col sm={{size:12}} style={fWeightSpan}><text>Offer Discount</text></Col>
//                                                 <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{curr(coupon.DISPLAY_LIST_PRICE).subtract(this.state.selectedOffer[0].offer_price).value.toFixed(2)}</text></Col>
//                                             </Row>
//                                         </Col>
//                                         <Col sm={{size:4}}>
//                                             <Row>
//                                                 <Col sm={{size:12}} style={fWeightSpan}><text> Offer Price</text> </Col>
//                                                 <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{this.state.selectedOffer[0].offer_price.toFixed(2)}</text> </Col>
//                                             </Row>
//                                         </Col>
//                                     </Row>
//                             <Row style={rowStyle}>
//                                 <Col sm={{size:4}}>
//                                     <Row>
//                                         <Col sm={{size:12}} style={fWeightSpan}><text>Coupon Code</text></Col>
//                                         {/* <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.MERCHANT_COUPON_CODE?coupon.MERCHANT_COUPON_CODE:"---"}</text></Col> */}
//                                         <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.MERCHANT_COUPON_CODE?coupon.MERCHANT_COUPON_CODE:coupon.SUPPORT_COUPON_CODE?coupon.SUPPORT_COUPON_CODE:coupon.OUR_COUPON_CODE?coupon.OUR_COUPON_CODE:"---"}</text></Col>

//                                     </Row>
//                                 </Col>
//                                 <Col sm={{size:4}}>
//                                     <Row>
//                                         <Col sm={{size:12}} style={fWeightSpan}><text>Coupon Discount</text></Col>
                                      
//                                         <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.MERCHANT_COUPON_CODE?(this.props.location.state.country==="India"?'\u20B9'+(coupon.COUPON_DISCOUNT!==null?coupon.COUPON_DISCOUNT.toFixed(2):"0.00"):"$"+(coupon.COUPON_DISCOUNT!==null?coupon.COUPON_DISCOUNT.toFixed(2):"0.00")):coupon.OUR_COUPON_CODE?(this.props.location.state.country==="India"?'\u20B9'+(coupon.COUPON_DISCOUNT!==null?coupon.COUPON_DISCOUNT.toFixed(2):"0.00"):"$"+(coupon.COUPON_DISCOUNT!==null?coupon.COUPON_DISCOUNT.toFixed(2):"0.00")):coupon.SUPPORT_COUPON_CODE?(this.props.location.state.country==="India"?'\u20B9'+(coupon.COUPON_DISCOUNT!==null?coupon.COUPON_DISCOUNT.toFixed(2):"0.00"):"$"+(coupon.COUPON_DISCOUNT!==null?coupon.COUPON_DISCOUNT.toFixed(2):"0.00")):"---"}</text></Col>

//                                         {/* <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.COUPON_DISCOUNT?(this.props.location.state.country==="India"?'\u20B9'+coupon.COUPON_DISCOUNT:"$"+coupon.COUPON_DISCOUNT):"---"}</text></Col> */}
//                                     </Row>
//                                 </Col>
//                                 <Col sm={{size:4}}>
//                                     <Row>
//                                         <Col sm={{size:12}} style={fWeightSpan}><text> Cancel Date</text> </Col>                                        
//                                         <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.REFUND_CREATED_AT!=null?<text>{momenttz(coupon.REFUND_CREATED_AT+"Z").tz(this.props.location.state.tz).format("DD MMM YYYY")}</text>:"---"}</text> </Col>                                        
//                                     </Row>
//                                 </Col>
//                             </Row>

//                             {/* { coupon.SUPPORT_COUPON_CODE&&!coupon.OFFER_CANCELLED ? <div>
//                             <Row style={rowStyle2}> 
//                             <Col sm={{size:4}}>
//                                 <text style={{color:'#3DC5B6'}}>Support Coupon Applied</text></Col>
//                             </Row>
//                             <Row style={rowStyle}>

//                             <Col sm={{size:4}}>
//                                     <Row>
//                                         <Col sm={{size:12}} style={fWeightSpan}><text>Coupon Code</text></Col>
//                                         <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.SUPPORT_COUPON_CODE?coupon.SUPPORT_COUPON_CODE:"---"}</text></Col>
//                                     </Row>
//                                 </Col>
//                                 <Col sm={{size:4}}>
//                                     <Row>
//                                         <Col sm={{size:12}} style={fWeightSpan}><text>Coupon Discount</text></Col>
                                      
//                                         <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9'+(coupon.COUPON_DISCOUNT!==null?coupon.COUPON_DISCOUNT.toFixed(2):"0.00"):"$"+(coupon.COUPON_DISCOUNT!==null?coupon.COUPON_DISCOUNT.toFixed(2):"0.00")}</text></Col>

//                                         {/* <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.COUPON_DISCOUNT?(this.props.location.state.country==="India"?'\u20B9'+coupon.COUPON_DISCOUNT:"$"+coupon.COUPON_DISCOUNT):"---"}</text></Col> */}
//                                     {/* </Row> */}
//                                 {/* </Col> */}
//                             {/* </Row> */} 
//                             {/* <Row style={{padding:5}} >
//                                 <Col sm={{size:3}}>
//                                     <Row >
//                                         <Col sm={{size:10}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5, color:'#868686'}}><text>Net Price</text></Col>
//                                         <Col sm={{size:10}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5, marginBottom:10,color:'#563951'}}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{curr(this.state.selectedOffer[0].offer_price).subtract(coupon.COUPON_DISCOUNT!==null?curr(coupon.COUPON_DISCOUNT):0).value.toFixed(2)}</text></Col>
//                                     </Row>
//                                 </Col>
//                                 <Col sm={{size:3}}>
//                                     <Row>
//                                         <Col sm={{size:10}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5,color:'#868686'}}><text>Platform Fee</text></Col>                                        
//                                         <Col sm={{size:10}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5, marginBottom:10,color:'#563951'}}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{curr(curr(this.props.location.state.margin).divide(100).value).multiply(curr(this.state.selectedOffer[0].offer_price).subtract(coupon.COUPON_DISCOUNT!==null?curr(coupon.COUPON_DISCOUNT):0)).value.toFixed(2)}</text></Col>
//                                     </Row>
//                                 </Col>
//                                 <Col sm={{size:3}}>
//                                     <Row>
//                                         <Col sm={{size:10}} style={{fontWeight:"400", padding: 5,color:'#868686'}}><text> Earnings</text> </Col>
//                                         <Col sm={{size:10}} style={{fontWeight:"400", padding: 5, marginBottom:10,color:'#563951'}}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{curr(curr(this.state.selectedOffer[0].offer_price).subtract(coupon.COUPON_DISCOUNT!==null?curr(coupon.COUPON_DISCOUNT):0)).subtract(curr(curr(this.props.location.state.margin).divide(100).value).multiply(curr(this.state.selectedOffer[0].offer_price).subtract(coupon.COUPON_DISCOUNT!==null?curr(coupon.COUPON_DISCOUNT):0)).value).value.toFixed(2)}</text> </Col>
                                        
//                                     </Row>
//                                 </Col>
//                             </Row> */}
//                             {/* </div>:null} */}

//   {/* { coupon.OUR_COUPON_CODE&&!coupon.OFFER_CANCELLED ? <div>
//     <Row style={rowStyle2}> 
//     <Col sm={{size:4}}>
//         <text style={{color:'#3DC5B6'}}>Admin Coupon Applied</text></Col>
//     </Row>
//     <Row style={rowStyle}>

//     <Col sm={{size:4}}>
//             <Row>
//                 <Col sm={{size:12}} style={fWeightSpan}><text>Coupon Code</text></Col>
//                 <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.OUR_COUPON_CODE?coupon.OUR_COUPON_CODE:"---"}</text></Col>
//             </Row>
//         </Col>
//         <Col sm={{size:4}}>
//             <Row>
//                 <Col sm={{size:12}} style={fWeightSpan}><text>Coupon Discount</text></Col>
              
//                 <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9'+(coupon.COUPON_DISCOUNT!==null?coupon.COUPON_DISCOUNT.toFixed(2):"0.00"):"$"+(coupon.COUPON_DISCOUNT!==null?coupon.COUPON_DISCOUNT.toFixed(2):"0.00")}</text></Col> */}

//                 {/* <Col sm={{size:12}} style={fWeightSpan1}><text style={textDecor}>{coupon.COUPON_DISCOUNT?(this.props.location.state.country==="India"?'\u20B9'+coupon.COUPON_DISCOUNT:"$"+coupon.COUPON_DISCOUNT):"---"}</text></Col> */}
//             {/* </Row> */}
//         {/* </Col> */}
//     {/* </Row> */}
//     {/* <Row style={{padding:5}} >
//         <Col sm={{size:3}}>
//             <Row >
//                 <Col sm={{size:10}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5, color:'#868686'}}><text>Net Price</text></Col>
//                 <Col sm={{size:10}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5, marginBottom:10,color:'#563951'}}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{curr(this.state.selectedOffer[0].offer_price).subtract(coupon.COUPON_DISCOUNT!==null?curr(coupon.COUPON_DISCOUNT):0).value.toFixed(2)}</text></Col>
//             </Row>
//         </Col>
//         <Col sm={{size:3}}>
//             <Row>
//                 <Col sm={{size:10}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5,color:'#868686'}}><text>Platform Fee</text></Col>                                        
//                 <Col sm={{size:10}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5, marginBottom:10,color:'#563951'}}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{curr(curr(this.props.location.state.margin).divide(100).value).multiply(curr(this.state.selectedOffer[0].offer_price).subtract(coupon.COUPON_DISCOUNT!==null?curr(coupon.COUPON_DISCOUNT):0)).value.toFixed(2)}</text></Col>
//             </Row>
//         </Col>
//         <Col sm={{size:3}}>
//             <Row>
//                 <Col sm={{size:10}} style={{fontWeight:"400", padding: 5,color:'#868686'}}><text> Earnings</text> </Col>
//                 <Col sm={{size:10}} style={{fontWeight:"400", padding: 5, marginBottom:10,color:'#563951'}}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{curr(curr(this.state.selectedOffer[0].offer_price).subtract(coupon.COUPON_DISCOUNT!==null?curr(coupon.COUPON_DISCOUNT):0)).subtract(curr(curr(this.props.location.state.margin).divide(100).value).multiply(curr(this.state.selectedOffer[0].offer_price).subtract(coupon.COUPON_DISCOUNT!==null?curr(coupon.COUPON_DISCOUNT):0)).value).value.toFixed(2)}</text> </Col>
                
//             </Row>
//         </Col>
//     </Row> */}
//     {/* </div>:null} */}

//      {/* <Row style={{backgroundColor: '#f1f1f1', padding: 15,marginTop:10}}>
//                                 <Col sm={{size:4}}>
//                                     <Row >
//                                         <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5, color:'#868686'}}><text>Net Price</text></Col>
//                                         <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5, marginBottom:10,color:'#563951'}}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{coupon.MERCHANT_PAYMENT_NET_PRICE.toFixed(2)}</text></Col>
//                                     </Row>
//                                 </Col>
//                                 <Col sm={{size:4}}>
//                                     <Row>
//                                         <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5,color:'#868686'}}><text>Platform Fee</text></Col>                                        
//                                         <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5, marginBottom:10,color:'#563951'}}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{curr(curr(this.props.location.state.margin).divide(100).value).multiply(coupon.MERCHANT_PAYMENT_NET_PRICE).value.toFixed(2)}</text></Col>
//                                     </Row>
//                                 </Col>
//                                 <Col sm={{size:4}}>
//                                     <Row>
//                                         <Col sm={{size:12}} style={{fontWeight:"400", padding: 5,color:'#868686'}}><text> Earnings</text> </Col>
//                                         <Col sm={{size:12}} style={{fontWeight:"400", padding: 5, marginBottom:10,color:'#563951'}}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{curr(coupon.MERCHANT_PAYMENT_NET_PRICE).subtract(curr(curr(this.props.location.state.margin).divide(100).value).multiply(coupon.MERCHANT_PAYMENT_NET_PRICE).value).value.toFixed(2)}</text> </Col>
                                        
//                                     </Row>
//                                 </Col>
//                             </Row>
//                             */}
//                             <Row style={{backgroundColor: '#f1f1f1', padding: 15,marginTop:10}}>
//                                 <Col sm={{size:4}}>
//                                     <Row >
//                                         <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5, color:'#868686'}}><text>Net Price</text></Col>
//                                         <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5, marginBottom:10,color:'#563951'}}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{numeral(coupon.MERCHANT_PAYMENT_NET_PRICE).format("0.00")}</text></Col>
//                                     </Row>
//                                 </Col>
//                                 <Col sm={{size:4}}>
//                                     <Row>
//                                         <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5,color:'#868686'}}><text>Platform Fee</text></Col>                                        
//                                         <Col sm={{size:12}} style={{borderRight:'1px solid #C3BFC0',fontWeight:"400", padding: 5, marginBottom:10,color:'#563951'}}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}{
//                                             numeral(numeral(this.props.location.state.margin).divide(100).value()).multiply(coupon.MERCHANT_PAYMENT_NET_PRICE).format("0.00")}
//                                             </text></Col>
//                                     </Row>
//                                 </Col>
//                                 <Col sm={{size:4}}>
//                                     <Row>
//                                         <Col sm={{size:12}} style={{fontWeight:"400", padding: 5,color:'#868686'}}><text> Earnings</text> </Col>
//                                         <Col sm={{size:12}} style={{fontWeight:"400", padding: 5, marginBottom:10,color:'#563951'}}><text style={textDecor}>{this.props.location.state.country==="India"?'\u20B9':"$"}
//                                         {
//                                             numeral(numeral(coupon.MERCHANT_PAYMENT_NET_PRICE).subtract(numeral(numeral(this.props.location.state.margin).divide(100).value()).multiply(coupon.MERCHANT_PAYMENT_NET_PRICE).value()).value()).format("0.00")
//                                             }</text> </Col>
                                        
//                                     </Row>
//                                 </Col>
//                             </Row>
                           

                             
//                         </CardText>
//                     </CardBody>
//                 </Card>
//             )
        })



}
else {
    if(this.state.loading===true){
        bookings= <text style={textDecor}>Loading...</text>;
    }       
    
             
}

    

    return (
        <div className="row justify-content-center" style={{marginTop:50, height: '100%', backgroundColor: '#EFEFEF'}}>
            <div className="col-7" > 
            <Form>
                <FormText style={{fontSize:24,marginBottom:20}}><text style={{color:'#563951', fontFamily:'Muli'}}>Transactions</text></FormText>
            </Form>
                {/* {selectedOffer}
                {bookings} */}
           {transaction}
           {bookings}
        
        
        <BarLoader style={{marginTop:500}} widthUnit ={"%"} width={100} loading={this.state.loading} color="#2C2165"/>

        </div>
    </div>
    )

}

}
    export default MerchantPaymentsBookingView;
