import React, {Component} from 'react'; 
import { Table,Row, Col, Card,CardText,CardHeader, Label } from 'reactstrap';  
import { withRouter } from 'react-router';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import momenttz  from 'moment-timezone';
  
class SupportIdView extends Component {
    state={
           f_name:null,
           l_name:null
    }

supportDetailsHandler = (Tid, bid,cat,desc,status,resol,cDate,rDate,fname,lname,rfname,rlname) => { 
    let url = this.props.location.state.url?this.props.location.state.url:this.props.match.url;
// console.log('Tid',Tid) 
let TicketId={
    ticketId :Tid
 }


 axios.post("/support/consumer/SearchByTicketId",TicketId)


 .then(res=>{

     if(res.data.data){
         if(res.data.details.length!==0) 
           {
             
             this.setState(({ 
                            ...this.state,
                            // f_name:res.data.details1.length!==0?res.data.details1[0].SUPPORT_USER_FNAME:null,
                            // l_name:res.data.details1.length!==0?res.data.details1[0].SUPPORT_USER_LNAME:null
                           

             }))
             
            //  console.log('Props render()',this.state.consumerDet)
             this.props.history.push({
                pathname: url+'/ConsumerSearchTicketId',
                state:{
                    acceptance_prid:bid,
                    TicketId: this.props.Sdetails[0].QF_CONSUMER_PRID,
                    consumerData: {
                        QF_CONSUMER_OFFER_ACCEPTED_PRID: bid,
                        CONSUMER_SUPPORT_CATEGORY: cat,
                        CONSUMER_SUPPORT_DESC: desc,
                        CONSUMER_SUPPORT_STATUS: status,
                        QF_CONSUMER_SUPPORT_PRID: Tid,
                        CONSUMER_SUPPORT_RESOLUTION:resol,
                        CONSUMER_SUPPORT_CREATED_ON_DATE:cDate,
                        CONSUMER_SUPPORT_RESOLVED_ON_DATE: rDate,
                        // SUPPORT_USER_FNAME:this.state.f_name,
                        // SUPPORT_USER_LNAME: this.state.l_name,
                        rf_name:rfname?rfname:'Not Yet Resolved',
                        rl_name:rlname?rlname:null,
                        f_name:fname,
                        l_name:lname,
                    },
                    condata: [{
                        QF_MERCHANT_OFFER_PRID : this.props.offerPrid,
                        QF_CONSUMER_PRID : this.props.Sdetails[0].QF_CONSUMER_PRID
                    }      ]  ,
                    // f_name:this.state.f_name,
                    // l_name:this.state.l_name,
                    url
                }
            }); 

            
           }
                      
        }

       else{
               if(res.data.dbError===true)
               {
                   
                   toast.error("Internal Server Error.",{position:toast.POSITION.BOTTOM_CENTER});
                   this.setState({loading:false,dbError:true})
               }
                                     
               
       }

   }).catch(err=>{
     console.log(err);
     toast.warn("Unable to Reach Server! Please Try Again!" , {
        position: toast.POSITION.BOTTOM_CENTER
      });
     this.setState({loading:false})
 })
        
 }

 SCouponDetailsHandler =(coupon) => {

    let url = this.props.location.state.url?this.props.location.state.url:this.props.match.url;


    axios.post("/support/supportUser/couponDetails", {coupon})
    .then((res) => {

        if(res.data.data){
            if(res.data.supportCoupon.length !== 0){
                this.setState({
                    ...this.state,
                    ScouponDetails: res.data.supportCoupon            
                });

                this.props.history.push({
                    pathname:url+'/CouponSearchDetails',
                    state:{
                        supportCoupon: true,
                        url,
                        MName: this.state.MName,
                        coupon_code : this.state.ScouponDetails,
                        active: '/home/coupon'
                    }
                });
            } else {
                toast.info("No Coupon to Display",{
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.setState({
                    ...this.state,
                    coupon: false,
                    loading: false
                })
            }
        }
          else {
            if(res.data.dbError){
                toast.error("Internal server error",{
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.setState({
                    ...this.state,
                    coupon: false,
                    loading: false
                })
            }
        }
    }).catch((err) => {
        this.setState({
            ...this.state,
            coupon: false,
            loading: false
        })
        toast.warn("Unable to Reach Server! Please Try Again!" , {
            position: toast.POSITION.BOTTOM_CENTER
          });
    });
}

  render() {

    const rowStyle={
        textAlign:"initial"
    }
    const rowStyle1={
        textAlign:"initial",
        marginBottom: 30
    }
    const fWeightSpan={
        fontWeight:"500",
        marginRight:5,
        fontFamily: 'Muli',
        color: '#868686'
    }
    const fWeightSpan1={
        fontWeight:"500",
        marginRight:5,
        textAlign: 'center',
        fontFamily: 'Muli',
        color: '#868686'
        
    }
    const LinkedText = {
        textDecoration:'underline',
        cursor:'pointer', 
        color: '#302939', 
        fontWeight:'bold',
        textTransform: 'uppercase',
        fontFamily: 'Muli',
            // marginLeft: 5,
            color: '#563951'
    }
    
        let Data = (
        //   <Col xl={{size: 10, offset: 1}}>
        <Card style={{border:"1px solid #a78bba",marginTop:10}}>
            <CardHeader style={{fontSize:"18px",color:"#fff",backgroundColor:"#2C2165",fontFamily:'Muli'}}>Support Details</CardHeader>
            
            {this.props.SCouponCode.length !==0 ? 
            <CardText style={{padding:"25px"}}>
                <Row className="mb-4" style={rowStyle}>
                    <Col className="col-4">
                        <text><span style={fWeightSpan}>Coupon Created:</span><text style={LinkedText} onClick={() => this.SCouponDetailsHandler(this.props.SCouponCode[0].SUPPORT_COUPON_CODE)}>{this.props.SCouponCode.length !==0 ? this.props.SCouponCode[0].SUPPORT_COUPON_CODE : null} </text></text>
                    </Col>  
                </Row>

                <Row>
                <Col >
                    {this.props.Sdetails.length !== 0 ? 
            
                        <Table responsive hidden={this.props.Sdetails.length !== 0 ? false : true}> 
                        <thead style={{marginTop: 10,backgroundColor:'#2C2165',color:"white",fontFamily:'Muli'}}>
                            <tr> 
                                <th>Ticket Id </th>
                                <th>Status </th>
                                <th>Severity</th>
                                <th>Description / Resolution </th>
                                <th>Created Date</th>
                                <th>Created By </th>
                            </tr>
                        </thead>
                        <tbody >
                            {this.props.Sdetails.map((detail, index) => {
                                return (
                                    <tr style={{fontFamily:'Muli'}}>                            
                                        <td style={LinkedText} onClick={() => this.supportDetailsHandler(detail.QF_CONSUMER_SUPPORT_PRID,detail.QF_CONSUMER_OFFER_ACCEPTED_PRID,detail.CONSUMER_SUPPORT_CATEGORY,detail.CONSUMER_SUPPORT_DESC,detail.CONSUMER_SUPPORT_STATUS,detail.CONSUMER_SUPPORT_RESOLUTION,detail.CONSUMER_SUPPORT_CREATED_ON_DATE,detail.CONSUMER_SUPPORT_RESOLVED_ON_DATE,detail.SUPPORT_USER_FNAME,detail.SUPPORT_USER_LNAME,detail.resolved?detail.resolved.length!==0?detail.resolved[0].Resolved_Fname:[]:[],detail.resolved?detail.resolved.length!==0?detail.resolved[0].Resolved_Lname:[]:[])}>{detail.QF_CONSUMER_SUPPORT_PRID}</td>
                                        <td>{detail.CONSUMER_SUPPORT_STATUS == 1 ? <Label style={{backgroundColor:"#ffc107",borderRadius:"3px",color:"#fff",marginLeft:"10px",marginRight:"10px",padding:4,width:90}}>Pending</Label> : detail.CONSUMER_SUPPORT_STATUS == 2 ? <Label style={{backgroundColor:"#3DC5B6",borderRadius:"3px",color:"#fff",marginLeft:"10px",marginRight:"10px",padding:4,width:90}}>Resolved</Label> : <text> --- </text>}</td>
                                        <td>{detail.CONSUMER_SUPPORT_SEVERITY === 1 ? <Label style={{backgroundColor:"#00aedb",borderRadius:"3px",color:"#fff",marginLeft:"10px",marginRight:"15px",padding:4, width:90}}>Very Low</Label> :  detail.CONSUMER_SUPPORT_SEVERITY === 2 ? <Label style={{backgroundColor:"#00b159",borderRadius:"3px",color:"#fff",marginLeft:"15px",marginRight:"15px",padding:4,width:90}}>Low</Label> : detail.CONSUMER_SUPPORT_SEVERITY === 3 ? <Label style={{backgroundColor:"#FFC459",borderRadius:"3px",color:"#fff",marginLeft:"15px",marginRight:"15px",padding:4,width:90}}>Medium</Label> : detail.CONSUMER_SUPPORT_SEVERITY === 4 ? <Label style={{backgroundColor:"#f37735",borderRadius:"3px",color:"#fff",marginLeft:"15px",marginRight:"15px",padding:4,width:90}}>High</Label> : detail.CONSUMER_SUPPORT_SEVERITY === 5 ? <Label style={{backgroundColor:"#d11141",borderRadius:"3px",color:"#fff",marginLeft:"10px",marginRight:"15px",padding:4,width:90}}> Very High </Label> : '---'}</td>
                                        <td>{detail.CONSUMER_SUPPORT_STATUS == 1 ? detail.CONSUMER_SUPPORT_DESC : detail.CONSUMER_SUPPORT_STATUS == 2 ? detail.CONSUMER_SUPPORT_RESOLUTION : <text> --- </text>}</td>
                                        <td>{momenttz(detail.CONSUMER_SUPPORT_CREATED_ON_DATE+"Z").tz('Asia/Kolkata').format('DD-MMM-YYYY hh:mm A')} IST</td>
                                        <td>{detail.SUPPORT_USER_FNAME}{' '}{detail.SUPPORT_USER_LNAME}</td>    
                                    </tr>
                                )
                            })
                            }
                        
                    
                            
                        </tbody>
                        </Table>
                        
                    : null}
                    </Col>
                </Row>
            </CardText>
            : 
            <CardText style={{padding:"25px"}}>
                <Row style={rowStyle1}>
                    <Col className="col-12">
                        <text><span style={fWeightSpan1}>No Coupon Created</span></text>
                    </Col>  
                </Row>

                <Row>
                    <Col>
                    {this.props.Sdetails.length !== 0 ? 
            
                        <Table hover hidden={this.props.Sdetails.length !== 0 ? false : true}> 
                        <thead style={{marginTop: 10,backgroundColor:'#2C2165',color:"white", fontFamily:'Muli'}}>
                            <tr>
                                <th> Ticket ID </th>
                                <th> Status </th>
                                <th>Severity</th>
                                <th>Description / Resolution </th>
                                <th>Created Date</th>
                                <th> Created By </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.Sdetails.map((detail, index) => {
                                return (
                                    <tr style={{fontFamily:'Muli'}}>                            
                                        <td style={LinkedText} onClick={() => this.supportDetailsHandler(detail.QF_CONSUMER_SUPPORT_PRID,detail.QF_CONSUMER_OFFER_ACCEPTED_PRID,detail.CONSUMER_SUPPORT_CATEGORY,detail.CONSUMER_SUPPORT_DESC,detail.CONSUMER_SUPPORT_STATUS,detail.CONSUMER_SUPPORT_RESOLUTION,detail.CONSUMER_SUPPORT_CREATED_ON_DATE,detail.CONSUMER_SUPPORT_RESOLVED_ON_DATE,detail.SUPPORT_USER_FNAME,detail.SUPPORT_USER_LNAME,detail.resolved?detail.resolved.length!==0?detail.resolved[0].Resolved_Fname:[]:[],detail.resolved?detail.resolved.length!==0?detail.resolved[0].Resolved_Lname:[]:[])}>{detail.QF_CONSUMER_SUPPORT_PRID}</td>
                                        <td>{detail.CONSUMER_SUPPORT_STATUS == 1 ? <Label style={{backgroundColor:"#ffc107",borderRadius:"3px",color:"#fff",marginLeft:"15px",marginRight:"15px",padding:4,width:90}}>Pending</Label> : detail.CONSUMER_SUPPORT_STATUS == 2 ? <Label style={{backgroundColor:"#3DC5B6",borderRadius:"3px",color:"#fff",marginLeft:"15px",marginRight:"15px",padding:4,width:90}}>Resolved</Label> : <text> --- </text>}</td>
                                        <td>{detail.CONSUMER_SUPPORT_SEVERITY ? detail.CONSUMER_SUPPORT_SEVERITY === 1 ? <Label style={{backgroundColor:"#00aedb",borderRadius:"3px",color:"#fff",marginLeft:"15px",marginRight:"15px",padding:4, width:90}}>Very Low</Label> :  detail.CONSUMER_SUPPORT_SEVERITY === 2 ? <Label style={{backgroundColor:"#00b159",borderRadius:"3px",color:"#fff",marginLeft:"15px",marginRight:"15px",padding:4,width:90}}>Low</Label> : detail.CONSUMER_SUPPORT_SEVERITY === 3 ? <Label style={{backgroundColor:"#ffc425",borderRadius:"3px",color:"#fff",marginLeft:"15px",marginRight:"15px",padding:4,width:90}}>Medium</Label> : detail.CONSUMER_SUPPORT_SEVERITY === 4 ? <Label style={{backgroundColor:"#f37735",borderRadius:"3px",color:"#fff",marginLeft:"15px",marginRight:"15px",padding:4,width:90}}>High</Label> : detail.CONSUMER_SUPPORT_SEVERITY === 5 ? <Label style={{backgroundColor:"#d11141",borderRadius:"3px",color:"#fff",marginLeft:"15px",marginRight:"15px",padding:4,width:90}}> Very High </Label> : '---' :'---'}</td>
                                        <td>{detail.CONSUMER_SUPPORT_STATUS == 1 ? detail.CONSUMER_SUPPORT_DESC : detail.CONSUMER_SUPPORT_STATUS == 2 ? detail.CONSUMER_SUPPORT_RESOLUTION : <text> --- </text>}</td>
                                        <td>{momenttz(detail.CONSUMER_SUPPORT_CREATED_ON_DATE+"Z").tz('Asia/Kolkata').format('DD-MMM-YYYY hh:mm A')} IST</td>
                                        <td>{detail.SUPPORT_USER_FNAME}{' '}{detail.SUPPORT_USER_LNAME}</td>    
                                    </tr>
                                )
                            })
                            }
                        
                    
                            
                        </tbody>
                        </Table>
                        
                    : null}
                    </Col>
                </Row>
            </CardText>
            }
        </Card>
          
        // </Col>
             
        )
   
      
    return (
        <div>
            {Data}
            <ToastContainer />
        </div>      
    );

  }
}

export default withRouter(SupportIdView);