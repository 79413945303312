import React, { Component } from 'react';
import { Form, FormGroup, FormFeedback, Label, Input, Button, Container } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import { BarLoader } from "react-spinners";


class BookingId extends Component {

    state = {
        bookingId : {
            value: '',
            valid : false,
            invalid: false
        },
        submit : {
            invalid: false
        },
        noData: false,
        loading: false,
        sClicked:false
    }

    bookingIdHandler = (event) => {
        let k=/^[0-9\b]+$/;
       
        if(event.target.value===''||k.test(event.target.value)){
            return this.setState({
                bookingId : {
                    ...this.state.bookingId,
                    value: event.target.value,
                    valid: true,
                    invalid: false
                },
                submit: {
                    ...this.state.submit,
                    invalid: false
                },
                noData:false
            });
        } 
        // else {
        //     this.setState({
        //         bookingId: {
        //             ...this.state.bookingId,
        //             valid: false,
        //             invalid: true
        //         },
        //         submit: {
        //             ...this.state.submit,
        //             invalid: true
        //         }
        //     })
        // }
    }

    submitButtonHandler = (booking_id) => {
        if(this.state.bookingId.invalid || 
            this.state.bookingId.value.length === 0){

            this.setState({
                submit: {
                    ...this.state.submit,
                    invalid: true 
                },
                bookingId:{
                    ...this.state.bookingId,
                    valid: false,
                    invalid: true
                }
            });
            return false;
        } else {

            this.setState({
                submit: {
                    ...this.state.submit,
                    invalid: false
                },
                bookingId:{
                    ...this.state.bookingId,
                    valid: true,
                    invalid: false
                },
                loading: true,
                sClicked:true
            });

            axios.post("/support/consumer/searchBookingId",{acceptance_prid:this.state.bookingId.value})
            .then(res=>{
                // console.log("Response",res.data);

                if(res.data.searchB)
                {

                    this.setState({
                        ...this.state,
                        sClicked:false,
                        loading: false
                    })

                    this.props.history.push({
                        pathname:"/home/bookingId/BookingIdSearch",
                        state:{
                            booking_id: this.state.bookingId.value,
                            bookLink: '/home/bookingId',
                            book: 'Booking Details',
                        }
                    });
                }
                else{
                    if(res.data.dbError){
                        this.setState({
                            ...this.state,
                            bId: null,  
                            noData: true,
                            sClicked:false,
                            loading: false               
                        });
                        toast.error("Internal Server Error", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    } else {
                        this.setState({
                            ...this.state,
                            sClicked:false,
                            noData: true, 
                            loading: false             
                        });
                        
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    ...this.state,
                    sClicked:false,
                    noData: true, 
                    loading: false             
                });
                toast.warn("Unable to Reach Server! Please Try Again!" , {
                position: toast.POSITION.BOTTOM_CENTER
                });
            });
        }
    }

    onKeyDown=(event)=>{
        if(event.key==='Enter')
        {
            event.preventDefault();
            event.stopPropagation();
            this.submitButtonHandler();
        }
        
    }
    
    render() {

        let nodata = null;
        if(this.state.noData){
            nodata = <text style={{fontFamily:'Muli'}}>No results to display</text>
        }
        return (
            <div>
                <Container fluid>
            <div className="row justify-content-center" style={{backgroundColor: '#EFEFEF'}}>
                <div className="col-3" >
                    <Form onSubmit={() => this.submitButtonHandler(this.state.bookingId.value)}>
                        <FormGroup row>
                            <Label for="Booking Id" ><text style={{fontFamily:'Muli'}}>Booking Id </text><span style={{color: 'red'}}>*</span></Label>
                            <Input  onChange={this.bookingIdHandler}
                                    valid={this.state.bookingId.valid ? true : false}
                                    invalid={this.state.bookingId.invalid ? true : false} 
                                    autoFocus 
                                    value={this.state.bookingId.value}
                                    onKeyDown={this.onKeyDown}
                                    placeholder="Please enter Booking Id"/>
                            <FormFeedback><text style={{fontFamily:'Muli'}}> Booking Id is invalid!</text></FormFeedback>
                        </FormGroup>
                       
                        <FormGroup>
                            <Button onClick={() => this.submitButtonHandler(this.state.bookingId.value)} style={{backgroundColor:"#2C2165", color:'white'}} disabled={this.state.sClicked}><text style={{fontFamily:'Muli'}}>Search</text></Button>
                            {/* disabled={this.state.submit.invalid ? true : false} */}
                        </FormGroup>
                    </Form>
                    <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/>

                    {nodata}
                    <ToastContainer />
                    
            </div>
            </div>
            </Container>
            </div>
        )
    }
} 

export default BookingId;