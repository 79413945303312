import React,{Component} from "react";
import {Container,Button,Form,Input} from "reactstrap";
import {ToastContainer,toast} from "react-toastify";
import "../../../node_modules/react-toastify/dist/ReactToastify.css";
import axios from "axios";
// import {Redirect} from "react-router-dom";
import {BarLoader} from 'react-spinners';
import {connect} from "react-redux";
// import { bindActionCreators } from "../../../../../../../Library/Caches/typescript/3.1/node_modules/redux";

class Login extends Component
{
    state={
        //loginStatus:false,
        email:"",
        password:"",
        emailValid:false,
        passwordValid:false,
        toastMessage:"",
        emailTouch:false,
        passwordTouch:false,
        loading:false
    }
    componentDidMount()
    {

    }

    onLoginHandler=(e)=>{
        // e.preventDefault();
        //console.log("Hello form login",e);


        if(this.state.email.trim()==="" || this.state.email.trim()===null)
        {
            this.setState({
                emailValid:false
            });
            toast.error("Email Id field cannot be blank",{position:toast.POSITION.BOTTOM_CENTER});

        }
        else if(this.state.password.trim()==="" || this.state.password.trim()===null)
        {
            this.setState({
                passwordValid:false
            });
            toast.error("Password filed cannot be blank",{position:toast.POSITION.BOTTOM_CENTER});

        }
        else if(!/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(this.state.email.trim()))
        {
            this.setState({
                emailValid:false
            });
            toast.error("Not a valid Email Id",{position:toast.POSITION.BOTTOM_CENTER});

        }
        else
        {
            this.setState({loading:true});
             let userDetails={
                 email:this.state.email,
                 password:this.state.password
             }   
            axios.post("/support/login",userDetails)
            .then(res=>{
                // console.log(res.data);
                if(res.data.loginStatus)
                {
                    
                    toast.success("Sign In Successfull",{position:toast.POSITION.BOTTOM_CENTER});
                    this.setState({loading:false});
                    this.props.setToken(res.data.userDetails.token);
                    this.props.setCredentials(res.data.userDetails);
                    this.props.history.push("/home/customerDetails");
                    
                    
                }
                else
                {
                    if(res.data.dbError===true || res.data.bcryptError)
                    {
                        toast.error("Internal Server Error",{position:toast.POSITION.BOTTOM_CENTER});
                        this.setState({loading:false})

                    }
                    if(res.data.usernameCheck===false)
                    {
                        toast.warn("Email not found. Please contact Admin",{position:toast.POSITION.BOTTOM_CENTER});
                        this.setState({loading:false})

                    }
                    if(res.data.passwordCheck===false)
                    {
                        toast.warn("Incorrect Email or Password",{position:toast.POSITION.BOTTOM_CENTER});
                        this.setState({loading:false})

                    }
                    if(res.data.Suspended===true)
                    {
                        toast.warn("Your Account is Suspended",{position:toast.POSITION.BOTTOM_CENTER});
                        this.setState({loading:false})

                    }
                    this.setState({loading:false})
                }

            }).catch(err=>{
                console.log(err);
                toast.warn("Unable to Reach Server! Please Try Again!" , {
                    position: toast.POSITION.BOTTOM_CENTER
                  });
                this.setState({loading:false})

            })

        }

    }
    emailChangeHandler=(e)=>{
        //console.log("from email change",e.target.value)
        this.setState({
            email:e.target.value,
            emailValid:true,
            emailTouch:true
        })

    }
    passwordChangeHandler=(e)=>{
        //console.log("from password change")
        this.setState({
            password:e.target.value,
            passwordValid:true,
            passwordTouch:true
        })
    }

    onKeyDown=(event)=>{
        if(event.key==='Enter')
        {
            event.preventDefault();
            event.stopPropagation();
            this.onLoginHandler();
        }
        
    }

    render()
    {
        //const override=css`width:100%`;
    console.log("state",this.state);
        return(
            
                <Container fluid className="text-center" style={{height:"100%",width:"100%",backgroundColor:"#f5f5f5"}}>

                    <Form style={{width:"100%",maxWidth:"300px",paddingTop:"100px",margin:"0 auto"}}>
                        <img className="mb-4" src="https://cdn4.iconfinder.com/data/icons/rcons-user/32/support_man-512.png" style={{width:"80px",height:"80px"}} />
                        <h3 className="mb-4 font-weight-normal">Sign In</h3>
                        <Input valid={this.state.emailValid} invalid={!this.state.emailValid && this.state.emailTouch} value={this.state.email} onChange={this.emailChangeHandler} type="email" style={{borderBottomRightRadius:0,borderBottomLeftRadius:0,marginBottom:"0px",height:"auto",padding:10}} placeholder="Email address" onKeyDown={this.onKeyDown} required autoFocus/>
                        <Input valid={this.state.passwordValid} invalid={!this.state.passwordValid && this.state.passwordTouch} value={this.state.password} onChange={this.passwordChangeHandler} className="mb-4" type="password" style={{borderTopLeftRadius:0,borderTopRightRadius:0,height:"auto",padding:10}} onKeyDown={this.onKeyDown} placeholder="Password" required/>
                        <Button onClick={this.onLoginHandler} style={{backgroundColor:"#2C2165", color:'white'}} className="btn-lg btn-block" >Sign In</Button>
                        <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/>
                    </Form>
                    
                    <ToastContainer autoClose={2500}/>
                </Container>
            
            
        );
    }
}

const mapStateToProps=state=>{
    return {
        token:state.token,
        id:state.id,
        email:state.email,
        fname:state.fname,
        role:state.role

    }
}

const mapDispatchToProps=dispatch=>{
    return {
        setToken:(token)=>dispatch({type:"SET_TOKEN",token}),
        setCredentials:(userDetails)=>dispatch({type:"SET_CREDENTIALS",userDetails})
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Login);