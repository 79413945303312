import React, { Component } from 'react';
import { Form, FormGroup, FormFeedback, Label, Input, Button,Container } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import { BarLoader} from "react-spinners";

import CouponView from '../SubComponents/ViewByCouponCode';


class CouponsDetails extends Component { 

    state = {
        couponCode : {
            value: null,
            valid: false,
            invalid: false
        },
        submit :{
            invalid: false,
            valid: false
        },
        McouponDetails: [],
        OcouponDetails:[],
        ScouponDetails:[],
        bookingDet: null,
        MName: null,
        coupon: false,
        loading: false,
        submitButton:false,
        country: null,
        tz: null
    }

    couponCodeHandler = (event) => {
        if(event.target.value.length === 0){
            return this.setState({
                couponCode:{
                    ...this.state.couponCode,
                    value: null,
                    valid: false,
                    invalid: true
                },
               
                submitButton:false
            })
        } else {
            return this.setState({
                couponCode:{
                    ...this.state.couponCode,
                    value: event.target.value.toUpperCase(),
                    valid: true,
                    invalid: false
                },
                submit:{
                    ...this.state.submit,
                    invalid: false
                },
                submitButton:false

                
            })
        }
    }

    submitButtonHandler = () => {
        if(this.state.couponCode.invalid || this.state.couponCode.value === null){

            this.setState({
                submit: {
                    ...this.state.submit,
                    invalid: true 
                },
                couponCode:{
                    ...this.state.couponCode,
                    valid: false,
                    invalid: true
                }               
            });
            return false;
        } else if(this.state.couponCode.value !== null) {
           
            let coupon = this.state.couponCode.value
            // console.log("Coupon here", coupon);
            this.setState({
                ...this.state,
                submitButton: true,
                loading: true
            })

            axios.post("/support/consumer/searchByCoupon", {coupon})
            .then((res) => {
                // console.log("coupon", res.data);

                if(res.data.data){
                    // console.log("true");
                    if(res.data.merchantCoupon.length !== 0){
                        // console.log("Merchant Coupon");
                        this.setState({
                            ...this.state,
                            McouponDetails: res.data.merchantCoupon,
                            // MName: res.data.resultName[0].MERCHANT_NAME,
                            // country: res.data.resultName[0].MERCHANT_ADDRESS_COUNTRY, 
                            // tz: res.data.resultName[0].MERCHANT_TIMEZONE, 
                            submit: {
                                ...this.state.submit,
                                invalid: false,
                                valid: true
                            },
                            couponCode:{
                                ...this.state.couponCode,
                                valid: true,
                                invalid: false
                            },
                            coupon: true,
                            loading: false
                        });

                        this.props.history.push({
                            pathname:'/home/coupon/CouponSearchDetails',
                            state:{
                                merchant: true,
                                // country: this.state.country,
                                // MName: this.state.MName,
                                // tz:this.state.tz,
                                coupon_code : this.state.McouponDetails,
                                couponLink: '/home/coupon',
                                coupon:'Coupon Details'
                            }
                        });
                    } else if(res.data.ourCoupon.length !== 0){
                        // console.log("Our Coupon");
                        this.setState({
                            ...this.state,
                            OcouponDetails: res.data.ourCoupon,
                            submit: {
                                ...this.state.submit,
                                invalid: false,
                                valid: true
                            },
                            couponCode:{
                                ...this.state.couponCode,
                                valid: true,
                                invalid: false
                            },
                            coupon: true,
                            loading: false
                        });

                        this.props.history.push({
                            pathname:'/home/coupon/CouponSearchDetails',
                            state:{
                                our: true,
                                MName: this.state.MName,
                                coupon_code : this.state.OcouponDetails,
                                couponLink: '/home/coupon',
                                coupon:'Coupon Details'
                            }
                        });
                    }  else if(res.data.supportCoupon.length !== 0){
                        // console.log("Our Coupon");
                        this.setState({
                            ...this.state,
                            ScouponDetails: res.data.supportCoupon,
                            bookingDet: res.data.bookingDet[0].MERCHANT_OFFER_CURRENCY,
                            submit: {
                                ...this.state.submit,
                                invalid: false,
                                valid: true
                            },
                            couponCode:{
                                ...this.state.couponCode,
                                valid: true,
                                invalid: false
                            },
                            coupon: true,
                            loading: false
                        });

                        this.props.history.push({
                            pathname:'/home/coupon/CouponSearchDetails',
                            state:{
                                supportCoupon: true,
                                country: this.state.country,
                                MName: this.state.MName,
                                coupon_code : this.state.ScouponDetails,
                                couponLink: '/home/coupon',
                                coupon:'Coupon Details'
                            }
                        });
                    } else {
                        // console.log("No coupon found");
                        // toast.info("No Coupon to Display",{
                        //     position: toast.POSITION.BOTTOM_CENTER
                        // });
                        this.setState({
                            ...this.state,
                            coupon: false,
                            loading: false
                        })
                    }
                }
                 else {
                    // console.log("false");
                    if(res.data.dbError){
                        toast.error("Internal server error",{
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        this.setState({
                            ...this.state,
                            coupon: false,
                            loading: false
                        })
                        return console.log("Database Error");
                    } else{
                        this.setState({
                            ...this.state,
                            loading: false,
                            coupon: false    
                        })
                    }

                    
                }
            }).catch((err) => {
                console.log(err);
                this.setState({
                    ...this.state,
                    coupon: false,
                    loading: false
                })
                toast.warn("Unable to Reach Server! Please Try Again!" , {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }); 
        }
    }

    onKeyDown=(event)=>{
        if(event.key==='Enter')
        {
            event.preventDefault();
            event.stopPropagation();
            this.submitButtonHandler();
        }
        
    }
    
    render() {

        // console.log("In Coupon", this.state);
        let couponView=null;

        if(!this.state.coupon){     
            if(this.state.submitButton)
            {
                if(!this.state.loading){
                   
                    couponView=(<text style={{fontFamily:'Muli'}}>No results to display</text>)
                }
            }
            else
            {
                couponView=null;
            }
        }


        return (
            <div>
                <Container fluid>
            <div className="row justify-content-center" style={{backgroundColor: '#EFEFEF'}}>
            <div className="col-3" >
            
                    <Form>
                        <FormGroup row>
                            <Label for="Coupon" ><text style={{fontFamily:'Muli'}}>Coupon Code </text><span style={{color: 'red'}}>*</span></Label>
                            <Input  onChange={this.couponCodeHandler}
                                    valid={this.state.couponCode.valid ? true : false}
                                    invalid={this.state.couponCode.invalid ? true : false} 
                                    value={this.state.couponCode.value}
                                    autoFocus
                                    onKeyDown={this.onKeyDown}
                                    placeholder="Please enter coupon code"
                                    maxLength={10}/>
                                    <FormFeedback><text style={{fontFamily:'Muli'}}>Coupon code is invalid</text></FormFeedback>
                        </FormGroup>
                        
                        <FormGroup>
                            <Button onClick={this.submitButtonHandler} style={{backgroundColor:"#2C2165", color:'white'}} disabled={this.state.loading ? true : false}><text style={{fontFamily:'Muli'}}>Search</text></Button>
                        </FormGroup>
                        
                        <ToastContainer />
                    </Form>
                    {couponView}
                <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/>                
   
                                
            </div>
            </div>
                </Container>
            </div>
        )
    }
} 

export default CouponsDetails;