import React,{Component} from "react";
import { Container, Col, Row, Card, CardText, CardHeader,Button } from "reactstrap";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import "../../../node_modules/react-toastify/dist/ReactToastify.css";
import { BarLoader } from "react-spinners"
import BreadCrumbs from '../Navbar/Breadcrumb'; 
import moment from 'moment';
import Switch from 'react-switch';
import Curr from 'currency.js';

class CouponSearchDetails extends Component {
    state = {
        OurCoupon_data: this.props.location.state.coupon_code,
        couponAppliedBookings:[],
        city: null,
        category: null,
        sub_category: null,
        MName: null,
        country: this.props.location.state.country,
        createdBy: {
            fname:'',
            lname:''
        },
        bookingDet: null,
        ourCoupon: this.props.location.state.our ? this.props.location.state.our : false,
        merchantCoupon: this.props.location.state.merchant ? this.props.location.state.merchant : false,
        supportCoupon: this.props.location.state.supportCoupon ? this.props.location.state.supportCoupon : false,
        loading: false,
        toggle:[],
        tz:this.props.location.state.tz ? this.props.location.state.tz : null,
        active: this.props.location.state.active,
        active0: this.props.location.state.active0 ? this.props.location.state.active0 : null,
        active1: this.props.location.state.active1 ? this.props.location.state.active1 : null,
        active2: this.props.location.state.active2 ? this.props.location.state.active2 : null
    }

    componentDidMount() {
 
       console.log('trace',this.props); 
       
        this.setState({
            ...this.state,
            loading: true,
            MName: this.props.location.state.McouponDetails ? this.props.location.state.McouponDetails[0].MERCHANT_NAME : null,
            // tz: this.props.location.state.tz ? this.props.location.state.tz : null
        })
        // console.log("component Did mount State", this.state);
        // console.log("Coupon Log", this.state.OurCoupon_data );

        let toggle=[];
        if(this.state.ourCoupon){
            let body ={
                id : this.state.OurCoupon_data[0].QF_OUR_COUPON_PRID,
                Ccode: 'ourCoupon'
            };
            // console.log("Our Coupon id", body);

            let coupon = this.state.OurCoupon_data[0].OUR_COUPON_CODE;

            axios.post("/support/consumer/searchByCoupon", {coupon})
            .then((res) => {
                console.log("coupon", res.data);

                if(res.data.data){

                    res.data.ourCoupon.map((coupon) => {
                        if(coupon.OUR_COUPON_ACTIVE === 1){
                            return toggle['coupon_'+coupon.QF_OUR_COUPON_PRID] = true
                        } else if(coupon.OUR_COUPON_ACTIVE === 0){
                            return toggle['coupon_'+coupon.QF_OUR_COUPON_PRID] = false
                        }
                    })
                    
                        this.setState({
                            ...this.state,
                            OurCoupon_data: res.data.ourCoupon,
                            // couponAppliedBookings:res.data.couponAppliedBookings.length !== 0 ? res.data.couponAppliedBookings : [],
                            toggle : toggle
                        });
                   console.log('data of adm coupon',this.state.OurCoupon_data)
                }
                 else {
                    // console.log("false");
                    if(res.data.dbError){
                        toast.error("Internal server error",{
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        this.setState({
                            ...this.state,
                            coupon: false,
                            loading: false
                        })
                        return console.log("Database Error");
                    }
                }
            }).catch((err) => {
                console.log(err);
                this.setState({
                    ...this.state,
                    coupon: false,
                    loading: false
                })
                toast.warn("Unable to Reach Server! Please Try Again!" , {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }); 

            axios.post("/support/consumer/city_cat_subCat", body)
            .then((res)=> {
                // console.log("OurCoupon Response", res);
                if(res.data.result){
                    // console.log("Result is true");
                    if(res.data.city && !res.data.catg && !res.data.sub){
                        // console.log("City is true",res.data.cityResult[0].CITY_NAME);
                        this.setState({
                            ...this.state,
                            city: res.data.cityResult[0].CITY_NAME,
                            loading:false
                        })
                    } else if(!res.data.city && res.data.catg && !res.data.sub){
                        // console.log("Category is true");
                        this.setState({
                            ...this.state,
                            category: res.data.cat[0].category_name,
                            loading: false
                        });
                    } else if(!res.data.city && !res.data.catg && res.data.sub){
                        // console.log("Sub-Category is true");
                        this.setState({
                            ...this.state,
                            sub_category: res.data.subCat[0].service_sub_category_name,
                            loading: false
                        });
                    } else if(res.data.city && res.data.catg && !res.data.sub){
                        // console.log("City and Cateory are true");
                        this.setState({
                            ...this.state,
                            city: res.data.cityResult[0].CITY_NAME,
                            category: res.data.cat[0].category_name,
                            loading: false
                        });
                    } else if(res.data.city && !res.data.catg && res.data.sub){
                        // console.log("City and Sub-Category are true");
                        this.setState({
                            ...this.state,
                            city: res.data.cityResult[0].CITY_NAME,
                            sub_category: res.data.subCat[0].service_sub_category_name,
                            loading: false
                        });
                    } else if(!res.data.city && res.data.catg && res.data.sub){
                        // console.log("Category and Sub-Category are true");
                        this.setState({
                            ...this.state,
                            category: res.data.cat[0].category_name,
                            sub_category: res.data.subCat[0].service_sub_category_name,
                            loading: false
                        });
                    } else if(res.data.city && res.data.catg && res.data.sub){
                        // console.log("All fields are true");
                        this.setState({
                            ...this.state,
                            city: res.data.cityResult[0].CITY_NAME,
                            category: res.data.cat[0].category_name,
                            sub_category: res.data.subCat[0].service_sub_category_name,
                            loading: false
                        });
                    } else {
                        if(res.data.dbError){ 
                            this.setState({
                                ...this.state,
                                loading: false
                            })
                            toast.error("Internal Server Error", {
                                position: toast.POSITION.BOTTOM_CENTER
                            })
                        }
                        this.setState({
                            ...this.state,
                            loading: false
                        })
                    }
                }
            }).catch(err => {
                this.setState({
                    ...this.state,
                    loading: false
                })
                toast.warn("Unable to Reach Server! Please Try Again!" , {
                    position: toast.POSITION.BOTTOM_CENTER
                  });
            })

        } else if(this.state.merchantCoupon){
            let body = {
                 id : this.state.OurCoupon_data[0].QF_MERCHANT_COUPON_PRID,
                 Ccode: 'merchantCoupon'
            };
            // console.log("Merchant Coupon id", body);

            let coupon = this.state.OurCoupon_data[0].MERCHANT_COUPON_CODE;

            axios.post("/support/consumer/searchByCoupon", {coupon})
            .then((res) => {
             console.log("coupon", res.data);

                if(res.data.data){
                        res.data.merchantCoupon.map((coupon) => {
                            if(coupon.MERCHANT_COUPON_ACTIVE === 1){
                                return toggle['coupon_'+coupon.QF_MERCHANT_COUPON_PRID] = true
                            } else if(coupon.MERCHANT_COUPON_ACTIVE === 0){
                                return toggle['coupon_'+coupon.QF_MERCHANT_COUPON_PRID] = false
                            }
                        })

                        
                        this.setState({
                            ...this.state,
                            OurCoupon_data: res.data.merchantCoupon,
                            // couponAppliedBookings:res.data.couponAppliedBookings.length !== 0 ? res.data.couponAppliedBookings : [],
                            toggle: toggle
                        });
                        console.log('mrchant coupon',this.state.OurCoupon_data)
                } else {
                    if(res.data.dbError){
                        toast.error("Internal server error",{
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        this.setState({
                            ...this.state,
                            coupon: false,
                            loading: false
                        })
                        return console.log("Database Error");
                    }
                }
            }).catch((err) => {
                console.log(err);
                this.setState({
                    ...this.state,
                    coupon: false,
                    loading: false
                })
                toast.warn("Unable to Reach Server! Please Try Again!" , {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }); 

            axios.post("/support/consumer/city_cat_subCat", body)
            .then((res1)=> {
                // console.log("Merchant Response", res1);
                if(res1.data.result){
                    // console.log("Result is true");
                    if(res1.data.city && !res1.data.catg && !res1.data.sub){
                        // console.log("City is true");
                        this.setState({
                            ...this.state,
                            city: res1.data.cityResult[0].CITY_NAME,
                            loading: false
                        });
                    } else if(!res1.data.city && res1.data.catg && !res1.data.sub){
                        // console.log("Category is true");
                        this.setState({
                            ...this.state,
                            category: res1.data.cat[0].category_name,
                            loading: false
                        });
                    } else if(!res1.data.city && !res1.data.catg && res1.data.sub){
                        // console.log("Sub-Category is true");
                        this.setState({
                            ...this.state,
                            sub_category: res1.data.subCat[0].service_sub_category_name,
                            loading: false
                        });
                    } else if(res1.data.city && res1.data.catg && !res1.data.sub){
                        // console.log("City and Cateory are true");
                        this.setState({
                            ...this.state,
                            city: res1.data.cityResult[0].CITY_NAME,
                            category: res1.data.cat[0].category_name,
                            loading: false
                        });
                    } else if(res1.data.city && !res1.data.catg && res1.data.sub){
                        // console.log("City and Sub-Category are true");
                        this.setState({
                            ...this.state,
                            city: res1.data.cityResult[0].CITY_NAME,
                            sub_category: res1.data.subCat[0].service_sub_category_name,
                            loading: false
                        });
                    } else if(!res1.data.city && res1.data.catg && res1.data.sub){
                        // console.log("Category and Sub-Category are true");
                        this.setState({
                            ...this.state,
                            category: res1.data.cat[0].category_name,
                            sub_category: res1.data.subCat[0].service_sub_category_name,
                            loading: false
                        });
                    } else if(res1.data.city && res1.data.catg && res1.data.sub){
                        // console.log("All fields are true");
                        this.setState({
                            ...this.state,
                            city: res1.data.cityResult[0].CITY_NAME,
                            category: res1.data.cat[0].category_name,
                            sub_category: res1.data.subCat[0].service_sub_category_name,
                            loading: false
                        });
                    } else {
                        if(res1.data.dbError){
                            this.setState({
                                ...this.state,
                                loading: false
                            })
                            toast.error("Internal Server Error", {
                                position: toast.POSITION.BOTTOM_CENTER
                            })
                        }
                        this.setState({
                            ...this.state,
                            loading: false
                        })
                    }
                }
            }).catch(err1 => {
                
                toast.warn("Unable to Reach Server! Please Try Again!" , {
                    position: toast.POSITION.BOTTOM_CENTER
                  });
                
            })
        } else if(this.state.supportCoupon){
            let body = {
                id: this.state.OurCoupon_data[0].QF_SUPPORT_COUPON_PRID,
                Ccode: 'supportCoupon'
            };
            // console.log("Support Coupon id", body);

            let coupon = this.state.OurCoupon_data[0].SUPPORT_COUPON_CODE;

            axios.post("/support/consumer/searchByCoupon", {coupon})
            .then((res) => {
                console.log("coupon", res.data);

                if(res.data.data){

                    res.data.supportCoupon.map((coupon) => {
                        if(coupon.SUPPORT_COUPON_ACTIVE === 1){
                            return toggle['coupon_'+coupon.QF_SUPPORT_COUPON_PRID] = true
                        } else if(coupon.SUPPORT_COUPON_ACTIVE === 0){
                            return toggle['coupon_'+coupon.QF_SUPPORT_COUPON_PRID] = false
                        }
                    })
                    
                        this.setState({
                            ...this.state,
                            OurCoupon_data: res.data.supportCoupon,
                            bookingDet: res.data.bookingDet[0].MERCHANT_OFFER_CURRENCY?res.data.bookingDet[0].MERCHANT_OFFER_CURRENCY:res.data.bookingDet[0].SERVICE_CURRENCY,
                            // couponAppliedBookings:res.data.couponAppliedBookings.length !== 0 ? res.data.couponAppliedBookings : [],
                            toggle: toggle
                        });
                        console.log('data of sup coupon',this.state.OurCoupon_data)
                } else {
                    // console.log("false");
                    if(res.data.dbError){
                        toast.error("Internal server error",{
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        this.setState({
                            ...this.state,
                            coupon: false,
                            loading: false
                        })
                        return console.log("Database Error");
                    }
                }
            }).catch((err) => {
                console.log(err);
                this.setState({
                    ...this.state,
                    coupon: false,
                    loading: false
                })
                toast.warn("Unable to Reach Server! Please Try Again!" , {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }); 

            axios.post("/support/consumer/city_cat_subCat", body)
            .then((res1)=> {
                // console.log("Support Response", res1);
                
                this.setState({
                    ...this.state,
                    createdBy: {
                        ...this.state.createdBy,
                        fname: res1.data.supportCoupon[0].SUPPORT_USER_FNAME,
                        lname: res1.data.supportCoupon[0].SUPPORT_USER_LNAME
                    } 
                })
                if(res1.data.result){
                    // console.log("Result is true");
                    if(res1.data.city && !res1.data.catg && !res1.data.sub){
                        // console.log("City is true");
                        this.setState({
                            ...this.state,
                            city: res1.data.cityResult[0].CITY_NAME,
                            loading: false
                        });
                    } else if(!res1.data.city && res1.data.catg && !res1.data.sub){
                        // console.log("Category is true");
                        this.setState({
                            ...this.state,
                            category: res1.data.cat[0].category_name,
                            loading: false
                        });
                    } else if(!res1.data.city && !res1.data.catg && res1.data.sub){
                        // console.log("Sub-Category is true");
                        this.setState({
                            ...this.state,
                            sub_category: res1.data.subCat[0].service_sub_category_name,
                            loading: false
                        });
                    } else if(res1.data.city && res1.data.catg && !res1.data.sub){
                        // console.log("City and Cateory are true");
                        this.setState({
                            ...this.state,
                            city: res1.data.cityResult[0].CITY_NAME,
                            category: res1.data.cat[0].category_name,
                            loading: false
                        });
                    } else if(res1.data.city && !res1.data.catg && res1.data.sub){
                        // console.log("City and Sub-Category are true");
                        this.setState({
                            ...this.state,
                            city: res1.data.cityResult[0].CITY_NAME,
                            sub_category: res1.data.subCat[0].service_sub_category_name,
                            loading: false
                        });
                    } else if(!res1.data.city && res1.data.catg && res1.data.sub){
                        // console.log("Category and Sub-Category are true");
                        this.setState({
                            ...this.state,
                            category: res1.data.cat[0].category_name,
                            sub_category: res1.data.subCat[0].service_sub_category_name,
                            loading: false
                        });
                    } else if(res1.data.city && res1.data.catg && res1.data.sub){
                        // console.log("All fields are true");
                        this.setState({
                            ...this.state,
                            city: res1.data.cityResult[0].CITY_NAME,
                            category: res1.data.cat[0].category_name,
                            sub_category: res1.data.subCat[0].service_sub_category_name,
                            loading: false
                        });
                    } else {
                        if(res1.data.dbError){
                            this.setState({
                                ...this.state,
                                loading: false
                            })
                            toast.error("Internal Server Error", {
                                position: toast.POSITION.BOTTOM_CENTER
                            })
                        }
                        this.setState({
                            ...this.state,
                            loading: false
                        })
                    }
                }
            }).catch(err1 => {
                
                toast.warn("Unable to Reach Server! Please Try Again!" , {
                    position: toast.POSITION.BOTTOM_CENTER
                  });
                
            })
        }
    }

    MerchantDetailsHandler=(id)=>{
        // console.log("Merchan personnel prid", id);
        let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;

        this.props.history.push({
            pathname:url+'/MerchantSearchDetails',
            state:{
                id: id,
                url,
                active: this.state.active,
                active0: this.state.active0,
                active0Link: this.state.active0Link,
                active1: this.state.active1,
                active2: this.state.active2
             
        }});
      
    }
updateHandler=(id)=>{
alert(id)  
}


bookingIdLinkHandler = (booking_id) => {
    let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
    this.props.history.push({
        pathname: url+'/BookingIdSearch',
        state:{
            booking_id,
            url,
            condata: this.props.location.state.condata,
            active: this.state.active,
            active0: this.state.active0,
            active0Link: this.state.active0Link,
            active1: this.state.active1,
            active1Link: this.state.active1Link,
            active2: this.state.active2 ,
            active2Link: this.state.active2Link,
            active3: this.state.active3,
            active3Link: this.state.active3Link,
            active4: this.state.active4,
            active4Link: this.state.active4Link,
            active5: this.state.active5,
            active5Link: this.state.active5Link,
            active6: this.state.active6,
            active6Link: this.state.active6Link,
            active7: this.state.active7,
            active7Link: this.state.active7Link ,
            active8: this.state.active8 ,
            active8Link: this.state.active8Link,
            active9: this.state.active9,
            active9Link: this.state.active9Link,
        }
    });
}

merchantCouponToggleHandler = (prid) => {
    // console.log("Coupon Prid", prid);

    let toggle={}
    if(this.state.toggle['coupon_'+prid]){
        toggle={
            ...this.state.toggle,
            ['coupon_'+prid]: false
        }
        this.setState({
            ...this.state,
            toggle: toggle
        })

        let body ={
            prid,
            val: false
        }

        axios.post('/support/merchant/couponactive', body)
        .then(res => {
            if(res.data.updated){
                this.componentDidMount();
                toast.success("Coupon is Deactivated", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            } else {
                if(res.data.dbError){
                    toast.error("Internal Server Error", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                } else {
                    this.setState({
                        ...this.state,
                        loading: false
                    })
                }
            }
        })
        .catch(err => {
            toast.warn("Unable to Reach Server! Please Try Again!" , {
                position: toast.POSITION.BOTTOM_CENTER
              });
        })
    } else {
        toggle={
            ...this.state.toggle,
            ['coupon_'+prid]: true
        }
        this.setState({
            ...this.state,
            toggle: toggle
        })

        let body ={
            prid,
            val: true
        }

        axios.post('/support/merchant/couponactive', body)
        .then(res => {
            if(res.data.updated){
                this.componentDidMount();
                toast.success("Coupon is Activated", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            } else {
                if(res.data.dbError){
                    toast.error("Internal Server Error", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                } else {
                    this.setState({
                        ...this.state,
                        loading: false
                    })
                }
            }
        })
        .catch(err => {
            toast.warn("Unable to Reach Server! Please Try Again!" , {
                position: toast.POSITION.BOTTOM_CENTER
              });
        })
    }
}

ourCouponToggleHandler = (id) => {
    // console.log("Coupon Prid", id);

    let toggle={}
    if(this.state.toggle['coupon_'+id]){
        toggle={
            ...this.state.toggle,
            ['coupon_'+id]: false
        }
        this.setState({
            ...this.state,
            toggle: toggle
        })

        let body = {
            id: id,
            toggle: false
        }

                axios.post("/support/consumer/activeCoupon", body)
                    .then((res) => {
                        // console.log("Coupon response", res.data);
                        if(res.data.data){
                            
                            if(res.data.active){
                                // console.log("Active");
                                toast.success("Coupon is activated", {
                                    position: toast.POSITION.BOTTOM_CENTER
                                });
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                                this.componentDidMount();
                            } else if(res.data.inactive){
                                // console.log("Inactive");
                                toast.success("Coupon is deactivated", {
                                    position: toast.POSITION.BOTTOM_CENTER
                                });
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                                this.componentDidMount();
                            } else {
                                // console.log("No data found!");
                                toast.warn("Data Not Found", {
                                    position: toast.POSITION.BOTTOM_CENTER
                                });
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                            }
                        } else {
                            
                            if(res.data.dbError){
                                toast.error("Internal Server Error", {
                                    position: toast.POSITION.BOTTOM_CENTER
                                });
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                            } else {
                                toast.warn("Coupon not updated, try again!", {
                                    position: toast.POSITION.BOTTOM_CENTER
                                });
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                            }
                            this.setState({
                                ...this.state,
                                 loading: false
                            });
                        }
                    })
                    .catch((err) => {
                        console.log("Server error",err);
                        toast.warn("Unable to Reach Server! Please Try Again!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        this.setState({
                            ...this.state,
                            loading: false
                        });
                    });
    } else {
        toggle={
            ...this.state.toggle,
            ['coupon_'+id]: true
        }
        this.setState({
            ...this.state,
            toggle: toggle
        })

        let body = {
            id: id,
            toggle: true
        }

         axios.post("/support/consumer/activeCoupon", body)
                .then((res) => {
                    // console.log("Coupon response", res.data);
                    if(res.data.data){
                        
                        if(res.data.active){
                            // console.log("Active");
                            toast.success("Coupon is activated", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                            this.componentDidMount();
                        } else if(res.data.inactive){
                            // console.log("Inactive");
                            toast.success("Coupon is deactivated", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                            this.componentDidMount();
                        } else{
                            // console.log("No data found!");
                            toast.warn("Data Not Found!", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                        }
                    } else {
                        
                        if(res.data.dbError){
                            toast.error("Internal Server Error", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                        } else {
                            toast.warn("Coupon not updated, try again!", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                        }
                        this.setState({
                            ...this.state,
                             loading: false
                        });

                    }
                })
                .catch((err) => {
                    console.log("Server error",err);
                    toast.warn("Unable to Reach Server! Please Try Again!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                });
    }
}

supportCouponToggleHandler = (id) => {
    // console.log("Coupon Prid", id);

    let toggle={}
    if(this.state.toggle['coupon_'+id]){
        toggle={
            ...this.state.toggle,
            ['coupon_'+id]: false
        }
        this.setState({
            ...this.state,
            toggle: toggle
        })

        let body = {
            id: id,
            toggle: false
        }
    

         axios.post("/support/consumer/supportActiveCoupon", body)
                .then((res) => {
                    if(res.data.data){
                        
                        if(res.data.active){
                            toast.success("Coupon is activated", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            this.setState({
                                ...this.state,
                                act:true,
                                loading: false
                            });
                            this.componentDidMount();

                        } else if(res.data.inactive){
                            toast.success("Coupon is deactivated", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            this.setState({
                                ...this.state,
                                act:false,
                                loading: false
                            });
                            this.componentDidMount();
                        } else {
                            toast.warn("Data Not Found", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                        }
                    } else {
                        
                        if(res.data.dbError){
                            toast.error("Internal Server Error", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                        } else {
                            toast.warn("Coupon not updated, try again!", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                        }
                        this.setState({
                            ...this.state,
                             loading: false
                        });
                    }
                })
                .catch((err) => {
                    toast.warn("Unable to Reach Server! Please Try Again!" , {
                        position: toast.POSITION.BOTTOM_CENTER
                      });
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                });
    } else {
        toggle={
            ...this.state.toggle,
            ['coupon_'+id]: true
        }
        this.setState({
            ...this.state,
            toggle: toggle
        })

        let body = {
            id: id,
            toggle: true
        }

         axios.post("/support/consumer/supportActiveCoupon", body)
                .then((res) => {
                    if(res.data.data){
                        
                        if(res.data.active){
                            toast.success("Coupon is activated", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                            this.componentDidMount();
                        } else if(res.data.inactive){
                            toast.success("Coupon is deactivated", {
                                position: toast.POSITION.BOTTOM_CENTER,
                                
                            });
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                            this.componentDidMount();
                        } else{
                            toast.warn("Data Not Found!", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                        }
                    } else {
                        
                        if(res.data.dbError){
                            toast.error("Internal Server Error", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                        } else {
                            toast.warn("Coupon not updated, try again!", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                        }
                        this.setState({
                            ...this.state,
                             loading: false
                        });

                    }
                })
                .catch((err) => {
                    toast.warn("Unable to Reach Server! Please Try Again!" , {
                        position: toast.POSITION.BOTTOM_CENTER
                      });
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                });
    }
}

bookingsConsumedHandler=(id)=>{
    // console.log('navigating true')
    let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
    let OurCoupon_data=this.state.OurCoupon_data.filter((each)=>{
        return each.QF_MERCHANT_COUPON_PRID===id;
      })
    //   console.log('data',this.state.OurCoupon_data)
    this.props.history.push({
        pathname:url+'/BookingsOfCoupon',
        state:{
        accepted_data:OurCoupon_data,
        url,
            
    }});
  


}


    render() {
        // console.log("In Details View state", this.state);
        // console.log("Push Props", this.props.location.state.coupon_code);
        // console.log("Merchant", this.props.location.state.merchant);
        // console.log("OurCoupon", this.props.location.state.our);
        // console.log("Props state", this.props);
        const rowStyle={
            textAlign:"initial"
        }

        const fWeightSpan={
            fontWeight:"500",
            marginRight: 5,
            fontFamily: 'Muli',
            color: '#868686'
        }
        const fontText = {
            fontWeight:'bold',
            fontFamily: 'Muli',
            color: '#563951'         
        }
        const fontText1 = {
            fontWeight:'bold',
            fontFamily: 'Muli',
            color: '#563951'            
        }
        const CtextDecor = {
            padding: 1,
            fontWeight: 'bold',
            cursor: 'pointer',
            textTransform: 'uppercase',
            fontWeight:'bold',
            fontFamily: 'Muli',
            color: '#563951' 
            // border: "1px solid black"        
        }
        const CtextDecor1 = {
            padding: 1,
            fontWeight: 'bold',
            // cursor: 'pointer',
            textTransform: 'uppercase',
            fontWeight:'bold',
            fontFamily: 'Muli',
            color: '#563951' 
            // border: "1px solid black"        
        }
        const LinkedText = {
            textDecoration:'underline',
            cursor:'pointer', 
            fontWeight:'bold',
            fontWeight:'bold',
            fontFamily: 'Muli',
            color: '#563951',
            textTransform: 'uppercase'
        }


        let ourCoupon = null;
        if(this.props.location.state.our){
        if(this.state.ourCoupon.length !== 0){

                ourCoupon = this.state.OurCoupon_data.map((coupon) => {
                    if(coupon.OUR_COUPON_TYPE==="referral"){
                        if(coupon.OUR_COUPON_CODE!=null){   

                            // console.log("In Map", coupon);
                            return (
                             <Card style={{border:"1px solid #a78bba",marginBottom: 20}}>
                                <Row className="mb-4" style={{paddingLeft:"15px",paddingRight:"15px"}}>
                            <Col sm={{size:12}} style={{fontWeight:"",fontSize:"22px",color:"#fff",backgroundColor:"#2C2165", fontFamily:'Muli'}}><CardHeader> Coupon Details  {coupon.OUR_COUPON_DELETED_FLAG===0&&(!moment(coupon.OUR_COUPON_BOOKING_DATE_TO).isBefore(new Date()))&&(!moment(coupon.OUR_COUPON_SERVICE_DATE_TO).isBefore(new Date()))?<span style={{float: 'right'}}><Switch height={20}  onChange={() => this.ourCouponToggleHandler(coupon.QF_OUR_COUPON_PRID)} checked={this.state.toggle['coupon_'+coupon.QF_OUR_COUPON_PRID]} onColor='#3DC5B6' offColor='#F86C69' disabled={true}/></span>:null} </CardHeader> </Col>
                                </Row>
                                {/* <CardHeader style={{fontWeight:"",fontSize:"22px",color:"#fff",backgroundColor:"#2C2165"}}>Coupon Details</CardHeader> */}
                                <CardText style={{padding:"25px"}}>
        
                                    <Row className="mb-4" style={rowStyle}>
        
                                        <Col className="col-4">
                                            <text style={fontText1}><span style={fWeightSpan}>Coupon Code:</span> </text> <text style={CtextDecor1}>{coupon.OUR_COUPON_CODE} </text>
                                        </Col>
                                        
        
                                        <Col className="col-4">
                                            <text style={fontText1}><span style={fWeightSpan}>Created By:</span> Administration </text>
                                        </Col>                            
                                            
                                        <Col className="col-4">
                                            <text style={fontText1}><span style={fWeightSpan}>Coupon Description:</span> { coupon.OUR_COUPON_DESC != null ? coupon.OUR_COUPON_DESC : <text style={fontText}>---</text> }</text>
                                        </Col> 
                                                               
                                        
                                    </Row>
        
                                    <Row className="mb-4" style={rowStyle}>
        
                                        <Col className="col-4">
                                            <text style={fontText1}><span style={fWeightSpan}>Coupon Type:</span>Referral Coupon</text>
                                        </Col>
        
                                        <Col className="col-4">                                
                                            <text style={fontText1}><span style={fWeightSpan}>Professional Name:</span>{coupon.MERCHANT_NAME!==null ? coupon.MERCHANT_NAME : <text style={fontText}>---</text>}</text>
                                        </Col>
        
                                        <Col className="col-4">
                                            <text style={fontText1}><span style={fWeightSpan}>Coupon Status:</span>
                                            <text > {coupon.OUR_COUPON_DELETED_FLAG===1 ?
                                                        <span style={{backgroundColor:"#F86C69",padding:10,width:100,color:"#fff",borderRadius:"3px"}}>Deleted</span> 
                                                    :
                                                    moment(moment(coupon.OUR_COUPON_BOOKING_DATE_TO).set('hour', 23).set('minute', 59).set('second', 59)).isBefore(new Date()) ? 
                                                        <span style={{backgroundColor:"#F86C69",padding:10,width:100,color:"#fff",borderRadius:"3px"}}>Expired</span>
                                                    : 
                                                    moment(moment(coupon.OUR_COUPON_SERVICE_DATE_TO).set('hour', 23).set('minute', 59).set('second', 59)).isBefore(new Date()) ?
                                                        <span style={{backgroundColor:"#F86C69",padding:10,width:100,color:"#fff",borderRadius:"3px"}}>Expired</span>
                                                    :
                                                    coupon.OUR_COUPON_ACTIVE===1?
                                                        <span style={{backgroundColor:"#3DC5B6",padding:10,width:100,color:"#fff",borderRadius:"3px"}}>Active</span>:
                                                        <span style={{backgroundColor:"#F86C69",padding:10,width:100,color:"#fff",borderRadius:"3px"}}>Inactive</span> 
        
                                                    }
                                            </text></text>
                                        </Col>
        
                                    
        
                                    </Row>
                                    <Row className="mb-4" style={rowStyle}>
        
                                    <Col className="col-4">
                                            <text style={fontText1}><span style={fWeightSpan}>Category:</span>{this.state.category != null ? this.state.category : <text style={fontText}>---</text>} </text>
                                        </Col>
        
        
                                        <Col className="col-4">                                
                                            <text style={fontText1}><span style={fWeightSpan}>Subcategory:</span>{this.state.sub_category != null ? this.state.sub_category : <text style={fontText}>---</text>}</text>
                                        </Col>
        
                                        <Col className="col-4">
                                            <text style={fontText1}><span style={fWeightSpan}>City:</span>{this.state.city != null ? this.state.city : <text style={fontText}>---</text>}</text>
                                        </Col>
        
                                    </Row>
                                    <Row className="mb-4" style={rowStyle}>
        
                                    
                                    <Col className="col-4">
                                            <text style={fontText1}><span style={fWeightSpan}>Country:</span>{coupon.OUR_COUPON_COUNTRY_CODE  ? coupon.OUR_COUPON_COUNTRY_CODE : <text style={fontText}>---</text>}</text>
                                        </Col>
                                        </Row>
        
        
                                    <hr />
        
                                    <Row className="mb-4" style={rowStyle}>
        
                                        
                                        <Col className="col-4"> 
                                            <text style={fontText1}><span style={fWeightSpan}>Allow Coupon From Booking Date:</span>
                                                {coupon.OUR_COUPON_BOOKING_DATE_FROM ?
                                                    moment(coupon.OUR_COUPON_BOOKING_DATE_FROM).format('DD-MMM-YYYY z') 
                                                : 
                                                    <text style={fontText}>---</text>
                                                }
                                            </text>
                                        </Col>
                                        <Col className="col-4">
                                            <text style={fontText1}><span style={fWeightSpan}>Allow Coupon Till Booking Date:</span>
                                                {coupon.OUR_COUPON_BOOKING_DATE_TO ? 
                                                    moment(coupon.OUR_COUPON_BOOKING_DATE_TO).format('DD-MMM-YYYY z')
                                                : 
                                                    <text style={fontText}>---</text>
                                                }
                                            </text>
                                        </Col>
                                        <Col className="col-4">
                                        <text style={fontText1}><span style={fWeightSpan}>Scratch Card Status:</span>{<text style={fontText}>Scratched</text>}</text>

                                        </Col>
                                        {/* <Col className="col-4">
                                            <text style={fontText1}><span style={fWeightSpan}>Allow Coupon From Service Date:</span>
                                                {coupon.OUR_COUPON_SERVICE_DATE_FROM ?
                                                    moment(coupon.OUR_COUPON_SERVICE_DATE_FROM).format('DD-MMM-YYYY z')
                                                : 
                                                    <text style={fontText}>---</text>
                                                }
                                            </text>
                                        </Col> */}
        
                                    </Row>
                                    <Row className="mb-4" style={rowStyle}>
        
                                        
                                        {/* <Col className="col-4">
                                            <text style={fontText1}><span style={fWeightSpan}>Allow Coupon Till Service Date:</span>
                                                {coupon.OUR_COUPON_SERVICE_DATE_TO ?
                                                    moment(coupon.OUR_COUPON_SERVICE_DATE_TO).format('DD-MMM-YYYY z')
                                                :
                                                    <text style={fontText}>---</text>}
                                            </text>
                                        </Col> */}
        
                                    </Row>
                                    <hr />
                                    <Row className="mb-4" style={rowStyle}>
        
                                        
        
                                        <Col className="col-4">
                                            <text style={fontText1}><span style={fWeightSpan}>Coupon Minimum Amount:</span> 
                                            {coupon.OUR_COUPON_MINIMUM_AMOUNT!=null&&coupon.OUR_COUPON_MINIMUM_AMOUNT!=0?coupon.OUR_COUPON_COUNTRY_CODE === 'India' ? <span> {'\u20B9'}</span> : <span>$</span>:null}
                                                    {coupon.OUR_COUPON_MINIMUM_AMOUNT ? 
                                                        Curr(coupon.OUR_COUPON_MINIMUM_AMOUNT).value.toFixed(2)
                                                    :
                                                        <text style={fontText}>---</text>
                                                    }
                                            </text>
                                        </Col>
        
        
                                        <Col className="col-4">
                                            <text style={fontText1}><span style={fWeightSpan}>Coupon Maximum Amount:</span> 
                                            {coupon.OUR_COUPON_MAXIMUM_AMOUNT!==null?coupon.OUR_COUPON_COUNTRY_CODE === 'India' ? <span> {'\u20B9'}</span> : <span>$</span>:null}
                                                    {coupon.OUR_COUPON_MAXIMUM_AMOUNT ? 
                                                        Curr(coupon.OUR_COUPON_MAXIMUM_AMOUNT).value.toFixed(2)
                                                    :
                                                        <text style={fontText}>---</text>
                                                    }
                                            </text>
                                        </Col>
        
        
                                        <Col className="col-4">
                                            { 
                                            coupon.OUR_COUPON_VALUE_OFF ?  
                                                <text style={fontText1}><span style={fWeightSpan}>Coupon Discount Amount:</span></text>
                                            : 
                                                coupon.OUR_COUPON_PERCENT_OFF ? 
                                                    <text style={fontText1}><span style={fWeightSpan}>Coupon Discount Percent:</span></text>
                                            :
                                                null
                                            } 
                                        
                                            <text style={fontText1}>
                                            { 
                                                !coupon.OUR_COUPON_PERCENT_OFF ?coupon.OUR_COUPON_COUNTRY_CODE === 'India' ? <span> {'\u20B9'}</span> : <span>$</span>:null
                                            }
        
                                            {
                                                coupon.OUR_COUPON_VALUE_OFF ? 
                                                    Curr(coupon.OUR_COUPON_VALUE_OFF).value.toFixed(2)
                                            :   
                                                coupon.OUR_COUPON_PERCENT_OFF ? 
                                                    Curr(coupon.OUR_COUPON_PERCENT_OFF).value.toFixed(2)
                                                    
                                            :
                                                <text style={fontText}>0</text>
                                            }                              
                                            { 
                                                coupon.OUR_COUPON_PERCENT_OFF ? <span>%</span> 
                                            : 
                                                null
                                            }
                                            </text>
                                        </Col> 
                                        
                                    </Row>
                                    <Row className="mb-4" style={rowStyle}>
        
        <Col className="col-4">
            <text style={fontText1}><span style={fWeightSpan}>Coupon Count:</span>
        
                    {/* {coupon.OUR_COUPON_USED_COUNT ? 
                        coupon.OUR_COUPON_USED_COUNT
                    :
                        <text style={fontText}>0</text>
                    } */}
                    {coupon.OUR_COUPON_USED_COUNT===null||0?0:coupon.OUR_COUPON_USED_COUNT>0?<text style={LinkedText} onClick={()=>this.bookingsConsumedHandler()}>{coupon.OUR_COUPON_USED_COUNT}</text>:coupon.OUR_COUPON_USED_COUNT}
                    {'/'}
                    {coupon.OUR_COUPON_MAX_COUNT ? 
                        coupon.OUR_COUPON_MAX_COUNT
                    :
                        <text style={fontText}>0</text>
                    }
            </text>
        </Col>
        </Row>
        
                                </CardText>                    
                            </Card>
                        )
                }

                    }
                    else{
                        if(coupon.OUR_COUPON_CODE!=null){   

                            // console.log("In Map", coupon);
                            return (
                             <Card style={{border:"1px solid #a78bba",marginBottom: 20}}>
                                <Row className="mb-4" style={{paddingLeft:"15px",paddingRight:"15px"}}>
                            <Col sm={{size:12}} style={{fontWeight:"",fontSize:"22px",color:"#fff",backgroundColor:"#2C2165", fontFamily:'Muli'}}><CardHeader> Coupon Details  {coupon.OUR_COUPON_DELETED_FLAG===0&&(!moment(coupon.OUR_COUPON_BOOKING_DATE_TO).isBefore(new Date()))&&(!moment(coupon.OUR_COUPON_SERVICE_DATE_TO).isBefore(new Date()))?<span style={{float: 'right'}}><Switch height={20}  onChange={() => this.ourCouponToggleHandler(coupon.QF_OUR_COUPON_PRID)} checked={this.state.toggle['coupon_'+coupon.QF_OUR_COUPON_PRID]} onColor='#3DC5B6' offColor='#F86C69' disabled={true}/></span>:null} </CardHeader> </Col>
                                </Row>
                                {/* <CardHeader style={{fontWeight:"",fontSize:"22px",color:"#fff",backgroundColor:"#2C2165"}}>Coupon Details</CardHeader> */}
                                <CardText style={{padding:"25px"}}>
        
                                    <Row className="mb-4" style={rowStyle}>
        
                                        <Col className="col-4">
                                            <text style={fontText1}><span style={fWeightSpan}>Coupon Code:</span> </text> <text style={CtextDecor1}>{coupon.OUR_COUPON_CODE} </text>
                                        </Col>
                                        
        
                                        <Col className="col-4">
                                            <text style={fontText1}><span style={fWeightSpan}>Created By:</span> Administration </text>
                                        </Col>                            
                                            
                                        <Col className="col-4">
                                            <text style={fontText1}><span style={fWeightSpan}>Coupon Description:</span> { coupon.OUR_COUPON_DESC != null ? coupon.OUR_COUPON_DESC : <text style={fontText}>---</text> }</text>
                                        </Col> 
                                                               
                                        
                                    </Row>
        
                                    <Row className="mb-4" style={rowStyle}>
        
                                        <Col className="col-4">
                                            <text style={fontText1}><span style={fWeightSpan}>Coupon Type:</span>Admin Coupon</text>
                                        </Col>
        
                                        <Col className="col-4">                                
                                            <text style={fontText1}><span style={fWeightSpan}>Professional Name:</span>{coupon.MERCHANT_NAME!==null ? coupon.MERCHANT_NAME : <text style={fontText}>---</text>}</text>
                                        </Col>
        
                                        <Col className="col-4">
                                            <text style={fontText1}><span style={fWeightSpan}>Coupon Status:</span>
                                            <text > {coupon.OUR_COUPON_DELETED_FLAG===1 ?
                                                        <span style={{backgroundColor:"#F86C69",padding:10,width:100,color:"#fff",borderRadius:"3px"}}>Deleted</span> 
                                                    :
                                                    moment(moment(coupon.OUR_COUPON_BOOKING_DATE_TO).set('hour', 23).set('minute', 59).set('second', 59)).isBefore(new Date()) ? 
                                                        <span style={{backgroundColor:"#F86C69",padding:10,width:100,color:"#fff",borderRadius:"3px"}}>Expired</span>
                                                    : 
                                                    moment(moment(coupon.OUR_COUPON_SERVICE_DATE_TO).set('hour', 23).set('minute', 59).set('second', 59)).isBefore(new Date()) ?
                                                        <span style={{backgroundColor:"#F86C69",padding:10,width:100,color:"#fff",borderRadius:"3px"}}>Expired</span>
                                                    :
                                                    coupon.OUR_COUPON_ACTIVE===1?
                                                        <span style={{backgroundColor:"#3DC5B6",padding:10,width:100,color:"#fff",borderRadius:"3px"}}>Active</span>:
                                                        <span style={{backgroundColor:"#F86C69",padding:10,width:100,color:"#fff",borderRadius:"3px"}}>Inactive</span> 
        
                                                    }
                                            </text></text>
                                        </Col>
        
                                    
        
                                    </Row>
                                    <Row className="mb-4" style={rowStyle}>
        
                                    <Col className="col-4">
                                            <text style={fontText1}><span style={fWeightSpan}>Category:</span>{this.state.category != null ? this.state.category : <text style={fontText}>---</text>} </text>
                                        </Col>
        
        
                                        <Col className="col-4">                                
                                            <text style={fontText1}><span style={fWeightSpan}>Subcategory:</span>{this.state.sub_category != null ? this.state.sub_category : <text style={fontText}>---</text>}</text>
                                        </Col>
        
                                        <Col className="col-4">
                                            <text style={fontText1}><span style={fWeightSpan}>City:</span>{this.state.city != null ? this.state.city : <text style={fontText}>---</text>}</text>
                                        </Col>
        
                                    </Row>
                                    <Row className="mb-4" style={rowStyle}>
        
                                    
                                    <Col className="col-4">
                                            <text style={fontText1}><span style={fWeightSpan}>Country:</span>{coupon.OUR_COUPON_COUNTRY_CODE  ? coupon.OUR_COUPON_COUNTRY_CODE : <text style={fontText}>---</text>}</text>
                                        </Col>
                                        <Col className="col-4">
                                            <text style={fontText1}><span style={fWeightSpan}>Allow only once per customer:</span>{coupon.SINGLE_COUPON_PER_DEVICE === 1 ?
                                            <span style={{backgroundColor:"#3DC5B6",padding:10,width:100,color:"#fff",borderRadius:"3px"}}>Yes</span>:
                                            <span style={{backgroundColor:"#F86C69",padding:10,width:100,color:"#fff",borderRadius:"3px"}}>No</span>}</text>
                                        </Col>
                                        </Row>
        
        
                                    <hr />
        
                                    <Row className="mb-4" style={rowStyle}>
        
                                        
                                        <Col className="col-4"> 
                                            <text style={fontText1}><span style={fWeightSpan}>Allow Coupon From Booking Date:</span>
                                                {coupon.OUR_COUPON_BOOKING_DATE_FROM ?
                                                    moment(coupon.OUR_COUPON_BOOKING_DATE_FROM).format('DD-MMM-YYYY z') 
                                                : 
                                                    <text style={fontText}>---</text>
                                                }
                                            </text>
                                        </Col>
                                        <Col className="col-4">
                                            <text style={fontText1}><span style={fWeightSpan}>Allow Coupon Till Booking Date:</span>
                                                {coupon.OUR_COUPON_BOOKING_DATE_TO ? 
                                                    moment(coupon.OUR_COUPON_BOOKING_DATE_TO).format('DD-MMM-YYYY z')
                                                : 
                                                    <text style={fontText}>---</text>
                                                }
                                            </text>
                                        </Col>
                                        <Col className="col-4">
                                            <text style={fontText1}><span style={fWeightSpan}>Allow Coupon From Service Date:</span>
                                                {coupon.OUR_COUPON_SERVICE_DATE_FROM ?
                                                    moment(coupon.OUR_COUPON_SERVICE_DATE_FROM).format('DD-MMM-YYYY z')
                                                : 
                                                    <text style={fontText}>---</text>
                                                }
                                            </text>
                                        </Col>
        
                                    </Row>
                                    <Row className="mb-4" style={rowStyle}>
        
                                        
                                        <Col className="col-4">
                                            <text style={fontText1}><span style={fWeightSpan}>Allow Coupon Till Service Date:</span>
                                                {coupon.OUR_COUPON_SERVICE_DATE_TO ?
                                                    moment(coupon.OUR_COUPON_SERVICE_DATE_TO).format('DD-MMM-YYYY z')
                                                :
                                                    <text style={fontText}>---</text>}
                                            </text>
                                        </Col>
        
                                    </Row>
                                    <hr />
                                    <Row className="mb-4" style={rowStyle}>
        
                                        
        
                                        <Col className="col-4">
                                            <text style={fontText1}><span style={fWeightSpan}>Coupon Minimum Amount:</span> 
                                            {coupon.OUR_COUPON_MINIMUM_AMOUNT!=null&&coupon.OUR_COUPON_MINIMUM_AMOUNT!=0?coupon.OUR_COUPON_COUNTRY_CODE === 'India' ? <span> {'\u20B9'}</span> : <span>$</span>:null}
                                                    {coupon.OUR_COUPON_MINIMUM_AMOUNT ? 
                                                        Curr(coupon.OUR_COUPON_MINIMUM_AMOUNT).value.toFixed(2)
                                                    :
                                                        <text style={fontText}>---</text>
                                                    }
                                            </text>
                                        </Col>
        
        
                                        <Col className="col-4">
                                            <text style={fontText1}><span style={fWeightSpan}>Coupon Maximum Amount:</span> 
                                            {coupon.OUR_COUPON_MAXIMUM_AMOUNT!==null?coupon.OUR_COUPON_COUNTRY_CODE === 'India' ? <span> {'\u20B9'}</span> : <span>$</span>:null}
                                                    {coupon.OUR_COUPON_MAXIMUM_AMOUNT ? 
                                                        Curr(coupon.OUR_COUPON_MAXIMUM_AMOUNT).value.toFixed(2)
                                                    :
                                                        <text style={fontText}>---</text>
                                                    }
                                            </text>
                                        </Col>
        
        
                                        <Col className="col-4">
                                            { 
                                            coupon.OUR_COUPON_VALUE_OFF ?  
                                                <text style={fontText1}><span style={fWeightSpan}>Coupon Discount Amount:</span></text>
                                            : 
                                                coupon.OUR_COUPON_PERCENT_OFF ? 
                                                    <text style={fontText1}><span style={fWeightSpan}>Coupon Discount Percent:</span></text>
                                            :
                                                null
                                            } 
                                        
                                            <text style={fontText1}>
                                            { 
                                                !coupon.OUR_COUPON_PERCENT_OFF ?coupon.OUR_COUPON_COUNTRY_CODE === 'India' ? <span> {'\u20B9'}</span> : <span>$</span>:null
                                            }
        
                                            {
                                                coupon.OUR_COUPON_VALUE_OFF ? 
                                                    Curr(coupon.OUR_COUPON_VALUE_OFF).value.toFixed(2)
                                            :   
                                                coupon.OUR_COUPON_PERCENT_OFF ? 
                                                    Curr(coupon.OUR_COUPON_PERCENT_OFF).value.toFixed(2)
                                                    
                                            :
                                                <text style={fontText}>0</text>
                                            }                              
                                            { 
                                                coupon.OUR_COUPON_PERCENT_OFF ? <span>%</span> 
                                            : 
                                                null
                                            }
                                            </text>
                                        </Col> 
                                        
                                    </Row>
                                    <Row className="mb-4" style={rowStyle}>
        
        <Col className="col-4">
            <text style={fontText1}><span style={fWeightSpan}>Coupon Count:</span>
        
                    {/* {coupon.OUR_COUPON_USED_COUNT ? 
                        coupon.OUR_COUPON_USED_COUNT
                    :
                        <text style={fontText}>0</text>
                    } */}
                    {coupon.OUR_COUPON_USED_COUNT===null||0?0:coupon.OUR_COUPON_USED_COUNT>0?<text style={LinkedText} onClick={()=>this.bookingsConsumedHandler()}>{coupon.OUR_COUPON_USED_COUNT}</text>:coupon.OUR_COUPON_USED_COUNT}
                    {'/'}
                    {coupon.OUR_COUPON_MAX_COUNT ? 
                        coupon.OUR_COUPON_MAX_COUNT
                    :
                        <text style={fontText}>0</text>
                    }
            </text>
        </Col>
        </Row>
        
                                </CardText>                    
                            </Card>
                        )
                }

                    }
                    
            });
        } else {
            ourCoupon = null;
        }
    }

        let merchantCoupon = null;
        if(this.props.location.state.merchant){
        if(this.state.merchantCoupon.length !== 0){
            // console.log("Merchant", this.state.OurCoupon_data.map(coupon => coupon.MERCHANT_COUPON_CODE));
            merchantCoupon = this.state.OurCoupon_data.map((coupon) =>{
                return (
                <Card style={{border:"1px solid #a78bba", marginBottom: 20}}>               
                    
                    

                        <Row className="mb-4" style={{paddingLeft:"15px",paddingRight:"15px"}}>
                    <Col sm={{size:12}} style={{fontWeight:"",fontSize:"22px",color:"#fff",backgroundColor:"#2C2165", fontFamily:"Muli"}}><CardHeader> Coupon Details {coupon.MERCHANT_COUPON_DELETED_FLAG===0&&(!moment(coupon.MERCHANT_COUPON_BOOKING_DATE_TO).isBefore(new Date()))&&(!moment(coupon.MERCHANT_COUPON_SERVICE_DATE_TO).isBefore(new Date()))?<span style={{float: 'right'}}><Switch height={20} onChange={() => this.merchantCouponToggleHandler(coupon.QF_MERCHANT_COUPON_PRID)} checked={this.state.toggle['coupon_'+coupon.QF_MERCHANT_COUPON_PRID]} onColor='#3DC5B6' offColor='#F86C69'/> </span>:null} </CardHeader> </Col>
                        </Row>
                                           

                        <CardText style={{padding:"25px"}}>
                        {/* <hr /> */}
                            <Row className="mb-4" style={rowStyle}>

                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Coupon Code: </span> </text> <text style={CtextDecor1}> {coupon.MERCHANT_COUPON_CODE} </text>
                                </Col>
      
                                    
                                {
                                this.props.location.state.merchant ? 
                                    // this.state.MName ?
                                        <Col className="col-4">
                                            <text style={fontText1}><span style={fWeightSpan}>Created By:</span><text style={LinkedText} onClick={() => this.MerchantDetailsHandler(coupon.QF_MERCHANT_PRID)}>{coupon.MERCHANT_NAME}</text></text>
                                        </Col>
                                    // :
                                    //     <Col className="col-4">
                                    //         <text style={fontText1}><span style={fWeightSpan}>Created By:</span><text >Merchant</text></text>
                                    //     </Col>
                                :
                                    <Col className="col-4">
                                        <text style={fontText1}><span style={fWeightSpan}>Created By:</span><text >Professional</text></text>
                                    </Col>
                                }                     
                                

                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Coupon Description:</span>{coupon.MERCHANT_COUPON_DESC != null ? coupon.MERCHANT_COUPON_DESC : <text style={fontText}>---</text>} </text>
                                </Col>                             
                            </Row>
                            <Row className="mb-4" style={rowStyle}>
                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Coupon Type:</span><text > Professional Coupon</text></text>
                                </Col>

                                
                                <Col className="col-4">
                                <text style={fontText1}><span style={fWeightSpan}>Subcategory:</span>{coupon.service_sub_category_name != null ? coupon.service_sub_category_name : <text style={fontText}>---</text>} </text>
                                   
                                </Col>                                 

 
                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Coupon Status:</span>
                                    <text > {coupon.MERCHANT_COUPON_DELETED_FLAG===1 ?
                                                <span style={{backgroundColor:"#F86C69",padding:10,width:100,color:"#fff",borderRadius:"3px"}}>Deleted</span> 
                                            :  
                                            coupon.MERCHANT_COUPON_SERVICE_DATE_TO ?                                           
                                                    // moment(coupon.MERCHANT_COUPON_SERVICE_DATE_TO).set('hour', 23).set('minute', 59).set('second', 59).isBefore(moment().tz(coupon.MERCHANT_TIMEZONE).format("YYYY-MM-DD")) ? 
                                                    moment(coupon.MERCHANT_COUPON_SERVICE_DATE_TO,"YYYY-MM-DD HH:mm:ss").isBefore(moment().utc().format("YYYY-MM-DD HH:mm:ss"))?
                                                    <span style={{backgroundColor:"#F86C69",padding:10,width:100,color:"#fff",borderRadius:"3px"}}>Expired</span>
                                                    :
                                                    coupon.MERCHANT_COUPON_ACTIVE===1?
                                                        <span style={{backgroundColor:"#3DC5B6",padding:10,width:100,color:"#fff",borderRadius:"3px"}}>Active</span> 
                                                        :
                                                        <span style={{backgroundColor:"#F86C69",padding:10,width:100,color:"#fff",borderRadius:"3px"}}>Inactive</span>
                                            :
                                                    // moment(coupon.MERCHANT_COUPON_BOOKING_DATE_TO).set('hour', 23).set('minute', 59).set('second', 59).isBefore(moment().tz(coupon.MERCHANT_TIMEZONE).format("YYYY-MM-DD")) ? 
                                                    moment(coupon.MERCHANT_COUPON_BOOKING_DATE_TO,"YYYY-MM-DD HH:mm:ss").isBefore(moment().utc().format("YYYY-MM-DD HH:mm:ss"))?
                                                    <span style={{backgroundColor:"#F86C69",padding:10,width:100,color:"#fff",borderRadius:"3px"}}>Expired</span>
                                                    :
                                                    coupon.MERCHANT_COUPON_ACTIVE===1?
                                                        <span style={{backgroundColor:"#3DC5B6",padding:10,width:100,color:"#fff",borderRadius:"3px"}}>Active</span> 
                                                        :
                                                        <span style={{backgroundColor:"#F86C69",padding:10,width:100,color:"#fff",borderRadius:"3px"}}>Inactive</span>
                                            }
                                    </text></text>
                                </Col>
                            </Row>
                            
                        <Row className="mb-4" style={rowStyle}>                               


                            <Col className="col-4">
                                <text style={fontText1}><span style={fWeightSpan}>Category:</span>{coupon.category_name != null ? coupon.category_name : <text style={fontText}>---</text>} </text>
                                   
                                </Col>
                                <Col className="col-4">
                                        <text style={fontText1}><span style={fWeightSpan}>Allow only once per customer:</span>{coupon.SINGLE_COUPON_PER_USER === 1 ?
                                        <span style={{backgroundColor:"#3DC5B6",padding:10,width:100,color:"#fff",borderRadius:"3px"}}>Yes</span>:
                                        <span style={{backgroundColor:"#F86C69",padding:10,width:100,color:"#fff",borderRadius:"3px"}}>No</span>}</text>
                                 </Col>
                                </Row>
                            <hr />
                            <Row className="mb-4" style={rowStyle}>                               
                                
                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Allow Coupon From Booking Date:</span>
                                        {coupon.MERCHANT_COUPON_BOOKING_DATE_FROM ?
                                            moment(coupon.MERCHANT_COUPON_BOOKING_DATE_FROM+"Z").tz(coupon.MERCHANT_TIMEZONE).format('DD-MMM-YYYY z') 
                                            :
                                            coupon.MERCHANT_COUPON_BOOKING_DATE_TO ?
                                            <text style={fontText}>Current</text>
                                            :
                                            <text style={fontText}>---</text>
                                        }
                                    </text>
                                </Col>
                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Allow Coupon Till Booking Date:</span>
                                        {coupon.MERCHANT_COUPON_BOOKING_DATE_TO ? 
                                            moment(coupon.MERCHANT_COUPON_BOOKING_DATE_TO+"Z").tz(coupon.MERCHANT_TIMEZONE).format('DD-MMM-YYYY z')
                                        : 
                                        coupon.MERCHANT_COUPON_BOOKING_DATE_FROM ?
                                            <text style={fontText}>Future</text>
                                            :
                                            <text style={fontText}>---</text>
                                        }
                                    </text>
                                </Col>
                                
                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Allow Coupon From Service Date:</span>
                                        {coupon.MERCHANT_COUPON_SERVICE_DATE_FROM ?
                                            moment(coupon.MERCHANT_COUPON_SERVICE_DATE_FROM+"Z").tz(coupon.MERCHANT_TIMEZONE).format('DD-MMM-YYYY z')
                                        : 
                                        coupon.MERCHANT_COUPON_SERVICE_DATE_TO ?
                                            <text style={fontText}>Current</text>
                                            :
                                            <text style={fontText}>---</text>
                                        }
                                    </text>
                                </Col>

                            </Row>
                            <Row className="mb-4" style={rowStyle}>                        
                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Allow Coupon Till Service Date:</span>
                                        {coupon.MERCHANT_COUPON_SERVICE_DATE_TO ?
                                            moment(coupon.MERCHANT_COUPON_SERVICE_DATE_TO+"Z").tz(coupon.MERCHANT_TIMEZONE).format('DD-MMM-YYYY z')
                                        :
                                        coupon.MERCHANT_COUPON_SERVICE_DATE_FROM ?
                                            <text style={fontText}>Future</text>
                                            :
                                            <text style={fontText}>---</text>
                                        }
                                    </text>
                                </Col>

                            </Row>
                            <hr />
                            <Row className="mb-4" style={rowStyle}>

                                
                                
                            
                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Coupon Minimum Amount:</span>

                                            { 
                                            coupon.MERCHANT_COUPON_MINIMUM_AMOUNT !== null ? 
                                            coupon.MERCHANT_ADDRESS_COUNTRY === "India" ? <span>{'\u20B9'}</span> : coupon.MERCHANT_ADDRESS_COUNTRY === "United States" ? <span>$</span> : null
                                            :null
                                            }                                            
                                            {coupon.MERCHANT_COUPON_MINIMUM_AMOUNT !== null ? 
                                                Curr(coupon.MERCHANT_COUPON_MINIMUM_AMOUNT).value.toFixed(2)
                                            :
                                                <text style={fontText}>---</text>
                                            }
                                    </text>
                                </Col>

                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Coupon Maximum Amount:</span>

                                            { 
                                            coupon.MERCHANT_COUPON_MAXIMUM_AMOUNT !== null ? 
                                            coupon.MERCHANT_ADDRESS_COUNTRY === "India" ? <span>{'\u20B9'}</span> : coupon.MERCHANT_ADDRESS_COUNTRY === "United States" ? <span>$</span> : null
                                            :null
                                            }                                            
                                            {coupon.MERCHANT_COUPON_MAXIMUM_AMOUNT !== null ? 
                                                Curr(coupon.MERCHANT_COUPON_MAXIMUM_AMOUNT).value.toFixed(2)
                                            :
                                                <text style={fontText}>---</text>
                                            }
                                    </text>
                                </Col>
                                <Col className="col-4">
                                    { 
                                        coupon.MERCHANT_COUPON_VALUE_OFF !== null?  <text style={fontText1}><span style={fWeightSpan}>Coupon Discount Amount:</span></text> 
                                    :
                                        coupon.MERCHANT_COUPON_PERCENT_OFF !== null ? <text style={fontText1}><span style={fWeightSpan}>Coupon Discount Percent:</span></text>
                                    :
                                        null
                                    } 
                                
                                    <text style={fontText1}> 
                                    { 
                                    coupon.MERCHANT_COUPON_VALUE_OFF !== null ? 
                                    coupon.MERCHANT_ADDRESS_COUNTRY === 'India' ? <span>{'\u20B9'}</span> :coupon.MERCHANT_ADDRESS_COUNTRY  === 'United States' ? <span>$</span> : null
                                    :
                                        null 
                                    }

                                    {
                                        coupon.MERCHANT_COUPON_VALUE_OFF !== null ? 
                                            Curr(coupon.MERCHANT_COUPON_VALUE_OFF).value.toFixed(2)
                                    : 
                                        coupon.MERCHANT_COUPON_PERCENT_OFF !== null ? 
                                            Curr(coupon.MERCHANT_COUPON_PERCENT_OFF).value.toFixed(2)
                                    :
                                        <text style={fontText}>0</text>
                                    }                                
                                    { 
                                        coupon.MERCHANT_COUPON_PERCENT_OFF !== null ? <span>%</span> 
                                    : 
                                        null 
                                    }
                                    </text>
                                </Col>
                            </Row>
                            
                            
                                <Row className="mb-4" style={rowStyle}>


                            <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Coupon Count:</span>

                                            {/* {coupon.MERCHANT_COUPON_USED_COUNT ? 
                                                coupon.MERCHANT_COUPON_USED_COUNT
                                            :
                                                <text style={fontText}>0</text>
                                            } */}
                                            {coupon.MERCHANT_COUPON_USED_COUNT===null||0?0:coupon.MERCHANT_COUPON_USED_COUNT>0?<text style={LinkedText} onClick={()=>this.bookingsConsumedHandler(coupon.QF_MERCHANT_COUPON_PRID)}>{coupon.MERCHANT_COUPON_USED_COUNT}</text>:coupon.MERCHANT_COUPON_USED_COUNT}

                                            &nbsp;{ '/'}&nbsp;
                                            {coupon.MERCHANT_COUPON_MAX_COUNT ? 
                                                coupon.MERCHANT_COUPON_MAX_COUNT
                                            :
                                                <text style={fontText}>0</text>
                                            }
                                    </text>
                                </Col>
                                </Row>


                        </CardText>                    
                    </Card> 
                )
            });
        } else{
            merchantCoupon = null;
        }
    }

    let supportCoupon = null;
    if(this.state.supportCoupon){
        if(this.state.ourCoupon.length !== 0){

            supportCoupon = this.state.OurCoupon_data.map((coupon) => {

                if(coupon.SUPPORT_COUPON_CODE!=null){
                    // console.log("In Map", coupon);
                    return (
                     <Card style={{border:"1px solid #a78bba", marginBottom: 20}}>
                        <Row className="mb-4" style={{paddingLeft:"15px",paddingRight:"15px"}}>
                    <Col sm={{size:12}} style={{fontWeight:"",fontSize:"22px",color:"#fff",backgroundColor:"#2C2165", fontFamily:'Muli'}}><CardHeader> Coupon Details{coupon.SUPPORT_COUPON_DELETED_FLAG===0&&(!moment(coupon.SUPPORT_COUPON_BOOKING_DATE_TO).isBefore(new Date()))?<span style={{float: 'right'}}><Switch height={20} onChange={() => this.supportCouponToggleHandler(coupon.QF_SUPPORT_COUPON_PRID)} checked={this.state.toggle['coupon_'+coupon.QF_SUPPORT_COUPON_PRID]} onColor='#3DC5B6' offColor='#F86C69'/></span>:null} </CardHeader> </Col>
                        </Row>
                        {/* <CardHeader style={{fontWeight:"",fontSize:"22px",color:"#fff",backgroundColor:"#2C2165"}}>Coupon Details</CardHeader> */}
                        <CardText style={{padding:"25px"}}>

                            <Row className="mb-4" style={rowStyle}>

                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Coupon Code:</span> </text> <text style={CtextDecor1}>{coupon.SUPPORT_COUPON_CODE} </text>
                                </Col>
                                
                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Booking Id:</span> <text style={LinkedText} onClick={() => this.bookingIdLinkHandler(coupon.QF_CONSUMER_OFFER_ACCEPTED_PRID)}> { coupon.QF_CONSUMER_OFFER_ACCEPTED_PRID != null ? coupon.QF_CONSUMER_OFFER_ACCEPTED_PRID : <text style={fontText}>---</text> }</text></text>
                                </Col>

                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Created By:</span>{this.state.createdBy.fname}{' '}{this.state.createdBy.lname}</text>
                                </Col>         

                            </Row>


                            <Row className="mb-4" style={rowStyle}>

                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Coupon Description:</span> { coupon.SUPPORT_COUPON_DESC != null ? coupon.SUPPORT_COUPON_DESC : <text style={fontText}>---</text> }</text>
                                </Col>

                                <Col className="col-4">                                
                                    <text style={fontText1}><span style={fWeightSpan}>Coupon Type:</span> Support Coupon </text>
                                </Col>

                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Coupon Status:</span>
                                    <text > {coupon.SUPPORT_COUPON_DELETED_FLAG===1 ?
                                                <span style={{backgroundColor:"#F86C69",padding:10,width:100,color:"#fff",borderRadius:"3px"}}>Deleted</span> 
                                            :
                                            moment(moment(coupon.SUPPORT_COUPON_BOOKING_DATE_TO).set('hour', 23).set('minute', 59).set('second', 59)).isBefore(new Date()) ? 
                                                <span style={{backgroundColor:"#F86C69",padding:10,width:100,color:"#fff",borderRadius:"3px"}}>Expired</span>
                                            :coupon.SUPPORT_COUPON_ACTIVE===1?
                                            <span style={{backgroundColor:"#3DC5B6",padding:10,width:100,color:"#fff",borderRadius:"3px"}}>Active</span> 

                                                // : 
                                            // moment(coupon.SUPPORT_COUPON_SERVICE_DATE_TO).isBefore(new Date()) ? 
                                            //     <span style={{backgroundColor:"#F86C69",padding:10,width:100,color:"#fff",borderRadius:"3px"}}>Expired</span>
                                            :
                                            <span style={{backgroundColor:"#F86C69",padding:10,width:100,color:"#fff",borderRadius:"3px"}}>Inactive</span> 

                                            }
                                    </text></text>
                                </Col>




                                 

                            </Row>
                            <Row className="mb-4" style={rowStyle}>

                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Category:</span>{this.state.category != null ? this.state.category : <text style={fontText}>---</text>} </text>
                                </Col> 

                                <Col className="col-4">                                
                                    <text style={fontText1}><span style={fWeightSpan}>Subcategory:</span>{this.state.sub_category != null ? this.state.sub_category : <text style={fontText}>---</text>}</text>
                                </Col>

                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>City:</span>{this.state.city != null ? this.state.city : <text style={fontText}>---</text>}</text>
                                </Col>
                                
                            </Row>
                            <hr />
                            <Row className="mb-4" style={rowStyle}>

                                
                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Allow Coupon From Booking Date:</span>
                                        {coupon.SUPPORT_COUPON_BOOKING_DATE_FROM ?
                                            <text>{moment(coupon.SUPPORT_COUPON_BOOKING_DATE_FROM).format('DD-MMM-YYYY z')} {
                                                // this.state.OurCoupon_data[0].SUPPORT_COUPON_COUNTRY_CODE === "India" ? <span>IST</span> : this.state.OurCoupon_data[0].SUPPORT_COUPON_COUNTRY_CODE === "United States" ? <span>PDT</span> : null
                                            }</text> 
                                        : 
                                            <text style={fontText}>---</text>
                                        }
                                    </text>
                                </Col>
                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Allow Coupon Till Booking Date:</span>
                                        {coupon.SUPPORT_COUPON_BOOKING_DATE_TO ? 
                                        <text>
                                            {moment(coupon.SUPPORT_COUPON_BOOKING_DATE_TO).format('DD-MMM-YYYY z')} {
                                                // this.state.OurCoupon_data[0].SUPPORT_COUPON_COUNTRY_CODE === "India" ? <span>IST</span> : this.state.OurCoupon_data[0].SUPPORT_COUPON_COUNTRY_CODE === "United States" ? <span>PDT</span> : null
                                            }</text>
                                        : 
                                            <text style={fontText}>---</text>
                                        }
                                    </text>
                                </Col>
                                

                            </Row>
                            
                            <hr />
                            <Row className="mb-4" style={rowStyle}>

                                {/* <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Number of Coupons:</span>
                                            {coupon.SUPPORT_COUPON_MAX_COUNT ? 
                                                coupon.SUPPORT_COUPON_MAX_COUNT
                                            :
                                                <text style={fontText}>---</text>
                                            }
                                    </text>
                                </Col> */}
                                

                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Coupon Count:</span>
                                            {/* {coupon.SUPPORT_COUPON_USED_COUNT ?
                                                coupon.SUPPORT_COUPON_USED_COUNT
                                            :
                                                <text style={fontText}>0</text>
                                            } */}
                                            {coupon.SUPPORT_COUPON_USED_COUNT===null||0?0:coupon.SUPPORT_COUPON_USED_COUNT>0?<text style={LinkedText} onClick={()=>this.bookingsConsumedHandler()}>{coupon.SUPPORT_COUPON_USED_COUNT}</text>:coupon.SUPPORT_COUPON_USED_COUNT}

                                            &nbsp;{'/'}&nbsp;
                                            {coupon.SUPPORT_COUPON_MAX_COUNT ? 
                                                coupon.SUPPORT_COUPON_MAX_COUNT
                                            :
                                                <text style={fontText}>0</text>
                                            }
                                    </text>
                                </Col>

                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Coupon Minimum Amount:</span>

                                        { 

                                          coupon.SUPPORT_COUPON_MINIMUM_AMOUNT!==null ? 
                                            this.state.bookingDet === 'Rupees' ?
                                                <span>{'\u20B9'}</span>
                                            :
                                            this.state.bookingDet === 'Dollars' ?
                                                <span>$</span>
                                            :
                                            this.state.bookingDet
                                            :null
                                        
                                        }                                    
                                        {coupon.SUPPORT_COUPON_MINIMUM_AMOUNT ? 
                                            Curr(coupon.SUPPORT_COUPON_MINIMUM_AMOUNT).value.toFixed(2)
                                        :
                                            <text style={fontText}>---</text>
                                        }

                                    </text>
                                </Col>

                                <Col className="col-4">
                                    { 
                                    coupon.SUPPORT_COUPON_VALUE_OFF ?  
                                        <text style={fontText1}><span style={fWeightSpan}>Coupon Discount Amount:</span></text>
                                    : 
                                        coupon.SUPPORT_COUPON_PERCENT_OFF ? 
                                            <text style={fontText1}><span style={fWeightSpan}>Coupon Discount Percent:</span></text>
                                    :
                                        null
                                    } 
                                
                                    <text style={fontText1}>
                                    { 
                                        coupon.SUPPORT_COUPON_VALUE_OFF ?  this.state.bookingDet === 'Rupees' ? <span>{'\u20B9'}</span> : this.state.bookingDet === 'Dollars' ? <span>$</span> : this.state.bookingDet
                                    :
                                        null
                                    }

                                    {
                                        coupon.SUPPORT_COUPON_VALUE_OFF ? 
                                            Curr(coupon.SUPPORT_COUPON_VALUE_OFF).value.toFixed(2)
                                    :   
                                   
                                        null
                                    }                                 
                                    { 
                                        coupon.SUPPORT_COUPON_PERCENT_OFF ? <span>%</span> 
                                    : 
                                        null
                                    }
                                    </text>
                                </Col>

                            </Row>
                            <Row className="mb-4" style={rowStyle}>

                            
                                    
                            </Row>

                        </CardText>                    
                    </Card>
                )
                                }
            });
        } else {
            supportCoupon = null;
        }
    }

        return (
            <div>
                {/* <BreadCrumbs coupon='Coupon Details' couponLink='/home/coupon/CouponSearchDetails' breadCrumb={true}/> */}
                <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/>            
                <Container fluid>
                    {/* <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#50E3C2"/> */}
                    
                    {this.state.ourCoupon ? this.state.loading === false ? ourCoupon : null : null}
                    {this.state.merchantCoupon ? this.state.loading === false ? merchantCoupon : null  : null}
                    {this.state.supportCoupon ? this.state.loading === false ? supportCoupon : null  : null}
                    {this.state.loading ? <text style={fontText1}>Loading...</text> : null}

                    <ToastContainer />
                                 
                </Container>
            </div>            
        )

    }

}

export default CouponSearchDetails;