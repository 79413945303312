import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import './index.css';
import '../node_modules/bootstrap/dist/css/bootstrap.css';

import {createStore} from "redux";
import {Provider} from "react-redux";
import authReducer from "./Redux/Reducers/authReducer";
import {PersistGate} from "../node_modules/redux-persist/integration/react";
import { persistStore, persistReducer } from 'redux-persist';
import storage from "../node_modules/redux-persist/lib/storage";
import {BarLoader} from 'react-spinners';


const persistConfig = {
    key: 'root',
    storage,
  }
   
  const persistedReducer = persistReducer(persistConfig, authReducer);

const store=createStore(persistedReducer);
let persistor=persistStore(store);

ReactDOM.render(<Provider store={store}><PersistGate loading={<BarLoader/>} persistor={persistor}><App /></PersistGate></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
