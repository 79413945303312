import React, {Component} from 'react';
import { Table, Button, Col } from 'reactstrap';
import { withRouter } from 'react-router';

class MerchantNameView extends Component {
  
  constructor(props){
    super(props)
  }

  tableClickedHandler = (id) => { 
    // console.log("Table Handler", id);
    this.props.history.push({
      pathname:this.props.match.url+"/MerchantSearchDetails",
      state:{ 
        id,
        active: this.props.active
      }
  });
  }

  render() {

    // console.log("Props in table", this.props)

    return (
      <Col xl={{size: 10, offset: 1}}>
        <Table style={{marginTop: 50}} hover hidden={this.props.state ? false : true}>
          <thead style={{backgroundColor:'#2C2165',color:"white", fontFamily:'Muli'}}>
            <tr>
              <th> Name </th>
              <th> Email </th>
              <th> Mobile </th>
              <th> City </th>
              <th> Country </th>
            </tr>
          </thead>
          <tbody style={{fontFamily:"Muli", color: '#563951',fontWeight:'bold',}}>
          {this.props.merchant.map((merchant) => {
              return (
                <tr onClick={() => this.tableClickedHandler(merchant.QF_MERCHANT_PRID)} key={merchant.QF_MERCHANT_PRID}>
                <td>{merchant.MERCHANT_NAME}</td>
                <td>{merchant.MERCHANT_CONTACT_EMAIL}</td>
                <td>{merchant.MERCHANT_CONTACT_PHONE}</td>
                <td>{merchant.MERCHANT_ADDRESS_CITY}</td>
                <td>{merchant.MERCHANT_ADDRESS_COUNTRY}</td>
                </tr>
              )
          })}
            
          </tbody>
        </Table>
      </Col>
      
    );

  }
}

export default withRouter(MerchantNameView);