import React from 'react'
import Lottie from 'react-lottie';
import animationData from './paymentfailed.json'
 
export default class LottieControl extends React.Component {
 
  constructor(props) {
    super(props);
    this.state = {isStopped: false, isPaused: false};
  }

//   componentDidMount() {
//     setTimeout(() => {
//       this.props.history.push('/admin/customerDetails')
//     }, 3000);
// }
 
  render() {
    const buttonStyle = {
      display: 'block',
      margin: '10px auto'
    };
 
    const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    let animate = <text>Looks like payment failed. Please contact support.</text>;
 
    return <div>
            <Lottie options={defaultOptions}
                height={400}
                width={400}
                isStopped={this.state.isStopped}
            />
        {animate}
    </div>
  }
}