import React, { Component } from 'react';
import { Form, FormGroup, FormFeedback, Label, Input, Button,Container } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import axios from "axios";
import {BarLoader} from "react-spinners";





class SupportTicket extends Component {

    state = {
        supportTicket : { 
            value: '',
            valid: false,
            invalid: false
        },
        noResult:false,
        consumerDet: [],
        f_name:null,
        l_name:null,
        submit :{
            invalid: false,
            valid:false
        },
        loading:false,
        noData: false,
        dbError:false,
        sClicked:false
    }

    onKeyDown=(event)=>{
        if(event.key==='Enter')
        {
            event.preventDefault();
            event.stopPropagation();
            this.submitButtonHandler();
        }
        
    }
    supportTicketHandler = (event) => {
        let k=/^[0-9\b]+$/;
       
        if(event.target.value===''||k.test(event.target.value)){
            this.setState({
                supportTicket:{
                    ...this.state.supportTicket,
                    value: event.target.value,
                    valid: true,
                    invalid: false,
                },
                noData: false,
                submit: {
                    ...this.state.submit,
                    invalid: false
                }
            })
           
       
         }
       
    }
   

    submitButtonHandler = () => {
        if(this.state.supportTicket.invalid || 
            this.state.supportTicket.value.length === 0){

            this.setState({
                submit: {
                    ...this.state.submit,
                    invalid: true 
                },
                supportTicket:{
                    ...this.state.supportTicket,
                    valid: false,
                    invalid: true
                }
            });
            return false;
        } else{
            this.setState({
                submit: {
                    ...this.state.submit,
                    invalid: false,
                    valid:true
                },
                supportTicket:{ 
                    ...this.state.supportTicket,
                    valid: true,
                    invalid: false
                },
                loading:true,
                sClicked:true
            
               
            })}
            let TicketId={
               ticketId :this.state.supportTicket.value
            }
            // console.log('tic',TicketId)
           

            axios.post("/support/consumer/SearchByTicketId",TicketId)


            .then(res=>{
      
                if(res.data.data){
                    if(res.data.details.length!==0) 
                      {
                        
                        this.setState(({ 
                                      ...this.state,
                                    consumerDet: res.data.details,
                                    // f_name:res.data.details1.length!==0?res.data.details1[0].SUPPORT_USER_FNAME:null,
                                    // l_name:res.data.details1.length!==0?res.data.details1[0].SUPPORT_USER_LNAME:null,
                            submit: {
                                     ...this.state.submit, 
                                     valid: true,
                                    }, 
                                      loading:false,
                                      noResult:false,
                                      sClicked:false

                        }))
                        // console.log('Props render()',this.state.consumerDet)
                        this.props.history.push({
                            pathname:"/home/supportTicket/ConsumerSearchTicketId",
                            state:{
                                TicketId:this.state.consumerDet[0].QF_CONSUMER_PRID, 
                                acceptance_prid:this.state.consumerDet[0].QF_CONSUMER_OFFER_ACCEPTED_PRID,

                                consumerData:{
                                 
                                             QF_CONSUMER_OFFER_ACCEPTED_PRID :this.state.consumerDet[0].QF_CONSUMER_OFFER_ACCEPTED_PRID,
                                             CONSUMER_SUPPORT_CATEGORY : this.state.consumerDet[0].CONSUMER_SUPPORT_CATEGORY,
                                             CONSUMER_SUPPORT_DESC: this.state.consumerDet[0].CONSUMER_SUPPORT_DESC,
                                             CONSUMER_SUPPORT_STATUS:this.state.consumerDet[0].CONSUMER_SUPPORT_STATUS,
                                             QF_CONSUMER_SUPPORT_PRID:this.state.consumerDet[0].QF_CONSUMER_SUPPORT_PRID,
                                             CONSUMER_SUPPORT_RESOLUTION:this.state.consumerDet[0].CONSUMER_SUPPORT_RESOLUTION,
                                             CONSUMER_SUPPORT_CREATED_ON_DATE:this.state.consumerDet[0].CONSUMER_SUPPORT_CREATED_ON_DATE,
                                             QF_CONSUMER_PRID:this.state.consumerDet[0].QF_CONSUMER_PRID,
                                             CONSUMER_SUPPORT_RESOLVED_ON_DATE:this.state.consumerDet[0].CONSUMER_SUPPORT_RESOLVED_ON_DATE,
                                             SUPPORT_USER_FNAME:this.state.consumerDet[0].SUPPORT_USER_FNAME,
                                             SUPPORT_USER_LNAME:this.state.consumerDet[0].SUPPORT_USER_LNAME,
                                             rf_name:this.state.consumerDet[1]?this.state.consumerDet[1].Resolved_fname:'Not', 
                                             rl_name:this.state.consumerDet[1]?this.state.consumerDet[1].Resolved_lname:'Resolved',

                                            },
                                           
                                            supportLink: '/home/supportTicket',
                                            support:'Support Details'
                            }
                        }); 

                       
                      }
                      if(res.data.details.length===0)
                      {
                        //   console.log('yes going')
                        this.setState({ 
                            ...this.state,
                           consumerDet:[],
    
                            submit: {
                           ...this.state.submit,
                            valid: true,
                       },
                       loading:false
                       ,   
                       noResult:true,
                       sClicked:false
                       
    
                      })
                       
                      }
      
                  }
      
                  else{
                          if(res.data.dbError===true)
                          {
                              
                              toast.error("Internal Server Error.",{position:toast.POSITION.BOTTOM_CENTER});
                              this.setState({loading:false,dbError:true})
                          }
                            
                         else{

                               this.setState({ 
                                ...this.state,
                                consumerDet:[], 
                                noData: true,
    
                         submit: {
                           ...this.state.submit,
                            valid: true,
                       },
                       loading:false,
                    sClicked:false})
      
                        }                    
                          
                  }
      
              }).catch(err=>{
                console.log(err);
                toast.warn("Unable to Reach Server! Please Try Again!" , {
                    position: toast.POSITION.BOTTOM_CENTER
                  });
                this.setState({loading:false,
                sClicked:false})
            })
        
    }
    
    render() {
      
    // console.log('time',moment().format("YYYY-MM-DD HH:mm:ss"))
       
    
     let noResult=null;
        
        return (
            <div>
                <Container fluid>
            <div className="row justify-content-center" style={{backgroundColor: '#EFEFEF'}}>
            <div className="col-3" >
                    <Form className="commentForm">
                        <FormGroup row>
                            <Label className="comment-input" for="Support-Ticket" ><text style={{fontFamily:'Muli'}}>Support Ticket-Id </text><span style={{color: 'red'}}>*</span></Label>
                            <Input  onChange={this.supportTicketHandler}
                                    valid={this.state.supportTicket.valid ? true : false}
                                    invalid={this.state.supportTicket.invalid ? true : false} 
                                    placeholder="Please enter Support Ticket-Id"
                                    value={this.state.supportTicket.value}
                                    onKeyDown={this.onKeyDown}
                                    autoFocus

                                    /> 
                                    <FormFeedback><text style={{fontFamily:'Muli'}}>Ticket-Id is Invalid!</text></FormFeedback>
                        </FormGroup>
                        
                        <FormGroup>
                            <Button disabled={this.state.loading?true :false} onClick={this.submitButtonHandler} style={{backgroundColor:"#2C2165", color:'white'}} disabled={this.state.sClicked}><text style={{fontFamily:'Muli'}}>Search</text></Button>
                       
                        </FormGroup>

                    </Form>
                      
                        { 
                            this.state.noData ? 
                            <p>No results to display</p> : null
                        }
                <BarLoader widthUnit={"%"} width={100}  loading={this.state.loading} color="#2C2165"/>
                <ToastContainer />

            </div> 
            
            </div>    
            </Container>                  
            </div>
            
           
        )
    }
} 

export default SupportTicket; 