
import React, { Component } from 'react';

class termsConditions extends Component {
    


    render() {

       

        return (
            <div >
                
               <p>sample text</p >
            </div>
        )
    }
}


export default termsConditions;