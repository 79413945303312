import React from 'react';
import {Row,
        Col,
        Navbar,
        NavbarBrand,
        NavItem,
        NavLink,
        Nav ,
        UncontrolledDropdown,
        Dropdown,
        DropdownToggle,
        DropdownMenu,
        DropdownItem,
        Input} from 'reactstrap';
import { NavLink as NavLinkReactor } from 'react-router-dom';
import {connect} from "react-redux";
import {FaSearch} from 'react-icons/fa';  
import config from '../../config'

class NavbarHome extends React.Component {

state={
        country : {
            name : this.props.country ? this.props.country : 'India'
        },
        coupon : {
            name: 'Create Coupon'
        },
        supportCoupon:{
            name: 'Support Coupon'
            
        }
    }

    countryHandler = (event) => {
        switch(event.target.id) {
            case "india":
            return (
                    this.setState({
                        country: {
                            ...this.state.country,
                            name: 'India'
                        }
                    }),

                    this.props.countryIndiaHandler(this.state.country)
            )
            case "usa":
            return (
                    this.setState({
                        country: {
                            ...this.state.country,
                            name: 'USA'
                        }
                    }),

                    this.props.contryUSAHandler(this.state.country)
                )
        }
    }

    couponHandler = (event) => {
        switch(event.target.id) {
            case "createCoupon":
            return this.setState({
                coupon: {
                    ...this.state.coupon,
                    name: 'Create Coupon'
                }
            });

            case "adminCoupon":
            return this.setState({
                coupon: {
                    ...this.state.coupon,
                    name: 'Admin Coupon'
                }
            })
        }
    }
    // supportCouponHandlercouponHandler = (event) => {
    //     switch(event.target.id) {
    //         case "createCoupon":
    //         return this.setState({
    //             supportCoupon: {
    //                 ...this.state.supportCoupon,
    //                 name: 'Create Coupon'
    //             }
    //         });

    //         case "viewCoupon":
    //         return this.setState({
    //             supportCouponcoupon: {
    //                 ...this.state.supportCoupon,
    //                 name: ' View Coupon'
    //             }
    //         })
    //     }
    // }

    onLogoutHandler=()=>{
        this.props.removeToken();
        this.props.removeCredentials();

        this.props.history.replace("/");
    }
categoryHandler = () => {
        this.props.history.push("/home/categorysearch");
    }
    cityCatDisplayHandler = () => {
        this.props.history.push("/home/cityCategoryDisplay");
    }

      render () {
         
          const mainNavStyle={
            color:"#fff",
            padding:12,
            borderRadius:3,
            textDecoration:"none",
            fontWeight:500,
            fontFamily: 'Muli'
          }

          const mainNavStyleMob={
            color:"#fff",
            padding:1,
            borderRadius:3,
            textDecoration:"none",
            // fontWeight:500,
            fontSize: '10px'
          }
          const mainNavStyleMob1={
            color:"#d8d860",
            padding:2,
            borderRadius:3,
            textDecoration:"none",
            fontWeight:500,
            fontSize: '10px'
          }

          const adminNavStyle={
            color:"black",
            padding:10,
            borderRadius:3,
            textDecoration:"none",
            fontWeight:500
          }

          const activeNav={
              backgroundColor:'#DBBCD9',
            //   "#CFB3CD",
              color:"black",
              fontFamily: 'Muli'
          }

          const subNavStyle={
              color:"#fff",
              padding:7,
              borderRadius:3,
              textDecoration:"none",
              fontWeight:400,
              fontFamily: 'Muli'
          }

          const subNavStyleMob={
            color:"#fff",
            padding:2,
            borderRadius:3,
            textDecoration:"none",
            fontWeight:500,
            fontSize: '10px'
        }
          const subNavStyle1={
            color:"#d1f4fa",
            padding:7,
            borderRadius:3,
            textDecoration:"none",
            fontWeight:400,
        }
        
        return(
        <div >
            

            <Navbar style={{backgroundColor:"#2C2165"}}>
                <NavbarBrand style={{color:"#d1f4fa",fontSize:"26px",fontWeight:"bold", fontFamily: 'Muli'}} className="navbar-brand mb-0 h1"><NavLinkReactor style={subNavStyle1} to="/home/customerDetails"> QF Support </NavLinkReactor></NavbarBrand>
                <text style={{color:"#d8d860",fontWeight:"bold",marginLeft:30}}>{config.localhost_url==="http://ec2-54-149-62-15.us-west-2.compute.amazonaws.com:8087"?'DEVELOPMENT':config.localhost_url==="http://ec2-34-214-32-243.us-west-2.compute.amazonaws.com:8087"?"TESTING":null }</text>
                <Nav >
                    <UncontrolledDropdown nav inNavbar  >
                        <DropdownToggle nav caret style={{ color: "#d8d860", padding:10, borderRadius:3, textDecoration:"none",fontWeight:500}}>
                              <text style={{fontFamily: 'Muli'}}> {this.state.country.name} </text> 
                        </DropdownToggle> 
                            <DropdownMenu right >
                                <DropdownItem onClick={this.countryHandler} id="india" style={{color:'black',fontWeight:500, textDecoration:'none',fontFamily: 'Muli'}} to="#"> India </DropdownItem>
                                <DropdownItem onClick={this.countryHandler} id="usa" style={{color:'black',fontWeight:500, textDecoration:'none',fontFamily: 'Muli'}} to="#" > USA </DropdownItem>
                            </DropdownMenu>
                    </UncontrolledDropdown> 
                
                    <NavItem>
                        <NavLink >
                            <NavLinkReactor style={mainNavStyle} to={this.props.match.url+"/myTicket"} activeStyle={activeNav}> My Tickets</NavLinkReactor> 
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink >
                            <NavLinkReactor style={mainNavStyle} to={this.props.match.url+"/versions"} activeStyle={activeNav}> Versions</NavLinkReactor> 
                        </NavLink>
                    </NavItem>

                   
                    <NavItem >
                        <NavLink >
                            <NavLinkReactor style={mainNavStyle} to={this.props.match.url+"/bugreport"} activeStyle={activeNav}> Bug Report </NavLinkReactor>
                        </NavLink>
                    </NavItem>

                     <NavItem>
                        <NavLink >
                            <NavLinkReactor style={mainNavStyle} to={this.props.match.url + "/feedback"} activeStyle={activeNav}> Feedback </NavLinkReactor>
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink >
                            <NavLinkReactor id="viewCoupon" onClick={this.supportCouponHandler} style={mainNavStyle}  to={this.props.match.url+"/supportViewCoupon"} activeStyle={activeNav}> My Coupons </NavLinkReactor>
                        </NavLink>
                    </NavItem>            
                    

                    <UncontrolledDropdown nav inNavbar >
                        <DropdownToggle nav caret style={{color: "#B6E1E0"}}>
                                <text style={mainNavStyle}> {this.props.fname} </text>
                        </DropdownToggle>
                            <DropdownMenu right>
                            {/* <DropdownItem>
                                    <NavLinkReactor onClick={this.cityCatDisplayHandler} style={{color:'black', textDecoration:'none', fontFamily:'Muli'}} to="/home/cityCategoryDisplay"> City/Category</NavLinkReactor>
                                </DropdownItem> */}
                                <DropdownItem>
                                    <NavLinkReactor style={{color:'black', textDecoration:'none', fontFamily:'Muli'}} to="/home/customerDetails"> Available </NavLinkReactor>
                                </DropdownItem> 
                                <DropdownItem>
                                    <NavLinkReactor style={{color:'black', textDecoration:'none', fontFamily:'Muli'}} to="/home/customerDetails" > Break </NavLinkReactor>
                                </DropdownItem>
                                <DropdownItem>
                                    <NavLinkReactor style={{color:'black', textDecoration:'none', fontFamily:'Muli'}} to="/home/customerDetails" > Offline </NavLinkReactor>
                                </DropdownItem>
                                <DropdownItem onClick={this.onChangePasswordHandler}> 
                                <NavLinkReactor style={{color:'black', textDecoration:'none', fontFamily:'Muli'}} to="/home/changePassword" >Change Password</NavLinkReactor> 
                                </DropdownItem>
                                {/* <DropdownItem onClick={this.categoryHandler} style={{color:'black', textDecoration:'none', fontFamily:'Muli'}} to="/home/categorysearch" > Support Search </DropdownItem> */}
                                <DropdownItem divider />
                                <DropdownItem onClick={this.onLogoutHandler} style={{color:'black', textDecoration:'none', fontFamily:'Muli'}} to="/" > Logout </DropdownItem>
                            </DropdownMenu>
                    </UncontrolledDropdown>

                </Nav>
            </Navbar>

            <Navbar style={{backgroundColor:"#9277BA"}}>                
                <NavbarBrand > <text style={{color:"#011936",fontSize:"18px",fontWeight:'bold', paddingLeft: 30,fontFamily: 'Muli'}}>Search By <span style={{padding: 5}}><FaSearch size="25" /></span></text> </NavbarBrand>              
                <Nav>
                    <NavItem>
                        <NavLink>
                            <NavLinkReactor style={subNavStyle} to={this.props.match.url+"/customerDetails"} activeStyle={activeNav}> Customer Details </NavLinkReactor>
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink>
                            <NavLinkReactor style={subNavStyle} to={this.props.match.url+"/bookingId"} activeStyle={activeNav}> Booking Id </NavLinkReactor>
                        </NavLink>
                    </NavItem>

                        
                    <NavItem>
                        <NavLink>
                            <NavLinkReactor style={subNavStyle} to={this.props.match.url+"/merchantDetails"} activeStyle={activeNav}> Professional Details </NavLinkReactor>
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink>
                            <NavLinkReactor style={subNavStyle} to={this.props.match.url+"/offerDetails"} activeStyle={activeNav}> Service Details </NavLinkReactor>
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink>
                            <NavLinkReactor style={subNavStyle} to={this.props.match.url+"/coupon"} activeStyle={activeNav}> Coupons </NavLinkReactor>
                        </NavLink>
                    </NavItem> 

                    <NavItem>
                        <NavLink>
                            <NavLinkReactor style={subNavStyle}  to={this.props.match.url+"/supportTicket"} activeStyle={activeNav}> Support Ticket Id </NavLinkReactor>
                        </NavLink>
                    </NavItem>            

                </Nav>
            </Navbar>
            </div>
            
                       
        );    
    }
}

const mapStateToProps=state=>{
    return {
        fname:state.fname,
        country: state.country 
    }
}

const mapDispatchToProps=dispatch=>{
    return {
        removeToken: ()=>dispatch({type:"REMOVE_TOKEN"}),
        removeCredentials: ()=>dispatch({type:"REMOVE_CREDENTIALS"}),
        contryUSAHandler: () => dispatch({type:"USA"}),
        countryIndiaHandler: () =>dispatch({type:"India"})
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(NavbarHome);