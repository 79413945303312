import React, { Component } from 'react';
import { Form, FormGroup, FormFeedback, Label, Input, Button, Row, Col,Container } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import '../../../node_modules/react-datepicker/dist/react-datepicker.css';
import '../../../node_modules/react-datepicker/dist/react-datepicker-cssmodules.css';
import { BarLoader } from "react-spinners";

import ViewByOfferid from '../SubComponents/ViewByOfferId';
import Select from 'react-select';

class OfferDetails extends Component {
    
        state = {
            category : {
                value: "---select---",
                valid: false,
                invalid: false
            },
            categories: [],
            subCategory : {
                value: "---select---",
                valid: false,
                invalid: false
            },
            subCategories: [],
            merchantName : {
                value: '',
                valid : false,
                invalid: false
            },
            offerId : {
                value: '',
                valid: false,
                invalid: false
            },
            OfferDetails: [],
            submit:{
                invalid: false,
                valid: false,
                Button: false
            },
          startDate: {
              value: null,
              valid: false,
              invalid: false 
          },
          loading: false,
          state: false,
          merchantOptions:[],
          serviceId : {
            value: '',
            valid: false,
            invalid: false
          },
          ServiceDetails: [],
        }


        componentDidMount() {
            // this.setState({
            //     ...this.state,
            //     loading:true
            // });
            axios.post("/support/consumer/categoryDetails")
            .then((res) => {
                if(res.data.result){
                        this.setState({
                            ...this.state,
                            categories: res.data.category,
                            loading: false
                        });                    

                } else {
                    if(res.data.dbError){
                        toast.error("Internal Server Error", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        this.setState({
                            ...this.state,
                            loading: false,
                            categories: []
                        });
                    } else {
                        toast.error("Problem fetching Categories", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        this.setState({
                            ...this.state,
                            loading: false,
                            categories: []
                        });
                    }
                }
                //.....added code
                axios.post('/support/allMerchantFetch')
                .then((response) => {
                    if(response.data.data){
                        if(response.data.result.length!==0){
                            console.log('Result', response.data.result);
                            this.setState({
                                merchantOptions: response.data.result
                            })
                        } else{
                            console.log("No result found")
                        }
                    } else {
                        if(response.data.dbError){
                            toast.error("Internal Server Error", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                        } else {
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                        
                        }
                    }
                })
                .catch((err1) => {
                    console.log("Error",err1);
                    toast.error("Server Error", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                });

                //..............
           })
           .catch((err) => {
                toast.warn("Unable to Reach Server! Please Try Again!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.setState({
                    ...this.state,
                    loading: false
                });
           });
        }

        categoryHandler = (event) => {
           
                let id = event.target.value;

                if(event.target.value === '0'){

                    this.setState({
                        ...this.state,
                        category: {
                            ...this.state.category,
                            value: '',
                            valid: false,
                            invalid: false
                        },
                        subCategory:{
                            ...this.state.subCategory,
                            value: '',
                            valid: false,
                            invalid: false
                        },
                        subCategories:[]
                    })

                } else {

                    this.setState({
                        ...this.state,
                        category: {
                            ...this.state.category,
                            value: event.target.value,
                            valid: true,
                            invalid: false
                        },
                        submit:{
                            ...this.state.submit,
                            invalid: false,
                            Button: false
                        },
                        offerId:{
                            ...this.state.offerId,
                            value: '',
                            invalid: false
                        },
                        subCategory:{
                            ...this.state.subCategory,
                            value: ''
                        },
                        subCategories:[]
                        
                    });
                
                    axios.post("/support/consumer/subCategoryDetails", {id})
                    .then((res1) => {

                        console.log('tRACE',res1.data)
        
                        if(res1.data.data){
                            if(res1.data.subCat){
                                this.setState({
                                    ...this.state,
                                    subCategories: res1.data.sub_category,
                                    loading: false
                                });
                            } else {
                                this.setState({
                                    ...this.state,
                                    subCategories: [],
                                    loading: false
                                });
                            }
                        } else {
                            if(res1.data.dbError){
                                toast.error("Internal Server Error", {
                                    position: toast.POSITION.BOTTOM_CENTER
                                });
                                this.setState({
                                    ...this.state,
                                    loading: false
                                })
                            } else {
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });                     
                            }                    
                        }
                    })
                    .catch((err) => {
                        toast.warn("Unable to Reach Server! Please Try Again!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        this.setState({
                            ...this.state,
                            loading: false
                        }); 
                    });
                }
                
    }

        subCategoryHandler = (event) => {

            if(event.target.value === '0'){

                this.setState({
                    ...this.state,
                    subCategory: {
                        ...this.state.subCategory,
                        value: '',
                        valid: true,
                        invalid: false
                    }
                })

            } else {
    
                this.setState({
                    subCategory: {
                        ...this.state.subCategory,
                        value: event.target.value,
                        valid: true,
                        invalid: false
                    },
                    submit: {
                        ...this.state.submit,
                        invalid: false,
                        Button: false
                    },
                    offerId:{
                        ...this.state.offerId,
                        value: '',
                        invalid: false
                    }
                });
            }
        }

        merchantNameHandler = (item) => {
            console.log('data trace',item)
            if(item == null || item.length == 0){
                this.setState({
                    merchantName:{
                        ...this.state.merchantName,
                        value : null,
                        valid: false,
                        invalid: true
                    }
                })
              } else{
                this.setState({
                    merchantName:{
                        ...this.state.merchantName,
                        value : item.label,
                        valid: true,
                        invalid: false
                    },
                    submit: {
                        ...this.state.submit,
                        invalid: false,
                        Button: false
                    },
                    offerId:{
                        ...this.state.offerId,
                        value: '',
                        invalid: false
                    }
                  })
              }
            // if(event.target.value.length === 0){
            //     return this.setState({
            //         merchantName:{
            //             ...this.state.merchantName,
            //             value: event.target.value,
            //             valid: false,
            //             invalid: true 
            //         }
            //     })
            // } else {
            //     return this.setState({
            //         merchantName : {
            //             ...this.state.merchantName,
            //             value: event.target.value,
            //             valid: true,
            //             invalid: false
            //         },
            //         submit: {
            //             ...this.state.submit,
            //             invalid: false,
            //             Button: false
            //         },
            //         offerId:{
            //             ...this.state.offerId,
            //             value: '',
            //             invalid: false
            //         }
            //     });
            // }        
        } 

        offerIdHandler = (event) => {
        let k=/^[0-9\b]+$/;

            if(event.target.value===''||k.test(event.target.value)){
             this.setState({
                    offerId : {
                        ...this.state.offerId,
                        value: event.target.value,
                        valid: true,
                        invalid: false
                    },
                    serviceId:{
                        ...this.state.serviceId,
                        value:'',
                        valid: false,
                        invalid: false
                    },
                    submit: {
                        ...this.state.submit,
                        invalid: false,
                        Button: false
                    },
                    // merchantName:{
                    //     ...this.state.merchantName,
                    //     value: '',
                    //     invalid: false
                    // },
                    // category:{
                    //     ...this.state.category,
                    //     value: 0,
                    //     invalid: false
                    // },
                    // subCategory:{
                    //     ...this.state.subCategory,
                    //     value: 0,
                    //     invalid: false
                    // },
                    // startDate:{
                    //     value: null,
                    //     invalid: false
                    // }
                });
            } 
        }
        ServiceIdHandler = (event) => {
            let k=/^[0-9\b]+$/;
        
                if(event.target.value===''||k.test(event.target.value)){
                 this.setState({
                        serviceId : {
                            ...this.state.serviceId,
                            value: event.target.value,
                            valid: true,
                            invalid: false
                        },
                        offerId:{
                            ...this.state.offerId,
                            value:'',
                            valid: false,
                            invalid: false
                        },
                        submit: {
                            ...this.state.submit,
                            invalid: false,
                            Button: false
                        },
                    });
                } 
            }
        submitButtonHandler = () => {
            if(this.state.serviceId.value === '' && this.state.offerId.value === '' ){
                this.setState({
                    ...this.state,
                    serviceId: {
                        ...this.state.serviceId,
                        valid: false,
                        invalid: true
                    },
                    submit: {
                        ...this.state.submit,
                        invalid: true,
                    }
                })
            }
            if(this.state.offerId.value.length > 0){

                if(this.state.offerId.invalid){
    
                    this.setState({
                        submit: {
                            ...this.state.submit,
                            invalid: true
                        }
                    });
    
                    // return toast.error("Please enter a valid offer id",{
                    //     position: toast.POSITION.BOTTOM_CENTER
                    // });
    
                } else {
                    
                    this.setState({
                        submit: {
                            ...this.state.submit,
                            invalid: false,
                            Button: true 
                        },
                        offerId:{
                            ...this.state.offerId,
                            valid: true,
                            invalid: false
                        },
                        // category:{
                        //     ...this.state.category,
                        //     valid: true,
                        //     invalid: false
                        // },
                        // subCategory: {
                        //     ...this.state.subCategory,
                        //     valid: true,
                        //     invalid: false
                        // },
                        // merchantName:{
                        //     ...this.state.merchantName,
                        //     valid: true,
                        //     invalid: false
                        // },
                        // startDate: {
                        //     ...this.state.startDate,
                        //     valid: true,
                        //     invalid: false
                        // },
                        loading: true
                    }); 
                    
                    let offerId = this.state.offerId.value;
    
                    axios.post("/support/consumer/searchByOfferid", { offerId })
                    .then((response) => {
                        // console.log("Response", response.data);
    
                        if(response.data.data){
                            this.setState({
                                OfferDetails: response.data.details,
                                submit: {
                                    ...this.state.submit,
                                    valid: true,
                                   
                                },
                                loading: false
                            });
                            this.props.history.push({
                                pathname:'/home/offerDetails/OfferIdSearchDetails',
                                state:{
                                  id : this.state.OfferDetails[0].QF_MERCHANT_OFFER_PRID,
                                  offerLink: '/home/offerDetails',
                                  offer:'Offer Details'
                                }
                            });
                            
                        } else {
                            if(response.data.dbError){
                                this.setState({
                                    submit:{
                                        ...this.state.submit,
                                        valid: false,                                        
                                    },
                                    loading: false
                                });
    
                                return toast.error("Internal Server Error!", {
                                    position: toast.POSITION.BOTTOM_CENTER
                                });
    
                            }
                                this.setState({
                                    OfferDetails:[],
                                    submit:{
                                        ...this.state.submit,
                                        valid: false,
                                    },
                                    loading: false
                                });
                            
                        }
                    })
                    .catch((err) => {
                        console.log("error", err);
                        toast.warn("Unable to Reach Server! Please Try Again!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        })
                        this.setState({
                            submit:{
                                ...this.state.submit,
                                valid: false,
                            },
                            loading: false
                        });
                    })
                }
    
            }
            if(this.state.serviceId.value.length > 0){
    
                if(this.state.serviceId.invalid){
    
                    this.setState({
                        submit: {
                            ...this.state.submit,
                            invalid: true
                        }
                    });
    
                    // return toast.error("Please enter a valid offer id",{
                    //     position: toast.POSITION.BOTTOM_CENTER
                    // });
    
                } else {
                    
                    this.setState({
                        submit: {
                            ...this.state.submit,
                            invalid: false,
                            Button: true 
                        },
                        serviceId:{
                            ...this.state.serviceId,
                            valid: true,
                            invalid: false
                        },
                        loading: true
                    }); 
                    
                    let body={
                        sid:this.state.serviceId.value
                    }
                    axios.post("/support/merchantservices/detailsdisplay",body)
                    .then((response) => {
                        // console.log("Response", response.data);
    
                        if(response.data.data){
                            this.setState({
                            ServiceDetails: response.data.displayservices,
                                submit: {
                                    ...this.state.submit,
                                    valid: true,
                                   
                                },
                                loading: false
                            });
                            this.props.history.push({
                                pathname:'/home/offerDetails/ServiceDetails',
                                state:{
                                  id : this.state.ServiceDetails[0].QF_MERCHANT_SERVICE_PRID,
                                  serviceLink: '/home/offerDetails',
                                  service:'Service Details'
                                }
                            });
                            
                        } else {
                            if(response.data.dbError){
                                this.setState({
                                    submit:{
                                        ...this.state.submit,
                                        valid: false,                                        
                                    },
                                    loading: false
                                });
    
                                return toast.error("Internal Server Error!", {
                                    position: toast.POSITION.BOTTOM_CENTER
                                });
    
                            }
                                this.setState({
                                    ServiceDetails:[],
                                    submit:{
                                        ...this.state.submit,
                                        valid: false,
                                    },
                                    loading: false
                                });
                            
                        }
                    })
                    .catch((err) => {
                        console.log("error", err);
                        toast.warn("Unable to Reach Server! Please Try Again!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        })
                        this.setState({
                            submit:{
                                ...this.state.submit,
                                valid: false,
                            },
                            loading: false
                        });
                    })
                }
    
            }

            // if(this.state.offerId.value.length > 0){

            //     if(this.state.offerId.invalid){

            //         this.setState({
            //             submit: {
            //                 ...this.state.submit,
            //                 invalid: true
            //             }
            //         });

            //         // return toast.error("Please enter a valid offer id",{
            //         //     position: toast.POSITION.BOTTOM_CENTER
            //         // });

            //     } else {
                    
            //         this.setState({
            //             submit: {
            //                 ...this.state.submit,
            //                 invalid: false,
            //                 Button: true 
            //             },
            //             offerId:{
            //                 ...this.state.offerId,
            //                 valid: true,
            //                 invalid: false
            //             },
            //             // category:{
            //             //     ...this.state.category,
            //             //     valid: true,
            //             //     invalid: false
            //             // },
            //             // subCategory: {
            //             //     ...this.state.subCategory,
            //             //     valid: true,
            //             //     invalid: false
            //             // },
            //             // merchantName:{
            //             //     ...this.state.merchantName,
            //             //     valid: true,
            //             //     invalid: false
            //             // },
            //             // startDate: {
            //             //     ...this.state.startDate,
            //             //     valid: true,
            //             //     invalid: false
            //             // },
            //             loading: true
            //         }); 
                    
            //         let sid = Number(this.state.offerId.value);
    
            //         axios.post("/support/merchantservices/detailsdisplay", { sid })
            //         .then((response) => {
            //              console.log("Response1", response.data);

            //             if(response.data.data){
            //                 this.setState({
            //                     OfferDetails: response.data.displayservices,
            //                     submit: {
            //                         ...this.state.submit,
            //                         valid: true,
                                   
            //                     },
            //                     loading: false
            //                 });
            //                 this.props.history.push({
            //                     pathname:'/home/offerDetails/ServiceDetails',
            //                     state:{
            //                       id : this.state.OfferDetails[0].QF_MERCHANT_SERVICE_PRID,
            //                       offerLink: '/home/offerDetails/OfferIdSearchDetails',
            //                       offer:'Offer Details'
            //                     }
            //                 });
                            
            //             } else {
            //                 if(response.data.dbError){
            //                     this.setState({
            //                         submit:{
            //                             ...this.state.submit,
            //                             valid: false,                                        
            //                         },
            //                         loading: false
            //                     });
    
            //                     return toast.error("Internal Server Error!", {
            //                         position: toast.POSITION.BOTTOM_CENTER
            //                     });

            //                 }
            //                     this.setState({
            //                         OfferDetails:[],
            //                         submit:{
            //                             ...this.state.submit,
            //                             valid: false,
            //                         },
            //                         loading: false
            //                     });
                            
            //             }
            //         })
            //         .catch((err) => {
            //             console.log("error", err);
            //             toast.warn("Unable to Reach Server! Please Try Again!", {
            //                 position: toast.POSITION.BOTTOM_CENTER
            //             })
            //             this.setState({
            //                 submit:{
            //                     ...this.state.submit,
            //                     valid: false,
            //                 },
            //                 loading: false
            //             });
            //         })
            //     }

            // } 
            // else if(this.state.category.value === "---select---" || this.state.category.invalid || this.state.category.value === '' || this.state.category.value === 0){
            //     this.setState({
            //         submit: {
            //             ...this.state.submit,
            //             invalid: true 
            //         },
            //         category: {
            //             ...this.state.category,
            //             valid: false,
            //             invalid: true
            //         },
            //         offerId: {
            //             ...this.state.offerId,
            //             valid: false,
            //             invalid: false
            //         },
            //         subCategory: {
            //             ...this.state.subCategory,
            //             value: "---select---"
            //         }
            //     });                
            //     return false;
            // } else if(this.state.subCategory.value === "---select---" || this.state.subCategory.value === '' || this.state.subCategory.invalid || this.state.subCategory.value === 0){
            //     this.setState({
            //         submit: {
            //             ...this.state.submit,
            //             invalid: true 
            //         },
            //         subCategory: {
            //             ...this.state.subCategory,
            //             valid: false,
            //             invalid: true
            //         }
            //     });                    
            //     // return toast.warn("Please select Sub-Category!", {
            //     //     position: toast.POSITION.BOTTOM_CENTER
            //     // });
            // } 
            // else if(this.state.merchantName.value.length === 0 || this.state.merchantName.invalid){
            //     this.setState({
            //         submit: {
            //             ...this.state.submit,
            //             invalid: true
            //         },
            //         merchantName:{
            //             ...this.state.merchantName,
            //             valid: false,
            //             invalid: true
            //         }
            //     });

            //     return toast.warn("Please enter Merchant Name!", {
            //         position: toast.POSITION.BOTTOM_CENTER
            //     }); 
            // } 
            // else if(this.state.startDate.value === '' || this.state.startDate.value === null){
            //     this.setState({
            //         submit: {
            //             ...this.state.submit,
            //             invalid: true
            //         },
            //         startDate: {
            //             ...this.state.startDate,
            //             valid: false,
            //             invalid: true
            //         }
            //     });  

            //     return toast.warn("Please select the Service Date!", {
            //         position: toast.POSITION.BOTTOM_CENTER
            //     });    
            // } else {
            //     this.setState({
            //         submit: {
            //             ...this.state.submit,
            //             invalid: false,
            //             Button: true
            //         },
            //         category:{
            //             ...this.state.category,
            //             valid: true,
            //             invalid: false
            //         },
            //         subCategory: {
            //             ...this.state.subCategory,
            //             valid: true,
            //             invalid: false
            //         },
            //         startDate: {
            //             ...this.state.startDate,
            //             valid: true,
            //             invalid: false
            //         },
            //         offerId:{
            //             ...this.state.offerId,
            //             valid: true,
            //             invalid: false
            //         },
            //         loading: true
            //     });

            //     let body = {
            //         sub_cat : this.state.subCategory.value,
            //         mname: this.state.merchantName.value ? this.state.merchantName.value : null,
            //         date : moment(this.state.startDate.value).format("YYYY-MM-DD")
            //     }
            //     axios.post("/support/consumer/searchBySubcategoryDate", body)
            //     .then((res) => {
            //         // console.log("Sub-category and Date search", res);

            //         if(res.data.data){
            //             this.setState({
            //                 ...this.state,
            //                 OfferDetails: res.data.offerDetails,
            //                 submit: {
            //                     ...this.state.submit,
            //                     valid: true,
            //                 },
            //                 loading: false                         
            //             }); 
                        
            //             if(res.data.offerDetails.length > 1){

            //                 this.setState({
            //                     ...this.state,
            //                     state: true
            //                 })

            //             } else {

            //                 this.setState({
            //                     ...this.state,
            //                     state: false
            //                 })
                            
            //                 this.props.history.push({
            //                     pathname:'/home/offerDetails/OfferIdSearchDetails',
            //                     state:{
            //                         id : this.state.OfferDetails[0].QF_MERCHANT_OFFER_PRID,
            //                         offerLink: '/home/offerDetails',
            //                         offer:'Offer Details'
            //                     }
            //                 });
            //             }              
            //         } else {
            //             if(res.data.dbError){
            //                 this.setState({
            //                     submit:{
            //                         ...this.state.submit,
            //                         valid: false
            //                     },
            //                     loading: false
            //                 })
            //                 toast.error("Internal server error!", {
            //                     position: toast.POSITION.BOTTOM_CENTER
            //                 });
            //             } 
            //                 // console.log("No data found!");                            
            //                 this.setState({
            //                     ...this.state,
            //                     OfferDetails: [],
            //                     submit: {
            //                         ...this.state.submit,
            //                         valid: false
            //                     },
            //                     loading: false
            //                 });
                        
            //         }                   
            //     })
            //     .catch((err) => {
            //         console.log("error", err);
            //         toast.warn("Unable to Reach Server! Please Try Again!", {
            //             position: toast.POSITION.BOTTOM_CENTER
            //         })
            //         this.setState({
            //             submit:{
            //                 ...this.state.submit,
            //                 valid: false
            //             },
            //             loading: false
            //         })
            //     });
            // }
        }
     
      dateHandleChange = (date) => {
        this.setState({
          startDate:{
              ...this.state.startDate,
              value: date,
              valid: true,
              invalid: false
          },
          submit: {
              ...this.state.submit,
              invalid: false,
              Button: false
          }
        });
      }

      onKeyDown=(event)=>{
        if(event.key==='Enter')
        {
            event.preventDefault();
            event.stopPropagation();
            this.submitButtonHandler();
        }
        
    }

    
    
    render() {
            //  console.log("state",this.state);
        //......................added code

            console.log("state",this.state);
            console.log('merchant Name',this.state.merchantName.value)
            const customStyles = {
               option: (provided, state) => ({
                 ...provided,
                 borderBottom: '1px dotted #9277BA',
                 color: state.isSelected ? '#50E3C2' : 'black',
                 padding: 10,
                 textAlign:'left'
               }),
               singleValue: (provided, state) => {
                 const opacity = state.isDisabled ? 0.5 : 1;
                 const transition = 'opacity 300ms';
             
                 return { ...provided, opacity, transition };
               }
             }
           
       let options=[];
       if(this.state.merchantOptions.length !== 0){
           options=this.state.merchantOptions.map((item) => {
                   
               return (
                   {value: item.QF_MERCHANT_PERSONNEL_PRID, label: item.MERCHANT_NAME ? item.MERCHANT_NAME : null}
               )
           })
   
       }
       console.log('data options',options)
       //............

        let categoryOptions = this.state.categories.map((option) => {
            // console.log("Category",option.category_name );
            return <option value={option.qf_service_category_prid} key={option.qf_service_category_prid}>{option.category_name}</option>;
        });

        let subCategoryOptions = this.state.subCategories.map((option) => {
            return <option value={option.qf_service_sub_category_prid} key={option.qf_service_sub_category_prid}>{option.service_sub_category_name}</option> ;
        });

        // let tView=null;
        let result=null;

        // if(this.state.OfferDetails.length !== 0){
        //     tView = <ViewByOfferid OfferDetails={this.state.OfferDetails} state={this.state.state}/>
        // } else 
        if(this.state.OfferDetails.length === 0 || this.state.ServiceDetails.length === 0){
            if(!this.state.loading && this.state.submit.Button)
            {
                result=(<text style={{fontFamily:'Muli'}}>No results to display</text>)
            }
            else
            {
                result=null;
            }
        }

        return (
            <div>
                <Container fluid>
            <div className="row justify-content-center" style={{ backgroundColor: '#EFEFEF'}}>
                <div className="col-3" >
                    <Form style={{width:350}}>
                        {/* <Row>
                            <Col>
                            <FormGroup row>
                            <Label style={{float:'left'}} for="Categories" ><text style={{fontFamily:'Muli'}}>Category</text> <span style={{color: 'red'}}>*</span></Label>
                            <Input  valid={this.state.category.valid ? true : false}
                                    invalid={this.state.category.invalid ? true : false}
                                    value={this.state.category.value ? this.state.category.value:''}
                                    onChange={this.categoryHandler} type="select"
                                    style={{marginRight:3,fontFamily: 'Muli',color: '#563951'}}>
                                   
                                <option value={0}>---Select---</option>
                                {categoryOptions}
                            </Input>
                            <FormFeedback><text style={{fontFamily:'Muli'}}>Category is Required</text></FormFeedback>
                        </FormGroup>
                        </Col>

                        <Col>
                        <FormGroup row>
                            <Label style={{float:'left'}} for="Subcategories" ><text style={{fontFamily:'Muli'}}>Subcategory</text> <span style={{color: 'red'}}>*</span></Label>
                            <Input  valid={this.state.subCategory.valid ? true : false}
                                    invalid={this.state.subCategory.invalid ? true : false}
                                    value={this.state.subCategory.value ? this.state.subCategory.value:''}
                                    onChange={this.subCategoryHandler} type="select" name="select" id="exampleSelect"
                                    style={{marginLeft:3,fontFamily: 'Muli',color: '#563951'}}>
                                <option value={0}>---Select---</option>
                                {subCategoryOptions}
                            </Input>
                            <FormFeedback><text style={{fontFamily:'Muli'}}>Subcategory is Required</text></FormFeedback>
                        </FormGroup>
                        </Col>
                        </Row>
                        <FormGroup>
                        <Row>
                            <Label style={{float:'left'}}><text style={{fontFamily:'Muli'}}> Merchant Name</text></Label>
                        </Row> 
                        <Row>
                            <Col>
                            <Select  
                                isSearchable={true}
                                isClearable
                                required
                                styles={customStyles}
                                width='50%'
                                options={options}
                                onChange={this.merchantNameHandler}
                                valid={this.state.merchantName.valid ? true : false}
                                invalid={this.state.merchantName.invalid ? true : false} 
                                maxLength={30}
                                placeholder="Please enter Merchant Name"
                                onKeyDown={this.onKeyDown}
                                />
                                 {/* this.state.merchantName.invalid? <span style={{color: '#DC143C'}}>Merchant Name is Required</span>:null} */}
                            {/* </Col> */}
                        {/* </Row> */}
                            {/* <Label style={{float:'left'}}><text style={{fontFamily:'Muli'}}> Merchant Name</text></Label>
                            <Input  onChange={this.merchantNameHandler}
                                    valid={this.state.merchantName.valid ? true : false}
                                    invalid={this.state.merchantName.invalid ? true : false} 
                                    value={this.state.merchantName.value}
                                    onKeyDown={this.onKeyDown}
                                    placeholder="Please enter Merchant Name"/>                                     */}
                        {/* </FormGroup> */}

                        {/* <Row>
                        <Col>
                        <FormGroup >
                            <Label for="Service-Date" ><text style={{fontFamily:'Muli'}}>Service Date</text> <span style={{color: 'red'}}>*</span></Label>
                            <DatePicker selected={this.state.startDate.value} 
                                        valid={this.state.startDate.valid ? true : false}
                                        invalid={this.state.startDate.invalid ? true : false}
                                        onChange={this.dateHandleChange}
                                        value={this.state.startDate.value}
                                        dateFormat="DD MMM YYYY"
                                        fixedHeight={true}
                                        isClearable={true}
                                        onKeyDown={this.onKeyDown} />
                                    {this.state.startDate.invalid ? <FormFeedback><text style={{fontFamily:'Muli'}}>Service Date is Mandatory</text></FormFeedback> : null }
                        </FormGroup>
                        </Col>
                        </Row>
                        <Row>
                            <Col>
                            <strong><text style={{fontFamily:'Muli'}}>OR</text></strong>
                            </Col>
                        </Row> */} 
                        
                        <FormGroup>
                        <Label style={{float:'left'}} for="offerid"><text style={{fontFamily:'Muli'}}>Service-Id</text> <span style={{color: 'red'}}>*</span></Label>
                        <Input  onChange={this.ServiceIdHandler}
                                valid={this.state.serviceId.valid ? true : false}
                                invalid={this.state.serviceId.invalid ? true : false} 
                                autoFocus
                                value={this.state.serviceId.value}
                                onKeyDown={this.onKeyDown}
                                placeholder="Please enter Service-Id" />
                        <FormFeedback><text style={{fontFamily:'Muli'}}>Service-Id is Invalid!</text></FormFeedback>
                    </FormGroup>  
                    <Row>
                        <Col>
                        <strong><text style={{fontFamily:'Muli'}}>OR</text></strong>
                        </Col>
                    </Row>
                    
                    <FormGroup>
                        <Label style={{float:'left'}} for="offerid"><text style={{fontFamily:'Muli'}}>Offer-Id</text> <span style={{color: 'red'}}>*</span></Label>
                        <Input  
                                onChange={this.offerIdHandler}
                                valid={this.state.offerId.valid ? true : false}
                                invalid={this.state.offerId.invalid ? true : false} 
                                // autoFocus
                                value={this.state.offerId.value}
                                onKeyDown={this.onKeyDown}
                                placeholder="Please enter Offer-Id" />
                        <FormFeedback><text style={{fontFamily:'Muli'}}>Offer-Id is Invalid!</text></FormFeedback>
                    </FormGroup>        
                    <FormGroup>
                        <Button onClick={this.submitButtonHandler} style={{backgroundColor:"#2C2165", color:'white'}} disabled={this.state.loading?true:false}><text style={{fontFamily:'Muli'}}>Search</text></Button>
                    </FormGroup>
                        <ToastContainer />
                    </Form>
                    <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/>  
            </div>
            


            </div>
            {/* {tView} */}
            </Container>
            {result}
            </div>
        )
    }
} 

export default OfferDetails;