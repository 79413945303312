import React, {Component} from 'react';
import { Table, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom'
import moment from 'moment';

class OfferIdView extends Component {

  tableClickHandler = (id) => {
    // console.log("Props id", id);
    this.props.history.push({
      pathname:this.props.match.url+"/OfferIdSearchDetails",
      state:{
        id
      }
  });
  } 
   

  render() {

    // console.log("The Props", this.props);

    return (
      <Col xl={{size: 10, offset: 1}}>
        <Table  style={{marginTop: 50}} hover hidden={this.props.state ? false : true}>
          <thead style={{backgroundColor:'#2C2165',color:"white", fontFamily:'Muli'}}>
            <tr style={{fontFamily:'Muli'}}>
              <th> Offer Id </th>
              <th> Offer Name </th>
              <th> From </th>
              <th> To </th>
              <th> Offer Price</th>
              <th> Offer Discount </th>
            </tr>
          </thead> 
          <tbody style={{fontFamily:"Muli", color: '#563951',fontWeight:'bold',}}>
            {this.props.OfferDetails.map((offer) => {

              return (

                <tr onClick={() => this.tableClickHandler(offer.QF_MERCHANT_OFFER_PRID)} style={{cursor:'pointer', fontFamily:'Muli'}}>
                <td>{offer.QF_MERCHANT_OFFER_PRID}</td>
                <td>{offer.MERCHANT_OFFER_HEADER_NAME}</td>
                <td>{moment(offer.MERCHANT_OFFER_FROM_DATE_TIME+'Z').tz(offer.MERCHANT_OFFER_TIMEZONE).format("DD MMM YYYY hh:mm A z")}</td>
                <td>{moment(offer.MERCHANT_OFFER_TO_DATE_TIME+'Z').tz(offer.MERCHANT_OFFER_TIMEZONE).format("DD MMM YYYY hh:mm A z")}</td>
                <td>{offer.MERCHANT_OFFER_PRICE ? offer.MERCHANT_OFFER_CURRENCY === "Rupees" ? <span> {'\u20B9'} </span> : offer.MERCHANT_OFFER_CURRENCY === "Dollars" ? <span>$</span>: offer.MERCHANT_OFFER_CURRENCY : null}{offer.MERCHANT_OFFER_PRICE}</td>
                <td>{offer.MERCHANT_OFFER_DISCOUNT}{offer.MERCHANT_OFFER_DISCOUNT ? <span> %</span> : null}</td>
                </tr>

              )
 
            })
            
            }
          </tbody>
        </Table>
      </Col>
      
    );

  }
}

export default withRouter(OfferIdView);