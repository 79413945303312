import React,{Component} from "react";
import {Container,Row,Col,Card,CardHeader,CardText, Button,CardBody, Input, Label,Modal,ModalBody,ModalHeader,ModalFooter} from "reactstrap";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import "../../../node_modules/react-toastify/dist/ReactToastify.css";
import {BarLoader} from "react-spinners"; 
import { withRouter } from 'react-router-dom';
import momenttz from 'moment-timezone';
import moment from 'moment';
import BreadCrumbs from '../Navbar/Breadcrumb';
import SupportDetails from '../SubComponents/supportIdDetailsTableView';
import Curr from 'currency.js';
import {Launcher} from 'react-chat-window'
import { connect } from 'react-redux';

class BookingIdSearch extends Component
{
 
    state={
        OfferId: null,
        consumerId: null,
        AcceptedOfferDetails: [],
        flagUserFName: null,
        flagUserLName: null,
        merchantpayDetailView:[],
        couponDet: [],
        couponType: null,
        CouponApplied: false,
        bId: null,
        loading:true,
        merchantName: null,
        mEmail:null,
        country: null,
        mAdd: [],
        cats: [],
        subCats: [],
        consumer: null,
        mpPrid: null,
        mPrid:null,
        MName: null,
        merchantFlag: false,
        noData: false,
        active: this.props.location.state ? this.props.location.state.active : null,
        active0: this.props.location.state ? this.props.location.state.active0 : null,
        active0Link: this.props.location.state ? this.props.location.state.active0Link : null,
        active1: this.props.location.state ? this.props.location.state.active1 : null,
        active1Link: this.props.location.state ? this.props.location.state.active1Link : null,
        active2: this.props.location.state ? this.props.location.state.active2 : null,
        active2Link: this.props.location.state ? this.props.location.state.active2Link : null,
        active3: this.props.location.state ? this.props.location.state.active3 : null,
        active3Link: this.props.location.state ? this.props.location.state.active3Link : null,
        active4: this.props.location.state ? this.props.location.state.active4 : null,
        active4Link: this.props.location.state ? this.props.location.state.active4Link : null,
        active5: this.props.location.state ? this.props.location.state.active5 : null,
        active5Link: this.props.location.state ? this.props.location.state.active5Link : null,
        active6: this.props.location.state ? this.props.location.state.active6 : null,
        active6Link: this.props.location.state ? this.props.location.state.active6Link : null,
        active7: this.props.location.state ? this.props.location.state.active7 : null,
        active7Link: this.props.location.state ? this.props.location.state.active7Link : null,
        active8: this.props.location.state ? this.props.location.state.active8 : null,
        active8Link: this.props.location.state ? this.props.location.state.active8Link : null,
        active9: this.props.location.state ? this.props.location.state.active9 : null,
        active9Link: this.props.location.state ? this.props.location.state.active9Link : null,
        consumer_data:[],
        user_data:[],
        accepted_data:[],
        MLocation: null,
        SLocation: null,
        url: null,
        SCouponCode:[],
        Sdetails:[],
        supportUserDet:{
            fname: null,
            lname: null
        },
        supportCoupons:[],
        serviceDetails:[],
        refundModal:false,
        refundModal2:false,
        refundModal3:false,
        refundModal4:false,
        refundModal5:false,
        refundButton:false,
        tiketStatus:[],
        serviceId:null,
        refundAfterDays:false,
        canAmountMore:false,
        refund_scenario:'',
        refund_scenario2:'',
        Sdetails2:[],
        consumerAdd:[],
        consumerBookingAdd:[],
        messageArray:[],
        modalToShowMessages: false,
        loginDetails:'',
        p1Location:[],
        p2Location:[],
        p3Location:[],
        p4Location:[]


    }

  componentDidMount(){

    try{

        this.setState({...this.state,loading: true,url: this.props.location.pathname}, async () => {

        const booking_id1 = this.props.location.state ? this.props.location.state.booking_id : this.state.bId
        this.chatFetchHandler(booking_id1)
    
        //Indepedent API calls
        const [ searchBooking, payDBooking, merchantFlagDet ] = await Promise.allSettled([
        axios.post("/support/consumer/searchBookingId",{acceptance_prid:booking_id1}),
        axios.post("/support/merchant/payDBookingId",{acceptance_prid:booking_id1}),
        axios.post("/support/consumer/merchantFlag/check",{booking_id:booking_id1}),
        ])
        //Dependent API calls
        const [ ConsumerSearchDetails, merchantname, subcategory ] = await Promise.all([
            axios.post("/support/consumer/ConsumerSearchDetails",{consumer_prid:searchBooking.value.data.acceptedData[0].QF_CONSUMER_PRID}),
            axios.post("/support/consumer/merchantname/merchantPersonnelToMerchantName",{mPPrid:searchBooking.value.data.acceptedData[0].QF_MERCHANT_PERSONNEL_PRID}),
            axios.post("/support/consumer/subcategory/pridToName",{scPrid:searchBooking.value.data.acceptedData[0].offersubId || searchBooking.value.data.acceptedData[0].servicesubId}),
        ])
        //Any server side error is being checked
        if(searchBooking.value.data.dbError || payDBooking.value.data.dbError || merchantFlagDet.value.data.dbError || ConsumerSearchDetails.data.dbError || merchantname.data.dbError || subcategory.data.data.dbError ){  
            this.setState({
                ...this.state, 
                AcceptedOfferDetails:[],
                consumer_data:[],
                merchantName:[],
                merchantFlag:[],
                subCats:[],
                cats:[],
                loading: false, 
            });
            return toast.error("Internal Server Error", {position: toast.POSITION.BOTTOM_CENTER});
        }else{
        
        //filtering disputes as well as pending Tickets
        let Sdetails2 = []
            Sdetails2 = searchBooking.value.data.searchB && searchBooking.value.data.results_supp.filter((each)=>{return each.CONSUMER_SUPPORT_CATEGORY=="Dispute" || each.CONSUMER_SUPPORT_CATEGORY=="Consumer refund request"})
        let pending = searchBooking.value.data.searchB ? searchBooking.value.data.results_supp : []
        let pendingLength = pending.filter((each,index)=>{return each.CONSUMER_SUPPORT_STATUS ==1})  
        this.setState({
    
                    ...this.state,
                        //Booking Details
                        tiketStatus:pendingLength,
                        bId: booking_id1,
                        AcceptedOfferDetails: searchBooking.value.data.acceptedData,
                        OfferId: searchBooking.value.data.acceptedData[0].QF_MERCHANT_OFFER_PRID || null,
                        serviceId:searchBooking.value.data.acceptedData[0].QF_MERCHANT_SERVICE_PRID || null,
                        CouponApplied: searchBooking.value.data.couponApplied,
                        couponDet: searchBooking.value.data.couponDet || [],
                        couponType: searchBooking.value.data.couponType || null,
                        mpPrid : searchBooking.value.data.acceptedData[0].QF_MERCHANT_PERSONNEL_PRID,
                        SCouponCode: searchBooking.value.data.results_coupon || [],
                        Sdetails: searchBooking.value.data.results_supp || [],
                        serviceDetails: searchBooking.value.data.serviceDetails || [],
                        MName:searchBooking.value.data.couponDet && searchBooking.value.data.couponDet[0].MERCHANT_NAME,
                        flagUserFName : searchBooking.value.data.acceptedData.length ? searchBooking.value.data.acceptedData[0].SUPPORT_USER_FNAME : null,
                        flagUserLName: searchBooking.value.data.acceptedData.length  ? searchBooking.value.data.acceptedData[0].SUPPORT_USER_LNAME : null ,
                        supportUserDet:{
                        ...this.state.supportUserDet,
                                fname: searchBooking.value.data.resultDet ? searchBooking.value.data.resultDet[0].SUPPORT_USER_FNAME : null,
                                lname: searchBooking.value.data.resultDet ? searchBooking.value.data.resultDet[0].SUPPORT_USER_LNAME : null
                        },
                        Sdetails2:Sdetails2,
                        consumerBookingAdd:searchBooking.value.data.userAddRes,
                        //consumer Details
                        consumer_data:ConsumerSearchDetails.data.searchDet?  ConsumerSearchDetails.data.consumerDet:[],
                        user_data:ConsumerSearchDetails.data.userDet.length  ? ConsumerSearchDetails.data.userDet : [],
                        accepted_data:ConsumerSearchDetails.data.accepted_data.length  ? ConsumerSearchDetails.data.accepted_data :[],
                        consumerAdd:ConsumerSearchDetails.data.adressUsers.length ?ConsumerSearchDetails.data.adressUsers:[],
                        loginDetails:ConsumerSearchDetails.data.loginDetails.length ?ConsumerSearchDetails.data.loginDetails:[],
                        //merchant name
                        merchantName: merchantname.data.mNameDetails[0].MERCHANT_NAME,
                        country: merchantname.data.mNameDetails[0].MERCHANT_ADDRESS_COUNTRY,
                        mPrid: merchantname.data.mNameDetails[0].QF_MERCHANT_PRID,
                        mEmail:merchantname.data.mNameDetails[0].MERCHANT_CONTACT_EMAIL,
                        mAdd: merchantname.data.mNameDetails,
                        //merchant flag Details
                        merchantFlag: merchantFlagDet.data &&  merchantFlagDet.data.flaged ? true : false,
                        //subcategory
                        cats:  subcategory.data.data? subcategory.data.cats || []:[],
                        subCats:subcategory.data.data? subcategory.data.subCats || []:[],
                        //payment Details
                        merchantpayDetailView: payDBooking.value.data.data?payDBooking.value.data.merchantD || []:[],
                        loading:false,
                    })
          }
         })
        }catch(err) 
         {
            console.log(err)
            toast.warn("Unable to Reach Server! Please Try Again!", {position: toast.POSITION.BOTTOM_CENTER});
            this.setState({loading:false})
         }
   
        //     axios.post("/support/consumer/searchBookingId",{acceptance_prid:booking_id})
        //    .then(res=>{

        //     if(res.data.searchB) 
            
        //     {

        //         let Sdetails2=[]
        //             Sdetails2=res.data.results_supp.filter((each)=>{
        //             return each.CONSUMER_SUPPORT_CATEGORY == "Dispute"|| each.CONSUMER_SUPPORT_CATEGORY == "Consumer refund request"
        //           })

        //         let pending=res.data.results_supp || []
        //         let pendingLength=pending.filter((each,index)=>{
        //             return each.CONSUMER_SUPPORT_STATUS == 1;
        //         })
        //         this.setState({
        //             tiketStatus:pendingLength,
        //             bId: booking_id,
        //             AcceptedOfferDetails: res.data.acceptedData,
        //             OfferId: res.data.acceptedData[0].QF_MERCHANT_OFFER_PRID || null,
        //             serviceId:res.data.acceptedData[0].QF_MERCHANT_SERVICE_PRID || null,
        //             CouponApplied: res.data.couponApplied,
        //             couponDet: res.data.couponDet || [],
        //             couponType: res.data.couponType || null,
        //             mpPrid : res.data.acceptedData[0].QF_MERCHANT_PERSONNEL_PRID,
        //             SCouponCode: res.data.results_coupon || [],
        //             Sdetails: res.data.results_supp || [],
        //             serviceDetails: res.data.serviceDetails || [],
        //             MName: res.data.couponDet && res.data.couponDet[0].MERCHANT_NAME,
        //             flagUserFName : res.data.acceptedData.length  ? res.data.acceptedData[0].SUPPORT_USER_FNAME : null,
        //             flagUserLName: res.data.acceptedData.length  ? res.data.acceptedData[0].SUPPORT_USER_LNAME : null ,
        //             supportUserDet:{
        //                 ...this.state.supportUserDet,
        //                 fname: res.data.resultDet ? res.data.resultDet[0].SUPPORT_USER_FNAME : null,
        //                 lname: res.data.resultDet ? res.data.resultDet[0].SUPPORT_USER_LNAME : null
        //             },
        //             Sdetails2:Sdetails2,
        //             consumerBookingAdd:res.data.userAddRes,
                    

        //         });
                

                
        //             let mPPrid = res.data.acceptedData[0].QF_MERCHANT_PERSONNEL_PRID;
        //             let scPrid = res.data.acceptedData[0].offersubId?res.data.acceptedData[0].offersubId:res.data.acceptedData[0].servicesubId;
        //             let consumer_prid = this.state.AcceptedOfferDetails[0].QF_CONSUMER_PRID;

        //             axios.post("/support/consumer/ConsumerSearchDetails",{consumer_prid})
        //             .then(res4=>{

        //                 if(res4.data.searchDet)
        //                 {
        //                     this.setState({
        //                         ...this.state,
        //                         consumer_data:res4.data.consumerDet,
        //                         user_data:res4.data.userDet.length  ? res4.data.userDet : [],
        //                         accepted_data:res4.data.accepted_data.length  ? res4.data.accepted_data :[],
        //                         consumerAdd:res4.data.adressUsers.length ?res4.data.adressUsers:[],
        //                         loginDetails:res4.data.loginDetails.length ?res4.data.loginDetails:[],
        //                     });

        //                     axios.post("/support/consumer/merchantname/merchantPersonnelToMerchantName", {mPPrid})
        //                     .then((res1) => {
        //                         if(res1.data.data){
        //                             this.setState({
        //                                 ...this.state,
        //                                 merchantName: res1.data.mNameDetails[0].MERCHANT_NAME,
        //                                 country: res1.data.mNameDetails[0].MERCHANT_ADDRESS_COUNTRY,
        //                                 mPrid: res1.data.mNameDetails[0].QF_MERCHANT_PRID,
        //                                 mEmail:res1.data.mNameDetails[0].MERCHANT_CONTACT_EMAIL,
        //                                 mAdd: res1.data.mNameDetails
        //                             })

        //                             axios.post("/support/consumer/merchantFlag/check", {booking_id})
        //                             .then((res) => {
        //                                 if(res.data.data){

        //                                     res.data.flaged  &&  this.setState({ ...this.state,merchantFlag: true })
                                           
        //                                     axios.post("/support/consumer/subcategory/pridToName", {scPrid})
        //                                     .then((res2) => {
        //                                         if(res2.data.data){
        //                                             this.setState({
        //                                                 ...this.state,
        //                                                 cats: res2.data.cats ? res2.data.cats : [],
        //                                                 subCats: res2.data.subCats ? res2.data.subCats : [],
        //                                                 loading:false
        //                                             });
        //                                         } else {
        //                                             if(res2.data.dbError){

        //                                                 this.setState({
        //                                                     ...this.state,
        //                                                     loading: false                 
        //                                                 });
                                                        
        //                                                 toast.error("Internal Server Error", {
        //                                                     position: toast.POSITION.BOTTOM_CENTER
        //                                                 });
        //                                             } else {
        //                                                 this.setState({
        //                                                     ...this.state,
        //                                                     cats: [],
        //                                                     subCats: [],
        //                                                     loading: false              
        //                                                 });
        //                                             }
        //                                         }                       
        //                                     })
        //                                     .catch((err) => {
        //                                         console.log(err);
        //                                         toast.warn("Unable to Reach Server! Please Try Again!", {
        //                                             position: toast.POSITION.BOTTOM_CENTER
        //                                         });
        //                                         this.setState({loading:false})
        //                                     });
        //                                 } else{
        //                                     if(res.data.dbError){
        //                                         this.setState({
        //                                             ...this.state,
        //                                             loading: false               
        //                                         });
        //                                         toast.error("Internal Server Error", {
        //                                             position: toast.POSITION.BOTTOM_CENTER
        //                                         });
        //                                     } else {
        //                                         toast.info("Data not found", {
        //                                             position: toast.POSITION.BOTTOM_CENTER
        //                                         });                
        //                                     }
        //                                 }
        //                             })
        //                             .catch((err) => {
        //                                 console.log(err);
        //                                 toast.warn("Unable to Reach Server! Please Try Again!", {
        //                                     position: toast.POSITION.BOTTOM_CENTER
        //                                 });
        //                                 this.setState({loading:false})
        //                             });
        //                         } else {
        //                             if(res1.data.dbError){
        //                                 this.setState({
        //                                     ...this.state,
        //                                     offerIdDetails: [],
        //                                     loading: false                 
        //                                 });
        //                                 toast.error("Internal Server Error", {
        //                                     position: toast.POSITION.BOTTOM_CENTER
        //                                 });
        //                             } else {
        //                                 this.setState({
        //                                     ...this.state,
        //                                     offerIdDetails: [],
        //                                     merchantName: null                 
        //                                 });
                                        
        //                             }
        //                         }
        //                     })
        //                     .catch((err) => {
        //                         console.log(err);
        //                         toast.warn("Unable to Reach Server! Please Try Again!" , {
        //                         position: toast.POSITION.BOTTOM_CENTER
        //                         });
        //                         this.setState({loading:false})
        //                     });
        //                 }
        //                 else
        //                 {
        //                     if(res4.data.dbError)
        //                                 {
        //                                     toast.error("Internal Server Error",{position:toast.POSITION.BOTTOM_CENTER});

        //                                 }
        //                                 else {
        //                                     this.setState({
        //                                         ...this.state,
        //                                         loading:false
        //                                     });
        //                                 } 
        //                 }

        //             }).catch(err=>{
        //                 console.log(err);
        //                 toast.warn("Unable to Reach Server! Please Try Again!",{position:toast.POSITION.BOTTOM_CENTER});
        //                 this.setState({loading:false})
        //             })
        
        
        //        axios.post("/support/merchant/payDBookingId",{acceptance_prid:booking_id})
        //       .then((res67) => {
        //         // console.log("respone67",res67.data);
        //         if(res67.data.data){

        //          if(res67.data.merchantD.length){
        //             this.setState({
        //                 ...this.state,
        //                    merchantpayDetailView:res67.data.merchantD})
        //          }
        //          if(!res67.data.merchantD.length){
        //             this.setState({
        //                 ...this.state,
        //                    merchantpayDetailView:[]})
        //         }
                
        //     }
        //     else {
        //         if(res67.data.dbError){

        //             this.setState({
        //                 ...this.state,
        //                 loading: false                 
        //             });
                    
        //             toast.error("Internal Server Error", {
        //                 position: toast.POSITION.BOTTOM_CENTER
        //             });
        //         } else {
        //             this.setState({
        //                 ...this.state,
        //                 merchantpayDetailView: [],
        //                 loading: false              
        //             });
        //         }
        //     }
        //   }).catch(err67=>{
        //     console.log(err67);
        //     toast.warn("Unable to Reach Server! Please Try Again!",{position:toast.POSITION.BOTTOM_CENTER});
        //     this.setState({loading:false})
        // });


        //     }
        //     else
        //     {
        //         if(res.data.dbError)
        //                     {
        //                         this.setState({
        //                             ...this.state,
        //                             loading: false                 
        //                         });
        //                         toast.error("Internal Server Error",{position:toast.POSITION.BOTTOM_CENTER});

        //                     }
        //         this.setState({
        //             AcceptedOfferDetails: [],
        //             AcceptedOfferDetails: [],
        //             // PaymentDetails: [],
        //             CouponApplied: [],
        //             couponDet: [],
        //             SCouponCode: [],
        //             Sdetails: [],
        //             serviceDetails:[],
                    
        //             loading:false,
        //             noData: true
        //         })
        //     }
        // }).catch(err=>{
        //     console.log(err);
        //     toast.warn("Unable to Reach Server! Please Try Again!",{position:toast.POSITION.BOTTOM_CENTER});
        //     this.setState({loading:false})
        // });

        
         }



        

    flagMerchantHandler = (id) => {
        let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
        let booking_id = id;
        axios.post("/support/consumer/merchantFlag/check", {booking_id})
        .then((res) => {
            if(res.data.data){
                if(res.data.flaged){
                    this.setState({
                        ...this.state,
                        merchantFlag: true
                    });
                    toast.info("Professional is already flagged for this Booking Id",{
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                } else {
                    this.setState({
                        ...this.state,
                        merchantFlag: false
                    });
                    this.props.history.push({
                        pathname:url+'/FlagMerchant',
                        state:{
                            id: id,
                            url,
                            mname: this.state.merchantName,
                            mpPrid: this.state.mpPrid,
                            active: this.state.active,
                            active0: this.state.active0,
                            active0Link: this.state.active0Link,
                            active1: this.state.active1,
                            active2: this.state.active2,
                        }
                    });
                }
            } else{
                if(res.data.dbError){
                    this.setState({
                        ...this.state,
                        loading: false                
                    });
                    toast.error("Internal Server Error", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                } else {
                    toast.info("Data not found", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });                    
                }
            }
        })
        .catch((err) => {
            toast.warn("Unable to Reach Server! Please Try Again!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({loading:false})
        });

       
    }

    createCouponHandler = (id,netPrice) => {
        let url=this.props.location.state.url ? this.props.location.state.url : this.props.match.url;
        this.props.history.push({ 
            pathname: url+'/supportCreateCoupon',
            state:{
                id: id,
                netPrice:netPrice,
                name:this.state.consumer_data[0].CONSUMER_FNAME,
                email:this.state.consumer_data[0].CONSUMER_EMAIL,
                amountSign:this.state.AcceptedOfferDetails[0].MERCHANT_OFFER_CURRENCY?this.state.AcceptedOfferDetails[0].MERCHANT_OFFER_CURRENCY:this.state.AcceptedOfferDetails[0].SERVICE_CURRENCY,
                catPrid: this.state.cats.length ? this.state.cats[0].qf_service_category_prid : null,
                subCatPrid: this.state.subCats.length ? this.state.subCats[0].qf_service_sub_category_prid : null,
                merchantCountry:this.state.mAdd[0].MERCHANT_ADDRESS_COUNTRY, 
                cityPrid: null,
                active: this.state.active,
                active0: this.state.active0, 
                active0Link: this.state.active0Link,
                active1: this.state.active1,
                active2: this.state.active2,
                url
            }
        });
    }

    CreateTicketHandler = (id,merchPersnelPrid) => {

        const { AcceptedOfferDetails : AOD }=this.state

        // return console.log('output',id,merchPersnelPrid)
        let url=this.props.location.state.url ? this.props.location.state.url : this.props.match.url;
        this.props.history.push({
            pathname:url+'/CreateTicket',
            state:{
                 
                merchEmail:this.state.mEmail,
                consumerName:this.state.consumer_data[0].CONSUMER_FNAME,
                merchName:this.state.merchantName,    
                offerId: this.state.OfferId,
                serviceId:this.state.serviceId,
                duration:AOD[0].SERVICE_DURATION_IN_MINS?AOD[0].SERVICE_DURATION_IN_MINS:null,
                service:AOD[0].serviceName?true:false,
                offerName: AOD[0].MERCHANT_OFFER_HEADER_NAME ? AOD[0].MERCHANT_OFFER_HEADER_NAME:AOD[0].REC_SERVICE_NAME,
                offerDate:AOD[0].MERCHANT_OFFER_FROM_DATE_TIME ? 
                momenttz(AOD[0].MERCHANT_OFFER_FROM_DATE_TIME+"Z").tz(AOD[0].MERCHANT_OFFER_TIMEZONE).format('DD MMM YYYY')
                :
                momenttz(AOD[0].BOOKING_FROM_DATE_TIME+"Z").tz(AOD[0].SERVICE_TIMEZONE).format('DD MMM YYYY')
                ,
                offerDateFrom:
                AOD[0].MERCHANT_OFFER_FROM_DATE_TIME ? 
                momenttz(AOD[0].MERCHANT_OFFER_FROM_DATE_TIME+"Z").tz(AOD[0].MERCHANT_OFFER_TIMEZONE).format('hh:mm A') 
                :  
                momenttz(AOD[0].BOOKING_FROM_DATE_TIME+"Z").tz(AOD[0].SERVICE_TIMEZONE).format('hh:mm A') 
                ,
                offerDateTo:  AOD[0].MERCHANT_OFFER_TO_DATE_TIME ? 
                momenttz(AOD[0].MERCHANT_OFFER_TO_DATE_TIME+"Z").tz(AOD[0].MERCHANT_OFFER_TIMEZONE).format('hh:mm A z') 
                :
                momenttz(AOD[0].BOOKING_TO_DATE_TIME+"Z").tz(AOD[0].SERVICE_TIMEZONE).format('hh:mm A z') 
                ,
                bookingDate:AOD[0].CONSUMER_PAYMENT_PROCESSED_ON_DATE ? 
                momenttz(AOD[0].CONSUMER_PAYMENT_PROCESSED_ON_DATE+"Z").tz(AOD[0].QF_MERCHANT_SERVICE_PRID!=null?AOD[0].SERVICE_TIMEZONE:AOD[0].MERCHANT_OFFER_TIMEZONE).format('DD MMM YYYY hh:mm A z') 
                :null, 
                offercurrency:AOD[0].MERCHANT_OFFER_CURRENCY?AOD[0].MERCHANT_OFFER_CURRENCY:AOD[0].SERVICE_CURRENCY,
                consumerId: AOD[0].QF_CONSUMER_PRID,
                lang:AOD[0].QF_MERCHANT_PERSONNEL_LANG,
                id: id,
                merchPersnelPrid:merchPersnelPrid,
                active: this.state.active,
                active0: this.state.active0,
                active0Link: this.state.active0Link,
                active1: this.state.active1,
                active2: this.state.active2,
                url
            }
        });
    }
    MerchantDetailsHandler=(id)=>{
        let url=this.props.location.state.url ? this.props.location.state.url : this.props.match.url;

        this.props.history.push({
            pathname: url+'/MerchantSearchDetails',
            state:{
                id: id,
                active: this.state.active,
                active0: this.state.active0,
                active0Link: this.state.active0Link,
                active1: this.state.active1,
                active2: this.state.active2,
                url
             
        }});
      
    }
    
offerDetailsHandler=()=>{
    let url= this.props.location.state.url? this.props.location.state.url : this.props.match.url;
        this.props.history.push({
            pathname:url+'/OfferIdSearchDetails',
            state:{
                condata:this.props.location.state.condata,
                id: this.state.OfferId,
                url,
                active: this.state.active,
                active0: this.state.active0,
                active0Link: this.state.active0Link,
                active1: this.state.active1,
                active1Link: this.state.active1Link,
                active2: this.state.active2 ,
                active2Link: this.state.active2Link,
                active3: this.state.active3,
                active3Link: this.state.active3Link,
                active4: this.state.active4,
                active4Link: this.state.active4Link,
                active5: this.state.active5,
                active5Link: this.state.active5Link,
                active6: this.state.active6,
                active6Link: this.state.active6Link,
                active7: this.state.active7,
                active7Link: this.state.active7Link ,
                active8: this.state.active8 ,
                active8Link: this.state.active8Link,
                active9: this.state.active9,
                active9Link: this.state.active9Link,            
        }});
    }
    
couponDetailsHandler=()=>{

    let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
    
        this.props.history.push({
            pathname:url+'/CouponSearchDetails',
            state:{
                MName: this.state.MName,
                country: this.state.country,
                tz:this.state.AcceptedOfferDetails[0].QF_MERCHANT_SERVICE_PRID!=null?this.state.AcceptedOfferDetails[0].SERVICE_TIMEZONE:this.state.AcceptedOfferDetails[0].MERCHANT_OFFER_TIMEZONE,
                merchant: this.state.couponType !== null ? this.state.couponType === 'mCoupon' ? true : false : false,
                our: this.state.couponType !== null ? this.state.couponType === 'oCoupon' ? true : false : false,
                supportCoupon: this.state.couponType !== null ? this.state.couponType === 'sCoupon' ? true : false : false,
                coupon_code: this.state.couponDet,
                active: this.state.active,
                active0: this.state.active0,
                active0Link: this.state.active0Link,
                active1: this.state.active1,
                active2: this.state.active2,
                url
             
        }});
    }
    serviceDetailsHandler = (id) => {
        let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
        this.props.history.push({
            pathname:url+"/ServiceDetails",
            state:{
                id: this.state.serviceId,
                url             
        }});
    }

   
   


    refundModalControl = (refund_scenario,desc) => {
       
        
     this.setState({

        refundModal:false,
        refundModal2:false,
        refundModal3:false,
        refundModal4:false,
        refundModal5:false
     });

     
     //Service is consumed:
     const refundDetails={
         acceptance_prid:this.state.AcceptedOfferDetails[0].QF_CONSUMER_OFFER_ACCEPTED_PRID,
         consumer_id:this.state.AcceptedOfferDetails[0].QF_CONSUMER_PRID,
         refund_scenario:this.state.refund_scenario.length!==0?this.state.refund_scenario:refund_scenario,
         fullRefund:desc==="full"?true:false,
         partialRefund:desc==="partial"?true:false,
         refundAfterDays:this.state.refundAfterDays,
         canAmountMore:this.state.canAmountMore,
         refund_scenario_2:this.state.refund_scenario2,
         supportPrid:this.props.id?this.props.id:null
     }


        if(this.state.AcceptedOfferDetails[0].PAYMENT_PROCESSED_CURRENCY==="INR")
        {
            axios.post("/payment/refund/support",refundDetails)
            .then((resFromRefund)=>{
    
                if(resFromRefund.data.refundStatus)
                {
                    this.setState({refundModal:false,refundButton:true});
                    toast.info("Refund Successful", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    setTimeout(()=> {
                        window.location.reload();
                      }, 3000);
                      
                }
                else
                {
                    if(resFromRefund.data.dbError)
                    {
                        this.setState({refundModal:false, loading: false});
                        toast.error("Internal server error", {
                            position: toast.POSITION.BOTTOM_CENTER
                        })
                    }    
                    else if(resFromRefund.data.refund)
                    {
                        this.setState({refundModal:false,refundButton:true});
                        toast.info("Refund Successful. However, something went wrong.", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    }
                    else
                    {
                        this.setState({refundModal:false});
                        toast.info("Not Refunded.", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    }
                    
                }
    
            }).catch((errFromRefund)=>{
                console.log(errFromRefund);
                toast.warn("Unable to Reach Server! Please Try Again!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.setState({loading:false})
            })
        }
        else if(this.state.AcceptedOfferDetails[0].PAYMENT_PROCESSED_CURRENCY==="usd")
        {
            axios.post("/support/refunds/stripe/v3",refundDetails)
        .then((resFromRefund)=>{

            if(resFromRefund.data.sRefund)
            {
                this.setState({refundModal:false,refundButton:true});
                toast.info("Refund Successful", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                setTimeout(()=> {
                    window.location.reload();
                  }, 4000);
            }
            else
            {
                if(resFromRefund.data.refund)
                {
                    this.setState({refundModal:false,refundButton:true, loading: false});
                    toast.error("Internal server error. Refund successfull", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    setTimeout(()=> {
                        window.location.reload();
                      }, 4000);
                }
                else if(resFromRefund.data.refundAfterDaysCheck)
                {
                    this.setState({refundModal4:true,refundModal2:false,refundAfterDays:true,refund_scenario:'service_not_consumed_can_allowed_partial',refund_scenario2:'can_allowed_refund_after_days', loading: false});
                    
                }
                else if(resFromRefund.data.canAmountMoreCheck)
                {

                    this.setState({refundModal5:true,refundModal2:false,canAmountMore:true,refund_scenario:'service_not_consumed_can_allowed_partial',refund_scenario2:'can_allowed_amount_more_refund', loading: false});
                  
                }
                else if(resFromRefund.data.dbError)
                {
                    this.setState({refundModal:false, loading: false});
                    toast.error("Internal server error", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                }
                else
                {
                    this.setState({refundModal:false});
                    toast.info("Not Refunded", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    
                }
                
            }

        }).catch((errFromRefund)=>{
            console.log(errFromRefund);
            toast.warn("Unable to Reach Server! Please Try Again!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            this.setState({loading:false})
        })
        }
        else
        {

                toast.error("Unable to determine payment gateway to process the refund,", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }


        
    }
    disputesHandler=()=>{
        let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
    
        let disputeCount=this.state.accepted_data.filter((disputeCount)=>{
            return disputeCount.SERVICE_DISPUTE == 1;
          })
        let disputeFiltered=disputeCount.map((each,k)=>{
            return k=each 
        }) 
        this.props.history.push({
          pathname: url+'/Disputes',
          state:{
            accepted_data:disputeFiltered,
           consumer_prid:this.state.AcceptedOfferDetails[0].QF_CONSUMER_PRID,
            url
          }
    
        })
        
    }

    cancelledHandler = () =>{
        let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
    
        let cancelledCount=this.state.accepted_data.filter((canlcelledCount)=>{
            return canlcelledCount.OFFER_CANCELLED == 1 && canlcelledCount.OFFER_ACCEPTED == 0;
          })
        let cancelFiltered=cancelledCount.map((each,k)=>{
            return k=each 
        }) 
        this.props.history.push({
          pathname: url+'/Cancellations',
          state:{
            accepted_data:cancelFiltered,
            consumer_prid:this.state.AcceptedOfferDetails[0].QF_CONSUMER_PRID,
            url 
          }
    
        })
    }
    bookingsHandler = () => {
        let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
        this.props.history.push({
            pathname: url+'/ConsumerBookings',
            state:{
              accepted_data:this.state.accepted_data,
              consumer_prid:this.state.AcceptedOfferDetails[0].QF_CONSUMER_PRID,
              url 
            }
      
          })

    }
    paymentMerchHandler = (id) =>{

        let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
        this.props.history.push({
            pathname: url+'/bookingView',
            state:{
                selectedTrans:this.state.merchantpayDetailView,
                tz:this.state.AcceptedOfferDetails[0].QF_MERCHANT_SERVICE_PRID!=null?this.state.AcceptedOfferDetails[0].SERVICE_TIMEZONE:this.state.AcceptedOfferDetails[0].MERCHANT_OFFER_TIMEZONE,
               url 
            }
      
          })

    }

    bookingsConsumedHandler = () =>{
        
        let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
        let consumedCount=this.state.accepted_data.filter((canlcelledCount)=>{
            return canlcelledCount.OFFER_CONSUMED == 1;
          })
        let consumedFiltered=consumedCount.map((each,k)=>{
            return k=each 
        }) 
        this.props.history.push({
            pathname: url+'/BookingsConsumed',
            state:{
              accepted_data:consumedFiltered,
              consumer_prid:this.state.AcceptedOfferDetails[0].QF_CONSUMER_PRID,
              url 
            }
      
          })


    }
   consumerPaymentHanler = () => {
    
       let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
       this.props.history.push({
         pathname: url+'/consumerpayments',
         state:{
          consumer_prid:this.state.AcceptedOfferDetails[0].QF_CONSUMER_PRID,
          url 
        }
  
      })
}
    noofBookingDisplayHandler = (offerId) => {
      
        let url=this.props.location.state.url ? this.props.location.state.url : this.props.match.url
        axios.post('/support/offer/allBookingDetails', {offerId})
        .then((res) => {
            if(res.data.data){
                if(res.data.result.length ){
                    this.setState({
                        ...this.state,
                        bookingDetails: res.data.result
                    })

                    this.props.history.push({
                        pathname: url+'/BookingsTabView',
                        state:{
                          accepted_data: this.state.bookingDetails,
                          url,
                          offerId
                        }
                  
                      })
                }
            }
        })
        .catch((err) => {
            console.log("Error", err);
        })
    }
    bookingsViewHandler = () => {
      
             let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
             this.props.history.push({
             pathname: url+'/allBookings',
             state:{
                consumer_prid:this.state.AcceptedOfferDetails[0].QF_CONSUMER_PRID,   
                consumer_name:this.state.consumer_data[0].CONSUMER_FNAME?this.state.consumer_data[0].CONSUMER_FNAME:this.state.consumer_data[0].CONSUMER_EMAIL?this.state.consumer_data[0].CONSUMER_EMAIL:'Not Updated',      
                url 
             }
             })
    }    

    
    earningsViewHandler = () => {
        
        let url=this.props.location.state.url?this.props.location.state.url:this.props.match.url;
        this.props.history.push({
            pathname: url+'/earnedCoupons',
            state:{
                consumer_prid:this.state.AcceptedOfferDetails[0].QF_CONSUMER_PRID,
                url 
            }
      
          })
    
    }

    chatFetchHandler = (bid) => {
        
        axios.post('/support/firebase/test', {bookinId:bid})
        .then( res => {
               res.data && this.setState({messageArray: res.data.newMessageArrray,modalToShowMessages: false})
        })
        .catch((c1) => {
            console.log(c1)
        })
    }

    render()
    {

        console.log('CONSOLE',this.state)



        // console.log('p1',this.state.p1Location)
        // console.log('p2',this.state.p2Location)
        // console.log('p3',this.state.p3Location)
        // console.log('p4',this.state.p4Location)
       
        // console.log('state',this.state.AcceptedOfferDetails)
        // console.log('state1',this.state.p1Location)


    const { AcceptedOfferDetails : AccOffDet } = this.state;

    const rowStyle={
        textAlign:"initial"
    }
    const fWeightSpan={
        fontWeight:"500",
        marginRight:5,
        fontFamily: 'Muli',
        color: '#868686'
    }
    const fontText = {
        fontWeight:'bold',
        fontFamily: 'Muli',
        color: '#563951'          
    }
    const fontText1 = {
        fontWeight:'bold',
        fontFamily: 'Muli',
        color: '#563951'            
    }
    const LinkedText = {
        textDecoration:'underline',
        cursor:'pointer', 
        color: '#302939', 
        fontWeight:'bold',
        textTransform: 'uppercase',
        fontFamily: 'Muli',
        color: '#563951'
    }
    const textDecor = {
        fontWeight:"bold",
        fontFamily: 'Muli',
        color: '#563951'
    }
    let Sdetails2=[];
    let cd=this.state.consumer_data,ud=this.state.user_data,ad=this.state.accepted_data;


      let offerAcceptFilter=ad.map((each,k)=>{
       return k=each
      }
      )
      let offerAcceptedfiltered=offerAcceptFilter.length
  
      let offerCancelMap= ad.filter((offerCancelCount,i=0)=>{
          return offerCancelCount.OFFER_CANCELLED===1;
        })
        let offerCancelFilter=offerCancelMap.map((each,k)=>{
         return k=each
        }
        )
        let offerCancelfiltered=offerCancelFilter.length
    
        let offerConsumeMap= ad.filter((offerConsumeCount,i=0)=>{
          return offerConsumeCount.OFFER_CONSUMED===1;
        })
        let offerConsumeFilter=offerConsumeMap.map((each,k)=>{
         return k=each
        }
        )
        let offerConsumefiltered=offerConsumeFilter.length

        let disputeCount=ad.filter((disputeCount)=>{
            return disputeCount.SERVICE_DISPUTE===1;
          })
        let disputeFiltered=disputeCount.map((each,k)=>{
            return k=each
        })
        let finalDispCount=disputeFiltered.length

 

        let refundModal=null;
        let refundModal2=null;
        let refundModal3=null;
        let refundModal4=null;
        let refundModal5=null;


            
        if(AccOffDet.length)
        {
         refundModal = (
        <Modal isOpen={this.state.refundModal}>
        <ModalHeader color="warning">Warning</ModalHeader>
          <ModalBody>
                {AccOffDet[0].PIN_VALIDATED ? "Service is already consumed. Do you want to give full refund?":null}
                
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={()=>this.refundModalControl("service_consumed_full",'full')}>Yes</Button>{' '}
            <Button onClick={()=>this.setState({refundModal:false})}>No</Button>
          </ModalFooter> 
        </Modal>
        );
        }

        if(AccOffDet.length)
        {
         refundModal2 = (
        <Modal isOpen={this.state.refundModal2}>
        <ModalHeader color="warning">Warning</ModalHeader>
          <ModalBody>
          Cancellation is allowed. Do you want to apply cancellation policy of professional?      
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={()=>this.refundModalControl("service_not_consumed_can_allowed_partial",'partial')}>Apply cancellation policy</Button>{' '}
            <Button color="info" onClick={()=>this.refundModalControl("service_not_consumed_can_allowed_full",'full')}>Full Refund</Button>{' '}
            
            <Button onClick={()=>this.setState({refundModal2:false})}>Cancel</Button>

          </ModalFooter> 
        </Modal>
        );
        }

        if(AccOffDet.length)
        {
         refundModal3 = (
        <Modal isOpen={this.state.refundModal3}>
        <ModalHeader color="warning">Warning</ModalHeader>
          <ModalBody>
          Cancellation is not allowed for this service. Do you want to give full refund?      
          </ModalBody>
          <ModalFooter>
          <Button color="danger" onClick={()=>this.refundModalControl("service_not_consumed_can_not_allowed_full",'full')}>Yes</Button>{' '}
            <Button onClick={()=>this.setState({refundModal3:false})}>No</Button>
          </ModalFooter> 
        </Modal>
        );
        }

        if(AccOffDet.length)
        {
         refundModal4 = (
        <Modal isOpen={this.state.refundModal4}>
        <ModalHeader color="warning">Warning</ModalHeader>
          <ModalBody>
          Cancelation days has been passed. Do you want to issue full refund?      
          </ModalBody>
          <ModalFooter>
          <Button color="danger" onClick={()=>this.refundModalControl("can_allowed_refund_after_days",'full')}>Yes</Button>{' '}
            <Button onClick={()=>this.setState({refundModal4:false})}>No</Button>
          </ModalFooter> 
        </Modal>
        );
        }

        if(AccOffDet.length)
        {
         refundModal5 = (
        <Modal isOpen={this.state.refundModal5}>
        <ModalHeader color="warning">Warning</ModalHeader>
          <ModalBody>
          Cancelation fee is more than refund amount. Do you want to issue full refund?       
          </ModalBody>
          <ModalFooter>
          <Button color="danger" onClick={()=>this.refundModalControl("can_allowed_amount_more_refund",'full')}>Yes</Button>{' '}
            <Button onClick={()=>this.setState({refundModal5:false})}>No</Button>
          </ModalFooter> 
        </Modal>
        );
        }




    let offerDetailView = null;
    if(AccOffDet.length){
        offerDetailView = (
            <Card style={{border:"1px solid #a78bba",marginTop:10}}>
                <CardHeader style={{fontWeight:"",fontSize:"18px",color:"#fff",backgroundColor:"#2C2165",fontFamily: 'Muli'}}>Service Details</CardHeader>
                <Card style={{padding: 10}}>
                    {AccOffDet[0].REFUND_REQUEST === 1 ? 
                        <Row>
                            <Col sm={{size:'auto'}}>
                                <Label style={{backgroundColor: '#EFEFEF', color:'black', fontWeight: 'bold', border:'1px solid #EFEFEF', padding: 5,fontFamily: 'Muli',}} color='secondary'>Booking Id: <strong> {this.state.bId} </strong></Label>
                            </Col>
                        
                            <Col sm={{size: 2}}>
                                <Label style={{backgroundColor: '#EFEFEF', color:'black', fontWeight: 'bold', border:'1px solid #EFEFEF', padding: 5,fontFamily: 'Muli', }}>Consumed:  <span> {AccOffDet[0].OFFER_CONSUMED ?  <span style={{backgroundColor:"#",padding:"5px",color:"black",borderRadius:"3px"}}>YES</span> :  <span style={{backgroundColor:"#",padding:"5px",color:"black",borderRadius:"3px"}}>NO</span>} </span></Label>
                            </Col>

                            <Col sm={{size: 5}} >
                                
                             
                                {AccOffDet[0].PIN_VALIDATED?<Button size="md" style={{backgroundColor:"#9277BA",marginRight:5,fontFamily: 'Muli',}}
                                      disabled={
                                        !this.state.Sdetails2.length ?
                                        true:
    
                                        (this.state.SCouponCode.length ? 
                                        true : 
                                        AccOffDet[0].REFUND_REQUEST===1 ) || (this.state.refundButton) ? 
                                        true :
                                         false}
                                        onClick={()=>this.setState({refundModal:true})} >Refund</Button> :


                                        (AccOffDet[0].REC_SERVICE_CANCEL_ALLOWED===0||AccOffDet[0].REC_SERVICE_CANCEL_ALLOWED===null)?
                                       
                                        <Button size="md" style={{backgroundColor:"#9277BA",marginRight:5,fontFamily: 'Muli',}}
                                        disabled={
                                            !this.state.Sdetails2.length ?
                                        true:
                                            (this.state.SCouponCode.length ? 
                                            true : 
                                            AccOffDet[0].REFUND_REQUEST===1 )  || (this.state.refundButton) ? 
                                            true : false}
                                          onClick={()=>this.setState({refundModal3:true})} >Refund</Button>:

                                        <Button size="md" style={{backgroundColor:"#9277BA",marginRight:5,fontFamily: 'Muli',}}
                                        disabled={
                                            !this.state.Sdetails2.length ?
                                        true:
                                           
                                            (this.state.SCouponCode.length ? 
                                            true : 
                                            AccOffDet[0].REFUND_REQUEST===1 )  || (this.state.refundButton) ? 
                                            true : false}
                                        onClick={()=>this.setState({refundModal2:true})} >Refund</Button>}   
                             

                                <Button size="md" style={{backgroundColor:"#9277BA",marginRight:5,fontFamily: 'Muli',}} onClick={() => this.CreateTicketHandler(this.state.bId,AccOffDet[0].QF_MERCHANT_PERSONNEL_PRID)}>Create Ticket</Button>                        
                                <Button size="md" style={{backgroundColor:"#9277BA",marginRight:5}} disabled={this.state.merchantFlag ? true : false} onClick={() => this.flagMerchantHandler(this.state.bId)}>Flag Merchant</Button>
                                <Button size="md" style={{backgroundColor:"#9277BA"}} 
                                disabled={
                                    !this.state.Sdetails2.length ?
                                        true:
                                       
                                        this.state.SCouponCode.length ?
                                    true :
                                        AccOffDet.length !==0 ? 
                                        (AccOffDet[0].REFUND_REQUEST === 1 ? 
                                        true : false) : true
                                    
                                    } onClick={() => this.createCouponHandler(this.state.bId,AccOffDet[0].NET_PRICE)}>Create Coupon</Button>
                                
                            </Col>
                            <Col sm={{size: 'auto'}}>
                                <Label style={{backgroundColor: '#EFEFEF', color:'black', fontWeight: 'bold', border:'1px solid #EFEFEF', padding: 5,fontFamily: 'Muli', }}>Cancelled:  <span> {AccOffDet.length !==0 ? AccOffDet[0].OFFER_CANCELLED === 1 ? <span style={{backgroundColor:"#",padding:"5px",color:"black",borderRadius:"3px"}}> YES </span> : <span style={{backgroundColor:"#",padding:"5px",color:"black",borderRadius:"3px"}}> NO </span> : <strong>---</strong>} </span></Label>
                            </Col>

                            <Col sm={{size: 'auto'}}>
                                <Label style={{backgroundColor: '#EFEFEF', color:'black', fontWeight: 'bold', border:'1px solid #EFEFEF', padding: 5,fontFamily: 'Muli', }}>Refund:  <span> {AccOffDet.length !==0 ?  AccOffDet[0].REFUND_REQUEST === 1 && AccOffDet[0].REFUND_INITIATED !== 1 &&  AccOffDet[0].REFUND_STATUS !== 1 ? <span style={{backgroundColor:"#F86C69",padding:"5px",color:"#fff",borderRadius:"3px"}}>  Requested </span> : AccOffDet[0].REFUND_REQUEST === 1 && AccOffDet[0].REFUND_INITIATED === 1 &&  AccOffDet[0].REFUND_STATUS !== 1 ? <span style={{backgroundColor:"#ffc107",padding:"5px",color:"#fff",borderRadius:"3px"}}> Initiated </span> : AccOffDet[0].REFUND_REQUEST === 1 && AccOffDet[0].REFUND_INITIATED === 1 && AccOffDet[0].REFUND_STATUS === 1 ? <span style={{backgroundColor:"#3DC5B6",padding:"5px",color:"#fff",borderRadius:"3px"}}>Completed </span> : AccOffDet[0].REFUND_REQUEST === 1 && AccOffDet[0].REFUND_STATUS === 1 ? <span style={{backgroundColor:"#3DC5B6",padding:"5px",color:"#fff",borderRadius:"3px"}}> Completed </span> : <span style={{backgroundColor:"#3DC5B6",padding:"5px",color:"#fff",borderRadius:"3px"}}>NO</span> : null} </span></Label>
                            </Col>
                        </Row>  
                        
                    :
                    
                        <Row>
                            <Col sm={{size: 'auto'}}>
                                <Label style={{backgroundColor: '#EFEFEF', color:'black', fontWeight: 'bold', border:'1px solid #EFEFEF', padding: 5,fontFamily: 'Muli',}} color='secondary'>Booking Id: <strong> {this.state.bId} </strong></Label>
                            </Col>

                            <Col sm={{size: 2}}>
                                <Label style={{backgroundColor: '#EFEFEF', color:'black', fontWeight: 'bold', border:'1px solid #EFEFEF', padding: 5,fontFamily: 'Muli', }} color='secondary'>Consumed:  <span> {AccOffDet[0].OFFER_CONSUMED ?  <span style={{backgroundColor:"#",padding:"5px",color:"black",borderRadius:"3px"}}>YES</span> : <span style={{backgroundColor:"#",padding:"5px",color:"black",borderRadius:"3px"}}>NO</span>} </span></Label>
                            </Col>

                            <Col sm={{size: 5}} >

                                
                            
                                     {AccOffDet[0].PIN_VALIDATED?<Button size="md" style={{backgroundColor:"#9277BA",marginRight:5,fontFamily: 'Muli',}}
                                      disabled={
                                        !this.state.Sdetails2.length ?
                                        true:

                                        (this.state.SCouponCode.length ? 
                                        true : 
                                        AccOffDet[0].REFUND_REQUEST===1 ) || (this.state.refundButton) ? 
                                        true 
                                        :false}
                                        onClick={()=>this.setState({refundModal:true})} >Refund</Button> :


                                        (AccOffDet[0].REC_SERVICE_CANCEL_ALLOWED == 0||AccOffDet[0].REC_SERVICE_CANCEL_ALLOWED == null)?
                                       
                                        <Button size="md" style={{backgroundColor:"#9277BA",marginRight:5,fontFamily: 'Muli',}}
                                        disabled={
                                            !this.state.Sdetails2.length ?
                                        true:
                                           
                                            (this.state.SCouponCode.length ? 
                                            true : 
                                            AccOffDet[0].REFUND_REQUEST===1 )  || (this.state.refundButton) ? 
                                            true : false}
                                          onClick={()=>this.setState({refundModal3:true})} >Refund</Button>:

                                        <Button size="md" style={{backgroundColor:"#9277BA",marginRight:5,fontFamily: 'Muli',}}
                                        disabled={
                                            !this.state.Sdetails2.length ?
                                            true:
                                           
                                            (this.state.SCouponCode.length ? 
                                            true : 
                                            AccOffDet[0].REFUND_REQUEST===1 )  || (this.state.refundButton) ? 
                                            true : false}
                                        onClick={()=>this.setState({refundModal2:true})} >Refund</Button>}     


                                <Button size="md" style={{backgroundColor:"#9277BA",marginRight:5,fontFamily: 'Muli',}} onClick={() => this.CreateTicketHandler(this.state.bId,AccOffDet[0].QF_MERCHANT_PERSONNEL_PRID)}>Create Ticket</Button>                        
                                <Button size="md" style={{backgroundColor:"#9277BA",marginRight:5,fontFamily: 'Muli',}} disabled={this.state.merchantFlag ? true : false} onClick={() => this.flagMerchantHandler(this.state.bId)}>Flag Merchant</Button>
                                <Button size="md" style={{backgroundColor:"#9277BA",fontFamily: 'Muli',}} 
                               
                               disabled={
                                !this.state.Sdetails2.length ?
                                true:
                                         
                                        this.state.SCouponCode.length ?
                                    true :
                                         AccOffDet.length ? 
                                         (AccOffDet[0].REFUND_REQUEST === 1 ? 
                                         true : false) : true
                                         }
                                onClick={() => this.createCouponHandler(this.state.bId,AccOffDet[0].NET_PRICE)}>Create Coupon</Button>
                                
                            </Col>

                            <Col sm={{size: 'auto'}}>
                                <Label style={{backgroundColor: '#EFEFEF', color:'black', fontWeight: 'bold', border:'1px solid #EFEFEF', padding: 5,fontFamily: 'Muli', }}>Cancelled:  <span> {AccOffDet.length !==0 ? AccOffDet[0].OFFER_CANCELLED === 1 ? <span style={{backgroundColor:"#",padding:"5px",color:"black",borderRadius:"3px"}}> YES </span> : <span style={{backgroundColor:"#",padding:"5px",color:"black",borderRadius:"3px"}}> NO </span> : <strong>---</strong>} </span></Label>
                            </Col>

                            <Col sm={{size: 'auto'}}>
                                <Label style={{backgroundColor: '#EFEFEF', color:'black', fontWeight: 'bold', border:'1px solid #EFEFEF', padding: 5,fontFamily: 'Muli', }}>Refund:  <span> {AccOffDet.length !==0 ?  AccOffDet[0].REFUND_REQUEST === 1 && AccOffDet[0].REFUND_INITIATED !== 1 &&  AccOffDet[0].REFUND_STATUS !== 1 ? <span style={{backgroundColor:"#F86C69",padding:"5px",color:"#fff",borderRadius:"3px"}}>  Requested </span> : AccOffDet[0].REFUND_REQUEST === 1 && AccOffDet[0].REFUND_INITIATED === 1 &&  AccOffDet[0].REFUND_STATUS !== 1 ? <span style={{backgroundColor:"#ffc107",padding:"5px",color:"#fff",borderRadius:"3px"}}> Initiated </span> : AccOffDet[0].REFUND_REQUEST === 1 && AccOffDet[0].REFUND_INITIATED === 1 && AccOffDet[0].REFUND_STATUS === 1 ? <span style={{backgroundColor:"#3DC5B6",padding:"5px",color:"#fff",borderRadius:"3px"}}>Completed </span> : AccOffDet[0].REFUND_REQUEST === 1 && AccOffDet[0].REFUND_STATUS === 1 ? <span style={{backgroundColor:"#3DC5B6",padding:"5px",color:"#fff",borderRadius:"3px"}}> Completed </span> : <span style={{backgroundColor:"#3DC5B6",padding:"5px",color:"#fff",borderRadius:"3px"}}>NO</span> : null} </span></Label>
                            </Col>
                        </Row>
                    
                    }
                </Card>
                <CardText style={{padding:"25px"}}>
                <Row className="mb-4" style={rowStyle}>

                        <Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}>{AccOffDet[0].QF_MERCHANT_SERVICE_PRID!=null?'Service Id':'Offer Id'}:</span>{AccOffDet[0].QF_MERCHANT_OFFER_PRID ?  <text style={LinkedText} onClick={this.offerDetailsHandler}>{AccOffDet[0].QF_MERCHANT_OFFER_PRID}</text> :AccOffDet[0].QF_MERCHANT_SERVICE_PRID!=null?<text style={LinkedText} onClick={this.serviceDetailsHandler}>{AccOffDet[0].QF_MERCHANT_SERVICE_PRID}</text>: <text style={fontText}>0</text> } </text>
                        </Col>

                        <Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}>Professional Name: </span>{this.state.merchantName ? <text style={LinkedText} onClick={() => this.MerchantDetailsHandler(this.state.mPrid)}>{this.state.merchantName}</text> : <text style={fontText}>---</text>}  </text>
                        </Col>
                       
                        <Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}>Professional Location:</span>{this.state.mAdd.length !== 0 ? <text style={textDecor}>{this.state.mAdd[0].MERCHANT_ADDRESS_LINE2}{', '}{this.state.mAdd[0].MERCHANT_ADDRESS_LINE3 ? <text style={textDecor}> {this.state.mAdd[0].MERCHANT_ADDRESS_LINE3}{', '}</text> : null}<text style={textDecor}>{this.state.mAdd[0].MERCHANT_ADDRESS_CITY}{', '}{this.state.mAdd[0].MERCHANT_ADDRESS_STATE}{', '}{this.state.mAdd[0].MERCHANT_ADDRESS_COUNTRY}{' - '}{this.state.mAdd[0].MERCHANT_ADDRESS_ZIPCODE}</text></text> : <text style={fontText}>---</text> } </text>
                        </Col>                      

                </Row>
                
                <Row className="mb-4" style={rowStyle}>
                        <Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}>{AccOffDet[0].QF_MERCHANT_SERVICE_PRID!=null?'Service Name':'Offer Name'}: </span>{AccOffDet[0].MERCHANT_OFFER_HEADER_NAME ? AccOffDet[0].MERCHANT_OFFER_HEADER_NAME :  <text style={fontText}> {AccOffDet[0].REC_SERVICE_NAME?AccOffDet[0].REC_SERVICE_NAME:<text>-----</text>}</text>}</text>
                        </Col>

                        <Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}> Description:</span>{ AccOffDet[0].MERCHANT_OFFER_DETAIL_DESC ? AccOffDet[0].MERCHANT_OFFER_DETAIL_DESC : <text style={fontText}>{AccOffDet[0].REC_SERVICE_DETAIL_DESC?AccOffDet[0].REC_SERVICE_DETAIL_DESC:<text>------</text>}</text> } </text>
                        </Col>                             
                            


                        <Col className="col-4">
                                <text style={fontText1}><span style={fWeightSpan}> Service Location:</span>{ this.state.consumerBookingAdd.length!==0 ? this.state.consumerBookingAdd[0][0].CONSUMER_ADDRESS ? this.state.consumerBookingAdd[0][0].CONSUMER_ADDRESS : this.state.mAdd.length !== 0 ? <text style={textDecor}>{this.state.mAdd[0].MERCHANT_ADDRESS_LINE2}{', '}{this.state.mAdd[0].MERCHANT_ADDRESS_LINE3 ? <text style={textDecor}> {this.state.mAdd[0].MERCHANT_ADDRESS_LINE3}{', '}</text> : null}<text style={textDecor}>{this.state.mAdd[0].MERCHANT_ADDRESS_CITY}{', '}{this.state.mAdd[0].MERCHANT_ADDRESS_STATE}{', '}{this.state.mAdd[0].MERCHANT_ADDRESS_COUNTRY}{' - '}{this.state.mAdd[0].MERCHANT_ADDRESS_ZIPCODE}</text></text> : <text style={fontText}>---</text>  : this.state.mAdd.length !== 0 ? <text style={textDecor}>{this.state.mAdd[0].MERCHANT_ADDRESS_LINE2}{', '}{this.state.mAdd[0].MERCHANT_ADDRESS_LINE3 ? <text style={textDecor}> {this.state.mAdd[0].MERCHANT_ADDRESS_LINE3}{', '}</text> : null}<text style={textDecor}>{this.state.mAdd[0].MERCHANT_ADDRESS_CITY}{', '}{this.state.mAdd[0].MERCHANT_ADDRESS_STATE}{', '}{this.state.mAdd[0].MERCHANT_ADDRESS_COUNTRY}{' - '}{this.state.mAdd[0].MERCHANT_ADDRESS_ZIPCODE}</text></text> : <text style={fontText}>---</text> }</text>
    
                            </Col>  
                                 

                </Row>
                <Row className="mb-4" style={rowStyle}>       
                <Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}> Category:</span>{ this.state.subCats.length != 0 ? this.state.subCats[0].category_name : <text style={fontText}>---</text> } </text>

                       
                        </Col>                   

                        <Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}> Subcategory:</span>{this.state.subCats.length != 0 ? this.state.subCats[0].service_sub_category_name : <text style={fontText}>---</text> } </text>
                        </Col> 

                        
                        {AccOffDet[0].QF_MERCHANT_SERVICE_PRID!=null?<Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}>Service Duration:</span>
                                <text style={fontText}>
                                {moment.utc(moment.duration(AccOffDet[0].REC_SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("H") == 0 ? null : moment.utc(moment.duration(AccOffDet[0].REC_SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("H")+ "h"}{' '}{moment.utc(moment.duration(AccOffDet[0].REC_SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("mm") == 0 ? null : moment.utc(moment.duration(AccOffDet[0].REC_SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("mm") + "m"}
                                </text>
                               
                            </text>
                        </Col>:null} 

                </Row>
                <Row className="mb-4" style={rowStyle}>
                <Col className="col-4">                                
                            <text style={fontText1}><span style={fWeightSpan}>Time Zone:</span>{AccOffDet[0].MERCHANT_OFFER_TIMEZONE ? AccOffDet[0].MERCHANT_OFFER_TIMEZONE : AccOffDet[0].SERVICE_TIMEZONE}</text>
                        </Col>
                </Row>
                <hr />
                <Row className="mb-4" style={rowStyle}>

                {AccOffDet[0].QF_MERCHANT_SERVICE_PRID!=null?
                <Col className="col-4">
                <text style={fontText1}><span style={fWeightSpan}>Date:</span>
                    {AccOffDet[0].BOOKING_FROM_DATE_TIME ? 
                        momenttz(AccOffDet[0].BOOKING_FROM_DATE_TIME+"Z").tz(AccOffDet[0].SERVICE_TIMEZONE).format('DD-MMM-YYYY')
                    : 
                        <text style={fontText}>---</text>}
                </text>
                </Col> 
                :<Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}>Date:</span>
                                {AccOffDet[0].MERCHANT_OFFER_FROM_DATE_TIME ? 
                                    momenttz(AccOffDet[0].MERCHANT_OFFER_FROM_DATE_TIME+"Z").tz(AccOffDet[0].MERCHANT_OFFER_TIMEZONE).format('DD-MMM-YYYY')
                                : 
                                    <text style={fontText}>---</text>}
                                
                            </text>
                        </Col>}

                        
                        
                      
                        
                        {AccOffDet[0].QF_MERCHANT_SERVICE_PRID!=null?
                        <Col className="col-4">
                        <text style={fontText1}><span style={fWeightSpan}>From:</span>
                            {AccOffDet[0].BOOKING_FROM_DATE_TIME ? 
                                momenttz(AccOffDet[0].BOOKING_FROM_DATE_TIME+"Z").tz(AccOffDet[0].SERVICE_TIMEZONE).format('hh:mm A z') 
                            : 
                                <text style={fontText}>---</text>}
                        </text>
                        </Col>
                        :<Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}>From:</span>
                                {AccOffDet[0].MERCHANT_OFFER_FROM_DATE_TIME ? 
                                    momenttz(AccOffDet[0].MERCHANT_OFFER_FROM_DATE_TIME+"Z").tz(AccOffDet[0].MERCHANT_OFFER_TIMEZONE).format('hh:mm A z') 
                                : 
                                    <text style={fontText}>---</text>}
                                
                            </text>
                            </Col> }

                       {AccOffDet[0].QF_MERCHANT_SERVICE_PRID!=null?
                       <Col className="col-4">
                       <text style={fontText1}><span style={fWeightSpan}>Till:</span>
                           {AccOffDet[0].BOOKING_TO_DATE_TIME ? 
                              momenttz(AccOffDet[0].BOOKING_TO_DATE_TIME+"Z").tz(AccOffDet[0].SERVICE_TIMEZONE).format('hh:mm A z') 
                           : 
                               <text style={fontText}>---</text>}
                       </text>
                       </Col>
                       :<Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}>Till:</span>
                                {AccOffDet[0].MERCHANT_OFFER_TO_DATE_TIME ? 
                                   momenttz(AccOffDet[0].MERCHANT_OFFER_TO_DATE_TIME+"Z").tz(AccOffDet[0].MERCHANT_OFFER_TIMEZONE).format('hh:mm A z') 
                                : 
                                    <text style={fontText}>---</text>}
                              
                            </text>
                            </Col>}     
                       

                </Row>
                <hr/>
                
                <Row className="mb-4" style={rowStyle}>                     

                        {   
                                AccOffDet[0].QF_MERCHANT_SERVICE_PRID!==null?
                                <Col className="col-4">
                                <text style={fontText1}><span style={fWeightSpan}>Professional Price:</span>
                                    {AccOffDet[0].SERVICE_CURRENCY === "Rupees" ? <span>{'\u20B9'}</span> : AccOffDet[0].SERVICE_CURRENCY === "Dollars" ? <span>$</span> : <span>{AccOffDet[0].SERVICE_CURRENCY} </span>}
                                    {AccOffDet[0].REC_SERVICE_OFFER_PRICE ? Curr(AccOffDet[0].REC_SERVICE_OFFER_PRICE).value.toFixed(2) : <text style={fontText}> 0.00 </text> } </text>
                                </Col>
                                :<Col className="col-4">
                                <text style={fontText1}><span style={fWeightSpan}>Professional Price:</span>
                                    {AccOffDet[0].MERCHANT_OFFER_CURRENCY === "Rupees" ? <span>{'\u20B9'}</span> : AccOffDet[0].MERCHANT_OFFER_CURRENCY === "Dollars" ? <span>$</span> : <span>{AccOffDet[0].MERCHANT_OFFER_CURRENCY} </span>}
                                    {AccOffDet[0].MERCHANT_OFFER_PRICE ? Curr(AccOffDet[0].MERCHANT_OFFER_PRICE).value.toFixed(2) : <text style={fontText}> 0 </text> } </text>
                                </Col>
                        
                        }
                        {
                                AccOffDet[0].CP_PRID ?
                                <Col>
                                <text style={fontText1}><span style={fWeightSpan}>{AccOffDet[0].CP_DIFF_PRICE>0?"Slash Fee" : "Slash Discount"}:</span>
                                {AccOffDet[0].SERVICE_CURRENCY === "Rupees" ? <span> {'\u20B9'} </span> : <span>$</span>}{Curr(Math.abs(AccOffDet[0].CP_DIFF_PRICE)).value.toFixed(2)}
                                </text>
                                </Col>
                                : null
                        }
                        {
                                AccOffDet[0].TS_PRID ?
                                <Col>
                                <text style={fontText1}><span style={fWeightSpan}>{AccOffDet[0].TS_DIFF_PRICE>0? "High Demand Surcharge" : "Low Demand Discount"}:</span>
                                {AccOffDet[0].SERVICE_CURRENCY === "Rupees" ? <span> {'\u20B9'} </span> : <span>$</span>}{Curr(Math.abs(AccOffDet[0].TS_DIFF_PRICE)).value.toFixed(2)}
                                </text>
                                </Col>
                                : null
                        }
                </Row>

                { AccOffDet[0].GST_COLLECTED_ON_PAYMENT && this.props.country != 'USA' ? <Row className="mb-4" style={rowStyle}>
                        {
                               
                                <Col>
                                <text style={fontText1}><span style={fWeightSpan}>GST Collected:</span>
                                {AccOffDet[0].SERVICE_CURRENCY === "Rupees" ? <span> {'\u20B9'} </span> : <span>$</span>}{AccOffDet[0].GST_COLLECTED_ON_PAYMENT.toFixed(2)}
                                </text>
                                </Col>
            
                            }
                        </Row> :null}

                <hr />
                <Row className="mb-4" style={rowStyle}>

                       
                {AccOffDet[0].QF_MERCHANT_SERVICE_PRID!=null?<Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}>Cancellation Allowed:</span>{AccOffDet[0].REC_SERVICE_CANCEL_ALLOWED ? <span style={{backgroundColor:"#3DC5B6",padding:"5px",color:"#fff",borderRadius:"3px"}}> Yes </span>:<span style={{backgroundColor:"#F86C69",padding:"5px",color:"#fff",borderRadius:"3px"}}>No</span>}</text>
                        </Col> :null}

                        {
                            AccOffDet[0].QF_MERCHANT_SERVICE_PRID != null ?
                            AccOffDet[0].REC_SERVICE_CANCEL_ALLOWED ? 
                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Days Before Cancellation Allowed:</span>{AccOffDet[0].REC_SERVICE_CANCEL_ALLOWED_DAYS ? AccOffDet[0].REC_SERVICE_CANCEL_ALLOWED_DAYS : <text style={fontText}>0</text> }{AccOffDet[0].REC_SERVICE_CANCEL_ALLOWED_DAYS === 1 ? <span> day</span> : AccOffDet[0].REC_SERVICE_CANCEL_ALLOWED_DAYS === 0 ? <span> day</span> : <span> days</span>}</text>
                                </Col>
                            : null
                            : null
                        }
                        {
                            AccOffDet[0].QF_MERCHANT_SERVICE_PRID !=null ?
                            AccOffDet[0].REC_SERVICE_CANCEL_ALLOWED ? 
                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Cancellation Allowed Before:</span>
                                        {momenttz(AccOffDet[0].BOOKING_FROM_DATE_TIME+"Z").tz(AccOffDet[0].SERVICE_TIMEZONE).subtract(AccOffDet[0].REC_SERVICE_CANCEL_ALLOWED_DAYS, 'days' ).format('DD MMM YYYY hh:mm A z')}
                                    </text>
                                </Col>
                            : null
                            : null
                        }
                        {AccOffDet[0].QF_MERCHANT_SERVICE_PRID!=null?null:  <Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}>Cancellation Allowed:</span>{AccOffDet[0].MERCHANT_OFFER_CANCEL_ALLOWED ? <span style={{backgroundColor:"#3DC5B6",padding:"5px",color:"#fff",borderRadius:"3px"}}> Yes </span>:<span style={{backgroundColor:"#F86C69",padding:"5px",color:"#fff",borderRadius:"3px"}}>No</span>}</text>
                        </Col>}

                        {
                        AccOffDet[0].MERCHANT_OFFER_CANCEL_ALLOWED ? 
                            <Col className="col-4">
                                <text style={fontText1}><span style={fWeightSpan}>Days Before Cancellation Allowed:</span>
                                    {AccOffDet[0].MERCHANT_OFFER_CANCEL_ALLOWED_MINUTES_BEFORE ? 
                                        <text>{AccOffDet[0].MERCHANT_OFFER_CANCEL_ALLOWED_MINUTES_BEFORE} {AccOffDet[0].MERCHANT_OFFER_CANCEL_ALLOWED_MINUTES_BEFORE === 1 ? <span> day</span> : AccOffDet[0].MERCHANT_OFFER_CANCEL_ALLOWED_MINUTES_BEFORE === 0 ? <span> day</span> : <span> days</span>}</text>
                                    : 
                                        <text style={fontText}>0</text> 
                                    }
                                </text>
                            </Col>
                        : null
                        }

                        {
                        AccOffDet[0].MERCHANT_OFFER_CANCEL_ALLOWED ? 
                            <Col className="col-4">
                                <text style={fontText1}><span style={fWeightSpan}>Cancellation Allowed Before:</span>
                                    {momenttz(AccOffDet[0].MERCHANT_OFFER_FROM_DATE_TIME+"Z").tz(AccOffDet[0].QF_MERCHANT_SERVICE_PRID!=null?AccOffDet[0].SERVICE_TIMEZONE:AccOffDet[0].MERCHANT_OFFER_TIMEZONE).subtract(AccOffDet[0].MERCHANT_OFFER_CANCEL_ALLOWED_MINUTES_BEFORE, 'days' ).format('DD MMM YYYY hh:mm A z')}
                                    
                                </text>
                            </Col>
                        : null
                        }

                </Row>
                <Row className="mb-4" style={rowStyle}>
                        {  AccOffDet[0].QF_MERCHANT_SERVICE_PRID!=null?AccOffDet[0].REC_SERVICE_CANCEL_FEE ||  AccOffDet[0].REC_SERVICE_CANCEL_FEE === 0 ? 
                        <Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}>Cancellation Fee:</span>
                            {AccOffDet[0].SERVICE_CURRENCY === "Rupees" ? <span>{'\u20B9'}</span> : AccOffDet[0].SERVICE_CURRENCY === "Dollars" ? <span>$</span> : <span>{AccOffDet[0].SERVICE_CURRENCY} </span>}
                            { AccOffDet[0].REC_SERVICE_CANCEL_FEE ? Curr(AccOffDet[0].REC_SERVICE_CANCEL_FEE).value.toFixed(2) : AccOffDet[0].REC_SERVICE_CANCEL_FEE === 0 ? Curr(AccOffDet[0].REC_SERVICE_CANCEL_FEE).value.toFixed(2) : null} </text>
                        </Col>
                        :null
                        :null
                        }
                        { AccOffDet[0].MERCHANT_OFFER_CANCEL_FEE ||  AccOffDet[0].MERCHANT_OFFER_CANCEL_FEE === 0 ? 

                        <Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}>Cancellation Fee:</span>
                                {AccOffDet[0].MERCHANT_OFFER_CURRENCY === "Rupees" ? <span>{'\u20B9'}</span> : AccOffDet[0].MERCHANT_OFFER_CURRENCY === "Dollars" ? <span>$</span> : <span>{AccOffDet[0].MERCHANT_OFFER_CURRENCY} </span>}
                                { AccOffDet[0].MERCHANT_OFFER_CANCEL_FEE ? Curr(AccOffDet[0].MERCHANT_OFFER_CANCEL_FEE).value.toFixed(2) : AccOffDet[0].MERCHANT_OFFER_CANCEL_FEE === 0 ? Curr(AccOffDet[0].MERCHANT_OFFER_CANCEL_FEE).value : null} </text>
                        </Col>
                        :
                        null
                        }     

                               <Col className="col-4">
                                <text style={fontText1}><span style={fWeightSpan}>Rating:</span>{AccOffDet[0].SERVICE_RATING?AccOffDet[0].SERVICE_RATING:'--'}</text>
                                </Col>           

                </Row>

            </CardText>
            
            </Card>
        );
        }

        let acceptedDetailView = null;
        let view1=null;
        
        
        AccOffDet.length &&  AccOffDet[0].QF_MERCHANT_SERVICE_PRID ?
                             view1=<text style={{fontFamily:'Muli'}}>Accepted Service Details</text>
                             :
                             view1=<text style={{fontFamily:'Muli'}}>Accepted Offer Details</text>
        
        
        if(AccOffDet.length){
            acceptedDetailView = (
                <Card style={{border:"1px solid #a78bba", marginTop:10}}>
                <CardHeader style={{fontSize:"18px",color:"#fff",backgroundColor:"#2C2165"}}><text style={{fontFamily:'Muli'}}>{view1}</text></CardHeader>
                <CardText style={{padding:"25px"}}>
                <Row className="mb-4" style={rowStyle}>
                     
                    <Col className="col-4">
                        <text style={fontText1}><span style={fWeightSpan}>Acceptance Pin:</span>{AccOffDet[0].OFFER_ACCEPTANCE_PIN ?  AccOffDet[0].OFFER_ACCEPTANCE_PIN : <text style={fontText}>---</text> } </text>
                    </Col>  

                    {AccOffDet[0].PIN_VALIDATED  
                    ?
    
                    <Col className="col-4">
                        <text style={fontText1}><span style={fWeightSpan}>Pin Validated: </span><span style={{backgroundColor:"#3DC5B6",padding:"5px",color:"#fff",borderRadius:"3px"}}>{AccOffDet[0].PIN_VALIDATED ?"Yes":"No"}</span></text>
                    </Col>

                    :

                    <Col className="col-4">
                        <text style={fontText1}><span style={fWeightSpan}>Pin Validated: </span><span style={{backgroundColor:"#F86C69",padding:"5px",color:"#fff",borderRadius:"3px"}}>{AccOffDet[0].PIN_VALIDATED ?"Yes":"No"}</span></text>
                    </Col>
                
                    }   

                    <Col className="col-4">
                        <text style={fontText1}><span style={fWeightSpan}> Pin Validated Date:</span> 
                            {AccOffDet[0].PIN_VALIDATED_TIME ? 
                                momenttz(AccOffDet[0].PIN_VALIDATED_TIME+"Z").tz(AccOffDet[0].QF_MERCHANT_SERVICE_PRID!=null?AccOffDet[0].SERVICE_TIMEZONE:AccOffDet[0].MERCHANT_OFFER_TIMEZONE).format('DD-MMM-YYYY hh:mm:ss A z') 
                            : 
                                <text style={fontText}> --- </text> 
                            }
                           
                        </text>
                    </Col> 
 
                </Row>

                <Row className="mb-4" style={rowStyle}>            
                    
                    {
                        AccOffDet[0].OFFER_CONSUMED  

                        ?
                    
                        <Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}>Service Consumed: </span><span style={{backgroundColor:"#3DC5B6",padding:"5px",color:"#fff",borderRadius:"3px"}}>{AccOffDet[0].OFFER_CONSUMED ?  "Yes" : "No"}</span></text>
                        </Col>

                        :

                        <Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}>Service Consumed: </span><span style={{backgroundColor:"#F86C69",padding:"5px",color:"#fff",borderRadius:"3px"}}>{AccOffDet[0].OFFER_CONSUMED ? "Yes" : "No"}</span></text>
                        </Col>
                
                    }
                    

                    <Col className="col-4">
                        <text style={fontText1}><span style={fWeightSpan}> Coupon Applied:</span> {this.state.CouponApplied  ? <span style={{backgroundColor:"#3DC5B6",padding:"5px",color:"#fff",borderRadius:"3px"}}>Yes</span> : <span style={{backgroundColor:"#F86C69",padding:"5px",color:"#fff",borderRadius:"3px"}}>No</span> } </text>
                    </Col> 

                    <Col className="col-4">
                        <text style={fontText1}><span style={fWeightSpan}> Net Price:</span>
                            {AccOffDet[0].MERCHANT_OFFER_CURRENCY === "Rupees" ? <span>{'\u20B9'}</span>
                            
                            :AccOffDet[0].SERVICE_CURRENCY === "Rupees" ? 
                                                <span>{'\u20B9'}</span> 
                                               :
                                               AccOffDet[0].SERVICE_CURRENCY === "Dollars" ? 
                                               <span>$</span>
                            : AccOffDet[0].MERCHANT_OFFER_CURRENCY === "Dollars" ? <span>$</span> 

                             : <span>{AccOffDet[0].MERCHANT_OFFER_CURRENCY} </span>}
                            {AccOffDet[0].NET_PRICE ? Curr(AccOffDet[0].NET_PRICE).value.toFixed(2) : <text style={fontText}> 0 </text>} </text>
                
                    </Col>          

                </Row>


                {this.props.country != 'USA'?  <Row className="mb-4" style={rowStyle}>
                <Col className="col-4">
                           <text style={fontText1}><span style={fWeightSpan}>Professional Price:</span>
                            {AccOffDet[0].SERVICE_CURRENCY === "Rupees" ? <span>{'\u20B9'} </span> : AccOffDet[0].SERVICE_CURRENCY === "Dollars" ? <span>$</span> : <span>{AccOffDet[0].SERVICE_CURRENCY} </span>}
                            {AccOffDet[0].SERVICE_OFFER_PRICE ? Curr(AccOffDet[0].SERVICE_OFFER_PRICE).value.toFixed(2) : <text style={fontText}> 0 </text> } </text>
                            </Col>

                            {
                                AccOffDet[0].CP_PRID ?
                                <Col>
                                <text style={fontText1}><span style={fWeightSpan}>{AccOffDet[0].CP_DIFF_PRICE>0?"Slash Fee" : "Slash Discount"}:</span>
                                {AccOffDet[0].SERVICE_CURRENCY === "Rupees" ? <span> {'\u20B9'} </span> : <span>$</span>}{Curr(Math.abs(AccOffDet[0].CP_DIFF_PRICE)).value.toFixed(2)}
                                </text>
                                </Col>
                                : null
                            }

                            
                          
                                {AccOffDet[0].TS_PRID ?
                                <Col className="col-4">

                                <text style={fontText1}><span style={fWeightSpan}>{AccOffDet[0].TS_DIFF_PRICE>0? "High Demand Surcharge" : "Low Demand Discount"}:</span>
                                {AccOffDet[0].SERVICE_CURRENCY === "Rupees" ? <span> {'\u20B9'} </span> : <span>$</span>}{Curr(Math.abs(AccOffDet[0].TS_DIFF_PRICE)).value.toFixed(2)}
                                </text>
                                </Col>

                                : null}

                         
                </Row>:null}
                {this.props.country != 'USA'? <Row className="mb-4" style={rowStyle}>

                <Col className="col-4">
                           <text style={fontText1}><span style={fWeightSpan}>Coupon Discount Amount:</span>
                            {AccOffDet[0].SERVICE_CURRENCY === "Rupees" ? <span>{'\u20B9'} </span> : AccOffDet[0].SERVICE_CURRENCY === "Dollars" ? <span>$</span> : <span>{AccOffDet[0].SERVICE_CURRENCY} </span>}
                            {AccOffDet[0].COUPON_DISCOUNT ? Curr(AccOffDet[0].COUPON_DISCOUNT).value.toFixed(2) : <text style={fontText}> 0 </text> } </text>
                            </Col>   
                            
                            <Col className="col-4">
           <text style={fontText1}><span style={fWeightSpan}>Convenience fee:</span>
            {AccOffDet[0].SERVICE_CURRENCY === "Rupees" ? <span>{'\u20B9'} </span> : AccOffDet[0].SERVICE_CURRENCY === "Dollars" ? <span>$</span> : <span>{AccOffDet[0].SERVICE_CURRENCY} </span>}
            {AccOffDet[0].CONVENIENCE_FEE ? Curr(AccOffDet[0].CONVENIENCE_FEE).value.toFixed(2) : <text style={fontText}> 0 </text> } </text>
            </Col>   
            

{AccOffDet[0].CONVENIENCE_FEE_GST ?
           <Col className="col-4">
           <text style={fontText1}><span style={fWeightSpan}> GST on Convenience fee:</span>
            {AccOffDet[0].SERVICE_CURRENCY === "Rupees" ? <span>{'\u20B9'} </span> : AccOffDet[0].SERVICE_CURRENCY === "Dollars" ? <span>$</span> : <span>{AccOffDet[0].SERVICE_CURRENCY} </span>}
            {AccOffDet[0].CONVENIENCE_FEE_GST ? Curr(AccOffDet[0].CONVENIENCE_FEE_GST).value.toFixed(2) : <text style={fontText}> 0 </text> } </text>
            </Col>:null}      

             
                         
                </Row>:null}

             



                {this.props.country != 'USA'? <Row className="mb-4" style={rowStyle}>

<Col className="col-4">
           <text style={fontText1}><span style={fWeightSpan}>GST on HD Surcharge:</span>
            {AccOffDet[0].SERVICE_CURRENCY === "Rupees" ? <span>{'\u20B9'} </span> : AccOffDet[0].SERVICE_CURRENCY === "Dollars" ? <span>$</span> : <span>{AccOffDet[0].SERVICE_CURRENCY} </span>}
            {AccOffDet[0].TS_GST ? Curr(AccOffDet[0].TS_GST).value.toFixed(2) : <text style={fontText}> 0 </text> } </text>
            </Col>   
            

{AccOffDet[0].PRO_GST ?
           <Col className="col-4">
           <text style={fontText1}><span style={fWeightSpan}> GST on Professional :</span>
            {AccOffDet[0].SERVICE_CURRENCY === "Rupees" ? <span>{'\u20B9'} </span> : AccOffDet[0].SERVICE_CURRENCY === "Dollars" ? <span>$</span> : <span>{AccOffDet[0].SERVICE_CURRENCY} </span>}
            {AccOffDet[0].PRO_GST ? Curr(AccOffDet[0].PRO_GST).value.toFixed(2) : <text style={fontText}> 0 </text> } </text>
            </Col>:null}      


            <Col className="col-4">
           <text style={fontText1}><span style={fWeightSpan}>GST on Slash fee:</span>
            {AccOffDet[0].SERVICE_CURRENCY === "Rupees" ? <span>{'\u20B9'} </span> : AccOffDet[0].SERVICE_CURRENCY === "Dollars" ? <span>$</span> : <span>{AccOffDet[0].SERVICE_CURRENCY} </span>}
            {AccOffDet[0].CP_GST ? Curr(AccOffDet[0].CP_GST).value.toFixed(2) : <text style={fontText}> 0 </text> } </text>
            </Col>   
            
         
                </Row>:null}

                {this.props.country != 'USA'? <Row className="mb-4" style={rowStyle}>

            {AccOffDet[0].GST_COLLECTED_ON_BOOKING ?
           <Col className="col-4">
           <text style={fontText1}><span style={fWeightSpan}>Total GST:</span>
            {AccOffDet[0].SERVICE_CURRENCY === "Rupees" ? <span>{'\u20B9'} </span> : AccOffDet[0].SERVICE_CURRENCY === "Dollars" ? <span>$</span> : <span>{AccOffDet[0].SERVICE_CURRENCY} </span>}
            {AccOffDet[0].GST_COLLECTED_ON_BOOKING ? Curr(AccOffDet[0].GST_COLLECTED_ON_BOOKING).value.toFixed(2) : <text style={fontText}> 0 </text> } </text>
            </Col> :null  }
            


         
                </Row>:null}

                {/* <Row className="mb-4" style={rowStyle}>
                    
                </Row> */}
            </CardText>
            
            </Card>
            );
        }

        let couponDetails = null;
           if(this.state.CouponApplied){
               if(this.state.couponDet.length){   
                couponDetails = (
                    
                    <Card style={{border:"1px solid #a78bba",marginTop:10}}>                        
                        <CardHeader style={{fontSize:"18px",color:"#fff",backgroundColor:"#2C2165"}}><text style={{fontFamily:'Muli'}}>Coupon Details</text></CardHeader>                        
                        <CardText style={{padding:"25px"}}>
                            <Row className="mb-4" style={rowStyle}>

                                {
                                !this.state.couponDet.length ?                     
                                    <Col className="col-4">                                
                                        <text style={fontText1}><span style={fWeightSpan}>Coupon Code:</span>{this.state.couponDet[0].OUR_COUPON_CODE ? <text style={LinkedText} onClick={this.couponDetailsHandler}>{this.state.couponDet[0].OUR_COUPON_CODE}</text> : this.state.couponDet[0].MERCHANT_COUPON_CODE ? <text style={LinkedText} onClick={this.couponDetailsHandler}>{this.state.couponDet[0].MERCHANT_COUPON_CODE}</text> : this.state.couponDet[0].SUPPORT_COUPON_CODE ? <text style={LinkedText} onClick={this.couponDetailsHandler}>{this.state.couponDet[0].SUPPORT_COUPON_CODE}</text> : <text style={fontText}>---</text> }</text>
                                    </Col> 
                                :                    
                                null 
                                } 

                                {
                                    !this.state.couponDet.length ?                     
                                        <Col className="col-4">                                
                                            <text style={fontText1}><span style={fWeightSpan}>Created By:</span>{this.state.MName ? <text style={LinkedText} onClick={() => this.MerchantDetailsHandler(this.state.couponDet[0].QF_MERCHANT_PRID)}> {this.state.MName}</text> : this.state.supportUserDet.fname ? <text>{this.state.supportUserDet.fname}{' '}{this.state.supportUserDet.lname}</text> : <text style={fontText}> Administration </text> }</text>
                                        </Col> 
                                    :                    
                                    null 
                                    }



                                {
                                !this.state.couponDet.length ?                     
                                    <Col className="col-4">                                
                                        <text style={fontText1}><span style={fWeightSpan}>Coupon Description:</span>{this.state.couponDet[0].OUR_COUPON_DESC ? this.state.couponDet[0].OUR_COUPON_DESC : this.state.couponDet[0].MERCHANT_COUPON_DESC ? this.state.couponDet[0].MERCHANT_COUPON_DESC : this.state.couponDet[0].SUPPORT_COUPON_DESC ? this.state.couponDet[0].SUPPORT_COUPON_DESC : <text style={fontText}>---</text>}</text>
                                    </Col>
                                :                    
                                null 
                                }
                                
                            </Row>
                            <Row className="mb-4" style={rowStyle}>
                                {
                                !this.state.couponDet.length ?                     
                                    <Col className="col-4">                                
                                        <text style={fontText1}><span style={fWeightSpan}>Coupon Type:</span>{this.state.couponType === 'oCoupon' ?<text style={fontText}>{ this.state.couponDet[0].OUR_COUPON_TYPE== "referral"?"Referral Coupon":"Admin Coupon"} </text> : this.state.couponType === 'mCoupon' ? <text style={fontText}>Professional Coupon </text> : this.state.couponType === 'sCoupon' ? <text style={fontText}>Support Coupon </text> : null }</text>
                                    </Col>
                                :                    
                                null 
                                }
                                    
                                   
                          
                          
                                        <Col sm={{size:4}} style={fWeightSpan}> Subcategory:{' '} 
                                        <text style={textDecor}>{ this.state.couponDet ? this.state.couponDet[0].service_sub_category_name?this.state.couponDet[0].service_sub_category_name: <text>---</text>  : <text>---</text>}</text></Col>
                            
                            </Row>
                            
                            <hr />
                            <Row className="mb-4" style={rowStyle}>
                                {this.state.couponDet.length  ?                     
                                    <Col className="col-4">                                
                                        <text style={fontText1}><span style={fWeightSpan}>Coupon From Booking Date:</span> 
                                            {this.state.couponDet[0].OUR_COUPON_BOOKING_DATE_FROM ? 
                                                this.state.couponDet[0].OUR_COUPON_BOOKING_DATE_FROM !== null ? 
                                                    moment(this.state.couponDet[0].OUR_COUPON_BOOKING_DATE_FROM).format('DD-MMM-YYYY') : null 
                                            : 
                                            this.state.couponDet[0].MERCHANT_COUPON_BOOKING_DATE_FROM ? 
                                                this.state.couponDet[0].MERCHANT_COUPON_BOOKING_DATE_FROM !== null ? 
                                                    moment(this.state.couponDet[0].MERCHANT_COUPON_BOOKING_DATE_FROM+"Z").tz(this.state.couponDet[0].MERCHANT_TIMEZONE).format('DD-MMM-YYYY z') : null 
                                            : 
                                            this.state.couponDet[0].SUPPORT_COUPON_BOOKING_DATE_FROM ? 
                                                 this.state.couponDet[0].SUPPORT_COUPON_BOOKING_DATE_FROM !== null ? 
                                                    moment(this.state.couponDet[0].SUPPORT_COUPON_BOOKING_DATE_FROM).format('DD-MMM-YYYY') : null 
                                            :
                                            <text style={fontText}>---</text>}
                                            
                                           
                                        </text>
                                        
                                    </Col>
                                :                    
                                null 
                                }
                           
                                {this.state.couponDet.length ?                     
                                    <Col className="col-4">                                
                                        <text style={fontText1}><span style={fWeightSpan}>Coupon Till Booking Date:</span>
                                            {this.state.couponDet[0].OUR_COUPON_BOOKING_DATE_TO ? 
                                                this.state.couponDet[0].OUR_COUPON_BOOKING_DATE_TO !== null ? 
                                                    moment(this.state.couponDet[0].OUR_COUPON_BOOKING_DATE_TO).format('DD-MMM-YYYY') : null 
                                            : 
                                            this.state.couponDet[0].MERCHANT_COUPON_BOOKING_DATE_TO ? 
                                                this.state.couponDet[0].MERCHANT_COUPON_BOOKING_DATE_TO !== null ? 
                                                    moment(this.state.couponDet[0].MERCHANT_COUPON_BOOKING_DATE_TO+"Z").tz(this.state.couponDet[0].MERCHANT_TIMEZONE).format('DD-MMM-YYYY z') : null 
                                            : 
                                            this.state.couponDet[0].SUPPORT_COUPON_BOOKING_DATE_TO ? 
                                                this.state.couponDet[0].SUPPORT_COUPON_BOOKING_DATE_TO !== null ? 
                                                    moment(this.state.couponDet[0].SUPPORT_COUPON_BOOKING_DATE_TO).format('DD-MMM-YYYY') : null 
                                            : 
                                            <text style={fontText}>---</text>}
                                            
                                            
                                        </text>
                                       
                                    </Col>
                                :                    
                                null 
                                }
                                {
                                this.state.couponDet.length && this.state.couponType !== 'sCoupon' && this.state.couponDet[0].OUR_COUPON_TYPE !== "referral" ?                      
                                    <Col className="col-4">                                
                                        <text style={fontText1}><span style={fWeightSpan}>Coupon From Service Date:</span>
                                            {this.state.couponDet[0].OUR_COUPON_SERVICE_DATE_FROM ? 
                                                this.state.couponDet[0].OUR_COUPON_SERVICE_DATE_FROM !== null ? 
                                                    moment(this.state.couponDet[0].OUR_COUPON_SERVICE_DATE_FROM).format('DD-MMM-YYYY') : null 
                                            : 
                                            this.state.couponDet[0].MERCHANT_COUPON_SERVICE_DATE_FROM ? 
                                                this.state.couponDet[0].MERCHANT_COUPON_SERVICE_DATE_FROM !== null ? 
                                                    moment(this.state.couponDet[0].MERCHANT_COUPON_SERVICE_DATE_FROM+"Z").tz(this.state.couponDet[0].MERCHANT_TIMEZONE).format('DD-MMM-YYYY z') : null 
                                            : 
                                            <text style={fontText}>---</text>}
                                            
                                        </text>
                                    </Col>
                                :                    
                                null 
                                }
                            </Row>
                            <Row className="mb-4" style={rowStyle}>

                                {this.state.couponDet.length && this.state.couponType !== 'sCoupon' && this.state.couponDet[0].OUR_COUPON_TYPE !== "referral"?                     
                                    <Col className="col-4">                                
                                        <text style={fontText1}><span style={fWeightSpan}>Coupon Till Service Date:</span>
                                            {this.state.couponDet[0].OUR_COUPON_SERVICE_DATE_TO ? 
                                                this.state.couponDet[0].OUR_COUPON_SERVICE_DATE_TO !== null ? 
                                                    moment(this.state.couponDet[0].OUR_COUPON_SERVICE_DATE_TO).format('DD-MMM-YYYY') : null 
                                            : 
                                            this.state.couponDet[0].MERCHANT_COUPON_SERVICE_DATE_TO ? 
                                                this.state.couponDet[0].MERCHANT_COUPON_SERVICE_DATE_TO !== null ? 
                                                    moment(this.state.couponDet[0].MERCHANT_COUPON_SERVICE_DATE_TO+"Z").tz(this.state.couponDet[0].MERCHANT_TIMEZONE).format('DD-MMM-YYYY z') : null 
                                            : 
                                            <text style={fontText}>---</text>}
                                            
                                           
                                        </text>
                                    </Col>
                                :                    
                                null 
                                }
                            </Row>
                            <hr />
                            <Row className="mb-4" style={rowStyle}>
           
                                { this.state.couponDet.length ?                     
                                    <Col className="col-4">                                
                                        <text style={fontText1}><span style={fWeightSpan}>Coupon Minimum Amount:</span>
                                            {
                                                this.state.couponDet[0].OUR_COUPON_MINIMUM_AMOUNT||this.state.couponDet[0].MERCHANT_COUPON_MINIMUM_AMOUNT||this.state.couponDet[0].SUPPORT_COUPON_MINIMUM_AMOUNT ?
                                                AccOffDet[0].MERCHANT_OFFER_CURRENCY === "Rupees" ?
                                                    <span>{'\u20B9'}</span> 
                                                : 
                                                AccOffDet[0].SERVICE_CURRENCY === "Rupees" ? 
                                                <span>{'\u20B9'}</span> 
                                               :
                                               AccOffDet[0].SERVICE_CURRENCY === "Dollars" ? 
                                               <span>$</span> 
                                           :
                                                AccOffDet[0].MERCHANT_OFFER_CURRENCY === "Dollars" ? 
                                                    <span>$</span> 
                                                : 
                                                    <span>{AccOffDet[0].MERCHANT_OFFER_CURRENCY}</span>
                                                :null
                                            }
                                            {
                                                this.state.couponDet[0].OUR_COUPON_CODE ? 
                                                    this.state.couponDet[0].OUR_COUPON_MINIMUM_AMOUNT ? 
                                                        Curr(this.state.couponDet[0].OUR_COUPON_MINIMUM_AMOUNT).value.toFixed(2)
                                                    :
                                                        <text style={fontText}>---</text>
                                                : 
                                                    this.state.couponDet[0].MERCHANT_COUPON_CODE ? 
                                                        this.state.couponDet[0].MERCHANT_COUPON_MINIMUM_AMOUNT ? 
                                                            Curr(this.state.couponDet[0].MERCHANT_COUPON_MINIMUM_AMOUNT).value.toFixed(2) 
                                                        :
                                                            <text style={fontText}>---</text>
                                                : 
                                                    this.state.couponDet[0].SUPPORT_COUPON_CODE ? 
                                                        this.state.couponDet[0].SUPPORT_COUPON_MINIMUM_AMOUNT ? 
                                                            Curr(this.state.couponDet[0].SUPPORT_COUPON_MINIMUM_AMOUNT).value.toFixed(2)
                                                        :
                                                            <text style={fontText}>---</text>
                                                :
                                                    <text style={fontText}>---</text>
                                            } 
                                        </text>
                                    </Col>
                                :                    
                                null 
                                }
                  
                  <Col className="col-4"><text style={fontText1}><span style={fWeightSpan}>Coupon Discount Percent:</span></text>
                                <text style={textDecor}>
                                {
                                    this.state.couponDet[0].OUR_COUPON_CODE ?
                                        this.state.couponDet[0].OUR_COUPON_PERCENT_OFF ?
                                            Curr(this.state.couponDet[0].OUR_COUPON_PERCENT_OFF).value.toFixed(2)
                                        :
                                      
                                        AccOffDet[0] ? AccOffDet[0].COUPON_DISCOUNT ?
                                        (Curr(AccOffDet[0].COUPON_DISCOUNT).multiply(100).value / Curr(AccOffDet[0].MERCHANT_OFFER_PRICE|| (AccOffDet[0].REC_SERVICE_OFFER_PRICE!==null?AccOffDet[0].REC_SERVICE_OFFER_PRICE:AccOffDet[0].SERVICE_OFFER_PRICE)).value).toFixed(2)
                                        :
                                        null:null
                                    :
                                    this.state.couponDet[0].MERCHANT_COUPON_CODE ?
                                        this.state.couponDet[0].MERCHANT_COUPON_PERCENT_OFF ?
                                            Curr(this.state.couponDet[0].MERCHANT_COUPON_PERCENT_OFF).value
                                        :
                                        
                                        AccOffDet[0] ? AccOffDet[0].COUPON_DISCOUNT ?
                                        (Curr(AccOffDet[0].COUPON_DISCOUNT).multiply(100).value / Curr(AccOffDet[0].MERCHANT_OFFER_PRICE|| (AccOffDet[0].REC_SERVICE_OFFER_PRICE!==null?AccOffDet[0].REC_SERVICE_OFFER_PRICE:AccOffDet[0].SERVICE_OFFER_PRICE)).value).toFixed(2)
                                        :
                                        null:null
                                    :
                                    this.state.couponDet[0].SUPPORT_COUPON_CODE ?
                                        this.state.couponDet[0].SUPPORT_COUPON_PERCENT_OFF ?
                                            Curr(this.state.couponDet[0].SUPPORT_COUPON_PERCENT_OFF).value
                                        :
                                       
                                        AccOffDet[0] ? AccOffDet[0].COUPON_DISCOUNT ?
                                        (Curr(AccOffDet[0].COUPON_DISCOUNT).multiply(100).value / Curr(AccOffDet[0].MERCHANT_OFFER_PRICE|| (AccOffDet[0].REC_SERVICE_OFFER_PRICE!==null?AccOffDet[0].REC_SERVICE_OFFER_PRICE:AccOffDet[0].SERVICE_OFFER_PRICE)).value).toFixed(2)
                                        :
                                        null:null
                                    :
                                    null
                                } </text>
                                <text style={textDecor}>
                                {
                                    this.state.couponDet[0].OUR_COUPON_CODE ?
                                        <span>%</span>
                                    :
                                    this.state.couponDet[0].MERCHANT_COUPON_CODE ?
                                       <span>%</span>
                                    :
                                    this.state.couponDet[0].SUPPORT_COUPON_CODE ?
                                       <span>%</span>
                                    :
                                        null
                                }</text>
                                </Col>
    

                                <Col className="col-4"><text style={fontText1}><span style={fWeightSpan}>Coupon Discount Amount:</span></text>
                                <text style={textDecor}>
                                {
                                        AccOffDet[0].MERCHANT_OFFER_CURRENCY === "Rupees" ?
                                                <span>{'\u20B9'}</span> 
                                        :
                                        AccOffDet[0].SERVICE_CURRENCY === "Rupees" ? 
                                                <span>{'\u20B9'}</span> 
                                               :
                                               AccOffDet[0].SERVICE_CURRENCY === "Dollars" ? 
                                               <span>$</span>:
                                        AccOffDet[0].MERCHANT_OFFER_CURRENCY === "Dollars" ?
                                            <span>$</span> 
                                        : 
                                            <span>{AccOffDet[0].MERCHANT_OFFER_CURRENCY} </span> 
                                    }</text>
                                    <text style={textDecor}>
                                    {

                                        AccOffDet[0].COUPON_DISCOUNT?
                                        Curr(AccOffDet[0].COUPON_DISCOUNT).value.toFixed(2)
                                        :
                                        <text>0.00</text>
                                        
                                    }</text>
                                </Col>
                            </Row>

                            <Row className="mb-4" style={rowStyle}>
                            {this.state.couponDet.length && this.state.couponType !== 'sCoupon'?
                                <Col className="col-4">                                
                                <text style={fontText1}><span style={fWeightSpan}>Coupon Maximum Amount:</span>
                                    {
                                        this.state.couponDet[0].OUR_COUPON_MAXIMUM_AMOUNT||this.state.couponDet[0].MERCHANT_COUPON_MAXIMUM_AMOUNT ?
                                        AccOffDet[0].MERCHANT_OFFER_CURRENCY === "Rupees" ?
                                            <span>{'\u20B9'}</span> 
                                        : 

                                        AccOffDet[0].SERVICE_CURRENCY === "Rupees" ? 
                                                <span>{'\u20B9'}</span> 
                                               :
                                               AccOffDet[0].SERVICE_CURRENCY === "Dollars" ? 
                                               <span>$</span>
                                         :      
                                        AccOffDet[0].MERCHANT_OFFER_CURRENCY === "Dollars" ? 
                                            <span>$</span> 
                                        : 
                                            <span>{AccOffDet[0].MERCHANT_OFFER_CURRENCY}</span>
                                        :null
                                    }
                                    {
                                        this.state.couponDet[0].OUR_COUPON_CODE ? 
                                            this.state.couponDet[0].OUR_COUPON_MAXIMUM_AMOUNT ? 
                                                Curr(this.state.couponDet[0].OUR_COUPON_MAXIMUM_AMOUNT).value.toFixed(2)
                                            :
                                                <text style={fontText}>---</text>
                                        : 
                                            this.state.couponDet[0].MERCHANT_COUPON_CODE ? 
                                                this.state.couponDet[0].MERCHANT_COUPON_MAXIMUM_AMOUNT ? 
                                                    Curr(this.state.couponDet[0].MERCHANT_COUPON_MAXIMUM_AMOUNT).value.toFixed(2) 
                                                :
                                                    <text style={fontText}>---</text>
                                        : 
                                            <text style={fontText}>---</text>
                                    } 
                                </text>
                            </Col>

                                :null}
                                <Col className="col-4">                                
                                    <text style={fontText1}><span style={fWeightSpan}>Coupon Count:</span> 
                                        {
                                            this.state.couponDet[0].OUR_COUPON_CODE ? 
                                                this.state.couponDet[0].OUR_COUPON_USED_COUNT ? 
                                                    this.state.couponDet[0].OUR_COUPON_USED_COUNT 
                                                :
                                                    <text style={fontText}>0</text>
                                            :
                                            this.state.couponDet[0].MERCHANT_COUPON_CODE ?
                                                this.state.couponDet[0].MERCHANT_COUPON_USED_COUNT ? 
                                                    this.state.couponDet[0].MERCHANT_COUPON_USED_COUNT 
                                                : 
                                                    <text style={fontText}>0</text>
                                            :
                                            this.state.couponDet[0].SUPPORT_COUPON_CODE ?
                                                this.state.couponDet[0].SUPPORT_COUPON_MAX_COUNT ? 
                                                    this.state.couponDet[0].SUPPORT_COUPON_MAX_COUNT 
                                                : 
                                                    <text style={fontText}>0</text>
                                            :
                                            <text style={fontText}>0</text>
                                        }
                                        {'/'}
                                        {
                                            this.state.couponDet[0].OUR_COUPON_CODE ? 
                                                this.state.couponDet[0].OUR_COUPON_MAX_COUNT ? 
                                                    this.state.couponDet[0].OUR_COUPON_MAX_COUNT 
                                                : 
                                                    <text style={fontText}>0</text>
                                            : 
                                            this.state.couponDet[0].MERCHANT_COUPON_CODE ? 
                                                this.state.couponDet[0].MERCHANT_COUPON_MAX_COUNT ? 
                                                    this.state.couponDet[0].MERCHANT_COUPON_MAX_COUNT 
                                                : 
                                                    <text style={fontText}>0</text>
                                            : 
                                            this.state.couponDet[0].SUPPORT_COUPON_CODE ? 
                                                this.state.couponDet[0].SUPPORT_COUPON_MAX_COUNT ? 
                                                    this.state.couponDet[0].SUPPORT_COUPON_MAX_COUNT 
                                                : 
                                                    <text style={fontText}>0</text>
                                            : 
                                            <text style={fontText}>0</text>
                                        }
                                    </text>
                                </Col>
                            </Row>
                        </CardText>
                    </Card>
                        );
            } else {
                couponDetails = null;
            }
        }

          let paymentDetailView = null;
           if(AccOffDet.length){
              paymentDetailView = (
                <Card style={{border:"1px solid #a78bba",marginTop:10}}>
                
                <CardHeader style={{fontSize:"18px",color:"#fff",backgroundColor:"#2C2165"}}><text style={{fontFamily:'Muli'}}>Customer Payment Details</text></CardHeader>
                
                <CardText style={{padding:"25px"}}>
                <Row className="mb-4" style={rowStyle}>

                    <Col className="col-4">
                        <text style={fontText1}><span style={fWeightSpan}>Payment Id: </span>
                            {AccOffDet[0].PAYMENT_GATEWAY_PAYMENT_INTENT_ID ? 
                                AccOffDet[0].PAYMENT_GATEWAY_PAYMENT_INTENT_ID 
                            : 
                                <text style={fontText}>---</text> 
                            } 
                        </text>
                    </Col>  

                    <Col className="col-4">
                        <text style={fontText1}><span style={fWeightSpan}>Payment Status: </span>
                            {AccOffDet[0].CONSUMER_PAYMENT_PASSED === 1 ? 
                                <span style={{backgroundColor:"#3DC5B6",padding:"5px",color:"#fff",borderRadius:"3px"}}> Passed </span> 
                            : 
                                <span style={{backgroundColor:"#F86C69",padding:"5px",color:"#fff",borderRadius:"3px"}}> Not Passed </span>
                            } 
                        </text>
                    </Col> 

                    <Col className="col-4">
                        <text style={fontText1}><span style={fWeightSpan}>Payment Date: </span>
                            {AccOffDet[0].CONSUMER_PAYMENT_PROCESSED_ON_DATE ? 
                              momenttz(AccOffDet[0].CONSUMER_PAYMENT_PROCESSED_ON_DATE+"Z").tz(AccOffDet[0].QF_MERCHANT_SERVICE_PRID!=null?AccOffDet[0].SERVICE_TIMEZONE:AccOffDet[0].MERCHANT_OFFER_TIMEZONE).format('DD-MMM-YYYY hh:mm:ss A z')
                            : 
                                <text style={fontText}>---</text>}
                           
                        </text>
                    </Col>                                                            

                </Row>

                <Row className="mb-4" style={rowStyle}>

                <Col className="col-4">                                
                        <text style={fontText1}><span style={fWeightSpan}>Country:</span>
                            {AccOffDet[0].MERCHANT_OFFER_CURRENCY === "Rupees" || AccOffDet[0].SERVICE_CURRENCY === "Rupees"? 
                                <span>INDIA</span> 
                            : 
                                <span>USA</span>
                            }
                        </text>
                    </Col>

                    <Col className="col-4">
                        <text style={fontText1}><span style={fWeightSpan}>Currency:</span>
                            {AccOffDet[0].MERCHANT_OFFER_CURRENCY === "Rupees" || AccOffDet[0].SERVICE_CURRENCY === "Rupees"? 
                                <span> INR</span> 
                            : 
                                <span> Dollar</span>
                            } 
                        </text>
                    </Col>

                    <Col className="col-4">
                        <text style={fontText1}><span style={fWeightSpan}> Payment Amount:</span>
                            {
                                AccOffDet[0].MERCHANT_OFFER_CURRENCY === "Rupees" ? 
                                    <span>{'\u20B9'}</span> 
                                : 
                                AccOffDet[0].SERVICE_CURRENCY === "Rupees" ? 
                                                <span>{'\u20B9'}</span> 
                                               :
                                               AccOffDet[0].SERVICE_CURRENCY === "Dollars" ? 
                                               <span>$</span>:
                                AccOffDet[0].MERCHANT_OFFER_CURRENCY === "Dollars" ?
                                    <span>$</span> 
                                : 
                                    <span> {AccOffDet[0].MERCHANT_OFFER_CURRENCY} </span>
                            }

                            {
                                AccOffDet[0].NET_PRICE ? 
                                    Curr(AccOffDet[0].NET_PRICE).value.toFixed(2) 
                                : 
                                    <text style={fontText}>0</text> 
                            } 
                        </text>
                    </Col> 
           
                </Row>
   
            </CardText>
            
            </Card>
            )
        }


        let merchantpayDetailView = null;
        if(this.state.merchantpayDetailView.length){
            merchantpayDetailView = (
                <Card style={{border:"1px solid #a78bba",marginTop:10}}>
                
                <CardHeader style={{fontSize:"18px",color:"#fff",backgroundColor:"#2C2165"}}><text style={{fontFamily:'Muli'}}>Professional Payment Details</text></CardHeader>
                
                <CardText style={{padding:"25px"}}>
                <Row className="mb-4" style={rowStyle}>

                    <Col className="col-4">
                        <text style={fontText1}><span style={fWeightSpan}>Payment Id: </span>
                            {this.state.merchantpayDetailView[0].QF_MERCHANT_PAYMENT_PRID ? 
                                <text style={LinkedText} onClick={() => this.paymentMerchHandler(this.state.merchantpayDetailView[0].QF_MERCHANT_PAYMENT_PRID)}>{this.state.merchantpayDetailView[0].QF_MERCHANT_PAYMENT_PRID}</text>
                            : 
                                <text style={fontText}>---</text> 
                            } 
                        </text>
                    </Col>  

                    <Col className="col-4">


                        <text style={fontText1}><span style={fWeightSpan}>Payment Status: </span>
                            {this.state.merchantpayDetailView[0].MERCHANT_PAYMENT_STATUS_OUR === 1 ? 
                              <text style={{padding:"5px",color:"#fff",backgroundColor:"#f3972d",borderRadius:"3px"}}>Initiated</text>  :
                              this.state.merchantpayDetailView[0].MERCHANT_PAYMENT_STATUS_OUR===2 ?
                                <text style={{padding:"5px",color:"#fff",backgroundColor:"#f3972d",borderRadius:"3px"}}>Pending</text>  :
                                this.state.merchantpayDetailView[0].MERCHANT_PAYMENT_STATUS_OUR===3?
                                <text style={{padding:"5px",color:"#fff",backgroundColor:"#3dc5b6",borderRadius:"3px"}}>Processed</text> :
                                  this.state.merchantpayDetailView[0].MERCHANT_PAYMENT_STATUS_OUR===4 ?
                                    <text style={{padding:"5px",color:"#fff",backgroundColor:"#ff5050",borderRadius:"3px"}}>Failed</text> :
                        this.state.merchantpayDetailView[0].MERCHANT_PAYMENT_STATUS_OUR===5? <text style={{color:"#ff5050"}}>Cancelled</text> :
                                   <text style={{padding:"5px",color:"#fff",backgroundColor:"#FFC459",borderRadius:"3px"}}>Unknown</text>}
                        </text>
                    </Col> 

                    <Col className="col-4">
                        <text style={fontText1}><span style={fWeightSpan}>Payment Date: </span>
                            {this.state.merchantpayDetailView[0].MERCHANT_PAYMENT_CREATED_ON_DATE ? 
                                momenttz(this.state.merchantpayDetailView[0].MERCHANT_PAYMENT_CREATED_ON_DATE+"Z").tz(AccOffDet[0].QF_MERCHANT_SERVICE_PRID?AccOffDet[0].SERVICE_TIMEZONE:AccOffDet[0].MERCHANT_OFFER_TIMEZONE).format('DD-MMM-YYYY hh:mm:ss A z') 
                            
                                : 
                                <text style={fontText}>---</text>}
                          
                        </text>
                    </Col>                                                            

                </Row>

                <Row className="mb-4" style={rowStyle}>

                <Col className="col-4">                                
                        <text style={fontText1}><span style={fWeightSpan}>Country:</span>
                            {AccOffDet[0].MERCHANT_OFFER_CURRENCY === "Rupees" || AccOffDet[0].SERVICE_CURRENCY === "Rupees" ? 
                                <span>INDIA</span> 
                            : 
                                <span>USA</span>
                            }
                        </text>
                    </Col> 

                    <Col className="col-4">
                        <text style={fontText1}><span style={fWeightSpan}>Currency:</span>
                            {AccOffDet[0].MERCHANT_OFFER_CURRENCY === "Rupees" || AccOffDet[0].SERVICE_CURRENCY === "Rupees"? 
                                <span> INR</span> 
                            : 
                                <span> Dollar</span>
                            } 
                        </text>
                    </Col>

                    <Col className="col-4" >
                    <text style={fontText1}><span style={fWeightSpan}> Payment Amount:</span>
                    {AccOffDet[0].MERCHANT_OFFER_CURRENCY === "Rupees" || AccOffDet[0].SERVICE_CURRENCY === "Rupees"?'\u20B9':"$"}{Curr(AccOffDet[0].MERCHANT_PAYMENT_NET_PRICE).subtract(Curr(Curr(AccOffDet[0].QF_MARGIN_APPLIED).divide(100).value).multiply(AccOffDet[0].MERCHANT_PAYMENT_NET_PRICE).value).value.toFixed(2)}</text> </Col>

                    


                    
                </Row>
                <Row className="mb-4" style={rowStyle}>
                <Col className="col-4">
                        <text style={fontText1}><span style={fWeightSpan}>Total Payment Amount:</span>
                            {
                                AccOffDet[0].MERCHANT_OFFER_CURRENCY === "Rupees" ? 
                                    <span>{'\u20B9'}</span> 
                                : 
                                AccOffDet[0].MERCHANT_OFFER_CURRENCY === "Dollars" ?
                                    <span>$</span> 
                                : 
                                AccOffDet[0].SERVICE_CURRENCY === "Rupees" ? 
                                                <span>{'\u20B9'}</span> 
                                               :
                                               AccOffDet[0].SERVICE_CURRENCY === "Dollars" ? 
                                               <span>$</span>:
                                    <span> {AccOffDet[0].MERCHANT_OFFER_CURRENCY} </span>
                            }

                            {
                                this.state.merchantpayDetailView[0].PAYMENT_AMOUNT_TO_MERCHANT ? 
                                    Curr(this.state.merchantpayDetailView[0].PAYMENT_AMOUNT_TO_MERCHANT).value.toFixed(2) 
                                : 
                                    <text style={fontText}>0</text> 

                            } 
                        </text>
                    </Col>
               
                 
         <Col className="col-4">


                        <text style={fontText1}><span style={fWeightSpan}>Bank Transfer Status: </span>
                            {this.state.merchantpayDetailView[0].MERCHANT_PAYMENT_BANK_TRANSFER_STATUS === 1 ? 
                              <text style={{padding:"5px",color:"#fff",backgroundColor:"#f3972d",borderRadius:"3px"}}>Initiated</text>  :
                              this.state.merchantpayDetailView[0].MERCHANT_PAYMENT_BANK_TRANSFER_STATUS===2 ?
                                <text style={{padding:"5px",color:"#fff",backgroundColor:"#f3972d",borderRadius:"3px"}}>Pending</text>  :
                                this.state.merchantpayDetailView[0].MERCHANT_PAYMENT_BANK_TRANSFER_STATUS===3?
                                <text style={{padding:"5px",color:"#fff",backgroundColor:"#3dc5b6",borderRadius:"3px"}}>Processed</text> :
                                  this.state.merchantpayDetailView[0].MERCHANT_PAYMENT_BANK_TRANSFER_STATUS===4 ?
                                    <text style={{padding:"5px",color:"#fff",backgroundColor:"#ff5050",borderRadius:"3px"}}>Failed</text> :
                                 this.state.merchantpayDetailView[0].MERCHANT_PAYMENT_BANK_TRANSFER_STATUS===5? <text style={{color:"#ff5050"}}>Cancelled</text> :
                                   <text style={{padding:"5px",color:"#fff",backgroundColor:"#FFC459",borderRadius:"3px"}}>Unknown</text>}
                        </text>
                    </Col> 

                    </Row>

                             
            </CardText>
            
            </Card>
            )
          }



           let refundDetails = null;
           if(AccOffDet.length ){
             if(AccOffDet[0].REFUND_STATUS == 1){
            
                refundDetails = (
                    
                    <Card style={{border:"1px solid #a78bba",marginTop:10}}>                        
                        <CardHeader style={{fontSize:"18px",color:"#fff",backgroundColor:"#2C2165"}}><text style={{fontFamily:'Muli'}}>Refund Details</text></CardHeader>                        
                        <CardText style={{padding:"25px"}}>
                            <Row className="mb-4" style={rowStyle}>

                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Refund Id:</span>
                                        {AccOffDet[0].REFUND_ID ? 
                                            AccOffDet[0].REFUND_ID 
                                        : 
                                            <text style={fontText}>---</text> 
                                        } 
                                    </text>
                                </Col>                                  

                            { 
                            AccOffDet[0].REFUND_STATUS == 1 ?

                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}> Refund Date:</span> 
                                        {AccOffDet[0].REFUND_CREATED_AT ? 
                                            momenttz(AccOffDet[0].REFUND_CREATED_AT+"Z").tz(AccOffDet[0].QF_MERCHANT_SERVICE_PRID!=null?AccOffDet[0].SERVICE_TIMEZONE:AccOffDet[0].MERCHANT_OFFER_TIMEZONE).format('DD-MMM-YYYY hh:mm:ss A z') 
                                        : 
                                            <text style={fontText}> --- </text>}
                                    </text>
                                </Col>
                            :
                            null
                            }

                            { 
                            AccOffDet[0].REFUND_REQUEST == 1 ?
                            
                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Refund Amount:</span>
                                        {AccOffDet[0].MERCHANT_OFFER_CURRENCY === "Rupees" ? 
                                            <span>{'\u20B9'}</span> 
                                        : 
                                        AccOffDet[0].SERVICE_CURRENCY === "Rupees" ? 
                                                <span>{'\u20B9'}</span> 
                                               :
                                               AccOffDet[0].SERVICE_CURRENCY === "Dollars" ? 
                                               <span>$</span>:
                                        AccOffDet[0].MERCHANT_OFFER_CURRENCY === "Dollars" ? 
                                            <span>$</span> 
                                        : 
                                            <span>{AccOffDet[0].MERCHANT_OFFER_CURRENCY} </span>
                                        }
                                        {AccOffDet[0].REFUND_AMOUNT ? 
                                            Curr(AccOffDet[0].REFUND_AMOUNT).value.toFixed(2)
                                        : 
                                            <text style={fontText}>0</text> 
                                        } 
                                    </text>
                                </Col>  
                            :
                            null                        
                            }
                            </Row>
                            
                            
                            <Row className="mb-4" style={rowStyle}>
                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}>Payment Id:</span> 
                                        {AccOffDet[0].REFUND_PAYMENT_ID ? 
                                            AccOffDet[0].REFUND_PAYMENT_ID 
                                        : 
                                            <text style={fontText}> --- </text>
                                        } 
                                    </text>
                                </Col>
                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}> Refund REASON:</span> 
                                        {AccOffDet[0].REFUND_REASON ? 
                                            AccOffDet[0].REFUND_REASON 
                                        : 
                                            <text style={fontText}> --- </text>
                                        } 
                                    </text>
                                </Col>
                                <Col className="col-4">
                                    <text style={fontText1}><span style={fWeightSpan}> Refund TYPE:</span> 
                                        {AccOffDet[0].REFUND_TYPE ? 
                                            AccOffDet[0].REFUND_TYPE 
                                        : 
                                            <text style={fontText}> --- </text>
                                        } 
                                    </text>
                                </Col>
                            </Row>
                            <Row className="mb-4" style={rowStyle}>    
                        
                    {AccOffDet[0].REFUND_REQUEST == 1 
                    && 
                    AccOffDet[0].REFUND_INITIATED === null 
                    && 
                    AccOffDet[0].REFUND_STATUS === null ?
                        <Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}> Refund Status:</span><span style={{backgroundColor:"#F86C69",padding:"5px",color:"#fff",borderRadius:"3px"}}> Requested </span> </text>
                        </Col>
                    :
                    AccOffDet[0].REFUND_REQUEST == 1 
                    && 
                    AccOffDet[0].REFUND_INITIATED == 1 
                    && 
                    AccOffDet[0].REFUND_STATUS === null ?
                        <Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}> Refund Status:</span><span style={{backgroundColor:"yellow",padding:"5px",color:"#fff",borderRadius:"3px"}}> Initiated </span> </text>
                        </Col>
                    :
                    AccOffDet[0].REFUND_REQUEST == 1 
                    && 
                    AccOffDet[0].REFUND_INITIATED == 1 
                    && 
                    AccOffDet[0].REFUND_STATUS == 1 ?
                        <Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}> Refund Status:</span><span style={{backgroundColor:"#3DC5B6",padding:"5px",color:"#fff",borderRadius:"3px"}}> Completed </span> </text>
                        </Col>
                    :
                    AccOffDet[0].REFUND_REQUEST == 1 
                    && 
                    AccOffDet[0].REFUND_INITIATED === null 
                    && 
                    AccOffDet[0].REFUND_STATUS == 1 ?
                        <Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}> Refund Status:</span><span style={{backgroundColor:"#3DC5B6",padding:"5px",color:"#fff",borderRadius:"3px"}}> Completed </span> </text>
                        </Col>
                    :
                        <Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}> Refund Status:</span><span style={{backgroundColor:"#F86C69",padding:"5px",color:"#fff",borderRadius:"3px"}}> Not Requested </span> </text>
                        </Col>                            
                    }                      
                </Row>
                        </CardText>
                    </Card>
                );
            } else {
                refundDetails = null;
            }
        }  

        let custDetails = null;
        if(this.state.consumer_data.length){                
            custDetails = (
                <Card style={{border:"1px solid #a78bba",marginTop:10}}>
                <CardHeader style={{fontWeight:"",fontSize:"18px",color:"#fff",backgroundColor:"#2C2165"}}>
                <Row>
                    <Col sm={{size:4, offset:4}}><text style={{fontFamily:'Muli'}}>Customer Details</text> </Col> 
                    <Col sm={{size:'auto', offset:2}} > {!ud.length ? <text style={{color:"#563951", padding:10,backgroundColor:"#FFD0D0",outline: '5px solid #e7eaf6'}}>Guest User</text> : ud[0].ACCOUNT_VERIFIED_FLAG !== 1 ? <text style={{color:"#563951", padding:10,backgroundColor:"#FFD0D0",outline: '5px solid #e7eaf6'}}> Guest User </text> : <text style={{color:"#563951", padding:10,backgroundColor:"#FFD0D0",  outline: '5px solid #e7eaf6' }}>Registered User</text> } </Col>
                </Row>
                </CardHeader>
                    <CardText style={{padding:"25px"}}>
                        <Row className="mb-4" style={rowStyle}>
                            <Col className="col-4">
                                <span style={fWeightSpan}>Name:</span>  <text style={textDecor}>{cd[0].CONSUMER_FNAME?cd[0].CONSUMER_FNAME:"Not updated"}
                                </text>
                            </Col>                       
                            <Col size={4}>
                                <span style={fWeightSpan}>Email:</span> <text style={textDecor}>{cd[0].CONSUMER_EMAIL!==null?cd[0].CONSUMER_EMAIL:"Not updated"}</text> 
                                {cd[0].CONSUMER_EMAIL !==null ?
                                ud.length?
                                    ud[0].ACCOUNT_VERIFIED_FLAG==1 ?
                                        <span style={{backgroundColor:"#3DC5B6",padding:"5px",color:"#fff",borderRadius:"1px", fontFamily:'Muli'}}>Verified</span>
                                        :
                                        <span style={{backgroundColor:"#F86C69",padding:"5px",color:"#fff",borderRadius:"3px",fontFamily:'Muli'}}>Not-Verified</span>
                                : <span style={{backgroundColor:"#F86C69",padding:"5px",color:"#fff",borderRadius:"3px",fontFamily:'Muli'}}>Not-Verified</span>
                            :null}
                            </Col>
                            <Col size={3}>
                            <span style={fWeightSpan}>Mobile Number:</span> <text style={textDecor}> 
                            {cd[0].CONSUMER_PHONE_COUNTRY_CODE?cd[0].CONSUMER_PHONE_COUNTRY_CODE+' ':cd[0].CONSUMER_PHONE!==null?'+1 ':null}{cd[0].CONSUMER_PHONE!==null ?  <text>{cd[0].CONSUMER_PHONE.toString().substring(0,3)}-{cd[0].CONSUMER_PHONE.toString().substring(3,6)}-{cd[0].CONSUMER_PHONE.toString().substring(6,10)}</text> : "Not updated"}</text>                          

                            {cd[0].CONSUMER_PHONE !==null ?
                                ud.length? 
                                    ud[0].MOBILE_VERIFIED_FLAG===1 ?
                                        <span style={{backgroundColor:"#3DC5B6",padding:"5px",color:"#fff",borderRadius:"3px", fontFamily:'Muli'}}>Verified</span>
                                        :
                                        <span style={{backgroundColor:"#F86C69",padding:"5px",color:"#fff",borderRadius:"3px",fontFamily:'Muli'}}>Not-Verified</span>
                                : <span style={{backgroundColor:"#F86C69",padding:"5px",color:"#fff",borderRadius:"3px",fontFamily:'Muli'}}>Not-Verified</span>
                            :null}
  
                            </Col>                        
                        </Row>
                        <Row className="mb-4" style={rowStyle}>

                        <Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}>Bookings:</span> {offerAcceptedfiltered===null||0?0:offerAcceptedfiltered>0?<text style={LinkedText} onClick={()=>this.bookingsHandler()}> {offerAcceptedfiltered}</text>:offerAcceptedfiltered}</text>
                        </Col>

                        <Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}>Bookings Consumed:</span> {offerConsumefiltered===null||0?0:offerConsumefiltered>0?<text style={LinkedText} onClick={()=>this.bookingsConsumedHandler()}> {offerConsumefiltered}</text>:offerConsumefiltered}</text>
                        </Col>

                        
                        <Col size={3}>
                            <span style={fWeightSpan}>Bookings Cancelled:</span> 
                            <text style={textDecor}>
                            {offerCancelfiltered===null||0?0:offerCancelfiltered>0?<text style={LinkedText} onClick={()=>this.cancelledHandler()}>{offerCancelfiltered}</text>:offerCancelfiltered}
                          </text>  
                            
                                                    </Col>                        
                    </Row>     
                    <Row className="mb-4" style={rowStyle}>
                        <Col sm={{size:4}}>
                            <span style={fWeightSpan}>Bookings Disputed:</span>
                            <text style={textDecor}>{finalDispCount===null||0?0:finalDispCount>0?<text style={LinkedText} onClick={()=>this.disputesHandler()}>{finalDispCount}</text>:finalDispCount}
                            </text>
                        </Col>
                        <Col sm={{size:4}}>     
                        <Button size="md" style={{backgroundColor:"#612147",marginRight:5,fontFamily: 'Muli',}} onClick={()=>this.earningsViewHandler()}>Earned Coupons</Button>
                        
                       </Col>
                        <Col sm={{size:4}}>     
                        <Button size="md" style={{backgroundColor:"#612147",marginRight:5,fontFamily: 'Muli',}} onClick={()=>this.consumerPaymentHanler()}>Payment&Transactions {'-->'}</Button>
                        
                       </Col>
                        </Row>   
                        <Row className="mb-4" style={rowStyle}>
                            <Col className="col-4">
                                <text style={fontText1}><span style={fWeightSpan}> Customer Address:</span>{ this.state.consumerAdd[0].length!==0 ? this.state.consumerAdd[0][0].CONSUMER_ADDRESS ? this.state.consumerAdd[0][0].CONSUMER_ADDRESS : '------' : '------' }</text>
    
                            </Col> 

                            <Col className="col-4">
                            <text style={fontText1}><span style={fWeightSpan}>Promotional Consent:</span>{cd[0].CONSUMER_PROMO_CONSENT_FLAG==1?"Yes":"No"}</text>
                        </Col> 

                            <Col>
                                <text style={fontText1}><span style={fWeightSpan}>Last Login Time:</span>{
                                this.state.loginDetails.length ? 
                                (this.state.loginDetails[0].USER_TOKEN_UPDATED_ON_DATE ? 
                                    moment(this.state.loginDetails[0].USER_TOKEN_UPDATED_ON_DATE+"Z").tz(this.state.consumerAdd[0].length !==0 ? (this.state.consumerAdd[0][0].CONSUMER_ADDRESS.includes('USA') ? 'America/Los_Angeles' : 'Asia/Kolkata') : (this.props.country === 'USA'?'America/Los_Angeles' : 'Asia/Kolkata')).format("DD-MMM-YYYY hh:mm A z") 
                                    : '-----') 
                                : '------'}
                                </text>
                            </Col> 
                            </Row>  
                                  
         
                    </CardText>            
                </Card>            
                )
            }else {
                custDetails = null; 
            }

            let supportDetails= null;
            if(this.state.Sdetails.length || this.state.SCouponCode.length){
                supportDetails = <SupportDetails SCouponCode={this.state.SCouponCode} Sdetails={this.state.Sdetails} offerPrid={AccOffDet[0].QF_MERCHANT_OFFER_PRID} servicePrid={AccOffDet[0].QF_MERCHANT_SERVICE_PRID}/>

            } else {
                supportDetails = null;
            }

            let merchantFlag = null;
            if(AccOffDet.length){
                if(AccOffDet[0].MERCHANT_FLAGGED){
                    merchantFlag = (
                        <Card style={{border:"1px solid #a78bba",marginTop:10}}>
                            <CardHeader style={{fontSize:"18px",color:"#fff",backgroundColor:"#2C2165", fontFamily:'Muli'}}><text>Professional Flagged Details</text></CardHeader>
                            <CardText style={{padding:"25px"}}>
                                <Row className="mb-4" style={rowStyle}>
                                    <Col className="col-4"><span style={fWeightSpan}>Flagged By:</span> <text style={textDecor}>{this.state.flagUserFName}{' '}{this.state.flagUserLName}</text></Col> 
                                    <Col className="col-2"><span style={fWeightSpan}>Severity:</span> <text style={textDecor}>{AccOffDet[0].MERCHANT_FLAG_SEVERITY === 1 ? <Label style={{backgroundColor:"#00aedb",borderRadius:"3px",color:"#fff",padding:4, width:78}}>Very Low</Label> :  AccOffDet[0].MERCHANT_FLAG_SEVERITY === 2 ? <Label style={{backgroundColor:"#00b159",borderRadius:"3px",color:"#fff",marginLeft:"15px",marginRight:"15px",padding:4,width:78,}}>Low</Label> : AccOffDet[0].MERCHANT_FLAG_SEVERITY === 3 ? <Label style={{backgroundColor:"#ffc425",borderRadius:"3px",color:"#fff",marginLeft:"15px",marginRight:"15px",padding:4,width:78}}>Medium</Label> : AccOffDet[0].MERCHANT_FLAG_SEVERITY === 4 ? <Label style={{backgroundColor:"#f37735",borderRadius:"3px",color:"#fff",marginLeft:"15px",marginRight:"15px",padding:4,width:78}}>High</Label> : AccOffDet[0].MERCHANT_FLAG_SEVERITY === 5 ? <Label style={{backgroundColor:"#d11141",borderRadius:"3px",color:"#fff",marginLeft:"15px",marginRight:"15px",padding:4,width:78}}> Very High </Label> : '---' }</text></Col>
                                    <Col className="col-2"><span style={fWeightSpan}>Type:</span> <text style={textDecor}>{AccOffDet[0].MERCHANT_FLAG_ISSUE_TYPE}</text></Col>
                                    <Col className="col-4"><span style={fWeightSpan}>Reason:</span> <text style={textDecor}>{AccOffDet[0].MERCHANT_FLAG_COMMENTS}</text></Col>
                                </Row>
                            </CardText>
                        </Card>
                    );  
                }         
            }


            let messagesList=[];
            let messages={}
            if(this.state.messageArray.length){
                this.state.messageArray.map((list) => {
                    return (
                        messages = {
                            author: list.MessageFrom == "merchant" ? 'them' : "me",
                            type: "text",
                            data: { text: list.text }
                        },
                        messagesList.push(messages)
                        )
                })
            }
        
    
    return (
        <div>

        <Container fluid style={{paddingLeft:"30px",paddingRight:"30px",backgroundColor:"rgb(239,239,239)",float: 'left', width: '100%' }}>
            <BarLoader widthUnit={"%"} width={100} loading={this.state.loading} color="#2C2165"/>
            {!this.state.loading && refundModal  }
            {!this.state.loading && refundModal2 }
            {!this.state.loading && refundModal3 }
            {!this.state.loading && refundModal4 }
            {!this.state.loading && refundModal5 }
            {!this.state.loading && custDetails  }
            {!this.state.loading && offerDetailView }
            {!this.state.loading && couponDetails }
            {!this.state.loading && acceptedDetailView }            
            {!this.state.loading && paymentDetailView  }
            {!this.state.loading && merchantpayDetailView }
            {!this.state.loading && refundDetails }
            {!this.state.loading && merchantFlag }
            {!this.state.loading && supportDetails }
            {this.state.noData   && 'No Results to display'}   
            {this.state.loading  && <text style={textDecor}>Loading...</text> }

            <div style={{zIndex:100000}}>
            {!this.state.messageArray.length && <Launcher
                agentProfile={{
                teamName: 'Customer Chat',
                imageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png'
                }}
                messageList={messagesList}
            />}
            </div>

            <ToastContainer />
        </Container>

        </div>
    )
    }

}
const mapStateToProps=state=>{
    return {
        country: state.country,
        token:state.token,
        id:state.id,
        view:state.view
    } 
  }
export default withRouter(connect(mapStateToProps)(BookingIdSearch));